import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import EditCourseModal from "../../components/modals/EditCourseModal";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import NormalPagination from "../../components/pagination/NormalPagination";
import YuJaButton from "../../components/standardization/YuJaButton";
import { useLoading } from "../../utils/LoadingContext";
import { BUTTON, COURSE_CHARACTER_LIMIT, DEFAULT_ROWS_PER_PAGE, DELETE_COURSE, DELETE_COURSE_MODAL_BODY, DELETE_CRSE, EDIT_BTN, MODAL_CANCEL_TEXT, MODAL_DELETE_TEXT, VIEW_TEXT } from "../../utils/constants";
import styles from "./ManageCourses.module.css";

export default React.memo(CourseTable);
function CourseTable({ data = [], columns = [], pageIdx=0, globalFilterObj = {}, getCourses }) {
    const {loading} = useLoading();
    const history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentCourseId, setCurrentCourseId] = useState("");
    const { deleteCourse } = CourseApiCalls();
    const [currentRow, setCurrentRow] = useState(null);

    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        // const filteredDate = globalFilterValue.date;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.courseName && values.courseName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (values.courseCode && values.courseCode.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        });
        // .filter(r => {
        //     if (!filteredDate) {
        //         return true;
        //     }
        //     const values = r.values;
        //     return values.startDate && values.startDate >= filteredDate;
        // });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: pageIdx, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);


    useEffect(() => {
        if (!pageOptions.length) {
            gotoPage(0);
        } else if (pageIdx < pageOptions.length) {
            gotoPage(pageIdx);
        } else {
            gotoPage(pageIdx - 1);
        }
    }, [data, pageIdx, pageOptions]);

    const onView = (courseId) => {
        history.push(`/course/content/${courseId}`);
    }

    const onDelete = (courseId) => {
        setCurrentCourseId(courseId);
        setShowDeleteModal(true);
        // if (!window.confirm("Are you sure you want to delete this course?")) {
        //     return;
        // }
        // const res = await deleteCourse(courseId);
        // if (res.success) {
        //     getCourses(pageIndex);
        // }
    }

    const handleDelete = async () => {
        const res = await deleteCourse(currentCourseId);
        if (res.success) {
            getCourses(pageIndex);
        }
    };

    const onEdit = (rowData, courseId) => {
        setCurrentRow(rowData);
        setCurrentCourseId(courseId)
        setModalShow(true);
    }

    const loadCourse = () => {
        getCourses(pageIndex);
    }

    const deleteCourseConfig = {
        title: DELETE_COURSE,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleDelete();
        },
        close:  () => {
            setShowDeleteModal(false);
        }, 
    };

    return (
        <>
            <div className={styles.tableContainer}>
                <Table responsive hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th tabIndex={0} {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth
                                        }
                                    })}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        if (cell.column.Header === 'Edit') {
                                            return (
                                                <td {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                    <div tabIndex={0} aria-label={EDIT_BTN} role={BUTTON} className={`${styles.tableBtn} ${styles.tableEditBtn}`}
                                                        onClick={() => { onEdit(cell.row.values, cell.row.values['courseId']); }}
                                                    />
                                                    <div  tabIndex={0} aria-label={DELETE_CRSE + (row.cells.at(index-2).value)} role={BUTTON} className={`${styles.tableBtn} ${styles.tableDeleteBtn}`}
                                                        onClick={() => { onDelete(cell.value); }}
                                                    />
                                                </td>
                                            );
                                        } else if (cell.column.Header === 'View Course') {
                                            return (
                                                <td {...cell.getCellProps()} className={styles.tableBtnUnit} style={{justifyContent: "center"}}>
                                                    <YuJaButton style={{margin: "auto"}} onClick={() => onView(cell.row.values['courseId'])} > {VIEW_TEXT}</YuJaButton>

                                                </td>
                                            );
                                        // } else if (cell.getCellProps().key.split("_")[2] === "startDate" ) {
                                        //     return (
                                        //         <td {...cell.getCellProps()}>
                                        //         {
                                        //         cell.value ?
                                        //         cell.value.split("/")[0] + '-' + cell.value.split("/")[1] + '-' + cell.value.split("/")[2] :
                                        //         ''
                                        //         }
                                        //         </td>
                                        //     );
                                        } else {
                                            return (
                                                <td tabIndex={0} aria-label={cell.column.Header + " " + cell.value}
                                                    title={cell.value && cell.value.length > COURSE_CHARACTER_LIMIT ? cell.value : ""}
                                                    {...cell.getCellProps({style: {wordBreak: 'break-word'}})}
                                                >
                                                    {cell.value && cell.value.length > COURSE_CHARACTER_LIMIT ?
                                                        cell.value.substring(0, COURSE_CHARACTER_LIMIT) + "..." :
                                                        cell.value
                                                    }
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }) : <tr><td colSpan="4" className={styles.noData}>No Results Found</td></tr>}
                    </tbody>
                </Table>
                {page && page.length !== 0 ?
                    <NormalPagination
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                    />
                 : <></> }
            </div>
            <EditCourseModal
                show={modalShow}
                setModalShow={setModalShow}
                rowData={currentRow}
                courseId={currentCourseId}
                loadCourse={loadCourse}
            />
            <YuJaGeneralAlert show={showDeleteModal}
                setModalShow={setShowDeleteModal}
                content={DELETE_COURSE_MODAL_BODY}
                config={deleteCourseConfig}
                AlertType={MODAL_DELETE_TEXT}/>
            {/* <Modal
				id="delete-modal"
				show={showDeleteModal}
				onHide={() => setShowDeleteModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				// dialogClassName="delete-modal"
            >
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={() => setShowDeleteModal(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0}>
                            {DELETE_COURSE}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
                        <div tabIndex={0}>{DELETE_COURSE_MODAL_BODY}</div>
					</Modal.Body>
                    <Modal.Footer>
						<button className="modal-button-cancel" tabIndex={0} onClick={() => setShowDeleteModal(false)}>
							{MODAL_CANCEL_TEXT}
						</button>
						<button
                            tabIndex={0}
							className="modal-button-submit"
							onClick={handleDelete}
						>
							{MODAL_DELETE_TEXT}
						</button>
					</Modal.Footer>
				</Spin>
			</Modal> */}
        </>
    );
}
