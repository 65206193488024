import { useHistory, useLocation } from "react-router-dom";
import { useFetchWrapper } from "../hooks/useFetchWrapper";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import { useLoading } from "../utils/LoadingContext";
import {
    HOME_PATH,
    IDENTITY_PROVIDER_TYPES,
    INTEGRATION_TYPES,
    LOGIN_PATH,
    POLL_CHOOSER_PATH,
    ROLES,
    VIEWER_USER_TYPE
} from "../utils/constants";
import { logoutOfChromeExtensions, notifyError, notifySuccess } from "../utils/helpers";
import { AUTHENTICATION_API_URL, INTEGRATION_API_URL } from "../utils/properties";
import { ENGAGE_REDIRECT_ERROR, GET_COURSE_ERROR, INVALID_EMAIL_ERROR, LOGIN_USER_ERROR, RESET_CODE_ERROR, SERVER_ERROR, SSO_REDIRECT_ERROR, USER_NOT_FOUND_ERROR } from "../utils/toast-message-constants";
import { CourseApiCalls } from './CourseApiCalls';
import { PollApiCalls } from "./PollApiCalls";
import { ResponseApiCalls } from "./ResponseApiCalls";

export const AuthenticationApiCalls = () => {
    const fetchWrapper = useFetchWrapper();
    const [setSession, getSession] = useLocalStorageNew("session", {});
    const[, getHostResource] = useLocalStorageNew(window.location.hostname, {});
    const history = useHistory();

    const {setUserNameToLocalStorage} = ResponseApiCalls();
    const { getPollRecordFromUniqueCode } = PollApiCalls();
    const { checkMemberAccess } = CourseApiCalls();

    const location = useLocation();
    const {setLoading} = useLoading();

    const checkUserAccess = async () => {
        const result = await getPollRecordFromUniqueCode(location.state.pollCode);
        if(!result || !result.poll) {
          return false; 
        }
        const courseId = result.poll.index2Pk;
        if (courseId == null) {
            console.log("This poll has no associated course");
            return true;
        }
    
        const accessibleResult = await checkMemberAccess(courseId.substring(2));
        console.log(accessibleResult)
        if (accessibleResult == null || accessibleResult.result == null || !accessibleResult.result) {
            return false; 
        }
    
        return true;
      }

      
    return {
        login,
        logout,
        reLogin,
        sendResetPwdEmail,
        resetPwd,
        getSAMLRedirectURL,
        loginSAML,
        loginCANVAS,
        loginBB,
        loginMOODLE,
        loginD2L,
        createUserRoster,
        getD2LAuthURL,
        tryCanvasURL
        // storeD2LAuthToken
    }

    // Function that handles login
    // Does not return any data, sets all the session data in the function
    function login(user, setAlertMsg) {
        const userName = user.userName;
        const bodyObj = Object.assign({}, user);

        const {institutionId} = getHostResource();
        bodyObj.institutionId = institutionId;
        // set user in local storage, and go back to the previous page
        setLoading(true);
        return fetchWrapper.post(
            {
                url: `${AUTHENTICATION_API_URL}/login`,
                body: bodyObj,
                authorizeRequired: false
            }
        ).then(result => {
            // console.log(result);
            setLoading(false);
            if (!result.success) {
                setAlertMsg(result.errorMessage);
                notifyError(result.errorMessage);
            }
            const session = getSession();
            session.authorization = result.result.token;
            session.userName = result.result.userName ? result.result.userName : userName;
            session.role = result.result.role;
            session.userId = result.result.userId;
            session.firstName = result.result.firstName ? result.result.firstName : "";
            session.lastName = result.result.lastName ? result.result.lastName : "";
            session.email = result.result.email ? result.result.email : "";
            // session.idpType = result.result.idpType !== "" ? result.result.idpType : IDENTITY_PROVIDER_TYPES.YUJA;
            if(result.result.idpType === "YuJa_Auto") {
                session.isYuJaAuto = true;
            }
            session.idpType = IDENTITY_PROVIDER_TYPES.YUJA;
            session.gradedLink = false;
            session.settings = result.result.settings ? result.result.settings : {};
            if (location.state
                && location.state.pollCode
                && location.state.from
                && location.state.from.includes("join")){
                      setSession(session);
                      checkUserAccess().then((userHasAccess) => { if (!userHasAccess) {
                        history.push(HOME_PATH);
                    }}).catch((error) => {console.error(error);});
                     
                setUserNameToLocalStorage(location.state.pollCode, result.result.userId, VIEWER_USER_TYPE.LOGGED_IN);
            }
            // console.log(session);
            setSession(session);
            if (location.state && location.state.from) {
                if(location.state.from === LOGIN_PATH){
                    history.push(HOME_PATH);
                } else {
                    history.push(location.state.from);
                }
            } else {
                history.push(HOME_PATH);
            } 
        }).catch(err => {
            console.log(err);
            history.push(LOGIN_PATH, {msg: LOGIN_USER_ERROR, from: location.pathname});
            setLoading(false);
        });
    }

    // Function that handles logout 
    // Does not return any data 
    function logout() {
        // remove user from local storage, set auth state to null and redirect to home page
        setLoading(true);
        logoutOfChromeExtensions();
        return fetchWrapper.post({ url: `${AUTHENTICATION_API_URL}/logout` })
            .then(result => {
                if (result.success) {
                    if(result.result && result.result.remoteLogoutUrl) {
                        setSession({});
                        // return fetchWrapper.get({url: result.result.remoteLogoutUrl}, {}, false, false, {});
                        // history.push(result.result.remoteLogoutUrl);
                        window.location.replace(result.result.remoteLogoutUrl);
                    } else {
                        setSession({});
                        history.push(LOGIN_PATH);
                    }
                } else {
                    notifyError(result.errorMessage);
                }
            }).catch(err => {
                console.log(err);
                notifyError(SERVER_ERROR);
            }).finally(() => {
                setLoading(false);
            })
    }

    function reLogin() {
        return fetchWrapper.post({url: `${AUTHENTICATION_API_URL}/reLogin/`})
            .then(result => {
                if (result.success) {
                    const session = getSession();
                    session.authorization = result.result.token;
                    session.userName = result.result.userName;
                    session.role = result.result.role;
                    session.userId = result.result.userId;
                    // session.idpType = result.result.idpType !== "" ? result.result.idpType : IDENTITY_PROVIDER_TYPES.YUJA;
                    session.idpType = IDENTITY_PROVIDER_TYPES.YUJA;
                    session.gradedLink = false;
                    session.settings = result.result.settings ? result.result.settings : {};
                    setSession(session);
                } else {
                    notifyError(result.errorMessage);
                    history.push(LOGIN_PATH, {from: location.pathname});
                }
            }).catch(err => {
                console.log(err);
                notifyError(SERVER_ERROR);
            })
    }


    function sendResetPwdEmail(identity) {
        const {institutionId} = getHostResource();
        setLoading(true);
        return fetchWrapper.post({
            url: `${AUTHENTICATION_API_URL}/pwd/email`,
            body: {institutionId, identity},
            authorizeRequired: false
        }).then(result => {
            if (!result.success) {
                notifyError(result.errorMessage);
                return false;
            } else {
                return true;
            }
        }).catch(err => {
            console.log(err);
            if (JSON.parse(err.message).errorCode === "Y017") {
                notifyError(USER_NOT_FOUND_ERROR);
            } else if (JSON.parse(err.message).errorCode === "Y100") {
                notifyError(INVALID_EMAIL_ERROR);
            }
            else {
                notifyError(JSON.parse(err.message).errorMessage);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    function resetPwd({resetCode, pwd}) {
        setLoading(true);
        return fetchWrapper.put({
            url: `${AUTHENTICATION_API_URL}/pwd/${resetCode}`,
            body: {pwd},
            authorizeRequired: false
        }).then(result => {
            if (!result.success) {
                notifyError(result.errorMessage);
                return false;
            } else {
                return true;
            }
        }).catch(err => {
            console.log(err);
            if (JSON.parse(err.message).errorCode === "Y017") {
                notifyError(RESET_CODE_ERROR);
            } else {
                notifyError(JSON.parse(err.message).errorMessage);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    function getSAMLRedirectURL(ssoType) {
        setLoading(true);
        return fetchWrapper.get({
            // pass dropdown value as last path parameter instead of hard-coded ADFS value
            url: `${AUTHENTICATION_API_URL}/login/sso/saml/` + ssoType,
            body: {},
            authorizeRequired: false
        }).then(result => {
            if (!result.success) {
                notifyError(SSO_REDIRECT_ERROR);
                return false;
            } else {
                return result.result.redirectURL;
            }
        }).catch(err => {
            console.log(err);
            notifyError(SSO_REDIRECT_ERROR);
            return false;
        }).finally(() => {
            setLoading(false);
        })
    }

    function loginSAML(secret, intType) {
        setLoading(true);
        return fetchWrapper.post({
            // pass dropdown value as last path parameter instead of hard-coded ADFS value
            url: `${AUTHENTICATION_API_URL}/login/sso/saml`,
            body: {
                "secret": secret,
                "intType": intType
            },
            authorizeRequired: false
        }).then(result => {
            if (result.success) {
                const session = getSession();
                session.authorization = result.result.token;
                session.userName = result.result.userName;
                session.role = result.result.role;
                session.userId = result.result.userId;
                // session.idpType = result.result.idpType !== "" ? result.result.idpType : IDENTITY_PROVIDER_TYPES.SAML;
                session.idpType = IDENTITY_PROVIDER_TYPES.SAML;
                session.firstName = result.result.firstName ? result.result.firstName : "";
                session.lastName = result.result.lastName ? result.result.lastName : "";
                session.email = result.result.email ? result.result.email : "";
                session.gradedLink = false;
                session.settings = result.result.settings ? result.result.settings : {};
                setSession(session);
                if (location.state && location.state.from) {
                    history.push(location.state.from);
                } else {
                    history.push(HOME_PATH);
                }
            } else {
                notifyError(result.errorMessage);
            }
        }).catch(err => {
            console.log(err);
            history.push(LOGIN_PATH, {msg: SSO_REDIRECT_ERROR});
            return false;
        }).finally(() => {
            setLoading(false);
        })
    }

    function loginCANVAS(secret, intType, reqType, redirectURL) {
        setLoading(true);
        return fetchWrapper.post({
            // pass dropdown value as last path parameter instead of hard-coded ADFS value
            url: `${AUTHENTICATION_API_URL}/login/lms`,
            body: {
                "secret": secret,
                "intType": intType
            },
            authorizeRequired: false
        }).then(result => {
            if (result.success) {
                const session = getSession();
                session.authorization = result.result.token;
                session.userName = result.result.userName;
                session.role = result.result.role;
                session.userId = result.result.userId;
                // session.idpType = result.result.idpType !== "" ? result.result.idpType : IDENTITY_PROVIDER_TYPES.LMS;
                session.idpType = IDENTITY_PROVIDER_TYPES.LMS;
                session.intType = INTEGRATION_TYPES.CANVAS;
                session.firstName = result.result.firstName ? result.result.firstName : "";
                session.lastName = result.result.lastName ? result.result.lastName : "";
                session.email = result.result.email ? result.result.email : "";
                session.gradedLink = false;
                session.settings = result.result.settings ? result.result.settings : {};
                if(result.result.courseId) {
                    session.courseId = result.result.courseId;
                }
                if(result.result.courses) {
                    session.courses = result.result.courses;
                }
                setSession(session);
                if(reqType && reqType === "deepLinking") {
                    history.push({pathname: POLL_CHOOSER_PATH + session.courseId, state : { secret : secret, intType : INTEGRATION_TYPES.CANVAS }});
                } else if(reqType && reqType === "pollResponseRedirect" && redirectURL !== "null") {
                    if(session.role === ROLES.VIEWER.value) {
                        const session = getSession();
                        session.gradedLink = true;
                        setSession(session);
                        storeLTIGradebookRequest(secret, redirectURL.substring(0, 6));
                        setUserNameToLocalStorage(redirectURL.substring(0, 6), result.result.userId, VIEWER_USER_TYPE.LMS_CANVAS);
                        history.push({pathname: "/join/" + redirectURL.substring(0, 6), state: {isLMS: true}});
                    } else if(session.role === ROLES.CREATOR.value || session.role === ROLES.IT_MANAGER.value) {
                        history.push({pathname: "/poll/share/" + redirectURL.slice(-36) + "/" + redirectURL.substring(0, 6), state: {isLMS: true}});
                    }
                } else {
                    if (location.state && location.state.from) {
                        history.push(location.state.from);
                    } else {
                        history.push(HOME_PATH);
                    }
                }
            } else {
                notifyError(result.errorMessage);
            }
        }).catch(err => {
            console.log(err);
            notifyError(SSO_REDIRECT_ERROR);
            return false;
        }).finally(() => {
            setLoading(false);
        })
    }

    function loginBB(secret, intType, reqType, redirectURL) {
        setLoading(true);
        return fetchWrapper.post({
            // pass dropdown value as last path parameter instead of hard-coded ADFS value
            url: `${AUTHENTICATION_API_URL}/login/lms`,
            body: {
                "secret": secret,
                "intType": intType
            },
            authorizeRequired: false
        }).then(result => {
            if (result.success) {
                const session = getSession();
                session.authorization = result.result.token;
                session.userName = result.result.userName;
                session.role = result.result.role;
                session.userId = result.result.userId;
                // session.idpType = result.result.idpType !== "" ? result.result.idpType : IDENTITY_PROVIDER_TYPES.LMS;
                session.idpType = IDENTITY_PROVIDER_TYPES.LMS;
                session.intType = INTEGRATION_TYPES.BLACKBOARD;
                session.firstName = result.result.firstName ? result.result.firstName : "";
                session.lastName = result.result.lastName ? result.result.lastName : "";
                session.email = result.result.email ? result.result.email : "";
                session.gradedLink = false;
                session.settings = result.result.settings ? result.result.settings : {};
                if(result.result.courseId) {
                    session.courseId = result.result.courseId;
                }
                setSession(session);
                if(reqType && reqType === "deepLinking") {
                    history.push({pathname: POLL_CHOOSER_PATH + session.courseId, state : { secret : secret, intType : INTEGRATION_TYPES.BLACKBOARD }});
                } else if(reqType && reqType === "pollResponseRedirect" && redirectURL !== "null") {
                    if(session.role === ROLES.VIEWER.value) {
                        const session = getSession();
                        session.gradedLink = true;
                        setSession(session);
                        storeLTIGradebookRequest(secret, redirectURL.substring(0, 6));
                        setUserNameToLocalStorage(redirectURL.substring(0, 6), result.result.userId, VIEWER_USER_TYPE.LMS_BLACKBOARD);
                        history.push({pathname: "/join/" + redirectURL.substring(0, 6), state: {isLMS: true}});
                    } else if(session.role === ROLES.CREATOR.value || session.role === ROLES.IT_MANAGER.value) {
                        history.push({pathname: "/poll/share/" + redirectURL.slice(-36) + "/" + redirectURL.substring(0, 6), state: {isLMS: true}});
                    }
                } else {
                    if (location.state && location.state.from) {
                        history.push(location.state.from);
                    } else {
                        history.push(HOME_PATH);
                    }
                }
            } else {
                notifyError(result.errorMessage);
            }
        }).catch(err => {
            console.log(err);
            notifyError(SSO_REDIRECT_ERROR);
            return false;
        }).finally(() => {
            setLoading(false);
        })
    }

    function loginMOODLE(secret, intType, reqType, redirectURL) {
        setLoading(true);
        return fetchWrapper.post({
            // pass dropdown value as last path parameter instead of hard-coded ADFS value
            url: `${AUTHENTICATION_API_URL}/login/lms`,
            body: {
                "secret": secret,
                "intType": intType
            },
            authorizeRequired: false
        }).then(result => {
            if (result.success) {
                const session = getSession();
                session.authorization = result.result.token;
                session.userName = result.result.userName;
                session.role = result.result.role;
                session.userId = result.result.userId;
                // session.idpType = result.result.idpType !== "" ? result.result.idpType : IDENTITY_PROVIDER_TYPES.LMS;
                session.idpType = IDENTITY_PROVIDER_TYPES.LMS;
                session.intType = INTEGRATION_TYPES.MOODLE;
                session.firstName = result.result.firstName ? result.result.firstName : "";
                session.lastName = result.result.lastName ? result.result.lastName : "";
                session.email = result.result.email ? result.result.email : "";
                session.gradedLink = false;
                session.settings = result.result.settings ? result.result.settings : {};
                if(result.result.courseId) {
                    session.courseId = result.result.courseId;
                }
                if(result.result.currentCourseRole && result.result.currentCourseRole.includes("#")) {
                    session.currentCourseRole = result.result.currentCourseRole.split("#")[1];
                } else {
                    session.currentCourseRole = "";
                }
                setSession(session);
                if(reqType && reqType === "deepLinking") {
                    history.push({pathname: POLL_CHOOSER_PATH + session.courseId, state : { secret : secret, intType : INTEGRATION_TYPES.MOODLE }});
                } else if(reqType && reqType === "pollResponseRedirect" && redirectURL !== "null") {
                    if(session.currentCourseRole === ROLES.VIEWER.value || session.role === ROLES.VIEWER.value) {
                        const session = getSession();
                        session.gradedLink = true;
                        setSession(session);
                        storeLTIGradebookRequest(secret, redirectURL.substring(0, 6));
                        setUserNameToLocalStorage(redirectURL.substring(0, 6), result.result.userId, VIEWER_USER_TYPE.LMS_MOODLE);
                        history.push({pathname: "/join/" + redirectURL.substring(0, 6), state: {isLMS: true}});
                    } else{
                        history.push({pathname: "/poll/share/" + redirectURL.slice(-36) + "/" + redirectURL.substring(0, 6), state: {isLMS: true}});
                    }
                } else {
                    if (location.state && location.state.from) {
                        history.push(location.state.from);
                    } else {
                        history.push(HOME_PATH);
                    }
                }
            } else {
                notifyError(result.errorMessage);
            }
        }).catch(err => {
            console.log(err);
            notifyError(SSO_REDIRECT_ERROR);
            return false;
        }).finally(() => {
            setLoading(false);
        })
    }

    function loginD2L(secret, intType, reqType, redirectURL) {
        setLoading(true);
        return fetchWrapper.post({
            // pass dropdown value as last path parameter instead of hard-coded ADFS value
            url: `${AUTHENTICATION_API_URL}/login/lms`,
            body: {
                "secret": secret,
                "intType": intType
            },
            authorizeRequired: false
        }).then(result => {
            if (result.success) {
                const session = getSession();
                session.authorization = result.result.token;
                session.userName = result.result.userName;
                session.role = result.result.role;
                session.userId = result.result.userId;
                // session.idpType = result.result.idpType !== "" ? result.result.idpType : IDENTITY_PROVIDER_TYPES.LMS;
                session.idpType = IDENTITY_PROVIDER_TYPES.LMS;
                session.intType = INTEGRATION_TYPES.D2L;
                session.firstName = result.result.firstName ? result.result.firstName : "";
                session.lastName = result.result.lastName ? result.result.lastName : "";
                session.email = result.result.email ? result.result.email : "";
                session.gradedLink = false;
                session.settings = result.result.settings ? result.result.settings : {};
                if(result.result.courseId) {
                    session.courseId = result.result.courseId;
                }
                setSession(session);
                if(reqType && reqType === "deepLinking") {
                    history.push({pathname: POLL_CHOOSER_PATH + session.courseId, state : { secret : secret, intType : INTEGRATION_TYPES.D2L }});
                } else if(reqType && reqType === "pollResponseRedirect" && redirectURL !== "null") {
                    if(session.role === ROLES.VIEWER.value) {
                        storeLTIGradebookRequest(secret, redirectURL.substring(0, 6));
                        const session = getSession();
                        session.gradedLink = true;
                        setSession(session);
                        setUserNameToLocalStorage(redirectURL.substring(0, 6), result.result.userId, VIEWER_USER_TYPE.LMS_D2L);
                        history.push({pathname: "/join/" + redirectURL.substring(0, 6), state: {isLMS: true}});
                    } else if(session.role === ROLES.CREATOR.value || session.role === ROLES.IT_MANAGER.value) {
                        history.push({pathname: "/poll/share/" + redirectURL.slice(-36) + "/" + redirectURL.substring(0, 6), state: {isLMS: true}});
                    }
                } else {
                    if (location.state && location.state.from) {
                        history.push(location.state.from);
                    } else {
                        history.push(HOME_PATH);
                    }
                }
            } else {
                notifyError(result.errorMessage);
            }
        }).catch(err => {
            console.log(err);
            notifyError(ENGAGE_REDIRECT_ERROR);
            return false;
        }).finally(() => {
            setLoading(false);
        })
    }

    function storeLTIGradebookRequest(secret, pollCode) {
        const session = getSession();
        fetchWrapper.post({
            url:`${INTEGRATION_API_URL}/lti/gradebook/request`,
            body: {
                secret: secret,
                pollCode: pollCode,
                userId: session.userId,
                intType: session.intType
            }
        }).then(result => {
            console.log(result);
        }).catch(err => {
            console.log(err);
        })
    }

    function createUserRoster(courseId, intType) {
        setLoading(true);
        const session = getSession();
        fetchWrapper.post({
            url:`${AUTHENTICATION_API_URL}/user/roster`,
            body: {
                courseId: courseId,
                intType: intType,
                userId: session.userId
            }
        }).then(result => {
            console.log(result);
            notifySuccess("The user roster has been successfully synced.");
        }).catch(err => {
            console.log(err);
            if (JSON.parse(err.message).errorCode === "Y015") {
                notifyError(GET_COURSE_ERROR);
            } else {
                notifyError("Unable to sync User Roster");
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    function getD2LAuthURL() {
        setLoading(true);
        fetchWrapper.get({
            url:`${AUTHENTICATION_API_URL}/auth/d2l`,
        }).then(result => {
            console.log(result);
            window.open(result.result.authURL, "_blank");
        }).catch(err => {
            console.log(err);
            notifyError("Unable to reach Login Page of D2L!")
        }).finally(() => {
            setLoading(false);
        })
    }

    function tryCanvasURL(apiAccessToken) {
        setLoading(true);
        fetchWrapper.get({
            url:`https://panotesting.instructure.com/api/v1/courses/635`,
            token: apiAccessToken
        }).then(result => {
            console.log(result);
        }).catch(err => {
            console.log(err);
            notifyError("Unable to reach Login Page of D2L!")
        }).finally(() => {
            setLoading(false);
        })
    }

    // function storeD2LAuthToken(x_a, x_b, x_c, x_state) {
    //     setLoading(true);
    //     fetchWrapper.post({
    //         url:`${AUTHENTICATION_API_URL}/auth/d2l`,
    //         body: {
    //             x_a : x_a,
    //             x_b : x_b,
    //             x_c : x_c,
    //             x_state : x_state,
    //         }
    //     }).then(result => {
    //         console.log(result);
    //     }).catch(err => {
    //         console.log(err);
    //         notifyError("Unable to store D2L Access Token!")
    //     }).finally(() => {
    //         setLoading(false);
    //     })
    // }
}
