import "bootstrap/dist/css/bootstrap.css";
import React, {useContext, useEffect, useRef, useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import Countdown from 'react-countdown';
import { useWindowDimensions } from "react-native-web";
import { ResponsiveContainer } from "recharts";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import NormalBox from "../../public/boxes/NormalBox";
import {useLoading, YuJaGlobalState} from "../../utils/LoadingContext";
import {
    BLANK_REGEX,
    CI_COORDINATE_SIZE,
    POLL_TYPE,
    QUESTION_TYPES,
    UNANSWERED,
    PARTICIPANT_TEXT,
    RESPONSE_TEXT,
    COMPLETION_RATE_TEXT,
    CORRECT_ANSWER_TEXT,
    UNAVAILABLE_TEXT,
    WRONG_ANSWER_TEXT,
    TEXTBOX,
    QUESTION_LABEL,
    QUESTION_TITLE,
    FITB_BLANK,
    FITB_NARRATOR,
    BUTTON, 
} from "../../utils/constants";
import { fetchImg, newPageLog } from "../../utils/helpers";
import { S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME } from "../../utils/properties";
import { decodeCIParam } from "../../utils/questionUtils";
import ClassResultChart from "./ClassResultChart";
import FITBResponseModal from "./FITBResponseModal";
import WordCloudResult from "./WordCloudResult";
import { ReactComponent as Boy } from "./images/graduating boy.svg";
import "./styles.css";
import NetworkStatus from "./NetworkStatus";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { ReactComponent as ParticipantsIcon } from "../../images/participants_icon.svg";
import { ReactComponent as ResponsesIcon } from "../../images/responses-icon.svg";
import { ReactComponent as CorrectIcon } from "../../images/correct-answer-icon.svg";
import { ReactComponent as WrongIcon } from "../../images/wrong-answer-icon.svg";
import { ReactComponent as CompletionRateIcon } from "../../images/completion-rate-icon.svg";
import { ReactComponent as SeeResponses } from "./images/eyeIcon.svg";
import { ReactComponent as SeeResponsesMobile } from "./images/seeResponses.svg";
import { ReactComponent as RightArrow} from "./images/rightArrow.svg";
import { processResult } from "../../utils/helpers";
import { questionCodeToName } from "../../utils/questionUtils";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import YuJaButton from "../../components/standardization/YuJaButton";
import { InitializeQuestionResultCount } from "../../utils/helpers";


export default function ClassResult({ pollInfo, currentQuestion, handleClassResultComplete, handleSAResult, institutionId, quesShareCount, websocketState,  sharedQuestionList, questions}) {
    const isNarrow = useMobileAccess(1200);
    const isMobile = useMobileAccess(480); 
    const curWindow = useWindowDimensions();
    const { pollKey, userName, uniqueCode, pollTitle, pollType, showAnswerOnViewer} = pollInfo;
    const { question, classResultEndTime, questionResponse = {}} = currentQuestion;
    const { queTitle, correctAnswers, questionType, weightage, serialNo, suffix="", image="",optionsMap } = question;
    const { getClassResult, getAllClassResult, getStudentResultByPoll} = ResponseApiCalls();
    const [questionResult, setQuestionResult] = useState([]);
    const [pollResults, setPollResults] = useState([]);
    const { setLoading } = useLoading();
    const [selectedQuestion, setSelectedQuestion] = useState(question);
    const [dataReady, setDataReady] = useState(false);
    const [questionImageURL, setQuestionImageURL] = useState("");
    const imageRef = useRef(null);
    const [isImgLoaded, setIsImgLoaded] = useState(false);
    const [modalshow, setModalShow] = useState(false);
    const {isOnline} = useContext(YuJaGlobalState);
    // const [yourAnswer, setYourAnswer] = useState(() => {
    //     return getYourAnswer(pollType, questionType, questionResponse.selectedOption);
    // });
    const unanswered = useRef(0);
    const intervalRef = useRef(0);

    const [participantCount, setParticipantCount] = useState("-");
    const [responsesCount, setResponsesCount] = useState("-");
    const [completionRate, setCompletionRate] = useState("-"); 
    const [questionData, setQuestionData ] = useState(() => {
        let CA;
        if (question.questionType === "MCSS") {
            CA = question.correctAnswers[0];
        } else if (question.questionType === "TF") {
            CA = question.correctAnswers;
        } else {
            CA = null;
        }
        return {
            correctAnswers: CA,
            questionType: question.questionType,
            queTitle: question.queTitle,
            questionResults: InitializeQuestionResultCount(question),
            rawResults: {},
            serialNo: question.serialNo,
            image: question.image,
            suffix: question.suffix,
            directLinkEvp: question.directLinkEvp
        };
    });
    const [questionResponses, setQuestionResponses] = useState(null);

    const [correctCount, setCorrectCount] = useState("-"); 
    const [incorrectCount, setIncorrectCount] = useState("-"); 

    const [isSwitching, setIsSWitching] = useState(false);

    function removeUnanswered(result) {
        let newResult = [];
        result.forEach((r) => {
            if (r.ans !== UNANSWERED) {
                newResult.push({...r});
            } else {
                unanswered.current = r.people;
            }
        });
        return newResult;
    };

    const onLoad = () => {
        setIsImgLoaded(true);
    }

    const getUnanswered = (result) => {
        if (result == null) {
          return 0;
        }
    
        let unansweredCount = 0; 
        result.forEach((r) => {
            if (r.ans === UNANSWERED) {
                unansweredCount = r.people;
            }
        });
        return unansweredCount;
    
      }

    async function getQuestionsData() {
        setLoading(true);
        const responses = await getStudentResultByPoll(pollKey, questions[0].pollSortKey.split("#")[2], userName);
        setQuestionResponses(responses);
        const results = await getAllClassResult(pollKey,  questions[0].pollSortKey.split("#")[2], questions);
        let tempQuestionsData = [];
        for (const result of results) { 
          let serialNo = result.serialNo; 
          const question = questions.find(({serialNo: no}) => parseInt(no) === parseInt(serialNo));
          let CA;
          if (question.questionType === "MCSS") {
            CA = question.correctAnswers[0];
          } else if (question.questionType === "TF") {
            CA = question.correctAnswers;
          } else {
            CA = null;
          }
          let questionType = question.questionType;
          let queTitle = question.queTitle;
          let MCSSelements = [];
          if (questionType === "MCSS") {
            for (let key in question.optionsMap) {
              MCSSelements.push(question.optionsMap[key]);
            }
          }
          let MCSSnum = MCSSelements.length;
          let suffix = question.suffix;
          let image = question.image;
          let questionImageURL;
          let isVideo = false;
          if(question.directLinkEvp) {
            questionImageURL = question.directLinkEvp;
            isVideo = true;
          }
          else if ((!!image || !!suffix) && institutionId && pollKey && serialNo) {
            let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;
  
            url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
            let img = await fetchImg(url);
            questionImageURL = img ? img : "";
          }
  
          let questionData = {
            correctAnswers: CA,
            questionType: questionType,
            queTitle: queTitle,
            questionResults: result.questionResults,
            serialNo: serialNo,
            MCSSnum: MCSSnum,
            questionImageURL: questionImageURL,
            isVideo: isVideo,
            rawResponse: result.rawResponse
          };
          tempQuestionsData.push(questionData); 
        }
  
        tempQuestionsData.forEach(item => {
          item.unansweredCount = getUnanswered(item.questionResults);
        });
  
        tempQuestionsData.forEach(item => {
          item.questionResults = removeUnanswered(item.questionResults);
        });
  
        setPollResults(tempQuestionsData);
        setDataReady(true);
        setLoading(false);
      }

    useEffect(() => {
        if (!isOnline || websocketState !== WebSocket.OPEN) {
          return;
        }
        setTimeout(() => {
          getQuestionsData();
        }, 3000);
    
      }, [isOnline, websocketState]);

    // useEffect(() => {
    //     if (!isOnline || websocketState !== WebSocket.OPEN) {
    //         if (intervalRef.current) {
    //             console.log("remove fetch class result interval")
    //             window.clearInterval(intervalRef.current);
    //         }
    //         return;
    //     }

    //     // if (questionType === QUESTION_TYPES.SA.name && pollType === POLL_TYPE.GRADED_POLL) {
    //     //     handleSAResult();
    //     //     return;
    //     // }

    //     if(isNarrow && document.getElementById("viewer-navbar") !== null) {
    //         document.getElementById("viewer-navbar").style.backgroundColor = "#E6F2F7";
    //     }


    //     const needRawResponse = questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.WC.name;
    //     if (pollType === POLL_TYPE.GRADED_POLL) { // graded share each results updated again after 3s
    //         setTimeout(() => {
    //             getClassResult(pollKey, uniqueCode, question, null, needRawResponse ? "all" : needRawResponse).then((result) => {
    //                 if (needRawResponse) {
    //                     setQuestionResult(result.responses ? result.responses : []);
    //                     const data = processResult(question, result);
    //                     setPollResults(removeUnanswered(data));
    //                 } else {
    //                     setPollResults(removeUnanswered(result));
    //                 }

    //                 setDataReady(true);
    //             });
    //             if (questionType === QUESTION_TYPES.FITB.name) {
    //                 getClassResult(pollKey, uniqueCode, question, null, false).then((result) => {
    //                     setPollResults(removeUnanswered(result));
    //                 })
    //             }
    //         }, 3000);
    //     } else { // survey share each results updated every 3s
    //         intervalRef.current = setInterval(() => {
    //             getClassResult(pollKey, uniqueCode, question, null, needRawResponse ? "all" : needRawResponse).then((result) => {
    //                 if (needRawResponse) {
    //                     setQuestionResult(result.responses ? result.responses : []);
    //                     const data = processResult(question, result);
    //                     setPollResults(removeUnanswered(data));
    //                 } else {
    //                     setPollResults(removeUnanswered(result));
    //                 }
    //                 setDataReady(true);
    //             });
    //         }, 3000);
    //     }


    //     return () => {
    //         if (intervalRef.current) {
    //             console.log("remove fetch class result interval")
    //             window.clearInterval(intervalRef.current);
    //         }
    //     }
    // }, [isOnline, websocketState]);

    // clean up function
    useEffect(() => {
        document.getElementById('root').style.backgroundColor = "#FCFBFF";
        document.getElementById('root').style.backgroundImage = ""
        newPageLog("ClassResult.js");
        return () => { setPollResults([]); }
    }, []);

    // const onLoadWrapper = () => {
    //     if (document.getElementsByClassName('right-wrapper')[0].scrollHeight > document.getElementsByClassName('right-wrapper')[0].offsetHeight) {
    //         document.getElementsByClassName('right-wrapper')[0].style.justifyContent = 'flex-start';
    //         document.getElementsByClassName('right-wrapper')[0].style.paddingTop = '20px';
    //     } else {
    //         document.getElementsByClassName('right-wrapper')[0].style.justifyContent = 'center';
    //         document.getElementsByClassName('right-wrapper')[0].style.paddingTop = null;
    //     }
    // };

    // useEffect(() => {
    //     onLoadWrapper();
    // }, [curWindow.height]);

    useEffect(() => {
        if (!isOnline) {
            return;
        }

        async function fetchData() {
            if(question.directLinkEvp) {
                setQuestionImageURL(question.directLinkEvp);
            }
            else if ((!!image || !!suffix) && institutionId && pollKey && serialNo) {
                let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;

                url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
                let img = await fetchImg(url);
                setQuestionImageURL(img ? img : "");
            }
        }
        fetchData();
    }, [suffix, institutionId, pollKey, serialNo, isOnline]);


    useEffect(() => {
        if (pollResults  && pollResults.length !== 0 ) {
          let incorrectCount = 0;
          let serialNo = selectedQuestion.serialNo
          const questiondata = pollResults.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
          setQuestionData(questiondata); 
          questiondata.questionResults.forEach((r) => {
            if (pollType === POLL_TYPE.GRADED_POLL && questionType !== QUESTION_TYPES.SA.name ) {
                if(questionType === QUESTION_TYPES.MCSS.name) {
                    let index = r.ans.split(". ")[0]
                    if (index && correctAnswers.includes(index.toLowerCase())){
                        setCorrectCount(r.people);
                    }
                    else {
                        incorrectCount += r.people; 
                    }
                }
                else if (questionType === QUESTION_TYPES.CI.name) {
                    if (correctAnswers.includes(r.name)) {
                        setCorrectCount(r.people);
                    }
                    else {
                        incorrectCount += r.people; 
                    }
                }
                else if (questionType === QUESTION_TYPES.TF.name) {
                    if (r.name.toString().includes(correctAnswers)){
                        setCorrectCount(r.people);
                    }
                    else {
                        incorrectCount += r.people; 
                    }
                }
                else if (questionType === QUESTION_TYPES.MH.name) {
                    if (r.ans === "Correct"){
                        setCorrectCount(r.people);
                    }
                    else {
                        incorrectCount += r.people; 
                    }
                }
                else if (questionType === QUESTION_TYPES.FITB.name) {
                    if (r.ans === "Correct"){
                        setCorrectCount(r.people);
                    }
                    else {
                        incorrectCount += r.people; 
                    }
                }
            }
          });
          if (pollType === POLL_TYPE.GRADED_POLL && questionType !== QUESTION_TYPES.SA.name ) {
                setIncorrectCount(incorrectCount);
          }
          let overall_count = questiondata.questionResults[0].total;
          setParticipantCount(overall_count);
          let count = questiondata.questionResults[0].total - questiondata.unansweredCount;
          setResponsesCount(count);
          setCompletionRate(Math.round(count/overall_count * 100));
      }
      }, [pollResults])

    
      const removeSelected = (data, target, questionType) => {
        if (questionType === QUESTION_TYPES.SA.name && data) {
        const indexToRemove = data.findIndex(item => item['0'] === target);
        if (indexToRemove !== -1) {
            const filteredList = [
              ...data.slice(0, indexToRemove),
              ...data.slice(indexToRemove + 1),
            ];
      
            return filteredList
          }
        }
        else if (questionType === QUESTION_TYPES.FITB.name && data) {
            const indexToRemove = data.findIndex(item =>
                Object.entries(item).every(([key, value]) => target[key] === value)
              );
          
              if (indexToRemove !== -1) {
                const filteredList = [
                    ...data.slice(0, indexToRemove),
                    ...data.slice(indexToRemove + 1),
                  ];
            return filteredList
        }
      }
    }

    useEffect(() => {
        setIsSWitching(true);
        getQuestionsData();
    }, [window.innerWidth])

    return (
        <div>
            {pollType === POLL_TYPE.GRADED_POLL && questionData.questionType === QUESTION_TYPES.FITB.name && (
                <FITBResponseModal
                    show={modalshow}
                    setModalShow={setModalShow}
                    content={ questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ? removeSelected(questionData.rawResponse, questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType) : questionData.rawResponse}
                    selectedOption={questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1] && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ? questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption : null}
                />
            )}
            {/* style={{width: isAnonymous ? "calc(100vw)" : "calc(100vw - 48px)"}} */}
            {/* {!isNarrow && (
                <div className="left-wrapper">
                    <div style={{flex: 1}}/>
                    <div className="left-header">Class Results</div>
                    <Boy width={`${Math.min(0.3 * curWindow.width, 0.6 * curWindow.height)}px`} height={`${Math.min(0.3 * curWindow.width, 0.6 * curWindow.height)}px`} margintop="25px"/>
                    <div style={{flex: 1}}/>
                    <div style={{alignSelf: "start", padding: 20}}>
                        <NetworkStatus tooltipPlacement={'right'} websocketState={websocketState}/>
                    </div>
                </div>
            )} */}

                <div className="waiting-room-title-container" style={{ height: "fit-content" , marginTop: "32px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between"}}>
                    <span tabIndex={0} aria-label={`${pollType === POLL_TYPE.GRADED_POLL ? "Poll " : "Survey "}` + "Result For " + pollTitle} role={TEXTBOX} className="waiting-room-title" style={{ wordBreak: "break-all", paddingLeft: !isNarrow? "170px" : "48px", textAlign: "center", flex: "1" , color: "#000", fontSize: "24px" }}> {`${pollType === POLL_TYPE.GRADED_POLL ? "Poll " : "Survey "}` + "Result For " + pollTitle}</span>
                    <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState} /> </div>
                </div>

                <div style={{ display: "flex", width:  "calc(100% - 36px)", gap: isMobile? "8px" : "25px", marginTop: "28px", marginLeft: "18px", marginRight: "18px", flexDirection: isMobile? "column" : ""}}>
                    <div tabIndex={0} role={TEXTBOX} style={{ color: "#000", fontFamily: "Geomanist", fontSize: isMobile ? "14px" :"18px", alignItems: "center", display: "flex" }}> Choose Question </div>
                    <YuJaDropdown
                        containerStyle={{width: !isMobile? "40%": "100%" }}
                        data={sharedQuestionList ? questions.filter(question => {
                            const  QuestionList = JSON.parse(sharedQuestionList);
                            return QuestionList.includes(question.serialNo);
                          }) : [question]}
                        value={"Question " + selectedQuestion.serialNo + ": " + selectedQuestion.queTitle}
                        getOptionLabel={item => "Question " + item.serialNo + ": " + item.queTitle}
                        getOptionValue={option => "Question " + option.serialNo + ": " + option.queTitle}
                        textStyle={{ overflow: "hidden", color: "#000", fontFamily: "Geomanist", fontSize: isMobile ? "14px" : "18px", letterSpacing: "0.63px", whiteSpace: "nowrap", textOverflow: 'ellipsis', textWrap: "nowrap", height: "20px", maxWidth: "100%",  paddingTop: isMobile? "2px" : ""}}
                        onChange={(item) => {
                            setDataReady(false);
                            setIsSWitching(true);
                            getQuestionsData();
                            setSelectedQuestion(item);
                        }}
                        style={{height: isMobile? "50px" : "40px"}}
                    />
                </div>

                
                {
                    isMobile && 
                    <>
                    <div style={{width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", borderRadius: "19.565px", border: "1px solid #969696", background: "#fff", height: "fit-content", marginTop: "15px", padding: "10px"
                    }}> 
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", rowGap: '10px', columnGap: "10px"}}>

                                <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%"}}>
                                    <div style={{ marginRight: "5px", width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ParticipantsIcon />
                                    </div>
                                    <div style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px" }}> {PARTICIPANT_TEXT} </div>
                                    <div style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {participantCount} </div>
                                </div>
                            <div style={{ display: "flex", alignItems: "center",  flex: "1" , minWidth: "45%"}}>
                                <div style={{ marginRight: "5px", width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ResponsesIcon />
                                </div>
                                <div style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {RESPONSE_TEXT} </div>
                                <div style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {responsesCount} </div>
                            </div>

                            { pollType !== POLL_TYPE.SURVEY && questionData.questionType !== QUESTION_TYPES.SA.name &&
                            <>
                            <div style={{ display: "flex", alignItems: "center",  flex: "1" , minWidth: "45%"}}>
                                <div style={{marginRight: "5px",width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CorrectIcon />
                                </div>
                                <div style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {CORRECT_ANSWER_TEXT} </div>
                                <div style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {correctCount} </div>
                            </div>

                            <div style={{ display: "flex", alignItems: "center",  flex: "1" , minWidth: "45%"}}>

                                <div style={{ marginRight: "5px", width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <WrongIcon />
                                </div>
                                <div style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {WRONG_ANSWER_TEXT} </div>
                                <div style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {incorrectCount} </div>
                            </div>
                            </>
                            }

                            <div style={{ display: "flex", alignItems: "center", width: "100%", flex: "1", maxWidth: "50%", minWidth: "45%"}}>

                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "5px"}}>
                                    <CompletionRateIcon />
                                </div>
                                <div style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {COMPLETION_RATE_TEXT} </div>
                                <div style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {completionRate} {completionRate !== "-" ? "%" : ""} </div>
                            </div>
                    </div>
                    </div> 
                    <div style={{display: "flex", flexDirection: "column", marginTop: "16px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", gap: "15px"}}> 
                    <span className="gradientWrapQuesType" style={{ height: isNarrow ? "23px" : "45px", width: isNarrow ? "109px" : "150px", marginLeft: "0px"}}>
                                <span className="questionPanelQuestionTypeContainer" style={{ width: isNarrow ? "107px" : "", padding: "0px", height: isNarrow ? "20px" : "", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div className="questionPanelQuestionType" role={TEXTBOX} style={{ fontSize: "12px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height: isNarrow? "12px" : ""}}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                </span>
                            </span>

                            <div className="cur-question-title" style={{ fontSize: isMobile ? "14px" : "18px", height: 'fit-content', wordBreak:"break-all"}}>Question {selectedQuestion.serialNo}  {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name ? " - " + selectedQuestion.queTitle : ""} </div>
                            {questionData.questionType === QUESTION_TYPES.FITB.name && <p className="ques-title" style={{whiteSpace: "pre-line", wordBreak:"break-all", order: "initial"}}>{ questionData.queTitle.replace(BLANK_REGEX, "______")}</p>}

                            {questionData.questionImageURL && questionData.questionType !== QUESTION_TYPES.CI.name && (
                        <div className="img-container" style={{display: 'flex',  justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px"}}>
                            {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                            {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                        </div>
                    )}

                    {questionData.questionImageURL && questionData.questionType === QUESTION_TYPES.CI.name && (
                         <div className="img-container" style={{display: 'flex',  justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px"}}>
                         <div style={{position: "relative"}}>
                             <img ref={imageRef} className="img-class-result" src={questionData.questionImageURL} tabIndex={0} alt="Question Image" onLoad={onLoad} />
                             {isImgLoaded && questionResponses !== null && imageRef.current !== null
                                 && !!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption
                                 && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("x")
                                 && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("y")
                                 &&
                                 <COORDINATE
                                     style={{
                                         position: "absolute",
                                         top: `${decodeCIParam(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                         left: `${decodeCIParam(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                     }}
                                 />
                             }
                         </div>
                     </div>
                    )}
                        
                    {(questionData.questionType !== QUESTION_TYPES.WC.name && questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.FITB.name) && (
                        <>
                            <ClassResultChart
                                correctAnswers={questionData.correctAnswers}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                numOptions={questionData.questionType === QUESTION_TYPES.MCSS.name || questionData.questionType === QUESTION_TYPES.CI.name
                                    ? pollResults.length : 3}
                                pollType={pollType}
                                showAnswerOnViewer={showAnswerOnViewer}
                                queResponse={questionResponses ? questionResponses.questions[selectedQuestion.serialNo - 1] : []}
                                question={selectedQuestion}
                            />
                        </>
                    )}

                    {/* {(!dataReady &&
                        questionData.questionType !== QUESTION_TYPES.WC.name && questionData.questionType !== QUESTION_TYPES.SA.name && 
                        !(pollType === POLL_TYPE.SURVEY && (questionData.questionType === QUESTION_TYPES.FITB.name))) && (
                        <div className="loading-text-back">
                            <p className="loading-text">Class Results Loading...</p>
                        </div>
                    )} */}

                    {questionData.questionType === QUESTION_TYPES.WC.name && (
                        <WordCloudResult
                            data={questionData.rawResponse}
                            // pollType={pollType}
                            // correctAnswers={correctAnswers}
                            isSwitching={isSwitching}
                            setIsSWitching={setIsSWitching}
                        />
                    )}

                    {(questionData.questionType === QUESTION_TYPES.SA.name &&
                    <>
                        <div style={{width: selectedQuestion.questionImageURL? "50%" : "100%", marginBottom: "40px"}}>
                            <>
                                <Col style={{maxHeight:"300px", overflowY: "auto"}}>
                                {dataReady && questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                    <>
                                    <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#C4BDD2",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                        {`"${questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                        </div> 
                                    
                                    {removeSelected(questionData.rawResponse,questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            tabIndex={0} 
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#CACACA",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                    </> 
                                 }

                                 {questionResponses && (!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ||  questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ==="unanswered")&& 
                                  <>{questionData.rawResponse?.map((item) =>
                                    item !== "unanswered" ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px"
                                        }}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )}</>
                                 }
                                </Col>
                            </>
                        </div>
                    </>
                    )}

                    {questionData.questionType === QUESTION_TYPES.FITB.name &&
                    <>
                        <div style={{width: selectedQuestion.questionImageURL? "50%" : "100%" }}>
                            <>
                                <Col style={{maxHeight:"300px", overflowY: "auto"}}>
                                {dataReady && questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                <>
                                 <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#C4BDD2",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                </div>
                                <div>
                                {removeSelected(questionData.rawResponse, questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>

                                </>
                                    }

                                    {questionResponses && (!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ||  questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") &&
                                        <>{questionData.rawResponse?.map((item) =>
                                        item !== "unanswered"? (
                                           <div
                                               className={`questionPanelAnswersText resultAnimation`}
                                               style={{
                                                   marginTop: "20px",
                                                   backgroundColor: "#CACACA",
                                                   color: "#000", borderRadius: "10px",
                                                   width: "fit-content",
                                                   padding: "15px",
                                               }}
                                           >
                                               {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                           </div>
                                       ) : <></> )} </>
                                    
                                    }
                                </Col>
                            </>
                        </div>
                        </>
                    }
                    </div>
                </>
                }

                {!isMobile &&
                <>
                <div className={pollType !== POLL_TYPE.SURVEY && questionData.questionType !== QUESTION_TYPES.SA.name ? "class-result-container" : "class-result-survey-container"} style={{marginLeft: "18px"}}>

                <div style={{display: "flex", justifyContent:  "center"}}>
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ParticipantsIcon />
                                </div>
                                <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {PARTICIPANT_TEXT} </div>
                            </div>
                            <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {participantCount} </div>
                        </div>
                    </div>
                    </div> 

                    <div style={{display: "flex", justifyContent:  "center"}}>
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ResponsesIcon />
                                </div>
                                <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {RESPONSE_TEXT} </div>
                            </div>
                            <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {responsesCount} </div>
                        </div>
                    </div>
                    </div>

                    <div style={{display: "flex", justifyContent:  "center"}}>
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <CompletionRateIcon />
                                <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {COMPLETION_RATE_TEXT} </div>
                            </div>
                            <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {completionRate} {completionRate !== "-" ? "%" : ""} </div>
                        </div>
                    </div>
                    </div>

                    { pollType !== POLL_TYPE.SURVEY && questionData.questionType !== QUESTION_TYPES.SA.name &&
                    <>
                    <div style={{display: "flex", justifyContent:  "center"}}>
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <CorrectIcon />
                                <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {CORRECT_ANSWER_TEXT} </div>
                            </div>
                            <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {correctCount} </div>
                        </div>
                    </div>
                    </div>

                    <div style={{display: "flex", justifyContent:  "center"}}>
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <WrongIcon />
                                </div>
                                <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {WRONG_ANSWER_TEXT} </div>
                            </div>
                            <div tabIndex={0} role={TEXTBOX}  style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {incorrectCount} </div>
                        </div>
                    </div>
                    </div> 
                    </>
                    }



                </div>

                <div style={{ width: "calc(100% - 36px)", borderRadius: "8px", background: "#FFF", boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)", marginTop: "30px", minHeight: "385px", padding: "22px", marginLeft: "18px", marginRight: "18px"}}>
                    <div className="header-board" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <div tabIndex={0} role={TEXTBOX} className="cur-question-title" style={{ fontSize: "18px" }}>Question {selectedQuestion.serialNo}</div>
                        {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name && (
                            <>
                                <ArrowRight />
                                <p tabIndex={0} aria-label={QUESTION_TITLE + question.queTitle} className="ques-desc" style={{ lineHeight: "normal", color: "#000", order: "unset", marginTop: "0px", flexShrink: "1"}}>
                                    {selectedQuestion.queTitle}
                                </p>
                            </>
                        )}
                        <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1" }}>
                            <span className="gradientWrapQuesType" style={{ height: "45px", width:"150px" }}>
                                <span className="questionPanelQuestionTypeContainer" style={{ padding: "0px" }}>
                                    <div tabIndex={0} role={TEXTBOX} className="questionPanelQuestionType" style={{ fontSize: "14px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center" }}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                </span>
                            </span>
                        </div>
                    </div>

                    {questionData.questionType === QUESTION_TYPES.FITB.name && <p tabIndex={0} aria-label={queTitle.replace(BLANK_REGEX, FITB_NARRATOR)} className="ques-title" style={{whiteSpace: "pre-line", wordBreak:"break-word", order: "initial"}}>{questionData.queTitle.replace(BLANK_REGEX, "______")}</p>}
                        
                    <div style={{display: "flex"}}> 

                    {(
                        questionData.questionType !== QUESTION_TYPES.WC.name && questionData.questionType !== QUESTION_TYPES.SA.name &&
                        questionData.questionType !== QUESTION_TYPES.FITB.name) && (
                        <div style={{width: "50%"}}>
                            <ClassResultChart
                                correctAnswers={questionData.correctAnswers}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                numOptions={questionData.questionType === QUESTION_TYPES.MCSS.name || questionData.questionType === QUESTION_TYPES.CI.name
                                    ? questionData.questionResults.length : 3}
                                pollType={pollType}
                                showAnswerOnViewer={showAnswerOnViewer}
                                queResponse={questionResponses ? questionResponses.questions[selectedQuestion.serialNo - 1] : []}
                                question={selectedQuestion}
                            />
                        </div>
                    )}

                    {/* {(!dataReady &&
                        questionData.questionType !== QUESTION_TYPES.WC.name && questionData.questionType !== QUESTION_TYPES.SA.name &&
                        !(pollType === POLL_TYPE.SURVEY && (questionData.questionType === QUESTION_TYPES.FITB.name))) && (
                        <div className="loading-text-back">
                            <p tabIndex={0} className="loading-text">Class Results Loading...</p>
                        </div>
                    )} */}

                    {questionData.questionType === QUESTION_TYPES.WC.name && (
                        <div style={{width: "50%"}}>
                        <WordCloudResult
                            data={questionData.rawResponse}
                            // pollType={pollType}
                            // correctAnswers={correctAnswers}
                            isSwitching={isSwitching}
                            setIsSWitching={setIsSWitching}
                        />
                        </div> 
                    )}

                    {((questionData.questionType === QUESTION_TYPES.SA.name) &&
                    <>
                        <div style={{width: "50%" }}>
                            <>
                                <Col style={{maxHeight:"300px", overflowY: "auto"}}>
                                    {dataReady && questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" && 
                                    <>
                                    <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#C4BDD2",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                        {`"${questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                        </div> 
                                    
                                    {removeSelected(questionData.rawResponse,questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            tabIndex={0} 
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#CACACA",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                    </> 
                                 }

                                { questionResponses && (!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ||  questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") &&
                                  <>{questionData.rawResponse?.map((item) =>
                                    item !== "unanswered" ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px"
                                        }}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )}</>
                                 }
                                </Col>
                            </>
                        </div>

                        {/* {!isNarrow ?
                        <>
                            {questionData.questionImageURL && (
                                <div className="img-container" style={{ display: 'flex', justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px" }}>
                                    <img className="img-class-result" src={questionData.questionImageURL} alt="Question Image" />
                                </div>
                            )}
                        </> : <></>
                    } */}
                    </>
                    )}

                    {questionData.questionType === QUESTION_TYPES.FITB.name &&
                    <>
                        <div style={{width:  "50%"}}>
                            <>
                                <Col style={{maxHeight:"300px", overflowY: "auto"}}>
                                {dataReady && questionResponses && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" && 
                                <>
                                 <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#C4BDD2",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                </div>
                                <div>
                                {removeSelected(questionData.rawResponse, questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>

                                </>
                                    }

                                    {questionResponses && (!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ||  questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                        <>{questionData.rawResponse?.map((item) =>
                                        item !== "unanswered"? (
                                           <div
                                               className={`questionPanelAnswersText resultAnimation`}
                                               style={{
                                                   marginTop: "20px",
                                                   backgroundColor: "#CACACA",
                                                   color: "#000", borderRadius: "10px",
                                                   width: "fit-content",
                                                   padding: "15px",
                                               }}
                                           >
                                               {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                           </div>
                                       ) : <></> )} </>
                                    
                                    }
                                </Col>
                            </>
                        </div>
                        {/* {!isNarrow ?
                                    <>
                                        {questionData.questionImageURL && (
                                            <div className="img-container" style={{display: 'flex',  justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px"}}>
                                                <img className="img-class-result" src={questionData.questionImageURL} alt="Question" />
                                            </div>
                                        )}
                                    </> : <></>
                        } */}
                        </>
                    }

                    {questionData.questionImageURL && questionData.questionType !== QUESTION_TYPES.CI.name && (
                        <div className="img-container" style={{display: 'flex',  justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px"}}>
                            {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                            {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                        </div>
                    )}

                    {questionData.questionImageURL && questionData.questionType === QUESTION_TYPES.CI.name && (
                        <div className="img-container" style={{display: 'flex',  justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px"}}>
                            <div style={{position: "relative"}}>
                                <img ref={imageRef} className="img-class-result" src={questionData.questionImageURL} tabIndex={0} alt="Question Image" onLoad={onLoad} />
                                {isImgLoaded && questionResponses !== null && imageRef.current !== null
                                    && !!questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption
                                    && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("x")
                                    && questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("y")
                                    &&
                                    <COORDINATE
                                        style={{
                                            position: "absolute",
                                            top: `${decodeCIParam(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                            left: `${decodeCIParam(questionResponses.questions[selectedQuestion.serialNo - 1].selectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    )}
                    </div>
                </div> 
                </>
                }

                {/* <NormalBox className="question-box">
                    {!isNarrow && <div className="header-board-question">
                        <div className="ques-name">Question {serialNo} {pollType === POLL_TYPE.SURVEY ? <></> : <p className="ques-pts"> - {weightage} points</p>}</div>
                        <span className="questionShareCountContainer">
                            <span className="questionShareCountTextCont">
                                <div className="questionShareCountBorder">Total Shared: {quesShareCount}</div>
                            </span>
                        </span>
                    </div>}
                    {isNarrow && <div className="header-board-question">
                        <div className="ques-name">Question {serialNo} {pollType === POLL_TYPE.SURVEY ? <></> : <p className="ques-pts"> - {weightage} points</p>}</div>
                        <div className="ques-name">Total Shared: {quesShareCount}</div>
                    </div>}

                    <p className="ques-title" style={{whiteSpace: "pre-line", wordBreak:"break-word"}}>{questionType === QUESTION_TYPES.FITB.name ? queTitle.replace(BLANK_REGEX, "______") : queTitle}</p>

                    {questionImageURL && questionType !== QUESTION_TYPES.CI.name && (isNarrow || (questionType !== QUESTION_TYPES.SA.name && questionType !== QUESTION_TYPES.FITB.name)) && (
                        <div className="img-container" style={{marginRight: 0}}>
                            <img className="img-class-result" src={questionImageURL} alt="Question" />
                        </div>
                    )}

                    {questionImageURL && questionType === QUESTION_TYPES.CI.name && (
                        <div className="img-container" style={{marginRight: 0}}>
                            <div style={{position: "relative"}}>
                                <img ref={imageRef} className="img-class-result" src={questionImageURL} alt="Question" onLoad={onLoad} />
                                {isImgLoaded
                                    && !!questionResponse.selectedOption
                                    && questionResponse.selectedOption.hasOwnProperty("x")
                                    && questionResponse.selectedOption.hasOwnProperty("y")
                                    &&
                                    <COORDINATE
                                        style={{
                                            position: "absolute",
                                            top: `${decodeCIParam(questionResponse.selectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                            left: `${decodeCIParam(questionResponse.selectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    )}

                    {(dataReady &&
                        questionType !== QUESTION_TYPES.WC.name &&
                        !(pollType === POLL_TYPE.SURVEY && (questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.FITB.name))) && (
                        <>
                            <ClassResultChart
                                correctAnswers={correctAnswers}
                                chartData={pollResults}
                                questionType={questionType}
                                numOptions={questionType === QUESTION_TYPES.MCSS.name || questionType === QUESTION_TYPES.CI.name
                                    ? pollResults.length : 3}
                                pollType={pollType}
                                showAnswerOnViewer={showAnswerOnViewer}
                                queResponse={questionResponse}
                                question={question}
                            />
                        </>
                    )}

                    {(!dataReady &&
                        questionType !== QUESTION_TYPES.WC.name &&
                        !(pollType === POLL_TYPE.SURVEY && (questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.FITB.name))) && (
                        <div className="loading-text-back">
                            <p className="loading-text">Class Results Loading...</p>
                        </div>
                    )}

                    {questionType === QUESTION_TYPES.WC.name && (
                        <WordCloudResult
                            data={questionResult}
                            // pollType={pollType}
                            // correctAnswers={correctAnswers}
                        />
                    )}

                    {(pollType === POLL_TYPE.SURVEY && (questionType === QUESTION_TYPES.SA.name) &&
                        <Container width={"100%"}>
                            <Row>
                                <Col style={{maxHeight:"300px", overflowY: "auto"}}>
                                    {questionResult?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#f4f4f4",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                </Col>
                                {!isNarrow ?
                                    <Col>
                                        {questionImageURL && (
                                            <div className="img-container" style={{marginRight: 0}}>
                                                <img className="img-class-result" src={questionImageURL} alt="Question" />
                                            </div>
                                        )}
                                    </Col> : <></>
                                }
                            </Row>
                        </Container>
                    )}

                    {(pollType === POLL_TYPE.SURVEY && (questionType === QUESTION_TYPES.FITB.name)) &&
                        <ResponsiveContainer width={"100%"}>
                            <Row>
                                <Col style={{maxHeight:"300px", overflowY: "auto"}}>
                                <div>
                                {questionResult?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#f4f4f4",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>
                                </Col>
                                {!isNarrow ?
                                    <Col>
                                        {questionImageURL && (
                                            <div className="img-container" style={{marginRight: 0}}>
                                                <img className="img-class-result" src={questionImageURL} alt="Question" />
                                            </div>
                                        )}
                                    </Col> : <></>
                                }
                            </Row>
                        </ResponsiveContainer>
                    }
                </NormalBox> */}
                <div className={"footer-container"} style={{justifyContent: "space-between" , backgroundColor: "#FCFBFF", width: "calc(100% - 36px)", marginLeft: "18px", padding: "0px", marginTop: "20px"}} >
                    {/* {pollType === POLL_TYPE.GRADED_POLL && questionData.questionType === QUESTION_TYPES.FITB.name && !isMobile &&
                    <YuJaButton tabIndex={0} role={BUTTON} style={{display: 'flex', alignSelf: 'flex-start', gap: "8px", cursor: "pointer", fontSize: "14px"}} onClick={() => {setModalShow(true)}}>
                        <SeeResponses /> <div style={{marginLeft: "8px"}}> See Responses </div>
                    </YuJaButton>
                    }

                    {pollType === POLL_TYPE.GRADED_POLL && questionData.questionType === QUESTION_TYPES.FITB.name && isMobile &&
                    <div tabIndex={0} role={BUTTON} style={{display: 'flex', alignSelf: 'flex-start', gap: "8px", cursor: "pointer", alignItems: "center", fontSize: "14px", width: "100%"}} onClick={() => {setModalShow(true)}}>
                        <SeeResponsesMobile /> <div style={{marginLeft: "8px"}}> See Responses </div>  <div style={{flex: "1", display: "flex", justifyContent: "end"}}><RightArrow /> </div>
                    </div>
                    } */}
                    {/* <div style={{display: 'flex', marginLeft: 10, alignSelf: 'flex-end'}}>
                        <div className="right-footer-title">
                            Unanswered
                        </div>
                        <div className="right-footer-spacing" style={{marginLeft: 20}}/>
                        <div className="right-footer-body">
                            {unanswered.current}
                        </div>
                    </div> */}
                </div>


                {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                    <Countdown className="hide" date={classResultEndTime} onComplete={() => handleClassResultComplete()}/>
                }

                <div style={{flex: 1}}/>
        </div>
    );
}