import { React, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";
import { removePrefixNew } from "../../utils/helpers";
import styles from "./DashboardPage.module.css";
import { ReactComponent as RecentResultIcon } from "./images/gradient_table_icon.svg";
import { ReactComponent as NoResultIllustration } from "./images/no_recent_results_found_illustration.svg";
import {YuJaKebabMenu} from "../../components/standardization/YuJaKebabMenu";
import { ACTIONS_LABEL, POLL_TITLE_LABEL, TEXTBOX, TOTAL_QUESTIONS_LABEL, VIEW_GRADEBOOK } from "../../utils/constants";

export default function CreatorRecentResults({data=[], loading=true, initialized=false}) {
    const history = useHistory();
    const [selectedIndex, setSelectedIndex] = useState([]);

    const viewResult = (poll) => {
        const {pollKey, uniqueCodePK} = poll;
        history.push({
            pathname: "/gradebook",
            state : {
                pollKey : removePrefixNew(pollKey),
                uniqueCode: removePrefixNew(uniqueCodePK), 
                option: poll 
            }
        });
    }

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    return (
        <div className={styles.panelContainer} style={{height: "56%"}}>
            <div className={styles.panelTitle}>
                <RecentResultIcon style={{marginRight: "15px"}}/>
                <div className={styles.panelSubTitle}>
                    <div role={TEXTBOX} tabIndex={0}>Recent Results</div>
                    <div role={TEXTBOX} tabIndex={0} style={{fontSize: "13px"}}>View polls that students have recently completed</div>
                </div>
            </div>
            {!loading ?
            <div className={styles.panelBody}>
                {/* <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}> */}
                    {initialized && (!data || !data.length) &&
                        <>
                            <NoResultIllustration style={{maxWidth: "100%", maxHeight: "100%"}}/>
                            <span role={TEXTBOX} tabIndex={0}>No recent results found</span>
                        </>
                    }
                    {initialized && (!!data && !!data.length) &&
                        <Table responsive={true} className={styles.recentResultTable} >
                            <thead>
                                <tr>
                                    <th tabIndex={0} role={TEXTBOX} style={{width: "50%", textAlign: "left"}}>Poll Title</th>
                                    <th tabIndex={0} role={TEXTBOX} style={{width: "20%"}}>Total Questions</th>
                                    <th tabIndex={0} role={TEXTBOX} style={{width: "30%"}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody >
                            {JSON.parse(JSON.stringify(data)).splice(0, 5).map((poll, index) =>
                                <tr key={index} role={"row"}>
                                    <td tabIndex={0} role={TEXTBOX} aria-label={POLL_TITLE_LABEL + poll.pollTitle} className={styles.omitableText} style={{textAlign: "left"}}>{poll.pollTitle}</td>
                                    <td tabIndex={0} role={TEXTBOX} aria-label={TOTAL_QUESTIONS_LABEL + poll.pollTitle + " " +poll.totalQuestions} >{poll.totalQuestions}</td>
                                    <td tabIndex={0} role={TEXTBOX} aria-label={ACTIONS_LABEL + poll.pollTitle}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <YuJaKebabMenu>
                                                <YuJaKebabMenu.Menu>
                                                    <YuJaKebabMenu.Item tabIndex={0} label={VIEW_GRADEBOOK + poll.pollTitle} onClick={() => viewResult(poll)}>View GradeBook</YuJaKebabMenu.Item>
                                                </YuJaKebabMenu.Menu>
                                            </YuJaKebabMenu>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    }
                {/* </Spin> */}

            </div>
            : windowSize[1] >= 900 ?
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "60% 20% 20%"}}> 
            <Skeleton height = {14.56005859375} width = "6vw" style = {{position: "relative", marginTop:"35px"}} /> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"35px"}} />
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"35px"}} />
            </div>
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "62% 20.5% 17.5%"}}>
            <Skeleton height = {14.56005859375} width = "8vw"  style={{ position: "relative", marginTop:"2vh"}}/> 
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}}  />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"2vh"}}/>
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"2vh"}}/>
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {14.56005859375}  width = "6.5vw" style={{ position: "relative", marginTop:"2vh"}}/>
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {14.56005859375}  width = "6vw" style={{ position: "relative", marginTop:"2vh"}}/>
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            </div>
            </SkeletonTheme>
            : windowSize[1] >= 780 ?
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "60% 20% 20%"}}> 
            <Skeleton height = {14.56005859375} width = "6vw" style = {{position: "relative", marginTop:"35px"}} /> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"35px"}} />
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"35px"}} />
            </div>
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "62% 20.5% 17.5%"}}>
            <Skeleton height = {14.56005859375} width = "8vw"  style={{ position: "relative", marginTop:"2vh"}}/> 
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}}  />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"2vh"}}/>
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"2vh"}}/>
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {14.56005859375}  width = "6.5vw" style={{ position: "relative", marginTop:"2vh"}}/>
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            </div>
            </SkeletonTheme>
            
            : <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "60% 20% 20%"}}> 
            <Skeleton height = {14.56005859375} width = "6vw" style = {{position: "relative", marginTop:"35px"}} /> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"35px"}} />
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"35px"}} />
            </div>
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "62% 20.5% 17.5%"}}>
            <Skeleton height = {14.56005859375} width = "8vw"  style={{ position: "relative", marginTop:"2vh"}}/> 
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}}  />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"2vh"}}/>
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"2vh"}}/>
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            <Skeleton height = {10} width = "1.5vw" style = {{position: "relative", marginTop:"2vh"}} />
            </div>
            </SkeletonTheme>}
        </div>
    );
}