import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
// import { FcMenu } from "react-icons/fc";
import { LinkContainer } from "react-router-bootstrap";
import { AuthenticationApiCalls } from "../apis/AuthenticationApiCalls";
import ResponsiveSidebar from "../components/ResponsiveSidebar";
import { useCookie } from "../hooks/useCookie";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import useMobileAccess from "../hooks/useMobileAccess";
import dropdownArrow from "../images/dropdown_arrow_white.svg";
import { ReactComponent as YuJaLogo } from "../images/YuJa_Logo.svg";
import { ReactComponent as NavbarArrow } from "../images/navbar_arrow.svg";
import {
  BUTTON,
  HOME_ICON,
  HOME_PATH,
  IDENTITY_PROVIDER_TYPES,
   NAVBAR_ENGAGE, OPEN_IN_NEW_TAB
} from "../utils/constants";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as UserProfile } from "../images/links/user_profile_icon.svg";
import { ReactComponent as NewWindowIcon} from "../images/links/new_window.svg";
import { ReactComponent as NewWindowIconBlue} from "../images/links/new_window_blue.svg";
import "./styles.css";
import layoutStyles from "../layouts/Layout.module.css";
import { newPageLog } from "../utils/helpers";
import NewSidebar from "./NewSidebar";
import { YuJaTooltip } from "./standardization/YuJaTooltip.js";

function NavBarUnit({ children, showCourse = false }) {
  return (
    <div className={showCourse ? "navbar-unit active" : "navbar-unit"}>
      <span className="navDropdownText" style={{display: "flex", alignItems: "center"}}>{children}</span>
      <img src={dropdownArrow} alt="Dropdown arrow" />
    </div>
  );
}

export default function YuJaNavbar({isViewer=false, isCreator=false, sidebarCollapsed}) {
  const [, getSession] = useLocalStorageNew("session", {});
  const [, getCookie] = useCookie("name", "Guest");
  const session = getSession();
  const { logout } = AuthenticationApiCalls();
  const anonymous = !session.userName;
  // const userName = anonymous ? getCookie() : session.userName;
  var userName;
  if (anonymous) {
    userName = getCookie();
  } else {
    if (session.firstName && session.lastName) {
      userName = session.firstName + " " + session.lastName;
    } else if (session.firstName) {
      userName = session.firstName;
    } else {
      userName = session.userName;
    }
  }

  var names = userName.split(" ");
  var initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  const isNarrow = useMobileAccess(1200);
  const [collapsed, setCollapsed] = useState(false);
  const [showLogoutBtn, setShowLogoutBtn] = useState(!!session.userName);


  // const sideBarToggle = {
  //   cursor: "pointer",
  //   margin: "10px",
  // };

  useEffect(() => {
    newPageLog("ViewerNavbar.js");
    if(session.idpType && session.idpType === IDENTITY_PROVIDER_TYPES.LMS && window.location !== window.parent.location) {
        setShowLogoutBtn(false);
    }
}, []);

  const sideNavbarStyle = {
    position: "absolute",
    left: "0px",
    top: "0px",
    minWidth: isNarrow ? "240px" : "60px",
    zIndex: 2,
    overflowX: "hidden",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.5)",
    height: "100vh",
  };


  return (
    <div id="viewer-navbar-container" style={{ wordBreak: "break-word"}}>
      <Container className="max-width sticky-top" id="navBar" style={{height: isNarrow ? "75px" : "", padding: 0}}>
          <Navbar id={isNarrow ? "viewer-navbar" : "first-navbar"}>
            <Container fluid id="first-nav-container">
              { !isNarrow &&
                  <>
                    {!session.gradedLink &&
                        <LinkContainer to={HOME_PATH}>
                          <Navbar.Brand className="p-2 logo-icon navbar-engage" aria-label={HOME_ICON}>
                            <YuJaLogo />
                            {sidebarCollapsed && <h1 className="navbar-text" style={{marginLeft: "8px", marginBottom: "0px"}}>{NAVBAR_ENGAGE}</h1>}
                            {sidebarCollapsed && <NavbarArrow style={{margin: "8px", marginRight: "0px"}} /> }
                            <h1 id="engagePageIdentifier" className="navbar-text" style={{marginLeft: "8px", marginBottom: "0px"}}></h1>
                          </Navbar.Brand>
                        </LinkContainer>
                    }
                    {!!session.gradedLink &&
                          <Navbar.Brand className="p-2 logo-icon navbar-engage" aria-label={HOME_ICON}>
                            <YuJaLogo />
                            {sidebarCollapsed && <h1 className="navbar-text" style={{marginLeft: "8px", marginBottom: "0px"}}>{NAVBAR_ENGAGE}</h1>}
                            {sidebarCollapsed && <NavbarArrow style={{margin: "8px", marginRight: "0px"}} /> }
                            <h1 id="engagePageIdentifier" className="navbar-text" style={{marginLeft: "8px", marginBottom: "0px"}}></h1>
                          </Navbar.Brand>
                    }
                  </>

              }
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  {isNarrow ?
                    <>
                      <ResponsiveSidebar
                        menuClassName="mobile-menu-modified"
                        className={`${layoutStyles.LeftSideBarContainer}`}
                        sidebarStyle={sideNavbarStyle}
                        // setShowSideBar={setShowSideBar}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                      >
                        <NewSidebar sideBarCollapseAble={false} isViewer={isViewer} isCreator={isCreator} isMobile={true} collapsed={collapsed} setCollapsed={setCollapsed}/>
                      </ResponsiveSidebar>
                    </> :
                    <>
                    </>
                  }
                </Nav>
              </Navbar.Collapse>
              <Nav className="justify-content-end">
                {window.location !== window.parent.location && <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    {isNarrow && 
                      <YuJaTooltip text={OPEN_IN_NEW_TAB} placement="bottom">
                        <NewWindowIconBlue aria-label={OPEN_IN_NEW_TAB} role={BUTTON} style={{width: "33px", height: "33px", cursor: "pointer", marginRight: "8px"}} onClick={() => {
                          const engageSession = encodeURIComponent(JSON.stringify(getSession()));
                          const dest = encodeURIComponent(window.location.pathname);
                          window.open(`/portal?engageSession=${engageSession}&dest=${dest}`);
                        }}/>
                      </YuJaTooltip>
                    }
                    {!isNarrow && 
                      <YuJaTooltip text={OPEN_IN_NEW_TAB} placement="bottom">
                        <NewWindowIcon aria-label={OPEN_IN_NEW_TAB} role={BUTTON} style={{width: "33px", height: "33px", cursor: "pointer", marginRight: "8px"}} onClick={() => {
                          const engageSession = encodeURIComponent(JSON.stringify(getSession()));
                          const dest = encodeURIComponent(window.location.pathname);
                          window.open(`/portal?engageSession=${engageSession}&dest=${dest}`);
                        }}
                      />
                      </YuJaTooltip>
                    }
                </div>}
                {isNarrow ? <div className="viewerNavBarUserName">{initials}</div> :
                  <>
                  {(anonymous || !showLogoutBtn) ? 
                      <div className="navBarUserName">{userName}</div> : <>
                      <NavDropdown
                          style={{minWidth: "85px"}}
                          title={(
                              <NavBarUnit tabIndex={0}>
                                  <UserProfile style={{marginRight: "8px"}}/> <div style={{textAlign: "center"}}>{userName} </div>
                              </NavBarUnit>
                          )}
                      >
                          <NavDropdown.Item tabIndex={0} onClick={logout} style={{fontFamily: "Geomanist", fontSize: "15px", display: "flex"}}><FontAwesomeIcon icon={faPowerOff} style={{fontSize: "1.4em", marginRight: "10px"}} />Logout</NavDropdown.Item>
                      </NavDropdown>
                  </>}
                  </>
                }
              </Nav>
            </Container>
          </Navbar>
      </Container>
    </div>
  );
}
