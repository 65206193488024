import { Spin } from "../../components/standardization/YuJaLoading";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
    ATTEMPTS_BOX,
    BUTTON,
    CLOSE_GRADE_MODAL,
    CONFIGURE_GRADEBOOK_OPTIONS_TEXT,
    CUSTOM_MAX_ATTEMPTS_TEXT,
    LMS_ATTEMPT_TITLE,
    MAX_ATTEMPTS_TITLE,
    MODAL_CANCEL_TEXT,
    MODAL_SAVE_TEXT,
    RADIAL_LABEL,
    RECORD_ATTEMPT,
    RECORD_ATTEMPT_TITLE,
    SYNC_ATTEMPT,
    TEXTBOX,
    UNLIMITED_MAX_ATTEMPTS_TEXT
} from "../../utils/constants";
import LMSQuizAttemptOption from "../buttons/LMSQuizAttemptOption";
import RecordAttemptOption from "../buttons/RecordAttemptOption.js";
import "./modal.css";
import YuJaButton from "../standardization/YuJaButton";
import {YuJaRadioBox} from "../standardization/YuJaRadiobox";
import {YuJaCheckbox} from "../standardization/YuJaCheckbox";
import {YuJaTextBox} from "../standardization/YuJaTextBox";

export default function GradebookOptionsModal({
    show,
    setShow,
    recordAttempt,
    setRecordAttempt,
    lmsAttempt,
    setLMSAttempt,
    maxAttempts,
    setMaxAttempts
}) {
    const { loading } = useLoading();
    const [customRecordAttempt, setCustomRecordAttempt] = useState(null);
    const [customLMSAttempt, setCustomLMSAttempt] = useState(null);
    const [customMaxAttempts, setCustomMaxAttempts] = useState(null);
    const recordAttemptOptions = useRef([ RECORD_ATTEMPT.ALL, RECORD_ATTEMPT.RECENT ]);
    const LMSQuizAttemptOptions = useRef([ SYNC_ATTEMPT.FIRST, SYNC_ATTEMPT.RECENT, SYNC_ATTEMPT.HIGHEST ]);

    const handleRecordAttemptOptionClick = (value) => {
        // console.log("Custom Record", value);
        setCustomRecordAttempt(value);
        if (value === RECORD_ATTEMPT.RECENT.value) {
            setCustomLMSAttempt(SYNC_ATTEMPT.RECENT.value); // Set LMS Attempt to "Most Recent" if Record Attempt is set to "Most Recent"
        } else if (value === RECORD_ATTEMPT.ALL.value) {
            setCustomLMSAttempt(lmsAttempt); // Set LMS Attempt to the previous value if Record Attempt is set to "All"
        }
    };

    const handleLMSAttemptOptionClick = (value) => {
        // console.log("Custom LMS", value);
        if (customRecordAttempt === RECORD_ATTEMPT.RECENT.value) {
            return; // Do not allow change in LMS options if Record Attempt is set to "Most Recent"
        }
        setCustomLMSAttempt(value);
    };

    const onChangeCustomMaxAttempts = (e) => {
        let a = e.target.value.replace(/[^0-9]/g, '');
        // console.log(a);
        setCustomMaxAttempts(a);
    };
    
    const onBlurCustomMaxAttempts = (e) => {
        // console.log(e.target.value);
        if (e.target.value === "" || parseInt(e.target.value) < 1) {
            setCustomMaxAttempts(1);
        } else {
            setCustomMaxAttempts(parseInt(e.target.value));
        }
    };

    const handleClose = () => {
        setShow(false);
        setCustomRecordAttempt(recordAttempt);
        setCustomLMSAttempt(lmsAttempt);
        setCustomMaxAttempts(maxAttempts);
    };

    const handleSave = async () => {
        console.log("Save", customRecordAttempt, customLMSAttempt, customMaxAttempts);
        setRecordAttempt(customRecordAttempt);
        setLMSAttempt(customLMSAttempt);
        setMaxAttempts(customMaxAttempts);
        setShow(false);
    };

    useEffect(() => {
        setCustomRecordAttempt(recordAttempt);
        setCustomLMSAttempt(lmsAttempt);
        setCustomMaxAttempts(maxAttempts);
    }, [recordAttempt, lmsAttempt, maxAttempts]);

    return (
    <Modal
        id="gradebook-config-modal"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop={loading ? "static" : true}
        dialogClassName="sa-modal"
    >
        <Spin tip="Loading..." size="large" spinning={loading}>
            <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_GRADE_MODAL} onClick={handleClose} className="modal-close" />
            <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Modal.Title tabIndex={0} role={TEXTBOX}>
                    {CONFIGURE_GRADEBOOK_OPTIONS_TEXT}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="gradebookOptionsRow">
                    <Col sm={4} tabIndex={0} role={TEXTBOX} className="gradebookOptionTitle">
                        {RECORD_ATTEMPT_TITLE}
                    </Col>
                    <Col sm={8} className="gradebookOptionConfigure">
                        {Object.entries(recordAttemptOptions.current).map((optionData, index) => {
                            const {value, label} = optionData[1];
                            return (
                                <div key={index} style={{padding: "0.5vh 0px"}}>
                                    <YuJaRadioBox
                                        aria-label={label + RADIAL_LABEL + (value === customRecordAttempt ? "selected" : "unselected")}
                                        label={label}
                                        checked={value === customRecordAttempt}
                                        onClick={() => handleRecordAttemptOptionClick(value)}
                                    />
                                </div>
                            );
                        })}
                    </Col>
                </Row>

                <hr/>

                <Row className="gradebookOptionsRow">
                    <Col sm={4} tabIndex={0} role={TEXTBOX} className="gradebookOptionTitle">
                        {LMS_ATTEMPT_TITLE}
                    </Col>
                    <Col sm={8} className="gradebookOptionConfigure">
                        {Object.entries(LMSQuizAttemptOptions.current).map((optionData, index) => {
                            const {value, label} = optionData[1];
                            return (
                                <div key={index} style={{padding: "0.5vh 0px"}}>
                                    <YuJaRadioBox
                                        aria-label={label + RADIAL_LABEL + (value === customLMSAttempt ? "selected" : "unselected")}
                                        label={label}
                                        checked={value === customLMSAttempt}
                                        onClick={() => handleLMSAttemptOptionClick(value)}
                                        disabled={customRecordAttempt === RECORD_ATTEMPT.RECENT.value}
                                    />
                                </div>
                            );
                        })}
                    </Col>
                </Row>
 
                <hr/>

               <Row className="gradebookOptionsRow">
                    <Col sm={4} tabIndex={0} role={TEXTBOX} className="gradebookOptionTitle">
                        {MAX_ATTEMPTS_TITLE}
                    </Col>
                    <Col sm={8} className="gradebookOptionConfigure">
                        <div
                            // className="attemptsOption margin-vertical-auto"
                            style={{padding: "0.5vh 0px"}}
                        >
                            <Row  style={{margin: '0px'}}>
                                <Col className="gradebook-option-radio-col">
                                    <YuJaCheckbox
                                        size={20}
                                        ariaLabel={ATTEMPTS_BOX + (customMaxAttempts === -1 ? "checked" : "unchecked")}
                                        label={UNLIMITED_MAX_ATTEMPTS_TEXT}
                                        checked={customMaxAttempts === -1}
                                        onClick={(e) => {
                                            if (e.target.checked) {
                                                setCustomMaxAttempts(-1);
                                            } else {
                                                setCustomMaxAttempts(1);
                                            }
                                        }}
                                    />

                                </Col>
                            </Row>
                        </div>
                        {customMaxAttempts !== -1 && (
                            <div style={{display: "flex", alignItems: "center"}}>
                                <YuJaTextBox
                                    placeholder={"1"}
                                    value={customMaxAttempts}
                                    onChange={onChangeCustomMaxAttempts}
                                    onBlur={onBlurCustomMaxAttempts}
                                    inputAlign={"center"}
                                    containerStyle={{width: 35, marginRight: 10, fontSize: 13, padding: 6}}
                                />
                                <div className="attemptsOptionText">{CUSTOM_MAX_ATTEMPTS_TEXT}</div>
                            </div>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton role={BUTTON} aria-label={CLOSE_GRADE_MODAL} type="secondary" onClick={handleClose}>
                    {MODAL_CANCEL_TEXT}
                </YuJaButton>
                <YuJaButton
                    onClick={(e) => {
                        e.preventDefault();
                        handleSave();
                    }}
                >
                    {MODAL_SAVE_TEXT}
                </YuJaButton>
            </Modal.Footer>
        </Spin>
    </Modal>
  );
}
