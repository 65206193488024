import { useEffect } from "react";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { useHistory } from "react-router-dom";

export default function OpenInNewTab () {
    const [setSession, ] = useLocalStorageNew("session", {});
    const history = useHistory();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.get('engageSession')) {
            const engageSession = JSON.parse(queryParams.get('engageSession'));
            setSession(engageSession);
        }
        if(queryParams.get('dest')) {
            const destinationURL = queryParams.get('dest');
            history.push(destinationURL);
        }
    }, []);

    return (
        <></>
    );
}
