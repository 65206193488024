import { Spin } from "../../components/standardization/YuJaLoading";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import { CourseApiCalls } from '../../apis/CourseApiCalls';
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import WarningModal from "../../components/modals/WarningModal";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import {
    CANNOT_ACCESS_POLL_ERROR,
    HOME_PATH,
    IDENTITY_PROVIDER_TYPES,
    INVALID_POLL_CODE_ERROR,
    POLL_STATE,
    POLL_TYPE,
    VIEWER_USER_TYPE,
    POLL_DISABLE_ANONYMOUS_WARNING,
    ANONYMOUS_JOIN_SETTING_TEXT,
    LOG_TYPES, POLL_SHARE_MODE, NETWORK_EFFECTIVE_TYPE, JOIN_POLL, JOIN_SURVEY, EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE, WEBSOCKET_STUDENT, PLATFORM_TYPES
} from "../../utils/constants";
import {
    formatErrorLog,
    getInstitutionId,
    newPageLog,
    notifyError,
    removePrefix,
    updateLogAttributes,
    updateLogger
} from "../../utils/helpers";
import { JOIN_LMS_POLL_ERROR } from "../../utils/toast-message-constants";
import {WSS_BASE_URL} from "../../utils/properties";
import JoinQuizPageQRNew from "./JoinQuizPageQRNew";
import {useJoinQuizWebsocketManager} from "../../hooks/useJoinQuizWebsocketManager";
import {YuJaGlobalState} from "../../utils/LoadingContext";
import {useWindowDimensions} from "react-native-web";
import { browserName, fullBrowserVersion, osName, osVersion, isAndroid} from 'react-device-detect';

export default function JoinQuizParentNew() {
    const windowSize = useWindowDimensions();
    const { pollUniqueCode } = useParams();
    const [param, setParam] = useState({});
    const {setMissedResponseMap, missedResponseMap, isCookieEnabled, missedReactionMap, setMissedReactionMap} = useContext(YuJaGlobalState);
    const { getPollRecordFromUniqueCode, getPollQuestions, getAudienceResponseByUser, reattemptPoll, handleSubmitIndividualResponse, handleSubmitMultiResponse, updateReaction } = ResponseApiCalls();
    const [visibility, setVisibility] = useState(null);
    const [, getTimeOffset] = useLocalStorageNew("timeOffset", 0);
    const [, getSession] = useLocalStorageNew("session", {});
    const [setGuestSession, getGuestSession] = useLocalStorageNew("guestSession", {});
    const [setMissedResponseStorage, getMissedResponseStorage] = useLocalStorageNew("missedResponseMap", {});
    const [setMissedReactionMapStorage, getMissedReactionMapStorage] = useLocalStorageNew("missedReactionMap", {});
    const [, getWarning] = useLocalStorageNew("warning", {});

    const pollInfoRef = useRef({});
    const completedResponsesRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [ pollClosed, setPollClosed] = useState(null);
    const history = useHistory();

    const { checkMemberAccess } = CourseApiCalls();
    const {getSetting} = InstitutionApiCalls();
    const session = getSession();

    const [warningModalShow, setWarningModalShow] = useState(false);
    const gradedLink = session.gradedLink;
    const idpType = session.idpType;
    const isYuJaAutoUser = session.isYuJaAuto;
    const [authenticationPassed, setAuthenticationPassed] = useState(false);


    const getWebSocketUrl = () => {
        if (!Object.entries(pollInfoRef.current).length) {
            return '';
        }

        const {pk} = pollInfoRef.current;
        const pollKey = removePrefix(pk);

        let browserType = browserName + " " + fullBrowserVersion;
        let platform = "";
        if(isAndroid) {
            platform = PLATFORM_TYPES.Android;
        } else {
            platform = osName + " " + osVersion;
        }
        let sessionFirstName = getSession().firstName ? getSession().firstName : "";
        let sessionLastName = getSession().lastName ? getSession().lastName : "";
        let sessionUserName = getSession().userName ? getSession().userName : getGuestSession()["$"+pollUniqueCode]?.userName;
        let sessionUserId = getSession().userId ? getSession().userId : getGuestSession()["$"+pollUniqueCode]?.userId;
        if(!!sessionFirstName && !!sessionLastName) {
            sessionUserName = sessionFirstName + " " + sessionLastName;
        }
        if(getGuestSession()["$"+pollUniqueCode]?.userType === VIEWER_USER_TYPE.AUTO_GUEST) {
            sessionUserName = "Guest";
        }
        let url;
        if(sessionUserId !== "") {
            url = `${WSS_BASE_URL}?pollKey=${pollKey}&pollCode=${pollUniqueCode}&userName=${sessionUserName}&userId=${sessionUserId}&role=${WEBSOCKET_STUDENT}&browserType=${browserType}&platform=${platform}`;
        } else {
            url = `${WSS_BASE_URL}?pollKey=${pollKey}&pollCode=${pollUniqueCode}&userName=${sessionUserName}&role=${WEBSOCKET_STUDENT}&browserType=${browserType}&platform=${platform}`;
        }

        return url;
    }


    const handleAfterWebsocketOpen = async () => {
        //1. resubmit missedResponses if require
        const missedResponseMapVal = isCookieEnabled ? getMissedResponseStorage() : missedResponseMap;
        if (!!missedResponseMapVal[pollUniqueCode] && !!Object.entries(missedResponseMapVal[pollUniqueCode]).length) {
            const {pollCode, pollKey, pollType, pollShareMode, response}= missedResponseMapVal[pollUniqueCode];
            //share-each
            if (pollShareMode === POLL_SHARE_MODE.SHARE_EACH) {
                await handleSubmitIndividualResponse(pollKey, pollCode, pollType, response);
                const copy = JSON.parse(JSON.stringify(missedResponseMapVal));
                delete copy[pollUniqueCode];
                if (isCookieEnabled) {
                    setMissedResponseStorage(copy);
                } else {
                    setMissedResponseMap(copy);
                }


            } else if (pollShareMode === POLL_SHARE_MODE.SHARE_ALL
                || pollShareMode === POLL_SHARE_MODE.SCHEDULE) { //share-all or schedule
                const {curQuestionNo, questions, responses, ...pollInfo} = missedResponseMapVal[pollUniqueCode];
                await handleSubmitMultiResponse(pollInfo, curQuestionNo, questions, responses)
                const copy = JSON.parse(JSON.stringify(missedResponseMapVal));
                delete copy[pollUniqueCode];
                if (isCookieEnabled) {
                    setMissedResponseStorage(copy);
                } else {
                    setMissedResponseMap(copy);
                }
            }
        }

        //2. re-update reaction if require
        const missedReactionMapVal = isCookieEnabled ? getMissedReactionMapStorage() : missedReactionMap;
        if (!!missedReactionMapVal[pollUniqueCode] && !!Object.entries(missedReactionMapVal[pollUniqueCode]).length) {
            const { pollKey, reactionMap, attemptsCount, currSerialNo}= missedReactionMapVal[pollUniqueCode];
            await updateReaction(pollKey, pollUniqueCode, reactionMap, attemptsCount, currSerialNo);
            const copy = JSON.parse(JSON.stringify(missedReactionMapVal));
            delete copy[pollUniqueCode];
            if (isCookieEnabled) {
                setMissedReactionMapStorage(copy);
            } else {
                setMissedReactionMap(copy);
            }
        }

        //3. re-get the poll information if poll is not ended
        if (pollClosed) {
            const {poll={}} = await getPollRecordFromUniqueCode(pollUniqueCode);

            //1. backward data compatible
            if (!!poll.uniqueCodeExpTime && Date.now() > poll.uniqueCodeExpTime) {
                return;
            }

            //2. poll is end
            let pollEnd = poll.liveQuestionTime + (poll.pollTimeLimit * 1000) + parseInt(getTimeOffset());
            if((poll.pollState === POLL_STATE.SHARED_ALL && pollEnd <= Date.now() && poll.pollType.toUpperCase() !== POLL_TYPE.SURVEY)
                || poll.pollState === POLL_STATE.STOPPED_ALL
            ) {
                return;
            }
        }
        joinPoll();
    }

    const {websocketMsg, websocketState, buildConnection, closeConnection} = useJoinQuizWebsocketManager(getWebSocketUrl, handleAfterWebsocketOpen);

    const Authentication = async () => {
        const result = await getPollRecordFromUniqueCode(pollUniqueCode);
        if(!result || !result.poll) {
            notifyError(INVALID_POLL_CODE_ERROR);
            return false;
        }

        pollInfoRef.current = result.poll ? result.poll : {};
        const {index2Pk: courseId, lmsHomeUrl} = result.poll;
        if (!!courseId) {
            //check if the poll is an LMS embedded poll and the viewer has been logged in from LMS
            if (!!lmsHomeUrl) {
                if (!gradedLink) {
                    formatErrorLog(EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE);
                    history.push(`/lti/${pollUniqueCode}/guide`);
                    return false;
                }
                return true;
            }


            const accessibleResult = await checkMemberAccess(courseId.substring(2));
            if (accessibleResult == null || accessibleResult.result == null || !accessibleResult.result) {
                notifyError(CANNOT_ACCESS_POLL_ERROR);
                if (session.authorization && session.userId) {
                    history.push(HOME_PATH);
                } else {
                    history.push("/qr/" + pollUniqueCode);
                }
                return false;
            }
        }


        if (pollInfoRef.current.hasOwnProperty(ANONYMOUS_JOIN_SETTING_TEXT) &&  pollInfoRef.current[ANONYMOUS_JOIN_SETTING_TEXT] === true && (!getSession().userId && !getGuestSession()["$"+pollUniqueCode]?.userName)) {
            notifyError(POLL_DISABLE_ANONYMOUS_WARNING);
            history.push(`/qr/${pollUniqueCode}`);
            return false;
        }

        return true;
    }

    const joinPoll = async (attemptsCount = 1) => {
        const now = new Date();
        const result = await getPollQuestions(pollUniqueCode, now);
        if (!result || !Object.entries(result).length) {
            return;
        }


        const {questionAnswer, userResponse, startTime, endTime} = result;
        const obj = { ...result };
        obj.questions.sort(function (a, b) {
            return a.serialNo - b.serialNo;
        });
        obj.liveQuestionTime = result.liveQuestionTime + parseInt(getTimeOffset());
        obj.liveQuestionResponse = questionAnswer;
        if (!!startTime) {
            obj.startTime = result.startTime;
        }
        if (!!endTime) {
            obj.endTime = result.endTime;
        }
        // if(obj.userType === VIEWER_USER_TYPE.MANUAL_GUEST) {
        obj.userName = obj.userId;
        // }

        let curQuestionNo;
        if (!!userResponse) {
            updateLogAttributes(userResponse);
            if (!isNaN(userResponse?.curQuestionNo)) curQuestionNo = parseInt(userResponse.curQuestionNo);
            if (!isNaN(userResponse?.attemptsCount) && attemptsCount === 1) attemptsCount = parseInt(userResponse.attemptsCount);
            obj.pollJoinTime = userResponse.pollJoinTime;
        }

        let questionNo;
        if (isNaN(curQuestionNo)) {
            questionNo = !!obj.liveQuestionResponse.length ? obj.liveQuestionResponse.length - 1 : 0;
        } else {
            questionNo = !!curQuestionNo ? curQuestionNo - 1 : 0;
        }
        obj.attemptsCount = attemptsCount;
        obj.pollQuestionIdx = questionNo;
        obj.institutionId = getInstitutionId(result.index5Pk);
        // console.log(obj);
        setParam(obj);
        if (pollClosed) {
            setPollClosed(false);
        }
        const guestSession = getGuestSession();
        guestSession["$"+pollUniqueCode] = {
            ...guestSession["$"+pollUniqueCode],
            GuestId: obj.userName
        };
        setGuestSession(guestSession);

        updateLogger(LOG_TYPES.INFO + "User " + obj.userId + " has joined poll " + pollUniqueCode);
    };

    const handleReattempt = async () => {
        setLoading(true);
        await reattemptPoll(param.institutionId, pollUniqueCode, getSession().userId, param.recordAttempt, new Date());
        await joinPoll(param.attemptsCount + 1);
        setLoading(false);
    };

    const handleVisibilityChange = useCallback(() => {
        console.log(document.visibilityState);
        if (document.visibilityState === "hidden") {
            setVisibility(false);
        } else if (document.visibilityState === "visible") {
            setVisibility(true);
        }
    }, []);

    useEffect(() => {
        newPageLog("JoinQuizParent.js");
    }, []);


    //===========================================step 2. join poll=======================================
    useEffect(() => {
        if (pollClosed === null) {
            return;
        }

        if (pollClosed) {
            setParam({
                pollState: POLL_STATE.CLOSED,
                pollTitle: pollInfoRef.current?.pollTitle,
                pollType: pollInfoRef.current?.pollType
            });
            if (websocketState !== WebSocket.OPEN || websocketState !== WebSocket.CONNECTING) {
                buildConnection();
            }

            setLoading(false);
        } else {
            joinPoll().then(() => {
                buildConnection();
                setLoading(false);
            })
        }
    }, [pollClosed]);
    //===========================================step 2. join poll end=======================================

    useEffect(() => {
        window.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            window.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [handleVisibilityChange]);

    useEffect(() => {
        const handleWindowResize = () => {
          if (document.getElementById("engagePageIdentifier") != null) {
            document.getElementById("engagePageIdentifier").innerText = pollInfoRef.current?.pollType === POLL_TYPE.SURVEY ? JOIN_SURVEY : JOIN_POLL;
          }
        };

        handleWindowResize();
    }, [windowSize]);


    //===========================================step 1. start verification=======================================
    useEffect(async () => {
        //1. authenticate
        const Authenticated  = getGuestSession()["$"+pollUniqueCode]?.Authenticated;
        if (!Authenticated) {
           const res = await Authentication();
           if (res) {
               //authentication passed
               const guestsession = getGuestSession();
               guestsession["$"+pollUniqueCode] = {
                   ...guestsession["$"+pollUniqueCode],
                   Authenticated: "true"
               };
               setGuestSession(guestsession);
               setAuthenticationPassed(true);
               if(window.navigator.cookieEnabled) {
                   localStorage.removeItem("warning");
               }
               setAuthenticationPassed(true);
           } else {
               return;
           }
        } else {
            setAuthenticationPassed(true);
        }

        //2. check if viewer has taken the poll
        if (!Object.entries(pollInfoRef.current).length) {
            const pollData = await getPollRecordFromUniqueCode(pollUniqueCode);
            pollInfoRef.current = pollData.poll ? pollData.poll : {};
        }
        const poll = pollInfoRef.current;
        let sessionUserName = getSession().userId ? getSession().userId : getGuestSession()["$"+pollUniqueCode]?.userId;
        let {data=[]} = await getAudienceResponseByUser(sessionUserName, pollUniqueCode);
        completedResponsesRef.current = data;
        // data = data.filter(response => (response.uniqueCodePK === "UC#" + pollUniqueCode));
        let userTakenPollTemp = false;
        if(data.length !== 0) {
            userTakenPollTemp = true;
        }

        if(document.getElementById("engagePageIdentifier") != null) {
            document.getElementById("engagePageIdentifier").innerText = poll.pollType.toUpperCase() === POLL_TYPE.GRADED_POLL ? JOIN_POLL : JOIN_SURVEY;
        }

        if (!!poll.uniqueCodeExpTime && Date.now() > poll.uniqueCodeExpTime) {//backward data compatible
            setPollClosed(true);
        } else if(!userTakenPollTemp) {// viewer hasn't taken the poll
            let pollEnd = poll.liveQuestionTime + (poll.pollTimeLimit * 1000);
            if((poll.pollState === POLL_STATE.SHARED_ALL && pollEnd <= Date.now() && poll.pollType.toUpperCase() !== POLL_TYPE.SURVEY)
                || poll.pollState === POLL_STATE.STOPPED_ALL
            ) {
                setPollClosed(true);
            } else if (poll.pollState === POLL_STATE.SCHEDULED && new Date(poll.endTime).getTime() + parseInt(getTimeOffset()) <= Date.now()) {
                setPollClosed(true);
            }else {
                setPollClosed(false);
            }
        } else {
            setPollClosed(false);
        }
    }, []);
    //===========================================step 1.verification end=======================================



    useEffect(() => {
        if (visibility === null) {
            return;
        }

        if (visibility) {
            buildConnection();
        } else {
            closeConnection();
        }

    }, [visibility]);





    return (
        <Spin size="large" wrapperClassName="globalSpin" spinning={false} tip="Loading...">
            {authenticationPassed &&
                <JoinQuizPageQRNew
                    param={param}
                    websocketState={websocketState}
                    websocketMsg={websocketMsg}
                    handleReattempt={handleReattempt}
                    setPollClosed={setPollClosed}
                    visibility={visibility}
                />
            }
        </Spin>
    );

}
