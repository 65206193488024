import {Form, Modal} from "react-bootstrap";
import {Spin} from "../../components/standardization/YuJaLoading";
import {ReactComponent as ModalClose} from "../../images/modal_close.svg";
import YuJaButton from "../standardization/YuJaButton";
import {
    BUTTON,
    CLOSE_MODAL,
    EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE, INVALID_POLL_CODE_ERROR,
    JOIN_POLL_MODAL_CANCEL,
    JOIN_POLL_MODAL_ENTER_POLL,
    JOIN_POLL_MODAL_POLL_CODE_LABEL,
    JOIN_POLL_MODAL_SCAN_QR,
    JOIN_POLL_MODAL_TITLE,
    POLL_CODE_LENGTH,
    REQUIRED_FIELD_ERROR,
    VIEWER_USER_TYPE
} from "../../utils/constants";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {formatErrorLog} from "../../utils/helpers";
import {ResponseApiCalls} from "../../apis/ResponseApiCalls";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import {PollApiCalls} from "../../apis/PollApiCalls";
import {CourseApiCalls} from "../../apis/CourseApiCalls";
import {YuJaTextBox} from "../standardization/YuJaTextBox";
import {useWindowDimensions} from "react-native-web";
import {USER_ACCESS_POLL_ERROR} from "../../utils/toast-message-constants";


export default function JoinPollModal({modalShow, setModalShow, code, setCode, setWarningModalShow}) {
    const isLoading = false;
    const history = useHistory();
    const {width} = useWindowDimensions();
    const {setUserNameToLocalStorage} = ResponseApiCalls();
    const {getPollRecordFromUniqueCode} = PollApiCalls();
    const {checkMemberAccess} = CourseApiCalls();
    const [, getSession] = useLocalStorageNew("session", {});
    const [setWarning, ] = useLocalStorageNew("warning", {});

    const [pollCodeError, setPollCodeError] = useState("");


    const handleJoinQR = () => {
        history.push("/qr");
    };


    const handleJoin = async () => {
        const session = getSession();
        if (await validateModalForm()) {
            const {idpType, intType, userId} = session;

            if (session && idpType && intType) {
                setUserNameToLocalStorage(code, userId, idpType + "_" + intType);
            } else {
                setUserNameToLocalStorage(code, userId, VIEWER_USER_TYPE.LOGGED_IN);
            }

            setWarning(false);
            setModalShow(false);
            history.push(`/join/${code}`);
        }
    };


    const validateModalForm = async () => {
        const session = getSession();
        if (!code) {
            setPollCodeError(REQUIRED_FIELD_ERROR);
            return false;
        } else if (code.length !== POLL_CODE_LENGTH) {
            setPollCodeError("Invalid poll code");
            return false;
        }

        const result = await getPollRecordFromUniqueCode(code);
        if (!result || !result.poll) {
            setPollCodeError(INVALID_POLL_CODE_ERROR);
            formatErrorLog(INVALID_POLL_CODE_ERROR);
            return false;
        }

        const {index2Pk: courseId, lmsHomeUrl} = result.poll;
        if (!courseId) {
            return true; // this means the poll is not inside the course so no need to check if the user can access it or not
        } else {
            if (!!lmsHomeUrl ) {
                if (!session.gradedLink) {
                    formatErrorLog(EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE);
                    history.push(`/lti/${code}/guide`);
                } else {
                    setWarning(false);
                    history.push({pathname: "/join/" + code});
                }
                return false;
            }

            const accessibleResult = await checkMemberAccess(courseId.substring(2))
            if (accessibleResult == null || accessibleResult.result == null || !accessibleResult.result) {
                setPollCodeError(USER_ACCESS_POLL_ERROR);
                formatErrorLog("User cannot access the poll");
                return false;
            } else {
                return true;
            }
        }

    };

    const handleClose = () =>{
        setModalShow(false);
        setCode("");
        setPollCodeError("");
    };

    return (
        <Modal
            id="form-modal"
            show={modalShow}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={isLoading ? "static" : true}
        >
            <Spin tip="Loading..." size="large" spinning={isLoading}>
                <ModalClose tabIndex={0} aria-label={CLOSE_MODAL} role={BUTTON} onClick={handleClose} className="modal-close" />
                <Modal.Header
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Modal.Title tabIndex={0} className="warning-title" id="contained-modal-title-vcenter">{JOIN_POLL_MODAL_TITLE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="customized-form" onSubmit={e => e.preventDefault()}>
                        <Form.Group style={{ marginBottom: "0.938rem" }}>
                            <div tabIndex={0} className="form-label">{JOIN_POLL_MODAL_POLL_CODE_LABEL}</div>
                            <YuJaTextBox
                                containerStyle={{width: "90%", fontSize: 15}}
                                value={code || ""}
                                onChange={(e) => setCode(e.target.value)}
                                isInvalid={!!pollCodeError}
                                errorMsg={pollCodeError}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="join-poll-modal-footer">
                    {width < 1100 &&
                        <YuJaButton onClick={handleJoinQR}>{JOIN_POLL_MODAL_SCAN_QR}</YuJaButton>
                    }
                    <div style={{flex: 1}}/>
                    <YuJaButton type="secondary"  onClick={handleClose}>{JOIN_POLL_MODAL_CANCEL}</YuJaButton>
                    <YuJaButton onClick={handleJoin}>{JOIN_POLL_MODAL_ENTER_POLL}</YuJaButton>
                </Modal.Footer>
            </Spin>
        </Modal>)
}
