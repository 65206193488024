import React, { useRef, useEffect } from "react";
import ClockBlack from "./images/timer-icon.svg";
import ClockRed from "./images/timer-icon-red.svg";
import Countdown from 'react-countdown';
import {HOUR_LABEL, MIN_LABEL, QUIZ_STATE, SEC_LABEL, TEXTBOX, TIME_REMAINING} from "../../utils/constants";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function CountdownTimerAll({ pollEndTime, timeUp, state }) {
  let hurry = false;
  const countDownRef = useRef();
  const isNarrow = useMobileAccess(1200);
  const handleStart = () => countDownRef.current.start();
  const handlePause = () => countDownRef.current.pause();

  useEffect(() => {
    if (state === QUIZ_STATE.PAUSED) {
      handlePause();
      console.log('Time Is Paused');
    }
    else if (state === QUIZ_STATE.RESUMED) {
      handleStart();
      console.log('Time Is Resumed');
    }
  }, [state]);

  const isEndRef = useRef();
  isEndRef.current = new Date() >= pollEndTime;

  useEffect(() => {
    if (new Date() >= pollEndTime) {
      timeUp();
    }
  }, [pollEndTime, timeUp])

  const renderer = ({ days, hours, minutes, seconds }) => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds < 4) {
      if (document.getElementById("tc") !== null)
        document.getElementById("tc").style.color = '#C93E3E';
      hurry = true;
    }

    // pad with 2 leading zeros
    hours = ('00' + ((days*24)+hours)).slice(-2);
    minutes = ('00' + minutes).slice(-2);
    seconds = ('00' + seconds).slice(-2);

    return (<>
      <img src={hurry ? ClockRed : ClockBlack} id="clock" className="timer-logo" alt="clock.icon"  style={{width: "32px", height: "32px"}}/>
      <span className="timer-count" tabIndex={0} role={TEXTBOX} aria-live="off" aria-atomic="true" aria-label={TIME_REMAINING + hours + HOUR_LABEL + minutes + MIN_LABEL + seconds + SEC_LABEL} id="tc">{hours}:{minutes}:{seconds}</span>
    </>);
  };

  return (
    <>
      <div className="timer-outer" style={isNarrow ? {backgroundColor: "#E6F2F7"} : {backgroundColor: "#ffffff"}}>
        <div className="img-wrap timer-holder">
          <Countdown
            ref={countDownRef}
            className="timer-count"
            date={pollEndTime}
            renderer={renderer}
            onComplete={() => {
              timeUp();
            }}
          />
        </div>
      </div>
    </>
  );
}
