import React, {createContext, useContext, useEffect, useState} from "react";
import { Detector } from "react-detect-offline";

export const YuJaGlobalState = createContext({
    loading: false,
    setLoading: () => {},
    isTokenExpiredModalShowUp: false,
    setIsTokenExpiredModalShowUp: () => {},
    isCookieEnabled: true,
    isOnline: true,
    effectiveType: null,
    missedResponseMap: {},
    setMissedResponseMap: () => {},
    missedReactionMap: {},
    setMissedReactionMap: () => {},
});

export function YuJaGlobalStateProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [effectiveType, setEffectiveType] = useState(null);
    const [missedResponseMap, setMissedResponseMap] = useState({});
    const [missedReactionMap, setMissedReactionMap] = useState({});
    const [isCookieEnabled, setIsCookieEnabled] = useState(window.navigator.cookieEnabled);

    const [isTokenExpiredModalShowUp, setIsTokenExpiredModalShowUp] = useState(false);
    const data = {
        loading,
        setLoading,
        isTokenExpiredModalShowUp,
        setIsTokenExpiredModalShowUp,
        isCookieEnabled,
        isOnline,
        effectiveType,
        missedResponseMap,
        setMissedResponseMap,
        missedReactionMap,
        setMissedReactionMap
    }

    useEffect(() => {
        const {connection, mozConnection, webkitConnection} = window.navigator;

        const conn = connection || mozConnection || webkitConnection;

        if (conn) {
            // Initial setup of effectiveType
            setEffectiveType(conn.effectiveType);

            // Add an event listener to update effectiveType when it changes
            const handleConnectionChange = () => {
                setEffectiveType(conn.effectiveType);
            };

            conn.addEventListener('change', handleConnectionChange);

            // Clean up the event listener when the component unmounts
            return () => {
                conn.removeEventListener('change', handleConnectionChange);
            };
        }
    }, []);


    return (
        <YuJaGlobalState.Provider value={data} >
            {children}
            <Detector
                //if the default api call not works try replacing with another public one
                // polling={{
                //     url: 'https://y8rrhscamg.execute-api.us-west-2.amazonaws.com/stage/public/inst?shortName=staging-dev',
                //     enabled: true
                // }}
                //
                polling={{
                    timeout: 50000
                }}
                onChange={(online) => setIsOnline(online)}
                render={() => <></>}
            />
        </YuJaGlobalState.Provider>
    );
}

export function useLoading() {
    const context = useContext(YuJaGlobalState);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}