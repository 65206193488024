import { POLL_TITLE_LENGTH, QUESTION_LENGTH, FITB_ANSWER_LENGTH, MC_ANSWER_LENGTH, SA_ANSWER_LENGTH, FITB_QUESTION_LENGTH } from "./constants";

export const POLL_TITLE_ERROR = "Please provide a title for the Poll.";
export const SURVEY_TITLE_ERROR = "Please provide a title for the Survey.";
export const QUESTION_BANK_TITLE_ERROR = "Please provide a name for the Question Bank.";
export const QUESTION_TITLE_ERROR = "Please provide a title for the question.";
export const CORRECT_ANSWER_NOT_SELECTED = "Please select the correct option.";
export const MISSING_RESPONSE_ERROR = "Please submit a response for Option:";
export const MH_MISSING_PREMISE_ERROR = "Please fill in Premise:";
export const MH_MISSING_RESPONSE_ERROR = "Please fill in Response:";
export const FITB_MISSING_RESPONSE_ERROR = "Please submit an answer for Blank:";

export const MC_CI_ANSWER_LENGTH_EXCEEDED = "You have passed the maximum character limit of " + MC_ANSWER_LENGTH + ". Please shorten the response.";
export const SA_ANSWER_LENGTH_EXCEEDED = "You have passed the maximum character limit of " + SA_ANSWER_LENGTH + ". Please shorten the response.";
export const FITB_ANSWER_LENGTH_EXCEEDED = "You have passed the maximum character limit of " + FITB_ANSWER_LENGTH + ". Please shorten the response.";

export const QUESTION_CHARACTER_LIMIT_EXCEEDED = "You have passed the maximum character limit of " + QUESTION_LENGTH + ". Please shorten the title of the question.";
export const POLL_TITLE_CHARACTER_LIMIT_EXCEEDED = "You have passed the maximum character limit of " + POLL_TITLE_LENGTH + ". Please shorten the title of the Poll.";
export const QUESTION_BANK_CHARACTER_LIMIT_EXCEEDED = "You have passed the maximum character limit of " + POLL_TITLE_LENGTH + ". Please shorten the name of the Question Bank";
export const SURVEY_TITLE_CHARACTER_LIMIT_EXCEEDED = "You have passed the maximum character limit of " + POLL_TITLE_LENGTH + ". Please shorten the title of the Survey.";
export const FITB_QUESTION_CHARACTER_LIMIT_EXCEEDED = "You have passed the maximum character limit of " + FITB_QUESTION_LENGTH + ". Please shorten the answer for the blank.";
export const PASSWORD_CHARACTER_LIMIT_EXCEEDED = "You have passed the maximum character limit of 30. Please shorten the password."; 
export const CHARACTER_LIMIT_EXCEEDED_GENERAL = "You have passed the maximum character limit of length."; 
export const RESPONSE_CHARACTER_LIMIT_EXCEEDED = "The correct answer has not been selected. Please select the correct answer.";
export const FITB_QUESTION_DUPLICATE_CORRECT = "Please provide an answer that has not previously been entered."; 

export const FITB_MIN_OPTIONS_ERROR = "Please insert at least one blank into the question.";
export const CI_MIN_OPTIONS_ERROR = "Please add at least one option to the Clickable Image.";
export const MC_MIN_OPTIONS_ERROR = "Please provide at least two options for Multiple Choice questions.";

export const QUESTION_MAX_POINTS_ERROR = "You cannot assign questions to be worth more than 1,000 points. ";
export const WC_ENTRIES_ERROR = "Please provide students with 1 to 10 entries.";
export const INCORRECT_QUESTION_TYPE = "Incorrect Question Type!";

export const SERVER_ERROR = "We have encountered a server error. Please try again or contact YuJa Help Center.";

export const ENTER_VALID_CODE_ERROR = "The Poll Code you’ve entered is incorrect. Please enter the correct Poll Code.";
export const JOIN_LMS_POLL_ERROR = "This Poll has been restricted to your LMS assignment. Please close this page and re-access the Poll in your LMS.";
export const JOIN_COURSE_POLL_ANONYMOUSLY_ERROR = "This Poll cannot be accessed anonymously. Please log in to access the Poll.";
export const CHECK_POLL_IN_COURSE_ERROR = "The Poll was unable to be verified within the course. Please try again or contact YuJa Help Center.";

// Polls:
export const LOADING_SEARCH_ERROR = "Please wait to search as we load your Polls.";

// Question bank 
export const SAVE_BANK_WARNING_MESSAGE = "Please provide a title or answers to at least one question.";
export const UPDATE_BANK_ERROR_MESSAGE = "The Question Bank could not be updated. Please try again or contact YuJa Help Center."; 
export const RETRIEVE_BANK_ERROR_MESSAGE = "The Question Bank could not be retrieved. Please try again or contact YuJa Help Center.";
export const DELETE_BANK_ERROR_MESSAGE = "The Question Bank could not be deleted. Please try again or contact YuJa Help Center.";
export const DELETE_BANK_SUCCESS_MESSAGE = "The Question Bank has been successfully deleted."
export const IMPORT_QUESTION_ERROR = "Please select at least one question to import."; 

// Gradebook:
export const SELECT_AT_LEAST_ONE_STUDENT = "Please select at least one student for grade sync.";
export const NO_STUDENTS_FOUND = "No students found.";
export const ENTER_VALID_SCORES = "Please enter scores between 0.00 - 100.00. ";
export const GRADEBOOK_SYNC_SUCCESS = "The gradebook has been successfully synced.";
export const GRADE_QUESTION_SUCCESS = "Changes have been successfully saved.";
export const GRADE_QUESTION_ERROR = "The changes could not be saved. Please try again or contact YuJa Help Center.";

// Institution API:
export const LOAD_PLATFORM_SETTINGS_ERROR = "The Platform settings could not be loaded. Please try again or contact YuJa Help Center.";
export const UPDATE_PLATFORM_SETTINGS_SUCCESS = "Platform settings have been successfully updated.";
export const UPDATE_PLATFORM_SETTINGS_ERROR = "The Platform settings could not be updated. Please try again or contact YuJa Help Center.";
export const SSO_UPDATE_SUCCESS = "SSO details have been successfully updated."; 
export const SSO_UPDATE_ERROR = "The Single-Sign-On details could not be updated. Please try again or contact YuJa Help Center."; 
export const LOAD_SSO_ERROR = 'The LMS details could not be loaded. Please try again or contact YuJa Help Center.';
export const UPDATE_LMS_SUCCESS = "LMS details have been successfully updated."; 
export const UPDATE_LMS_ERROR = "The LMS details could not be updated. Please try again or contact YuJa Help Center.";

// Poll API
export const UPLOAD_IMAGE_ERROR = "The uploaded image could not be saved. Please try again or contact YuJa Help Center.";
export const DELETE_IMAGE_ERROR = "The image could not be deleted. Please try again or contact YuJa Help Center.";
export const SELECT_POLL_TYPE_ERROR = "Please select a poll type.";
export const MISSING_UNIQUE_CODE_ERROR = "Fetched data does not include unique code.";
export const INCOMPLETE_POLL_ERROR = "The Poll is missing some required fields. To save, please fill in the highlighted fields.";
export const INCOMPLETE_SURVEY_ERROR = "The Survey is missing some required fields. To save, please fill in the highlighted fields. ";
export const DELETE_DRAFT_POLL_ERROR = "The drafted Poll could not be deleted. Please try again or contact YuJa Help Center.";
export const DELETE_DRAFT_POLL_SUCCESS = "The drafted Poll has been successfully deleted.";
export const DELETE_DRAFT_SURVEY_ERROR = "The drafted Survey could not be deleted. Please try again or contact YuJa Help Center.";
export const DELETE_DRAFT_SURVEY_SUCCESS = "The drafted Survey has been successfully deleted.";
export const DELETE_PUBLISHED_POLL_ERROR = "The published Poll could not be deleted. Please try again or contact YuJa Help Center.";
export const DELETE_PUBLISHED_POLL_SUCCESS = "The Published Poll has been successfully deleted.";
export const DELETE_PUBLISHED_SURVEY_ERROR = "The published Survey could not be deleted. Please try again or contact YuJa Help Center.";
export const DELETE_PUBLISHED_SURVEY_SUCCESS = "The Published Survey has been successfully deleted.";
export const SHARE_SUCCESS = "The Poll or Survey has been successfully shared.";
export const COPY_SUCCESS = "The Poll or Survey has been successfully copied.";
export const SHARE_POLL_ERROR = "The Poll could not be shared. Please try again or contact YuJa Help Center.";
export const SHARE_SURVEY_ERROR = "The Survey could not be shared. Please try again or contact YuJa Help Center.";
export const COPY_POLL_ERROR = "The Poll could not be copied. Please try again or contact YuJa Help Center.";
export const COPY_SURVEY_ERROR = "The Survey could not be copied. Please try again or contact YuJa Help Center.";
export const QUESTIONS_SHARE_ERROR = "The questions could not be shared. Please try again or contact YuJa Help Center.";
export const QUESTIONS_SHARE_SUCCESS = "Questions have been successfully shared.";
export const STOP_ERROR = "Question sharing could not be stopped. Please try again or contact YuJa Help Center.";
export const STOP_SUCCESS = "Question sharing has been successfully stopped.";
export const GET_POLLS_ERROR = "The Polls could not be retrieved. Please try again or contact YuJa Help Center.";
export const GET_PUBLISHED_POLL_ERROR = "The published Polls could not be retrieved. Please try again or contact YuJa Help Center.";
export const UPDATE_PUBLISHED_POLL_ERROR = "The published Polls could not be updated. Please try again or contact YuJa Help Center.";
export const GET_ACTIVE_POLLS_ERROR = "The active Polls could not be retrieved. Please try again or contact YuJa Help Center.";
export const STOP_POLL_SUCCESS = "Successfully stopped sharing question: #serialNo."; 
export const STOP_POLL_ERROR = "Sharing Question #serialNo could not be stopped. Please try again or contact YuJa Help Center.";
export const SAVE_POLL_SUCCESS = "pollTitle has been successfully saved";
export const SAVE_POLL_ERROR = "pollTitle could not be saved. Please try again or contact YuJa Help Center.";
export const UPDATE_POLL_SUCCESS = "pollTitle has been successfully updated.";
export const UPDATE_POLL_ERROR = "pollTitle could not be updated. Please try again or contact YuJa Help Center."; 
export const PUBLISH_POLL_ERROR = "pollTitle could not be published. Please try again or contact YuJa Help Center."; 
export const REACTIVATE_POLL_ERROR = "pollTitle could not be reactivated. Please try again or contact YuJa Help Center.";
export const SHARE_QUESTION_SUCCESS = "Successfully shared question: #serialNo.";
export const SHARE_QUESTION_ERROR = "Question #serialNo could not be shared. Please try again or contact YuJa Help Center.";
export const LOCK_QUESTION_SUCCESS = "Successfully locked question: #serialNo.";
export const LOCK_QUESTION_ERROR = "Question #serialNo could not be locked. Please try again or contact YuJa Help Center.";
export const PAUSE_QUESTION_SUCCESS = "Successfully paused question: #serialNo.";
export const PAUSE_QUESTION_ERROR = "Question #serialNo could not be paused. Please try again or contact YuJa Help Center.";
export const RESUME_QUESTION_SUCCESS = "Successfully resumed question #serialNo."; 
export const RESUME_QUESTION_ERROR = "Question #serialNo could not be resumed. Please try again or contact YuJa Help Center.";
export const SAVE_BANK_SUCCESS = "ProfileTitle has been successfully updated.";
export const UPDATE_BANK_SUCCESS = "ProfileTitle  has been successfully updated.";
export const CLIPBOARD_COPY_SUCCESS = "uniqueCode has been successfully copied.";
export const COPY_URL_SUCCESS = "The URL has been successfully copied.";

// Reponse API:
export const REATTEMPT_POLL_ERROR = "The Poll could not activate for a reattempt. Please try again or contact YuJa Help Center.";
export const JOINING_POLL_ERROR = "The Poll could not be joined. Please try again or contact YuJa Help Center."; 
export const VALIDATE_USERNAME_ERROR = "Your username could not be validated. Please try again or contact YuJa Help Center."; 
export const SHARE_LIST_ERROR = "The question list could not be retrieved. Please try again or contact YuJa Help Center."; 
export const UPDATE_GRADE_ERROR = "The grade could not be updated. Please try again or contact YuJa Help Center."; 
export const SUBMIT_RESPONSE_NETWORK_ERROR = "Your internet connection has been disconnected. Please do not refresh the page. Your submission will automatically be resubmitted once the connection is restored.";
export const SUBMIT_REACTION_NETWORK_ERROR = "Your internet connection has been disconnected. Please do not refresh the page. Your reaction will automatically be updated once the connection is restored.";
export const REATTEMPT_NETWORK_ERROR = "Your internet connection has been disconnected. Please check and try again.";


// User API
export const LOAD_USERS_ERROR = "Users could not be retrieved. Please try again or contact YuJa Help Center. ";
export const SAVE_USER_SUCCESS = "The user has been successfully saved.";
export const SAVE_USER_ERROR = "The user could not be saved. Please try again or contact YuJa Help Center. ";
export const UPDATE_USER_SUCCESS = "User has been successfully updated.";
export const UPDATE_USER_ERROR = "The user could not be updated. Please try again or contact YuJa Help Center. ";
export const DELETE_USER_SUCCESS = "User has been successfully deleted.";
export const DELETE_USER_ERROR = "The user could not be deleted. Please try again or contact YuJa Help Center. ";
export const DELETE_SELECTED_USERS_SUCCESS = "Selected users have been successfully deleted.";
export const DELETE_SELECTED_USERS_ERROR = "The selected users could not be deleted. Please try again or contact YuJa Help Center. ";
export const LOAD_USER_ROSTER_ERROR = "The users could not be retrieved. Please try again or contact YuJa Help Center.";
export const SAVE_SETTINGS_SUCCESS = "Settings have been successfully saved.";
export const SAVE_SETTINGS_ERROR = "The settings could not be saved. Please try again or contact YuJa Help Center.";

// Authentication API 
export const LOGIN_USER_ERROR = "Your login credentials are incorrect. Please try again."
export const USER_NOT_FOUND_ERROR = "The user could not be found. Please contact your IT Manager."; 
export const INVALID_EMAIL_ERROR = "The email is not formatted correctly. Please ensure the email is formatted correctly and try again."; 
export const RESET_CODE_ERROR = "The link you selected has expired. Please request a new link to reset your password.";
export const SSO_REDIRECT_ERROR = "The login could not redirect to your SSO. Please try again or contact YuJa Help Center."; 
export const ENGAGE_REDIRECT_ERROR = "Could not redirect to Engage. Please try again or contact YuJa Help Center."; 

// Course API
export const SAVE_COURSE_SUCCESS = "The course has been successfully saved."; 
export const INVALID_COURSE_NAME_ERROR = "Please ensure the course Name can only contain letters and numbers."; 
export const INVALID_COURSE_CODE_ERROR = "Please ensure the Course Code only contains numbers."; 
export const SAVE_COURSE_ERROR = "The course could not be saved. Please try again or contact YuJa Help Center."; 
export const COURSE_UPDATE_SUCCESS = "The course has been successfully updated."; 
export const UPDATE_COURSE_ERROR = "The course could not be updated. Please try again or contact YuJa Help Center."; 
export const COURSE_DELETE_SUCCESS = "The course has been successfully deleted. "; 
export const COURSE_DELETE_ERROR = "The course could not be deleted. Please try again or contact YuJa Help Center.";
export const LOAD_COURSE_ERROR = "The course could not be loaded. Please try again or contact YuJa Help Center."; 
export const GET_COURSE_ERROR = "The course could not be retrieved. Please try again or contact YuJa Help Center."; 
export const SAVE_COURSE_MEMEBER_SUCCESS = "Course members have been successfully updated."; 
export const SAVE_COURSE_MEMEBER_ERROR = "The course members could not be saved. Please try again or contact YuJa Help Center. ";

// Public API 

export const SUPPORT_REQUEST_SUCCESS = "Your message has been sent to our fantastic YuJa Support Team. We will respond to your inquiry within 24 hours!"; 
export const SUPPORT_REQUEST_ERROR = "An unexpected error occurred while processing your request. Please try again or contact YuJa Help Center."; 

export const CUSTOM_TIME_LIMIT_MAX_ERROR = "Please enter a custom Time Limit that is less than 1 hour. "; 
export const CUSTOM_TIME_LIMIT_MIN_ERROR = "Please enter a custom Time Limit that is at least 10 seconds."; 
export const CUSTOME_TIME_LIMIT_FORMAT_ERROR = "When entering a custom Time Limit, the format must be 00:00:00.";

export const SUBJECT_EXCEED_LIMIT_ERROR = "You have passed the maximum character limit of 100. Please shorten the Subject line."; 
export const NAME_REQUIRED_FIELD = "Please enter a name."; 
export const EMAIL_REQUIRED_FIELD = "Please enter an email."; 
export const VALID_EMAIL_ERROR = "The email is not formatted correctly. Please ensure the email is formatted correctly and try again."; 
export const MISSING_SUBJECT_ERROR = "Please enter a subject.";
export const MISSING_MESSAGE_ERROR = "Please enter a message.";

export const MISSING_OPTION_ERROR = "Please select a published Poll to continue.";
export const USER_ACCESS_POLL_ERROR = "The Poll is active in a course you are not enrolled in. Please contact your instructor.";
export const LMS_RESTRICTED_ERROR = "This Poll has been restricted to your LMS assignment. Please close this page and re-access the Poll in your LMS."; 
export const FAILED_SUBMIT_ERROR = "The answer could not be submitted for Q.submittedQueNo. Please try again or contact YuJa Help Center."
export const MISSING_USER_ERROR = "Please select at least one user to delete.";
// attempt to disable yuja credential login without LMS set up 
export const LMS_DISABLE_YUJA_ERROR = "To disable YuJa Engage credentials, you need to integrate your institution’s Single Sign-On (SSO) with Engage. For information on configuring your SSO authentication with Engage, please visit support.yuja.com.";
export const TIME_SHARE_ALL_ERROR = "Please enter the time questions will be shared."; 

export const SELECT_FILE_ERROR = "Please select a file to submit.";
export const INVALID_FILE_FORMAT_ERROR = "Please upload an image or video with the correct file format. Acceptable formats are PNG, JPG, JPEG, JFIF, PJPEG, PJP, and MP4.";
export const UPLOAD_ONE_IMAGE_ERROR = "Please upload a single image or video.";

export const AREA_OVERLAP_ERROR = "Two different areas can not be overlapped";
export const ADD_OPTIONS_ERROR = "More options could not be added. Please try again or contact YuJa Help Center.";

export const REQUEST_SEND_SUCCESS = "Please wait while your request is being sent. You will receive a confirmation shortly.";
export const RESPONSE_LINK_SUCCESS = "Response Link has been copied successfully."; 
// schedule poll 

export const INVALID_SCHEDULE_END_TIME_ERROR = "Enter an End Date and Time that is not in the past."; 
export const INVALID_SCHEDULE_END_DATE_ERROR = "Enter an End Date and Time that is after the Start Date and Time.";
export const INVALID_SCHEDULE_START_TIME_ERROR = "Enter a Start Date and Time that is not in the past.";
export const MISSING_SCHEDULE_START_END_TIME_ERROR = "Enter a Start and End Time for the Poll."; 
export const SCHEDULE_POLL_START_END_GAP_ERROR = "Start and End Dates must be within 30 days of each other.";
export const INVALID_TIME_LIMIT_ERROR = "Length of time cannot exceed the selected Start and End Dates for the poll."; 

// question bank 

export const IMPORT_QUESTION_BANK_SUCCESS = "The questions have been successfully imported.";
export const IMPORT_QUESTION_BANK_ERROR = "The questions could not be imported. Please try again or contact YuJa Help Center.";
