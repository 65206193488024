import { useCookie } from "../hooks/useCookie";
import { useFetchWrapper } from "../hooks/useFetchWrapper";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import {useLoading, YuJaGlobalState} from "../utils/LoadingContext";
import {
    IDENTITY_PROVIDER_TYPES,
    NETWORK_ISSUE,
    PLATFORM_TYPES, POLL_SHARE_MODE,
    POLL_TYPE,
    QUESTION_TYPES, SYNC_ATTEMPT,
    VIEWER_USER_TYPE
} from "../utils/constants";
import {
    hasSA,
    notifyError,
    processResult,
    formatAPILog,
    formatAPIResponseLog,
    formatAPIErrorLog,
    calculateScore
} from "../utils/helpers";
import { RESPONSE_API_URL } from "../utils/properties";
import { chooseQuestion } from "../utils/questionUtils";
import {
    JOINING_POLL_ERROR, REATTEMPT_NETWORK_ERROR,
    REATTEMPT_POLL_ERROR,
    SHARE_LIST_ERROR, SUBMIT_REACTION_NETWORK_ERROR,
    SUBMIT_RESPONSE_NETWORK_ERROR,
    UPDATE_GRADE_ERROR,
    VALIDATE_USERNAME_ERROR
} from "../utils/toast-message-constants";
import { browserName, fullBrowserVersion, osName, osVersion, isAndroid} from 'react-device-detect';
import {LTIApiCalls} from "./LTIApiCalls";
import {useContext} from "react";

export const ResponseApiCalls = () => {
    const fetchWrapper = useFetchWrapper();
    const { setLoading } = useLoading();
    const [, getCookie,] = useCookie("name", "Guest");
    const {missedResponseMap, setMissedResponseMap, missedReactionMap, setMissedReactionMap, isCookieEnabled} = useContext(YuJaGlobalState);

    const [setMissedResponseStorage, getMissedResponseStorage] = useLocalStorageNew("missedResponseMap", {});
    const [setMissedReactionMapStorage, getMissedReactionMapStorage] = useLocalStorageNew("missedReactionMap", {});
    const [setGuestSession, getGuestSession] = useLocalStorageNew("guestSession", {});
    const [, getSession] = useLocalStorageNew("session", {});
    const { gradebookSync } = LTIApiCalls();

    return {
        setUserNameToLocalStorage,
        getPollQuestions,
        getAudienceResponseByUser,
        getAudienceResultByUser,
        getAudienceResponseByPoll,
        getPollsTakenByStudent,
        getStudentResultByPoll,
        getClassResult,
        getAllClassResult,
        getActiveStudentsInPoll,
        getPollRecordFromUniqueCode,
        getResultByGradeBookPerspective,
        checkUserNameExisted,
        getSharedQuestionList,
        gradeQuestion,
        gradeQuestionStudents, 
        updateScore,
        syncScore,
        reattemptPoll,
        fakeCall,
        getQuestionResult, 
        setStudentReaction, 
        getPollReaction,
        getShortName,
        handleSubmitIndividualResponse,
        handleSubmitMultiResponse,
        updateReaction
    }

    // function getUserNameFromLocalStorage(pollCode) {
    //     const guestSession = getGuestSession();
    //     const markedPollCode = '$' + pollCode;
    //     if (!guestSession.hasOwnProperty(markedPollCode)) {
    //         return '';
    //     }
    //
    //     const pollConversation = guestSession[markedPollCode];
    //     if (!pollConversation.hasOwnProperty('userName')) {
    //         delete guestSession[markedPollCode];
    //         setGuestSession(guestSession);
    //         return '';
    //     }
    //
    //     return pollConversation.userName;
    // }

    function getJoinPollBody(pollCode) {
        const res = {};
        res.shortName = getShortName();
        res.browserType = browserName + " " + fullBrowserVersion;
        if(isAndroid) {
            res.platform = PLATFORM_TYPES.Android;
        } else {
            res.platform = osName + " " + osVersion;
        }

        const guestSession = getGuestSession();
        const markedPollCode = '$' + pollCode;
        const pollConversation = guestSession[markedPollCode];
        //join poll by directly typing the url
        if (!pollConversation) {
            const session = getSession();
            const cookieUserName = getCookie();
            if (!!session.userName) {
                res.userId = session.userId;
                if(session.idpType === IDENTITY_PROVIDER_TYPES.LMS) {
                    res.userType = session.idpType + "_" + session.intType;
                } else {
                    res.userType = VIEWER_USER_TYPE.LOGGED_IN;
                }
                setUserNameToLocalStorage(pollCode, res.userId, res.userType);
            } else if (cookieUserName && cookieUserName !== "Guest") {
                res.userName = cookieUserName;
                res.userType = VIEWER_USER_TYPE.MANUAL_GUEST;
                setUserNameToLocalStorage(pollCode, res.userName, res.userType);
            }
            return res;
        }

        // join from scanning QR code
        //backfoward compatible
        if (!pollConversation.hasOwnProperty("userId") && pollConversation.userType !== VIEWER_USER_TYPE.MANUAL_GUEST) {
            res.userId = pollConversation.userName;
            res.userType = pollConversation.userType;
            
        } else{
            res.userName = pollConversation.userName;
            res.userType = pollConversation.userType;
            res.userId = pollConversation.userId;
        
        // if (guestSession.hasOwnProperty(markedPollCode) && pollConversation.hasOwnProperty('userName')) {
        //     res.userName = pollConversation.userName;
        //     res.userType = pollConversation.userType;
        // }

        // if (guestSession.hasOwnProperty(markedPollCode) && (pollConversation.userType == "LOGGED_IN")) {
        //     res.userId = pollConversation.userId;
        //     res.userType = pollConversation.userType;
        // }

        // if(guestSession.hasOwnProperty(markedPollCode) && pollConversation.hasOwnProperty('userId')) {
        //     res.userId = pollConversation.userId;
        // }
        }

        return res;

    }


    // function getUserTypeFromLocalStorage(pollCode) {
    //     const guestSession = getGuestSession();
    //     const markedPollCode = '$' + pollCode;
    //     if (!guestSession.hasOwnProperty(markedPollCode)) {
    //         return null;
    //     }
    //
    //     const pollConversation = guestSession[markedPollCode];
    //     if (!pollConversation.hasOwnProperty('userType')) {
    //         return null;
    //     }
    //
    //     return pollConversation.userType;
    // }

    function setUserNameToLocalStorage(pollCode, userId=null, userType=VIEWER_USER_TYPE.AUTO_GUEST) {
        const guestSession = getGuestSession();
        formatAPILog("SetUserNameToLocalStorage", guestSession);
        const markedPollCode = '$' + pollCode;
        const obj = !!guestSession[markedPollCode] ? (guestSession[markedPollCode].userType === userType ? JSON.parse(JSON.stringify(guestSession[markedPollCode])) : {}) : {};
        if(userId && userType === VIEWER_USER_TYPE.MANUAL_GUEST) {
            obj.userName = userId;
        }
        else if (userId) {
            obj.userId = userId;
        }
        obj.userType = userType;
        guestSession[markedPollCode] = obj;
        setGuestSession(guestSession);
        formatAPIResponseLog("SetUserNameToLocalStorage", guestSession);
    }

    // API call for when a user joins a poll 
    // Returns the poll data for a specific poll
    // return data includes, uniqueCode, pollKey, pollTitle, questions, institutionId
    function getPollQuestions(pollCode, pollJoinTime='') {
        const bodyObj = getJoinPollBody(pollCode);
        if (pollJoinTime) {
            bodyObj.pollJoinTime = pollJoinTime
        }
        setLoading(true);
        return fetchWrapper.post(
            {
                url: `${RESPONSE_API_URL}/response/user/${pollCode}`,
                body: bodyObj,
                authorizeRequired: false
            }
        ).then((result) => {
            console.log(result);
            if (!result.errorCode)  {
                const session = getSession();
                const {pollKey, userName, questions, userType, userId} = result;
                if (!!pollKey && !!pollCode && !!session.userId) {
                    syncScore(pollKey, pollCode, session.userId, !session.courseId || !hasSA(result.questions));
                }
                if (!userType || userType === VIEWER_USER_TYPE.AUTO_GUEST) {
                    setUserNameToLocalStorage(pollCode, userId, userType ? userType : VIEWER_USER_TYPE.AUTO_GUEST);
                }

                if (userType === VIEWER_USER_TYPE.MANUAL_GUEST) {
                    const guestSession = getGuestSession();
                    const markedPollCode = '$' + pollCode;
                    const obj = {};
                    obj.userType = userType;
                    obj.userName = userName;
                    obj.userId = userId;
                    guestSession[markedPollCode] = obj;
                    setGuestSession(guestSession);
                }
                
                formatAPIResponseLog("GetPollQuestions", result);
                return {
                    ...result,
                    questions: chooseQuestion(1, questions),
                };
            }
        }).catch(err => {
            console.log(err);
            formatAPIErrorLog("GetPollQuestions", err);
            try {
                const errObj = JSON.parse(err.message);
                notifyError(errObj.errorMessage);
                return null;
            } catch {
                console.log(err.message);
                notifyError(JOINING_POLL_ERROR);
                return null;
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    function getAudienceResponseByUser(userId, pollCode="") {
        if (!userId) {
            return {};
        }

        setLoading(true);
        return fetchWrapper.get(
            {
                url: `${RESPONSE_API_URL}/response/audience-response/${userId}/${pollCode}`,
            }
        ).then((result) => {
            formatAPIResponseLog("GetAudienceResponseByUser", result);
            return (result);
        }).catch(error => {
            console.log(error);
            formatAPIErrorLog("GetAudienceResponseByUser", error);
            return {};
        }).finally(() => {
            setLoading(false);
        });
    }

    function getAudienceResultByUser(institutionId, userId, silent=false) {
        if (!silent) setLoading(true);
        return fetchWrapper.get(
            {
                url: `${RESPONSE_API_URL}/response/audience-result/${institutionId}/${userId}`,
            }
        ).then((result) => {
            formatAPIResponseLog("GetAudienceResultByUser", result);
            return (result);
        }).finally(() => {
            if (!silent) setLoading(false);
        });
    }

    // Returns all responses for a given poll
    function getAudienceResponseByPoll(pollKey, pollCode, totalQuestions) {
        return fetchWrapper.get(
            {
                url: `${RESPONSE_API_URL}/response/audience-response/poll/${pollKey}/${pollCode}/${totalQuestions}`,
            }
        ).then((result) => {
            formatAPIResponseLog("GetAudienceResponseByPoll", result);
            return (result);
        });
    }

    // Returns all published polls that a student has completed
    function getPollsTakenByStudent(institutionId, userId) {
        return fetchWrapper.get(
            {
                url: `${RESPONSE_API_URL}/response/student/polls/${institutionId}/${userId}`,
            }
        ).then((result) => {
            console.log(result);
            formatAPIResponseLog("GetPollsTakenByStudent", result);
            return (result);
        });
    }

    // Returns the responses for a specific student and poll
    function getStudentResultByPoll(pollKey, pollCode, userId) {
        return fetchWrapper.get({
            url: `${RESPONSE_API_URL}/response/student/poll/${pollKey}/${pollCode}/${userId}`,
        }).then((result) => {
            console.log(result);
            formatAPIResponseLog("GetStudentResultByPoll", result);
            return (result);
        });
    }

   // function getResultByQuestionPerspective(pollKey, uniqueCode) {
   //      return fetchWrapper.get(
   //          {
   //              url: `${RESPONSE_API_URL}/response/result/questions/${pollKey}/${uniqueCode}`,
   //              authorizeRequired: false
   //          }
   //      ).then((result) => {
   //          return result;
   //      }).catch(err => {
   //          const errObj = JSON.parse(err.message);
   //          notifyError(errObj.errorMessage);
   //      });
   //  }


    function getResultByGradeBookPerspective(pollKey, uniqueCode) {
        // setLoading(true);
        // console.log(lmsAttempt);
        return fetchWrapper.get({
            url: `${RESPONSE_API_URL}/response/result/gradeBook/${pollKey}/${uniqueCode}`,
            authorizeRequired: false
        }).then((result) => {
            formatAPIResponseLog("GetResultByGradeBookPerspective", result);
            return result;
        }).catch(err => {
            console.log(err);
            const errObj = JSON.parse(err.message);
            notifyError(errObj.errorMessage);
            formatAPIErrorLog("GetResultByGradeBookPerspective", errObj.errorMessage);
        }).finally(() => {
            // setLoading(false);
        });
    }

    // // Function to get poll responses per student
    // // returns an array of objects found under data
    // // object contains, firstName, middleName, lastName, userName, userId, institutionId, countCorrect, countIncorrect, countNoResponse
    // function getPollResultByStudentsPerspective(pollKey, uniqueCode) {
    //     setLoading(true);
    //     return fetchWrapper.get(
    //         {
    //             url: `${RESPONSE_API_URL}/response/result/students/${pollKey}/${uniqueCode}`,
    //             authorizeRequired: false
    //         }
    //     ).then((result) => {
    //         console.log(result.data);
    //         return result;
    //     }).catch(err => {
    //         const errObj = JSON.parse(err.message);
    //         notifyError(errObj.errorMessage);
    //     }).finally(() => {
    //         setLoading(false);
    //     });
    // }

    function getClassResult(pollKey, uniqueCode, liveQuestion, callBackFunc, raw=false) {
        console.log("fetching class results");
        return fetchWrapper.get(
            {
                url: `${RESPONSE_API_URL}/response/question/${pollKey}/${uniqueCode}/${liveQuestion.serialNo}`,
                authorizeRequired: false
            }
        ).then((result) => {
            formatAPIResponseLog("GetClassResult", result);
            if (raw === "all") {
                return result; 
            }
            if (raw) {
                return result.responses ? result.responses : [];
            }
            const data = processResult(liveQuestion, result);
            if (callBackFunc) {
                callBackFunc(liveQuestion.questionType, result);
            }
            return data;
        }).catch(err => {
            console.log(err);
            formatAPIErrorLog("GetClassResult", err);
            return [];
        });
    }

    function getAllClassResult(pollKey, uniqueCode, questions=[]) {
        console.log("fetching class results");
        return fetchWrapper.get(
            {
                url: `${RESPONSE_API_URL}/response/question/${pollKey}/${uniqueCode}`,
                authorizeRequired: false
            }
        ).then((results=[]) => {
            for (const result of results) {
                 const {questionResults={}, serialNo, questionType,} = result;
                 result.rawResponse = result.questionResults.responses; 
                 const question = questions.find(({serialNo: no}) => parseInt(no) === parseInt(serialNo));
                 result.questionResults = processResult(question, questionResults);
                 result.optionsMap = question.optionsMap;
             }
            return results;
        }).catch(err => {
            console.log(err);
            formatAPIErrorLog("GetAllClassResult", err);
            return [];
        });
    }

    function getActiveStudentsInPoll(pollKey, uniqueCode) {
        return fetchWrapper.get(
            {
                url: `${RESPONSE_API_URL}/response/poll/students/${pollKey}/${uniqueCode}`,
            }
        ).then((result) => {
            formatAPIResponseLog("GetActiveStudentsInPoll", result);
            return result;
        }).catch(err => {
            const errObj = JSON.parse(err.message);
            notifyError(errObj.errorMessage);
            formatAPIErrorLog("GetActiveStudentsInPoll", errObj.errorMessage);
        });
    }


    function setStudentReaction(pollKey, uniqueCode,userId, reaction, currSerialNo) {
        const bodyObj = {reaction: [reaction], questionNumber: currSerialNo};
        return fetchWrapper.put(
            {
                url: `${RESPONSE_API_URL}/response/poll/students/${pollKey}/${uniqueCode}/${userId}`,
                body: bodyObj
            }
        ).then((result) => {
            formatAPIResponseLog("SetStudentReaction", result);
            return result;
        }).catch(err => {
            const errObj = JSON.parse(err.message);
            notifyError(errObj.errorMessage);
            formatAPIErrorLog("SetStudentReaction", errObj.errorMessage);
        });
    }

    function getPollRecordFromUniqueCode(uniqueCode) {
        // console.log("Get PollRecord from Poll unique Code");
        const bodyObj = getJoinPollBody(uniqueCode);
        return fetchWrapper.post(
            {
                url: `${RESPONSE_API_URL}/response/published/record/${uniqueCode}`,
                body: bodyObj
            }
        ).then((result) => {
            // console.log(result);
            formatAPIResponseLog("GetPollRecordFromUniqueCode", result);
            return result;
        }).catch((error) => {
            console.log(error);
            formatAPIErrorLog("GetPollRecordFromUniqueCode", error);
            return null;
        });
    }

    function getShortName() {
        let hostname = window.location.hostname;
        let shortName = '';
        if (hostname.includes("engage.yuja.com") && hostname.includes("www")) {
            shortName = hostname.indexOf('.') >= 0 ?
                hostname.substring(hostname.indexOf('.') + 1, hostname.indexOf("engage.yuja.com") - 1) :
                hostname;
        } else {
            shortName = hostname.indexOf('.') >= 0 ?
                hostname.substring(0, hostname.indexOf('.')) :
                hostname;
        }

        return shortName;
    }


    function checkUserNameExisted(pollCode, userName) {
        let shortName = getShortName();
        setLoading(true);
        return fetchWrapper.get({
            url: `${RESPONSE_API_URL}/response/user/${shortName}/${pollCode}/existed?userName=${userName}`
        }).then((res) => {
            formatAPIResponseLog("CheckUserNameExisted", res);
            return res.existed;
        }).catch(err => {
            console.log(JSON.stringify(err));
            notifyError(VALIDATE_USERNAME_ERROR);
            formatAPIErrorLog("CheckUserNameExisted", err);
            return null;
        }).finally(() => {
            setLoading(false);
        })
    }

    function getSharedQuestionList(pollKey, pollCode) {
        setLoading(true);
        return fetchWrapper.get({
            url: `${RESPONSE_API_URL}/response/shared/questions/${pollKey}/${pollCode}/`
        }).then((res) => {
            formatAPIResponseLog("GetSharedQuestionList", res);
            return res;
        }).catch(err => {
            console.log(JSON.stringify(err));
            formatAPIErrorLog("GetSharedQuestionList", err);
            if (err.message !== NETWORK_ISSUE) {
                notifyError(SHARE_LIST_ERROR);
            }
            return null;
        }).finally(() => {
            setLoading(false);
        })
    }

    function gradeQuestion(institutionId, pollKey, pollCode, userId, isCorrect, question, attemptNo) {
        setLoading(true);
        const bodyObj = {isCorrect: isCorrect, ...question, attemptNo: attemptNo};
        return fetchWrapper.put({
            url: `${RESPONSE_API_URL}/response/grade/${institutionId}/${pollKey}/${pollCode}/${question.serialNo}/${userId}`,
            body: bodyObj
        }).then((res) => {
            formatAPIResponseLog("GradeQuestion", res);
            return res;
        }).catch(err => {
            console.log(JSON.stringify(err));
            formatAPIErrorLog("GradeQuestion", err);
            notifyError(UPDATE_GRADE_ERROR);
            return null;
        }).finally(() => {
            setLoading(false);
        })
    }

    function gradeQuestionStudents(institutionId, pollKey, pollCode, isCorrect, question, selectedOptions) {
        setLoading(true);
        const bodyObj = {isCorrect: isCorrect, ...question, selectedOption: selectedOptions};
        return fetchWrapper.put({
            url: `${RESPONSE_API_URL}/response/grade/${institutionId}/${pollKey}/${pollCode}/${question.serialNo}`,
            body: bodyObj
        }).then((res) => {
            formatAPIResponseLog("GradeQuestion", res);
            return res;
        }).catch(err => {
            console.log(JSON.stringify(err));
            formatAPIErrorLog("GradeQuestion", err);
            notifyError(UPDATE_GRADE_ERROR);
            return null;
        }).finally(() => {
            setLoading(false);
        })
    }

    function updateScore(pollKey, pollCode, userId, score, attemptNo, lmsAttempt, callback) {
        // setLoading(true);
        return fetchWrapper.put({
            url: `${RESPONSE_API_URL}/response/grade/${pollKey}/${pollCode}/${userId}`,
            body: {score: score, attemptNo: attemptNo, lmsAttempt: lmsAttempt}
        }).then((res) => {
            callback(pollCode, userId, res.score);
            formatAPIResponseLog("UpdateScore", res);
            return res;
        }).catch(err => {
            console.log(JSON.stringify(err));
            notifyError(UPDATE_GRADE_ERROR);
            formatAPIErrorLog("UpdateScore", err);
            return null;
        }).finally(() => {
            // setLoading(false);
        })
    }

    function syncScore(pollKey, pollCode, userId, isSynced, isManual=false) {
        // setLoading(true);
        console.log(isSynced);
        return fetchWrapper.put({
            url: `${RESPONSE_API_URL}/response/sync/${pollKey}/${pollCode}/${userId}`,
            body: {isGraded: isSynced, isManual: isManual}
        }).then((res) => {
            formatAPIResponseLog("SyncScore", res);
            return res;
        }).catch(err => {
            console.log(JSON.stringify(err));
            formatAPIErrorLog("SyncScore", err);
            // notifyError("Cannot Update isSync!");
            return null;
        }).finally(() => {
            // setLoading(false);
        })
    }

    function reattemptPoll(institutionId, pollCode, userId, recordAttempt, reattemptTime) {
        console.log(institutionId, pollCode, userId);
        return fetchWrapper.put({
            url: `${RESPONSE_API_URL}/response/reattempt/${institutionId}/${pollCode}/${userId}`,
            body: {recordAttempt: recordAttempt, pollJoinTime: reattemptTime}
        }).then((res) => {
            formatAPIResponseLog("ReattemptPoll", res);
            return res;
        }).catch(err => {
            console.log(JSON.stringify(err));
            formatAPIErrorLog("ReattemptPoll", err);
            if (err.message === NETWORK_ISSUE) {
                notifyError(REATTEMPT_NETWORK_ERROR);
            } else {
                notifyError(REATTEMPT_POLL_ERROR);
            }
            return null;
        });
    }

    // Function to warm up lambda function
    // returns
    function fakeCall() {
        return fetchWrapper.post({
            url: `${RESPONSE_API_URL}/response/fake-call`,
        }).then((result) => {
            return result;
        }).catch(() => {
            return null;
        });
    }

    function getQuestionResult(pollKey, uniqueCode, serialNo) {
        return fetchWrapper.get(
            {
                url: `${RESPONSE_API_URL}/response/question/${pollKey}/${uniqueCode}/${serialNo}`,
                authorizeRequired: false
            }
        ).then((result) => {
            formatAPIResponseLog("GetQuestionResult", result);
            return result;
        }).catch(err => {
            console.log(JSON.stringify(err));
            formatAPIErrorLog("GetQuestionResult", err);
            return null;
        })

    }

    function getPollReaction(pollKey, uniqueCode, serialNo, pollMode) {
        return fetchWrapper.get(
            {
                url: `${RESPONSE_API_URL}/response/poll/reaction/${pollKey}/${uniqueCode}/${serialNo}/${pollMode}`,
                authorizeRequired: false
            }
        ).then((result) => {
            formatAPIResponseLog("getPollReaction", result);
            return result; 
        }).catch(err => {
            console.log(JSON.stringify(err));
            formatAPIErrorLog("getPollReaction", err);
            return null;
        })


    }

    function handleSubmitIndividualResponse(pollKey, pollCode, pollType, answer) {
        const {shortName, userId} = getJoinPollBody(pollCode);
        const {questionType} = answer;
        return fetchWrapper.put({
            url: `${RESPONSE_API_URL}/response/user/${pollCode}`,
            body: {
                shortName: shortName,
                uniqueCodePK: pollCode,
                pollKey: pollKey,
                ownerId: userId,
                answersMap: [answer],
            },
            authorizeRequired: false
        }).then(async (result) => {
            formatAPIResponseLog("handleSubmitIndividualResponse", result);

            // sync graded poll is only for non-SA question and logged-in users only
            if (pollType === POLL_TYPE.SURVEY || questionType === QUESTION_TYPES.SA.name || !getSession().userId) {
                return;
            }
            const {questions} = await getPollQuestions(pollCode);
            let earnedPointsRaw = 0;
            let totalPoints = 0;
            let res = await getAudienceResponseByUser(userId);
            if(!res.hasOwnProperty("data")) {
                res.data = [];
            }

            let currentPollRes = res.data.filter(r => r.pollKey.substring(2) === pollKey);
            for (const tempResult of currentPollRes) {
                let questionResponse = tempResult;
                // totalPoints += actualQuestions[questionResponse.serialNo - 1].weightage;
                if (!!questionResponse.attempts && questionResponse.attempts.length !== 0 && questionResponse.attempts["1"].isCorrect) {
                    earnedPointsRaw += questions[questionResponse.serialNo - 1].weightage;
                } else if (questionResponse.isCorrect) {
                    earnedPointsRaw += questions[questionResponse.serialNo - 1].weightage;
                }
            }

            for (const question of questions) {
                if (question.hasOwnProperty("shareCount") && question.shareCount > 0) {
                    totalPoints += question.weightage;
                }
            }
            if (totalPoints !== 0) {
                await gradebookSync(
                    pollCode,
                    userId,
                    calculateScore(earnedPointsRaw, totalPoints),
                    () => {
                        // This callback fnct (syncScore) updates the isGraded and syncCount attribute in the database to reflect the automatic sync
                        // that just occurred (ie. gradebookSync). This callback fnct only gets run if there are no SA questions in the poll.
                        if (!hasSA(questions)) {
                            syncScore(pollKey, pollCode, userId, true);
                        }
                    }
                );
            }
        }).catch((err) => {
            formatAPIErrorLog("handleSubmitIndividualResponse", err);
            if (err.message === NETWORK_ISSUE) {
                const copy = JSON.parse(JSON.stringify(isCookieEnabled ?  getMissedResponseStorage() : missedResponseMap));
                copy[pollCode] = {pollCode, pollKey, pollType, pollShareMode: POLL_SHARE_MODE.SHARE_EACH, response: answer};
                if (isCookieEnabled) {
                    setMissedResponseStorage(copy);
                } else {
                    setMissedResponseMap(copy);
                }
                notifyError(SUBMIT_RESPONSE_NETWORK_ERROR);
            }
        })
    }


    function handleSubmitMultiResponse(pollInfo, curQuestionNo, questions=[], answersMap) {
        const {pollKey, uniqueCode: pollCode, pollType, recordAttempt, lmsAttempt, attemptsCount} = pollInfo;
        const {shortName, userId} = getJoinPollBody(pollCode);

        return fetchWrapper.put({
            url: `${RESPONSE_API_URL}/response/user/${pollCode}`,
            body: {
                shortName: shortName,
                uniqueCodePK: pollCode,
                pollKey: pollKey,
                ownerId: userId,
                curQuestionNo: curQuestionNo,
                answersMap: answersMap,
                recordAttempt: recordAttempt,
                attemptsCount: attemptsCount
            },
            authorizeRequired: false
        }).then(async (result) => {
            formatAPIResponseLog("handleSubmitMultiResponse", result);
            // sync graded poll is only for non-SA poll and logged-in users only
            if (pollType === POLL_TYPE.SURVEY || hasSA(questions) || !getSession().userId) {
                return;
            }

            const {questionAnswer=[]} = await getPollQuestions(pollCode);
            // sending gradebook to lms
            // may be we can move this to another function and call that
            let earnedPointsRaw = 0;
            let totalPoints = 0;


            if (lmsAttempt === SYNC_ATTEMPT.HIGHEST.value) {
                let attemptNo = 1;
                while (true) {
                    let flag = true; // flag to check if all questions do not have attemptNo
                    let tempEarnedPointsRaw = 0;
                    for (const questionResponse of questionAnswer) {
                        if (!questionResponse.attempts || !questionResponse.attempts['' + attemptNo]) {
                            continue;
                        }
                        flag = false;
                        if (questionResponse.attempts['' + attemptNo].isCorrect) tempEarnedPointsRaw += questions.find(i => i.serialNo === questionResponse.serialNo)?.weightage;
                    }
                    if (flag) {
                        break;
                    }
                    //get higher point
                    if (tempEarnedPointsRaw > earnedPointsRaw) {
                        earnedPointsRaw = tempEarnedPointsRaw;
                    }
                    ++attemptNo;
                }
            } else {
                for (const questionResponse of questionAnswer) {
                    if (!!questionResponse.attempts) {
                        if (lmsAttempt === SYNC_ATTEMPT.FIRST.value) {
                            if (questionResponse.attempts["1"] &&
                                questionResponse.attempts["1"].isCorrect) earnedPointsRaw += questions.find(i => i.serialNo === questionResponse.serialNo)?.weightage;
                        } else if (lmsAttempt === SYNC_ATTEMPT.RECENT.value) {
                            if (questionResponse.attempts['' + attemptsCount] &&
                                questionResponse.attempts['' + attemptsCount].isCorrect) earnedPointsRaw += questions.find(i => i.serialNo === questionResponse.serialNo)?.weightage;
                        }
                    } else if (questionResponse.isCorrect) {
                        earnedPointsRaw += questions[questionResponse.serialNo - 1].weightage;
                    }
                }
            }
            totalPoints = questions.map(q => q.weightage).reduce((a, b) => a + b, 0);
            if (totalPoints !== 0) {
                const score = calculateScore(earnedPointsRaw, totalPoints);
                // console.log(earnedPointsRaw, totalPoints, score);
                if (score < 0) {
                    return;
                }
                gradebookSync(
                    pollCode,
                    userId,
                    score,
                    () => syncScore(pollKey, pollCode, userId, true)
                );
            }



        }).catch((err) => {
            formatAPIErrorLog("handleSubmitMultiResponse", err);
            if (err.message === NETWORK_ISSUE) {
                const copy = JSON.parse(JSON.stringify(isCookieEnabled ? getMissedResponseStorage() : missedResponseMap));
                const oldMap = !!copy[pollCode] ? copy[pollCode] : {};
                let {responses: oldResponses = []} = oldMap;
                //append responses
                for (const answer of answersMap) {
                    const responseIdx = oldResponses.findIndex(i => i.serialNo === answer.serialNo);
                    if (responseIdx >= 0) {//if existed then replace it
                        oldResponses[responseIdx] = answer;
                    } else {
                        oldResponses.push(answer);
                    }
                }

                const newResponses = oldResponses.sort((a, b) => a.serialNo - b.serialNo);
                const newMap = {...pollInfo, questions, curQuestionNo}
                newMap.responses = newResponses;
                copy[pollCode] = newMap;
                if (isCookieEnabled) {
                    setMissedResponseStorage(copy);
                } else {
                    setMissedResponseMap(copy);
                }
                notifyError(SUBMIT_RESPONSE_NETWORK_ERROR);
            }
        });
    }

    function updateReaction(pollKey, pollCode, reaction={}, attemptsCount=null, currSerialNo=null) {
        const {userId} = getJoinPollBody(pollCode);
        let body = {};
        if (attemptsCount != null) {
            body = {
                uniqueCodePK: pollCode,
                pollKey: pollKey,
                ownerId: userId,
                reactionMap: reaction,
                attemptsCount: attemptsCount
            }
        }
        else {
            body = {
                uniqueCodePK: pollCode,
                pollKey: pollKey,
                ownerId: userId,
                reactionMap: reaction,
                curQuestionNo: parseInt(currSerialNo, 10)
            }
        }
        return fetchWrapper.put({
            url: `${RESPONSE_API_URL}/response/user/${pollCode}`,
            body: body ,
            authorizeRequired: false
        }).then((result) => {
            formatAPIResponseLog("updateReaction", result);
        }).catch((err) => {
            formatAPIErrorLog("updateReaction", err);
            if (err.message === NETWORK_ISSUE) {
                const copy = JSON.parse(JSON.stringify(isCookieEnabled ? getMissedReactionMapStorage() : missedReactionMap));
                let pollConversation = !!copy[pollCode] ? copy[pollCode] : {};
                let {reactionMap: oldReaction={}} = pollConversation;
                //append reaction
                let newReactionMap; 
                if (!!attemptsCount) {
                    newReactionMap = {[attemptsCount]: {...oldReaction[attemptsCount], ...reaction[attemptsCount]}}
                }
                else {
                    let serialNo = parseInt(currSerialNo, 10); 
                    newReactionMap = {[serialNo]: {...oldReaction[serialNo], ...reaction[serialNo]}}
                }
                // const newReactionMap = {...oldReaction, ...reaction};
                pollConversation = { reactionMap: newReactionMap, pollKey, attemptsCount, currSerialNo};
                copy[pollCode] = pollConversation;
                if (isCookieEnabled) {
                    setMissedReactionMapStorage(copy);
                } else {
                    setMissedReactionMap(copy);
                }
            }
        });
    }


}