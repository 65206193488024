import React, { useCallback, useEffect, useState } from "react";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { TEXTBOX, VIEWER_MENU_LIST } from "../../utils/constants";
import styles from "./EnrolledCourses.module.css";
import EnrolledCoursesSearchForm from "./EnrolledCoursesSearchForm";
import EnrolledCoursesTable from "./EnrolledCoursesTable";

export default function ViewEnrolledCoursesPage(props) {
  const [filter, setFilter] = useState({});
  const [courseData, setCourseData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const { getCourseByStudent } = CourseApiCalls();
  const [setSession, getSession] = useLocalStorageNew("session", {});

  useEffect(() => {
    if(document.getElementById("engagePageIdentifier") != null) {
      document.getElementById("engagePageIdentifier").innerText = VIEWER_MENU_LIST[3].name;
    }
    
    const handleWindowResize = () => {
      if(document.getElementById("engagePageIdentifier") != null) {
        document.getElementById("engagePageIdentifier").innerText = VIEWER_MENU_LIST[3].name;
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const getCourses = async () => {
    setDataReady(false);
    const session = getSession();
    let res;
    if(session.idpType && session.intType) {
      res = await getCourseByStudent(true, session.idpType.toLowerCase(), session.intType.toLowerCase());
    } else {
      res = await getCourseByStudent(true);
    }

    res.sort(function (a, b) {
      return new Date(Date.parse(b.updateTime)) - new Date(Date.parse(a.updateTime));
    })
    setCourseData(res);
    setDataReady(true);
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleSubmit = useCallback(({ ...filterObj }) => {
    setFilter(filterObj);
  }, []);

  const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: 'Course Code', accessor: 'courseCode', width: isMobileScreen ? 100 : 120 },
    { Header: 'Course Name', accessor: 'courseName', width: isMobileScreen ? 100 : 120 },
    // { Header: 'Instructor\'s Name', accessor: 'instructorsName', width: 120 },
    // { Header: 'Start Date', accessor: 'startDate', width: isMobileScreen ? 120 : 140 },
  ];

  return (
    <>
      {courseData && courseData.length !== 0 || !dataReady ? (
        <>
          <div className={styles.container}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>Student Courses</div>
            </div>
            <EnrolledCoursesSearchForm handleSubmit={handleSubmit} />
            <EnrolledCoursesTable columns={columns} data={courseData} globalFilterObj={filter} getCourses={getCourses} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.c2}>
            <div className={styles.titleBox}>
              <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>You are not enrolled in any courses.</div>
            </div>
            <div tabIndex={0} role={TEXTBOX} className={styles.text}>Contact a prof to be added to a course.</div>
          </div>
        </>
      )}
    </>
  );
}