import { Spin } from "../../components/standardization/YuJaLoading";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { PLEASE_SELECT_POLL, POLL_TITLE_DROPDOWN_LENGTH, POLL_TYPE, VIEWER_MENU_LIST, VIEW_TAKEN_POLLS_TITLE, YOUR_ANSWER_TITLE, LMS_GRADEBOOK_SYNC, BUTTON, CLOSE_MODAL, TEXTBOX} from "../../utils/constants";
import TakenPollTable from "./TakenPollTable";
import styles from "./ViewTakenPollsPage.module.css";


export default function ViewTakenPollsPage(props) {
  const[, getHostResource] = useLocalStorageNew(window.location.hostname, {});
  let institutionId = getHostResource().institutionId;

  const [publishedPollDict, setPublishedPollDict] = useState({});
  const { getPollsTakenByStudent, getStudentResultByPoll, getAudienceResultByUser} = ResponseApiCalls();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedPollUniqueKey, setSelectedPollUniqueKey] = useState("select");
  const [selectedPoll, setSelectedPoll] = useState({});
  const [selectedPollInitialized, setSelectedPollInitialized] = useState(false);
  // const [showLongTitle, setShowLongTitle] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [pollList, setPollList] = useState([]);
  const [, getSession] = useLocalStorageNew("session", {});
  const session = getSession();
  const location = useLocation();

  useEffect(() => {
    if(document.getElementById("engagePageIdentifier") != null) {
      document.getElementById("engagePageIdentifier").innerText = VIEWER_MENU_LIST[2].name;
    }
    
    const handleWindowResize = () => {
      if(document.getElementById("engagePageIdentifier") != null) {
        document.getElementById("engagePageIdentifier").innerText = VIEWER_MENU_LIST[2].name;
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const checkGraded = (poll, grades) => {
    let pollCode = poll.sk.split("#")[2];
    let grade = grades[pollCode];
    if (grade && poll.lmsAttempt) {
      for (const result of poll.questions) {
        let questionResponse = result;
        if (questionResponse.attempts && poll.lmsAttempt === LMS_GRADEBOOK_SYNC.RECENT.value) {
          const entries = Object.values(questionResponse.attempts);
          const lastEntry = entries[entries.length - 1];
          if (lastEntry.isGraded === false) {
            return false; 
          }
        }
        else if (questionResponse.attempts && poll.lmsAttempt === LMS_GRADEBOOK_SYNC.FIRST.value) {
          const entries = Object.values(questionResponse.attempts);
          const lastEntry = entries[0];
          if (lastEntry.isGraded === false) {
            return false; 
          }
        }
        else if (questionResponse.attempts && poll.lmsAttempt === LMS_GRADEBOOK_SYNC.HIGHEST.value) {
          const entries = Object.values(grade);
          let highest = 0;
          let highest_index = 0;
          for (let i = 0; i < entries.length; i++) {
            let score = parseInt(parseFloat(entries[i].score), 10);
            if (score > highest) {
              highest = score;
              highest_index = i;
            }
          }
          const curr_entries = Object.values(questionResponse.attempts);
          const lastEntry = curr_entries[highest_index];
          if (lastEntry.isGraded === false) {
            return false; 
          }
        }
    }
    return true; 
    }
    else {
      for (const result of poll.questions) {
        let questionResponse = result;
        if (!questionResponse.isGraded) {
          return false; 
        }
        else if (questionResponse.attempts) {
          const entries = Object.values(questionResponse.attempts);
          const lastEntry = entries[0];
          if (lastEntry.isGraded === false) {
            return false; 
          }
        }
      }
      return true;
  }
  };

  const getPollData = async () => {
    if (!session.userId) {
      setPollList([]);
      console.log("error with login details");
      return;
    }

    let {publishedPolls, grades} = await getAudienceResultByUser(institutionId, session.userId, true);
    publishedPolls = publishedPolls.map(p => {
      p.pollKey = p.pk.split("#")[1];
      p.pollCode = p.sk.split("#")[2];
      return p;
    });
    if (session.courseId) {
      publishedPolls = publishedPolls.filter(poll => (poll.index2Pk === "C#"+session.courseId));
    }
    publishedPolls = publishedPolls.filter(poll => (
      !(
        poll.pollShareMode === "schedule" &&
        new Date() < new Date(poll.endTime)
      )
    ));

    for(let element of  publishedPolls) {
      let isGraded = checkGraded(element, grades);
      element.studentResult = isGraded;
    }

    publishedPolls = publishedPolls.filter(poll => poll.studentResult !== false);

    const publishedPollDict = publishedPolls.reduce((obj, item) => {
      return {
        ...obj,
        [item["pollKey"] + "#" + item["pollCode"]]: item,
      };
    }, {});

    setPollList(publishedPolls);
    setPublishedPollDict(publishedPollDict);
    return {publishedPolls, publishedPollDict}
    // setPollData(res.data);
  };

  const choosePoll = async (option) => {
    setSelectedOption(option);
    setSelectedPollInitialized(false);
    setSelectedPollUniqueKey(option.value);

    const publishedPoll = publishedPollDict[option.value];
    if (!publishedPoll) {
      return;
    }

    const { questions, synced } = await getStudentResultByPoll(publishedPoll.pollKey, publishedPoll.pollCode, session.userId);
    publishedPoll.questions = questions;
    publishedPoll.synced = synced;
    publishedPoll.pollType = publishedPoll.pollType ? publishedPoll.pollType : POLL_TYPE.GRADED_POLL;
    setSelectedPoll(publishedPoll);
    setSelectedPollInitialized(true);
  }


  const columns = [
    { Header: 'Number', accessor: 'serialNo', width: 50 },
    { Header: 'Question', accessor: 'queTitle', width: 250 },
    { Header: 'Type', accessor: 'questionType', width: 140 },
    { Header: 'Status', accessor: 'isCorrect', width: 140 },
    { Header: 'Your Answer', accessor: 'selectedOption', width: 250 },
    // { Header: 'Correct Answer', accessor: 'correctAnswers', width: isMobileScreen ? 100 : 120 },
  ];

  useEffect(async () => {
    const {publishedPollDict} = await getPollData();
    if (location && location.state && location.state.pollKey && location.state.uniqueCode) {
      const key = location.state.pollKey + "#" + location.state.uniqueCode;
      setSelectedPollUniqueKey(key);
      const publishedPoll = publishedPollDict[key];
      if (!publishedPoll) {
        return;
      }
      const pollTitle = publishedPoll.pollTitle;
      const label = ((pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : pollTitle) + " (" + location.state.uniqueCode + ")";
      setSelectedOption({value: key, label: label, pollTitle: pollTitle, trimmedTitle: pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH, pollType: publishedPoll.pollType});

      setSelectedPollInitialized(false);
      const { questions, synced } = await getStudentResultByPoll(publishedPoll.pollKey, publishedPoll.pollCode, session.userId);
      publishedPoll.questions = questions;
      publishedPoll.synced = synced;
      setSelectedPoll(publishedPoll);
      setSelectedPollInitialized(true);
    }
  }, [location]);

  // useEffect(() => {
  //   setShowLongTitle(!!selectedOption && selectedOption.trimmedTitle);
  // }, [selectedOption]);

  return (
    <>
      <Modal
				id="answer-modal"
				show={showAnswerModal}
				onHide={() => setShowAnswerModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={true}
				dialogClassName="answer-modal"
      >
				<Spin tip="Loading..." size="large" spinning={false}>
					<ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={() => setShowAnswerModal(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0} role={TEXTBOX}>
							{YOUR_ANSWER_TITLE}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ wordBreak: "break-all" }}>
						{<div tabIndex={0} className={styles.answer}>{modalData}</div>}
					</Modal.Body>
				</Spin>
			</Modal>
      <div className={styles.container}>
        <div className={styles.titleBox}>
          <div className={styles.titleBoxIcon} />
          <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>{VIEW_TAKEN_POLLS_TITLE}</div>
        </div>

        {/* <div style={{ margin: "2%", width: "100%" }}>
          <Form >
            <Form.Label>Please select a poll</Form.Label>
            <Form.Select name="pollName"
              value={selectedPollUniqueKey}
              onChange={choosePoll}>
              <option id="sel" value="select">select...</option>
              {pollList.map((key) => {
                return <option
                  value={`${key.pollKey}#${key.pollCode}`}
                  key={`${key.pollKey}#${key.pollCode}`}>
                  {key.pollTitle} - {key.pollCode}
                </option>
              })}
            </Form.Select>
          </Form>
        </div> */}

        <div className={styles.searchBox}>
          <label tabIndex={0} for="react-select-3-input" role={TEXTBOX} style={{ marginRight: "10px" }}>{PLEASE_SELECT_POLL}</label>
          <Select
            className={styles.select}
            options={pollList.map((key) => {
              return {
                value: `${key.pollKey}#${key.pollCode}`,
                label: ((key.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (key.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : key.pollTitle) +
                          " (" + key.pollCode + ")",
                pollTitle: key.pollTitle,
                trimmedTitle: key.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH,
                pollType: key.pollType ? key.pollType : POLL_TYPE.GRADED_POLL
              }
            })}
            onChange={choosePoll}
            value={selectedOption}
          />
        </div>
        
        {!!selectedOption && !!selectedOption.pollTitle &&
        <>
          <div tabIndex={0} role={TEXTBOX} className={styles.pollTitleTitle}>{selectedOption.pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"} Title:</div>
          <div tabIndex={0} role={TEXTBOX} className={styles.pollTitleText}>{selectedOption.pollTitle}</div>
        </>
        }

        {selectedPollUniqueKey !== "select" && selectedPollInitialized &&
          <TakenPollTable
            institutionId={institutionId}
            columns={columns}
            selectedPoll={selectedPoll}
            setShowAnswerModal={setShowAnswerModal}
            setModalData={setModalData}  
          />}
      </div>
    </>
  );
}