import { PUBLIC_ACCESS_API_URL } from "../utils/properties";
import { useFetchWrapper } from "../hooks/useFetchWrapper";
import {useLoading} from "../utils/LoadingContext";
import { notifySuccess, notifyError, formatAPIErrorLog, formatAPIResponseLog } from "../utils/helpers";
import { SUPPORT_REQUEST_ERROR, SUPPORT_REQUEST_SUCCESS } from "../utils/toast-message-constants";
const defaultInstitutionId = "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615";

export const PublicApiCall = () => {
    const fetchWrapper = useFetchWrapper();

    const {setLoading} = useLoading();

    return {
        resolveShortName,
        checkPollInCourse,
        supportRequest,
        getInstitutionDetails
    }

    // Function that handles login
    // Does not return any data, sets all the session data in the function
    function resolveShortName(shortName) {

        // set user in local storage, and go back to the previous page
        return fetchWrapper.get(
            {
                url: `${PUBLIC_ACCESS_API_URL}/public/inst?shortName=${shortName}`,
                authorizeRequired: false
            }
        ).then(res => {
            if (res.success) {
                return res.result;
            }
        }).catch(err => {
            console.log(err);
            return {institutionId: defaultInstitutionId};
        });
    }

    function checkPollInCourse(pollCode) {
        setLoading(true);
        return fetchWrapper.get(
            {
                url: `${PUBLIC_ACCESS_API_URL}/public/poll/${pollCode}/course`,
                authorizeRequired: false
            }
        ).then(res => {
            if (res.success) {
                formatAPIResponseLog("CheckPollInCourse", res.result);
                return res.result;
            }
        }).catch(err => {
            console.log(err);
            formatAPIErrorLog("CheckPollInCourse", err);
            return false;
        }).finally(() => {
            setLoading(false);
        });
    }

    function supportRequest(name, email, subject, message) {
        setLoading(true);
        return fetchWrapper.post(
            {
                url: `${PUBLIC_ACCESS_API_URL}/public/support`,
                body: {
                    "name": name ,
                    "email": email,
                    "subject": subject,
                    "message": message
                }
            }
        ).then(res => {
            if (res.success) {
                notifySuccess(SUPPORT_REQUEST_SUCCESS);
            }
            else {
                notifyError(SUPPORT_REQUEST_ERROR);
            }
            return res.result;
        }).catch(err => {
            console.log(err);
            return false;
        }).finally(() => {
            setLoading(false);
        });
    }

    function getInstitutionDetails(shortname) {
        return fetchWrapper.get({
            url: `${PUBLIC_ACCESS_API_URL}/public/inst?shortName=${shortname}`,
            authorizeRequired: false
        }).then((res) => {
            if (res.success) {
                return res.result;
            }
        }).catch(error => {
            console.log(error);
        });
    }
}
