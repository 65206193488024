import {ReactComponent as ArrowLeft} from "../images/ArrowCircleLeft.svg";
import {ReactComponent as ArrowRight} from "../images/ArrowCircleRight.svg";
import styles from "../layouts/Layout.module.css";
import {
    ANONYMOUS_JOIN_MODAL_TITLE,
    CREATE_POLL_PATH,
    CREATOR_MENU_LIST,
    GRADED_POLL,
    NAVBAR_ENGAGE,
    SURVEY,
    VIEWER_MENU_LIST,
    ROLES,
    POLL_TYPE,
    EDIT_POLL_PATH, IDENTITY_PROVIDER_TYPES, BUTTON, IMG, LOGOUT_LABEL, EXPAND_SIDEBAR, COLLAPSE_SIDEBAR, JOIN_POLL_PATH
} from "../utils/constants";
import {useHistory, useLocation} from "react-router-dom";
import {useLocalStorageNew} from "../hooks/useLocalStorageNew";
import {useState, useEffect} from "react";
import WarningModal from "./modals/WarningModal";
import JoinPollModal from "./modals/JoinPollModal";
import {ReactComponent as Down} from "../images/links/down.svg";
import {ReactComponent as ActiveDown} from "../images/links/active_down.svg";
import {ReactComponent as Up} from "../images/links/up.svg";
import {ReactComponent as ActiveUp} from "../images/links/active_up.svg";
import  SurveyIcon from "../images/links/create_survey.svg";
import ActiveSurveyIcon from "../images/links/active_create_survey_icon.svg";
import ActiveCreatePollIcon from "../images/links/active_create_polls_icon.svg";
import CreatePollIcon from "../images/links/create_poll_icon.svg";
import { YuJaTooltip } from "./standardization/YuJaTooltip";
import { ReactComponent as Logout } from "../images/logout_mobile.svg";
import { AuthenticationApiCalls } from "../apis/AuthenticationApiCalls";
import { updateCreateMode, updateShowCreateOptions, CREATE_MODE, SHOW_CREATE_OPTIONS } from "../utils/helpers";


export default function NewSidebar({sideBarCollapseAble, isCreator=false, isViewer=false, isMobile=false, collapsed, setCollapsed}) {
    const history = useHistory();
    const [showCreateOptions, setShowCreateOptions] = useState(SHOW_CREATE_OPTIONS);
    const [, getSession] = useLocalStorageNew("session", {});
    const { pathname } = useLocation();
    const session = getSession();
    const { role = 'ANONYMOUS'} = session;
    const isEmbeddedFrame = (session.idpType && session.idpType === IDENTITY_PROVIDER_TYPES.LMS && window.location !== window.parent.location);
    const [code, setCode] = useState("");
    const [joinModalShow, setJoinModalShow] = useState(false);
    const [warningModalShow, setWarningModalShow] = useState(false);
    const { logout } = AuthenticationApiCalls();

    useEffect(() => {
        if(window.innerWidth >= 1200 && !sideBarCollapseAble) {
            setCollapsed(false);
        }
        if(window.innerWidth < 1200) {
            setCollapsed(true);
        }
    }, [window.innerWidth]);

    const mouseOver = (e, name) => {
        const menu = CREATOR_MENU_LIST.find(i => i.name === name);
        if (window.location.pathname === menu?.path) {
            return;
        }
    }

    const mouseLeave = (e, name) => {
        const menu = CREATOR_MENU_LIST.find(i => i.name === name);
        if (window.location.pathname === menu?.path) {
            return;
        }
        const img = document.getElementById(`menu-icon-${name}`);
        if (!!img) {
            img.src = menu?.icon;
        }
    };

    const mouseOverViewer = (e, name) => {
        const menu = VIEWER_MENU_LIST.find(i => i.name === name);
        if (window.location.pathname === menu?.path) {
            return;
        }
    };

    const mouseLeaveViewer = (e, name) => {
        const menu = VIEWER_MENU_LIST.find(i => i.name === name);
        if (window.location.pathname === menu?.path) {
            return;
        }
        const img = document.getElementById(`menu-icon-${name}`);
        if (!!img) {
            img.src = menu?.icon;
        }
    };

    const isActiveCreatePollPage = () => {
        if (window.location.pathname === CREATE_POLL_PATH || window.location.pathname.includes(EDIT_POLL_PATH)) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (window.location.pathname.includes(JOIN_POLL_PATH)) {
            setCollapsed(true);
        }
    }, [window])

    return (<>
        {isCreator && window.innerWidth >= 1200 &&
            <div className={styles.sideBarContainer} style={{width: (collapsed ? "60px" : "240px")}}>
                <div className={styles.sidebarMenu}>
                    <div style={{padding: "20px 5px 0 5px"}}>
                        <div className={styles.sidebarTitle}>
                            {!collapsed && <div>{NAVBAR_ENGAGE}</div>}
                            {!collapsed && (sideBarCollapseAble || window.innerWidth < 1200) && <div style={{cursor: "pointer"}} tabIndex={0} aria-label={COLLAPSE_SIDEBAR} role={BUTTON} onClick={() => {setCollapsed(true)}}>
                                    <ArrowLeft/>
                            </div>}
                            {collapsed && <div style={{cursor: "pointer"}} aria-label={EXPAND_SIDEBAR} role={BUTTON} tabIndex={0} onClick={() => {setCollapsed(false)}}>
                                <ArrowRight/>
                            </div>}
                        </div>
                        <div className={styles.sidebarDivideLine}/>
                        {CREATOR_MENU_LIST.filter(menu => menu.roles.includes(role)).map((menu, idx) => (
                            <div
                                key={idx}
                                onMouseOver={(e) => mouseOver(e, menu.name)}
                                onMouseLeave={(e) => mouseLeave(e, menu.name)}
                            > 
                                {menu.name === CREATOR_MENU_LIST[1].name
                                    ?
                                    <>
                                        <div
                                            tabIndex={0}
                                            aria-label={menu.name}
                                            role={BUTTON}
                                            id={`menu-link-${idx}`}
                                            onClick={() => {
                                                setShowCreateOptions(collapsed ? true : !showCreateOptions);
                                                updateShowCreateOptions(collapsed ? true : !showCreateOptions);
                                                setCollapsed(false);
                                            }}
                                            className={`${(isActiveCreatePollPage() && collapsed) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                            hidden={!menu.roles.includes(role)}
                                            style={{display: "flex", justifyContent: "space-between"}}
                                        >
                                            <div style={{width: "100%"}}>
                                                {!collapsed && <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={menu.icon}
                                                        style={{width: "50px", height: "18px", marginRight: "5px"}}
                                                        alt={`${menu.name} icon`}
                                                    />
                                                    <span className={styles.sidebarText}>{menu.name}</span>
                                                </div>}
                                                {collapsed && <YuJaTooltip placement={"right"} text={menu.name}><div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={isActiveCreatePollPage() ? menu.activeIcon : menu.icon}
                                                        style={{height: "18px"}}
                                                        alt={`${menu.name} icon`}
                                                        className={`${isActiveCreatePollPage() ? styles.activeSubIcon: ""}`}
                                                    />
                                                </div></YuJaTooltip>}
                                            </div>
                                            {!collapsed && showCreateOptions && window.location.pathname === menu.path &&
                                                <ActiveUp style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                            }
                                            {!collapsed &&  showCreateOptions && window.location.pathname !== menu.path &&
                                                <Up style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                            }

                                            {!collapsed &&  !showCreateOptions && window.location.pathname === menu.path &&
                                                <ActiveDown style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                            }
                                            {!collapsed && !showCreateOptions && window.location.pathname !== menu.path &&
                                                <Down style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                            }
                                        </div>
                                        {!collapsed && showCreateOptions &&
                                            <div className={styles.createOptionWrap}>
                                                <span
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        updateCreateMode(POLL_TYPE.GRADED_POLL);
                                                        history.push({
                                                            pathname: CREATE_POLL_PATH,
                                                            state: {
                                                                pollType: GRADED_POLL,
                                                                fromNavbar: true,
                                                                previous: pathname
                                                            }
                                                        });
                                                    }}
                                                    className={`${(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.GRADED_POLL) ? styles.activeSideBarLink : styles.createOption}`}
                                                >
                                                    <div>
                                                        <img
                                                            id={`menu-icon-${menu.name}`}
                                                            src={(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.GRADED_POLL) ? ActiveCreatePollIcon : CreatePollIcon}
                                                            style={{width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px"}}
                                                            alt={`${menu.name} poll icon`}
                                                            className={`${(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.GRADED_POLL) ? styles.activeSubIcon: ""}`}
                                                        />
                                                        <span className={styles.sidebarText}>Create Poll</span>
                                                    </div>
                                                </span>
                                                <span
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        updateCreateMode(POLL_TYPE.SURVEY);
                                                        history.push({
                                                            pathname: CREATE_POLL_PATH,
                                                            state: {
                                                                pollType: SURVEY,
                                                                fromNavbar: true,
                                                                previous: pathname
                                                            }
                                                        });
                                                    }}
                                                    className={`${(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.SURVEY) ? styles.activeSideBarLink : styles.createOption}`}
                                                >
                                                   <div>
                                                        <img
                                                            id={`menu-icon-${menu.name}`}
                                                            src={(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.SURVEY) ? ActiveSurveyIcon : SurveyIcon}
                                                            style={{width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px"}}
                                                            alt={`${menu.name} survey icon`}
                                                            className={`${(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.SURVEY) ? styles.activeSubIcon: ""}`}
                                                        />
                                                        <span className={styles.sidebarText}>Create Survey</span>
                                                    </div>
                                                </span>
                                            </div>
                                        }
                                    </>
                                    :
                                    <span
                                        tabIndex={0}
                                        aria-label={menu.name}
                                        role={BUTTON}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            history.push({pathname: menu.path});
                                        }}
                                        className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                    >
                                        {!collapsed && <div>
                                            <img
                                                id={`menu-icon-${menu.name}`}
                                                src={(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? menu.activeIcon : menu.icon}
                                                style={{width: "50px", height: "18px", marginRight: "5px"}}
                                                alt={`${menu.name} icon`}
                                                className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSubIcon: ""}`}
                                            />
                                            <span className={styles.sidebarText}>{menu.name}</span>
                                        </div>}
                                        {collapsed && <YuJaTooltip placement={"right"} text={menu.name}>
                                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                            <img
                                                id={`menu-icon-${menu.name}`}
                                                src={(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? menu.activeIcon : menu.icon}
                                                style={{height: "18px"}}
                                                alt={`${menu.name} icon`}
                                                className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSubIcon: ""}`}
                                            />
                                        </div>
                                        </YuJaTooltip>}
                                    </span>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        }
        {isViewer && window.innerWidth >= 1200 &&
            <div className={styles.sideBarContainerViewer} style={{width: (collapsed ? "60px" : "240px")}}>
            <div className={styles.sidebarMenu}>
                <div style={{padding: "20px 5px 0 5px"}}>
                    <div className={styles.sidebarTitle}>
                        {!collapsed && <div>{NAVBAR_ENGAGE}</div>}
                        {!collapsed && sideBarCollapseAble && <div style={{cursor: "pointer"}} aria-label={COLLAPSE_SIDEBAR} tabIndex={0} role={BUTTON} onClick={() => {setCollapsed(true)}}>
                                <ArrowLeft/>
                        </div>}
                        {collapsed && <div style={{cursor: "pointer"}} aria-label={EXPAND_SIDEBAR} role={BUTTON} tabIndex={0} onClick={() => {setCollapsed(false)}}>
                            <ArrowRight/>
                        </div>}
                    </div>
                    <div className={styles.sidebarDivideLine}/>
                    {VIEWER_MENU_LIST.filter(menu => menu.roles.includes(role)).map((menu, idx) => (
                        <div
                            key={idx}
                            onMouseOver={(e) => mouseOverViewer(e, menu.name)}
                            onMouseLeave={(e) => mouseLeaveViewer(e, menu.name)}
                        >
                                <span
                                    tabIndex={0}
                                    aria-label={menu.name}
                                    role={BUTTON}
                                    id={`menu-link-${menu.name}`}
                                    onClick={() => {
                                        if(menu.name === ANONYMOUS_JOIN_MODAL_TITLE) {
                                            setJoinModalShow(true);
                                        }
                                        else {
                                            history.push({pathname: menu.path});
                                        }
                                    }}
                                    className={`${window.location.pathname === menu.path ? styles.activeSideBarLink : styles.sidebarLink}`}
                                    hidden={!menu.roles.includes(role)}
                                >
                                    {!collapsed && <div>
                                        <img
                                            id={`menu-icon-${menu.name}`}
                                            src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                            style={{width: "50px", height: "18px", marginRight: "5px"}}
                                            alt={`${menu.name} icon`}
                                            className={`${window.location.pathname === menu.path ? styles.activeSubIcon: ""}`}
                                        />
                                        <span className={styles.sidebarText}>{menu.name}</span>
                                    </div>}
                                    {collapsed && <YuJaTooltip placement={"right"} text={menu.name}><div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
                                        <img
                                            role={IMG}
                                            id={`menu-icon-${menu.name}`}
                                            src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                            style={{height: "18px"}}
                                            alt={`${menu.name} icon`}
                                            className={`${window.location.pathname === menu.path ? styles.activeSubIcon: ""}`}
                                        />
                                    </div></YuJaTooltip>}
                                </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>}
        {isViewer && isMobile && 
            <div className={styles.sideBarContainerViewer} style={{width: "240px"}}>
            <div className={styles.sidebarMenu}>
                <div style={{padding: "20px 5px 0 5px"}}>
                    <div className={styles.sidebarTitle}>
                        <div>{NAVBAR_ENGAGE}</div>
                        {role !== ROLES.ANONYMOUS.value && !isEmbeddedFrame &&
                            <div>
                                <Logout style={{cursor: "pointer", marginRight: "5px"}} aria-label={LOGOUT_LABEL} role={BUTTON} tabIndex={0} onClick={logout}/>
                            </div>
                        }
                    </div>
                    <div className={styles.sidebarDivideLine}/>
                    {VIEWER_MENU_LIST.filter(menu => menu.roles.includes(role)).map((menu, idx) => (
                        <div
                            key={idx}
                            onMouseOver={(e) => mouseOverViewer(e, menu.name)}
                            onMouseLeave={(e) => mouseLeaveViewer(e, menu.name)}
                        >
                                <span
                                    tabIndex={0}
                                    aria-label={menu.name}
                                    role={BUTTON}
                                    id={`menu-link-${menu.name}`}
                                    onClick={() => {
                                        if(menu.name === ANONYMOUS_JOIN_MODAL_TITLE) {
                                            setJoinModalShow(true);
                                        }
                                        else {
                                            history.push({pathname: menu.path});
                                        }
                                        setCollapsed(true);
                                    }}
                                    className={`${window.location.pathname === menu.path ? styles.activeSideBarLink : styles.sidebarLink}`}
                                    hidden={!menu.roles.includes(role)}
                                >
                                    <div>
                                        <img
                                            id={`menu-icon-${menu.name}`}
                                            src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                            style={{width: "50px", height: "18px", marginRight: "5px"}}
                                            alt={`${menu.name} icon`}
                                            className={`${window.location.pathname === menu.path ? styles.activeSubIcon: ""}`}
                                        />
                                        <span className={styles.sidebarText}>{menu.name}</span>
                                    </div>
                                </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>}


        {isCreator && isMobile &&
            <div className={styles.sideBarContainerViewer} style={{width: "240px"}}>
                <div className={styles.sidebarMenu}>
                    <div style={{padding: "20px 5px 0 5px"}}>
                        <div className={styles.sidebarTitle}>
                            <div>{NAVBAR_ENGAGE}</div>
                            {role !== ROLES.ANONYMOUS.value && !isEmbeddedFrame &&
                                <div>
                                    <Logout style={{cursor: "pointer", marginRight: "5px"}} aria-label={LOGOUT_LABEL} role={BUTTON} tabIndex={0} onClick={logout}/>
                                </div>
                            }
                        </div>
                        <div className={styles.sidebarDivideLine}/>
                        {CREATOR_MENU_LIST.filter(menu => menu.roles.includes(role)).map((menu, idx) => (
                            <div
                                key={idx}
                                onMouseOver={(e) => mouseOver(e, menu.name)}
                                onMouseLeave={(e) => mouseLeave(e, menu.name)}
                            >
                                {menu.name === CREATOR_MENU_LIST[1].name
                                    ?
                                    <>
                                        <div
                                            tabIndex={0}
                                            id={`menu-link-${idx}`}
                                            onClick={() => {
                                                setShowCreateOptions(collapsed ? true : !showCreateOptions);
                                                updateShowCreateOptions(collapsed ? true : !showCreateOptions);
                                                setCollapsed(false);
                                            }}
                                            className={`${(isActiveCreatePollPage() && collapsed) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                            hidden={!menu.roles.includes(role)}
                                            style={{display: "flex", justifyContent: "space-between"}}
                                        >
                                            <div style={{width: "100%"}}>
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                                        style={{width: "50px", height: "18px", marginRight: "5px"}}
                                                        alt={`${menu.name} icon`}
                                                        className={`${window.location.pathname === menu.path ? styles.activeSubIcon: ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{menu.name}</span>
                                                </div>
                                            </div>
                                            {!collapsed && showCreateOptions && window.location.pathname === menu.path &&
                                                <ActiveUp style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                            }
                                            {!collapsed &&  showCreateOptions && window.location.pathname !== menu.path &&
                                                <Up style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                            }

                                            {!collapsed &&  !showCreateOptions && window.location.pathname === menu.path &&
                                                <ActiveDown style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                            }
                                            {!collapsed && !showCreateOptions && window.location.pathname !== menu.path &&
                                                <Down style={{width: "18px", cursor: "pointer", marginRight: "5px"}}/>
                                            }
                                        </div>
                                        {!collapsed && showCreateOptions &&
                                            <div className={styles.createOptionWrap}>
                                                <span
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        updateCreateMode(POLL_TYPE.GRADED_POLL);
                                                        history.push({
                                                            pathname: CREATE_POLL_PATH,
                                                            state: {
                                                                pollType: GRADED_POLL,
                                                                fromNavbar: true,
                                                                previous: pathname
                                                            }
                                                        });
                                                    }}
                                                    className={`${(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.GRADED_POLL) ? styles.activeSideBarLink : styles.createOption}`}
                                                >
                                                    <div>
                                                        <img
                                                            id={`menu-icon-${menu.name}`}
                                                            src={(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.GRADED_POLL) ? ActiveCreatePollIcon : CreatePollIcon}
                                                            style={{width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px"}}
                                                            alt={`${menu.name} icon`}
                                                            className={`${(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.GRADED_POLL) ? styles.activeSubIcon: ""}`}
                                                        />
                                                        <span className={styles.sidebarText}>Create Poll</span>
                                                    </div>
                                                </span>
                                                <span
                                                    tabIndex={0}
                                                    onClick={() => {
                                                        updateCreateMode(POLL_TYPE.SURVEY);
                                                        history.push({
                                                            pathname: CREATE_POLL_PATH,
                                                            state: {
                                                                pollType: SURVEY,
                                                                fromNavbar: true,
                                                                previous: pathname
                                                            }
                                                        });
                                                    }}
                                                    className={`${(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.SURVEY) ? styles.activeSideBarLink : styles.createOption}`}
                                                >
                                                   <div>
                                                        <img
                                                            id={`menu-icon-${menu.name}`}
                                                            src={(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.SURVEY) ? ActiveSurveyIcon : SurveyIcon}
                                                            style={{width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px"}}
                                                            alt={`${menu.name} icon`}
                                                            className={`${(isActiveCreatePollPage() && CREATE_MODE === POLL_TYPE.SURVEY) ? styles.activeSubIcon: ""}`}
                                                        />
                                                        <span className={styles.sidebarText}>Create Survey</span>
                                                    </div>
                                                </span>
                                            </div>
                                        }
                                    </>
                                    :
                                    <span
                                        tabIndex={0}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            history.push({pathname: menu.path});
                                        }}
                                        className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                    >
                                        <div>
                                            <img
                                                id={`menu-icon-${menu.name}`}
                                                src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                                style={{width: "50px", height: "18px", marginRight: "5px"}}
                                                alt={`${menu.name} icon`}
                                                className={`${window.location.pathname === menu.path ? styles.activeSubIcon: ""}`}
                                            />
                                            <span className={styles.sidebarText}>{menu.name}</span>
                                        </div>
                                    </span>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>}
        <JoinPollModal
          code={code}
          setCode={setCode}
          modalShow={joinModalShow}
          setModalShow={setJoinModalShow}
          setWarningModalShow={setWarningModalShow}
      />
      {warningModalShow && <WarningModal
        show={warningModalShow}
        setModalShow={setWarningModalShow}
        code={code}
        usecase={"2"}
    />}
    </>)
};
