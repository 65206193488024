import ActiveBranding from "../images/links/active_branding_icon.svg";
import ActiveCreatePoll from "../images/links/active_create_group_icon.svg";
import ActiveGradeBook from "../images/links/active_gradebook_icon.svg";
import ActiveHome from "../images/links/active_home_icon.svg";
import ActiveIntegration from "../images/links/active_integration_icon.svg";
import ActiveJoinPoll from "../images/links/active_join_poll_icon.svg";
import ActiveManageCourses from "../images/links/active_manage_courses_icon.svg";
import ActiveManagePolls from "../images/links/active_manage_polls_icon.svg";
import ActiveManageUsers from "../images/links/active_manage_users_icon.svg";
import ActiveSettings from "../images/links/active_settings_icon.svg";
import Branding from "../images/links/branding_icon.svg";
import CreatePoll from "../images/links/create_group_icon.svg";
import GradeBook from "../images/links/gradebook_icon.svg";
import GradientBranding from "../images/links/gradient_branding_icon.svg";
import GradientCreatePoll from "../images/links/gradient_create_poll_icon.svg";
import GradientGradeBook from "../images/links/gradient_gradebook_icon.svg";
import GradientHome from "../images/links/gradient_home_icon.svg";
import GradientIntegration from "../images/links/gradient_integration_icon.svg";
import GradientJoinPoll from "../images/links/gradient_join_poll_icon.svg";
import GradientManageCourses from "../images/links/gradient_manage_courses_icon.svg";
import GradientManagePolls from "../images/links/gradient_manage_polls_icon.svg";
import GradientManageUsers from "../images/links/gradient_manage_users_icon.svg";
import GradientSettings from "../images/links/gradient_settings_icon.svg";
import Home from "../images/links/home_icon.svg";
import Integration from "../images/links/integration_icon.svg";
import JoinPoll from "../images/links/join_poll_icon.svg";
import ManageCourses from "../images/links/manage_courses_icon.svg";
import ManagePolls from "../images/links/manage_polls_icon.svg";
import ManageUsers from "../images/links/manage_users_icon.svg";
import Settings from "../images/links/settings_icon.svg";
import Login from "../images/login.svg";

export const REQUIRED_FIELD_ERROR = "this field is required";
export const INVALID_POLL_CODE_ERROR = "Invalid poll code";
export const CANNOT_ACCESS_POLL_ERROR = "User cannot access the poll.";
export const INVALID_QUESTION_TYPE = "Invalid question type";
export const UNAUTHORIZED_ERROR = "Sorry, Unauthorized, please Login first!";
export const SESSION_MISSED_ERROR = "Sorry, Please login first!";
export const SESSION_EXPIRED_ERROR = "Sorry, session is expired, Please re-login!";
export const PERMISSION_VALIDATE_ERROR = "Sorry, You don't have permission to access this page!";
export const NO_ACCESS_TO_POLL_ERROR = "You don't have access to this poll!";
export const BLANK_REGEX = /\([[a-zA-Z0-9!?@#=$&:*<>"';^_%\\\-.+,\/\ ]+\)/g;
export const GRADE_BOOK_QUESTION_COLUMN_REGEX = /^Q\d*$/;
export const ATTEMPT_REGEX = /^Attempt #\d+$/;
export const SCORE_REGEX = /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/g;

export const POLL_CODE_LENGTH = 6;
export const ANONYMOUS_USERNAME_LENGTH = 6;
export const USERNAME_LENGTH = 4;

export const DEFAULT_TIME_LIMIT = 15;
export const DEFAULT_GET_READY_TIME_LIMIT = 10;
export const DEFAULT_ENTRIES_PER_PARTICIPANT = 1;
export const DEFAULT_SHOWN_ENTRIES = 3;
export const DEFAULT_POLL_TIME_LIMIT = 60;
export const UNTIMED_POLL = -1;
export const UNTIMED_POLL_TIME_LIMIT = 24*60*60;
export const GET_READY = 11;
export const WAIT_LENGTH = 20;
export const TOTAL_LENGTH = WAIT_LENGTH + GET_READY + 3;

export const POLL_TITLE_LENGTH = 100;
export const POLL_TITLE_DROPDOWN_LENGTH = 50;
export const QUESTION_LENGTH = 1000;
export const FITB_QUESTION_LENGTH = 1000; 
export const MC_ANSWER_LENGTH = 100;
export const SA_ANSWER_LENGTH = 100;
export const FITB_ANSWER_LENGTH = 100;
export const FITB_BLANK_LIMIT = 2;
export const WC_ANSWER_LENGTH = 25; // Word Cloud Response Character Limit
export const WC_DISPLAY_LIMIT = 1; // Show View Answer if more than one entry
export const FIRST_WC_PLACEHOLDER = "Enter a word";
export const OTHER_WC_PLACEHOLDER = "Enter another word";

export const MANAGE_POLLS_PAGE_POLL_TITLE_LENGTH = 30;

export const QR_SCANNER_WIDTH = 300;
export const QR_SCANNER_HEIGHT = 300;

export const ANY = "ANY";
export const PORTRAIT = "PORTRAIT";
export const LANDSCAPE = "LANDSCAPE";

export const ROLES = {
    ADMINISTRATOR: { value: "ADMINISTRATOR", display: "Admin" },
    IT_MANAGER: { value: "IT_MANAGER", display: "IT Manager" },
    CREATOR: { value: "CREATOR", display: "Creator" },
    VIEWER: { value: "VIEWER", display: "Viewer" },
    ANONYMOUS: { value: "ANONYMOUS", display: "Anonymous" }
}

export const CREATION_ROLES = {
    IT_MANAGER: { value: "IT_MANAGER", display: "IT Manager" },
    CREATOR: { value: "CREATOR", display: "Creator" },
    VIEWER: { value: "VIEWER", display: "Viewer" },
}

export const ERROR_CODES = {
    USER_ALREDY_EXISTS: "Y009",
    INVALID_CODE: "Y008",
    EXPIRED_SESSION: "Y026",
    INVALID_SESSION: "Y027",
    INVALID_POLL_CODE: "Y029",
    EXPIRED_POLL_CODE: "Y032"
};

export const AUTHORIZATION_TYPE = "Bearer";

export const FROM_HEADERS = { from: "APPLICATION" };

export const HTTP_STATUS = {
    UNAUTHORIZED: 401,
    AUTHORIZE_DENY: 403,
}

export const HOME_PATH = "/home";
export const COURSE_PATH = "/poll/course/";
export const POLL_CHOOSER_PATH = "/poll/choose/";
export const JOIN_PARTICIPANT_PATH = "/qr";
export const LOGIN_PATH = "/";
export const SESSION_WILL_EXPIRED_DURATION = 30 * 60 * 1000;
export const COOKIE_WILL_EXPIRED_DURATION = 24 * 60 * 60 * 1000;
export const SESSION_CHECK_INTERVAL = 60 * 1000;

export const SURVEY_ALL_PAGE_STATE = {
    QUESTION: 'QUESTION',
    RESULT: 'RESULT',
    RESULT_ALL: 'RESULT_ALL',
    NOT_READY: 'NOT_READY'
};

export const SURVEY_ALL_CURRENT_QUESTION_NO_KEY = 'SURVEY_ALL_CURRENT_QUESTION_NO_KEY';

export const QUESTION_TYPES = {
    MCSS: { name: 'MCSS', description: 'Multiple Choice' },
    SA: { name: 'SA', description: 'Short Answer' },
    TF: { name: 'TF', description: 'True or False' },
    FITB: { name: 'FITB', description: 'Fill in the Blank' },
    CI: { name: 'CI', description: 'Clickable Image' },
    WC: { name: 'WC', description: 'Word Cloud' },
    MH: { name: 'MH', description: 'Matching' }
};

export const imageSuffixArr = ['.png', '.jfif', '.pjpeg', '.jpg', '.jpeg', '.pjp', '.mp4'];
export const videoSuffixArr = ['.mp4'];

export const MCSSoptLower = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
export const MCSSoptUpper = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

// Accessible Colors with White Background:
export const WORD_CLOUD_COLORS = ['#004A7F', '#006B3F', '#7F0000', '#4C005C', '#BF4600', '#333333', '#006E99', '#D63838', '#8A2BE2', '#737373', '#008080', '#CF00CF', '#000080', '#808000', '#B85B39', '#800000', '#4B0082', '#7F6C5B'];

export const PAGE_TYPE = {
    COMPATIBLE: "0",
    VIEWER: "1",
    OTHER: "2"
};

export const NETWORK_ISSUE = "NETWORK IS NOT WORKING";
export const NETWORK_STATUS_TYPE = {
    ONLINE: "Online",
    OFFLINE: "Offline"
};

export const NETWORK_EFFECTIVE_TYPE = {
    LOW: "2g",
    MID: '3g',
    NORMAL: '4g'
};

export const NETWORK_DOWN_TOOLTIP_MSG = "The internet connection has been disconnected. Please check your connection and select the Refresh button.";
export const WEBSOCKET_CONNECTION_DOWN_TOOLTIP_MSG = "A communication error has occurred. Please select the Refresh button to try again.";
export const NETWORK_LOW_TOOLTIP_MSG = "Weak internet connection detected. Please check your connection and select the Refresh button to avoid missing any questions.";

export const POLL_STATE = {
    SHARED: "SHARED",
    SHARED_ALL: "SHARED_ALL",
    STOPPED: "STOPPED",
    STOPPED_ALL: "STOPPED_ALL",
    LOCKED: "LOCKED",
    PAUSED: "PAUSED",
    RESUMED: "RESUMED",
    SCHEDULED: "SCHEDULED",
    CLOSED: "CLOSED"
};

export const QUIZ_STATE = {
    LOADING: "LOADING",
    WAITING: "WAITING",
    PENDING: "PENDING",
    GET_READY: "GET_READY",
    GET_READY_ALL: "GET_READY_ALL",
    POLL_TIME: "POLL_TIME",
    POLL_TIME_ALL: "POLL_TIME_ALL",
    LOCKED: "LOCKED",
    PAUSED: "PAUSED",
    RESUMED: "RESUMED",
    USER_COMPLETE_POLL: "USER_COMPLETE_POLL",
    CLASS_RESULT: "CLASS_RESULT",
    SA_RESULT: "SA_RESULT",
    CLASS_RESULT_ALL: "CLASS_RESULT_ALL",
    CLASS_RESULT_ALL_SURVEY: "CLASS_RESULT_ALL_SURVEY",
    EXPIRED: "EXPIRED",
    CLOSED: "CLOSED",
};

export const POLL_TYPE = {
    GRADED_POLL: "GRADED_POLL",
    SURVEY: "SURVEY",
};

export const SUB_QUESTION_TYPE = {
    PREVIOUS: "PREVIOUS",
    NEXT: "NEXT",
    UNANSWERED: "UNANSWERED"
}

export const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/;
export const INTEGRATION_TYPES = {
    ADFS: "ADFS",
    SHIBBOLETH: "SHIBBOLETH",
    DCAM: "DCAM",
    GOOGLE: "GOOGLE",
    AZURE: "AZURE",
    CANVAS: "CANVAS",
    BLACKBOARD: "BLACKBOARD",
    MOODLE: "MOODLE",
    D2L: "D2L",
    LTI: "LTI1.3",
    LMS_API: "LMS_API"
};
export const IDENTITY_PROVIDER_TYPES = {
    SAML: "SAML",
    LMS: "LMS",
    YUJA: "YuJa"
};

export const LOG_TYPES = {
    INFO: "Info: ",
    WARNING: "Warning: ",
    ERROR: "ERROR: "
};

export const LOG_API_FILES = {
    RESPONSE: "ResponseApiCalls.js",
    PUBLIC: "PublicApiCall.js",
    POLL: "PollApiCalls.js",
    USER: "UserApiCalls.js",
    LTI: "LTIApiCalls.js",
    INST: "InstitutionApiCalls.js",
    COURSE: "CourseApiCalls.js",
    AUTHENTICATION: "AuthenticationApiCalls.js"
};

export const VIEWER_USER_TYPE = {
    MANUAL_GUEST: "MANUAL_GUEST",
    AUTO_GUEST: "AUTO_GUEST",
    LOGGED_IN: "LOGGED_IN",
    LMS_CANVAS: "LMS_CANVAS",
    LMS_BLACKBOARD: "LMS_BLACKBOARD",
    LMS_MOODLE: "LMS_MOODLE",
    LMS_D2L: "LMS_D2L"
};

export const CI_COORDINATE_SIZE = {
    width: 24,
    height: 24
};

export const TOAST_TYPE = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    INFO: "INFO",
    WARNING: "WARNING",
}

export const LMS_GRADEBOOK_SETTINGS_TITLE = "LMS Gradebook Settings";
export const LMS_GRADEBOOK_SETTINGS_SUBTITLE = "Default quiz attempt to send to the LMS Gradebook";
export const NO_SELECTION_TEXT = "Please Select one";

export const PLATFORM_SETTINGS = {
    DISABLE_USER: "DisableUser",
    ANONYMOUS_JOIN: "AnonymousJoin",
    LMS_QUIZ_ATTEMPTS: "LMSQuizAttempts",
    YUJA_LOGIN: "YujaLogin",
    YUJA_NAME: "YujaName",
    SSONAME_ADFS: "SSOName_ADFS",
    SSONAME_SHI: "SSOName_SHI",
    SSONAME_DCAM: "SSOName_DCAM",
    SSONAME_GOOGLE: "SSOName_GOOGLE",
    SSONAME_AZURE: "SSOName_AZURE",
    DEFAULT: "default",
}

export const DEFAULT_LOGIN_METHODS = {
    YUJA_LOGIN: "1",
    SSONAME_ADFS: "2",
    SSONAME_SHI: "3",
    SSONAME_DCAM: "4",
    SSONAME_GOOGLE: "5",
    SSONAME_AZURE: "6",
}

export const LOGIN_METHODS = {
    YUJA: "YuJa Credentials",
    ADFS: "SSO - ADFS",
    SHIBBOLETH: "SSO - SHIBBOLETH",
    DCAM: "SSO - DCAM",
    GOOGLE: "SSO- GOOGLE",
    AZURE: "SSO - AZURE",
}

export const USER_SETTINGS = {
    TOAST_MESSAGE_DURATION: "ToastMessageDuration",
}

export const CONFIGURE_GRADEBOOK_OPTIONS_TEXT = "Configure Gradebook Options";
export const RECORD_ATTEMPT_TITLE = "Recording Quiz Attempts";
export const LMS_ATTEMPT_TITLE = "LMS Quiz Attempts";
export const MAX_ATTEMPTS_TITLE = "Maximum Attempts";
export const UNLIMITED_MAX_ATTEMPTS_TEXT = "Allow Unlimited Attempts";
export const CUSTOM_MAX_ATTEMPTS_TEXT = "Maximum Attempts Allowed (if limited)";
export const MODAL_CANCEL_TEXT = "Cancel";
export const MODAL_CLOSE_TEXT = "Close";
export const MODAL_EDIT_TEXT = "Edit";
export const MODAL_GO_BACK_TEXT = "Go Back";
export const MODAL_SAVE_TEXT = "Save";
export const MODAL_SEND_TEXT = "Send";
export const MODAL_DELETE_TEXT = "Delete";
export const MODAL_CONFIRM_TEXT = "Confirm"; 
export const REATTEMPT_MODAL_TITLE = "Reattempt Poll";
export const REATTEMPT_MODAL_BODY = "Are you sure you want to reattempt the poll?";
export const YES_TEXT = "Yes";
export const NO_TEXT = "No";
export const USERNAME = "Username";
export const USERNAME_SUBTITLE = "Please fill username.";
export const PASSWORD = "Password";
export const PASSWORD_SUBTITLE = "Please fill password.";
export const CONFIRM_PASSWORD = "Confirm Password";
export const CONFIRM_PASSWORD_SUBTITLE = "Please comfirm password.";
export const ROLE = "Role";
export const ROLE_SUBTITLE = "Please select one option.";
export const FIRST_NAME = "First Name";
export const FIRST_NAME_SUBTITLE = "Please fill first name.";
export const MIDDLE_NAME = "Middle Name";
export const MIDDLE_NAME_SUBTITLE = "Please fill middle name.";
export const LAST_NAME = "Last Name";
export const LAST_NAME_SUBTITLE = "Please fill last name.";
export const EMAIL = "Email";
export const EMAIL_SUBTITLE = "Please fill email.";
export const PHONE = "Phone";
export const PHONE_SUBTITLE = "Please fill phone.";
export const JOIN_POLL = "Join Poll";
export const SCAN_QR = "Scan QR Code";

export const USER_COMPLETE_POLL_TITLE = "You're all Set!";
export const USER_COMPLETE_POLL_SUBTITLE = "Results Will Be Available Once the Poll is Closed.";
export const UNLIMITED_ATTEMPTS_TEXT = "Click Retry to re-attempt the poll as many times as you'd like.";
export const MAX_ATTEMPTS_REACHED = "You have reached the maximum number of attempts for this poll.";
export const REATTEMPT_OUT_OF = "out of";
export const REATTEMPT_REMAINING_TEXT = "attempts have been used. You can re-attempt the poll by clicking Retry.";
export const RETRY_TEXT = "Retry";

export const SUBMIT_TEXT = "Submit";
export const POLL_TEXT = "Poll";
export const SURVEY_TEXT = "Survey";

export const RECORD_ATTEMPT = {
    ALL: {value: 'all', label: 'Record All Attempts'},
    RECENT: {value: 'recent', label: 'Record Only Most Recent Attempt'},
}

export const LMS_GRADEBOOK_SYNC = { // for platform setting
    FIRST: {value: 'first', label: 'First Attempt'},
    RECENT: {value: 'recent', label: 'Most Recent Attempt'},
    HIGHEST: {value: 'highest', label: 'Attempt with Highest Score'}
}

export const SYNC_ATTEMPT = { // other cases
    FIRST: {value: 'first', label: 'Send First Attempt to LMS'},
    RECENT: {value: 'recent', label: 'Send Most Recent Attempt to LMS'},
    HIGHEST: {value: 'highest', label: 'Send Highest Score Attempt to LMS'}
}

// Support Center:
export const YUJA_HELP = "YuJa Help";
export const YUJA_HELP_TEXT = "We're here to help! For product questions and assistance, please select your preferred support option.";
export const TOLL_FREE_PHONE = "Toll-Free Phone";
export const SEND_EMAIL = "Send Email";
export const SEND_MESSAGE = "Send Message";
export const PRODUCT_DOCS = "Product Docs";
export const YUJA_SUPPORT = "YuJa Support";
export const YUJA_SUPPORT_TEXT = "YuJa offers live product support to all users. Please select the phone number closest to you.";
export const US_CANADA_TEXT = "US & Canada";
export const US_CANADA_PHONE = "+1 (888) 257 2278";
export const UK_TEXT = "United Kingdom";
export const UK_PHONE = "+44 (800) 368 8712";
export const NED_TEXT = "Netherlands";
export const NED_PHONE = "+31 (800) 280 0355";
export const INT_TEXT = "International";
export const INT_PHONE = "+1 (408) 457 1036";
export const EMAIL_SUPPORT_BODY = "Our Support Team aims to provide you with a response within 24 hours.";
export const EMAIL_SUPPORT_PREFIX = "To reach YuJa Support via email, please contact";
export const SUPPORT_EMAIL_ID = "support@yuja.com";
export const MESSAGE_SUPPORT_BODY = "Our Support Team aims to provide you with a response within 24 hours.";
export const NAME_TEXT = "Name";
export const EMAIL_TEXT = "Email";
export const SUBJECT_TEXT = "Subject";
export const MESSAGE_TEXT = "Message";

// Gradebook:
export const HEADER = 'Header'; // for react-table v7
// export const HEADER_NEW = 'header'; // for react-table v8
export const CELL = 'Cell'; // for react-table v7
// export const CELL_NEW = 'cell'; // for react-table v8
export const IP_ADDRESS_COLUMN_NAME = 'Ip Address';
export const CHECKBOX_COLUMN_ID = 'selection';
export const STUDENT_COLUMN_NAME = 'Student';
export const STUDENT_COLUMN_ID = 'student';
export const STUDENT_COLUMN_INDEX = 1;
export const SCORE_COLUMN_NAME = 'Score';
export const SCORE_COLUMN_ID = 'score';
export const USER_ID_COLUMN_NAME = 'User ID';
export const USER_ID_COLUMN_ID = 'userId';
export const USER_ID_COLUMN_INDEX = 3;
export const CORRECT = 'correct';
export const INCORRECT = 'incorrect';
export const NOT_GRADED = 'Not Graded'
export const NO_RESPONSE = 'No Response';
export const ATTEMPT = 'Attempt';
export const ATTEMPT_NO_PREFIX = ATTEMPT + ' #';
export const IS_CORRECT_PREFIX = 'isCorrect';
export const IS_GRADED_PREFIX = 'isGraded';
export const ATTEMPTS = 'attempts';
export const RESPONSES = 'responses';
export const DATE_HEADER = "Date";
export const UPDATED_DATE_PK = "updatedDatePK";
export const POLL_TITLE_HEADER = "Title";
export const POLL_TITLE = "pollTitle";
export const POLL_TYPE_HEADER = "Mode";
export const POLL_TYPE_ACCESSOR = "pollType";
export const QUESTION_HEADER = "Questions";
export const TOTAL_QUESTIONS = "totalQuestions";
export const ACTIVATION_STATUS_HEADER = "Activation Status";
export const POLL_SORT_KEY = "pollSortKey";
export const ACTION_HEADER = "Action";
export const POLL_KEY = "pollKey";

export const VIEW_TAKEN_POLLS_TITLE = "Polls";
export const YOUR_ANSWER_TITLE = "Your Answer";
export const PLEASE_SELECT_POLL = "Please select a poll";

export const RESULT_CONTAINER_KEY_PREFIX = "resultContainer";
export const WORD_CLOUD_ID = "wordCloud";
export const WORD_CLOUD_DATA = "wordCloudData";
export const EMPTY_WORD_CLOUD_TEXT = "No Responses Yet";
export const QUESTION_IMAGE_ID = "questionImage";

export const UNLIMITED_ATTEMPTS = -1;
export const DEFAULT_TOAST_MESSAGE_DURATION = 5;
export const DEFAULT_PAGINATION_SIZE = 5; // Page Numbers shown in the bottom of the table
export const DEFAULT_ROWS_PER_PAGE = 10; // Rows per page
export const POLL_TITLE_CHARACTER_LIMIT = 30;
export const COURSE_CHARACTER_LIMIT = 30;
export const GRADEBOOK_DEFAULT_MESSAGE = "Choose a poll that has been published from the drop-down menu above and review the scores of each individual participant.";

export const FIXED_ANSWER = {
    UNANSWERED: "unanswered",
    OTHER: "other"
}
export const UNANSWERED = "Unanswered";
export const UNGRADED = "ungraded"; 

export const POLL_SHARE_MODE = {
    SHARE_ALL: "shareAll",
    SHARE_EACH: "shareEach",
    SCHEDULE: "schedule",
}

export const USER_CREATION_FIELDS = {
    USERNAME: "userName", 
    FIRST_NAME: "firstName", 
    MIDDLE_NAME: "middleName", 
    LAST_NAME: "lastName",
}

export const ASCII_CODE_A = 65;

export const CREATOR_MENU_LIST = [
    {
        name: "Home",
        path: "/home",
        icon: Home,
        activeIcon: ActiveHome,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    {
        name: "Create",
        path: "/create",
        icon: CreatePoll,
        activeIcon: ActiveCreatePoll,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    {
        name: "Polls",
        path: "/poll/user",
        parentPage: "/poll",
        icon: ManagePolls,
        activeIcon: ActiveManagePolls,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    {
        name: "Gradebook",
        path: "/gradebook",
        icon: GradeBook,
        activeIcon: ActiveGradeBook,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    {
        name: "Users",
        path: "/users",
        icon: ManageUsers,
        activeIcon: ActiveManageUsers,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value]
    },
    {
        name: "Courses",
        path: "/course/user",
        icon: ManageCourses,
        activeIcon: ActiveManageCourses,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },
    {
        name: "Integrations",
        path: "/admin/integration",
        icon: Integration,
        activeIcon: ActiveIntegration,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value]
    },
    {
        name: "Settings",
        path: "/admin/platformsetting",
        icon: Settings,
        activeIcon: ActiveSettings,
        roles: [ROLES.ADMINISTRATOR.value, ROLES.IT_MANAGER.value, ROLES.CREATOR.value]
    },

]

export const VIEWER_MENU_LIST = [
    {
        name: "Login",
        path: "/",
        icon: Login,
        activeIcon: Login,
        gradientIcon: Login,
        roles: [ROLES.ANONYMOUS.value,]
    },
    {
        name: "Home",
        path: "/home",
        icon: Home,
        activeIcon: ActiveHome,
        gradientIcon: GradientHome,
        roles: [ROLES.VIEWER.value]
    },
    // {
    //     name: "Join Poll",
    //     path: "",
    //     icon: JoinPoll,
    //     activeIcon: ActiveJoinPoll,
    //     gradientIcon: GradientJoinPoll,
    //     roles: [ROLES.ANONYMOUS.value, ROLES.VIEWER.value]
    // },
    {
        name: "Polls",
        path: "/student/polls",
        icon: ManagePolls,
        activeIcon: ActiveManagePolls,
        gradientIcon: GradientManagePolls,
        roles: [ROLES.VIEWER.value]
    },
    {
        name: "Courses",
        path: "/student/courses",
        icon: ManageCourses,
        activeIcon: ActiveManageCourses,
        gradientIcon: GradientManageCourses,
        roles: [ROLES.VIEWER.value]
    },
    {
        name: "Settings",
        path: "/student/settings",
        icon: Settings,
        activeIcon: ActiveSettings,
        gradientIcon: GradientSettings,
        roles: [ROLES.VIEWER.value]
    },
]

// CreateQuizSidebar:
export const SWITCH_POLL_MODAL_TITLE = "Your changes won't be saved";
export const LOSE_CHANGE_MESSAGE = "The existing changes will not be kept, do you wish to proceed?";
export const SWITCH_POLL_MESSAGE = "The existing questions will not be kept, do you wish to proceed?";
export const SWITCH_POLL_MODAL_TEXT = "I wish to proceed";

export const QUESTION_PROFILE_MESSAGE = "Are you sure your want to delete this question bank?";

export const QUESTION_PROFILE_TITLE = "Deleting a question bank";

export const GRADED_POLL = "Graded Poll";
export const SURVEY = "Survey";

export const CREATE_POLL_PATH = "/create";
export const EDIT_POLL_PATH = "/edit";
export const EDIT_QUESTION_BANK_PATH = "/questions/edit";
export const JOIN_POLL_PATH = "/join";

export const YUJA_TOKEN_EXPIRED_ALERT_TITLE = "Session Expired";
export const YUJA_TOKEN_EXPIRED_ALERT_CONTENT = "Your session has expired. Please click OK to log in or refresh the page if you're within a LMS.";
export const YUJA_TOKEN_EXPIRED_ALERT_OK_BUTTON = "OK";

export const CONFIRM_UNANSWERED_SUBMIT_TITLE = "Submit Unanswered Question";
export const CONFIRM_UNANSWERED_SUBMIT_CONTENT = "You have not answered this question. Are you sure you want to submit?";


// Question Banks
export const CREATE_QUESTION_PROFILE_MODAL_TITLE = "Save as question bank";
export const CREATE_QUESTION_PROFILE_MODAL_TEXT = "Enter a name to save existing questions as a new question bank";
export const CREATE_QUESTION_PROFILE_MODAL_INPUT = "Question Bank Name";
export const SELECT_ALL_TEXT = "Select All";
export const CLEAR_ALL_TEXT = "Clear All";
export const QUESTION_PROFILE_MODAL_TEXT = "Import";
export const QUESTION_PROFILE_MODAL_TITLE = "Question Bank";
export const QUESTION_PROFILE_MODAL_MESSAGE = "Select a question bank from the list below and select questions."
export const QUESTION_PROFILE_MODAL_SUB_TITLE = "View Question Banks";
export const GO_BACK_TEXT = "Go Back";
export const IMPORT_BANK_TEXT = "Import from Bank";
export const QUESTION_PROFILE_ACTION_ONE = "Edit";
export const QUESTION_PROFILE_ACTION_TWO = "Delete";
export const QUESTION_PROFILE_IMPORT_MESSAGE = "Select questions from the list below and Import them.";
export const QUESTION_PROFILE_IMPORT_EDIT = "Edit Bank";
export const QUESTION_BANK_TITLE_PLACEHOLDER = "Question Bank Title";
export const EDIT_QUESTION_BANK = "Edit Question Bank";


export const ADD_QUESTION_TEXT = "Add Question";

export const CREATE_QUESTION_DETAIL_SIDEBAR_TITLE = "Question Details";

export const POLL_QUESTION_TITLE = "Poll Questions";
export const NO_QUESTION_ADDED = "No Questions added yet"


// CreateQuizDetailSidebar:
export const ENTRIES_TITLE = "Entries per Participant";
export const ENTRIES_SUBTITLE = "Enter maximum entries per participant.";
export const CUSTOM_TEXT = "Custom";
export const ENTRY_TEXT = "Entry";
export const ENTRIES_TEXT = "Entries";
export const CUSTOM_ENTRIES_FOOTER = "Max 10";

// Question options:
export const QUESTION_DEFAULT_OPTIONS = {
    MCSS: '{"a":"","b":"","c":"","d":""}',
};

// Matching
export const PREMISE = "Premise";
export const RESPONSE = "Response";

//Tab Participant options
export const TAB_PARTICIPANTS_CORRECT = "Correct";
export const TAB_PARTICIPANTS_INCORRECT = "Incorrect";
export const TAB_PARTICIPANTS_NO_RESPONSE = "No Response";
export const TAB_PARTICIPANTS_UNANSWERED = "Unanswered";

// Student Gradebook
export const VIEW_TEXT = "View";
export const VIEW_ANSWER = "View Answer";

// Courses:
export const COURSES = "Courses";
export const CREATE_COURSE = "Create Course";
export const COURSE_SEARCH_PLACEHOLDER = "Find by Course Name or Course Code";
export const NO_RESULTS_FOUND = "No Results Found";
export const COURSE_NAME = "Course Name";
export const COURSE_CODE = "Course Code";
export const VIEW_COURSE = "View Course";

// Polls:
export const POLLS = "Polls";
export const CREATE_POLL = "Create Poll";
export const NO_POLLS_TEXT = "There are no polls to display.";
export const CREATE_POLL_TEXT = "Press the button below to create a poll.";
export const OVERRIDE_IMAGE_TEXT = "The poll only allows one image or video per question. Do you wish to replace the image or video currently associated with this question?";
export const OVERRIDE_IMAGE_TITLE = "Replace Image or Video"; 
export const OVERRIDE_IMAGE_BUTTON = "Continue";

// Poll Search Form:
export const START_DATE = "Start Date";
export const END_DATE = "End Date";

// Users:
export const USERS = "Users";
export const CREATE_USER = "Create User";
export const DATE_CREATED = "Date Created";
export const LAST_ACCESS_TIME = "Last Access Time";
export const OPERATION = "Operation";
export const UPDATE_USER = "Update User";
export const SEARCH_USER = "Find by Username, First Name, Last Name or Email"; 
//platform setting
export const PLATFORM_SETTING_SAVE_ERROR_MSG = "Can not set YuJa Login as default credentials when YuJa Login method is disabled!";
export const LOGIN_SETTINGS_ERROR_MSG = "In order to disable the YuJa Engage credential as a stand-alone option, you need to first set up an authentication option with your campus Single Sign On. For more information on how to configure an SSO authentication with Engage, please go to support.yuja.com.";
export const LMS_AUTHENTICATION_LABEL = "Require users to authenticate through LMS before accessing poll.";
export const YUJA_DISABLE_LABEL = "Disable YuJa Login Credentials";
export const USER_TOAST_MESSAGE_TOOLTIP = "How long the toast message should display until it disappears.\n" +
"If this option is set to manual dismissed, the toast will not disappear until the user closes it manually.";
export const USER_TOAST_MESSAGE_LABEL = "Customize your time preference to suit your needs for viewing toast notifications";
export const UPLOAD_IMAGE_ERROR = "Please upload an image with a valid file format.";
export const VIEWER_SETTINGS_SAVE_TEXT = "Save Changes";

//Keynote presentation question page
export const PRESENTATION_QUESTION_PAGE_TITLE = "YuJa Engage";
export const PRESENTATION_QUESTION_POLL_UNAVAILABLE_TITLE = "POLL UNAVAILABLE";
export const PRESENTATION_QUESTION_POLL_UNAVAILABLE_TEXT = "Please try logging in as the poll owner.";
export const PRESENTATION_QUESTION_INTERNAL_ERROR_TITLE = "INTERNAL ERROR";
export const PRESENTATION_QUESTION_INTERNAL_ERROR_TEXT = "Please contact YuJa Help Center.";


//course API 

export const INVALID_COURSE_CODE = "Invalid courseCode";
export const INVALID_COURSE_NAME = "Invalid courseName";

export const serialNo_TEXT = 'serialNo'; 
export const POLL_TITLE_TEXT = 'pollTitle';
export const BANK_TITLE_TEXT = "ProfileTitle";
export const UNIQUECODE_TEXT = "uniqueCode";
export const SUBMITED_QUESTION_NO_TEXT = "submittedQueNo"; 

export const DELETE_CONFIRMATION = "Are you sure you want to delete it?";
export const DELETE_COURSE_CONFIRMATION = "Are you sure you want to delete this course?";
export const DELETE_USER_CONFIRMATION = "Are you sure you want to delete the user and everything associated with this user?";
export const DELETE_MULTIPLE_USER_CONFIRMATION = "Are you sure you want to delete them?";
// schedule poll 

export const SCHEDULE_POLL_TIME_MESSAGE = "Set the length of time for the Poll (HH:MM:SS) :";
export const SCHEDULE_POLL_EDIT_MODAL_TITLE = "Update Release Schedule"; 
export const SCHEDULE_POLL_EDIT_MODAL_MESSAGE = "Update the scheduled Poll's Start Date, End Date, and the length of time the Poll is available.";
export const SCHEDULE_POLL_OPTION = "Schedule Poll"; 
export const SCHEDULE_POLL_SHARE_MODE = "schedule"; 
export const SCHEDULE_POLL_OPEN_MESSAGE = "The Poll Will Open on ";
export const SCHEDULE_START_DATE_PLACEHOLDER = "Start Date"; 
export const SCHEDULE_END_DATE_PLACEHOLDER = "End Date"; 
export const SCHEDULE_ADD_START_DATE = "Add Start Time"; 
export const SCHEDULE_ADD_END_DATE = "Add End Time"; 

export const SCHEDULE_FROM_TEXT = "From"; 
export const SCHEDULE_TO_TEXT = "To"; 

export const SCHEDULE_TIME_AM = "AM"; 
export const SCHEDULE_TIME_PM = "PM"; 
// Ended Poll:
export const REATTEMPT_CLOSED_POLL = "If you would like to re-attempt the poll, please reach out to your instructor directly.";
export const REATTEMPT_CLOSED_SURVEY = "If you would like to re-attempt the survey, please reach out to your instructor directly.";
export const CLOSED_GRADED_POLL = "The poll is now closed.";
export const CLOSED_SURVEY = "The survey is now closed.";
export const SUPPORT_PREFIX = "For technical questions, please reach out to";
export const YUJA_ENGAGE_SUPPORT = "YuJa Engage Support";

// SharePollPage
export const VIEW_POLL_CODE_TEXT = "To join directly, either scan the QR code or copy the link and paste it into your browser.";
export const VIEW_POLL_CODE_MINIMIZED_TEXT = "To join directly, either scan the QR code or copy the link and paste it into your browser.";

//release poll page
export const SHOW_OR_HIDE_CORRECT_ANSWER_TOGGLE_LABEL = "Show Correct Answer";

//create poll page
export const SHOW_CORRECT_ANSWER_CHECKBOX_LABEL = "Show Correct Answer to Viewers";
export const FILE_SIZE_TOO_LARGE = "Uploaded file is too large, maximum file size is 50 MB";

//Poll Filtering 

export const ACTIVATED_STATUS = "Activated";
export const ALL_MODE = "All Mode";
export const GRADED_MODE = "Graded Poll";
export const GRADED_SHORT = "Graded";
export const COURSE_STATUS = "Course"; 
export const DRAFT_STATUS = "Draft"; 
export const ALL_STATUS = "All Status"; 

export const SEARCH_TEXT = "Search";

export const WAITING_ROOM_POLL_TEXT = " Waiting for the creator to start the poll.";
export const WAITING_ROOM_SURVEY_TEXT = " Waiting for the creator to start the survey.";

// Disable anonymous join 
export const DISABLE_ANONYMOUS_SIDE_BAR = "Require participants to enter their names when joining"; 
export const DISABLE_ANONYMOUS_PLATFORM = "Require participants to enter their names when joining"; 
export const INSTITUTION_DISABLE_ANONYMOUS_WARNING = "Please enter your name to join"; 
export const POLL_DISABLE_ANONYMOUS_WARNING = "Please enter your name to join"; 
export const ANONYMOUS_JOIN_SETTING_TEXT = "anonymousJoin"; 
export const ANONYMOUS_JOIN_MODAL_TITLE = "Join Poll";
export const ANONYMOUS_JOIN_MODAL_SUBTITLE = "To join the Poll please enter a name."; 
export const ANONYMOUS_JOIN_MODAL_BUTTON = "Enter Poll";

// LMS authenticate 

export const AUTHENTICATE_LMS_SETTING = "Require users to authenticate through the LMS before accessing Polls";
export const POLL_CODE_PREFIX = "PUB#UC#"


export const EDIT_DATE_ACTION_TEXT = "Edit Open Dates";

// Integration Page
export const TYPE_PLACEHOLDER = "Select Integration Type";
export const LMS_PLACEHOLDER = "Select LMS";
export const ADFS_SSO_URL = "ADFS SSO URL";
export const NAME_FORMAT = "Name ID Format";
export const REMOTE_LOGOUT = "Remote Logout URL";
export const ADFS_CERTIFICATE = "ADFS Signing Certificate Fingerprint";
export const LOGOUT_URL = "Logout URL";
export const GIVEN_NAME = "Given Name Attribute";
export const FAMILY_NAME = "Family Name Attribute";
export const EMAIL_ATTR = "Email Attribute";
export const ROLE_ATTR = "Role Attribute";
export const ROLE_MAPPING = "Role Mapping";
export const IT_MANAGER_MAPPING = "IT Manager Mapping";
export const INSTR_MAPPING = "Instructor Mapping";
export const LINKAGE_ATTR = "Linkage Attribute";
export const LINKAGE_TYPE = "Linkage Type";
export const CROSS_INTEGRATION = "Cross Integration with LTI";
export const AZURE_SSO_URL = "Azure SSO URL";
export const AZURE_CERTIFICATE = "Azure Signing Certificate Fingerprint";
export const DCAM_CERTIFICATE = "DCAM Signing Certificate Fingerprint";
export const DCAM_SSO_URL = "DCAM SSO URL";
export const USER_PROVISION = "User Provisioning";
export const INSTRUCTOR = "Instructor";
export const GENERAL_SSO = "General SSO Details";
export const GOOGLE_SSO_URL = "Google SSO URL";
export const GOOGLE_CERTIFICATE = "Google Signing Certificate Fingerprint";
export const SHIBBOLETH_SSO_URL = "Shibboleth SSO URL";
export const SHIBBOLETH_CERTIFICATE = "Shibboleth Signing Certificate Fingerprint";
export const API_CONFIG = "API Configuration";
export const API_ACCESS = "API Access";
export const ALLOW_API_ACCESS = "Allow API Access";
export const SYNC_SETTINGS = "Synchronization Settings";
export const LINKAGE_SETTINGS = "Linkage Settings";
export const ISS = "Enter ISS";
export const CLIENT_ID = "Enter Client Id";
export const OIDC = "Enter OIDC Endpoint";
export const JWKS = "Enter JWKS Endpoint";
export const OAUTH = "Enter OAuth2 Token Endpoint";
export const DEPLOYMENT = "Enter Deployment Id";
export const USER_CONFIG = "User Configuration";
export const AUTO_PROVISION_USER = "Automatically provision user accounts";
export const AUTO_SYNC_USER = "Automatically sync data on user login";
export const AUTO_PROVISION_COURSE = "Automatically provision courses";
export const AUTO_SYNC_COURSE = "Automatically sync course data when entering the YuJa LTI Tool";
export const COURSE_CONFIG = "Course Configuration";
export const FALL_BACK_TO_EMAIL = "Fallback to email address to cross-match users when no Linkage Attribute matches";

export const INTEGRATION_TYPES_LOWERCASE = {
    ADFS: "Adfs",
    SHIBBOLETH: "Shibboleth",
    DCAM: "Dcam",
    GOOGLE: "Google",
    AZURE: "Azure",
    CANVAS: "Canvas",
    BLACKBOARD: "Blackboard",
    MOODLE: "Moodle",
    D2L: "D2L",
};

export const INTEGRATION_TYPES_DETAILS = {
    ADFS: "SSO - ADFS [SAML]",
    SHIBBOLETH: "SSO - Shibboleth [SAML]",
    DCAM: "SSO - DCAM [SAML]",
    GOOGLE: "SSO - Google Apps",
    AZURE: "SSO - Azure",
    LTI: "LMS - LTI 1.3",
    LMS_API: "LMS - API"
};

export const blackboardRoles = [{display: "Student", value: "studentRole"}, {display: "Instructor", value: "instructorRole"}, 
    {display: "Grader", value: "graderRole"}, {display: "Course Builder", value: "courseBuilderRole"}, 
    {display: "Teaching Assistant", value: "teachingAssistantRole"}, {display: "Guest", value: "guestRole"}, 
    {display: "Administrator", value: "adminRole"}, {display: "Other", value: "otherRole"}  
];

export const canvasRoles = [{display: "Administrator", value: "adminRole"}, {display: "Teacher", value: "teacherRole"}, 
    {display: "Teaching Assistant", value: "teachingAssistantRole"}, {display: "Designer", value: "designerRole"}, 
    {display: "Observer", value: "observerRole"}, {display: "Student", value: "studentRole"}
];

export const moodleRoles = [{display: "Student/Course Creator", value: "studentRole"}, {display: "Teacher/Non-editing Teacher/Manager", value: "instructorRole"},  
    {display: "Administrator", value: "adminRole"}
];

export const d2lRoles = [{display: "Student/Course Creator", value: "studentRole"}, {display: "Instructor", value: "instructorRole"},  
    {display: "Administrator", value: "adminRole"}
];

export const LTI_USER_MAPPING = {
    STUDENT: "Student",
    INSTRUCTOR: "Instructor",
    GRADER: "Grader",
    COURSE_BUILDER: "Course Builder",
    TEACHER: "Teacher",
    TEACHING_ASSISTANT: "Teaching Assistant",
    GUEST: "Guest",
    OTHER: "Other",
    ADMIN: "Administrator",
    DESIGNER: "Designer",
    OBSERVER: "Observer",
    STUDENT_COURSE: "Student/Course Creator",
    TEACHER_MANAGER: "Teacher/Non-editing Teacher/Manager"
};

// Share Poll Modal:
export const SHARE_POLL_BODY = "Please choose the members you want to share this poll with.";
export const SHARE_SURVEY_BODY = "Please choose the members you want to share this survey with.";
export const SHARE_TEXT = "Share";

// Delete Modal:
export const DELETE_POLL = "Delete Poll";
export const DELETE_SURVEY = "Delete Survey";
export const DELETE_POLL_MODAL_BODY = "Are you sure you want to delete it?";
export const DELETE_USER = "Delete User";
export const DELETE_USER_MODAL_BODY = "Are you sure you want to delete it?";
export const DELETE_USER_DATA_MODAL_BODY = "Are you sure you want to delete the user and everything associated with this user?";
export const DELETE_COURSE = "Delete Course";
export const DELETE_COURSE_MODAL_BODY = "Are you sure you want to delete this course?";

// QR Landing Page
export const WELCOME_YUJA_ENGAGE = "Welcome to YuJa Engage";
export const PLEASE_CHOOSE_OPTIONS = "Please choose one of the following options.";
export const POLL_IN_COURSE_LOGIN_AND_JOIN = "The Poll requires you to join using your credentials.";

// Share Poll Page
export const BROWSER_TYPES = {
    Chrome: "Chrome",
    Firefox: "Firefox",
    Edge: "Edge",
    Safari: "Safari",
    MobileApp: "Mobile App",
    Other: "Other"
};
export const PLATFORM_TYPES = {
    Apple: "Apple",
    iPhone: "iOS",
    Mac: "Mac OS",
    iPad: "iPad OS",
    Windows: "Windows",
    Android: "Android"
};
export const AVG_ENGAGEMENT_TITLE = "Average Engagement";
export const ENGAGEMENT_TITLE = "Engagement";

//Join poll modal
export const JOIN_POLL_MODAL_TITLE = "Join Poll";
export const JOIN_POLL_MODAL_POLL_CODE_LABEL = "Poll Code";
export const JOIN_POLL_MODAL_NAME_REQUIRED_LABEL = "Name";
export const JOIN_POLL_MODAL_NAME_OPTIONAL_LABEL = "Name [optional]";
export const JOIN_POLL_MODAL_ENTER_POLL = "Enter Poll";
export const JOIN_POLL_MODAL_SCAN_QR = "Scan QR";
export const JOIN_POLL_MODAL_CANCEL = "Cancel";


export const SEND_REQUEST_TITLE = "Sending Request";

// Navbar
export const NAVBAR_ENGAGE = "Engage";
export const SURVEY_NAVBAR = " Survey";
export const POLL_NAVBAR = " Poll";
export const RELEASE = "Release ";
export const RESULTS = "Results";
export const JOIN_SURVEY = "Join Survey";

export const OPEN_IN_NEW_TAB = "Open in New Tab";

export const POLL_TITLE_PLACEHOLDER = "Enter Poll Title Here"; 
export const SURVEY_TITLE_PLACEHOLDER = "Enter Survey Title Here"; 
export const UPDATE_CLOSE_BUTTON_TEXT = "Update & Close";
export const SAVE_CLOSE_BUTTON_TEXT = "Save & Close"; 

export const FITB_QUESTION_TITLE = "Type question and for the word you wish to blank, click the Blank button. Below you can add the answer to each blank."; 
export const CI_QUESTION_TITLE = "Click anywhere on the image to mark that region as an option.";
export const PREVIEW_TEXT = "Preview";
export const ADD_BLANK_TEXT = "Add Blank";
export const ADD_MATCHING_PAIR_TEXT = "Add Matching Pair"; 
export const ADD_DISTRACTORS_TEXT = "Add Distractors"; 

//get ready page
export const GET_READY_PAGE_TITLE = "Get Ready!";
export const GET_READY_PAGE_TIME_LIMIT_1ST_PART = "You will have";
export const GET_READY_PAGE_TIME_LIMIT_2ND_PART = "to answer all questions!";
export const GET_READY_PAGE_TIMER_COUNTER_DESCRIPTION = "Get Ready! The question will appear in";

// local storage
export const SESSION = "session";
export const MISSED_RESPONSE_MAP = "missedResponseMap";
export const GUEST_SESSION = "guestSession";
export const TIME_OFFSET = "timeOffset";
export const COURSE_CONTENT = "courseContent";
export const QUESTIONS = "questions";
export const CREATE_TIME = "createTime";
export const SAVE_EXIT = "saveExit";
export const RECENT_QUESTIONS = "recentQuestions";
export const SHOW_ANSWER = "showAnswer";
export const POLL_KEY_TEXT = "PollKey";
export const RESP_DATA = "respData";
export const SA_RESULT = "SAResult";
export const FITB_RESULT = "FITBResult";
export const CURR_INDEX = "Curr_index";
export const IS_SAVING = "isSaving";
export const RESPONSE_CORRECT = "responseCorrect";

// external integration
export const TOPHAT = "Top Hat";
export const MENTIMETER = "Mentimeter";
export const POLL_EVERYWHERE = "Poll Everywhere";
export const INTEGRATED_PRODUCTS = [
    {label: MENTIMETER, value: MENTIMETER}, 
    {label: TOPHAT, value: TOPHAT}, 
    {label: POLL_EVERYWHERE, value: POLL_EVERYWHERE}
];
export const INTEG_SUBTITLE = "You can migrate existing data from third-party products to use within your Polls and Surveys. To get started, simply select a product from the drop-down menu below and follow the steps.";
export const MENTIMETER_EXTENSION_ID = "jbjniknpaofjfnghbocbhcppplchkkhp";
export const TOPHAT_EXTENSION_ID = "bgdaaeabdabncnacphdgiafkhmjfodio";
export const POLL_EVERYWHERE_EXTENSION_ID = "hddappnkhbmbfnpekaejnneejhohdokh";
export const LOGOUT_CHROME_EXT_MESSAGE = "removeChromeExtSessionInfo";
export const GET_CHROME_EXT_MESSAGE = "getChromeSessionInfo";
export const EXT_CONNECTION_ERROR = "An error occurred while connecting to the extension, please make sure the extension is installed!";
export const INSTALL_STEP = "Install the Engage Browser Extension";
export const INSTALL_SUBTITLE = "Please open Chrome and download the Engage Browser Extension.";
export const INSTALL_BTN = "Install Extension";
export const COMPLETED_STEP = "Step 1: Installed extension successfully.";
export const CONNECT_STEP = "Connect to the Engage Browser Extension";
export const CONNECT_SUBTITLE = "Once you've installed the extension, please click the “Connect” button.";
export const CONNECT_BTN = "Connect";
export const COMPLETED_CONNECT = "Step 2: Connected to the Engage browser extension successfully.";
export const ACCESS = "Access ";
export const MIGRATE_QUES = " to Migrate Questions";
export const TOPHAT_STEPS = ["Step 1: Select My Courses from the Main Menu.", "Step 2: Select the Engage Browser Extension.", "Step 3: Click the Migrate button to begin migrating questions.", "Step 4: Log in to Engage and access the Poll tab to review your migrated Polls."];
export const MENTIMETER_STEPS = ["Step 1: Select My Presentations from the left-side menu.", "Step 2: Select the Engage Browser Extension.", "Step 3: Click the Migrate button to begin migrating questions.", "Step 4: Log in to Engage and access the Poll tab to review your migrated Polls."];
export const PE_STEPS = ["Step 1: Select My Activities from the Activities menu.", "Step 2: Select the Engage Browser Extension.", "Step 3: Click the Migrate button to begin migrating questions.", "Step 4: Log in to Engage and access the Poll tab to review your migrated Polls."];
export const TOPHAT_URL = "https://chrome.google.com/webstore/detail/yuja-engage-for-poll-migr/bgdaaeabdabncnacphdgiafkhmjfodio";
export const PE_URL = "https://chrome.google.com/webstore/detail/yuja-engage-for-activity/hddappnkhbmbfnpekaejnneejhohdokh";
export const MENTIMETER_URL = "https://chrome.google.com/webstore/detail/yuja-engage-for-question/jbjniknpaofjfnghbocbhcppplchkkhp";
export const TOPHAT_SUPPORT_URL = "https://support.yuja.com/hc/en-us/articles/18985103413015";
export const MENTIMETER_SUPPORT_URL = "https://support.yuja.com/hc/en-us/articles/18982550088215";
export const PE_SUPPORT_URL = "https://support.yuja.com/hc/en-us/articles/18984955562775";

// Poll chooser confirm
export const POLL_CHOOSER_CONFIRM_TITLE = "Insert Poll";
export const POLL_CHOOSER_CONFIRM_CANCEL = "Cancel";
export const POLL_CHOOSER_CONFIRM_CONTENT = " Once the Poll is inserted through the Poll Chooser, it can only be accessed through the LMS.";
export const POLL_CHOOSER_CONFIRM_DONT_SHOW_AGAIN = "Don’t show this again";
export const POLL_CHOOSER_CONFIRM_OK = "I Understand";
// LMS API Integration
export const START_SYNC = "Start Synchronization";
export const COURSE_ID = "Course ID";
export const ENTER_COURSE_ID = "Enter Course ID";
export const DOMAIN = "Domain";
export const INST_BASE_URL = "Enter the institution's base url";
export const LMS_API_LINK = "LMS API Link";
export const APP_ID = "App ID";
export const APP_ID_PLACEHOLDER = "App ID used for D2L API Access";
export const APP_KEY = "App Key";
export const APP_KEY_PLACEHOLDER = "App key used for D2L API Access";
export const API_ENDPOINT_INST = "The API endpoint for the institution";
export const ACCESS_TOKEN = "Access Token";
export const API_ACCESS_TOKEN = "The institution's API access token";
export const MOODLE_CARD_DESC = "Moodle API Courses and users can be linked based on various fields. Select the fields which you want to be used to link Moodle Courses and Users provisioned using the Moodle API";
export const CANVAS_CARD_DESC = "Canvas API Courses and users can be linked based on various fields. Select the fields which you want to be used to link Canvas Courses and Users provisioned using the Canvas API";
export const D2L_CARD_DESC = "D2L API Courses and users can be linked based on various fields. Select the fields which you want to be used to link D2L Courses and Users provisioned using the D2L API";
export const MOODLE_COURSES = "Moodle Courses:";
export const CANVAS_COURSES = "Canvas Courses:";
export const D2L_COURSES = "D2L Courses:";
export const PRIMARY_LINKAGE_ATTR = "Primary Linkage Attribute:";
export const MOODLE_USERS = "Moodle Users:";
export const CANVAS_USERS = "Canvas Users:";
export const D2L_USERS = "D2L Users:";
export const FALL_BACK_TO_EMAIL_LMS_MOODLE = "Use user email and user's name as backup fields if no Moodle User ID is found (Recommended)";
export const FALL_BACK_TO_EMAIL_LMS_CANVAS = "Use user email and user's name as backup fields if no Canvas User ID is found (Recommended)";
export const FALL_BACK_TO_EMAIL_LMS_D2L = "Use user email and user's name as backup fields if no D2L User ID is found (Recommended)";
//Take Embedded poll guide
export const EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE = "Can not Access the poll. Viewer has to click to the Poll link of LMS to take the poll.";
export const EMBEDDED_POLL_TITLE = "How to Access the Poll";
export const EMBEDDED_POLL_GUIDE_TITLE = "Join Poll Steps";
export const EMBEDDED_POLL_GUIDE_DESCRIPTION = "Simply follow the steps below to join the poll.";
export const EMBEDDED_POLL_GUIDE_STEP_1_PREFIX = "1. Log in to your";
export const EMBEDDED_POLL_GUIDE_STEP_1_SUFFIX = "LMS.";
export const EMBEDDED_POLL_GUIDE_STEP_2 = "2. Navigate to the course that contains the Poll.";
export const EMBEDDED_POLL_GUIDE_STEP_3 = "3. Locate and select the Poll to access it. If you’re unable to locate the Poll, your instructor can provide you with information regarding the name and location of the Poll.";

export const UNAVAILABLE_TEXT = "N/A";
export const PARTICIPANT_TEXT ="Participants";
export const RESPONSE_TEXT = "Responses";
export const WRONG_ANSWER_TEXT = "Wrong Answers"; 
export const COMPLETION_RATE_TEXT = "Response Rate";
export const CORRECT_ANSWER_TEXT = "Correct Answers"; 

// accessibility roles narrator
export const BUTTON = "button";
export const TOGGLE = "toggle";
export const TEXTBOX = "paragraph";
export const NONE = "none";
export const IMG = "img";
export const ACTIVE_POLLS_LABEL = "Join Poll ";
export const ACTIVE_POLLS_LABEL_2 = "with poll code ";
export const POLL_CODE_LABEL = "Text box ";
export const POLL_CODE_2_LABEL = " for the poll code";
export const POLL_TITLE_LABEL = "Poll Title ";
export const PERCENTAGE_LABEL = "Percentage ";
export const VIEW_RESULTS_LABEL = "View Results for Poll ";
export const TOOLTIP = "Tooltip";
export const COURSE_LABEL = "Course Name ";
export const COURSE_CODE_LABEL = "Course Code ";
export const COURSE_NAME_LABEL = "Course Name ";
export const CLOSE_MODAL = "Close Modal";
export const PREVIOUS_PAGE = "Go To Previous Page";
export const NEXT_PAGE = "Go To Next Page";
export const FIRST_PAGE = "Go To First Page";
export const LAST_PAGE = "Go To Last Page";
export const CURRENT_PAGE = "Go To Page ";
export const COLLAPSE_SIDEBAR = "Collapse Sidebar";
export const EXPAND_SIDEBAR = "Expand Sidebar";
export const LOGOUT_LABEL = "Logout";
export const HOME_ICON = "Return to Engage homepage";
export const NUMBER_LABEL = "Number Q";
export const QUESTION_LABEL = "Question";
export const TYPE_LABEL = "Type";
export const STATUS_LABEL = "Status";
export const YOUR_ANS_LABEL = "Your Answer";
export const FITB_BLANK = "______";
export const FITB_NARRATOR = "blank";
export const DIVISOR_LABEL = " out of ";
export const GET_READY_LABEL = "Poll will start in ";
export const SECONDS_LABEL = " seconds";
export const TOTAL_SHARED_LABEL = "Total Shared ";
export const QUESTION_TITLE = "Question Title ";
export const THUMBS_UP = "Rate question with a thumbs up";
export const THUMBS_DOWN = "Rate question with a thumbs down";
export const QUESTIONS_LABEL = " questions";
export const HOUR_LABEL = " hours ";
export const MIN_LABEL = " minutes ";
export const SEC_LABEL = " seconds ";
export const TIME_REMAINING = "Time remaining ";
export const OPTION_LABEL = "Option ";
export const NEXT_QUES = "Go to next question ";
export const PREV_QUES = "Go to previous question ";
export const CUSTOM_TIME_LIMIT = "Type Custom Time Limit";
export const CUSTOM_POINTS = "Type Custom Points";
export const CUSTOM_COUNTDOWN = "Type Custom Countdown";
export const VIEW_COURSE_LABEL = "Find by First Name or Last Name";

// accessibility creator narrator
export const MODAL = " modal";
export const CLOSE_GRADE_MODAL = "Close Configure Gradebook Options Modal";
export const SUPPORT_CENTER_BTN = "Close YuJa Help modal";
export const SUPPORT_CENTER = 'YuJa Help modal';
export const COPY_POLL = "Copy Join Poll Link";
export const ACTIVATE_BUTTON = "Activate the poll: ";
export const VIEW_RESULTS = "View results summary for poll: ";
export const TOTAL_QUESTIONS_LABEL = "Total Questions for ";
export const ACTIONS_LABEL = "More actions for Poll Title: ";
export const VIEW_GRADEBOOK = "View gradebook for poll title: ";
export const YUJA_HELP_MODAL = "YuJa Help modal";
export const QUES_LABEL_1 = "Question ";
export const QUES_LABEL_2 = " Duplicate question button";
export const QUES_LABEL_3 = " Delete question button";
export const DUPLICATE_BTN = "Duplicate question ";
export const DELETE_BTN = "Delete question ";
export const QUESTION_BANK_MODAL = "Question Bank Modal";
export const SERACH_BOX_QUES_BANK = "Search box. Search question banks by typing their names";
export const SEARCH_BTN = "Search questions";
export const QUES_BANK_NAME = "Question bank name: ";
export const TOTAL_QUES_BANK = "Total Questions for ";
export const EDIT_QUES_BANK = "Edit question bank: ";
export const DELETE_QUES_BANK = "Delete question bank: ";
export const QUES_BANK_CLOSE = "Cancel Question Bank modal";
export const QUES_BANK_CHECKBOX = "Check box for ";
export const QUES_BANK_SAVE = "Check box. Save as question bank ";
export const RADIAL_LABEL = " radial button ";
export const ATTEMPTS_BOX = "Check box. Allow unlimited attempsts ";
export const CREATE_POLL_LABEL = "Text box. Enter the poll title here";
export const CREATE_QUES_TYPE = " drop-down menu for more question types";
export const CREATE_QUES_LABEL = "Text box. Enter the question title";
export const UPLOAD_MEDIA = "Upload media button";
export const OPT_LABEL = "Option ";
export const CREATE_OPT_RADIAL = "radial button option ";
export const TEXT_BOX = "Text box. Option ";
export const DELETE_OPT_BTN = "Delete option ";
export const RADIAL_BTN_TRUE = "Radial button true ";
export const RADIAL_BTN_FALSE = "Radial button false ";
export const FITB_INPUT = "Text box. Type a question and click Add Blank for the word you wish to not show. Add the answer to your blank below";
export const FITB_ADD = "Add more options for ";
export const FITB_DELETE = "Delete ";
export const PREMISE_SELECTED = "Text box. Premise ";
export const RESPONSE_LABEL = "Text box. Response ";
export const DELETE_BTN_MH = "Delete Premise ";
export const DELETE_BTN_MH_2 = " and response ";
export const SEARCH_POLL = "Search text box. Find by Poll Title";
export const START_DATE_LABEL = "Select Start Date";
export const END_DATE_LABEL = "Select End Date";
export const ALL_MODE_LABEL = " drop-down menu for modes";
export const ALL_STATUS_LABEL = " drop-down menu for status";
export const ACTIVATE_TIME = "Set the length of time for the Poll in hours, minutes, and seconds.";
export const CANCEL_ACTIVATE = "Cancel Active poll";
export const DATE = "Date ";
export const TITLE = "Title ";
export const MODE = "Mode ";
export const ORDER = " order";
export const QUES_LABEL = "Questions for ";
export const ACT_STATUS = "Activation status for ";
export const ACT_ITEMS = "Action items for ";
export const ACT_MODAL = "Activate Poll Modal";
export const SHARE_MODAL = "Share Poll modal";
export const SHARE_MEMBERS = "Select members drop-down menu";
export const SHARE_MODAL_CLOSE = "Close Share Poll modal button";
export const DELETE_MODAL = "Delete Poll Modal";
export const GRADEBOOK_DROPDOWN = "Select published Poll drop-down menu";
export const SHOW_ATMPS = "Show Attempts carrot icon ";
export const HIDE_ATMPS = "Hide attempts carrot icon ";
export const VIEW_QUES = "View ";
export const STD_LABEL = "Student ";
export const SCORE_LABEL = "Score ";
export const ATMPT_LABEL = "Attempt ";
export const ATMPT_LABEL_2 = " for student";
export const USERS_SEARCH = "Search text box. Find by username, First Name, Last name or Email";
export const CREATE_USER_MODAL = "Create user modal";
export const CANCEL_USER = "Cancel Create User.";
export const TEXT_BOX_MODAL = "Text box enter ";
export const USER_ROLE = "Select role drop-down menu.";
export const ROLE_LABEL = "Role ";
export const OPERATION_LABEL = "Operation more action menu.";
export const SEARCH_BOX_COURSE = "Search text box. Find by course name or course code";
export const EDIT_BTN = "Edit. Course Update ";
export const DELETE_CRSE = "Edit. Delete course ";
export const SELECT_LABEL = "Dropdown. Select ";
export const SEARCH_BOX = "Search text box. Find by First Name or Last name.";
export const INTEG_TEXT_BOX = " text box";
export const ATMPT_DROPDOWN = "Attempt drop-down menu. ";
export const SETT_LABEL = " text box. Enter how you’d like the option to appear";
export const RADIAL_BTN = " radial button. ";
export const CREATE_POLL_CARROT = "Expand the Time Limit panel";
export const CREATE_POLL_CARROT_2 = "Collapse the Time Limit panel";
export const CREATE_POLL_CARROT_3 = "Expand the Points panel";
export const CREATE_POLL_CARROT_4 = "Collapse the Points panel";
export const CREATE_POLL_CARROT_5 = "Expand the Countdown panel";
export const CREATE_POLL_CARROT_6 = "Collapse the Countdown panel";
export const TOGGLE_LABEL = "Toggle button ";

// websocket roles
export const WEBSOCKET_STUDENT = "STUDENT";
export const WEBSOCKET_INSTRUCTOR = "INSTRUCTOR";

export const LOGIN_PAGE_FOOTER_TEXT = "Powered by YuJa"; 
export const LOGIN_BUTTON_TEXT = "Login & Join"; 
export const JOIN_POLL_DESCRIPTION = "To join the Poll, please enter your name and poll code.";
export const JOIN_USING_CODE_QR_TITLE = "Join Using Code or QR";
export const JOIN_USING_CODE_TITLE = "Join Using Code";
export const JOIN_ANONYMOUS_TEXT = "Join Anonymously";
export const QR_LANDING_PAGE_TEXT = "Transforms any course into a more active learning experience for students and allows instructors to gain actionable metrics.";
export const JOIN_ANONYMOUS_MESSAGE = "You can enter your name below to join the Poll.";
export const SCAN_QR_TITLE = "Scan to Join Poll";

export const SEARCH_BOX_PLACEHOLDER = "Find by Poll Title";
export const YUJA_SUPPORT_LINK = "https://support.yuja.com/hc/en-us/p/student-response-system";

export const SURVEY_RESULT_TEXT = "Survey Result For ";
export const POLL_RESULT_TEXT = "Poll Result For ";
export const RELEASE_QUESTION_COUNT_TEXT = "Questions Released:";
export const WAIT_QUESTION_TEXT = "Waiting for the Next Question.";
export const VIDEO_ERROR_CI = "Videos are not supported for Clickable Image questions";

export const YUJA_ENGAGE_CAN_ACCESS_D2L_API = "YuJa Engage now has access to the D2L API";
export const YOU_MAY_NOW_CLOSE_THIS_TAB = "You may now close this tab.";
