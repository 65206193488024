import {updateLogger} from "../utils/helpers";
import {LOG_TYPES, } from "../utils/constants";
import {useContext, useEffect, useRef, useState} from "react";
import {YuJaGlobalState} from "../utils/LoadingContext";

export const useJoinQuizWebsocketManager = (getUrl, handleAfterOpen) => {
    const {isOnline} = useContext(YuJaGlobalState);
    const websocketNeedCloseRef = useRef(false);
    const websocketRef = useRef(null);
    const [websocketState, setWebsocketState] = useState(null);
    const [isInitial, setIsInitial] = useState(true);
    const [websocketMsg, setWebsocketMsg] = useState({});

    //close websocket when component is going to be destroyed
    useEffect(() => {
        return () => {
            closeWebsocketConnection();
        }
    }, []);


    useEffect(() => {
        //network is down
        if (!isOnline) {
            if (!!websocketRef.current) {
                setWebsocketState(WebSocket.CLOSED);
                websocketRef.current.onclose = () =>{}
                websocketRef.current.close();
                websocketRef.current = null;
            }
            return;
        }


        //network recovery
        if (isOnline && !isInitial) {
            //rebuild
            buildWebsocketConnection();
        }
    }, [isOnline]);


    const buildWebsocketConnection = (isReconnect=false) => {
        //close previous one if it's necessary
        if (!!websocketRef.current) {
            websocketRef.current.onclose = () =>{}
            websocketRef.current.close();
        }

        const url = getUrl();
        if (!url || !isOnline) {
            return;
        }

        let newWebSocket = new WebSocket(url);
        newWebSocket.onopen = onOpen;
        newWebSocket.onclose = onClose;
        newWebSocket.onmessage = onMessage;
        newWebSocket.onerror = onError;
        websocketRef.current = newWebSocket;
        setWebsocketState(WebSocket.CONNECTING);
        setIsInitial(false);
    };


    const closeWebsocketConnection = () => {
        if (!!websocketRef.current) {
            setWebsocketState(null);
            setWebsocketMsg({});
            websocketRef.current.onclose = () => {};
            websocketRef.current.close();
            websocketRef.current = null;
        }
    }

    const onClose = (e) => {
        const now = new Date();
        console.log("ws closed", now.toString(), e);
        updateLogger(LOG_TYPES.INFO + "ws closed " + now.toString());
        setWebsocketState(e.currentTarget.readyState);

        if (!websocketNeedCloseRef.current) {
            const now = new Date();
            console.log("ws reopened", now.toString());
            updateLogger(LOG_TYPES.INFO + "ws reopened " + now.toString());
            buildWebsocketConnection(true);
        }
    };

    const onError = (e) => {
        const now = new Date();
        console.log("ws gets error", now.toString(), e)
        setWebsocketState(e.currentTarget.readyState);
    }

    const onMessage = (e) => {
        const now = new Date();
        const message = JSON.parse(e.data);
        console.log("msg receive time: ", now.toString(), e);
        const {liveQuestionSerialNo, liveQuestionMessage, liveQuestionTime} = message;
        console.log(liveQuestionTime, liveQuestionSerialNo, liveQuestionMessage);
        setWebsocketMsg(message);
    }

    const onOpen = (e) => {
        const now = new Date();
        console.log("ws opened", now.toString(), e);
        updateLogger(LOG_TYPES.INFO + "ws opened" + now.toString());
        setWebsocketState(e.currentTarget.readyState);
        if (handleAfterOpen) {
            handleAfterOpen();
        }
    }

    return {
        websocketMsg,
        websocketState,
        buildConnection: buildWebsocketConnection,
        closeConnection: closeWebsocketConnection
    };
};