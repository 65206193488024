import "antd/dist/antd.min.css";
import 'rc-datepicker/lib/style.css';
import React, { useState, useRef, useEffect} from "react";
import "./modals/modal.css";
import "react-datepicker/dist/react-datepicker.css";
import {ReactComponent as CalendarIcon} from "../images/calendarIcon.svg";
import DatePicker from 'react-datepicker';
import styles from "./modals/QuestionProfiles.module.css"; 
import { PollApiCalls } from "../apis/PollApiCalls";
import { removePrefix } from "../utils/helpers";
import { END_DATE_LABEL, SCHEDULE_ADD_END_DATE, SCHEDULE_ADD_START_DATE, SCHEDULE_END_DATE_PLACEHOLDER, SCHEDULE_FROM_TEXT, SCHEDULE_START_DATE_PLACEHOLDER, SCHEDULE_TIME_AM, SCHEDULE_TIME_PM, SCHEDULE_TO_TEXT, START_DATE_LABEL, TEXTBOX } from "../utils/constants";
import YuJaButton from "./standardization/YuJaButton";

const CustomTimeInput =  React.forwardRef(({props, refer}) => {
    return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
    <div style={{display: "flex"}}> 
    <div tabIndex={0} role={TEXTBOX} style={{fontSize: "15px", fontWeight: "700", width: "104px", fontFamily: "Geomanist"}}> 
     {props.start ? SCHEDULE_ADD_START_DATE : SCHEDULE_ADD_END_DATE} 
    </div> 
    <div style={{width: "55.5px", height: "27px", borderRadius: "4.5px", background: "rgba(118, 118, 128, 0.12)", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "15px"}}>
      <input 
        aria-label={(props.start ? props.pollTimeLimitDateH : props.pollTimeLimitDateENDH) + " hours"}
        type="text"
        onChange={(e) => props.handlePollTimeDateChangeH(e, props.Date, props.setDate, props.start)}
        value={props.start ? props.pollTimeLimitDateH : props.pollTimeLimitDateENDH}
        style={{background: "rgba(118, 118, 128, 0.12)", color: "#000", fontSize: "13px", height: "13px", width: "18px", border: "none", fontFamily: "Geomanist"}}
      />
      <div style={{ alignItems: "end", justifyContent: "center", display: "flex", height: "21px" }}> : </div>
      <input
        aria-label={(props.start ? props.pollTimeLimitDateM : props.pollTimeLimitDateENDM) + " minutes"}
        type="text"
        onChange={(e) => props.handlePollTimeDateChangeM(e, props.Date, props.setDate, props.start)}
        value={props.start ? props.pollTimeLimitDateM : props.pollTimeLimitDateENDM}
        style={{ background: "rgba(118, 118, 128, 0.12)", color: "#000", fontSize: "13px", height: "13px", width: "18px", border: "none", fontFamily: "Geomanist"}}
      />
      </div>
      <div style={{borderRadius: "6.75px", background: "rgba(118, 118, 128, 0.12)", width: "75px", height: "27px", marginLeft: "6.5px", display: "flex", alignItems: "center"}}> 
      <div role={TEXTBOX} className={(props.start && props.startTimeZone === "AM") || (!props.start && props.endTimeZone === "AM") ? styles.selectedTimePeriod : styles.notSelectedTimePeriod} style={{width: "38.25px", height: "24px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "1px", fontSize: "13px", fontFamily: "Geomanist", cursor: "pointer"}} tabIndex={0} onClick={() => {props.handleChangeAM(props)}}> {SCHEDULE_TIME_AM} </div>
      <div role={TEXTBOX} className={(props.start && props.startTimeZone === "PM") || (!props.start && props.endTimeZone === "PM") ? styles.selectedTimePeriod : styles.notSelectedTimePeriod} style={{ fontSize: "13px", fontFamily: "Geomanist", width: "36px", height: "24px", alignItems: "center", justifyContent: "center", display: "flex", cursor: "pointer"}} tabIndex={0} onClick={() => {props.handleChangePM(props)}}> {SCHEDULE_TIME_PM} </div>
      </div>
    </div>
    <div style={{marginTop: "10px"}}>
      <YuJaButton style={{width: "100%"}} onClick={() => {refer?.current.setOpen(false)}}>Close</YuJaButton>
    </div>
    </div>
    )
  }); 


export default function DatePickerComponentNew({startDate, endDate, setStartDate, setEndDate, pollKey=false,  pollUniqueCode=false}) {

    const refCustomInputStart = useRef(); 
    const refCustomInputEnd = useRef();

    const [pollTimeLimitDateH, setPollTimeLimitDateH] = useState("00");
    const [pollTimeLimitDateM, setPollTimeLimitDateM] = useState("00");
    const [pollTimeLimitDateENDH, setPollTimeLimitDateENDH] = useState("00");
    const [pollTimeLimitDateENDM, setPollTimeLimitDateENDM] = useState("00");
    const [displayStartDate, setDisplayStartDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState(""); 


    const [startTimeZone, setStartTimeZone] = useState("AM");
    const [endTimeZone, setEndTimeZone] = useState("AM");

    const [disableEditStart, setDisableEditStart] = useState(false); 
    const [disableEditEnd, setDisableEditEnd] = useState(false); 

    const [startDateOpen, setStartDateOpen] = useState(false); 
    const [endDateOpen, setEndDateOpen] = useState(false); 


    const {getPublishedPollDataFromKey } = PollApiCalls();

    const handlePollTimeDateChangeH = (e, date, setDate, start) => {
        let hours = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2); 
        if(start) {
        setPollTimeLimitDateH(hours);
        }
        else {
          setPollTimeLimitDateENDH(hours);
        }
        console.log(hours); 
        
        let targetDate = new Date();
        if (date instanceof Date && !isNaN(date) ) {
            targetDate = date; 
        }
        else {
            targetDate.setMinutes(0);
        }
        if (/^\d*$/.test(hours) && Number(hours) >= 0 && Number(hours) <= 12) { 
          if ((start && startTimeZone === "PM")|| ( !start && endTimeZone === "PM")) {
            if (Number(hours) === 12) {
              targetDate.setHours(12); 
            }
            else {
              targetDate.setHours(Number(hours) + 12); 
            }
          }
          else {
            if (Number(hours) === 12 ) {
              targetDate.setHours(0);
            }
            else {
              targetDate.setHours(hours); 
            }
          }
          setDate(targetDate); 
          if (start) {
            setDisplayStartDate(`${targetDate.getFullYear()}-${String(targetDate.getMonth()+ 1).padStart(2, '0')}-${String(targetDate.getDate()).padStart(2, '0')} ${hours}:${String(targetDate.getMinutes()).padStart(2, '0')} ${startTimeZone}`);
          }
          else {
            setDisplayEndDate(`${targetDate.getFullYear()}-${String(targetDate.getMonth()+ 1).padStart(2, '0')}-${String(targetDate.getDate()).padStart(2, '0')} ${hours}:${String(targetDate.getMinutes()).padStart(2, '0')} ${endTimeZone}`);
          }
        }
      }; 

    
    
    const handlePollTimeDateChangeM = (e, date, setDate, start) => {
        let hours = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2); 
        if(start) {
          setPollTimeLimitDateM(hours);
        }
        else {
          setPollTimeLimitDateENDM(hours);
        }
        console.log(hours); 

        let targetDate = new Date();
        if (date instanceof Date && !isNaN(date) ) {
            targetDate = date; 
        }
        else {
            targetDate.setHours(0);
        }

        let newValue = e.target.value;
        if (/^\d*$/.test(newValue) && Number(newValue) >= 0 && Number(newValue) <= 60) { 
          targetDate.setMinutes(newValue); 
          setDate(targetDate); 
          const hours = String(targetDate.getHours() % 12 || 12).padStart(2, '0');
          if (start) {
            setDisplayStartDate(`${targetDate.getFullYear()}-${String(targetDate.getMonth()+ 1).padStart(2, '0')}-${String(targetDate.getDate()).padStart(2, '0')} ${hours}:${String(targetDate.getMinutes()).padStart(2, '0')} ${startTimeZone}`);
          }
          else {
            setDisplayEndDate(`${targetDate.getFullYear()}-${String(targetDate.getMonth()+ 1).padStart(2, '0')}-${String(targetDate.getDate()).padStart(2, '0')} ${hours}:${String(targetDate.getMinutes()).padStart(2, '0')} ${endTimeZone}`);
          }
        }
      }; 

      const handleChangeAM = (props) => {
        if (props.start) {
          if (props.startTimeZone !== "AM") {
            props.setStartTimeZone("AM");
            props.setDisplayStartDate(props.displayStartDate.slice(0, -2) + "AM");
            if (startDate && startDate instanceof Date) {
              let newStartDate = new Date(startDate);
              newStartDate.setHours(startDate.getHours() - 12);
              setStartDate(newStartDate);
            }
          }
        }
        else {
          if (props.endTimeZone !== "AM") {
            props.setEndTimeZone("AM");
            props.setDisplayEndDate(props.displayEndDate.slice(0, -2) + "AM");
            if (endDate && endDate instanceof Date) {
              let newEndDate = new Date(endDate);
              newEndDate.setHours(!!endDate.getHours() ? endDate.getHours() - 12 : 0);
              setEndDate(newEndDate);
            }
          }
        }
      }
      
      
      const handleChangePM = (props) => {
        if (props.start) {
          if(props.startTimeZone !== "PM" && startDate && startDate instanceof Date) {
            props.setStartTimeZone("PM"); 
            props.setDisplayStartDate(props.displayStartDate.slice(0, -2) + "PM"); 
            let newStartDate = new Date(startDate);
            newStartDate.setHours(startDate.getHours() + 12 < 24 ? startDate.getHours() + 12 : 23); 
            setStartDate(newStartDate);
          }
        }
        else {
          if (props.endTimeZone !== "PM" && endDate && endDate instanceof Date) {
            let newEndDate = new Date(endDate);
            newEndDate.setHours(endDate.getHours() + 12 < 24 ? endDate.getHours() + 12 : 23); 
            setEndDate(newEndDate);
            props.setEndTimeZone("PM");
            props.setDisplayEndDate(props.displayEndDate.slice(0, -2) + "PM");
          }
        } 
      }
    
      const CustomInput = React.forwardRef((props, ref) => {
        return (
          <div className="react-datepicker__input-container">          
            <input type="text" tabIndex={-1} placeholder={SCHEDULE_FROM_TEXT} class="datepickerinputStart react-datepicker-ignore-onclickoutside" value={"Start Date   " + displayStartDate} aria-label={"Start Date   " + displayStartDate} readOnly={true} style={{backgroundColor: disableEditStart ? "#CCCCCC" : "#fff"}}/>
            <div role={TEXTBOX} tabIndex={0} aria-label={START_DATE_LABEL} onClick={() => {if(endDateOpen){props.onBlur(); setEndDateOpen(false);} {if(!disableEditStart){ if(!startDateOpen){props.onClick(); setStartDateOpen(true);} else {props.onBlur(); setStartDateOpen(false);}}}}} style={{position: "absolute", cursor: !disableEditStart ? "pointer" : "", width: "225px", height: "36px", top:"0px"}}  onBlur={() => {setStartDateOpen(false);}}></div>
            <CalendarIcon onClick={() => {if(endDateOpen){props.onBlur(); setEndDateOpen(false);} if(!disableEditStart){if(!startDateOpen){props.onClick(); setStartDateOpen(true);} else {props.onBlur(); setStartDateOpen(false);}}}} style={{transform: "translate(-30px, 8px)", cursor: !disableEditStart ? "pointer": "", position: "absolute"}}/>
          </div>
        );
      });
    
      const CustomInputEnd = React.forwardRef((props, ref) => {
        return (
          <div className="react-datepicker__input-container">          
            <input tabIndex={-1} type="text" placeholder={SCHEDULE_TO_TEXT} class="datepickerinputStart react-datepicker-ignore-onclickoutside" value={"End Date   " + displayEndDate} aria-label={"Start Date   " + displayEndDate} readOnly={true}  style={{backgroundColor: disableEditEnd ? "#CCCCCC" : "#fff"}} onBlur={() => {setEndDateOpen(false);}}/>
            <div role={TEXTBOX} tabIndex={0} aria-label={END_DATE_LABEL} onClick={() => {if(startDateOpen) {props.onBlur(); setStartDateOpen(false);}if (!disableEditEnd) {if(!endDateOpen) {props.onClick(); setEndDateOpen(!endDateOpen)} else {props.onBlur(); setEndDateOpen(false);}}}} style={{position: "absolute", width: "225px", cursor: !disableEditEnd ? "pointer" : "" , height: "36px", top:"0px"}}></div>
            <CalendarIcon onClick={() => {if(startDateOpen) {props.onBlur(); setStartDateOpen(false);} if (!disableEditEnd) {props.onClick(); setEndDateOpen(!endDateOpen)} else {props.onBlur(); setEndDateOpen(false);}}} style={{transform: "translate(-30px, 8px)", cursor: !disableEditEnd ? "pointer" : "", position: "absolute"}}/>
          </div>
        );
      }); 

      const parseDateString = (value) => {
        const hours = String(value.getHours() % 12 || 12).padStart(2, '0');
        const ampm = value.getHours() >= 12 ? 'PM' : 'AM';
        return `${value.getFullYear()}-${String(value.getMonth()+ 1).padStart(2, '0')}-${String(value.getDate()).padStart(2, '0')} ${hours}:${String(value.getMinutes()).padStart(2, '0')} ${ampm}`;
      }

      useEffect (async () => {
        if(pollKey && pollUniqueCode) {
          const result = await getPublishedPollDataFromKey(removePrefix(pollKey), pollUniqueCode.substring(7)); 
          if(result.poll.startTime && result.poll.endTime) {
          const tempStartDate = new Date(result.poll.startTime);
          const tempEndDate = new Date(result.poll.endTime);
          setStartDate(tempStartDate);
          setEndDate(tempEndDate); 
          setPollTimeLimitDateH(String(tempStartDate.getHours() % 12 || 12).padStart(2, '0'));
          setStartTimeZone(tempStartDate.getHours() > 12 ? "PM" : "AM");
          setEndTimeZone(tempEndDate.getHours() > 12 ? "PM" : "AM");
          setPollTimeLimitDateM(String(tempStartDate.getMinutes()).padStart(2, '0'));
          setPollTimeLimitDateENDH(String(tempEndDate.getHours() % 12 || 12).padStart(2, '0'));
          setPollTimeLimitDateENDM(String(tempEndDate.getMinutes()).padStart(2, '0'));

          setDisplayStartDate(parseDateString(tempStartDate));
          setDisplayEndDate(parseDateString(tempEndDate)); 

          if (new Date() > tempStartDate) {
            setDisableEditStart(true); 
          }
          if (new Date() > tempEndDate) {
            setDisableEditEnd(true); 
          }
        }
        }
      }, [pollKey, pollUniqueCode])

    return (
        <div style={{ display: "flex", gap: "10px", marginBottom: "10px", justifyContent: "space-between" }}>
            <div><DatePicker
                ref={refCustomInputStart}
                showTimeInput
                style={{ padding: "20px" }}
                placeholderText={SCHEDULE_START_DATE_PLACEHOLDER}
                className="datepickerinputStart"
                selected={startDate}
                selectsStart
                onChange={(value) => {
                    if(startDate instanceof Date &&  startDate.getHours()) {
                        value.setHours(startDate.getHours()); 
                    }
                    if (startDate instanceof Date && startDate.getMinutes()) {
                        value.setMinutes(startDate.getMinutes());
                    }
                    setStartDate(value);
                    const hours = String(value.getHours() % 12 || 12).padStart(2, '0');
                    const ampm = value.getHours() >= 12 ? 'PM' : 'AM';
                    setDisplayStartDate(`${value.getFullYear()}-${String(value.getMonth() + 1).padStart(2, '0')}-${String(value.getDate()).padStart(2, '0')} ${hours}:${String(value.getMinutes()).padStart(2, '0')} ${ampm}`);
                }
                }
                customInput={<CustomInput/>}
                customTimeInput={<CustomTimeInput props={{ Date: startDate, setDate: setStartDate, start: true, handlePollTimeDateChangeH: handlePollTimeDateChangeH, handlePollTimeDateChangeM: handlePollTimeDateChangeM, pollTimeLimitDateH: pollTimeLimitDateH, pollTimeLimitDateENDH: pollTimeLimitDateENDH, pollTimeLimitDateM: pollTimeLimitDateM, pollTimeLimitDateENDM: pollTimeLimitDateENDM, startTimeZone: startTimeZone, endTimeZone: endTimeZone, setStartTimeZone: setStartTimeZone, setEndTimeZone: setEndTimeZone, displayStartDate: displayStartDate, displayEndDate: displayEndDate, setDisplayStartDate: setDisplayStartDate, setDisplayEndDate: setDisplayEndDate, handleChangeAM: handleChangeAM, handleChangePM: handleChangePM }} refer={refCustomInputStart} key="startDate" />}
                shouldCloseOnSelect={false}
                strictParsing={true}
            />
            </div>
            <div >
                <DatePicker
                    ref={refCustomInputEnd}
                    showTimeInput
                    style={{ padding: "20px" }}
                    placeholderText={SCHEDULE_END_DATE_PLACEHOLDER}
                    className="datepickerinputEnd"
                    selected={endDate}
                    selectsEnd
                    onChange={value => {
                        if(endDate instanceof Date && endDate.getHours()) {
                            value.setHours(endDate.getHours()); 
                        }
                        if (endDate instanceof Date && endDate.getMinutes()) {
                            value.setMinutes(endDate.getMinutes());
                        }
                        setEndDate(value);
                        const hours = String(value.getHours() % 12 || 12).padStart(2, '0');
                        const ampm = value.getHours() >= 12 ? 'PM' : 'AM';
                        setDisplayEndDate(`${value.getFullYear()}-${String(value.getMonth() + 1).padStart(2, '0')}-${String(value.getDate()).padStart(2, '0')} ${hours}:${String(value.getMinutes()).padStart(2, '0')} ${ampm}`);
                    }}
                    customInput={<CustomInputEnd/>}
                    customTimeInput={<CustomTimeInput props={{ Date: endDate, setDate: setEndDate, start: false, handlePollTimeDateChangeH: handlePollTimeDateChangeH, handlePollTimeDateChangeM: handlePollTimeDateChangeM, pollTimeLimitDateH: pollTimeLimitDateH, pollTimeLimitDateENDH: pollTimeLimitDateENDH, pollTimeLimitDateM: pollTimeLimitDateM, pollTimeLimitDateENDM: pollTimeLimitDateENDM, startTimeZone: startTimeZone, endTimeZone: endTimeZone, setStartTimeZone: setStartTimeZone, setEndTimeZone: setEndTimeZone, displayStartDate: displayStartDate, displayEndDate: displayEndDate, setDisplayStartDate: setDisplayStartDate, setDisplayEndDate: setDisplayEndDate, handleChangeAM: handleChangeAM, handleChangePM: handleChangePM }} key="endDate" refer={refCustomInputEnd}/>}
                    shouldCloseOnSelect={false}
                /> </div> </div>


    )

}