import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { PremiseRadio } from "./PremiseRadio";
import DragDropIcon from "../../images/drag_drop.svg";
import {MC_ANSWER_LENGTH, PREMISE_SELECTED} from "../../utils/constants";
import "../styles.css";
import { notifyError } from "../../utils/helpers";
import { MC_CI_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";

export default function Premise({
  optionData,
  selectedQuestion,
  questions,
  setQuestions,
  index,
  pollType
}) {
  const [showDragIcon, setShowDragIcon] = useState(false);
  const { serialNo, } = selectedQuestion;
  const selectedQuestionIndex = serialNo - 1;

  const handleAnswerChange = (input, index) => {
    let copyOfQuestions = [...questions];
    let newOptionsMap = [ ...copyOfQuestions[selectedQuestionIndex].optionsMap ];
    newOptionsMap[index][0] = input;
    copyOfQuestions[selectedQuestionIndex].optionsMap = newOptionsMap;
    setQuestions(
      copyOfQuestions
    );
    console.log(copyOfQuestions)
  };


  return (
    <>
    {optionData.length > 1 ?
      <div style={{display: "flex", width: "100%"}}>
        <Draggable draggableId={"premise" + index} index={index} key={"premise" + index}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1} style={{width: "100%", ...provided.draggableProps.style} }>
              <div
                onMouseEnter={() => setShowDragIcon(true)}
                onMouseLeave={() => setShowDragIcon(false)}
                style={{display: "flex", flex: 1, alignItems: "center"}}
              >
                <div
                  {...provided.dragHandleProps}
                  style={{width: 40,  display: "flex", justifyContent: "center"}}
                  title={"Drag to Reorder"}
                  tabIndex={-1}
                >
                  {showDragIcon && <img src={DragDropIcon} alt="drag drop icon" />}
                </div>
                <PremiseRadio
                  index={index}
                  pollType={pollType}
                />

                <YuJaTextBox
                    name={"question-text"}
                    label={PREMISE_SELECTED + (index+1)}
                    containerStyle={{ width: "100%", fontSize: 20}}
                    placeholder={`Type Premise ${index + 1}`}
                    value={optionData[0]}
                    maxLength={MC_ANSWER_LENGTH}
                    onChange={(e) => {
                      if(e.target.value.length <= MC_ANSWER_LENGTH) {
                        handleAnswerChange(e.target.value, index);
                      }
                      else {
                        notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                        handleAnswerChange(e.target.value.substring(0, MC_ANSWER_LENGTH), index);
                      }
                    }}
                />
              </div>
            </div>
          )}
        </Draggable>
    </div>
        : <></>}
    </>
  );
}