import 'rc-datepicker/lib/style.css';
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./ManageCourses.module.css"
import { COURSE_SEARCH_PLACEHOLDER, SEARCH_BOX_COURSE } from '../../utils/constants';
import YuJaButton from "../../components/standardization/YuJaButton";
import {ReactComponent as SearchIcon} from "../../images/search_bar_icon.svg";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";

export default function CourseSearchForm({ handleSubmit, openCourseModal }) {
    const [keyword, setKeyword] = useState("");
    const [date, setDate] = useState("");

    const clickSearch = () => {
        let formattedDate = date ? date.split("-").reverse().join("/") : "";
        handleSubmit({ keyword: keyword, date: formattedDate });
    }

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch();
        }
    }

    return (
        <div className={styles.searchBox}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                <YuJaTextBox
                    label={SEARCH_BOX_COURSE}
                    containerStyle={{width: "30vw", fontSize: 13, marginRight: 20}}
                    placeholder={COURSE_SEARCH_PLACEHOLDER}
                    onChange={e => setKeyword(e.target.value)}
                    onKeyUp={enterKeyup}
                    value={keyword}
                    before={<SearchIcon style={{marginRight: 5}}/>}
                />

                {/* Old date picker */}
                {/* <Form.Control
                    className={styles.searchBoxDateInput}
                    type="date"
                    placeholder="Date"
                    onChange={e => setDate(e.target.value)}
                    value={date}
                >
                </Form.Control> */}
                
                {/* New date picker */}
                {/* <DatePickerInput
                    displayFormat='DD-MM-YYYY'
                    style={{padding : "20px"}}
                    placeholder="DD-MM-YYYY"
                    onChange={value => {
                        if(!isNaN(value)){
                            setDate(value.toISOString().split('T')[0]);
                        } else {
                            setDate("");
                        }
                    }}
                    value={date}
                /> */}

                <YuJaButton onClick={clickSearch} >Search</YuJaButton>
            </Form>
        </div>
    );
}
