import { useState } from "react";
import { Form } from "react-bootstrap";
import { useLoading } from "../../utils/LoadingContext";
import styles from "./PollChooser.module.css";

export default function PollChooserSearchForm({ handleSubmit }) {
    const [keyword, setKeyword] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const {setLoading} = useLoading();

    const clickSearch = () => {
        let formattedStartDate = startDate ? startDate.split("-").reverse().join("-") : "";
        let formattedEndDate = endDate ? endDate.split("-").reverse().join("-") : "";
        handleSubmit({ keyword: keyword, startDate: formattedStartDate, endDate: formattedEndDate });
    }

    const enterKeyup = (e) => {
        setLoading(true);
        clickSearch();
        setLoading(false);
        // if (e.key === 'Enter') {
        // }
    }

    return (
        <div className={styles.searchBox}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                <Form.Control
                    className={styles.searchBoxKeywordInput}
                    placeholder="Find by Poll Title"
                    onChange={e => setKeyword(e.target.value)}
                    onKeyUp={enterKeyup}
                    value={keyword}
                >
                </Form.Control>
                <Form.Control
                    className={styles.searchBoxDateInput}
                    type="date"
                    placeholder="Start Date"
                    onChange={e => setStartDate(e.target.value)}
                    value={startDate}
                >
                </Form.Control>
                <Form.Control
                    className={styles.searchBoxDateInput}
                    type="date"
                    placeholder="End Date"
                    onChange={e => setEndDate(e.target.value)}
                    value={endDate}
                >
                </Form.Control>
                <div className={styles.searchBoxSearchBtn} onClick={clickSearch}>Search</div>
            </Form>
        </div>
    );
}