import {
  CompositeDecorator,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
} from "draft-js";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BLANK_REGEX, POLL_TYPE } from "../../utils/constants";
import { updateCorrectAnswers, updateTitleInput } from "../../utils/questionUpdate";
import FITBAnswerGroup from "./FITBAnswerGroup";
import FITBQuestionTitle from "./FITBQuestionTitle";
import FITBRow from "./FITBRow";
import useMobileAccess from "../../hooks/useMobileAccess"; 
import YuJaFITBPreviewModal from "../../components/modals/YuJaFITBPreviewModal";
import FITBAnswer from "./FITBAnswer";
export default function FITBQuestion({
  questions,
  setQuestions,
  selectedQuestion,
  pollType
}) {
  const { correctAnswers, serialNo, queTitle='' } = selectedQuestion;
  const indexToChange = serialNo - 1;

  const isNarrow = useMobileAccess(1100);

  const [previewModalShow, setPreviewModalShow] = useState(false); 

  const handleAddAnswer = (key) => {
    correctAnswers[key].push("");
    setQuestions(
      updateCorrectAnswers({ ...correctAnswers }, indexToChange, questions)
    );
  };

  const handleUpdateAnswer = (key, indexInAnswersArray, text) => {
    correctAnswers[key][indexInAnswersArray] = text;
    setQuestions(
      updateCorrectAnswers({ ...correctAnswers }, indexToChange, questions)
    );
  };
  
  function BlankStrategy(contentBlock, callback) {
    findWithRegex(BLANK_REGEX, contentBlock, callback);
  }

  const BlankSpan = (props) => {
    return (
      <span
        style={{
          color: "#029ce2",
          fontWeight: 700,
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontSize: "18px"
        }}
        data-offset-key={props.offsetKey}
      >
        {props.children}
      </span>
    );
  };

  const [compositeDecorator, ] = useState(new CompositeDecorator([
    {
      strategy: BlankStrategy,
      component: BlankSpan,
    },
  ]));
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromText(queTitle),
      compositeDecorator
    )
  );

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  const removeTextFromEditor = (text, editorValue) => {
    const currentContent = editorValue.getCurrentContent();
    let parsedContent = currentContent;
    currentContent
      .getBlockMap()
      .filter((block) => block.getText().includes(text))
      .forEach((block) => {
        const offset = block.getText().indexOf(text);
        parsedContent = Modifier.replaceText(
          parsedContent,
          SelectionState.createEmpty(block.getKey())
            .set("anchorOffset", offset)
            .set("focusOffset", offset + text.length),
          ""
        );
      });

    return EditorState.createWithContent(parsedContent, compositeDecorator);
  };

  const handleDeleteAnswer = (key, indexInAnswersArray) => {
    let newQuestions = questions;

    if (correctAnswers[key].length === 1) {
      const newEditorState = removeTextFromEditor("(" + key + ")", editorState);
      setEditorState(newEditorState);
      newQuestions = updateTitleInput(
        newEditorState.getCurrentContent().getPlainText(),
        indexToChange,
        questions
      );
      delete correctAnswers[key];
    } else {
      correctAnswers[key].splice(indexInAnswersArray, 1);
      console.log(newQuestions);
    }
    setQuestions(
      updateCorrectAnswers({ ...correctAnswers }, indexToChange, newQuestions)
    );
  };

  const modalConfig = {
    title: "Blank Preview",
    submitButtonText: "Proceed Anyways",
    close: (() => {setPreviewModalShow(false)}), 
    cancelText: "Close"
};

  return (
    <Container className="question-main-container no-padding" style={{paddingLeft: "2px", paddingRight: "0px"}}>
      {previewModalShow && <YuJaFITBPreviewModal config={modalConfig} queTitle={queTitle} setModalShow={setPreviewModalShow} show={previewModalShow} sumbitButtonShow={false}/>}
      <FITBQuestionTitle
        setQuestions={setQuestions}
        questions={questions}
        selectedQuestion={selectedQuestion}
        editorState={editorState}
        setEditorState={setEditorState}
        compositeDecorator={compositeDecorator}
        previewModalShow={previewModalShow}
        setPreviewModalShow={setPreviewModalShow}
      />
      <div className={"FITBAnswerContainer"}>    
      {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
      <>{Object.keys(correctAnswers).length === 0 ? null : (
        <FITBRow noBorder={true} text="Type Answers here" showButtons={false}
        >
            {/* <Col className="fitb-input-col margin-vertical-auto no-padding">
               <FITBTextArea disabled={true} value="" placeholder="Enter potential answers here"/>
             </Col> */}
          
            <Container>
            <div style={{display: 'flex', flexWrap: "wrap", gap: "5%"}}> 
              {Object.keys(correctAnswers).map((key, i) => (
                <>
                {correctAnswers[key].map((value, i) => (
                <div key={i} style={{ minWidth: "47.5%", flexBasis: "47.5%"}}>
                    <FITBAnswer
                      index={i}
                      answerKey={key}
                      answerValue={value}
                      handleAddAnswer={handleAddAnswer}
                      handleUpdateAnswer={handleUpdateAnswer}
                      handleDeleteAnswer={handleDeleteAnswer}
                    />
                </div>
              ))}
              </> ))}
              </div>
            </Container>
        </FITBRow>
      )}</>
      }
      </div>
    </Container>
  );
}
