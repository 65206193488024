import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { INTEG_TEXT_BOX } from "../../utils/constants";
import styles from "./YuJaTextBox.module.css";
import { YuJaTooltip } from "./YuJaTooltip";



export const YuJaTextBox = ({
                                disabled=false,
                                isInvalid=false,
                                value="",
                                name="",
                                errorMsg="",
                                onBlur,
                                onChange,
                                placeholder,
                                type="text",
                                inputAlign="left",
                                before,
                                after,
                                containerStyle={},
                                style={},
                                inputClass =null, 
                                label="textbox",
                                useValue=false,
                                ...props
}) => {
    const actualInputRef = useRef(null);
    const [inputPadding, setInputPadding] = useState(10);
    const [inputVal, setInputVal] = useState(value);


    const onChangeFunc = (e) => {
        setInputVal(e.target.value);
        if (onChange) {
            onChange(e);
        }
    }

    const onClick = () => {
        actualInputRef.current.focus();
    }

    useEffect(() => {
        setInputVal(value);
    }, [value])


    useEffect(() => {
        if (containerStyle.padding) {
            setInputPadding(containerStyle.padding);
            return;
        }

        const inputFontSize = parseFloat(getComputedStyle(actualInputRef.current).fontSize);
        if (inputFontSize <= 13) {
            setInputPadding(6);
        }

        if (inputFontSize > 16) {
            setInputPadding(inputFontSize * 10 / 16);
        }
    }, []);


    return (
            <div
                className={`${styles.box} ${disabled ? styles.disabled : ""} ${isInvalid ? styles.invalid : ""}`}
                 style={{...containerStyle, padding: inputPadding}}
                onClick={onClick}
            >
                {before}
                <input
                    className={inputClass ? inputClass : ""}
                    ref={actualInputRef}
                    name={name}
                    value={useValue? value : inputVal}
                    type={type}
                    onChange={onChangeFunc}
                    onBlur={onBlur}
                    disabled={disabled}
                    placeholder={placeholder}
                    style={{...style, textAlign: inputAlign}}
                    aria-label={label === "textbox" ? placeholder + INTEG_TEXT_BOX : label}
                    {...props}
                />
                {after}

                {isInvalid && errorMsg &&
                    <div className={styles.iconContainer}>
                        <YuJaTooltip placement={"right"} text={errorMsg} tooltipStyle={{maxWidth: 300}}>
                            <div className={styles.invalidIcon}/>
                        </YuJaTooltip>
                    </div>
                }
            </div>
    )
}


YuJaTextBox.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.oneOf(["text", "password", "number"]),
    disabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    inputAlign: PropTypes.oneOf(["left", "center", "right"]),
}