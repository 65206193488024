import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import BaseQuestion from "../../questions/BaseQuestion";
import { CREATE_POLL_LABEL, POLL_TITLE_LENGTH, POLL_TITLE_PLACEHOLDER, POLL_TYPE, QUESTION_TYPES, SAVE_CLOSE_BUTTON_TEXT, SURVEY_TITLE_PLACEHOLDER, UPDATE_CLOSE_BUTTON_TEXT } from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import { POLL_TITLE_CHARACTER_LIMIT_EXCEEDED, SURVEY_TITLE_CHARACTER_LIMIT_EXCEEDED } from "../../utils/toast-message-constants";
import "./styles.css";

export default function QuestionsMain({
                                        title,
                                        setTitle,
                                        isEditPage = false,
                                        questions,
                                        setQuestions,
                                        savePoll,
                                        savePollClose,
                                        institutionId,
                                        pollKey,
                                        currQueDisplay,
                                        changeCurrQueDisplay,
                                        saveButtonDisabled,
                                        pollType,
                                        isSaving,
                                        disableSave
                                      }) {


  const saveButtonStyle = {
    fontSize: 14,
    width: "277px", 
    height: "35px" , 
    padding: "7.941px", 
    borderRadius: "4.21px", 
    border: "1.336px solid #42296E", 
    justifyContent: "center", 
    alignItems: "center",
    display: "flex",
  };

  const pollTitleLimit = POLL_TITLE_LENGTH;

  useEffect(() => {
    if (!!pollType && pollType.toUpperCase() === POLL_TYPE.SURVEY){
      let questionsWithoutAnswers = questions;
      questionsWithoutAnswers.forEach((question) => {
        if (question.questionType === QUESTION_TYPES.MCSS.name || question.questionType === QUESTION_TYPES.TF.name || question.questionType === QUESTION_TYPES.SA.name) {
          question.correctAnswers = [];
        }
        // else if (question.questionType === QUESTION_TYPES.FITB.name) {
          // console.log("FITB Correct Answers", question.correctAnswers);
          // question.correctAnswers = {};
        // }
      })
      setQuestions(questionsWithoutAnswers);
     }
   }, [pollType]);

  return (
    <div>
        <Row style={{marginTop: "36px"}}>
          <Col className="quiz-title-col margin-vertical-auto no-padding ">
                  <YuJaTextBox
                    label={CREATE_POLL_LABEL}
                    containerStyle={{color: "#006DC7", fontSize: "20px", fontFamily: "Geomanist", width: "auto", padding: 12, fontWeight: 400, borderRadius: "9.039px;"}}
                    placeholder= {pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_PLACEHOLDER : POLL_TITLE_PLACEHOLDER}
                    inputAlign={"center"}
                    name={"pollTitle"}
                    value={title}
                    maxLength={pollTitleLimit}
                    onChange={(e) => {
                        if (e.target.value.length <= pollTitleLimit) {
                            setTitle(e.target.value);
                        }
                        else {
                            notifyError(pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_CHARACTER_LIMIT_EXCEEDED : POLL_TITLE_CHARACTER_LIMIT_EXCEEDED);
                            setTitle(e.target.value.substring(0, POLL_TITLE_LENGTH));
                        }
                    }}
                    inputClass={"PollTitleInput"}
                  />
          </Col>
        </Row>

      <BaseQuestion
        questions={questions}
        setQuestions={setQuestions}
        currQueDisplay={currQueDisplay}
        changeCurrQueDisplay={changeCurrQueDisplay}
        selectedQuestion={questions[currQueDisplay-1]}
        pollType={pollType}
        institutionId={institutionId}
        pollKey={pollKey}
        isSaving={isSaving}
      />

      <div style={{display: "flex", margin: "10px 0", justifyContent: "center"}}>
          <YuJaButton title={"Save & Close Poll"} onClick={disableSave ? null : savePollClose} style={saveButtonStyle} label={isEditPage ? UPDATE_CLOSE_BUTTON_TEXT : SAVE_CLOSE_BUTTON_TEXT}>
            {isEditPage ? UPDATE_CLOSE_BUTTON_TEXT : SAVE_CLOSE_BUTTON_TEXT}
          </YuJaButton>
      </div>
    </div>
  );
}
