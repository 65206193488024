import React, {useEffect, useRef, useState} from "react";
import styles from "./YuJaKebabMenu.module.css";
import {ReactComponent as MenuIcon} from "../../images/cebabe_menu_icon.svg"
import {useYuJaEventBus} from "./YuJaEventBusProvider";
import { BUTTON, TEXTBOX } from "../../utils/constants";

export const YuJaKebabMenu = ({children, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const iconRef = useRef();

    const open = () => {
            setIsOpen(!isOpen);
    }


    return (
        <div className={styles.container}>
            <MenuIcon
                aria-label="Open Menu"
                role={BUTTON}
                tabIndex={0}
                onClick={open}
                ref={iconRef}
                style={{cursor: "pointer"}}
            />
            {
                children &&
                React.cloneElement(
                    children,
                    {
                        ...children.props,
                        isOpen: isOpen,
                        setIsOpen: setIsOpen,
                        iconRef: iconRef,
                    }
                )
            }
        </div>
    );
}


function Menu({isOpen, setIsOpen, iconRef, children}) {
    const menuRef = useRef();
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();

    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && menuRef.current && !iconRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            addClickEventHandler(handleGlobalClick);
        }

        return () => {
            removeClickEventHandler(handleGlobalClick);
        };
    }, [isOpen]);

    return (
        <>
            {isOpen &&
                <>
                    <div className={styles.menuContainer} ref={menuRef}>
                        <div className={`${styles.menuList}`} >
                            {
                                children && Array.isArray(children) &&
                                    children.map((item, index) => (
                                       <>
                                           {item &&
                                               React.cloneElement(
                                                   item,
                                                   {
                                                       ...item.props,
                                                       id: index,
                                                       key: index,
                                                       isOpen: isOpen,
                                                       setIsOpen: setIsOpen
                                                   }
                                               )
                                           }
                                       </>
                                    ))
                            }


                            {
                                children && !Array.isArray(children) &&
                                    React.cloneElement(
                                        children,
                                        {
                                            ...children.props,
                                            isOpen: isOpen,
                                            setIsOpen: setIsOpen
                                        }
                                    )

                            }
                        </div>
                    </div>
                    {/*<div className={styles.backDrop} onClick={() => setIsOpen(false)}/>*/}
                </>
            }
        </>
    );
}


function Item({children, isOpen, label, setIsOpen, ...props}) {
    const nodeRef = useRef();

    useEffect(() => {
            const textNode = nodeRef.current;
            if (textNode && textNode.offsetWidth < textNode.scrollWidth) {
                textNode.title = textNode.innerText;
            }
            console.log(`isOpen: ${isOpen}`)
    }, []);

    const onClick =(e) => {
        setIsOpen(false);
        if (props.onClick) {
            props.onClick(e);
        }

    }

    return (
        <div className={`${styles.menuItem}`} role={TEXTBOX} onClick={onClick} tabIndex={0} aria-label={label}>
            <span ref={nodeRef} className={styles.menuItemText}>{children}</span>
        </div>
    );
}

YuJaKebabMenu.Menu= Menu;
YuJaKebabMenu.Item= Item;