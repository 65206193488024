import React, { useEffect, useState } from "react";
import { Card, Form, FormGroup } from "react-bootstrap";
import { AuthenticationApiCalls } from "../../../../apis/AuthenticationApiCalls";
import { InstitutionApiCalls } from "../../../../apis/InstitutionApiCalls";
import YuJaButton from "../../../../components/standardization/YuJaButton";
import { YuJaCheckbox } from "../../../../components/standardization/YuJaCheckbox";
import { YuJaDropdown } from "../../../../components/standardization/YuJaDropdown";
import { YuJaTextBox } from "../../../../components/standardization/YuJaTextBox";
import apiAccessIcon from "../../../../images/apiAccessIcon.svg";
import linkageSettingsIcon from "../../../../images/linkageSettingsIcon.svg";
import syncSettingsIcon from "../../../../images/syncSettingsIcon.svg";
import {
    ACCESS_TOKEN,
    ALLOW_API_ACCESS,
    API_ACCESS,
    API_ACCESS_TOKEN,
    API_ENDPOINT_INST,
    APP_ID,
    APP_ID_PLACEHOLDER,
    APP_KEY,
    APP_KEY_PLACEHOLDER,
    CANVAS_CARD_DESC,
    CANVAS_COURSES,
    CANVAS_USERS,
    COURSE_ID,
    D2L_CARD_DESC,
    D2L_COURSES,
    D2L_USERS,
    DOMAIN,
    ENTER_COURSE_ID,
    FALL_BACK_TO_EMAIL_LMS_CANVAS,
    FALL_BACK_TO_EMAIL_LMS_D2L,
    FALL_BACK_TO_EMAIL_LMS_MOODLE,
    INST_BASE_URL,
    INTEGRATION_TYPES,
    INTEGRATION_TYPES_LOWERCASE,
    INTEG_TEXT_BOX,
    LINKAGE_SETTINGS,
    LMS_API_LINK,
    MOODLE_CARD_DESC,
    MOODLE_COURSES,
    MOODLE_USERS,
    PRIMARY_LINKAGE_ATTR,
    START_SYNC,
    SYNC_SETTINGS,
    TEXTBOX
} from "../../../../utils/constants";
import { notifyError } from "../../../../utils/helpers";
import styles from "./LTI3.module.css";

export default function GeneralLMSAPIPage({ selectedMenu, canvasForm, blackboardForm, d2lForm, moodleForm, setD2lForm, setBlackboardForm, setCanvasForm, setMoodleForm }) {
    const [loaded, setLoaded] = useState(false);
    const [syncCourseId, setSyncCourseId] = useState("");
    const { getSSODetails } = InstitutionApiCalls();
    const { createUserRoster, getD2LAuthURL, tryCanvasURL } = AuthenticationApiCalls();
    const MOODLE_USER_LINKAGE_ATTRIBUTES = {
        MOODLE_USER_ID: { value: "userId", display: "Moodle User ID" },
        PRIMARY_EMAIL: { value: "email", display: "Primary Email" }
    }
    const CANVAS_USER_LINKAGE_ATTRIBUTES = {
        CANVAS_USER_ID: { value: "userId", display: "Canvas User ID" },
        PRIMARY_EMAIL: { value: "email", display: "Primary Email" }
    }
    const D2L_USER_LINKAGE_ATTRIBUTES = {
        D2L_USER_ID: { value: "userId", display: "D2L User ID" },
        PRIMARY_EMAIL: { value: "email", display: "Primary Email" }
    }
    const MOODLE_COURSE_LINKAGE_ATTRIBUTES = {
        MOODLE_COURSE_ID: { value: "courseId", display: "Moodle Course ID" },
    }
    const CANVAS_COURSE_LINKAGE_ATTRIBUTES = {
        CANVAS_COURSE_ID: { value: "courseId", display: "Canvas Course ID" },
    }
    const D2L_COURSE_LINKAGE_ATTRIBUTES = {
        D2L_COURSE_ID: { value: "courseId", display: "D2L Course ID" },
    }
    const [selectedUserItem, setSelectedUserItem] = useState(MOODLE_USER_LINKAGE_ATTRIBUTES.MOODLE_USER_ID.value);
    const [selectedCourseItem, setSelectedCourseItem] = useState(MOODLE_COURSE_LINKAGE_ATTRIBUTES.MOODLE_COURSE_ID.value);
    const getIntegrationType = () => {
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) { return INTEGRATION_TYPES.MOODLE; }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) { return INTEGRATION_TYPES.CANVAS; }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) { return INTEGRATION_TYPES.D2L; }
    };
    const getSSOMap = (ssoMap) => {
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) { return ssoMap.MOODLE; }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) { return ssoMap.CANVAS; }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) { return ssoMap.D2L; }
    };
    const getForm = () => {
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) { return moodleForm; }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) { return canvasForm; }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) { return d2lForm; }
    };
    const callUpdateFunction = (e) => {
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) { handleMoodleFormChange(e); }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) { handleCanvasFormChange(e); }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) { handleD2LFormChange(e); }
    };
    const handleUserLinkageValueChange = (item) => {
        setSelectedUserItem(item.value);
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {
            const moodleFormConst = Object.assign({}, moodleForm);
            moodleFormConst["apiUserLinkageAttr"] = item.value;
            setMoodleForm(moodleFormConst);
        }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {
            const canvasFormConst = Object.assign({}, canvasForm);
            canvasFormConst["apiUserLinkageAttr"] = item.value;
            setCanvasForm(canvasFormConst);
        }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {
            const d2lFormConst = Object.assign({}, d2lForm);
            d2lFormConst["apiUserLinkageAttr"] = item.value;
            setD2lForm(d2lFormConst);
        }
    };
    const handleCourseLinkageValueChange = (item) => {
        setSelectedCourseItem(item.value);
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {
            const moodleFormConst = Object.assign({}, moodleForm);
            moodleFormConst["apiCourseLinkageAttr"] = item.value;
            setMoodleForm(moodleFormConst);
        }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {
            const canvasFormConst = Object.assign({}, canvasForm);
            canvasFormConst["apiCourseLinkageAttr"] = item.value;
            setCanvasForm(canvasFormConst);
        }
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {
            const d2lFormConst = Object.assign({}, d2lForm);
            d2lFormConst["apiCourseLinkageAttr"] = item.value;
            setD2lForm(d2lFormConst);
        }
    };
    const handleMoodleFormChange = (e) => {
        const input = e.currentTarget;
        const moodleFormConst = Object.assign({}, moodleForm);
        if (input.type === "checkbox") {
            moodleFormConst[input.name] = input.checked;
        } else {
            moodleFormConst[input.name] = input.value;
        }
        setMoodleForm(moodleFormConst);
    };
    const handleCanvasFormChange = (e) => {
        const input = e.currentTarget;
        const canvasFormConst = Object.assign({}, canvasForm);
        if (input.type === "checkbox") {
            canvasFormConst[input.name] = input.checked;
        } else {
            canvasFormConst[input.name] = input.value;
        }
        setCanvasForm(canvasFormConst);
    };
    const handleD2LFormChange = (e) => {
        const input = e.currentTarget;
        const d2lFormConst = Object.assign({}, d2lForm);
        if (input.type === "checkbox") {
            d2lFormConst[input.name] = input.checked;
        } else {
            d2lFormConst[input.name] = input.value;
        }
        setD2lForm(d2lFormConst);
    };
    const d2lAllowApiAccess = () => {
        if (d2lForm.domain !== "" && d2lForm.lmsApiLink !== "") {
            getD2LAuthURL();
        } else {
            notifyError("Please Enter a valid Course ID");
        }
    };
    const syncUserRosterNow = () => {
        // if(selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {
        //     tryCanvasURL(getForm().apiAccessToken);
        // }
        if (syncCourseId && syncCourseId !== "") {
            createUserRoster(syncCourseId, selectedMenu);
        } else {
            notifyError("Please Enter a valid Course ID");
        }
    };
    const fetchData = () => {
        getSSODetails().then((result) => {
          let ssoMap = result.ssoConfig;
          const ssoConfigData = JSON.parse(
            ssoMap.hasOwnProperty(getIntegrationType())
              ? getSSOMap(ssoMap)
              : "{}"
          );
          if (
            ssoConfigData !== "undefined" ||
            ssoConfigData.propertyIsEnumerable()
          ) {
            if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) { 
                if(ssoConfigData.apiCourseLinkageAttr === undefined || ssoConfigData.apiCourseLinkageAttr === "") {
                    ssoConfigData.apiCourseLinkageAttr = MOODLE_COURSE_LINKAGE_ATTRIBUTES.MOODLE_COURSE_ID.value;
                }
                setSelectedCourseItem(ssoConfigData.apiCourseLinkageAttr);
                if(ssoConfigData.apiUserLinkageAttr === undefined || ssoConfigData.apiUserLinkageAttr === "") {
                    ssoConfigData.apiUserLinkageAttr = MOODLE_USER_LINKAGE_ATTRIBUTES.MOODLE_USER_ID.value;
                }
                setSelectedUserItem(ssoConfigData.apiUserLinkageAttr);
                setMoodleForm(ssoConfigData); 
            }
            if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) { 
                if(ssoConfigData.apiCourseLinkageAttr === undefined || ssoConfigData.apiCourseLinkageAttr === "") {
                    ssoConfigData.apiCourseLinkageAttr = CANVAS_COURSE_LINKAGE_ATTRIBUTES.CANVAS_COURSE_ID.value;
                }
                setSelectedCourseItem(ssoConfigData.apiCourseLinkageAttr);
                if(ssoConfigData.apiUserLinkageAttr === undefined || ssoConfigData.apiUserLinkageAttr === "") {
                    ssoConfigData.apiUserLinkageAttr = CANVAS_USER_LINKAGE_ATTRIBUTES.CANVAS_USER_ID.value;
                }
                setSelectedUserItem(ssoConfigData.apiUserLinkageAttr);
                setCanvasForm(ssoConfigData); 
            }
            if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) { 
                if(ssoConfigData.apiCourseLinkageAttr === undefined || ssoConfigData.apiCourseLinkageAttr === "") {
                    ssoConfigData.apiCourseLinkageAttr = D2L_COURSE_LINKAGE_ATTRIBUTES.D2L_COURSE_ID.value;
                }
                setSelectedCourseItem(ssoConfigData.apiCourseLinkageAttr);
                if(ssoConfigData.apiUserLinkageAttr === undefined || ssoConfigData.apiUserLinkageAttr === "") {
                    ssoConfigData.apiUserLinkageAttr = D2L_USER_LINKAGE_ATTRIBUTES.D2L_USER_ID.value;
                }
                setSelectedUserItem(ssoConfigData.apiUserLinkageAttr);
                setD2lForm(ssoConfigData); 
            }
          }
        });
      };
    useEffect(() => {
        fetchData();
        setLoaded(true);
        if(document.getElementById("lmsApiLastElem") != null) {
            document.getElementById("lmsApiLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("integrationSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('integrationSaveBtn').focus();
                }
            });
        }
    }, [loaded, selectedMenu]);

    return (<div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Card className={styles.smallBox}>
                <Card.Header>
                    <img src={apiAccessIcon} alt={API_ACCESS} width={"24px"} height={"24px"} />
                    <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{API_ACCESS}</span>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <div className="form-label">{DOMAIN}</div>
                            <YuJaTextBox
                                label={DOMAIN + INTEG_TEXT_BOX}
                                placeholder={INST_BASE_URL}
                                name="domain"
                                value={getForm().domain}
                                containerStyle={{ fontSize: 14, backgroundColor: "#FFF" }}
                                onChange={callUpdateFunction}
                            />
                        </Form.Group>
                        <Form.Group>
                            <div className="form-label">{LMS_API_LINK}</div>
                            <YuJaTextBox
                                label={LMS_API_LINK + INTEG_TEXT_BOX}
                                placeholder={API_ENDPOINT_INST}
                                name="lmsApiLink"
                                value={getForm().lmsApiLink}
                                containerStyle={{ fontSize: 14, backgroundColor: "#FFF" }}
                                onChange={callUpdateFunction}
                            />
                        </Form.Group>
                        {(selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE || selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) &&
                            <Form.Group>
                                <div className="form-label">{ACCESS_TOKEN}</div>
                                <YuJaTextBox
                                    label={ACCESS_TOKEN + INTEG_TEXT_BOX}
                                    placeholder={API_ACCESS_TOKEN}
                                    name="lmsApiLink"
                                    value={getForm().apiAccessToken}
                                    containerStyle={{ fontSize: 14, backgroundColor: "#FFF" }}
                                    onChange={callUpdateFunction}
                                />
                            </Form.Group>
                        }
                        {selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L &&
                            <Form.Group>
                                <div className="form-label">{APP_ID}</div>
                                <YuJaTextBox
                                    label={APP_ID + INTEG_TEXT_BOX}
                                    placeholder={APP_ID_PLACEHOLDER}
                                    name="appId"
                                    value={getForm().appId}
                                    containerStyle={{ fontSize: 14, backgroundColor: "#FFF" }}
                                    onChange={callUpdateFunction}
                                />
                                <div className="form-label">{APP_KEY}</div>
                                <YuJaTextBox
                                    label={APP_KEY + INTEG_TEXT_BOX}
                                    placeholder={APP_KEY_PLACEHOLDER}
                                    name="appKey"
                                    value={getForm().appKey}
                                    containerStyle={{ fontSize: 14, backgroundColor: "#FFF" }}
                                    onChange={callUpdateFunction}
                                />
                                <div className="form-label">
                                    <YuJaButton onClick={d2lAllowApiAccess}>{ALLOW_API_ACCESS}</YuJaButton>
                                </div>
                            </Form.Group>
                        }
                    </Form>
                </Card.Body>
            </Card>
            <Card className={styles.smallBox}>
                <Card.Header>
                    <img src={linkageSettingsIcon} alt={LINKAGE_SETTINGS} width={"24px"} height={"24px"} />
                    <span role={TEXTBOX} tabIndex={0} className={styles.cardHeader}>{LINKAGE_SETTINGS}</span>
                </Card.Header>
                <Card.Body>
                {selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE && 
                    <Form>
                    <Form.Group>
                        <div>{MOODLE_CARD_DESC}</div>
                    </Form.Group>
                    <Form.Group>
                        <div className="form-label">{MOODLE_COURSES}</div>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>{PRIMARY_LINKAGE_ATTR}</div>
                        <YuJaDropdown
                            containerStyle={{ width: 200 }}
                            data={Object.values(MOODLE_COURSE_LINKAGE_ATTRIBUTES)}
                            value={selectedCourseItem}
                            getOptionLabel={item => item.display}
                            getOptionValue={item => item.value}
                            name="apiCourseLinkageAttr"
                            onChange={handleCourseLinkageValueChange}
                        ></YuJaDropdown>
                    </div>
                    <Form.Group>
                        <div className="form-label">{MOODLE_USERS}</div>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>{PRIMARY_LINKAGE_ATTR}</div>
                        <YuJaDropdown
                            containerStyle={{ width: 200 }}
                            data={Object.values(MOODLE_USER_LINKAGE_ATTRIBUTES)}
                            value={selectedUserItem}
                            getOptionLabel={item => item.display}
                            getOptionValue={item => item.value}
                            name="apiUserLinkageAttr"
                            onChange={handleUserLinkageValueChange}
                        ></YuJaDropdown>
                    </div>
                    <Form.Group controlId="fallbackToEmail" style={{margin: "10px 0 0 0", fontSize: "14px"}}>
                        <YuJaCheckbox name="fallbackToEmailLms" checked={getForm().fallbackToEmailLms} label={FALL_BACK_TO_EMAIL_LMS_MOODLE} onClick={callUpdateFunction}/>
                    </Form.Group>
                </Form>
                }
                {selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS && 
                    <Form>
                    <Form.Group>
                        <div>{CANVAS_CARD_DESC}</div>
                    </Form.Group>
                    <Form.Group>
                        <div className="form-label">{CANVAS_COURSES}</div>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>{PRIMARY_LINKAGE_ATTR}</div>
                        <YuJaDropdown
                            containerStyle={{ width: 200 }}
                            data={Object.values(CANVAS_COURSE_LINKAGE_ATTRIBUTES)}
                            value={selectedCourseItem}
                            getOptionLabel={item => item.display}
                            getOptionValue={item => item.value}
                            name="apiCourseLinkageAttr"
                            onChange={handleCourseLinkageValueChange}
                        ></YuJaDropdown>
                    </div>
                    <Form.Group>
                        <div className="form-label">{CANVAS_USERS}</div>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>{PRIMARY_LINKAGE_ATTR}</div>
                        <YuJaDropdown
                            containerStyle={{ width: 200 }}
                            data={Object.values(CANVAS_USER_LINKAGE_ATTRIBUTES)}
                            value={selectedUserItem}
                            getOptionLabel={item => item.display}
                            getOptionValue={item => item.value}
                            name="apiUserLinkageAttr"
                            onChange={handleUserLinkageValueChange}
                        ></YuJaDropdown>
                    </div>
                    <Form.Group controlId="fallbackToEmail" style={{margin: "10px 0 0 0", fontSize: "14px"}}>
                        <YuJaCheckbox name="fallbackToEmailLms" checked={getForm().fallbackToEmailLms} label={FALL_BACK_TO_EMAIL_LMS_CANVAS} onClick={callUpdateFunction}/>
                    </Form.Group>
                </Form>
                }
                {selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L && 
                    <Form>
                    <Form.Group>
                        <div>{D2L_CARD_DESC}</div>
                    </Form.Group>
                    <Form.Group>
                        <div className="form-label">{D2L_COURSES}</div>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>{PRIMARY_LINKAGE_ATTR}</div>
                        <YuJaDropdown
                            containerStyle={{ width: 200 }}
                            data={Object.values(D2L_COURSE_LINKAGE_ATTRIBUTES)}
                            value={selectedCourseItem}
                            getOptionLabel={item => item.display}
                            getOptionValue={item => item.value}
                            name="apiCourseLinkageAttr"
                            onChange={handleCourseLinkageValueChange}
                        ></YuJaDropdown>
                    </div>
                    <Form.Group>
                        <div className="form-label">{D2L_USERS}</div>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>{PRIMARY_LINKAGE_ATTR}</div>
                        <YuJaDropdown
                            containerStyle={{ width: 200 }}
                            data={Object.values(D2L_USER_LINKAGE_ATTRIBUTES)}
                            value={selectedUserItem}
                            getOptionLabel={item => item.display}
                            getOptionValue={item => item.value}
                            name="apiUserLinkageAttr"
                            onChange={handleUserLinkageValueChange}
                        ></YuJaDropdown>
                    </div>
                    <Form.Group controlId="fallbackToEmail" style={{margin: "10px 0 0 0", fontSize: "14px"}}>
                        <YuJaCheckbox name="fallbackToEmailLms" checked={getForm().fallbackToEmailLms} label={FALL_BACK_TO_EMAIL_LMS_D2L} onClick={callUpdateFunction}/>
                    </Form.Group>
                </Form>
                }
                    
                </Card.Body>
            </Card>
            <Card className={styles.smallBox}>
                <Card.Header>
                    <img src={syncSettingsIcon} alt={SYNC_SETTINGS} width={"24px"} height={"24px"} />
                    <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{SYNC_SETTINGS}</span>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <FormGroup>
                            <div className="form-label">{COURSE_ID}</div>
                            <YuJaTextBox
                                label={COURSE_ID + INTEG_TEXT_BOX}
                                placeholder={ENTER_COURSE_ID}
                                name="syncCourseId"
                                containerStyle={{ fontSize: 14, backgroundColor: "#FFF" }}
                                onChange={event => setSyncCourseId(event.target.value)}
                            />
                        </FormGroup>
                        <Form.Group style={{ paddingTop: "15px" }}>
                            <YuJaButton id="lmsApiLastElem" onClick={syncUserRosterNow}>{START_SYNC}</YuJaButton>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    </div>
    );
}
