import "./styles.css";
import React, { useEffect } from "react";
import {ReactComponent as NoActivityFeedIcon} from "./images/NO_ACTIVITY_FEED.svg";
import {ReactComponent as NoActivityFeedIconSmall} from "./images/NO_ACTIVITY_FEED_SMALL.svg";
import {ReactComponent as NotificationIcon} from "./images/NOTIFICATION_ICON.svg";
import { Spin } from "../../components/standardization/YuJaLoading";
import { useState } from "react";
import {ReactComponent as DropDown} from "./images/DROPDOWN_ICON.svg";
import {ReactComponent as DropUp} from "./images/DROPUP_ICON.svg";

export default function ViewActivityFeed({data=[], localState}) {
    const [minimized, setMinimized] = useState(false);
    let sharingStates = ["SHARED_ALL", "SHARED", "GET_READY_ALL", "GET_READY"];

    useEffect(() => {
        console.log(localState);
    }, [localState])

    return(<div>
        {minimized && 
            <div className="smallPanelBodyNew" style={{width: "350px"}}>
                <span className="smallPanelTitle">Activity Feed</span>
                <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
            {/*<div className="smallPanelBody" style={{width: "350px"}}>
                <Spin size="medium" wrapperClassName="panelSpin" spinning={false} style={{height: "100%"}}>
                    {(!data.students || !data.students.length || !sharingStates.includes(localState)) && 
                        <div style={{display: "flex", width: "100%"}}>
                            <div style={{display: "flex", flex: 5, alignItems: "center", flexDirection: "column"}}>
                                <NoActivityFeedIconSmall/>
                                <div>No activity found</div>
                            </div>
                            <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
                        </div>
                    }
                    {(!!data.students && !!data.students.length && sharingStates.includes(localState)) && <div style={{width: "100%"}}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <span className="smallPanelTitle">Activity Feed</span>
                            <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
                        </div>
                        <div className="pollCodePanelBody">
                            <div className="responsePanelNumbers" style={{color: "#268726"}}>{data.students.length}</div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <span className="responselegendcircle" style={{backgroundColor: "#268726"}}></span>
                                <div className="responsePanelText">Joined</div>
                            </div>
                        </div>
                    </div>}
                </Spin>
            </div>*/}
            </div>
        }
        {!minimized && 
        <div className="smallPanelBody2" style={{ padding: "25px 0px", width: "350px"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span className="smallPanelTitle" style={{marginLeft: "30px"}}>Activity Feed</span>
                <DropUp style={{cursor: "pointer", marginRight: "30px"}} onClick={() => setMinimized(true)}/>
            </div>
            <span className="smallPanelSubtitle" style={{marginLeft: "30px"}}>View current activity within the poll</span>
            <div className="pollCodePanelBody" style={{ overflowY: "auto"}}>
            <Spin size="large" wrapperClassName="panelSpin" spinning={false} tip="Loading..." style={{height: "100%"}}>
                {(!data.students || !data.students.length || !sharingStates.includes(localState)) && 
                    <>
                        <NoActivityFeedIcon style={{maxWidth: "100%", maxHeight: "100%"}}/>
                        <span>No activity found</span>
                    </>
                }
                {(!!data.students && !!data.students.length && sharingStates.includes(localState)) && <>
                    <div className="activityFeedBody">
                        {data.students.slice(0).reverse().map((element, index) => {
                            return (<div key={index}>
                                {index === 0 && 
                                <div className="firstStudent" id={"ActivityFeedQ"+index}>
                                    <div className="activityFeedTab"></div>
                                    <div style={{display: "flex", flexDirection: "column", flex: 2, marginRight: "5px"}}>
                                        <p className="activityFeedNameText">{decodeURI(element.userName)}</p>
                                        <p className="activityFeedSmallText">Has just joined the poll</p>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#F2F2F2", width: "40px", height: "40px", marginRight: "30px"}}>
                                        <NotificationIcon/>
                                    </div>
                                </div>
                                }
                                {index !== 0 && 
                                    <div className="outerStudent" id={"ActivityFeedQ"+index}>
                                        <div className="activityFeedTab"></div>
                                        <div style={{display: "flex", flexDirection: "column", flex: 2, marginRight: "5px"}}>
                                            <p className="activityFeedNameText">{decodeURI(element.userName)}</p>
                                            <p className="activityFeedSmallText">Has just joined the poll</p>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#F2F2F2", width: "40px", height: "40px", marginRight: "30px"}}>
                                            <NotificationIcon/>
                                        </div>
                                    </div>
                                }
                            </div>);
                        })}
                    </div>
                </>}
            </Spin>
            </div>
        </div>}
    </div>);
}
