import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import DesktopBg from "../../images/get-ready-background-desktop.svg";
import MobileBg from "../../images/get-ready-background.svg";
// import Green from "../../images/green_background.svg";
// import White from "../../images/white_background.svg";
// import Yellow from "../../images/yellow_background.svg";
import "./styles.css";
import {
  POLL_TYPE,
  POLL_STATE,
  SCHEDULE_POLL_OPEN_MESSAGE,
  GET_READY_PAGE_TITLE,
  GET_READY_PAGE_DESCRIPTION,
  GET_READY_PAGE_TIMER_COUNTER_DESCRIPTION,
  GET_READY_PAGE_TIME_LIMIT_1ST_PART,
  GET_READY_PAGE_TIME_LIMIT_2ND_PART,
  GET_READY_LABEL,
  SECONDS_LABEL,
  POLL_TITLE_LABEL,
  TEXTBOX
} from "../../utils/constants";
import useMobileAccess from './../../hooks/useMobileAccess';
import {newPageLog} from "../../utils/helpers";

import {ReactComponent as ScheduleLoading} from "../../images/schedule_loading.svg";
import { useCountdown } from "../../hooks/useCountdown";
import NetworkStatus from "./NetworkStatus";
import waitingRoomBackground from "../../images/waitingRoomBackground.svg";
import waitingRoomBackgroundMobile from "../../images/waitingRoomBackgroundMobile.svg";

const renderTime = ({ remainingTime }) => {
  if (remainingTime <= 0) {
    document.getElementById("root").style.backgroundImage = "none";
  }
  // else if (remainingTime === 1) {
  //   document.getElementById("root").style.backgroundImage =
  //     "url(" + Green + ")";
  // } else if (remainingTime < 5) {
  //   document.getElementById("root").style.backgroundImage =
  //     "url(" + Yellow + ")";
  // } else {
  //   document.getElementById("root").style.backgroundImage =
  //     "url(" + White + ")";
  // }
  // else {
  //   document.getElementById("root").style.backgroundImage = `url(${window.innerWidth < 480 ? waitingRoomBackgroundMobile : waitingRoomBackground})`;
  //   document.getElementById('root').style.backgroundPositionX= `${window.innerWidth < 480 ? "": "-200px"}`; 
  //   document.getElementById('root').style.backgroundPositionY= `${window.innerWidth < 480 ? "": "105px"}`;
  //   document.getElementById('root').style.backgroundRepeat = `${window.innerWidth < 480 ? "": "no-repeat"}`;
  //   document.getElementById('root').style.backgroundColor = "#FCFBFF";
  // }

  // if (remainingTime === 1) {
  //   document.getElementsByClassName("get-ready-all-title")[0].style.color =
  //     "#0A820E";
  // }

  return (
    <div className="timer">
      <div tabIndex={0} role={TEXTBOX} aria-label={GET_READY_LABEL + (remainingTime-1) + SECONDS_LABEL} className="get-ready-timer-value">{remainingTime - 1}</div>
    </div>
  );
};

const renderTimeNew = ({ remainingTime }) => {
  return (
    <div className="timer">
      <div tabIndex={0} role={TEXTBOX} aria-label={GET_READY_LABEL + (remainingTime - 1 >= 0 ? remainingTime - 1 : 0) + SECONDS_LABEL} className="get-ready-timer-value">{remainingTime - 1 >= 0 ? remainingTime - 1 : 0}</div>
    </div>
  );
};

export default function GetReadyAll({poll, handleGetReadyAllComplete, pollType, pollState, pollTitle, websocketState}) {
  const {timeReadyDuration, timeLimit, pollStartTime} = poll;
  // const {timeReadyDuration} = current;
  const isNarrow = useMobileAccess(1200);
  const { getReturnValues, resetCountdown } = useCountdown(new Date(pollStartTime).getTime());
  const [days, hours, minutes, seconds] = getReturnValues; 
  const [showCountDown, setShowCountDown] = useState(false); 

  const [countDownSeconds, setCountDownSeconds ] = useState(null);

  useEffect(() => {
    if(days === 0 && hours === 0 && minutes === 0 && seconds <= 15 && pollState.includes(POLL_STATE.SCHEDULED)) {
      if(countDownSeconds === null) {
        setCountDownSeconds(seconds);
      }
      setShowCountDown(true)
    }
    if (days + hours + minutes + seconds <= 0) {
      handleGetReadyAllComplete();
    }
  }, [seconds]);
  const [keyNo, setKeyNo] = useState(0);

  const convertTime = () => {
    if (pollStartTime === null) {
      return ""; 
    }
    const dateTime = new Date(pollStartTime);
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];
    
    const month = monthNames[dateTime.getMonth()];
    const day = dateTime.getDate();
    const year = dateTime.getFullYear();
    
    let hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const timeZone = dateTime.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];

    return`${month} ${day}, ${year} - ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period} (${timeZone})`;
  }

  useEffect(() => {
    newPageLog("GetReadyAll.js");
    // if(document.getElementById("viewer-navbar") !== null) {
    //   document.getElementById("viewer-navbar").style.backgroundColor = "";
    // }

    // document.body.classList.add("get-ready-background");
    // return () => {
    //   document.body.classList.remove("get-ready-background");
    // };
  }, []);

  let timeLimitMessage = "";
  let tempTimeLimit = timeLimit;

  if (tempTimeLimit >= 3600) {
    if (tempTimeLimit >= 7200) {
      timeLimitMessage += Math.floor(tempTimeLimit / 3600) + " hours";
    } else {
      timeLimitMessage += "1 hour";
    }
    tempTimeLimit = tempTimeLimit % 3600;
    if (tempTimeLimit !== 0) {
      timeLimitMessage += ", ";
    }
  }

  if (tempTimeLimit >= 60) {
    if (tempTimeLimit >= 120) {
      timeLimitMessage +=
        tempTimeLimit % 60 === 0 && timeLimit >= 3600
          ? "and " + Math.floor(tempTimeLimit / 60) + " minutes"
          : Math.floor(tempTimeLimit / 60) + " minutes";
    } else {
      timeLimitMessage +=
        tempTimeLimit % 60 === 0 && timeLimit >= 3600
          ? "and 1 minute"
          : "1 minute";
    }

    tempTimeLimit = tempTimeLimit % 60;
    if (tempTimeLimit !== 0) {
      timeLimitMessage += ", ";
    }
  }

  if (tempTimeLimit >= 1) {
    if (tempTimeLimit >= 2) {
      timeLimitMessage +=
        timeLimit >= 60
          ? "and " + tempTimeLimit + " seconds"
          : tempTimeLimit + " seconds";
    } else {
      timeLimitMessage += timeLimit >= 60 ? "and 1 second" : "1 second";
    }
  }

  useEffect(() => {
    setKeyNo(keyNo + 1);
  }, [timeReadyDuration]);

  useEffect(() => {
    resetCountdown(pollStartTime);
  }, [pollStartTime]);

  return (
    <div className="get-ready-container" style={{ backgroundImage: window.innerWidth < 480 ? `url(${waitingRoomBackgroundMobile})` : `url(${waitingRoomBackground})`, backgroundPositionX: window.innerWidth >= 480 ? "-310px" : "", backgroundPositionY: window.innerWidth >=  480 ? "80px" : "", backgroundColor: "#FCFBFF", backgroundRepeat: "no-repeat" }}>
      {!pollState.includes(POLL_STATE.SCHEDULED) &&
        <>
          <div className="waiting-room-title-container" style={{ height: "fit-content" , marginTop: window.innerWidth < 480 ? 24 : "50px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between"}}>
            <span tabIndex={0} aria-label={POLL_TITLE_LABEL + pollTitle} role={TEXTBOX} className="waiting-room-title" style={{ wordBreak: "break-all", paddingLeft: !isNarrow? "170px" : "48px", textAlign: "center", flex: "1" }}>{pollTitle}</span>
            <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState} /> </div>
          </div>
        <Container className="get-ready-background">
          {/* <Row>
            <Col>
              <span className="get-ready-all-title">{GET_READY_PAGE_TITLE}</span>
            </Col>
          </Row> */}
          <Row style={{marginTop: "40px"}}>
          <Row>
            <Col>
              <div className="get-ready-sub-title" id="title" style={{marginTop: "30px"}}>
                <div tabIndex={0} role={TEXTBOX} >{pollType.toUpperCase() !== POLL_TYPE.SURVEY && `${GET_READY_PAGE_TIME_LIMIT_1ST_PART} ${timeLimitMessage} ${GET_READY_PAGE_TIME_LIMIT_2ND_PART}`}<br/></div>
                <div tabIndex={0} role={TEXTBOX} style={{marginTop: "10px"}}>{GET_READY_PAGE_TIMER_COUNTER_DESCRIPTION}</div>
              </div>
            </Col>
          </Row>
            <Col>
              <div className="timer-wrapper">
                <CountdownCircleTimer
                  size={isNarrow ? 190 : 256}
                  isPlaying
                  key={"countdown-" + keyNo}
                  duration={timeReadyDuration}
                  colors={[
                    "#0277AD",
                    "#0277AD",
                    "#0277AD",
                    "#0277AD",
                    "#0277AD",
                    "#0277AD",
                    "#0277AD",
                    "#0277AD",
                    "#F1B843",
                    "#F1B843",
                    "#F1B843",
                    "#0A820E",
                  ]}
                  colorsTime={[11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]}
                  strokeWidth={20}
                >
                  {renderTime}
                </CountdownCircleTimer>
              </div>
            </Col>
          </Row>
        </Container>
        </>
      }

      {pollState.includes(POLL_STATE.SCHEDULED) &&
          <>
          <div className="waiting-room-title-container" style={{height: "fit-content" , marginTop: window.innerWidth < 480 ? 24 : "50px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between"}}>
            <span tabIndex={0} aria-label={POLL_TITLE_LABEL + pollTitle} role={TEXTBOX} className="waiting-room-title" style={{ wordBreak: "break-all", paddingLeft: !isNarrow? "170px" : "48px", textAlign: "center", flex: "1" }}>{pollTitle}</span>
            <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState} /> </div>
          </div>
            <div>
              <Col className="center" style={{marginTop: "90px"}}>
                <ScheduleLoading/>
              </Col>
            </div>
            {/* <div>
              <Col tabIndex={0} role={TEXTBOX} className="center" style={{fontSize: "40px", fontWeight: "700", color: "#42296E"}}>
                {pollTitle}
              </Col>
            </div> */}
            <div>
              <Col className="center">
                <div tabIndex={0} role={TEXTBOX} className="waiting-room-schedule" style={{marginTop: "10px"}}>
                  {SCHEDULE_POLL_OPEN_MESSAGE + convertTime()}
                </div>
              </Col>
            </div>
            {showCountDown &&
                <Row style={{marginTop: "40px"}}>
                  <Col>
                    <div className="timer-wrapper">
                      <CountdownCircleTimer
                          size={isNarrow ? 190 : 256}
                          isPlaying
                          duration={countDownSeconds}
                          colors={[
                            "#0277AD",
                            "#0277AD",
                            "#0277AD",
                            "#0277AD",
                            "#0277AD",
                            "#0277AD",
                            "#0277AD",
                            "#0277AD",
                            "#F1B843",
                            "#F1B843",
                            "#F1B843",
                            "#0A820E",
                          ]}
                          colorsTime={[11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]}
                          strokeWidth={20}
                      >{renderTimeNew}
                      </CountdownCircleTimer>
                    </div>
                  </Col>
                </Row>
            }
            {/* {!showCountDown &&
              <>
                <div style={{flex: 3}}/>
                <div >
                  <NetworkStatus tooltipPlacement={'top'} websocketState={websocketState}/>
                </div>
                <div style={{flex: 1}}/>
              </>
            } */}
          </>
      }
    </div>
  );
}
