import React, { useCallback, useEffect, useRef, useState } from "react";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import {
    DEFAULT_GET_READY_TIME_LIMIT,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_TIME_LIMIT,
    ERROR_CODES,
    LOG_TYPES, POLL_SHARE_MODE,
    POLL_STATE,
    POLL_TYPE,
    QUIZ_STATE,
    RECORD_ATTEMPT,
    SYNC_ATTEMPT,
    UNLIMITED_ATTEMPTS,
    WAIT_LENGTH,
    WAIT_QUESTION_TEXT
} from "../../utils/constants";
import ClassResult from "./ClassResult";
import ClassResultAll from "./ClassResultAll";
import ClassResultAllSurvey from "./ClassResultAllSurvey";
import Expired from "./Expired";
import GetReady from "./GetReady";
import GetReadyAll from "./GetReadyAll";
import PollReady from "./PollReady";
import PollReadyAll from "./PollReadyAll";
import SAResult from "./SAResult";
import UserCompletePoll from "./UserCompletePoll";
import WaitingRoom from "./WaitingRoom";
import { newPageLog, updateLogger } from "../../utils/helpers";
import ClassResultAllScheduled from "./classResultSchedule";
import EndedPollNew from "./EndedPollNew";

export default function JoinQuizPageQRNew({ param, websocketState, websocketMsg, handleReattempt, setPollClosed, visibility}) {
    const { pollKey = "", userName = "", pollTitle = "", pollDescription = "", uniqueCode = "", showAnswerOnViewer=false,
        questions = [], liveQuestionNo = -1, liveQuestionTime = -1, liveQuestionResponse, pollState = "NONE", pollShareMode,
        pollType = "ANONYMOUS", isTimed = true, pollTimeLimit = DEFAULT_POLL_TIME_LIMIT, updateTimeEpoch = 0, errorCode = "",
        pollQuestionIdx = 0, institutionId = "",pollJoinTime = "", startTime = null, endTime = null, recordAttempt=RECORD_ATTEMPT.RECENT.value, lmsAttempt=SYNC_ATTEMPT.RECENT.value, maxAttempts=UNLIMITED_ATTEMPTS,
        attemptsCount = 1 } = param;
    const pollInfo = { pollKey, uniqueCode, userName, pollTitle, pollDescription, pollType, pollShareMode, showAnswerOnViewer, recordAttempt, lmsAttempt, maxAttempts, attemptsCount };
    // const currentQuestion = useRef({});
    // const currentPoll = useRef({});
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [currentPoll, setCurrentPoll] = useState({});
    const [pollLiveQuestionIdx, setPollLiveQuestionIdx] = useState(pollQuestionIdx);
    const [state, setState] = useState(QUIZ_STATE.LOADING);
    const [error, setError] = useState("");
    const [stopped, setStopped] = useState(false);
    const [stoppedAll, setStoppedAll] = useState(false);
    const [quesShareCount, setQuesShareCount] = useState("");
    const [allSharecount, setAllShareCount] = useState("");
    const [sharedQuestionList, setSharedQuestionList] = useState("");
    const [, getTimeOffset] = useLocalStorageNew("timeOffset", 0);
    const [, getSession] = useLocalStorageNew("session", {});
    const {getSharedQuestionList} = ResponseApiCalls();
    const [allowReattempt, setAllowReattempt] = useState(false);

    // useEffect(() => {
    //   if(state !== QUIZ_STATE.LOADING) {
    //     setJoinLoading(false);
    //   }
    // }, [state]);

    useEffect(() => {
        if (errorCode === ERROR_CODES.INVALID_POLL_CODE || errorCode === ERROR_CODES.EXPIRED_POLL_CODE) {
            setError(errorCode);
            setState(QUIZ_STATE.EXPIRED);
            return;
        }

        if (pollState === POLL_STATE.CLOSED) {
            setState(QUIZ_STATE.CLOSED)
            return;
        }

        setAllowReattempt(!!getSession().userName); // (maxAttempts === UNLIMITED_ATTEMPTS || attemptsCount < maxAttempts);
        if (pollState.includes(POLL_STATE.STOPPED_ALL)) {
            const pollTime = liveQuestionTime + parseInt(getTimeOffset());
            const timeLimit = pollTimeLimit;
            const pollEndPoint = pollTime + timeLimit * 1000;
            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;

            const currentPollObj = {};
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.questions = questions;
            // currentPoll.current = currentPollObj;
            setCurrentPoll(currentPollObj);

            if(pollType === POLL_TYPE.SURVEY){
                setState(QUIZ_STATE.CLASS_RESULT_ALL_SURVEY);
            } else {
                setState(QUIZ_STATE.CLASS_RESULT_ALL);
            }
            return;
        }

        if (pollState.includes(POLL_STATE.SHARED_ALL)) {
            setState(QUIZ_STATE.POLL_TIME_ALL);
            const pollTime = liveQuestionTime + parseInt(getTimeOffset());
            const timeLimit = pollTimeLimit;
            const pollEndPoint = pollTime + timeLimit * 1000;
            const currentTime = Date.now();
            // configure class results later (TBD)
            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;

            const currentPollObj = {};
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.questions = questions;
            currentPollObj.isTimed = isTimed;
            currentPollObj.timeLimit = timeLimit;
            // currentPoll.current = currentPollObj;
            setCurrentPoll(currentPollObj);
            // if (classResultEndTime < currentTime) {
            //   setState(QUIZ_STATE.PENDING);
            //   currentQuestion.current = currentPollObj
            //   return;
            // }

            if (pollType.toUpperCase() === POLL_TYPE.SURVEY) {
                //get ready
                if (currentPollObj.isTimed && currentPollObj.pollStartTime > currentTime) {
                    setState(QUIZ_STATE.GET_READY_ALL);
                    const duration = (currentPollObj.pollStartTime - currentTime) / 1000;
                    currentPollObj.timeReadyDuration = duration;
                    // currentPoll.current = currentPollObj;
                    setCurrentPoll(currentPollObj);
                    return;
                }

                //poll question
                setState(QUIZ_STATE.POLL_TIME_ALL);
                // currentPoll.current = currentPollObj;
                setCurrentPoll(currentPollObj);
                return;
            }

            if (currentPollObj.pollStartTime > currentTime) {
                setState(QUIZ_STATE.GET_READY_ALL);
                const duration = (currentPollObj.pollStartTime - currentTime) / 1000;
                currentPollObj.timeReadyDuration = duration;
                // currentPoll.current = currentPollObj;
                setCurrentPoll(currentPollObj);
                return;
            }

            if (currentPollObj.pollEndTime > currentTime && pollQuestionIdx < questions.length) {
                setState(QUIZ_STATE.POLL_TIME_ALL);
                // currentPoll.current = currentPollObj;
                setCurrentPoll(currentPollObj);
                return;
            }

            setState(QUIZ_STATE.USER_COMPLETE_POLL);
            // currentPoll.current = currentPollObj;
            setCurrentPoll(currentPollObj);
            return;
        }
        if (pollState.includes(POLL_STATE.SCHEDULED)) {
            let pollTime = new Date(startTime).getTime();
            const timeLimit = pollTimeLimit;
            const currentTime = Date.now();
            if (pollJoinTime && new Date(pollJoinTime).getTime() > pollTime) {
                pollTime = new Date(pollJoinTime).getTime();
            }
            const pollEndPoint = Math.min(new Date(pollTime).getTime() + timeLimit * 1000,  new Date(endTime).getTime());



            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;

            const currentPollObj = {};
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollFinishTime = new Date(endTime).getTime();
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.questions = questions;
            currentPollObj.isTimed = isTimed;
            currentPollObj.timeLimit = timeLimit;
            // currentPoll.current = currentPollObj;


            if (currentPollObj.pollStartTime > currentTime) {
                setState(QUIZ_STATE.GET_READY_ALL);
                currentPollObj.timeReadyDuration = (currentPollObj.pollStartTime - currentTime) / 1000;
                // currentPoll.current = currentPollObj;
                setCurrentPoll(currentPollObj);
                return;
            }
            if ( (currentPollObj.pollEndTime > currentTime && pollQuestionIdx < questions.length)) {
                setState(QUIZ_STATE.POLL_TIME_ALL);
                // currentPoll.current = currentPollObj;
                setCurrentPoll(currentPollObj);
                return;
            }

            setState(QUIZ_STATE.USER_COMPLETE_POLL);
            // currentPoll.current = currentPollObj;
            setCurrentPoll(currentPollObj);
            return;
        }




        if (liveQuestionNo <= 0) {
            setState(QUIZ_STATE.WAITING);
            return;
        }

        //share-each
        const curQue = questions[Number(liveQuestionNo) - 1];
        const { timeLimit = DEFAULT_TIME_LIMIT, } = curQue;
        const queEndPoint = pollState === POLL_STATE.LOCKED ? updateTimeEpoch : liveQuestionTime + parseInt(getTimeOffset()) + timeLimit * 1000;
        const classResultEndTime = queEndPoint + (WAIT_LENGTH + 2) * 1000;
        const currentTime = Date.now();
        const currentObj = {}

        currentObj.question = curQue;
        currentObj.questionStartTime = liveQuestionTime + parseInt(getTimeOffset());
        currentObj.questionEndTime = queEndPoint;
        currentObj.classResultEndTime = classResultEndTime;
        currentObj.questionResponse = liveQuestionResponse;

        if (pollType.toUpperCase() === POLL_TYPE.SURVEY) {
            //stopped
            if (pollState === POLL_STATE.STOPPED) {
                setState(QUIZ_STATE.PENDING);
                // currentQuestion.current = currentObj
                setCurrentQuestion(currentObj);
                return;
            }

            //get ready
            if (currentObj.questionStartTime > currentTime) {
                setState(QUIZ_STATE.GET_READY);
                const duration = (currentObj.questionStartTime - currentTime) / 1000;
                currentObj.timeReadyDuration = duration;
                // currentQuestion.current = currentObj;
                setCurrentQuestion(currentObj);
                return;
            }

            const { selectedOption = null} = liveQuestionResponse;

            //unanswered
            if (!selectedOption) {
                setState(QUIZ_STATE.POLL_TIME);
                // currentQuestion.current = currentObj
                setCurrentQuestion(currentObj);
            } else {
                setState(QUIZ_STATE.CLASS_RESULT);
                // currentQuestion.current = currentObj
                setCurrentQuestion(currentObj);
            }
            return;

        }

        if (pollState.includes(POLL_STATE.PAUSED)) {
            setState(QUIZ_STATE.PAUSED);
            const timeLeft = pollState.split('-')[1];
            currentObj.questionEndTime = timeLeft * 1000 + Date.now();
            currentObj.classResultEndTime = timeLeft * 1000 + Date.now() + (WAIT_LENGTH + 2) * 1000;
            // currentQuestion.current = currentObj;
            setCurrentQuestion(currentObj);
            return;
        }

        // if (pollState.includes('RESUMED')) {
        //   setState("RESUMED");
        //   const timeLeft = pollState.split('-')[1];
        //   currentObj.questionEndTime = timeLeft * 1000 + liveQuestionTime;
        //   currentObj.classResultEndTime = timeLeft * 1000 + liveQuestionTime + (WAIT_LENGTH + 2) * 1000;
        //   currentQuestion.current = currentObj;
        //   return;
        // }

        if (classResultEndTime < currentTime) {
            setState(QUIZ_STATE.PENDING);
            // currentQuestion.current = currentObj
            setCurrentQuestion(currentObj);
            return;
        }

        if (currentObj.questionStartTime > currentTime) {
            setState(QUIZ_STATE.GET_READY);
            const duration = (currentObj.questionStartTime - currentTime) / 1000;
            currentObj.timeReadyDuration = duration;
            // currentQuestion.current = currentObj
            setCurrentQuestion(currentObj);
            return;
        }


        if (currentObj.questionEndTime > currentTime && pollState === POLL_STATE.LOCKED) {
            setState(QUIZ_STATE.LOCKED);
            // currentQuestion.current = currentObj;
            setCurrentQuestion(currentObj);
            return;
        }

        if (currentObj.questionEndTime > currentTime) {
            setState(QUIZ_STATE.POLL_TIME);
            // currentQuestion.current = currentObj
            setCurrentQuestion(currentObj);
            return;
        }

        if (currentObj.classResultEndTime > currentTime) {
            setState(QUIZ_STATE.CLASS_RESULT);
            // currentQuestion.current = currentObj
            setCurrentQuestion(currentObj);
        }
    }, [param]);


    useEffect(async () => {
        if (state === QUIZ_STATE.LOADING || pollShareMode !== POLL_SHARE_MODE.SHARE_EACH || document.visibilityState !== "visible") {
            return;
        }
        let shareData = await getSharedQuestionList(pollKey, uniqueCode);
        setQuesShareCount(shareData.sharedQuestionCount + "/" + questions.length);
        setSharedQuestionList(shareData.sharedQuestionList);
        setAllShareCount(shareData.sharedCountList);
    }, [state, visibility]);

    useEffect(() => {
        const {liveQuestionSerialNo, liveQuestionMessage, liveQuestionTime} = websocketMsg;
        if (!websocketMsg || !Object.entries(websocketMsg).length || !liveQuestionMessage || !liveQuestionSerialNo) {
            return;
        }

        const now = new Date();
        updateLogger(LOG_TYPES.INFO + "Websocket received message on JoinQuizPageQR.js");
        updateLogger(LOG_TYPES.INFO + "msg receive time: " + now.toString());
        updateLogger(LOG_TYPES.INFO + "liveQuestionTime: " + liveQuestionTime);
        updateLogger(LOG_TYPES.INFO + "liveQuestionSerialNo: " + liveQuestionSerialNo);
        updateLogger(LOG_TYPES.INFO + "liveQuestionMessage: " + liveQuestionMessage);
        //when poll is closed then join poll
        if (pollState === POLL_STATE.CLOSED) {
            setPollClosed(false);
            return;
        }


        const currentQue = questions[Number(liveQuestionSerialNo) - 1];
        const currentObj = {};
        const currentPollObj = JSON.parse(JSON.stringify(currentPoll));
        currentObj.question = currentQue;

        if (liveQuestionMessage === 'stop') {
            setStopped(true);
            const queTime = liveQuestionTime + parseInt(getTimeOffset());
            const {timeLimit = DEFAULT_TIME_LIMIT} = currentQue;
            const queEndPoint = queTime + timeLimit * 1000;
            const classResultEndTime = queEndPoint + (WAIT_LENGTH + 2) * 1000;
            currentObj.questionStartTime = queTime;
            currentObj.questionEndTime = queEndPoint;
            currentObj.classResultEndTime = classResultEndTime;
            setCurrentQuestion(currentObj);
            if (state !== QUIZ_STATE.POLL_TIME) {
                handleQuestionComplete();
            }
        } else if (liveQuestionMessage === 'stopAll') {
            setStoppedAll(true);
            if (state !== QUIZ_STATE.POLL_TIME_ALL) {
                handlePollComplete();
            }

        } else if (liveQuestionMessage === 'lock') {
            const queEndPoint = liveQuestionTime + parseInt(getTimeOffset());
            const classResultEndTime = queEndPoint + (WAIT_LENGTH + 2) * 1000;
            currentObj.questionEndTime = queEndPoint;
            currentObj.classResultEndTime = classResultEndTime;
            // currentQuestion.current = currentObj;
            setCurrentQuestion(currentObj);
            setState(QUIZ_STATE.LOCKED);
        } else if (liveQuestionMessage === 'share') {
            const queTime = liveQuestionTime + parseInt(getTimeOffset());
            const {timeLimit = DEFAULT_TIME_LIMIT} = currentQue;
            const queEndPoint = queTime + timeLimit * 1000;
            const classResultEndTime = queEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (queTime - Date.now()) / 1000;
            currentObj.questionStartTime = queTime;
            currentObj.questionEndTime = queEndPoint;
            currentObj.classResultEndTime = classResultEndTime;
            currentObj.timeReadyDuration = duration;
            // currentQuestion.current = currentObj;
            setCurrentQuestion(currentObj);
            setState(QUIZ_STATE.GET_READY);
        } else if (liveQuestionMessage.includes('pause')) {
            setState(QUIZ_STATE.PAUSED);
        } else if (liveQuestionMessage.includes('resume')) {
            const {timeLimit = DEFAULT_TIME_LIMIT} = currentQue;
            const queTime = liveQuestionTime + parseInt(getTimeOffset());
            const queEndPoint = queTime + timeLimit * 1000;
            const classResultEndTime = queEndPoint + (WAIT_LENGTH + 2) * 1000;
            currentObj.questionEndTime = queEndPoint;
            currentObj.classResultEndTime = classResultEndTime;
            // currentQuestion.current = currentObj;
            setCurrentQuestion(currentObj);
            setState(QUIZ_STATE.RESUMED);
        } else if (liveQuestionMessage === 'shareAll'){
            const pollTime = liveQuestionTime + parseInt(getTimeOffset());
            currentPollObj.isTimed = true;
            // const timeLimit = liveQuestionMessage.split(':')[1];
            const pollEndPoint = pollTime + pollTimeLimit * 1000;
            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;
            currentPollObj.timeLimit = pollTimeLimit;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.questions = questions;
            // currentPoll.current = currentPollObj;
            setCurrentPoll(currentPollObj);
            setState(QUIZ_STATE.GET_READY_ALL);
        }
        else if (liveQuestionMessage === "updateScheduleTime") {
            let startTime = websocketMsg.startTime;
            let endTime = websocketMsg.endTime;
            let pollTime = new Date(startTime).getTime();
            const timeLimit =  parseInt(websocketMsg.pollTimeLimit, 10);
            if (pollJoinTime && new Date(pollJoinTime).getTime() > pollTime) {
                pollTime = pollJoinTime;
            }
            const pollEndPoint = Math.min(new Date(pollTime).getTime() + timeLimit * 1000,  new Date(endTime).getTime());
            const classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
            let duration = (pollTime - Date.now()) / 1000;
            currentPollObj.rawQueResponses = liveQuestionResponse ? liveQuestionResponse : [];
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.pollStartTime = pollTime;
            currentPollObj.pollFinishTime = new Date(endTime).getTime();
            currentPollObj.pollEndTime = pollEndPoint;
            currentPollObj.classResultEndTime = classResultEndTime;
            currentPollObj.timeReadyDuration = duration;
            currentPollObj.questions = questions;
            currentPollObj.isTimed = isTimed;
            currentPollObj.timeLimit = timeLimit;
            setCurrentPoll(currentPollObj);
        }
    }, [websocketMsg])



    useEffect(() => {
        setPollLiveQuestionIdx(pollQuestionIdx);
    }, [param, pollQuestionIdx]);

    useEffect(() => {
        newPageLog("JoinQuizPageQR.js");
    }, []);

    const handleGetReadyComplete = useCallback(() => {
        console.log("go question page!!!!!!")
        setState(QUIZ_STATE.POLL_TIME);
    }, []);

    const handleGetReadyAllComplete = useCallback(() => {
        setState(QUIZ_STATE.POLL_TIME_ALL);
    }, []);

    const handleQuestionComplete = useCallback(() => {
        setState(QUIZ_STATE.CLASS_RESULT);
        setStopped(false);
    }, []);

    const handleSAQuestionComplete = useCallback(() => {
        setState(QUIZ_STATE.SA_RESULT);
        setStopped(false);
    }, []);

    const handleUserCompletePoll = useCallback(() => {
        setState(QUIZ_STATE.USER_COMPLETE_POLL);
    }, []);

    const handlePollComplete = () => {
        if(pollType === POLL_TYPE.SURVEY){
            setState(QUIZ_STATE.CLASS_RESULT_ALL_SURVEY);
        } else {
            setState(QUIZ_STATE.CLASS_RESULT_ALL);
        }
        setStoppedAll(false);
    };

    const handleClassResultComplete = useCallback(() => {
        setState(QUIZ_STATE.PENDING);
    }, []);

    const handleSAResultComplete = useCallback(() => {
        setState(QUIZ_STATE.PENDING);
    }, []);

    const changeCurQue = (que) => {
        // currentQuestion.current = que;
        setCurrentQuestion(que);
    };

    const changeCurPoll = (poll) => {
        // currentPoll.current = poll;
        setCurrentPoll(poll);
    };

    return (
        <>
            {state === QUIZ_STATE.EXPIRED &&
                <Expired errObj={error} />
            }
            {state === QUIZ_STATE.WAITING &&
                <WaitingRoom pollType={pollType} bodyText={pollDescription} titleText={pollTitle} afterQ={false} websocketState={websocketState}/>
            }
            {state === QUIZ_STATE.PENDING &&
                <WaitingRoom
                    pollType={pollType}
                    allSet={true}
                    bodyText={WAIT_QUESTION_TEXT}
                    bodyShareCount={quesShareCount}
                    titleText={pollTitle}
                    afterQ={true}
                    websocketState={websocketState}
                />
            }
            {state === QUIZ_STATE.GET_READY &&
                <GetReady question={currentQuestion} handleGetReadyComplete={handleGetReadyComplete} pollTitle={pollTitle}  websocketState={websocketState}/>
            }
            {state === QUIZ_STATE.POLL_TIME &&
                <PollReady
                    pollInfo={pollInfo}
                    currentQuestion={currentQuestion}
                    handleQuestionComplete={handleQuestionComplete}
                    handleSAQuestionComplete={handleSAQuestionComplete}
                    state={state}
                    setState={setState}
                    stopped={stopped}
                    institutionId={institutionId}
                    quesShareCount={quesShareCount}
                    allSharecount={allSharecount}
                    changeCurQue={changeCurQue}
                    websocketState={websocketState}
                    totalQuestion={questions.length}
                />
            }
            {state === QUIZ_STATE.POLL_TIME_ALL &&
                <PollReadyAll
                    pollInfo={pollInfo}
                    currentPoll={currentPoll}
                    pollLiveQuestionIdx={pollLiveQuestionIdx}
                    setPollLiveQuestionIdx={setPollLiveQuestionIdx}
                    handleUserCompletePoll={handleUserCompletePoll}
                    handlePollComplete={handlePollComplete}
                    stoppedAll={stoppedAll}
                    state={state}
                    institutionId={institutionId}
                    changeCurPoll={changeCurPoll}
                    websocketState={websocketState}
                />
            }
            {/* {state === QUIZ_STATE.SA_RESULT &&
                <SAResult
                    pollInfo={pollInfo}
                    currentQuestion={currentQuestion}
                    handleSAResultComplete={handleSAResultComplete}
                    institutionId={institutionId}
                    quesShareCount={quesShareCount}
                    websocketState={websocketState}
                />
            } */}
            { (state === QUIZ_STATE.CLASS_RESULT || state === QUIZ_STATE.SA_RESULT) &&
                <ClassResult
                    pollInfo={pollInfo}
                    currentQuestion={currentQuestion}
                    handleSAResult={handleSAQuestionComplete}
                    handleClassResultComplete={handleClassResultComplete}
                    institutionId={institutionId}
                    quesShareCount={quesShareCount}
                    sharedQuestionList={sharedQuestionList}
                    websocketState={websocketState}
                    questions={questions}
                />
            }
            {state === QUIZ_STATE.GET_READY_ALL &&
                <GetReadyAll
                    poll={currentPoll}
                    pollType={pollType}
                    handleGetReadyAllComplete={handleGetReadyAllComplete}
                    pollState={pollState}
                    pollTitle={pollTitle}
                    websocketState={websocketState}
                />
            }
            {state === QUIZ_STATE.USER_COMPLETE_POLL && !pollState.includes(POLL_STATE.SCHEDULED) &&
                <UserCompletePoll
                    handlePollComplete={handlePollComplete}
                    pollEndTime={currentPoll.pollEndTime}
                    allowReattempt={allowReattempt}
                    handleReattempt={handleReattempt}
                    attemptsCount={attemptsCount}
                    maxAttempts={maxAttempts}
                    pollTitle={pollTitle}
                    websocketState={websocketState}
                />
            }
            {state === QUIZ_STATE.CLASS_RESULT_ALL &&
                <ClassResultAll
                    userName={userName}
                    pollInfo={pollInfo}
                    currentPoll={currentPoll}
                    handleClassResultComplete={handleClassResultComplete}
                    websocketState={websocketState}
                />
            }

            {  state === QUIZ_STATE.USER_COMPLETE_POLL &&  pollState.includes(POLL_STATE.SCHEDULED) &&
                <ClassResultAllScheduled
                    handlePollComplete={handlePollComplete}
                    pollEndTime={currentPoll.pollEndTime}
                    allowReattempt={allowReattempt}
                    handleReattempt={handleReattempt}
                    attemptsCount={attemptsCount}
                    maxAttempts={maxAttempts}
                    isComplete={state === QUIZ_STATE.CLASS_RESULT_ALL}
                    websocketState={websocketState}
                    pollTitle={pollTitle}
                />
            }
            {state === QUIZ_STATE.CLASS_RESULT_ALL_SURVEY &&
                <ClassResultAllSurvey
                    currentPoll={currentPoll}
                    institutionId={institutionId}
                    websocketState={websocketState}
                    pollTitle={pollTitle}
                />
            }
            {state === QUIZ_STATE.CLOSED &&
                <EndedPollNew
                    pollTitle={pollTitle}
                    pollType={pollType}
                    websocketState={websocketState}
                />
            }
        </>
    );
}
