import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { UserApiCalls } from "../../apis/UserApiCalls";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ReactComponent as PlusIconHovered } from "../../images/plus-hovered.svg";
import {CREATE_USER, CREATOR_MENU_LIST, DATE_CREATED, DELETE_MULTIPLE_USER_CONFIRMATION, EMAIL, FIRST_NAME, LAST_ACCESS_TIME, LAST_NAME, LINKAGE_TYPE, ROLE, USERNAME, USERS, OPERATION, DEFAULT_ROWS_PER_PAGE} from "../../utils/constants";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import { DELETE_USER, DELETE_USER_DATA_MODAL_BODY, DELETE_USER_MODAL_BODY, MODAL_CANCEL_TEXT, MODAL_DELETE_TEXT } from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import styles from "./ManageUserPage.module.css";
import UserModal from "./UserModal";
import UserSearchForm from "./UserSearchForm";
import { UserTableNew } from "./UserTableNew";
import YuJaButton from "../../components/standardization/YuJaButton";
import CourseSearchForm from "../ManageCoursesPage/CourseSearchForm";
import { MISSING_USER_ERROR } from "../../utils/toast-message-constants";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function ManageUserPage() {
    const {loadUserRoster, saveUser, deleteUser, deleteSelectedUsers, checkUserExist} = UserApiCalls();
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [filter, setFilter] = useState({});
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [hovered, setHovered] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [uid, setUid] = useState(""); // User ID
    const [iid, setIid] = useState(""); // Institution ID
    const [isNew, setIsNew] = useState(true);
    const [dataReady, setDataReady] = useState(false);
    const [showSeleteDeleteModal, setShowSeleteDeleteModal] = useState(false);
    const [user, setUser] = useState({
        userName: "",
        firstName: "",
        lastName: "",
        role: "",
        email: "",
        linkageType: "",
        updatedTime: "",
        lastLoginTime: ""
    });

    useEffect(() => {
        if(document.getElementById("engagePageIdentifier") != null) {
            document.getElementById("engagePageIdentifier").innerText = CREATOR_MENU_LIST[4].name;
        }
    }, []);

    const loadData = async (pageIndex=0) => {
        setDataReady(false);
        const res = await loadUserRoster();
        res.sort(function (a, b) {
            return new Date(Date.parse(b.updateTime)) - new Date(Date.parse(a.updateTime));
        });
        setData(res);
        setPageIndex(pageIndex);
        setDataReady(true);
    };

    const submitUserModal = async (userObj, isNew) => {
        const res = await saveUser(userObj, isNew);
        setShowModal(false);
        if (res) {
            await loadData();
        }
    };

    const clickDelete = async (userId, instId) => {
        // let res;
        setUid(userId);
        if (instId) {
            setIid(instId);
            // if (!window.confirm("Are you sure you want to delete the user and everything associated with this user?")) {
            //     return;
            // }
            // res = await deleteUser(userId, instId); // for lms user
        } else {
            setIid(null);
            // if (!window.confirm("Are you sure you want to delete it?")) {
            //     return;
            // }
            // res = await deleteUser(userId);
        }
        setShowDeleteModal(true);
        // if (res) {
        //     await loadData(pageIndex);
        // }
    };

    const handleDelete = async () => {
        let res;
        // console.log(uid, iid);
        if (iid) {
            res = await deleteUser(uid, iid); // for lms user
        } else {
            res = await deleteUser(uid);
        }
        setShowDeleteModal(false);
        if (res) {
            await loadData(pageIndex);
        }
    };

    const openAddUserModal = useCallback(() => {
        setShowModal(true);
        setIsNew(true);
        setUser({
            userName: "",
            password: "",
            passwordConfirm: "",
            role: "",
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            phone: ""
        });
    }, []);

    const openUpdateUserModal = useCallback((existedUser, pageIndex) => {
        setShowModal(true);
        setIsNew(false);
        setUser(existedUser);
        setPageIndex(pageIndex);
    }, []);

    const closeUserModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const handleDeleteSelected = async () => {
        if(selectedUserIds.length <= 0) {
            notifyError(MISSING_USER_ERROR);
            return false;
        }

        // if (!window.confirm(DELETE_MULTIPLE_USER_CONFIRMATION)) {
        //     return;
        // }

        const res = await deleteSelectedUsers(selectedUserIds);
        if (res) {
            await loadData(pageIndex);
        }
        setShowSeleteDeleteModal(false); 
    };

    const deleteSelectedUserConfig = {
        title: DELETE_USER,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleDeleteSelected();
        },
        close:  () => {
            setShowSeleteDeleteModal(false); 
        }, 
    }

    const deleteUserConfig = {
        title: DELETE_USER,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleDelete();
        },
        close:  () => {
            setShowDeleteModal(false);
        }, 
    };

    const handSelectUserId = (userIds, pageIndex) => {
        setSelectedUserIds(userIds);
        setPageIndex(pageIndex);
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleSubmit = ({ ...filterObj }) => {
        setFilter(filterObj);
        setPageIndex(0);
    };

    const columns = [
        { Header: 'Username', accessor: 'userName', width: 180 },
        { Header: 'First Name', accessor: 'firstName', width: 130 },
        { Header: 'Last Name', accessor: 'lastName', width: 130 },
        { Header: 'Role', accessor: 'role', width: 130 },
        { Header: 'Email', accessor: 'email', width: 300 },
        { Header: 'Linkage Type', accessor: 'linkageType', width: 160 },
        { Header: 'Date Created', accessor: 'createdDate', width: 160 },
        { Header: 'Last Access Time', accessor: 'lastLoginTime', width: 160 },
        { Header: 'Operation', accessor: '', width: 200 }
    ];

    return (<>
            <YuJaGeneralAlert
                show={showSeleteDeleteModal}
                setModalShow={setShowSeleteDeleteModal}
                content={selectedUserIds.length === 1 ? DELETE_USER_DATA_MODAL_BODY : DELETE_MULTIPLE_USER_CONFIRMATION}
                config={deleteSelectedUserConfig}
                AlertType={MODAL_DELETE_TEXT}
            />
            <YuJaGeneralAlert
                show={showDeleteModal}
                setModalShow={setShowDeleteModal}
                content={iid ? DELETE_USER_DATA_MODAL_BODY : DELETE_USER_MODAL_BODY}
                config={deleteUserConfig}
                AlertType={MODAL_DELETE_TEXT}
            />
        {/* <Modal
            id="delete-modal"
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={loading ? "static" : true}
            // dialogClassName="delete-modal"
        >
            <Spin tip="Loading..." size="large" spinning={loading}>
                <ModalClose onClick={() => setShowDeleteModal(false)} className="modal-close" />
                <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Modal.Title>
                        {DELETE_USER}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {iid ? DELETE_USER_DATA_MODAL_BODY : DELETE_USER_MODAL_BODY}
                </Modal.Body>
                <Modal.Footer>
                    <button className="modal-button-cancel" onClick={() => setShowDeleteModal(false)}>
                        {MODAL_CANCEL_TEXT}
                    </button>
                    <button
                        className="modal-button-submit"
                        onClick={handleDelete}
                    >
                        {MODAL_DELETE_TEXT}
                    </button>
                </Modal.Footer>
            </Spin>
        </Modal> */}
        <div className={styles.container}>
            <div className={styles.titleBox}>
                <div className={styles.titleBoxIcon} />
                <div tabIndex={0} className={styles.titleBoxTitle}>{USERS}</div>
            </div>
            {/* <div >
                <Button style={{ marginBottom: "16px" }} title={"Create User"} onClick={openAddUserModal}>Create User</Button>&nbsp;
                <Button style={{ marginBottom: "16px" }} title={"Delete User"} variant="danger"  onClick={handleDeleteSelected}>Delete Users</Button>
            </div> */}
            <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <UserSearchForm handleSubmit={handleSubmit} />
                <YuJaButton onClick={openAddUserModal} style={{marginLeft: 15}}><PlusIcon style={{width: 20}}/>{CREATE_USER}</YuJaButton>
            </div>
            {dataReady && <UserTableNew data={data} columns={columns} pageIdx={pageIndex} globalFilterObj={filter} handleSelectUserId={handSelectUserId} openUpdateUserModal={openUpdateUserModal} clickDelete={clickDelete} handleDeleteSelected={() => {setShowSeleteDeleteModal(true)}}/>}
            {!dataReady && (
                <div className={styles.skeletonTableContainer}>
                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                        <div className={styles.skeletonHeaderContainer}>
                            <div className={styles.skeletonHeaderCell}>{USERNAME}</div>
                            <div className={styles.skeletonHeaderCell}>{FIRST_NAME}</div>
                            <div className={styles.skeletonHeaderCell}>{LAST_NAME}</div>
                            <div className={styles.skeletonHeaderCell}>{ROLE}</div>
                            <div className={styles.skeletonHeaderCell}>{EMAIL}</div>
                            <div className={styles.skeletonHeaderCell}>{LINKAGE_TYPE}</div>
                            <div className={styles.skeletonHeaderCell}>{DATE_CREATED}</div>
                            <div className={styles.skeletonHeaderCell}>{LAST_ACCESS_TIME}</div>
                            <div className={styles.skeletonHeaderCell}>{OPERATION}</div>
                        </div>
                        <div className={styles.skeletonBodyContainer}>
                        {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                            return (<>
                                <Skeleton height={20} width={`${4 + (Math.random())}vw`}className={styles.skeletonBodyCell} />
                                <Skeleton height={20} width={`${3 + (Math.random())}vw`} className={styles.skeletonBodyCell} />
                                <Skeleton height={20} width={`${3 + (Math.random())}vw`} className={styles.skeletonBodyCell} />
                                <Skeleton height={20} width={`${2 + Math.random()}vw`} className={styles.skeletonBodyCell} />
                                <Skeleton height={20} width={`${2 + (Math.random()*3)}vw`} className={styles.skeletonBodyCell} />
                                <Skeleton height={20} width="2vw" className={styles.skeletonBodyCell} />
                                <Skeleton height={20} width="2vw" className={styles.skeletonBodyCell} />
                                <Skeleton height={20} width="2vw" className={styles.skeletonBodyCell} />
                                <Skeleton height={20} width="2vw" className={styles.skeletonBodyCell} />
                            </>)
                        })}
                        </div>
                    </SkeletonTheme>
                </div>
            )}
            <UserModal user={user} checkExisted={checkUserExist} submit={submitUserModal} close={closeUserModal} isShow={showModal} isNew={isNew} />
        </div>
    </>);
}
