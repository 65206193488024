import "./styles.css";
import React from "react";
import {Spin} from "../../components/standardization/YuJaLoading";
import QRCode from "qrcode.react";
import { HOST_URL } from "../../utils/properties";
import {ReactComponent as CopyIcon} from "./images/COPY_ICON.svg"
import {ReactComponent as CopyIconSmall} from "./images/COPY_ICON_SMALL.svg"
import { notifySuccess } from "../../utils/helpers";
import { useState } from "react";
import {ReactComponent as DropDown} from "./images/DROPDOWN_ICON.svg";
import {ReactComponent as DropUp} from "./images/DROPUP_ICON.svg";
import {ReactComponent as DOWNLOAD_SMALL} from "./images/DOWNLOAD_ICON_SMALL.svg";
import { ReactComponent as DOWNLOAD_BIG } from "./images/DOWNLOAD_ICON_BIG.svg";
import CopyToClipboard from 'react-copy-to-clipboard'; 
import { VIEW_POLL_CODE_MINIMIZED_TEXT, VIEW_POLL_CODE_TEXT } from "../../utils/constants";
import { CLIPBOARD_COPY_SUCCESS, RESPONSE_LINK_SUCCESS } from "../../utils/toast-message-constants";
import { UNIQUECODE_TEXT } from "../../utils/constants";

export default function ViewPollCode({data=[], loading=true, initialized=false}) {
    const [minimized, setMinimized] = useState(window.innerHeight > 810 ? false : true);

    const hostname = window.location.hostname;
    let shortName = '';
          if (hostname.includes(HOST_URL) && hostname.includes("www")) {
              shortName = hostname.indexOf('.') >= 0 ?
                  hostname.substring(hostname.indexOf('.') + 1, hostname.indexOf("engage.yuja.com") - 1) :
                  hostname;
          } else {
              shortName = hostname.indexOf('.') >= 0 ?
                  hostname.substring(0, hostname.indexOf('.')) :
                  hostname;
          }
    const INST_URL = shortName + '.' + HOST_URL;

    const [isHovered, setIsHovered] = useState(false);
    const [qrBackground, setqrBackground] = useState("#f6f6f6")

    const handleMouseEnter = () => {
        setIsHovered(true);
        setqrBackground("rgba(0, 0, 0, 0.6)");
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setqrBackground("#f6f6f6");
    };


    const downloadQR = () => {
        const canvas = document.getElementById("1234");
        
        const backgroundColor = "#FFFFFF"; 
        
        const qrCodeWidth = canvas.width;
        const qrCodeHeight = canvas.height;
        
        const padding = 10; 
        
        const newWidth = qrCodeWidth + 2 * padding;
        const newHeight = qrCodeHeight + 2 * padding;
        
        const newCanvas = document.createElement("canvas");
        newCanvas.width = newWidth;
        newCanvas.height = newHeight;
        const newContext = newCanvas.getContext("2d");
        
        newContext.fillStyle = backgroundColor;
        newContext.fillRect(0, 0, newWidth, newHeight);
        
        newContext.drawImage(canvas, padding, padding, qrCodeWidth, qrCodeHeight);

        const pngUrl = newCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${data.index3Pk.substring(3)}.png`;
        
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

    return(<div style={{marginBottom: "15px"}}>
        {minimized &&
            <div className="smallPanelBodyNew" style={{width: "350px"}}>
                <span className="smallPanelTitle">How to Join The Poll?</span>
                <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
            {/*<div className="smallPanelBody" style={{width: "350px"}}>
                <Spin size="medium" wrapperClassName="panelSpin" spinning={loading} style={{height: "100%"}}>
                {initialized && 
                    <div style={{display: "flex"}}>
                        <div style={{position:"relative"}}>
                        <QRCode
                            id = "1234"
                            bgColor={qrBackground}
                            renderAs="canvas"
                            style={{height: "85px", width: "85px", marginRight: "8px",  cursor: "pointer"}}
                            value={INST_URL + "/qr/" + data.index3Pk.substring(3)}
                            onClick={async () => {await setqrBackground("#f6f6f6"); downloadQR();}} 
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            />
                        {isHovered && <DOWNLOAD_SMALL className= "downloadIcon" style = {{width: "48px", height: "48px", cursor: "pointer", left: "22%", top: "22%"}}
                                onClick={async () => {await setqrBackground("#f6f6f6"); downloadQR();}} 
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}> </DOWNLOAD_SMALL>}
                            </div>
                        <div style={{display:"flex", flexDirection: "column"}}>
                            <div className="pollCodePanelTextSmall">
                                {VIEW_POLL_CODE_MINIMIZED_TEXT}
                            </div>
                            <div style={{display:"block"}}>
                                <CopyToClipboard text={data.index3Pk.substring(3)} onCopy={()=> {notifySuccess(CLIPBOARD_COPY_SUCCESS.replace(UNIQUECODE_TEXT, data.index3Pk.substring(3)))}}>
                                <div className="gradientWrap2" style={{width: "85px", cursor: "pointer"}}>
                                    <span className="gradientBtnSmall pollCodePanelSubtitleSmall">{data.index3Pk.substring(3)}  <CopyIconSmall style={{margin: "2px"}}/></span>
                                </div>
                                </CopyToClipboard>
                                <div style={{marginLeft: "5px", display: "inline-block"}}></div>
                                <CopyToClipboard text={`${INST_URL}/qr/${data.index3Pk.substring(3)}`} onCopy={()=> {notifySuccess(RESPONSE_LINK_SUCCESS)}}>
                                <div className="gradientWrap2" style={{width: "85px", cursor: "pointer"}}>
                                    <span className="gradientBtnSmall linkPanelSubtitleSmall">Link<CopyIconSmall style={{margin: "2px 2px 2px 5px"}} /></span>
                                </div>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div>
                            <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
                        </div>
                    </div>
                }
                </Spin>
            </div>*/}
            </div>
        }
        {!minimized && 
        <div className="smallPanelBody2" style={{width: "350px"}}>
            <Spin size="large" wrapperClassName="panelSpin" spinning={false} tip="Loading..." style={{height: "100%"}}>
                {initialized && <div style={{width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <span className="smallPanelTitle">How to Join The Poll?</span>
                        <DropUp style={{cursor: "pointer"}} onClick={() => setMinimized(true)}/>
                    </div>
                    <div style={{maxWidth: "310px", marginTop: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                            <div style={{position:"relative"}}>
                            <QRCode
                                id = "1234"
                                bgColor={qrBackground}
                                renderAs="canvas"
                                style={{marginRight: "5px", cursor: "pointer"}}
                                value={INST_URL + "/qr/" + data.index3Pk.substring(3)}
                                onClick={async () => {await setqrBackground("#f6f6f6"); downloadQR();}} 
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            />
                            {isHovered && <DOWNLOAD_BIG className= "downloadIcon" style = {{width: "64px", height: "64px", cursor: "pointer", left: "25%", top: "25%"}}
                                onClick={async () => {await setqrBackground("#f6f6f6"); downloadQR();}} 
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}> </DOWNLOAD_BIG>}
                            </div> 
                            <div style={{display:"flex", flexDirection: "column", justifyContent: "center"}}>
                                <CopyToClipboard text={data.index3Pk.substring(3)} onCopy={()=> {notifySuccess(CLIPBOARD_COPY_SUCCESS.replace(UNIQUECODE_TEXT, data.index3Pk.substring(3)))}}>
                                <div className="gradientWrap" style={{cursor: "pointer"}}>
                                    <span className="gradientBtn pollCodePanelSubtitle">{data.index3Pk.substring(3)}  <CopyIcon style={{margin: "2px"}}/></span>
                                </div>
                                </CopyToClipboard>
                                <div style={{marginTop: "20px"}}></div>
                                <CopyToClipboard text={`${INST_URL}/qr/${data.index3Pk.substring(3)}`} onCopy={()=> {notifySuccess(RESPONSE_LINK_SUCCESS)}}>
                                <div className="gradientWrap" style={{cursor: "pointer"}}>
                                    <span className="gradientBtn linkPanelSubtitle">Link<CopyIcon style={{margin: "2px 2px 2px 10px"}}/></span>
                                </div>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div className="pollCodePanelText">
                            {VIEW_POLL_CODE_TEXT}
                        </div>
                    </div>
                </div>}
            </Spin>
        </div>}
    </div>);
}
