import { Spin } from "../../components/standardization/YuJaLoading";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import FormModal from "../../components/modals/FormModal";
import NormalPagination from "../../components/pagination/NormalPagination";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
    ACTION_HEADER,
    ACTIVATION_STATUS_HEADER,
    CREATION_ROLES,
    DELETE_CONFIRMATION,
    DEFAULT_ROWS_PER_PAGE,
    DELETE_POLL,
    DELETE_POLL_MODAL_BODY,
    DELETE_SURVEY,
    MODAL_CANCEL_TEXT,
    MODAL_DELETE_TEXT,
    POLL_SORT_KEY,
    POLL_TITLE_CHARACTER_LIMIT,
    POLL_TITLE_HEADER,
    POLL_TYPE,
    POLL_TYPE_ACCESSOR,
    POLL_TYPE_HEADER,
    DATE_HEADER,
    ALL_MODE,
    ALL_STATUS,
    COURSE_STATUS,
    DRAFT_STATUS,
    ACTIVATED_STATUS,
    SCHEDULE_POLL_SHARE_MODE,
    GRADED_MODE,
    GRADED_SHORT,
    EDIT_DATE_ACTION_TEXT,
    SHARE_POLL_BODY,
    SHARE_SURVEY_BODY,
    SHARE_TEXT,
    ROLES,
    TEXTBOX,
    DATE,
    MODE,
    TITLE,
    ACT_STATUS,
    ACT_ITEMS,
    QUES_LABEL
} from "../../utils/constants";
import { isBefore, removePrefix } from "../../utils/helpers";
import { timePollCustomToSeconds } from './../../utils/questionUtils';
import styles from "./ManagePolls.module.css";
import EditDateModal from "../../components/modals/EditDateModal";
import {ReactComponent as Down} from "../../images/links/down.svg";
import {ReactComponent as Up} from "../../images/links/up.svg";
import {ReactComponent as DownGrey} from "../../images/links/down_grey.svg";
import {ReactComponent as UpGrey} from "../../images/links/up_grey.svg";
import {ReactComponent as ReleaseIcon} from "../../images/share.svg";
import {ReactComponent as EditIcon} from "../../images/pencil.svg";
import {ReactComponent as DeleteIcon} from "../../images/trash.svg";
import {ReactComponent as PublishIcon} from "../../images/Publish.svg";
import {ReactComponent as ViewResultIcon} from "../../images/View.svg";
import {ReactComponent as ShareIcon} from "../../images/share-poll.svg";
import {ReactComponent as EditDateIcon} from "../../images/edit-date.svg";
import {ReactComponent as CopyIcon} from "../../images/copyPoll.svg";
import {YuJaKebabMenu} from "../../components/standardization/YuJaKebabMenu";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";

export default React.memo(PollTable);
function PollTable({ data = [], columns = [], pageIdx=0, globalFilterObj = {}, getPollInformation, Datesort, dateSort, Titlesort, Modesort, titleSort, modeSort}) {
    
    const history = useHistory();
    const {loading} = useLoading();
    const [, getSession] = useLocalStorageNew("session", {});
    const { publishPoll, reactivatePoll, sharePollToUsers, softDeleteDraftPoll, softDeletePublishedPoll, copyPollToUser} = PollApiCalls();
    const [courseModalShow, setCourseModalShow] = useState(false);
    const [copyPollModal, setCopyPollModal] = useState(false);
    const [sharePollModal, setSharePollModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [courseOptions, setCourseOptions] = useState([]);
    const [userData, setUserData] = useState([]);
    const [selectedCourseOpts, setSelectedCourseOpts] = useState([]);
    const [selectedMemberOpts, setSelectedMemberOpts] = useState([]);
    const [memberSelectedError, setMemberSelectedError] = useState("");
    const { loadUsers } = UserApiCalls();
    const { getCourseByUser } = CourseApiCalls();
    const [courseData, setCourseData] = useState([]);
    const [pollKey, setPollKey] = useState("");
    const [pollUniqueCode, setPollUniqueCode] = useState("");
    const [pollType, setPollType] = useState("");
    const [ shareAll, setShareAll ] = useState(0);
    const [ pollTimeLimit, setPollTimeLimit ] = useState("00:30:00");

    const [sortSelected, setSortSelected] = useState(0);
    const [dateModalShow, setDateModalShow] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        const filteredStartDate = globalFilterValue.startDate;
        const filteredEndDate = globalFilterValue.endDate;
        const filteredMode = globalFilterValue.mode;
        const filteredStatus = globalFilterValue.status;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.pollTitle && values.pollTitle.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (r.original.courseCode && r.original.courseCode.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        }).filter(r => {
            if (!filteredStartDate && !filteredEndDate) {
                return true;
            }
            if (!filteredStartDate) {
                return isBefore(r.values.updatedDatePK, filteredEndDate);
            }
            if (!filteredEndDate) {
                return isBefore(filteredStartDate, r.values.updatedDatePK);
            }
            const values = r.values;
            return values.updatedDatePK && isBefore(filteredStartDate, r.values.updatedDatePK) && isBefore(r.values.updatedDatePK, filteredEndDate);
        }).filter(r => {
            if(!filteredMode || filteredMode === ALL_MODE) {
                return true;
            }
            const values = r.values;
            if(values.pollType && values.pollType.substring(0,6) === filteredMode.substring(0,6)) {
                return true;
            }
        }).filter(r => {
            if(!filteredStatus || filteredStatus === ALL_STATUS) {
                return true;
            }
            const values = r.values;
            if(filteredStatus === COURSE_STATUS && !!r.original.courseCode) {
                console.log(r.original.coursecode)
                return true;
            }
            if (filteredStatus === DRAFT_STATUS && values.pollSortKey === filteredStatus) {
                return true;
            }
            if(filteredStatus === ACTIVATED_STATUS && values.pollSortKey.startsWith('PUB#UC#') && !r.original.courseCode) {
                return true;
            }
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize},
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: pageIdx, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);


    useEffect(() => {
        if (!pageOptions.length) {
            gotoPage(0);
            return;
        }

        if (pageIdx < pageOptions.length) {
            gotoPage(pageIdx);
        } else {
            gotoPage(pageIdx - 1);
        }

        for (let i = 0; i < pageSize; i++) {
            if (document.getElementById(`menu-${i}`)) {
                document.getElementById(`menu-${i}`).style.display = "inline-block";
            }
            if (document.getElementById(`options-${i}`)) {
                document.getElementById(`options-${i}`).style.display = "none";
            }

        }
    }, [data, pageIdx, pageOptions]);

    const clearForm = () => {
        setCourseModalShow(false);
        setPollTimeLimit("00:30:00");
        setSelectedCourseOpts([]);
        setShareAll(false);
        setStartDate(new Date());
        setEndDate(new Date());
    }

    const copyPollClearForm = () => {
        setCopyPollModal(false);
        setPollTimeLimit("00:30:00");
        setSelectedCourseOpts([]);
        setShareAll(false);
        setStartDate(new Date());
        setEndDate(new Date());
    }

    const sharePollClearForm = () => {
        setMemberSelectedError("");
        setSelectedMemberOpts([]);
        setSharePollModal(false);
    };

    const handlePublishFromModal = async () => {
        let body = {};
        let res;
        body.pollKey = removePrefix(pollKey);
        body.pollType = pollType;
        body.pollShareMode = shareAll === 2 ? "schedule" : shareAll === 1 ? "shareAll" : "shareEach";
        if (startDate) {
            body.startTime = startDate;
        }
        if(endDate) {
            body.endTime = endDate;
        }
        if (selectedCourseOpts.length !== 0) {
            body.courseId = selectedCourseOpts.value;
            body.courseCode = selectedCourseOpts.label.substring(0, selectedCourseOpts.label.indexOf(":")-1);
        }
        if (shareAll && pollType === "Graded Poll") {
            body.pollTimeLimit = timePollCustomToSeconds(pollTimeLimit);
        }
        console.log(body);
        res = await publishPoll(body);
        if (res) {
            const { uniqueCode } = res;
            const trimmedUniqueCode = removePrefix(uniqueCode);
            history.push(`/poll/share/${removePrefix(pollKey)}/${trimmedUniqueCode}`);
        }
    }

    const handleCopyPollToCourse = async () => {
        let key = removePrefix(pollKey);
        let pollCode = pollUniqueCode.substring(7);
        let res;
        if(selectedCourseOpts.length !== 0) {
            let courseIdent = selectedCourseOpts.label.substring(0, selectedCourseOpts.label.indexOf(":")-1);
            if(shareAll === 1 && pollType === "Graded Poll") {
                res = await reactivatePoll({ 'pollKey': key, 'pollCode': pollCode, courseId: selectedCourseOpts.value, courseCode: courseIdent, pollShareMode: "shareAll", 'pollTimeLimit': timePollCustomToSeconds(pollTimeLimit)});
            }
            else if (shareAll === 2 && pollType === "Graded Poll") {
                res = await reactivatePoll({ 'pollKey': key, 'pollCode': pollCode, courseId: selectedCourseOpts.value, courseCode: courseIdent, pollShareMode: "schedule", 'pollTimeLimit': timePollCustomToSeconds(pollTimeLimit), startTime: startDate, endTime: endDate});
            }
            else {
                res = await reactivatePoll({ 'pollKey': key, 'pollCode': pollCode, courseId: selectedCourseOpts.value, courseCode: courseIdent, pollShareMode: (!shareAll ? "shareEach" : "shareAll")});
            }
        } else {
            if(shareAll === 1 && pollType === "Graded Poll") {
                res = await reactivatePoll({ 'pollKey': key, 'pollCode': pollCode, pollShareMode: "shareAll", 'pollTimeLimit': timePollCustomToSeconds(pollTimeLimit)});
            }
            else if (shareAll === 2 && pollType === "Graded Poll") {
                res = await reactivatePoll({ 'pollKey': key, 'pollCode': pollCode, pollShareMode: "schedule", 'pollTimeLimit': timePollCustomToSeconds(pollTimeLimit), startTime: startDate, endTime: endDate});
            }
            else {
                res = await reactivatePoll({ 'pollKey': key, 'pollCode': pollCode, pollShareMode: (!shareAll ? "shareEach" : "shareAll")});
            }
        }
        if (res) {
            const { pollKey, uniqueCode } = res;
            const trimmedUniqueCode = removePrefix(uniqueCode);
            history.push(`/poll/share/${pollKey}/${trimmedUniqueCode}`);
        }
    };

    const modalConfig = {
        title: `Activate ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"}`,
        submitButtonText: "Activate",
        clearForm: clearForm,
        submit: handlePublishFromModal,
        activatePollType: pollType,

        form: [
            {
                label: `Would you like to activate the ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "survey" : "poll"} to a course?`,
                subOptions: ["Activate without a course", "Activate within a course"],
                value: selectedCourseOpts,
                options: courseOptions,
                isMulti: false,
                setFunction: setSelectedCourseOpts,
                type: "select2",
            },
            {
                label: `How would you like to release the ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "survey" : "poll"}?`,
                options: ["Activate Each Question Individually", `Activate the Entire ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"}`],
                isMulti: false,
                type: "select3"
            },
        ],
    };

    const copyPollModalConfig = {
        title: `Reactivate ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"}`,
        submitButtonText: "Reactivate",
        clearForm: copyPollClearForm,
        submit: handleCopyPollToCourse,
        activatePollType: pollType,

        form: [
            {
                label: `Would you like to copy this ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "survey" : "poll"} to a course?`,
                subOptions: ["Reactivate without a course", "Reactivate within a course"],
                value: selectedCourseOpts,
                options: courseOptions,
                isMulti: false,
                setFunction: setSelectedCourseOpts,
                type: "select2",
            },
            {
                label: `How would you like to release the ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "survey" : "poll"}?`,
                options: ["Activate Each Question Individually", `Activate the Entire ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"}`],
                isMulti: false,
                type: "select3"
            },
        ],
    };

    const sharePoll = async () => {
        let key = removePrefix(pollKey);
        let pollCode = null;
        if(pollUniqueCode !== "Draft") {
            pollCode = pollUniqueCode.substring(7);
        }
        if(selectedMemberOpts.length === 0) {
            setMemberSelectedError("Please select at least 1 member!");
        } else {
            setMemberSelectedError("");
            if(pollCode !== null) {
                await sharePollToUsers({'pollKey': key, 'pollType': pollType, 'pollCode': pollCode, 'users': selectedMemberOpts.map(user => user.value)});
            }
            else {
                await sharePollToUsers({'pollKey': key, 'pollType': pollType, 'users': selectedMemberOpts.map(user => user.value)});
            }
            sharePollClearForm();
        }
    };

    const sharePollModalConfig = {
        title: `Share ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"}`,
        subText: pollType.toUpperCase() === POLL_TYPE.SURVEY ? SHARE_SURVEY_BODY : SHARE_POLL_BODY,
        submitButtonText: SHARE_TEXT,
        clearForm: sharePollClearForm,
        submit: sharePoll,

        form: [
            {
                label: "Members",
                value: selectedMemberOpts,
                options: userData,
                isMulti: true,
                setFunction: setSelectedMemberOpts,
                type: "select4",
                error: memberSelectedError
            },
        ],
    };
    
    const getCourses = async () => {
        const session = getSession();
        let res;
        if(session.idpType && session.intType) {
            res = await getCourseByUser(session.idpType.toLowerCase(), session.intType.toLowerCase());
        } else {
            res = await getCourseByUser();
        }
        const courseOptions = res.filter(course => course.courseRole && course.courseRole !== ROLES.VIEWER.value);
        setCourseData(courseOptions);

        let res2 = await loadUsers(true);
        res2 = res2.filter(user => user.userId !== getSession().userId && (user.role === CREATION_ROLES.CREATOR.value || user.role === CREATION_ROLES.IT_MANAGER.value));
        const options = res2.map(user => ({value: user.userId, label: `${user.userName} (${user.email})`}));
        setUserData(options);
        // const res = await getCourseByUser();
        // setCourseData(res);
    };
    
    useEffect(() => {
        getCourses();
    }, []);
    
    useEffect(() => {
        const options = courseData.map(course => ({value: course.courseId, label: `${course.courseCode} : ${course.courseName}`}));
        setCourseOptions(options);
    }, [courseData]);

    const onGo = async (pollKey) => {
        setPollKey(pollKey);
        setCourseModalShow(true);

        // let path = '/poll/' + key + "/" + removePrefix(res.uniqueCode);
        // history.push({ pathname: path });
    }

    const onGoCopy = async (pollKey, uniqueCode) => {
        setPollKey(pollKey);
        setPollUniqueCode(uniqueCode);
        setCopyPollModal(true);
    }

    const onGoShare = async (pollKey, uniqueCode) => {
        setPollKey(pollKey);
        setPollUniqueCode(uniqueCode);
        setSharePollModal(true);
    };

    const onView = (pollKey, uniqueCode) => {
        let key = removePrefix(pollKey);
        let pollCode = uniqueCode.substring(7);
        history.push(`/poll/published/${key}/${pollCode}`);
    }

    const onDeleteDraft = (pollKey, pollType) => {
        // let key = removePrefix(pollKey);
        // console.log("DELETE DRAFT POLL");
        // if (!window.confirm("Are you sure you want to delete it?")) {
        //     return;
        // }
        setPollKey(pollKey);
        setPollType(pollType);
        setPollUniqueCode(null);
        setShowDeleteModal(true);
        // const res = await softDeleteDraftPoll(key, pollType);
        // if (res.success) {
        //     getPollInformation(pageIndex);
        // }
    }

    const onDeletePublished = (pollKey, uniqueCode, pollType) => {
        console.log(pollKey, uniqueCode, pollType);
        // let key = removePrefix(pollKey);
        // console.log("DELETE PUBLISHED POLL");
        // if (!window.confirm("Are you sure you want to delete it?")) {
        //     return;
        // }
        setPollKey(pollKey);
        setPollUniqueCode(uniqueCode);
        setPollType(pollType);
        setShowDeleteModal(true);
        // let pollCode = uniqueCode.substring(7);
        // const res = await softDeletePublishedPoll(key, pollCode, pollType);
        // if (res.success) {
        //     getPollInformation(pageIndex);
        // }
    }

    const handleSort = (column) => {
        if (column.render('Header')===DATE_HEADER) {
            Datesort();
            setSortSelected(0);
        }
        else if(column.render('Header')=== POLL_TITLE_HEADER) {
            Titlesort();
            setSortSelected(1);
        }
        else if (column.render('Header')=== POLL_TYPE_HEADER){
            Modesort();
            setSortSelected(2);
        }
    };

    const handleDelete = async () => {
        console.log(pollKey, pollUniqueCode, pollType);
        let key = removePrefix(pollKey);
        if (pollUniqueCode) { // delete published
            console.log("DELETE PUBLISHED POLL");
            let pollCode = pollUniqueCode.substring(7);
            const res = await softDeletePublishedPoll(key, pollCode, pollType);
            if (res.success) {
                getPollInformation(pageIndex);
            }
        } else { // delete draft
            console.log("DELETE DRAFT POLL");
            const res = await softDeleteDraftPoll(key, pollType);
            if (res.success) {
                getPollInformation(pageIndex);
            }
        }
    };

    const onEdit = (pollKey) => {
        console.log("EDIT POLL")
        console.log(pollKey)
        history.push({ pathname: "/edit/" + removePrefix(pollKey) });
    }

    const handleClick = (index) => {
        document.getElementById(`menu-${index}`).style.display = "none";
        document.getElementById(`options-${index}`).style.display = "inline";
    }

    const onPublishedShare = (pollKey, uniqueCode) => {
        let key = removePrefix(pollKey);
        let pollCode = uniqueCode.substring(7);
        history.push(`/poll/share/${key}/${pollCode}`);
    };

    const deletePollConfig = {
        title: pollType.toUpperCase() === POLL_TYPE.SURVEY ? DELETE_SURVEY : DELETE_POLL,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: () => {
            handleDelete();
        },
        close:  () => {
            setShowDeleteModal(false);
        }, 
    };

    return (
        <>
            {
                dateModalShow && <EditDateModal show={dateModalShow} setModalShow={setDateModalShow} pollKey={pollKey} pollUniqueCode={pollUniqueCode}/>
            }
            {courseModalShow && <FormModal
              show={courseModalShow}
              setModalShow={setCourseModalShow}
              modalConfig={modalConfig}
              pollTimeLimit={pollTimeLimit}
              setPollTimeLimit={setPollTimeLimit}
              setShareAll={setShareAll}
              shareAll={shareAll}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />}
            {copyPollModal && <FormModal
              show={copyPollModal}
              setModalShow={setCopyPollModal}
              modalConfig={copyPollModalConfig}
              pollTimeLimit={pollTimeLimit}
              setPollTimeLimit={setPollTimeLimit}
              setShareAll={setShareAll}
              shareAll={shareAll}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />}
            {sharePollModal && <FormModal
              show={sharePollModal}
              setModalShow={setSharePollModal}
              modalConfig={sharePollModalConfig}
            />}
            <YuJaGeneralAlert 
                show={showDeleteModal}
                setModalShow={setShowDeleteModal}
                content={DELETE_POLL_MODAL_BODY}
                config={deletePollConfig}
                AlertType={MODAL_DELETE_TEXT}/>
            {/* <Modal
				id="delete-modal"
				show={showDeleteModal}
				onHide={() => setShowDeleteModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				// dialogClassName="delete-modal"
            >
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={() => setShowDeleteModal(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0}>
                            {pollType.toUpperCase() === POLL_TYPE.SURVEY ? DELETE_SURVEY : DELETE_POLL}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
                        <div tabIndex={0}>{DELETE_POLL_MODAL_BODY}</div>
					</Modal.Body>
                    <Modal.Footer>
						<button tabIndex={0} className="modal-button-cancel" onClick={() => setShowDeleteModal(false)}>
							{MODAL_CANCEL_TEXT}
						</button>
						<button tabIndex={0}
							className="modal-button-submit"
							onClick={handleDelete}
						>
							{MODAL_DELETE_TEXT}
						</button>
					</Modal.Footer>
				</Spin>
			</Modal> */}
            <div className={styles.tableContainer}>
                <Table  hover {...getTableProps()} >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth,
                                        }
                                    })}>
                                        <span tabIndex={0} role={TEXTBOX}>{column.render('Header')}</span>
                                        {(column.render('Header') === DATE_HEADER && dateSort === 0 && sortSelected === 0) || (column.render('Header') === POLL_TITLE_HEADER&& titleSort === 1  && sortSelected === 1) || (column.render('Header') === POLL_TYPE_HEADER && modeSort === 1 && sortSelected === 2) ?
                                        <Down tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "descending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => {handleSort(column)}}/>
                                        : (column.render('Header') === DATE_HEADER && dateSort === 1 && sortSelected === 0) || (column.render('Header') === POLL_TITLE_HEADER && titleSort === -1 && sortSelected === 1) || (column.render('Header') === POLL_TYPE_HEADER && modeSort === -1 && sortSelected === 2) ?
                                        <Up tabIndex={0} role={TEXTBOX} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => {handleSort(column)}}/>
                                        : (column.render('Header') === DATE_HEADER && dateSort === 0) || (column.render('Header') === POLL_TITLE_HEADER&& titleSort === 1) || (column.render('Header') === POLL_TYPE_HEADER && modeSort === 1 ) ?
                                        <DownGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer",  marginLeft: "10px"}} title="descending" onClick={() => {handleSort(column)}}/>
                                        : (column.render('Header') === DATE_HEADER && dateSort === 1) || (column.render('Header') === POLL_TITLE_HEADER&& titleSort === -1) || (column.render('Header') === POLL_TYPE_HEADER && modeSort === -1) ?
                                        <UpGrey role={TEXTBOX} tabIndex={0} aria-label={column.render('Header') + "ascending order"} style={{width: "15px", cursor: "pointer", marginLeft: "10px"}} title="ascending" onClick={() => {handleSort(column)}}/> : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        if (cell.column.Header === ACTION_HEADER) {
                                            return (
                                                <td {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                    <div tabIndex={0}  role={TEXTBOX} aria-label={ACT_ITEMS + row.cells.at(index-4).value} style={{display: "flex", justifyContent: "center"}}>
                                                        <YuJaKebabMenu >
                                                            <YuJaKebabMenu.Menu>
                                                                {cell.row.values[POLL_SORT_KEY] === 'Draft' ?
                                                                    <YuJaKebabMenu.Item onClick={() => onEdit(cell.value)}>
                                                                        <EditIcon style={{width: 18, marginRight: 5}}/>Edit
                                                                    </YuJaKebabMenu.Item>
                                                                     : <YuJaKebabMenu.Item onClick={() => onPublishedShare(cell.value, cell.row.values[POLL_SORT_KEY])}>
                                                                        <ReleaseIcon style={{width: 18, marginRight: 5}}/>Release
                                                                     </YuJaKebabMenu.Item>
                                                                }
                                                                 {cell.row.values[POLL_SORT_KEY] === 'Draft' &&
                                                                    <YuJaKebabMenu.Item
                                                                        onClick={() => {
                                                                            setPollType(cell.row.values.pollType);
                                                                            onGo(cell.value);
                                                                        }}
                                                                    >
                                                                        <PublishIcon style={{width: 18, marginRight: 5}}/>Activate
                                                                    </YuJaKebabMenu.Item>
                                                                }



                                                                {cell.row.values[POLL_SORT_KEY] !== 'Draft' &&

                                                                    <YuJaKebabMenu.Item
                                                                        onClick={() => {
                                                                            setPollType(cell.row.values.pollType);
                                                                            onGoCopy(cell.value, cell.row.values[POLL_SORT_KEY]);
                                                                        }}
                                                                    >
                                                                        <PublishIcon style={{width: 18, marginRight: 5}}/>Reactivate
                                                                    </YuJaKebabMenu.Item>
                                                                }
                                                                <YuJaKebabMenu.Item
                                                                    onClick={() => {
                                                                        setPollType(cell.row.values.pollType);
                                                                        onGoShare(cell.value, cell.row.values[POLL_SORT_KEY]);
                                                                    }}
                                                                >
                                                                    <ShareIcon style={{width: 18, marginRight: 5}}/> Share
                                                                </YuJaKebabMenu.Item> 
                                                                {cell.row.values[POLL_SORT_KEY] !== 'Draft' &&
                                                                    <YuJaKebabMenu.Item onClick={() => onView(cell.value, cell.row.values[POLL_SORT_KEY])}>
                                                                        <ViewResultIcon style={{width: 18, marginRight: 5}}/>Results
                                                                    </YuJaKebabMenu.Item>
                                                                }
                                                                {cell.row.original && cell.row.original.pollShareMode && cell.row.original.pollShareMode === SCHEDULE_POLL_SHARE_MODE &&
                                                                <YuJaKebabMenu.Item
                                                                onClick={() => {
                                                                    setPollKey(cell.value);
                                                                    setPollUniqueCode(cell.row.values[POLL_SORT_KEY]);
                                                                    setDateModalShow(true);
                                                                }}
                                                                >
                                                                <EditDateIcon style={{width: 18, marginRight: 5}}/>{EDIT_DATE_ACTION_TEXT}
                                                                </YuJaKebabMenu.Item>
                                                                }
                                                                <YuJaKebabMenu.Item
                                                                    onClick={() => {
                                                                        cell.row.values[POLL_SORT_KEY] === 'Draft' ?
                                                                        onDeleteDraft(cell.value, cell.row.values[POLL_TYPE_ACCESSOR]) :
                                                                        onDeletePublished(cell.value, cell.row.values[POLL_SORT_KEY], cell.row.values[POLL_TYPE_ACCESSOR])
                                                                    }}
                                                                >
                                                                    <DeleteIcon style={{width: 18, marginRight: 5}}/> {MODAL_DELETE_TEXT}
                                                                </YuJaKebabMenu.Item>

                                                                <YuJaKebabMenu.Item
                                                                        onClick={async () => {
                                                                            if (cell.row.values[POLL_SORT_KEY] === 'Draft') {
                                                                                const res = await copyPollToUser({'pollKey': removePrefix(cell.value), 'pollType': cell.row.values[POLL_TYPE_ACCESSOR]});
                                                                                if (res) {
                                                                                    getPollInformation(0);
                                                                                }
                                                                            }
                                                                            else {
                                                                                const res = await copyPollToUser({'pollKey': removePrefix(cell.value), 'pollType': cell.row.values[POLL_TYPE_ACCESSOR], 'pollCode': cell.row.values[POLL_SORT_KEY].substring(7)});
                                                                                if (res) {
                                                                                    getPollInformation(0);
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        <CopyIcon style={{width: 18, marginRight: 5}}/> Duplicate
                                                                    </YuJaKebabMenu.Item>
                                                            </YuJaKebabMenu.Menu>
                                                        </YuJaKebabMenu>
                                                    </div>
                                                </td>
                                            );
                                        } else if (cell.column.Header === ACTIVATION_STATUS_HEADER) {
                                            return (

                                                // Uncomment below lins to show poll code
                                                // <td {...cell.getCellProps()}>
                                                //      {cell.value.startsWith('PUB#UC#') ? cell.value.replace('PUB#UC#', '') : cell.value}
                                                //  </td>
                                                <td role={TEXTBOX} aria-label={ACT_STATUS + row.cells.at(index-3).value + (cell.value.startsWith('PUB#UC#') ? "Activated" : cell.value)} tabIndex={0} {...cell.getCellProps()}>
                                                    {(cell.value.startsWith('PUB#UC#') ? "Activated" : cell.value)}
                                                </td>

                                            );
                                        } else if (cell.column.Header === POLL_TITLE_HEADER) {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX} aria-label={TITLE + cell.value}
                                                    title={cell.value.length > POLL_TITLE_CHARACTER_LIMIT ? cell.value : ""}
                                                    {...cell.getCellProps()}
                                                >
                                                    <div style={{width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}> {cell.value.length > POLL_TITLE_CHARACTER_LIMIT ?
                                                        cell.value.substring(0, POLL_TITLE_CHARACTER_LIMIT) + "..." :
                                                        cell.value
                                                    }</div>
                                                </td>

                                            );
                                        } else if (cell.column.Header === POLL_TYPE_HEADER) {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX} aria-label={MODE + cell.value + " for " + row.cells.at(index=1).value} {...cell.getCellProps()}>
                                                    {cell.value && cell.value.substring(0,6) === GRADED_SHORT ? GRADED_MODE : cell.value}
                                                </td>

                                            );
                                        } else {
                                            return (
                                                <td tabIndex={0} role={TEXTBOX} aria-label={cell.column.Header === "Date" ? (DATE + cell.value): (QUES_LABEL + row.cells.at(index-2).value + " " + cell.value)} {...cell.getCellProps()}>
                                                    {cell.value}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }) : <tr><td colSpan="6" className={styles.noData}>No Results Found</td></tr>}
                    </tbody>
                </Table>

                <NormalPagination
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                />
            </div>
        </>
    );
}