import React from "react";
import { TEXTBOX } from "../../utils/constants";

export default function OptionButton({
  label,
  value,
  textDisplay,
  valueToEqual,
  compareValue,
  // valueToNotEqual,
  handleClick,
  isCustom = false,
}) {
  return (
    <div
      onClick={() => handleClick(value)}
      className="option-button cursor-pointer"
      tabIndex={0}
      role={TEXTBOX}
      aria-label={(textDisplay && textDisplay.includes("Secs")) ? textDisplay.slice(0, textDisplay.indexOf("S")) + "Seconds" : textDisplay}
      style={{
        padding: isCustom && "0px",
        background: compareValue(value, valueToEqual) ? "#42296E" : "#FFFFFF",
      }}
    >
      <div
        className="option-button-text"
        style={{
          color: compareValue(value, valueToEqual) ? "#FFFFFF" : "#000000",
        }}
      >
        {textDisplay}
      </div>
    </div>
  );
}
