import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import { Spin } from "../../components/standardization/YuJaLoading";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { ReactComponent as EditIcon } from "../../images/pencil.svg";
import { useLoading } from "../../utils/LoadingContext";
import { BUTTON, CLEAR_ALL_TEXT, GO_BACK_TEXT, GRADED_POLL, QUESTION_BANK_MODAL, QUESTION_PROFILE_IMPORT_EDIT, QUESTION_PROFILE_IMPORT_MESSAGE, QUESTION_PROFILE_MODAL_MESSAGE, QUESTION_PROFILE_MODAL_SUB_TITLE, QUESTION_PROFILE_MODAL_TEXT, QUESTION_PROFILE_MODAL_TITLE, QUESTION_TYPES, QUES_BANK_CHECKBOX, QUES_BANK_CLOSE, SELECT_ALL_TEXT, SURVEY, TEXTBOX } from "../../utils/constants";
import { notifyError, notifySuccess } from "../../utils/helpers";
import { IMPORT_QUESTION_BANK_ERROR, IMPORT_QUESTION_BANK_SUCCESS, IMPORT_QUESTION_ERROR } from "../../utils/toast-message-constants";
import YuJaButton from "../standardization/YuJaButton";
import { YuJaCheckbox } from "../standardization/YuJaCheckbox";
import QuestionBankSearch from "./QuestionBankSearch";
import QuestionProfileTable from "./QuestionProfileTable";

export default function QuestionProfileModal({setModalShow, show, setQuestions, questions, pollType, pollKey}) {
    const [questionProfileData, setQuestionProfileData] = useState([]);
    const { duplicateBankImage } = PollApiCalls();
    const { getQuestionProfile, deleteQuestionProfile} = UserApiCalls(); 
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [selectedProfileName, setSelectedProfileName] = useState(null);
    const [checkedValues, setCheckedValues] = useState(new Map());
    // const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentQuestions", []);
    const [selectedQuestionProfile, setSelectedQuestionProfile] = useState(""); 
    const [isImporting, setIsImporting] = useState(false);  
    const history = useHistory();
    const { loading } = useLoading();
    const [collapsed, setCollapsed] = useState(new Map()); 
    const [data, setData] = useState([]);
    const [questionTitleLimit, setQuestionTitleLimit] = useState(60);

    const [filter, setFilter] = useState({});

    const handleClose = () =>{
        handleSearch({ keyword: ""});
        setIsImporting(false);
        setCheckedValues(new Map());
        setModalShow(false); 
    };

    const selectAllProfile = () => {
        const updatedMap = new Map(checkedValues);
        if (selectedProfile) {
            if(!updatedMap.get(selectedProfileId)) {
                updatedMap.set(selectedProfileId, []); 
            }
          Array.from(selectedProfile.values()).map((value, index) => {
            if (!updatedMap.get(selectedProfileId).includes(index)) {
                if ((pollType === SURVEY && value.questionType !== QUESTION_TYPES.MH.name) || (pollType === GRADED_POLL && value.questionType !== QUESTION_TYPES.WC.name)) {
                    updatedMap.get(selectedProfileId).push(index);
                }
            }
          });
        }
        setCheckedValues(updatedMap);
    };

    const clearAllProfile = () => {
        const updatedMap = new Map(checkedValues);
        if (selectedProfile) {
            if(!updatedMap.get(selectedProfileId)) {
                updatedMap.set(selectedProfileId, []); 
            }
          Array.from(selectedProfile.values()).map((value, index) => {
            if (updatedMap.get(selectedProfileId).includes(index)) {
                const pos = updatedMap.get(selectedProfileId).indexOf(index);
                updatedMap.get(selectedProfileId).splice(pos, 1);
            }
          });
        }
        setCheckedValues(updatedMap);
    };

    const handleSubmit = async () => {
        try {
            let curr_questions = [];
            questions.forEach((item) => {
                curr_questions.push(item);
            });
            // var deleted_question = null; 
            checkedValues.forEach((list, key) => {
                const questionlist = questionProfileData.get(key).questions;
                const bankQuestions = [];
                list.forEach((item) => {
                    // if(curr_questions[curr_questions.length - 1].queTitle == "") {
                    //      deleted_question = curr_questions[curr_questions.length - 1]
                    //  }
                    const modifiedQuestion = { ...questionlist[item] };
                    if (!modifiedQuestion.hasOwnProperty("queTitle")) {
                        modifiedQuestion.queTitle = "";
                    }
                    if (pollType === GRADED_POLL && modifiedQuestion.hasOwnProperty("timeLimit") && modifiedQuestion.timeLimit === 0) {
                        modifiedQuestion.timeLimit = 15;
                    }
                    // modifiedQuestion.questionProfileId = key; 
                    modifiedQuestion.serialNo = curr_questions.length + 1;
                    curr_questions.push(modifiedQuestion);
                    bankQuestions.push(modifiedQuestion);
                });
                let inputbody = {
                    questions: bankQuestions,
                    questionBankId: key,
                    pollKey: pollKey,
                }
                duplicateBankImage(inputbody);
            });
            if (questions.length === curr_questions.length) {
                notifyError(IMPORT_QUESTION_ERROR)
                return;
            }
            setQuestions(curr_questions);
            // setRecentQuestions(curr_questions);  
            // if (deleted_question != null) {
            //     const newQuestions = deleteQuestion(deleted_question, curr_questions); 
            //     setQuestions(newQuestions); 
            // }
            setIsImporting(false);
            setCheckedValues(new Map());
            handleSearch({ keyword: "" });
            notifySuccess(IMPORT_QUESTION_BANK_SUCCESS);
            setModalShow(false);
        } catch (error) {
            notifyError(IMPORT_QUESTION_BANK_ERROR);
        }
    }

    const handleCheckboxChange = (index) => {
        const updatedMap = new Map(checkedValues);

        if(!updatedMap.get(selectedProfileId)) {
            updatedMap.set(selectedProfileId, []); 
        }
      
        if(updatedMap.get(selectedProfileId).includes(index)) {
            const pos = updatedMap.get(selectedProfileId).indexOf(index);
            updatedMap.get(selectedProfileId).splice(pos, 1);
        }
        else {
            updatedMap.get(selectedProfileId).push(index);
          }
        setCheckedValues(updatedMap);
    }

    const handleKeyClick = (value, index) => {
        const keyArray = Array.from(questionProfileData.keys());
        setSelectedProfile(value.questions);
        setSelectedProfileId(keyArray[index]); 
        setSelectedProfileName(value.ProfileTitle);
        console.log(value.questions)
    };

    const getQuestionProfiles = async () => {
        const res = await getQuestionProfile();
        const sortedArray = Object.entries(res).sort((a, b) => new Date(Date.parse(b[1].creationTime)) - new Date(Date.parse(a[1].creationTime)));
        const sortedMap = new Map(sortedArray);  
        setQuestionProfileData(sortedMap); 
        console.log(sortedMap)
    };

    useEffect(() => {
        if(show === true) {
            getQuestionProfiles(); 
            setIsImporting(false);
            setCheckedValues(new Map());
            setSelectedProfile(null); 
            setSelectedProfileId(null);
            setSelectedProfileName(null);
            setSelectedQuestionProfile("");
            setCollapsed(false);
        }
    }, [show]);

    useEffect(() => {
        const collapsedMap = new Map(); 
        Array.from(questionProfileData.keys()).map((value, index) => {
            collapsedMap.set(value, false)
          });
        setCollapsed(collapsedMap); 
    }, [questionProfileData])

    useEffect(() => {
        if(selectedProfileId && !checkedValues.has(selectedProfileId)) {
            setCheckedValues((prevCheckedQuestions) => {
                const updatedMap = new Map(prevCheckedQuestions);
                updatedMap.set(selectedProfileId, []); 
                return updatedMap; 
            }); 
        }
    }, [selectedProfileId])

    const columns = [
        { Header: 'Question Bank Name', accessor: 'ProfileTitle', width:  200 },
        { Header: 'Questions', accessor: 'questions', width: 100 },
        { Header: 'Action', accessor: '', width: 100, id: 'action' },
      ];

    const onEdit = (questionProfileId) => {
        setModalShow(false);
        history.push({ pathname: "/questions/edit/" + questionProfileId });
    }

    useEffect(() => {
        const updateConstantValue = () => {
          const windowWidth = window.innerWidth;
          if (windowWidth <= 395) {
            setQuestionTitleLimit(20);
          }
          else if (windowWidth <= 495 ) {
            setQuestionTitleLimit(30);
          }
          else if (windowWidth <= 1500) {
            setQuestionTitleLimit(40);
          } else if (windowWidth <= 1800) {
            setQuestionTitleLimit(50);
          } else {
            setQuestionTitleLimit(60);
          }
        };
    
        // Call the function once to set the initial value
        updateConstantValue();
    
        // Add an event listener to handle window resize
        window.addEventListener('resize', updateConstantValue);
    
        // Clean up the event listener when the component is unmounted
        return () => {
          window.removeEventListener('resize', updateConstantValue);
        };
      }, []);

    const handleSearch = useCallback(({ ...filterObj }) => {
        setFilter(filterObj);
      }, []);
    
    return (
        <Modal
            id="form-modal"
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
            dialogClassName= {"qp-modal"}
        >
            <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
            <ModalClose tabIndex={0} role={BUTTON} aria-label={QUES_BANK_CLOSE} onClick={handleClose} className="modal-close" style={{width: "24px", height: "24px"}}/>
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title tabIndex={0} role={TEXTBOX} aria-label={QUESTION_BANK_MODAL} className="modal-title" id="contained-modal-title-vcenter">
                    {QUESTION_PROFILE_MODAL_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {!isImporting ?
            <>
            <div>
            <div tabIndex={0} role={TEXTBOX} style={{fontSize:"16px", lineHeight: "16px", fontWeight: "bold", fontFamily: "Geomanist", color: "#000000"}}> {QUESTION_PROFILE_MODAL_SUB_TITLE} </div>
            <div tabIndex={0} role={TEXTBOX} style={{fontSize: "14px", lineHeight: "14px", marginTop: "5px",  fontFamily: "Geomanist", color: "#696969"}}> {QUESTION_PROFILE_MODAL_MESSAGE} </div>
            {/* <div className="questionProfile-layout">
            {Array.from(questionProfileData.values()).map((value, index) => (
                // <div style = {{overflowWrap: "break-word"}} className={selectedProfile != value.questions ? "QuestionProfile-value": "QuestionProfile-value-selected"} onClick={() => handleKeyClick(value, index)}>{value.ProfileTitle}</div>
                <>
                    <div className="questionProfile-cards" onClick={() => {setIsImporting(true); handleKeyClick(value, index);}}>
                        <div style={{flex: '80%', overflowWrap: "break-word", width: "80%"}}>{value.ProfileTitle.length > 30 ? `${value.ProfileTitle.slice(0, 30)}...` :value.ProfileTitle} </div>  
                        <div style={{flex: '20%', display: "flex", flexDirection: "row-reverse", height: "100%", alignSelf: "flex-start", zIndex: "100"}}> <div style={{display: "flex", flexDirection: "column"}}> 
                        {!collapsed || !collapsed.get(Array.from(questionProfileData.keys())[index]) ? <FcMenu
                                onClick={(e) => {
                                    const updatedMap = new Map(collapsed);
                                    const collapse = collapsed.get(Array.from(questionProfileData.keys())[index])
                                    updatedMap.set(Array.from(questionProfileData.keys())[index], !collapse); 
                                    setCollapsed(updatedMap);
                                    e.stopPropagation();
                                }}
                                size={24}
                            /> :
                        
                        <>
                        <img
                            src={EditIcon}
                            alt="edit icon"
                            style={{margin: "0px", padding: "0px", cursor: "pointer",width: "24px", height: "24px",  zIndex: "100"}}
                            title={"Edit Question"}
                            onClick={(e) => { onEdit(Array.from(questionProfileData.keys())[index]); e.stopPropagation();}}
                        />
                        <img
                            src={DeleteIcon}
                            alt="delete icon"
                            style={{ marginRight: "2px", padding: "0px", cursor: "pointer", width: "24px", height: "24px",  zIndex: "100"}}
                            title={"Delete Question"}
                            onClick={(e) => {onDelete(Array.from(questionProfileData.keys())[index]); e.stopPropagation();}}
                        /> </> } 
                        </div></div> 
                    </div>       
                </>
                ))}
            </div>  */}
             <QuestionBankSearch handleSubmit={handleSearch}/>
             <QuestionProfileTable columns={columns} data={Array.from(questionProfileData.values())} getQuestionProfiles={getQuestionProfiles} keys={Array.from(questionProfileData.keys())} selectedQuestionProfile={selectedQuestionProfile} setSelectedQuestionProfile={setSelectedQuestionProfile} setIsImporting={setIsImporting} setModalShow={setModalShow} handleKeyClick={handleKeyClick} globalFilterObj={filter}/>
            </div> 
            </>
            : <div style={{padding: "10px 0px 0px 10px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}> 
                <div> 
                <div tabIndex={0} role={TEXTBOX} style={{fontSize:"16px", lineHeight: "16px", fontWeight: "bold", fontFamily: "Geomanist", color: "#000000"}} title={selectedProfileName?.length > 30 ? selectedProfileName : ""}> 
                    {selectedProfileName?.length > 30 ? `${selectedProfileName.slice(0, 30)}...` : selectedProfileName}
                </div> 
                <div tabIndex={0} role={TEXTBOX} style={{fontSize: "14px", lineHeight: "14px", marginTop: "5px",  fontFamily: "Geomanist", color: "#696969"}}> {QUESTION_PROFILE_IMPORT_MESSAGE} </div>
                </div>
                <YuJaButton style={{fontSize: 14, padding: 5}}  onClick={(e) => { onEdit(selectedProfileId); e.stopPropagation();}}>
                    <EditIcon style={{width: 20, height: 20}}/>{QUESTION_PROFILE_IMPORT_EDIT}
                </YuJaButton>
                </div> 
            {/* <div> 
            {Array.from(questionProfileData.values()).map((value, index) => (
                // <div style = {{overflowWrap: "break-word"}} className={selectedProfile != value.questions ? "QuestionProfile-value": "QuestionProfile-value-selected"} onClick={() => handleKeyClick(value, index)}>{value.ProfileTitle}</div>
                <>
                    <div className={selectedProfile != value.questions ? "questionProfile-cards": "questionProfile-cards-activated"} onClick={() => handleKeyClick(value, index)}>
                        <div style={{flex: '100%', overflowWrap: "break-word", width: "80%"}}>{value.ProfileTitle.length > 30 ? `${value.ProfileTitle.slice(0, 30)}...` :value.ProfileTitle} </div>  
                    </div>       
                </>
                ))}
            </div> */}
            <div style={{flex: '7 1 70%', maxHeight: "70vh", minHeight: "20vh", marginTop: "20px", overflowY: "auto"}}>
                <div style={{display: "flex", gap: "20px"}}> 
                <YuJaButton onClick={() => {selectAllProfile();}}> {SELECT_ALL_TEXT} </YuJaButton>
                <YuJaButton  onClick={() => {clearAllProfile();}} style= {{paddingLeft: "10px"}}> {CLEAR_ALL_TEXT} </YuJaButton>
                </div>
            <div style={{marginTop: "10px", borderBottom: "0.73px solid #8A8A8A"}}> 
            {selectedProfile &&
                <div>
                    {Array.from(selectedProfile.values()).map((value, index) => (
                        <div style={{display: "flex", gap: "10px", alignItems:"center", width: "100%", border: "0.73px solid #8A8A8A", borderBottom: "0px", height: "33px", paddingLeft: "10px"}}>
                            <YuJaCheckbox
                                size={18}
                                id={selectedProfileId + index}
                                ariaLabel={QUES_BANK_CHECKBOX + value.queTitle + " " + (checkedValues.has(selectedProfileId) && checkedValues.get(selectedProfileId).includes(index) ? "checked" : "unchecked")}
                                checked={checkedValues.has(selectedProfileId) && checkedValues.get(selectedProfileId).includes(index)}
                                onClick={() => { handleCheckboxChange(index)}}
                                disabled={(pollType === GRADED_POLL && value.questionType === QUESTION_TYPES.WC.name) || (pollType === SURVEY && value.questionType === QUESTION_TYPES.MH.name)}
                            />
                            <div style={{display: "block", width: "100%"}}>
                                <div title={value.queTitle?.length > questionTitleLimit ? value.queTitle : "" }>
                                    {(!value.queTitle || value.queTitle === "") ? "untitled question": (value.queTitle?.length > questionTitleLimit) ?  `${value.queTitle.slice(0, questionTitleLimit)}...` :value.queTitle}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
            </div>
            <div style={{display:"flex"}}> 
            </div> 
            </div>
            </div>
             }
            </Modal.Body>
            <Modal.Footer>
                {!isImporting? 
                    <YuJaButton aria-label={QUES_BANK_CLOSE} onClick={handleClose} type={"secondary"}>Cancel</YuJaButton>
                    :
                    <>
                        <YuJaButton type={"secondary"} onClick={() => {setIsImporting(false)}}>
                         {GO_BACK_TEXT}
                        </YuJaButton>
                        <YuJaButton onClick={handleSubmit}>{QUESTION_PROFILE_MODAL_TEXT}</YuJaButton>
                    </>
                }
            </Modal.Footer>
            </Spin>
        </Modal>
    );
}