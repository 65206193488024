import "bootstrap/dist/css/bootstrap.css";
import { Spin } from "../../components/standardization/YuJaLoading";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal} from "react-bootstrap";
import "./styles.css";
import { useLoading } from "../../utils/LoadingContext";
import Countdown from 'react-countdown';
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import {
  MAX_ATTEMPTS_REACHED,
  NO_TEXT,
  REATTEMPT_MODAL_BODY,
  REATTEMPT_MODAL_TITLE,
  RETRY_TEXT,
  UNLIMITED_ATTEMPTS,
  UNLIMITED_ATTEMPTS_TEXT,
  USER_COMPLETE_POLL_SUBTITLE,
  USER_COMPLETE_POLL_TITLE,
  YES_TEXT,
  TEXTBOX,
} from "../../utils/constants";
import { getReattemptText } from "../../utils/helpers";
import { useCountdown } from "../../hooks/useCountdown";

import {ReactComponent as ScheduleDone} from "../../images/schedule_done.svg";
import {useWindowDimensions} from "react-native-web";
import NetworkStatus from "./NetworkStatus";
import YuJaButton from "../../components/standardization/YuJaButton";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function ClassResultAllScheduled({pollTitle, handlePollComplete, pollEndTime, handleReattempt, allowReattempt, attemptsCount, maxAttempts, isComplete, websocketState}) {
  const isMobile = useMobileAccess(480);
  const isNarrow = useMobileAccess(1200);
  const { loading, height } = useLoading();
  const {width} = useWindowDimensions();
  const [showReattemptModal, setShowReattemptModal] = useState(false);
  const [canReattempt, setCanReattempt] = useState(false); 

  const { getReturnValues, resetCountdown } = useCountdown(pollEndTime, false); 
  const [days, hours, minutes, seconds] = getReturnValues; 


  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
        handlePollComplete();
    }
  }, [seconds]);

  useEffect(() => {
    resetCountdown(pollEndTime);
  }, [pollEndTime]);


  useEffect(() => {
    if (document.getElementById("viewer-navbar") !== null) {
      document.getElementById("viewer-navbar").style.backgroundColor = "";
    }
    console.log(attemptsCount, maxAttempts);
    setCanReattempt(maxAttempts === UNLIMITED_ATTEMPTS || attemptsCount < maxAttempts);

    document.body.classList.add("waiting-room-background");
    return () => {
      document.body.classList.remove("waiting-room-background");
    };
  }, []); 

  const onClickReattempt = () => {
    setShowReattemptModal(true);
  };

  const handleClose = () => {
    setShowReattemptModal(false);
  };

    return ( 
      <div  style={{...(width > 1200 ? {} : {paddingTop: 0.05 * height}), ...{display: "flex", flexDirection: "column", alignItems: "center", flex: 1}, }}>
        <div className="waiting-room-title-container" style={{ height: "fit-content" , marginTop: "32px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px" , justifyContent: "space-between"}}>
                    <span role={TEXTBOX} tabIndex={0} className="waiting-room-title" style={{ wordBreak: "break-word", paddingLeft: !isNarrow? "170px" : "48px", textAlign: "center", flex: "1"}}>{pollTitle}</span>
                    <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState} /> </div>
                </div>
        <Container>
          <Modal
            id="reattempt-modal"
            show={showReattemptModal}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={loading ? "static" : true}
            dialogClassName="sa-modal"
          >
            <Spin tip="Loading..." size="large" spinning={loading}>
              <ModalClose onClick={handleClose} className="modal-close" />
              <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Modal.Title>
                  {REATTEMPT_MODAL_TITLE}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {REATTEMPT_MODAL_BODY}
              </Modal.Body>
              <Modal.Footer>
                <button className="modal-button-cancel" onClick={handleClose}>
                  {NO_TEXT}
                </button>
                <button className="modal-button-submit" onClick={handleReattempt}>
                  {YES_TEXT}
                </button>
              </Modal.Footer>
            </Spin>
          </Modal>
        <Row>
          <Col className="center" style={{marginTop: "90px"}}>
            <ScheduleDone />
          </Col>
        </Row>
        <Row style={{marginTop: "15px"}}>
            <Col className="center">
              <span className="waiting-room-title" style={{ color: "#42296E"}}>
                {USER_COMPLETE_POLL_TITLE}
              </span>
            </Col>
          </Row>
          <Row style={{marginTop: "10px"}}>
            <Col className="center">
              <span className="waiting-room-bottom">
                {USER_COMPLETE_POLL_SUBTITLE}
              </span>
            </Col>
          </Row>
      </Container>

        <div style={{flex: 1}}/>
        {/* <div style={{marginBottom: 20}}>
          <NetworkStatus websocketState={websocketState}/>
        </div> */}

        {allowReattempt && !isComplete &&
            <Row className="no-padding center" style={{marginBottom: 70, width: "100%"}}>
               <Col className="no-padding center" style={{display: "flex", justifyContent: "center", gap: "20px", alignItems: "center"}}>
                <span className="reattempt-text">
                  {canReattempt ?
                      (maxAttempts === UNLIMITED_ATTEMPTS ?
                          UNLIMITED_ATTEMPTS_TEXT :
                          getReattemptText(attemptsCount, maxAttempts)) :
                      MAX_ATTEMPTS_REACHED
                  }
                </span>
                {canReattempt &&
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <YuJaButton onClick={onClickReattempt} style={{ width: isNarrow ? "60px" : "90px", borderRadius: "4.205px", border: "1.682px solid #42296E", fontSize: isNarrow ? "18px" : "24px", fontFamily: 'Geomanist', display: "flex", justifyContent: "center"}}>{RETRY_TEXT}</YuJaButton>
                  </div>
                }
              </Col>
            </Row>
        }
    </div>
    ) 
}