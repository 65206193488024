import React from "react";
import { MdOutlineCancel, MdOutlineCheckCircle } from "react-icons/md";
import "./styles.css";

export default function TableAudienceResultRow({ response }) {
  const { userName, answersMap, resultPercentage } = response;
  const responseIconStyle = {
    margin: "3px",
    bottom: "10%",
    position: "relative",
    float: "left",
  };

  return (
    <tr>
      <td>{userName}</td>
      <td className="border-right">{resultPercentage}%</td>

      {answersMap.map((answer, idx) => {
        if (answer.isCorrect) {
          return (
            <td key={idx}>
              <div className="student-response correct">
                <MdOutlineCheckCircle
                  size={27}
                  style={{ ...responseIconStyle, color: "#219025" }}
                />
                <div className="student-response-text">
                  {answer.selectedOption}
                </div>
              </div>
            </td>
          );
        } else {
          return (
            <td key={idx}>
              <div className="student-response wrong">
                <MdOutlineCancel
                  size={27}
                  style={{ ...responseIconStyle, color: "#d35f5e" }}
                />
                <div className="student-response-text">
                  {answer.selectedOption}
                </div>
              </div>
            </td>
          );
        }
      })}
    </tr>
  );
}
