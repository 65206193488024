import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { usePagination, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import ArrowIcon from "../../images/arrow_right.svg";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import CIImageAnswerViewer from "../../questions/CI/CIImageAnswerViewer";
import { ASCII_CODE_A, BUTTON, CLOSE_MODAL, DEFAULT_ROWS_PER_PAGE, FITB_BLANK, FITB_BLANK_LIMIT, FITB_NARRATOR, FIXED_ANSWER, NUMBER_LABEL, POLL_TYPE, QUESTION_LABEL, QUESTION_TYPES, SA_ANSWER_LENGTH, STATUS_LABEL, TEXTBOX, TYPE_LABEL, UNANSWERED, VIEW_ANSWER, WC_DISPLAY_LIMIT, YOUR_ANS_LABEL } from "../../utils/constants";
import { fixFITB } from "../../utils/helpers";
import styles from "./ViewTakenPollsPage.module.css";
import YuJaButton from "../../components/standardization/YuJaButton";


export default React.memo(TakenPollTable);
function TakenPollTable({ institutionId, selectedPoll = {}, columns = [], setShowAnswerModal, setModalData }) {
    const [modalShow, setModalShow] = useState(false);
    const [question, setQuestion] = useState({});
    const { questions=[], pollKey, synced=true, pollType} = selectedPoll;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable({
        columns,
        data: questions,
        initialState: { pageIndex: 0, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
    }, usePagination);

    const showQuestion = async (serialNo) => {
        const no = parseInt(serialNo);
        const selectedQuestion = questions.find(q => q.serialNo === no);
        setQuestion(selectedQuestion);
        setModalShow(true);
    }

    return (
    <>
        <Modal
            id="question-modal"
            show={modalShow}
            onHide={() => setModalShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
            dialogClassName="question-modal2"
        >
            <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={() => setModalShow(false)} className="modal-close" />
            <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Modal.Title tabIndex={0} role={TEXTBOX}>
                    {`Q${question.serialNo} --- Your Answer`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CIImageAnswerViewer
                    institutionId={institutionId}
                    pollKey={pollKey}
                    serialNo={question.serialNo}
                    questionType={question.questionType}
                    suffix={question.suffix}
                    image={question.image}
                    selectedOption={question.selectedOption}
                />
            </Modal.Body>
        </Modal>
        <div className={styles.tableContainer}>
            <Table responsive hover {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                if (column.Header === 'Status' && pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
                                return (
                                <th tabIndex={0} {...column.getHeaderProps({
                                    style: {
                                        width: column.width,
                                        minWidth: column.minWidth
                                    }
                                })}>
                                    {column.render('Header')}
                                </th>);
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    if (cell.column.Header === 'Number') {
                                        return (
                                            <td aria-label={NUMBER_LABEL + cell.row.original.serialNo} tabIndex={0} {...cell.getCellProps()}>
                                                Q{cell.row.original.serialNo}
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Status') {
                                        if (pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
                                        let display;
                                        const serialNo = cell.row.original.serialNo;
                                        const currQues = questions[serialNo - 1];
                                        const {isCorrect, isGraded, questionType} = currQues;
                                        if (questionType === QUESTION_TYPES.SA.name) {
                                            if (isCorrect) { // isCorrect and isGraded have default value as false
                                                display = "Correct";
                                            } else if (isGraded) {
                                                display = "Incorrect";
                                            } else if ((!cell.row.original.hasOwnProperty("selectedOption") || cell.row.original.selectedOption === FIXED_ANSWER.UNANSWERED)) {
                                                display = "Unanswered";
                                            } else {
                                                display = "Not Graded";
                                            }
                                        } else {
                                            if (isCorrect) {
                                                display = "Correct";
                                            } else if ((!cell.row.original.hasOwnProperty("selectedOption") || cell.row.original.selectedOption === FIXED_ANSWER.UNANSWERED)) {
                                                display = "Unanswered";
                                            } else {
                                                display = "Incorrect";
                                            }
                                        }
                                        return (
                                            <td aria-label={STATUS_LABEL + display} tabIndex={0} {...cell.getCellProps()}>
                                                {display}
                                            </td>
                                        );
                                    }

                                    if (cell.column.Header === 'Question') {
                                        if (cell.row.original.questionType === QUESTION_TYPES.FITB.name) {
                                            let display = fixFITB(cell.value);
                                            return (
                                                <td aria-label={QUESTION_LABEL + display.replaceAll(FITB_BLANK, FITB_NARRATOR)} tabIndex={0} {...cell.getCellProps()} style={{wordBreak: 'break-all'}}>
                                                    {display}
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td aria-label={QUESTION_LABEL + cell.value} tabIndex={0} {...cell.getCellProps()} style={{wordBreak: 'break-all'}}>
                                                    {cell.value}
                                                </td>
                                            );
                                        }
                                    }

                                    if (cell.column.Header === 'Type') {
                                        for (let type in QUESTION_TYPES) {
                                            if (QUESTION_TYPES[type].name === cell.value) {
                                                return (
                                                    <td aria-label={TYPE_LABEL + QUESTION_TYPES[type].description} tabIndex={0} {...cell.getCellProps()}>
                                                        {QUESTION_TYPES[type].description}
                                                    </td>
                                                );
                                            }
                                        };
                                    }

                                    if (cell.column.Header === 'Your Answer') {
                                        if (!cell.row.original.hasOwnProperty("selectedOption") || cell.row.original.selectedOption === FIXED_ANSWER.UNANSWERED) {
                                            return (
                                                <td aria-label={YOUR_ANS_LABEL + pollType.toUpperCase() === POLL_TYPE.SURVEY ? 'Unanswered' : 'NA'} tabIndex={0} {...cell.getCellProps()}>{pollType.toUpperCase() === POLL_TYPE.SURVEY ? 'Unanswered' : '---'}</td>
                                            );
                                        }
                                        let display = "---";
                                        var array;
                                        switch (cell.row.original.questionType) {
                                            case QUESTION_TYPES.MCSS.name:
                                                if (pollType.toUpperCase() === POLL_TYPE.SURVEY) {
                                                    display = cell.row.original.selectedOption.sort((a, b) => a.localeCompare(b)).map(ch => {return <>{ch.toUpperCase()}</>});
                                                } else {
                                                    display = cell.row.original.selectedOption.toUpperCase();
                                                }
                                                break;
                                            case QUESTION_TYPES.TF.name:
                                                display = cell.row.original.selectedOption.charAt(0).toUpperCase() + cell.row.original.selectedOption.slice(1);
                                                break;
                                            case QUESTION_TYPES.SA.name:
                                                display = cell.row.original.selectedOption["0"];
                                                if (display.length > SA_ANSWER_LENGTH) {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {/* {display.slice(0, SA_ANSWER_LENGTH)} */}
                                                            <YuJaButton
                                                                onClick={() => {
                                                                    setModalData(display);
                                                                    setShowAnswerModal(true);
                                                                }}
                                                                style={{margin: "auto"}}
                                                            >
                                                                View Answer
                                                            </YuJaButton>
                                                        </td>
                                                    );
                                                }
                                                break;
                                            case QUESTION_TYPES.FITB.name:
                                                array = Object.values(cell.row.original.selectedOption);
                                                if (array.length > FITB_BLANK_LIMIT) {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {/* {array.slice(0, FITB_BLANK_LIMIT).map((key, index) => { return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-all' }}>Blank {index + 1}: {key}</p>); })} */}
                                                            <YuJaButton
                                                                onClick={() => {
                                                                    setModalData(array.map((key, index) => {return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-all' }}>Blank {index + 1}: {key}</p>)}));
                                                                    setShowAnswerModal(true);
                                                                }}
                                                                style={{margin: "auto"}}
                                                            >
                                                                View Answer
                                                            </YuJaButton>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td tabIndex={0} {...cell.getCellProps()}>
                                                        {array.map((key, index) => { return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-all' }}>Blank {index + 1}: {key}</p>); })}
                                                    </td>
                                                );
                                            case QUESTION_TYPES.CI.name:
                                                display = cell.row.original.selectedOption.selected;
                                                return (
                                                    <td tabIndex={0} aria-label={YOUR_ANS_LABEL + isNaN(display) ? display : String.fromCharCode(ASCII_CODE_A + parseInt(display))} className={styles.clickableAnswer} {...cell.getCellProps()} onClick={() => showQuestion(cell.row.original.serialNo)}>
                                                        {isNaN(display) ? display : String.fromCharCode(ASCII_CODE_A + parseInt(display))}
                                                    </td>
                                                );
                                            case QUESTION_TYPES.WC.name:
                                                array = Object.values(cell.row.original.selectedOption).filter(key => key !== "");
                                                if (array.length > WC_DISPLAY_LIMIT) { // display only first 3 words; or limit by characters?
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {/* {array.slice(0, FITB_BLANK_LIMIT).map((key, index) => { return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-all' }}>Blank {index + 1}: {key}</p>); })} */}
                                                            <div
                                                                className={styles.viewAnswerBtn}
                                                                tabIndex={0}
                                                                style={{margin: "auto"}}
                                                                onClick={() => {
                                                                    setModalData(array.map((key, index) => {
                                                                        if (key === "") return <></>;
                                                                        return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-all' }}>
                                                                            Entry {index+1}: {key}
                                                                        </p>)
                                                                    }));
                                                                    setShowAnswerModal(true);
                                                                }}
                                                            >
                                                                View Answer
                                                            </div>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td tabIndex={0} {...cell.getCellProps()}>
                                                        {array.map((key, index) => {
                                                            return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-all' }}>
                                                                {key}
                                                            </p>)    
                                                        })}
                                                    </td>
                                                );
                                            case QUESTION_TYPES.MH.name: 
                                                array = Object.keys(cell.row.original.selectedOption); 
                                                let values = cell.row.original.selectedOption; 
                                                let options = cell.row.original.optionsMap.filter(sublist => sublist.length === 2);
                                                console.log(options); 
                                                console.log(cell);
                                                console.log(selectedPoll);
                                                return (
                                                    <td tabIndex={0} {...cell.getCellProps()}>
                                                        {/* {array.slice(0, FITB_BLANK_LIMIT).map((key, index) => { return (<p key={index} style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-all' }}>Blank {index + 1}: {key}</p>); })} */}
                                                        <div
                                                            className={styles.viewAnswerBtn}
                                                            onClick={() => {
                                                                setModalData(options.map((key, index) => {
                                                                    if (key === "") return <></>;
                                                                    return (<div style={{ display: "flex", width: "100%", gap: "10px", alignItems: "center", justifyContent: "center"}}> <p className="questionPanelAnswersText" id={"OptionPart1" + index} style={{ marginRight: "0px", border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1, marginBottom: "0px", marginTop: "10px"}}>
                                                                        {key[0]}
                                                                    </p>
                                                                        <img
                                                                            src={ArrowIcon}
                                                                            alt="Arrow icon"
                                                                            className="questions-page-icons cursor-pointer"
                                                                            style={{ width: "30px", margin: "10px 0px 0px 10px" }}
                                                                        /> <p className="questionPanelAnswersText" id={"OptionPart2" + index} style={{ border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1,  marginBottom: "0px", marginTop: "10px" }}>
                                                                            {values.hasOwnProperty(index) ? values[index] : UNANSWERED}
                                                                        </p> </div>)
                                                                }));
                                                                setShowAnswerModal(true);
                                                            }}
                                                        >
                                                            {VIEW_ANSWER}
                                                        </div>
                                                    </td>
                                                );

                                            default:
                                                break;
                                        }
                                        if (cell.row.original.questionType !== QUESTION_TYPES.FITB.name) {
                                            return (
                                                <td tabIndex={0} aria-label={YOUR_ANS_LABEL + display} style={{wordBreak: 'break-all'}} {...cell.getCellProps()}>
                                                    {display}
                                                </td>
                                            );
                                        }
                                    }

                                    return (
                                        <td tabIndex={0} {...cell.getCellProps()}>
                                            {cell.value}
                                        </td>
                                    );

                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            
            <NormalPagination
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                nextPage={nextPage}
                gotoPage={gotoPage}
            />
        </div>
    </>
    );
}
