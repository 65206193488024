import "./styles.css";
import {ReactComponent as NoParticipantsIcon} from "./images/NO_PARTICIPANTS_ICON.svg";
import {ReactComponent as NoParticipantsSmall} from "./images/NO_PARTICIPANTS_SMALL.svg";
import { React, useEffect } from "react";
import {Spin} from "../../components/standardization/YuJaLoading";
import { useState } from "react";
import {ReactComponent as DropDown} from "./images/DROPDOWN_ICON.svg";
import {ReactComponent as DropUp} from "./images/DROPUP_ICON.svg";
import {ReactComponent as EngageIcon} from "./images/engage_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { AVG_ENGAGEMENT_TITLE, ENGAGEMENT_TITLE, POLL_SHARE_MODE, POLL_STATE, POLL_TYPE } from "../../utils/constants";

export default function ViewParticipants({data=[], pollData=[], responseData=[], allData=[], loading=true, initialized=false, currQues, shareMode, localState}) {
    const [minimized, setMinimized] = useState(true);
    const checkEmptyShareEach1 = !data || !data.length || data[0].total === 0;
    const checkEmptyShareAll1 = !responseData || (responseData.done === 0 && responseData.inProgress === 0);
    const checkEmptyShareEach2 = !!data && !!data.length && data[0].total !== 0;
    const checkEmptyShareAll2 = !!responseData && (responseData.done !== 0 || responseData.inProgress !== 0);
    const [pollWasShared, setPollWasShared] = useState(false);

    const getEngagement = () => {
        if(!!data && data[0].total !== 0) {
            return ((data[0].total - data.find((element) => element.ans === "Unanswered").people)/data[0].total*100).toFixed(1);
        }
        return 0;
    };

    const getAvgEngagement = () => {
        let totalEngagement = 0;
        for(let question of allData) {
            if(!!question && question[0].total !== 0) {
                totalEngagement += ((question[0].total - question.find((element) => element.ans === "Unanswered").people)/question[0].total*100);
            }
        }
        return (totalEngagement/allData.length).toFixed(1);
    };

    useEffect( () => {
        if(document.getElementById("partsUnasTotal") !== null) {
            let width = 0;
            if(shareMode === POLL_SHARE_MODE.SHARE_EACH && data[0].total !== 0) {
                width = data.find((element) => element.ans === "Unanswered").people/data[0].total*100;
            }
            else if((shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && responseData.done !== 0) {
                width = (responseData.done/(responseData.done + responseData.inProgress))*100;
            }
            document.getElementById("partsUnasTotal").style.width = width + "%";
        }
        if(document.getElementById("partsAnsTotal") !== null) {
            let width = 0;
            if(shareMode === POLL_SHARE_MODE.SHARE_EACH && data[0].total !== 0) {
                width = (data[0].total - data.find((element) => element.ans === "Unanswered").people)/data[0].total*100;
            }
            else if((shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && responseData.inProgress !== 0) {
                width = (responseData.inProgress/(responseData.done + responseData.inProgress))*100;
            }
            document.getElementById("partsAnsTotal").style.width = width + "%";
        }
    }, [data, responseData, loading, initialized, minimized]);

    const displayParticipants = () => {
        if(shareMode === POLL_SHARE_MODE.SHARE_EACH) {
            return true;
        }

        if(pollWasShared) {
            return true;
        }

        if(localState !== "START POLL") {
            setPollWasShared(true);
            return true;
        }

        if(localState !== "SHARED_ALL" && (shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE)) {
            return true;
        }
        
        if(!!pollData && !!pollData.poll && !!pollData.poll.pollState) {
            const currentTime = new Date();

            if(pollData.poll.pollState === POLL_STATE.SCHEDULED && !!pollData.poll.startTime) {
                if(currentTime > new Date(pollData.poll.startTime)) {
                    return true;
                }
            } 
            else if(pollData.poll.pollState === POLL_STATE.SHARED_ALL || pollData.poll.pollState === POLL_STATE.STOPPED_ALL) {
                return true;
            }
        }
        return false;
    };

    return(<div style={{marginBottom: "15px"}}>
        {minimized && 
            <div className="smallPanelBodyNew" style={{width: "350px"}}>
                <span className="smallPanelTitle">Participants</span>
                <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
            {/*<div className="smallPanelBody" style={{width: "350px"}}>
                <Spin size="medium" wrapperClassName="panelSpin" spinning={loading} style={{height: "100%"}}>
                {!loading && initialized && ((shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) ? checkEmptyShareAll1 : checkEmptyShareEach1) &&
                    <div style={{display: "flex", width: "100%"}}>
                        <div style={{display: "flex", flex: 5, alignItems: "center", flexDirection: "column"}}>
                            <NoParticipantsSmall/>
                            <div>No participants found</div>
                        </div>
                        <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
                    </div>
                }
                {!loading && initialized && ((shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) ? checkEmptyShareAll2 : checkEmptyShareEach2) && <div style={{width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <span className="smallPanelTitle">Participants</span>
                        <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
                    </div>
                    <div className="pollCodePanelBody">
                    <div style={{justifyContent: "flex-start", display: "flex", flexDirection: "column", width: "100%"}}>
                        <div className="participantsPanelTextSmall" style={{flex: 1, display: "flex", marginBottom: "10px"}}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div>Attempted <FontAwesomeIcon icon={faCircle} style={{fontSize: "0.25em", margin: "3px"}}/>&nbsp;</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE)&& <div>{localState === "START POLL" ? "Incomplete " : "In Progress "}<FontAwesomeIcon icon={faCircle} style={{fontSize: "0.25em", margin: "3px"}}/>&nbsp;</div>}
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div style={{color: "#0277AD"}}>{data[0].total - data.find((element) => element.ans === "Unanswered").people}</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE)&& <div style={{color: "#0277AD"}}>{responseData.inProgress}</div>}
                        </div>
                        <div className="slidecontainerSmall">
                            <div className="slider2Small" id="partsAnsTotal">.</div>
                        </div>

                        <div className="participantsPanelTextSmall" style={{flex: 1, display: "flex", marginTop: "10px", marginBottom: "10px"}}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div>Unattempted <FontAwesomeIcon icon={faCircle} style={{fontSize: "0.25em", margin: "3px"}}/>&nbsp;</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && <div>{localState === "START POLL" ? "Finished " : "Done "}<FontAwesomeIcon icon={faCircle} style={{fontSize: "0.25em", margin: "3px"}}/>&nbsp;</div>}
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div style={{color: "#01BEA3"}}>{data.find((element) => element.ans === "Unanswered").people}</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && <div style={{color: "#01BEA3"}}>{responseData.done}</div>}
                        </div>
                        <div className="slidecontainerSmall">
                            <div id="partsUnasTotal" className="sliderSmall">.</div>
                        </div>
                    </div>
                    </div>
                </div>}
                </Spin>
            </div>*/}
            </div>
        }
        {!minimized &&
        <div className="smallPanelBody2" style={{width: "350px"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span className="smallPanelTitle">Participants</span>
                <DropUp style={{cursor: "pointer"}} onClick={() => setMinimized(true)}/>
            </div>
            <span className="smallPanelSubtitle">Total number of {shareMode === POLL_SHARE_MODE.SHARE_EACH ? "Q"+currQues : ""} participants</span>
            <div className="pollCodePanelBody">
            <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}>
                {!loading && initialized && (((shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) ? checkEmptyShareAll1 : checkEmptyShareEach1) || !displayParticipants()) &&
                    <>
                        <NoParticipantsIcon style={{maxWidth: "100%", maxHeight: "100%"}}/>
                        <span>No participants found</span>
                    </>
                }
                {!loading && initialized && displayParticipants() && ((shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE)? checkEmptyShareAll2 : checkEmptyShareEach2) &&
                    <div style={{justifyContent: "flex-start", display: "flex", flexDirection: "column", width: "100%"}}>
                        <div className="participantsPanelText" style={{flex: 1, display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div>Attempted</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && <div>{localState === "START POLL" ? "Incomplete " : "In Progress "}</div>}
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div style={{color: "#0277AD"}}>{data[0].total - data.find((element) => element.ans === "Unanswered").people}</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && <div style={{color: "#0277AD"}}>{responseData.inProgress}</div>}
                        </div>
                        <div className="slidecontainer">
                            <div className="slider2" id="partsAnsTotal">.</div>
                        </div>

                        <div className="participantsPanelText" style={{flex: 1, display: "flex", justifyContent: "space-between", marginTop: "20px", marginBottom: "8px"}}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div>Unattempted</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE)&& <div>{localState === "START POLL" ? "Finished " : "Done "}</div>}
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div style={{color: "#01BEA3"}}>{data.find((element) => element.ans === "Unanswered").people}</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && <div style={{color: "#01BEA3"}}>{responseData.done}</div>}
                        </div>
                        <div className="slidecontainer">
                            <div id="partsUnasTotal" className="slider">.</div>
                        </div>

                        <div className="engagementText" style={{flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "25px"}}>
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div>{ENGAGEMENT_TITLE}</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && <div>{AVG_ENGAGEMENT_TITLE}</div>}
                            {shareMode === POLL_SHARE_MODE.SHARE_EACH && <div className="engagementButton"><EngageIcon/>{getEngagement()}%</div>}
                            {(shareMode === POLL_SHARE_MODE.SHARE_ALL || shareMode === POLL_SHARE_MODE.SCHEDULE) && <div className="engagementButton"><EngageIcon/>{(localState === "SHARED_ALL" && pollData.poll.pollType !== POLL_TYPE.SURVEY) ? 0 : getAvgEngagement()}%</div>}
                        </div>
                    </div>
                }
            </Spin>
            </div>
        </div>
        }
    </div>);
}
