import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { DEFAULT_TIME_LIMIT, NO_ACCESS_TO_POLL_ERROR, POLL_SHARE_MODE, POLL_STATE, POLL_TYPE, QUESTION_TYPES, POLL_CODE_PREFIX, PLATFORM_TYPES, BROWSER_TYPES, POLL_TEXT, SURVEY, RELEASE, WEBSOCKET_INSTRUCTOR, TAB_PARTICIPANTS_CORRECT, TAB_PARTICIPANTS_INCORRECT, TAB_PARTICIPANTS_UNANSWERED } from "../../utils/constants";
import { getInstitutionId, getPollShareModeFromPollState, notifyError } from "../../utils/helpers";
import ViewActivityFeed from "./ViewActivityFeed";
import ViewParticipants from "./ViewParticipants";
import ViewPollCode from "./ViewPollCode";
import ViewQuestions from "./ViewQuestions";
import ViewResponses from "./ViewResponses";
import ViewBrowserInfo from "./ViewBrowserInfo";
import { ReactComponent as ExpandIcon } from "./images/ExpandSideBar.svg";
import { ReactComponent as CollapseIcon } from "./images/CollapseSideBar.svg";
import { ReactComponent as RedTimer } from "./images/RED_TIMER_ICON.svg";
import { ReactComponent as ShareIcon } from "./images/SHARE_ICON.svg";
import { ReactComponent as StopIcon } from "./images/STOP_POLL_ICON.svg";
import { ReactComponent as SwitchIcon } from "./images/SWITCH_VIEW_ICON.svg";
import { ReactComponent as Timer } from "./images/TIMER_ICON.svg";
import {ReactComponent as Megaphone} from "./images/Megaphone.svg";
import EditDateModal from "../../components/modals/EditDateModal";
import { WSS_BASE_URL } from "../../utils/properties";

export default function SharePollPage({ }) {
    const { pollKey, pollCode } = useParams();
    const isNarrow = useMobileAccess(1300);
    const [collapsed, setCollapsed] = useState(isNarrow ? true : false);
    const { getPublishedPollDataFromKey, shareQuestion, shareAllQuestions, stopQuestion, stopAllQuestions } = PollApiCalls();
    const [pollData, setPollData] = useState({});
    const countDownRef = useRef();
    const [queTimeLimit, setQueTimeLimit] = useState(0);
    const [queEnd, setQueEnd] = useState(0);
    const [pollEnd, setPollEnd] = useState(0);
    const [pollTimeLimit, setPollTimeLimit] = useState(0);
    const timeRemainingRef = useRef(0);
    const [localState, setLocalState] = useState("START POLL");
    const [, getTimeOffset] = useLocalStorageNew("timeOffset", 0);
    const [, getSession] = useLocalStorageNew("session", {});
    const [setPollRes, getPollRes] = useLocalStorageNew("pollRes", {});
    const [setRespData, getRespData] = useLocalStorageNew("respData", {});
    const [sharingAll, setSharingAll] = useState(false);
    const [loading, setLoading ] = useState(true);
    const [initialized, setInitialized ] = useState(false);
    const [loadingParticipants, setLoadingParticipants] = useState(true);
    const [initializedParticipants, setInitalizedParticipants] = useState(false);
    const [loadingResponses, setLoadingResponses] = useState(true);
    const [initializedResponses, setInitalizedResponses] = useState(false);

    const [selectedQuestion, setSelectedQuestion] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const {getClassResult, getResultByGradeBookPerspective, getActiveStudentsInPoll, getAudienceResponseByPoll} = ResponseApiCalls();
    const [pollResults, setPollResults] = useState([]);
    const [points, setPoints] = useState([]);
    const [activeStudents, setActiveStudents] = useState({});
    const [sharedQuestionNo, setSharedQuestionNo] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [shareAll, setShareAll] = useState("");
    const [responseData, setResponseData] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [engagementData, setEngagementData] = useState([]);
    const [message, setMessage] = useState({});
    const [messageCount, setMessageCount] = useState(0);

    const [, setPollType] = useState("");
    const [, setLivePollTime] = useState(0);
    const [, setInstitutionId] = useState("");
    const history = useHistory();
    const ref = useRef(null);

    const [dateModalShow, setDateModalShow] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [answerMsg, setAnswerMsg] = useState({});
    const [reactionMsg, setReactionMsg] = useState({});
    const [removeAnsMsg, setRemoveAnsMsg] = useState({});
    const [fallbackOn, setFallBackOn] = useState(false);

    const websocketRef = useRef(null);

    const handleShare = async () => {
        let res = await shareQuestion(pollKey, pollCode, currentQuestion);
        setSharingAll(false);
        let quesTimeLimit = pollData.questions[currentQuestion-1].timeLimit;
        setQueTimeLimit(quesTimeLimit);
        setQueEnd(res.liveQuestionTime + parseInt(getTimeOffset()) + quesTimeLimit*1000);
        setSharedQuestionNo(currentQuestion);
        setLocalState('GET_READY');
        countDownRef.current.start();
    };

    const handleShareAll = async () => {
        let res = await shareAllQuestions(pollKey, pollCode);
        setPollEnd(res.liveQuestionTime + parseInt(getTimeOffset()) + pollTimeLimit * 1000);
        setSharingAll(true);
        setLocalState('GET_READY_ALL');
        countDownRef.current.start();
    };

    const handleStop = async () => {
        await stopQuestion(pollKey, pollCode, sharedQuestionNo);
        setLocalState('TIMEUP');
        setTimeout(() => {
            setLocalState("START POLL");
        }, 2000);
    };

    const handleStopAll = async () => {
        await stopAllQuestions(pollKey, pollCode);
        setLocalState('TIMEUP_ALL');
        setTimeout(() => {
            setLocalState("START POLL");
        }, 2000);
    };

    const handleEngagementData = async() => {
        let newData = [];
        async function getDataFromAllQuestions() {
            for (let i = 0; i < pollData.questions.length; i++) {
                const res = await getClassResult(pollKey, pollCode, pollData.questions[i], setPointsCallback);
                newData.push(res);
            }
        }

        getDataFromAllQuestions().then(() => {
            setEngagementData(newData);
        })
    };

    const handleFetchAllRespones = async(shareType, questions) => {
        /*setLoadingResponses(true);
        setInitalizedResponses(false);
        if (shareType === POLL_SHARE_MODE.SHARE_EACH) {
            setLoadingParticipants(true);
            setInitalizedParticipants(false);
        }*/

        let newData = [];
        async function processAllResponses() {
            for (let i = 0; i < questions.length; i++) {
                const res = await getClassResult(pollKey, pollCode, questions[i], setPointsCallback);
                newData.push(res);
            }
        }

        processAllResponses().then(() => {
            setPollResults(newData);
            /*if(shareType === POLL_SHARE_MODE.SHARE_EACH) {
                setLoadingParticipants(false);
                setInitalizedParticipants(true);
            }
            setLoadingResponses(false);
            setInitalizedResponses(true);*/
        })
    };

    const updateAllInfo = () => {
        getActiveStudentsInPoll(pollKey, pollCode).then(resp => {
            setActiveStudents(resp)
        });
        getResultByGradeBookPerspective(pollKey, pollCode).then(res => {
            handleUserInfoUpdate(res.gradeBookBreakdownResult.responses);
        });
        handleEngagementData();
        handleFetchAllRespones(shareAll, pollData.questions);
        if (shareAll === POLL_SHARE_MODE.SHARE_ALL || shareAll === POLL_SHARE_MODE.SCHEDULE) {
            getAudienceResponseByPoll(pollKey, pollCode, pollData.questions.length).then(resp => {
                if (JSON.stringify(resp) === JSON.stringify(getRespData())) return;
                setRespData(resp);
                setLoadingParticipants(true);
                setInitalizedParticipants(false);
                setResponseData(resp);
            }).finally( () => {
                setLoadingParticipants(false);
                setInitalizedParticipants(true);
            });
        }
    };

    /* handles all websocket communications for real time data that we show */
    useEffect(() => {
        if (document.visibilityState === "visible") {
            buildWebsocketConnection();
            updateAllInfo();
        
        } else {
            closeWebsocketConnection();
        }
    }, [document.visibilityState]);

    useEffect(() => {
        buildWebsocketConnection();

        return () => {
            closeWebsocketConnection();
        }
    }, []);

    const closeWebsocketConnection = () => {
        if(!!websocketRef.current) {
            websocketRef.current.close();
            websocketRef.current = null;
        }
    };

    const buildWebsocketConnection = () => {
        if(!!websocketRef.current) {
            return;
        }

        let sessionUserName = getSession().userName ? getSession().userName : "";
        let sessionUserId = getSession().userId ? getSession().userId : "";
        let url = `${WSS_BASE_URL}?pollKey=${pollKey}&pollCode=${pollCode}&userName=${sessionUserName}&userId=${sessionUserId}&role=${WEBSOCKET_INSTRUCTOR}`;
        let newWebSocket = new WebSocket(url);
        newWebSocket.onopen = onOpen;
        newWebSocket.onclose = onClose;
        newWebSocket.onmessage = onMessage;
        newWebSocket.onerror = onError;
        websocketRef.current = newWebSocket;
    };

    const onClose = (e) => {
        const now = new Date();
        console.log("ws closed", now.toString(), e);
        // fall back logic 3
        setFallBackOn(true);
    };

    const onError = (e) => {
        const now = new Date();
        console.log("ws gets error", now.toString(), e);
    };

    const onMessage = (e) => {
        const message = JSON.parse(e.data);
        console.log(message);
        setMessage(message);
    };

    const onOpen = (e) => {
        const now = new Date();
        console.log("ws opened", now.toString(), e);
        // fall back logic 3
        setFallBackOn(false);
    };
    
    // fall back logic 2
    const checkIfNewMessages = (pastMsgCount) => {
        if(timeRemainingRef.current && localState === "SHARED" && timeRemainingRef.current < 0.3*pollData.questions[sharedQuestionNo-1].timeLimit && pastMsgCount === messageCount) {   
            setFallBackOn(true);
        } else {
            setFallBackOn(false);
        }
        return messageCount;
    };

    useEffect(() => {
        let pastMsgCount = 0;
        const interval = setInterval(() => {
            pastMsgCount = checkIfNewMessages(pastMsgCount);
        }, 3000);
    
        return () => clearInterval(interval);
    }, [initialized]);

    useEffect(() => {
        handleStudentMessage(message);
    }, [message]);

    const handleStudentMessage = (currentMsg) => {
        if(!!currentMsg.eventMessage && currentMsg.eventMessage === "connect") {
            let activityStds = {students: currentMsg.students};
            setActiveStudents(activityStds);
            handleUserInfoUpdate(currentMsg.platformData);
            setResponseData({done: responseData.done, inProgress: currentMsg.platformData.length-responseData.done});
        }
        else if(!!currentMsg.eventMessage && currentMsg.eventMessage === "disconnect") {
            let activityStds = {students: currentMsg.students};
            setActiveStudents(activityStds);
            handleUserInfoUpdate(currentMsg.platformData);
        }
        else if(!!currentMsg.eventMessage && currentMsg.eventMessage === "updateResponse" && (shareAll === POLL_SHARE_MODE.SHARE_EACH || pollData.poll.pollType.toUpperCase() === POLL_TYPE.SURVEY)) {
            let sharedQuesNo = sharedQuestionNo;
            if(pollData.poll.pollType.toUpperCase() === POLL_TYPE.SURVEY && shareAll === POLL_SHARE_MODE.SHARE_ALL) {
                if(currentMsg.answer && currentMsg.answer.length !== 2) {
                    return;
                }
                if(currentMsg.reaction) {
                    sharedQuesNo = currentMsg.serialNo;
                }
                if(currentMsg.answer) {
                    sharedQuesNo = currentMsg.answer[0].serialNo;
                }
            }
            
            // Graded Poll Reactions
            let thumbUpCount = 0;
            let thumbDownCount = 0;

            if(currentMsg.reaction && shareAll === POLL_SHARE_MODE.SHARE_EACH) {
                let keys = Object.keys(currentMsg.reaction.reactionMap[sharedQuesNo]);
                let highestKey = Math.max(...keys);
                let userReact = currentMsg.reaction.reactionMap[sharedQuesNo][highestKey][0];
                if(userReact === "1") {
                    thumbUpCount++;
                }
                else if(userReact === "2") {
                    thumbDownCount++;
                }
            }

            if(currentMsg.prevReaction && shareAll === POLL_SHARE_MODE.SHARE_EACH && currentMsg.prevReaction[sharedQuesNo]) {
                let keys = Object.keys(currentMsg.prevReaction[sharedQuesNo]);
                let highestKey = Math.max(...keys);
                let userReact = currentMsg.prevReaction[sharedQuesNo][highestKey][0];
                if(userReact === "1") {
                    thumbUpCount--;
                }
                else if(userReact === "2") {
                    thumbDownCount--;
                }
            }

            // Survey Share All Reactions
            if(currentMsg.reaction && shareAll === POLL_SHARE_MODE.SHARE_ALL) {
                let keys = Object.keys(currentMsg.reaction.reactionMap[1][sharedQuesNo]);
                let highestKey = Math.max(...keys);
                let userReact = currentMsg.reaction.reactionMap[1][sharedQuesNo][highestKey][0];
                if(userReact === "1") {
                    thumbUpCount++;
                }
                else if(userReact === "2") {
                    thumbDownCount++;
                }
            }

            if(currentMsg.prevReaction && shareAll === POLL_SHARE_MODE.SHARE_ALL && currentMsg.prevReaction[1][sharedQuesNo]) {
                let keys = Object.keys(currentMsg.prevReaction[1][sharedQuesNo]);
                let highestKey = Math.max(...keys);
                let userReact = currentMsg.prevReaction[1][sharedQuesNo][highestKey][0];
                if(userReact === "1") {
                    thumbUpCount--;
                }
                else if(userReact === "2") {
                    thumbDownCount--;
                }
            }
            setReactionMsg({thumbUpCount: thumbUpCount, thumbDownCount: thumbDownCount});


            // Process user answer below

            let orgPollResults = JSON.parse(JSON.stringify(pollResults));
            let newResults = orgPollResults[sharedQuesNo-1]; // update results for specific question
            let CIPoints = JSON.parse(JSON.stringify(points));
            
            if(currentMsg.prevAnswer && !fallbackOn) {
                let userResp = currentMsg.prevAnswer[1].selectedOption;

                if(currentMsg.answer[0].questionType === QUESTION_TYPES.MCSS.name) {
                    if(Array.isArray(userResp)) {
                        for(let newAns of userResp) {
                            for(let opt of newResults) {
                                if((opt.ans !== TAB_PARTICIPANTS_UNANSWERED && opt.name.toLowerCase().includes(newAns)) || opt.ans.toLowerCase() === newAns) {
                                    opt.people--;
                                }
                            }
                        }
                        for(let opt of newResults) {
                            opt.total--;
                        }
                    } else {
                        for(let opt of newResults) {
                            opt.total--;
                            if((opt.ans !== TAB_PARTICIPANTS_UNANSWERED && opt.name.toLowerCase().includes(userResp)) || opt.ans.toLowerCase() === userResp) {
                                opt.people--;
                            }
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.CI.name) {
                    for(let opt of newResults) {
                        opt.total--;
                        if((opt.name != null && opt.name.toString() === userResp.selected) || opt.ans.toLowerCase() === userResp.selected || opt.ans.toLowerCase() === userResp) {
                            opt.people--;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    if(userResp !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                        CIPoints = CIPoints.filter(elem => elem.userName !== currentMsg.answer[0].ownerId.substring(2));
                    }
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.TF.name) {
                    for(let opt of newResults) {
                        opt.total--;
                        if(opt.name.includes(userResp) || opt.ans.toLowerCase() === userResp) {
                            opt.people--;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.SA.name) {
                    let isCorr = currentMsg.prevAnswer[1].isCorrect;
                    for(let opt of newResults) {
                        opt.total--;
                        if(opt.ans === TAB_PARTICIPANTS_CORRECT && isCorr) {
                            opt.people--;
                        }
                        else if(opt.ans.toLowerCase() === userResp) {
                            opt.people--;
                        }
                        else if(opt.ans === TAB_PARTICIPANTS_INCORRECT && !isCorr && currentMsg.prevAnswer[1].isGraded) {
                            opt.people--;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    setRemoveAnsMsg({prevSA: userResp});
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.FITB.name) {
                    let isCorr = currentMsg.prevAnswer[1].isCorrect;
                    for(let opt of newResults) {
                        opt.total--;
                        if(opt.ans === TAB_PARTICIPANTS_CORRECT && isCorr) {
                            opt.people--;
                        }
                        else if(opt.ans.toLowerCase() === userResp) {
                            opt.people--;
                        }
                        else if(opt.ans === TAB_PARTICIPANTS_INCORRECT && !isCorr && userResp !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                            opt.people--;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    setRemoveAnsMsg({prevFITB: userResp});
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.MH.name) {
                    let isCorr = currentMsg.prevAnswer[1].isCorrect;
                    for(let opt of newResults) {
                        opt.total--;
                        if(opt.ans === TAB_PARTICIPANTS_CORRECT && isCorr) {
                            opt.people--;
                        }
                        else if(opt.ans.toLowerCase() === userResp) {
                            opt.people--;
                        }
                        else if(opt.ans === TAB_PARTICIPANTS_INCORRECT && !isCorr && userResp !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                            opt.people--;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.WC.name) {
                    let isCorr = currentMsg.prevAnswer[1].isCorrect;
                    for(let opt of newResults) {
                        opt.total--;
                        if(opt.ans === TAB_PARTICIPANTS_CORRECT && isCorr) {
                            opt.people--;
                        }
                        else if(opt.ans.toLowerCase() === userResp) {
                            opt.people--;
                        }
                        else if(opt.ans === TAB_PARTICIPANTS_INCORRECT && !isCorr && userResp !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                            opt.people--;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    if(userResp !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                        setRemoveAnsMsg({prevWC: userResp[0]});
                    }
                }
            }

            if(currentMsg.answer && !fallbackOn) {
                setMessageCount(messageCount+1);
                let userAnswer = currentMsg.answer[0].attempts[1].selectedOption;

                if(currentMsg.answer[0].questionType === QUESTION_TYPES.MCSS.name) {
                    if(Array.isArray(userAnswer)) {
                        for(let ans of userAnswer) {
                            for(let opt of newResults) {
                                if((opt.ans !== TAB_PARTICIPANTS_UNANSWERED && opt.name.toLowerCase().includes(ans)) || opt.ans.toLowerCase() === ans) {
                                    opt.people++;
                                }
                            }
                        }
                        for(let opt of newResults) {
                            opt.total++;
                        }
                    } else {
                        for(let opt of newResults) {
                            opt.total++;
                            if((opt.ans !== TAB_PARTICIPANTS_UNANSWERED && opt.name.toLowerCase().includes(userAnswer)) || opt.ans.toLowerCase() === userAnswer) {
                                opt.people++;
                            }
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    setPollResults(orgPollResults);
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.CI.name) {
                    let hasOther = false;
                    for(let opt of newResults) {
                        opt.total++;
                        if(opt.ans === "other") {
                            hasOther = true;
                        }
                        if((opt.name != null && opt.name.toString() === userAnswer.selected) || opt.ans.toLowerCase() === userAnswer.selected || opt.ans.toLowerCase() === userAnswer) {
                            opt.people++;
                        }
                    }
                    if(!hasOther && userAnswer.selected === "other") {
                        newResults= [...newResults, {name: -1, people: 1, ans: 'other', total: newResults[0].total}];
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    setPollResults(orgPollResults);
                    if(userAnswer !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                        userAnswer.userName = currentMsg.answer[0].ownerId.substring(2);
                        setPoints([...CIPoints, userAnswer]);
                    } else {
                        setPoints(CIPoints);
                    }
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.TF.name) {
                    for(let opt of newResults) {
                        opt.total++;
                        if(opt.name.includes(userAnswer) || opt.ans.toLowerCase() === userAnswer) {
                            opt.people++;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    setPollResults(orgPollResults);
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.SA.name) {
                    let isCorr = currentMsg.answer[0].attempts[1].isCorrect;
                    for(let opt of newResults) {
                        opt.total++;
                        if(opt.ans === TAB_PARTICIPANTS_CORRECT && isCorr) {
                            opt.people++;
                        }
                        else if(opt.ans.toLowerCase() === userAnswer) {
                            opt.people++;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    setPollResults(orgPollResults);
                    setAnswerMsg({sa: userAnswer});
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.FITB.name) {
                    let isCorr = currentMsg.answer[0].attempts[1].isCorrect;
                    for(let opt of newResults) {
                        opt.total++;
                        if(opt.ans === TAB_PARTICIPANTS_CORRECT && isCorr) {
                            opt.people++;
                        }
                        else if(opt.ans.toLowerCase() === userAnswer) {
                            opt.people++;
                        }
                        else if(opt.ans === TAB_PARTICIPANTS_INCORRECT && !isCorr && userAnswer !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                            opt.people++;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    setPollResults(orgPollResults);
                    setAnswerMsg({fitb: userAnswer});
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.MH.name) {
                    let isCorr = currentMsg.answer[0].attempts[1].isCorrect;
                    for(let opt of newResults) {
                        opt.total++;
                        if(opt.ans === TAB_PARTICIPANTS_CORRECT && isCorr) {
                            opt.people++;
                        }
                        else if(opt.ans.toLowerCase() === userAnswer) {
                            opt.people++;
                        }
                        else if(opt.ans === TAB_PARTICIPANTS_INCORRECT && !isCorr && userAnswer !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                            opt.people++;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    setPollResults(orgPollResults);
                }
                else if(currentMsg.answer[0].questionType === QUESTION_TYPES.WC.name) {
                    let isCorr = currentMsg.answer[0].attempts[1].isCorrect;
                    for(let opt of newResults) {
                        opt.total++;
                        if(opt.ans === TAB_PARTICIPANTS_CORRECT && isCorr) {
                            opt.people++;
                        }
                        else if(opt.ans.toLowerCase() === userAnswer) {
                            opt.people++;
                        }
                        else if(opt.ans === TAB_PARTICIPANTS_INCORRECT && !isCorr && userAnswer !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                            opt.people++;
                        }
                    }
                    orgPollResults[sharedQuesNo-1] = newResults;
                    setPollResults(orgPollResults);
                    if(userAnswer !== TAB_PARTICIPANTS_UNANSWERED.toLowerCase()) {
                        setAnswerMsg({wc: userAnswer[0]});
                    }
                }
                setEngagementData(orgPollResults);
            }
        }

        if(shareAll === POLL_SHARE_MODE.SHARE_ALL || shareAll === POLL_SHARE_MODE.SCHEDULE) {
            if(!!currentMsg.eventMessage && currentMsg.eventMessage === "reattemptPoll") {
                setResponseData({done: responseData.done <= 0 ? 0 : responseData.done-1, inProgress: responseData.inProgress+1});
            }
            else if(!!currentMsg.eventMessage && currentMsg.eventMessage === "updateResponse" && currentMsg.answer && currentMsg.answer[0].serialNo && currentMsg.answer[0].serialNo === pollData.questions.length) {
                setResponseData({done: responseData.done+1, inProgress: responseData.inProgress <= 0 ? 0 : responseData.inProgress-1});
            }
        }
    };

    /* END OF WEBSOCKET LOGIC */

    useEffect(() => {
        if(selectedQuestion && (localState === "START POLL" || localState === "TIMEUP" || localState === "TIMEUP_ALL")) {
            if(shareAll === POLL_SHARE_MODE.SHARE_ALL) {
                setPollEnd(Date.now() + pollTimeLimit*1000);
                setSharingAll(true);
            } else {
                let quesTimeLimit = pollData.questions[currentQuestion-1].timeLimit;
                setQueTimeLimit(quesTimeLimit);
                setQueEnd(Date.now() + quesTimeLimit*1000);
                setSharingAll(false);
            }
        }
        if(!selectedQuestion && (localState === "START POLL" || localState === "TIMEUP" || localState === "TIMEUP_ALL")) {
            setPollEnd(Date.now() + pollTimeLimit*1000);
            setSharingAll(true);
        }

        if(localState === "TIMEUP_ALL" && (shareAll === POLL_SHARE_MODE.SHARE_ALL || shareAll === POLL_SHARE_MODE.SCHEDULE)) {
            updateAllInfo();
        }

        // fall back logic 1
        if(localState === "TIMEUP") {
            setTimeout(() => {
                if(shareAll === POLL_SHARE_MODE.SHARE_EACH && messageCount < activeStudents.students.length) {
                    updateAllInfo();
                }
                setMessageCount(0);
            }, 2000);
        }

    }, [currentQuestion, selectedQuestion, localState]);



    useEffect(() => {
        setLoading(true);
        setInitialized(false);
        // setLoadingParticipants(true);
        // setLoadingResponses(true);
        // setInitalizedParticipants(false);
        // setInitalizedResponses(false);
        setPollRes({});
        setRespData({});

        getPublishedPollDataFromKey(pollKey, pollCode).then(res => {
            const {poll, questions} = res;
            setPollResults(Array(questions.length));
            setPollData(res);
            // Checks that user accessing poll has access to it (created it or was shared to them)
            if (poll.index1Pk.substring(2) !== getSession().userId) {
                notifyError(NO_ACCESS_TO_POLL_ERROR);
                history.push(`/poll/user/`);
            }
            const altSharedAll = getPollShareModeFromPollState(poll.pollState);
            let shareType = poll.pollShareMode ? poll.pollShareMode : (altSharedAll ?  altSharedAll : POLL_SHARE_MODE.SHARE_EACH);
            setShareAll(shareType);
            setLoading(false);
            setInitialized(true);
            setPollType(poll.pollType);
            if(document.getElementById("engagePageIdentifier") != null) {
                document.getElementById("engagePageIdentifier").innerText = RELEASE + (poll.pollType === POLL_TYPE.GRADED_POLL ? POLL_TEXT : SURVEY);
            }
            const {liveQuestionNo = 0, pollState = '', liveQuestionTime = 0, pollTimeLimit, index5Pk} = poll;
            setSelectedQuestion(poll.pollShareMode !== POLL_SHARE_MODE.SHARE_ALL);
            setInstitutionId(getInstitutionId(index5Pk));
            // getClassResult(pollKey, pollCode, questions[0], setPointsCallback).then(resp => {
            //     console.log('first poll results initialization', resp);
            //     setPollResults(resp);
            // }).finally(() => {
            //     if(poll.pollShareMode === POLL_SHARE_MODE.SHARE_ALL) {
            //         getAudienceResponseByPoll(pollKey, pollCode, questions.length).then(resp => {
            //             setResponseData(resp);
            //         }).finally(() => {
            //             setLoadingParticipants(false);
            //             setLoadingResponses(false);
            //             setInitalizedParticipants(true);
            //             setInitalizedResponses(true);
            //         });
            //     } else {
            //         setLoadingParticipants(false);
            //         setLoadingResponses(false);
            //         setInitalizedParticipants(true);
            //         setInitalizedResponses(true);
            //     }
            // });

            setPollTimeLimit(pollTimeLimit);
            setPollEnd(Date.now() + pollTimeLimit*1000);

            let totalWeightage = 0;
            for(let question of questions) {
                totalWeightage += question.weightage;
            }
            setTotalPoints(totalWeightage);

            handleFetchAllRespones(shareType, questions);

            if (pollState === POLL_STATE.STOPPED || pollState === POLL_STATE.STOPPED_ALL) {
                setLocalState("START POLL");
            } else if (pollState === POLL_STATE.SHARED_ALL) {
                // if ((liveQuestionTime + (pollTimeLimit * 1000)) < Date.now() && poll.pollType.toUpperCase() !== POLL_TYPE.SURVEY) {
                //     return;
                // }
                let pollEndPoint= liveQuestionTime + pollTimeLimit * 1000;
                const currentTime = Date.now();

                if (liveQuestionTime > currentTime) {
                    setSharingAll(true);
                    setLivePollTime(liveQuestionTime);
                    setPollEnd(pollEndPoint);
                    setLocalState("GET_READY_ALL");
                    countDownRef.current?.start();
                    return;
                }
    
                if (pollEndPoint > currentTime) {
                    setSharingAll(true);
                    setLivePollTime(liveQuestionTime);
                    setPollEnd(pollEndPoint);
                    setLocalState("SHARED_ALL");
                    countDownRef.current?.start();
                    return;
                }

                if (poll.pollType.toUpperCase() === POLL_TYPE.SURVEY) {
                    setSharingAll(true);
                    setLivePollTime(liveQuestionTime);
                    setPollEnd(pollEndPoint);
                    setLocalState("SHARED_ALL");
                    countDownRef.current?.start();
                }

            } else if (pollState === POLL_STATE.SCHEDULED) {
                console.log(poll)
                const currentTime = new Date();
    
                if (poll.startTime && poll.endTime && (new Date(poll.endTime) > currentTime && currentTime > new Date(poll.startTime) )) {
                    setSharingAll(true);
                    setLivePollTime(liveQuestionTime);
                    setPollEnd(new Date(poll.endTime).getTime());
                    setLocalState("SHARED_ALL");
                    countDownRef.current?.start();
                    return;
                }

                if (poll.startTime && poll.endTime && (new Date(poll.endTime) < currentTime)) {
                    setLocalState("START POLL");
                    return;
                }
            } else {
                if (liveQuestionNo < 1) {
                    handleFetchAllRespones(shareType, questions);
                    return;
                }

                // if ((liveQuestionTime + (questions[liveQuestionNo-1].timeLimit * 1000)) < Date.now() && poll.pollType.toUpperCase() !== POLL_TYPE.SURVEY) {
                //     return;
                // }
                const liveQue = questions[liveQuestionNo - 1];

                const { timeLimit = DEFAULT_TIME_LIMIT, } = liveQue;
                let queEndPoint = liveQuestionTime + timeLimit * 1000;
                const currentTime = Date.now();


                if (liveQuestionTime > currentTime) {
                    setSharedQuestionNo(liveQuestionNo);
                    setCurrentQuestion(liveQuestionNo);
                    setQueTimeLimit(timeLimit);
                    setQueEnd(queEndPoint);
                    setLocalState("GET_READY");
                    countDownRef.current?.start();
                    return;
                }
    
    
                if (queEndPoint > currentTime) {
                    setSharedQuestionNo(liveQuestionNo);
                    setCurrentQuestion(liveQuestionNo);
                    setQueTimeLimit(timeLimit);
                    setQueEnd(queEndPoint);
                    setLocalState("SHARED");
                    countDownRef.current?.start();
                    return;
                }

                //survey doesn't have time limit
                if (poll.pollType.toUpperCase() === POLL_TYPE.SURVEY) {
                    setSharedQuestionNo(liveQuestionNo);
                    setCurrentQuestion(liveQuestionNo);
                    setQueTimeLimit(timeLimit);
                    setQueEnd(queEndPoint);
                    setLocalState("SHARED");
                    countDownRef.current?.start();
                }
            }
        })

    }, []);

    const timeUp = () => {
        //if current poll is survey then don't need to time up
        if (!!pollData?.poll?.pollType && pollData.poll.pollType.toUpperCase() === POLL_TYPE.SURVEY) {
            setLocalState(sharingAll ? 'SHARED_ALL' : 'SHARED');
            return;
        }
        sharingAll ? setLocalState("TIMEUP_ALL") : setLocalState("TIMEUP");
        setTimeout(() => {
            setLocalState("START POLL");
        }, 2000);
    };

    const renderer = ({ days, hours, minutes, seconds }) => {
        if (localState === 'TIMEUP_ALL') {
            return (
                <div className="pollPanelTimerText"><Timer style={{marginRight: "10px"}}/>Poll Done</div>
            );
        }

        if (localState === 'TIMEUP') {
            return (
                <div className="pollPanelTimerText"><Timer style={{marginRight: "10px"}}/>Question Done</div>
            );
        }

        const hoursView = ('00' + ((days*24)+hours)).slice(-2);
        const minutesView = ('00' + minutes).slice(-2);
        const secondsView = ('00' + seconds).slice(-2);
        const urgent = days === 0 && hours === 0 && minutes === 0 && seconds < 4 && seconds !== 0;
        
        const time = (seconds + minutes * 60 + hours * 3600 + days * 86400);
        timeRemainingRef.current = time;

        if (localState === "START POLL") {
            return (
                <div className="pollPanelTimerText"><Timer style={{marginRight: "10px"}}/>{hoursView}:{minutesView}:{secondsView}</div>
            );
        }
        
        if (localState === "GET_READY_ALL" && time > pollTimeLimit) {
            return (
                <div className="pollPanelTimerText"><Timer style={{marginRight: "10px"}}/>Get Ready: {time - pollTimeLimit - 1}</div>
            );
        }


        if (localState === "GET_READY" && time > queTimeLimit) {
            return (
                <div className="pollPanelTimerText"><Timer style={{marginRight: "10px"}}/>Get Ready: {time - queTimeLimit - 1}</div>
            );
        }
        
        // pad with 2 leading zeros
        if(time && sharingAll && time <= pollTimeLimit){
            setLocalState('SHARED_ALL');
            return (
                <div className={`pollPanelTimerText ${urgent ? 'urgent' : ''}`}>{urgent ? <RedTimer className="timerMargin"/> :<Timer className="timerMargin"/>}{hoursView}:{minutesView}:{secondsView}</div>
            );
        }

        if (time && time <= queTimeLimit) {
            setLocalState('SHARED');
            return (
                <div className={`pollPanelTimerText ${urgent ? 'urgent' : ''}`}>{urgent ? <RedTimer className="timerMargin"/> :<Timer className="timerMargin"/>}{hoursView}:{minutesView}:{secondsView}</div>
            );
        }

        return <></>;
    };

    const setPointsCallback = (questionType, res) => {
        if (questionType !== QUESTION_TYPES.CI.name) {
            return;
        }
        const {points} = res;
        setPoints(points ? points: []);
    };


    useEffect(() => {
        if (initialized) {
            getClassResult(pollKey, pollCode, pollData.questions[currentQuestion-1], setPointsCallback).then(res => {
                if (JSON.stringify(res) === JSON.stringify(getPollRes())) return;
                setPollRes(res);
                
                pollResults[currentQuestion-1] = res;
                setPollResults(pollResults);
            }).finally(() => {
                if (shareAll === POLL_SHARE_MODE.SHARE_EACH) {
                    setLoadingParticipants(false);
                    setInitalizedParticipants(true);
                }
                setLoadingResponses(false);
                setInitalizedResponses(true);
            });
        }

        const interval = setInterval( () => {
            if (initialized && document.visibilityState === "visible" && fallbackOn) {
                getClassResult(pollKey, pollCode, pollData.questions[currentQuestion-1], setPointsCallback).then(res => {
                    if (JSON.stringify(res) === JSON.stringify(getPollRes())) return;
                    setPollRes(res);

                    pollResults[currentQuestion-1] = res;
                    setPollResults(pollResults);
                }).finally(() => {
                    if (shareAll === POLL_SHARE_MODE.SHARE_EACH) {
                        setLoadingParticipants(false);
                        setInitalizedParticipants(true);
                    }
                    setLoadingResponses(false);
                    setInitalizedResponses(true);
                });
            }       
        }, 10000);
    
        return () => clearInterval(interval);
    }, [initialized]);

    useEffect(() => {
        getActiveStudentsInPoll(pollKey, pollCode).then(resp => {
            setActiveStudents(resp)
        });

        const interval = setInterval( () => {
            if(document.visibilityState === "visible" && fallbackOn) {
                getActiveStudentsInPoll(pollKey, pollCode).then(resp => {
                    setActiveStudents(resp)
                });
            }
        }, 5000);
    
        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        if(isNarrow) {
            setCollapsed(true);
        }
        else {
            setCollapsed(false);
        }
    }, [isNarrow])

    const handleUserInfoUpdate = (responseData) => {
        let chrome = (responseData.filter(resp => resp.browserType && resp.browserType.includes(BROWSER_TYPES.Chrome))).length;
        let firefox = (responseData.filter(resp => resp.browserType && resp.browserType.includes(BROWSER_TYPES.Firefox))).length;
        let edge = (responseData.filter(resp => resp.browserType && resp.browserType.includes(BROWSER_TYPES.Edge))).length;
        let safari = (responseData.filter(resp => resp.browserType && resp.browserType.includes(BROWSER_TYPES.Safari))).length;
        let mobileApp = (responseData.filter(resp => resp.browserType && resp.browserType.includes(BROWSER_TYPES.MobileApp))).length;
        let apple = (responseData.filter(resp => resp.platform && (resp.platform.toUpperCase().includes(PLATFORM_TYPES.iPhone.toUpperCase()) || resp.platform.includes(PLATFORM_TYPES.Mac) || resp.platform.includes(PLATFORM_TYPES.iPad)))).length;
        let windows = (responseData.filter(resp => resp.platform && resp.platform.includes(PLATFORM_TYPES.Windows))).length;
        let android = (responseData.filter(resp => resp.platform && resp.platform.toUpperCase().includes(PLATFORM_TYPES.Android.toUpperCase()))).length;
        let browserData = {
            "chrome": chrome,
            "firefox": firefox,
            "edge": edge,
            "safari": safari,
            "mobileApp": mobileApp,
            "otherBrowser": responseData.length - chrome - firefox - edge - safari - mobileApp,
            "apple": apple,
            "android": android,
            "windows": windows,
            "otherPlatform" : responseData.length - apple - android - windows,
            "total" : responseData.length
        };
        setUserInfo(browserData);
    };

    useEffect(() => {
        getResultByGradeBookPerspective(pollKey, pollCode).then(res => {
            handleUserInfoUpdate(res.gradeBookBreakdownResult.responses);
        });

        const interval = setInterval(() => {
            if(fallbackOn) {
                getResultByGradeBookPerspective(pollKey, pollCode).then(res => {
                    handleUserInfoUpdate(res.gradeBookBreakdownResult.responses);
                });
            }
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if(initialized) {
            handleEngagementData();
        }

        const interval = setInterval(() => {
            if(initialized && fallbackOn) {
                handleEngagementData();
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [initialized]);

    useEffect(() => {
        if (initialized && (shareAll === POLL_SHARE_MODE.SHARE_ALL || shareAll === POLL_SHARE_MODE.SCHEDULE)) {
            getAudienceResponseByPoll(pollKey, pollCode, pollData.questions.length).then(resp => {
                if (JSON.stringify(resp) === JSON.stringify(getRespData())) return;
                setRespData(resp);
                setLoadingParticipants(true);
                setInitalizedParticipants(false);
                setResponseData(resp);
            }).finally( () => {
                setLoadingParticipants(false);
                setInitalizedParticipants(true);
            });
        }

        const interval = setInterval( () => {
            if (document.visibilityState === "visible" && initialized && (shareAll === POLL_SHARE_MODE.SHARE_ALL || shareAll === POLL_SHARE_MODE.SCHEDULE) && fallbackOn) {
                getAudienceResponseByPoll(pollKey, pollCode, pollData.questions.length).then(resp => {
                    if (JSON.stringify(resp) === JSON.stringify(getRespData())) return;
                    setRespData(resp);
                    setLoadingParticipants(true);
                    setInitalizedParticipants(false);
                    setResponseData(resp);
                }).finally( () => {
                    setLoadingParticipants(false);
                    setInitalizedParticipants(true);
                });
            }
        }, 15000);
        return () => clearInterval(interval);
    }, [initialized]);


    const convertTime = (startTime) => {
        if (startTime === null) {
          return ""; 
        }
        const dateTime = new Date(startTime);
        const monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
        ];
        
        const month = monthNames[dateTime.getMonth()];
        const day = dateTime.getDate();
        const year = dateTime.getFullYear();
        
        let hours = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
    
        const timeZone = dateTime.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    
        return`${month} ${day}, ${year} - ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period} (${timeZone})`;
      } 

      useEffect(() => {
        if (pollData && pollData.poll && !startDate) {
            setStartDate(pollData.poll.startTime);
        }
        const checkStartTime = () => {
            if (shareAll === POLL_SHARE_MODE.SCHEDULE && pollData && pollData.poll && pollData.poll.startTime && pollData.poll.endTime) {
                const currentTime = new Date().getTime();
                const startTime = startDate ? new Date(startDate).getTime() : new Date(pollData.poll.startTime).getTime();
                const endTime = new Date(pollData.poll.endTime).getTime();
    
                if (currentTime >= startTime && currentTime <= endTime) {
                    setLocalState("SHARED_ALL");
                    setTimeout(checkStartTime, 1000); 
                } else if (endTime < currentTime) {
                    setLocalState("TIMEUP_ALL");
                }else {
                    setTimeout(checkStartTime, 1000); 
                }
            }
        };
    
        checkStartTime(); 
    
        return () => {
            clearTimeout(checkStartTime); 
        };
      }, [pollData, startDate])

    return (
        <div className="sharePollContainer" style={{gap: "15px"}}>
            {
                dateModalShow && <EditDateModal show={dateModalShow} setModalShow={setDateModalShow} pollKey={pollKey} pollUniqueCode={POLL_CODE_PREFIX + pollCode} setDate={setStartDate}/>
            }
            {shareAll === POLL_SHARE_MODE.SCHEDULE && !isNarrow &&
                <div style={{backgroundColor: "#C3CDFF", padding: "15px 30px 15px 30px", marginBottom: "10px", fontFamily: "Geomanist", fontSize: "14.5px", display: "flex", flexWrap: "wrap", alignItems: "center", borderRadius: "6.25px"}}> 
                <Megaphone style={{marginRight: "10px"}}/>
                <strong style={{ marginRight: "5px"}}>Attention</strong> : The Poll will automatically be shared with students on <strong style={{ marginLeft: "5px", marginRight: "5px"}}>{convertTime(startDate) + "."}</strong> You can modify the release of the Poll by clicking <span style={{color: "#0277AD", textDecoration: "underline", fontWeight: "700", marginLeft: "5px", marginRight: "5px", cursor: "pointer"}} onClick={() => {setDateModalShow(true)}}>Update Release Schedule.</span>
                </div>

            }
             {shareAll === POLL_SHARE_MODE.SCHEDULE && isNarrow &&
                <div style={{backgroundColor: "#C3CDFF", padding: "15px 30px 15px 30px", marginBottom: "10px", fontFamily: "Geomanist", fontSize: "14.5px", borderRadius: "6.25px", display: "flex", alignItems: "center"}}> 
                <div style={{minWidth: "120px"}}>
                <Megaphone style={{marginRight: "10px"}}/>
                <strong style={{ marginRight: "5px"}}>Attention : </strong>
                </div>
                <div style={{flexGrow: 1}}> 
                <div>The Poll will automatically be shared with students on <strong style={{ marginTop: "10px"}}>{convertTime(startDate) + "."}</strong> </div>
                <div>You can modify the release of the Poll by clicking <span style={{color: "#0277AD", textDecoration: "underline", fontWeight: "700", cursor: "pointer"}} onClick={() => {setDateModalShow(true)}}>Update Release Schedule.</span> </div>
                </div> 
                </div>

            }

            <div style={{display: "flex", gap: "25px", height: shareAll === POLL_SHARE_MODE.SCHEDULE ? "80%" : "100%", flex: shareAll === POLL_SHARE_MODE.SCHEDULE ? '1' : ""}}> 
                <div style={{display: "flex", flexDirection: "column", gap: "15px", flex: "1"}}>
            <div className="pollPanel">
                {!loading && initialized && 
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div className="pollPanelSubtitle">{pollData.poll.pollType === POLL_TYPE.GRADED_POLL ? "Poll" : "Survey"} Title</div>
                        <div className={pollData.poll.pollTitle.length > 60 ? "pollPanelTitleSmall" : "pollPanelTitle"}>{pollData.poll.pollTitle}</div>
                    </div>
                    <div style={{display: "flex"}}>
                        {localState === "START POLL" && shareAll === POLL_SHARE_MODE.SHARE_EACH &&
                            <div className="gradientWrapPoll" onClick={() => handleShare()} style={{marginRight: "10px"}}>
                                <span className="gradientBtnPublish" tabIndex={0}>
                                <ShareIcon style={{marginRight: "10px"}}/>Release Question {currentQuestion}
                                </span>
                            </div>
                        }
                        {(localState === "GET_READY" || localState === "SHARED") && shareAll === POLL_SHARE_MODE.SHARE_EACH &&
                            <div className="gradientWrapPoll" onClick={() => handleStop()} style={{marginRight: "10px"}}>
                                <span className="gradientBtnStop" tabIndex={0}>
                                <StopIcon style={{marginRight: "10px"}}/>Stop Question {sharedQuestionNo}
                                </span>
                            </div>
                        }
                        {localState === "TIMEUP" && shareAll === POLL_SHARE_MODE.SHARE_EACH &&
                            <div className="grayedGradientWrap" style={{opacity: 0.5, marginRight: "10px"}}>
                                <span className="grayedGradientBtnStop">
                                <StopIcon style={{marginRight: "10px"}}/>Stop Question {localState === "TIMEUP" ? sharedQuestionNo : currentQuestion}
                                </span>
                            </div>
                        }
                        {localState === "START POLL" && shareAll === POLL_SHARE_MODE.SHARE_ALL &&
                            <div className="gradientWrapPoll" onClick={() => handleShareAll()} style={{marginRight: "10px"}}>
                                <span className="gradientBtnPublish" tabIndex={0}>
                                <ShareIcon style={{marginRight: "10px"}}/>Release {pollData.poll.pollType === POLL_TYPE.GRADED_POLL ? "Poll" : "Survey"}
                                </span>
                            </div>
                        }
                        {(localState === "GET_READY_ALL" || localState === "SHARED_ALL") && shareAll === POLL_SHARE_MODE.SHARE_ALL &&
                            <div className="gradientWrapPoll" onClick={() => handleStopAll()} style={{marginRight: "10px"}}>
                                <span className="gradientBtnStop" tabIndex={0}>
                                <StopIcon style={{marginRight: "10px"}}/>Stop {pollData.poll.pollType === POLL_TYPE.GRADED_POLL ? "Poll" : "Survey"}
                                </span>
                            </div>
                        }
                        {localState === "TIMEUP_ALL" && shareAll === POLL_SHARE_MODE.SHARE_ALL &&
                            <div className="grayedGradientWrap" style={{opacity: 0.5, marginRight: "10px"}}>
                                <span className="grayedGradientBtnStop">
                                <StopIcon style={{marginRight: "10px"}}/>Stop {pollData.poll.pollType === POLL_TYPE.GRADED_POLL ? "Poll" : "Survey"}
                                </span>
                            </div>
                        }
                        {selectedQuestion && shareAll === POLL_SHARE_MODE.SHARE_ALL &&
                            <div className="gradientWrapPoll" onClick={() => setSelectedQuestion(false)}>
                                <span className="gradientBtnView" tabIndex={0}>
                                    <SwitchIcon style={{marginRight: "10px"}}/>View All Questions
                                </span>
                            </div>
                        }
                        {!selectedQuestion && shareAll === POLL_SHARE_MODE.SHARE_ALL &&
                            <div className="gradientWrapPoll" onClick={() => setSelectedQuestion(true)}>
                                <span className="gradientBtnView" tabIndex={0}>
                                    <SwitchIcon style={{marginRight: "10px"}}/>View Question {currentQuestion}
                                </span>
                            </div>
                        }
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {collapsed ?
                                <div className="ExpandIcon" onClick={() => {
                                    setCollapsed(false);
                                }} onMouseDown={(e) => e.preventDefault()}>
                                </div>
                                 :
                                <div className="CollapseIcon" onClick={() => setCollapsed(true)} onMouseDown={(e) => e.preventDefault()}>
                                </div> }
                        </div>
                    </div>
                </div>}
            </div>


            <div className="sharePollBodyContainer" style={{marginRight: "0px", height: "100%"}}>
                <div className={isNarrow ? "viewQuestionsContainerNarrow" : "viewQuestionsContainer2"} style={{marginRight: "0px"}}>
                    <ViewQuestions 
                        data={pollData} answerMsg={answerMsg} reactionMsg={reactionMsg} removeAnsMsg={removeAnsMsg}
                        pollResults={pollResults[currentQuestion-1]} initializedResponses={initializedResponses} loadingResponses={loadingResponses}
                        setLoadingResponses={setLoadingResponses} setInitializedResponses={setInitalizedResponses}
                        initialized={initialized} loading={loading} localState={localState}
                        currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} selectedQuestion={selectedQuestion}
                        points={points} totalPoints={totalPoints}
                        countDownRef={countDownRef} renderer={renderer} sharingAll={sharingAll} pollEnd={pollEnd} queEnd={shareAll === POLL_SHARE_MODE.SHARE_ALL ? pollEnd : queEnd} timeUp={timeUp} pollMode={shareAll}
                    />
                </div>
                    <>
                        {/* <div className={collapsed ? "viewResultsCollapsedControl" : "viewResultsControl"}>
                            {collapsed ?
                                <ExpandIcon onClick={() => {
                                    setCollapsed(false);
                                    setTimeout(() => {
                                        ref.current?.scrollIntoView({ behavior: 'smooth' });
                                    }, 50);
                                }}/> :
                                <CollapseIcon onClick={() => setCollapsed(true)}/>}
                        </div> */}
                    </> 
                    {/* <div className="viewResultsContainer2">
                        <ViewResponses data={pollResults[currentQuestion-1]} pollData={pollData} currQues={currentQuestion} setCurrQues={setCurrentQuestion} initialized={initializedResponses} loading={loadingResponses}/>
                        <ViewParticipants pollData={pollData} data={pollResults[currentQuestion-1]} responseData={responseData} allData={engagementData} currQues={currentQuestion} initialized={initializedParticipants} loading={loadingParticipants} localState={localState} shareMode={shareAll}/>
                        <ViewBrowserInfo data={userInfo} />
                        <ViewPollCode data={pollData.poll} initialized={initialized} loading={loading}/>
                        <ViewActivityFeed data={activeStudents} localState={localState}/>
                    </div> */}
            </div>
            </div>


            <div ref={ref} className={collapsed ? "viewResultsContainerCollapsed" : "viewResultsContainerNarrow"} style={{height: "100%"}}>
                            {/* <div className="viewResultsContainer2"> */}
                            <ViewResponses data={pollResults[currentQuestion-1]} pollData={pollData} currQues={currentQuestion} setCurrQues={setCurrentQuestion} initialized={initializedResponses} loading={loadingResponses} localState={localState}/>
                            <ViewParticipants data={pollResults[currentQuestion-1]} pollData={pollData} responseData={responseData} allData={engagementData} currQues={currentQuestion} initialized={initializedParticipants} loading={loadingParticipants} localState={localState} shareMode={shareAll}/>
                            <ViewBrowserInfo data={userInfo} />
                            <ViewPollCode data={pollData.poll} initialized={initialized} loading={loading}/>
                            <ViewActivityFeed data={activeStudents} localState={localState}/>
                            {/* </div> */}
                        </div>
            </div>
        </div>
    );
}
