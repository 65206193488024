import React, { useEffect, useMemo } from "react";
import { Table } from "react-bootstrap";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import NormalBox from "../../public/boxes/NormalBox";
import styles from "./CourseContent.module.css";
import { DEFAULT_ROWS_PER_PAGE, TEXTBOX } from "../../utils/constants";

export default React.memo(CourseMemberTable);
function CourseMemberTable({ data = [], columns = [], globalFilterObj = {}, getPollInformation }) {
    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const {keyword} = globalFilterValue;
        const filteredKeyword = keyword ? keyword.toLowerCase() : "";
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.firstName && values.firstName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (values.lastName && values.lastName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [globalFilterObj, setGlobalFilter])

    const settings = useMemo(() => {
        return {
          'contentBoxType': 'light',
          'textTitle': 'title',
          'textAlign': 'center',
        };
      }, []);

    return (
        <>
            <NormalBox boxType={settings.contentBoxType} className={styles.tableContainer}>
                <Table responsive hover {...getTableProps()} style={{margin: "0px"}}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps({
                            style: {
                            }
                        })}>
                            {headerGroup.headers.map(column => (
                                <th tabIndex={0} {...column.getHeaderProps({
                                    style: {
                                        width: column.width,
                                        minWidth: column.minWidth,
                                        // border: '1px solid #000000'
                                    }
                                })}>
                                    <div className={styles.tableHeader}>
                                        {column.render('Header')}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page && page.length !== 0 ? page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps({
                                            style: {
                                                // width: '100%',
                                                // fontFamily: 'Geomanist',
                                                // fontStyle: 'normal',
                                                // fontWeight: '400',
                                                // fontsize: '1.25rem',
                                                // lineHeight: '1.25rem',
                                                // letterSpacing: '0.035em',
                                                // height: '4rem',
                                                // overflowX: 'hidden',
                                                // whiteSpace: 'nowrap',
                                                // textOverflow: 'ellipsis',
                                                // border: '1px solid #000000'
                                                wordBreak: 'break-all'
                                            }
                                        })}>
                                            <div tabIndex={0} role={TEXTBOX} aria-label={cell.column.Header + " " + cell.value} className={styles.tableBody}>
                                                {cell.value}
                                            </div>
                                        </td>
                                    );

                                })}
                            </tr>
                        )
                    }) : <tr><td colSpan="5" className={styles.noData}>No Results Found</td></tr>}
                    </tbody>
                </Table>
            </NormalBox>

            {page && page.length !== 0 ?
                <NormalPagination
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    paddingTop="20px"
                /> : <></>
            }
        </>
    );
}
