import styles from "./platform.module.css";
import React, {useState} from "react";
import {TEXTBOX, USER_SETTINGS, USER_TOAST_MESSAGE_LABEL, USER_TOAST_MESSAGE_TOOLTIP} from "../../utils/constants";
import { BsQuestionCircle, BsQuestionCircleFill } from "react-icons/bs";
import  ToastIcon from "../../images/toast_icon.svg";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import {YuJaTooltip} from "../../components/standardization/YuJaTooltip";
import { Card, Form } from "react-bootstrap";

export default function UserSettingTab({userSettings, setUserSettings}) {
    const optionArr = [
        {value: "5", label: "5 Seconds"},
        {value: "10", label: "10 Seconds"},
        {value: "-1", label: "Manual Dismissal"},
    ];

    const [isHovered, setIsHovered] = useState(false);

    const onSelect = (item) => {
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.TOAST_MESSAGE_DURATION] = item.value;
        setUserSettings(copy);
    };

    return (
        <div>
            <Card className={styles.smallBoxUser}>
                <Card.Header>
                    <img src={ToastIcon} alt="PollIcon" width={"24px"} height={"24px"} />
                    <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>Toast Message Settings</span>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <div className="form-labelReg">
                            <span role={TEXTBOX} tabIndex={0}>{USER_TOAST_MESSAGE_LABEL}</span>
                            <YuJaTooltip
                                text={USER_TOAST_MESSAGE_TOOLTIP}
                                placement={"top"}
                                tooltipStyle={{maxWidth: 400}}
                            >
                                <span
                                    style={{margin: '0 10px'}}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    aria-label={USER_TOAST_MESSAGE_TOOLTIP}
                                    role={TEXTBOX}
                                >

                                    {isHovered && <BsQuestionCircleFill/>}
                                    {!isHovered && <BsQuestionCircle/>}
                                </span>
                            </YuJaTooltip>
                        </div>

                        <YuJaDropdown
                            containerStyle={{maxWidth: "250px", marginTop: "15px"}}
                            data={optionArr}
                            value={userSettings[USER_SETTINGS.TOAST_MESSAGE_DURATION]}
                            onChange={onSelect}
                            id="userLastElem"
                        />
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}