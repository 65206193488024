import "bootstrap/dist/css/bootstrap.css";
import React, {useContext, useEffect, useState} from "react";
import { Container, Row } from "react-bootstrap";
import { useWindowDimensions } from "react-native-web";
import { useHistory } from "react-router-dom";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { IDENTITY_PROVIDER_TYPES, POLL_RESULT_TEXT, SYNC_ATTEMPT, TEXTBOX } from "../../utils/constants";
import { calculateScore, hasSA, newPageLog } from "../../utils/helpers";
import { ReactComponent as Boy } from "./images/graduating boy.svg";
import "./styles.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import NetworkStatus from "./NetworkStatus";
import {YuJaGlobalState} from "../../utils/LoadingContext";
import { ReactComponent as ParticipantsIcon } from "../../images/participants_icon.svg";
import { ReactComponent as ResponsesIcon } from "../../images/responses-icon.svg";
import { ReactComponent as CompletionRateIcon } from "../../images/completion-rate-icon.svg";

export default function ClassResultAll({ userName, pollInfo, currentPoll, handleClassResultComplete, websocketState }) {
    const { pollKey, uniqueCode, pollTitle, lmsAttempt } = pollInfo;
    const isNarrow = useMobileAccess(1200);
    const curWindow = useWindowDimensions();
    const history = useHistory();
    const [, getSession] = useLocalStorageNew("session", {});
    const session = getSession();
    const anonymous = !session.userName;
    // const fetchWrapper = useFetchWrapper();

    const { getAudienceResponseByUser } = ResponseApiCalls();
    const [ totalCorrect, setTotalCorrect ] = useState(0);
    const [ earnedPoints, setEarnedPoints ] = useState(0);
    const [ totalPoints, setTotalPoints ] = useState(0);
    const [ results, setResults ] = useState([]);
    const {isOnline} = useContext(YuJaGlobalState);

    useEffect(() => {
        if (!isOnline || hasSA(currentPoll.questions) || websocketState !== WebSocket.OPEN) {
            return;
        }

        newPageLog("ClassResultAll.js");
        if (isNarrow && document.getElementById("viewer-navbar") !== null) {
            document.getElementById("viewer-navbar").style.backgroundColor = "#E6F2F7";
        }

        async function getResult() {
            let totalCorrectRaw = 0;
            let earnedPointsRaw = 0;
            const {questions} = currentPoll;
            const res = await getAudienceResponseByUser(userName, uniqueCode);
            // If user has not done the poll yet, then they are redirected to waiting page
            /*if (res.data.length === 1) {
                handleClassResultComplete();
                return;
            }*/

            setResults(res);

            //filter current poll result
            let currentPollRes = res.data;

            if (lmsAttempt === SYNC_ATTEMPT.HIGHEST.value) {
                let attemptNo = 1;
                while (true) {
                    let flag = true; // flag to check if all questions do not have attemptNo
                    let tempEarnedPointsRaw = 0;
                    let tempTotalCorrectRaw = 0;
                    for (const tempResult of currentPollRes) {
                        let questionResponse = tempResult;
                        if (!questionResponse.attempts || !questionResponse.attempts['' + attemptNo]) {
                            continue;
                        }
                        flag = false;
                        if (questionResponse.attempts['' + attemptNo].isCorrect) {
                            tempEarnedPointsRaw += questions[questionResponse.serialNo - 1].weightage;
                            ++tempTotalCorrectRaw;
                        }
                    }
                    if (flag) break;
                    if (tempEarnedPointsRaw > earnedPointsRaw) {
                        earnedPointsRaw = tempEarnedPointsRaw;
                        totalCorrectRaw = tempTotalCorrectRaw;
                    }
                    ++attemptNo;
                }
            } else {
                for (const tempResult of currentPollRes) {
                  let questionResponse = tempResult;
                  if (!!questionResponse.attempts) {
                    if (lmsAttempt === SYNC_ATTEMPT.FIRST.value) {
                        if (questionResponse.attempts["1"].isCorrect) {
                            earnedPointsRaw += questions[questionResponse.serialNo - 1].weightage;
                            ++totalCorrectRaw;
                        }
                    } else if (lmsAttempt === SYNC_ATTEMPT.RECENT.value) {
                        if (questionResponse.attempts['' + questionResponse.attemptsCount].isCorrect) {
                            earnedPointsRaw += questions[questionResponse.serialNo - 1].weightage;
                            ++totalCorrectRaw;
                        }
                    } // lmsAttempt === SYNC_ATTEMPT.HIGHEST.value score calculated above
                  } else if (questionResponse.isCorrect) {
                    earnedPointsRaw += questions[questionResponse.serialNo - 1].weightage;
                    ++totalCorrectRaw;
                  }
                }
            }

            setTotalCorrect(totalCorrectRaw);
            setEarnedPoints(earnedPointsRaw);
            setTotalPoints(questions.map(q => q.weightage).reduce((a, b) => a + b, 0));
        }


        setTimeout(() => {
            getResult();
        }, 3000);
    }, [isOnline, websocketState]);

    // current attempt may not be the attempt to sync
    // useEffect(() => {
    //     gradebookSync(
    //         uniqueCode,
    //         session.userId,
    //         calculateScore(earnedPoints, totalPoints),
    //         () => syncScore(pollKey, uniqueCode, session.userId, true)
    //     );
    // }, [earnedPoints, totalPoints]);

    return (
        <Row id="everything" className="results-container" style={{backgroundColor: "#FCFBFF"}}>
            {/* style={{width: isAnonymous ? "calc(100vw)" : "calc(100vw - 48px)"}} */}
            {/* {!isNarrow && (
                <div className="left-wrapper" >
                    <div style={{flex: 1}}/>
                    <div className="left-header">Poll Results</div>
                    <Boy width={`${Math.min(0.3 * curWindow.width, 0.6 * curWindow.height)}px`} height={`${Math.min(0.3 * curWindow.width, 0.6 * curWindow.height)}px`} margintop="25px"/>
                    <div style={{flex: 1}}/>
                    <div style={{alignSelf: "start", padding: 20}}>
                        <NetworkStatus tooltipPlacement={'right'} websocketState={websocketState}/>
                    </div>
                </div>
            )} */}

            {/* <div className="right-wrapper" style={isNarrow ? {paddingTop:  0.05 * curWindow.height} : {}}> */}
                {/* {!isNarrow &&
                    <div style={{flex: 1}}/>
                }
                {isNarrow && <div style={{display: "flex", width: "100%", flexDirection: "column", justifyContent: "center"}}>
                    <div className="left-header">Poll Results</div>
                    <div className="right-header" style={{width: "100%"}}>{pollTitle}</div>
                </div>}
                {!isNarrow && <div className="right-header">{pollTitle}</div>} */}
            <div className="waiting-room-title-container" style={{ height: "fit-content" , marginTop: "32px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between"}}>
                <span tabIndex={0} role={TEXTBOX} className="waiting-room-title" style={{ wordBreak: "break-all", paddingLeft: !isNarrow? "170px" : "48px", textAlign: "center", flex: "1", color: "#000", fontSize: "24px" }}>{POLL_RESULT_TEXT + pollTitle}</span>
                <div  style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState} /> </div>
            </div>
                
                    {/* <Row style={{width: "100%", position: "relative", alignItems: "center"}}> */}
                        {/* <Col>
                            <Container style={{padding: "10% 0% 10% 20%", marginLeft: "12px"}}>
                                <div className="result-header">Class Results</div>
                                <div style ={{height: "50px"}}><span className="result-body-title">Average Overall Score: </span><span className="result-body">TBA</span></div>
                                <div style ={{height: "50px"}}><span className="result-body-title">Average Grade Percentage: </span><span className="result-body">TBA</span></div>
                                <div style ={{height: "50px"}}><span className="result-body-title">Average Points Earned: </span><span className="result-body">TBA</span></div>
                            </Container>
                        </Col> */}

                        {/* <div className="verticalLine"></div> */}

                        {/* <Col> */}
                            {/* <Container style={{padding: "5% 10% 5% 10%", justifyContent: "center"}}>
                                <Row className="result-header">Your Results</Row>
                                <Row style ={{width: "100%", height: "50px"}}><Col className="result-body-title justify-content-end">Overall Score: </Col><Col className="result-body">{totalCorrect}/{currentPoll.questions.length}</Col></Row>
                                <Row style ={{width: "100%", height: "50px"}}><Col className="result-body-title justify-content-end">Grade Percentage: </Col><Col className="result-body">{Math.round((totalCorrect/currentPoll.questions.length)*100)}%</Col></Row>
                                <Row style ={{width: "100%", height: "50px"}}><Col className="result-body-title justify-content-end">Points Earned: </Col><Col className="result-body">{totalPoints}</Col></Row>
                            </Container> */}
                        {/* </Col> */}
            <div style={{display: "flex"}}> 

             {
                !isNarrow && (
                    <div style={{ justifyContent: "center", alignItems: "center", display: "flex", width: "50%", flexDirection: "column"}}> 
                    <Boy width={`${Math.min(0.3 * curWindow.width, 0.6 * curWindow.height)}px`} height={`${Math.min(0.3 * curWindow.width, 0.6 * curWindow.height)}px`} margintop="25px"/>
                    {!isNarrow && hasSA(currentPoll.questions) &&
                                    <div tabIndex={0} role={TEXTBOX} style={{fontSize: "18px", color: "#000", fontFamily: "Geomanist"}}>
                                        For Poll Results, Please Contact Poll Administrator
                                    </div>
                        }
                    </div>
                )
            }    
            <div style={{ display: "flex", marginTop: "30px", gap: "42px", justifyContent: "center", flexDirection: "column", flex: "1", alignItems: isNarrow? "center" : ""}}>
                <div><div tabIndex={0} role={TEXTBOX} style={{fontSize: "20px", fontFamily: "Geomanist", display: "flex", width: "275px", justifyContent: "center"}}> Your Result </div></div>
                <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                    <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <ParticipantsIcon />
                            </div>
                            <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> Overall Score: </div>
                        </div>
                        <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {!hasSA(currentPoll.questions) ? totalCorrect/currentPoll.questions.length : "N/A"}</div>
                    </div>
                </div>

                <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                    <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <ResponsesIcon />
                            </div>
                            <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> Points Earned: </div>
                        </div>
                        <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {!hasSA(currentPoll.questions) ? earnedPoints : "N/A"} </div>
                    </div>
                </div>

                <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                    <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <CompletionRateIcon />
                            <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> Grade Percentage: </div>
                        </div>
                        <div tabIndex={0} role={TEXTBOX} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {!hasSA(currentPoll.questions) ? calculateScore(earnedPoints, totalPoints) + "%" : "N/A"} </div>
                    </div>
                </div>

            { !isNarrow &&
                <div className="d-flex justify-content-center" style={{marginTop: "60px", width: "275px", display: "flex", justifyContent: "center"}}>
                    {session.idpType !== IDENTITY_PROVIDER_TYPES.LMS && <div style={{display: "inline-block"}}>
                        <YuJaButton
                            style={{fontSize: 20, padding: "10px 25px"}}
                            onClick={()=>history.push(anonymous ? "/" : "/home")}
                        >
                            {anonymous ? "Login" : "Home"}
                        </YuJaButton>
                    </div> }
                    {!hasSA(currentPoll.questions) &&
                    <div style={anonymous ? {display : "none"} : {display: "inline-block", marginLeft: "25px"}}>
                        <YuJaButton
                            onClick={()=>history.push({pathname: "/student/polls", state : { pollKey : pollKey, uniqueCode: uniqueCode, results: results }})}
                            style={{fontSize: 20, padding: "10px 25px"}}
                        >
                            Gradebook
                        </YuJaButton>
                    </div>
                    }
                </div>
                }
                </div> 
                </div> 

                    {/* </Row> */}
                { isNarrow &&
                <div className="d-flex justify-content-center" style={{marginTop: "40px", width: "100%", display: "flex", flexDirection: "column", gap: '20px', justifyContent: "center", alignItems: "center"}}>
                    {session.idpType !== IDENTITY_PROVIDER_TYPES.LMS && <div style={{display: "inline-block"}}>
                        <YuJaButton
                            style={{fontSize: 20, padding: "10px 25px"}}
                            onClick={()=>history.push(anonymous ? "/" : "/home")}
                        >
                            {anonymous ? "Login" : "Home"}
                        </YuJaButton>
                    </div> }
                    {!hasSA(currentPoll.questions) &&
                    <div style={anonymous ? {display : "none"} : {display: "inline-block"}}>
                        <YuJaButton
                            onClick={()=>history.push({pathname: "/student/polls", state : { pollKey : pollKey, uniqueCode: uniqueCode, results: results }})}
                            style={{fontSize: 20, padding: "10px 25px"}}
                        >
                            Gradebook
                        </YuJaButton>
                    </div>
                    }

                    {hasSA(currentPoll.questions) &&
                                    <div className="question-title">
                                        For Poll Results, Please Contact Poll Administrator
                                    </div>
                        }
                </div>
                }
                <div style={{flex: 1}}/>
                {/* {isNarrow &&
                    <>
                        <div style={{flex: 1}}/>
                        <div style={{paddingBottom: 20}}>
                            <NetworkStatus tooltipPlacement={'top'} websocketState={websocketState}/>
                        </div>
                    </>
                } */}
            {/* </div> */}
        </Row>
    );
}