import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Spin } from "../../components/standardization/YuJaLoading";

import TableQuestionTitle from "./TableQuestionTitle";
import TableCorrectAnswersRow from "./TableCorrectResponsesRow";
import "./styles.css";
import TableAudienceResultRow from "./TableAudienceResultRow";
import EmptyTable from "./EmptyTable";
import { RESPONSE_API_URL } from "../../utils/properties";

export default function ResponsesPage() {
  const { quizPIN } = useParams();
  const [sampleAnswers, setSampleAnswers] = useState([]);
  const [responses, setResponses] = useState([]);

  // Only loading spinner works for the first fetch, rest of them will be done asynchronously
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllResponses = () => {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      fetch(
        RESPONSE_API_URL + "/poll/response/all/" + quizPIN,
        requestOptions
      )
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            setResponses(result);
            if (result.length > 0 && result[0].answersMap) {
              setSampleAnswers(result[0].answersMap);
            }

            setLoading(false);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
            setLoading(false);
          }
        );

      setTimeout(fetchAllResponses, 8000);
    };

    fetchAllResponses();
  }, [quizPIN]);

  let table;

  if (responses.length > 0) {
    table = (
      <Table striped hover size="sm" className="border ">
        <thead>
          <tr>
            <th>Username</th>
            <th className=" border-right">Score</th>
            {sampleAnswers.map((answer) => {
              return <TableQuestionTitle serialNo={answer.serialNo} key={answer.serialNo}/>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="sticky-second-header">
            <td></td>
            <td className="border-right"></td>
            {sampleAnswers.map((answer,idx) => {
              return (
                <TableCorrectAnswersRow
                  correctAnswers={answer.correctAnswers}
                  key={idx}
                />
              );
            })}
          </tr>
          {responses.map((response,idx) => {
            return <TableAudienceResultRow response={response} key={idx} />;
          })}
        </tbody>
      </Table>
    );
  } else {
    table = <EmptyTable />;
  }

  return (
    <div className="spinner-container">
      <Spin tip="Loading..." size="large" spinning={isLoading}>
        <Card id="grade-book-container" className="border">
          <Card.Body>
            <Card.Subtitle className=" header-text mb-2 text-center">
              Grade Book
            </Card.Subtitle>
            <div className="table-fix-head">{table}</div>
          </Card.Body>
        </Card>
      </Spin>
    </div>
  );
}
