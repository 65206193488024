import { React, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { Rnd } from "react-rnd";
import { ASCII_CODE_A, BLANK_REGEX, MCSSoptLower, MCSSoptUpper, POLL_TYPE, QUESTION_TYPES } from "../../utils/constants";
import { fetchImg, } from "../../utils/helpers";
import { S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME } from "../../utils/properties";
import { decodeCIParam } from "../../utils/questionUtils";
import "./styles.css";
import ArrowIcon from "../../images/arrow_right.svg"

export default function TabQuestion({question, pollType, institutionId, pollKey, forGradebook=false}) {

    const {correctAnswers, optionsMap, queTitle, serialNo, questionType, suffix, image} = question;
    const [isVideo, setIsVideo] = useState(false);

    const [questionImageURL, setQuestionImageURL] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const imageRef = useRef(null);
    // const {pollKey} = useParams();
    const TFelements = ['True', 'False'];

    let keys = null;
    if (correctAnswers) {
        keys = Object.keys(correctAnswers); 
    }
    
    if (pollType === POLL_TYPE.SURVEY) {
        keys = null;
    }

    const isCorrectAnswer = (val) => {
        return (!!pollType && pollType !== POLL_TYPE.SURVEY && !!correctAnswers && correctAnswers.includes(val)) ? "MCSS-ans-c" : "MCSS-ans";
    };

    const renderTitle = () => {
        if (questionType === QUESTION_TYPES.FITB.name) {
            return (<><span className="question-title-FITB" style={{whiteSpace: "pre-line"}}>{queTitle.replace(BLANK_REGEX, "______")}</span></>)
        }
        return (<span className="question-title">{queTitle}</span>);
    };

    useEffect(() => {
        async function fetchData() {
            if(question.directLinkEvp) {
                setQuestionImageURL(question.directLinkEvp);
                setIsVideo(true);
            }
            else if ((!!image || !!suffix) && institutionId && pollKey && serialNo) {
                let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;

                url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
                let img = await fetchImg(url);
                setQuestionImageURL(img ? img : "");
                setIsVideo(false);
            }
        }
        fetchData();
    }, [image, suffix, serialNo, institutionId, pollKey]);

    const onLoad = () => {
        setIsLoaded(true);
    }

    return (<>
        <div className={forGradebook ? "question-tab-body-gb" : "question-tab-body"}>
            <div tabIndex={0}>{renderTitle()}</div>

            {questionImageURL && (
              <div className="question-image-container" >
                <div style={{position: "relative"}}>
                    {!isVideo && <img ref={imageRef} className="question-image" src={questionImageURL} onLoad={onLoad} alt="Question" />}
                    {isVideo && <iframe allowFullScreen style={{maxWidth: "min(40vw, 720px)", maxHeight: "min(22.5vw, 405px)"}} src={questionImageURL} alt="Question" />}
                    {questionType === QUESTION_TYPES.CI.name && isLoaded &&
                        optionsMap.map((area, idx) =>
                            <Rnd
                                key={idx}
                                id={`option-area-${idx}`}
                                size={{
                                    width: decodeCIParam(area.width, imageRef.current.width),
                                    height: decodeCIParam(area.height, imageRef.current.height)
                                }}
                                position={{
                                    x: decodeCIParam(area.x, imageRef.current.width),
                                    y: decodeCIParam(area.y, imageRef.current.height)
                                }}
                                enableResizing={false}
                                disableDragging={true}
                                bounds={"parent"}
                            >
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "solid red 1.5px",
                                    fontWeight: "700",
                                    fontSize: "16px",
                                    backgroundColor: "hsla(0,0%,73.3%,.5)",
                                }}>{String.fromCharCode(ASCII_CODE_A + idx)}</div>
                            </Rnd>
                        )
                    }
                </div>
              </div>
            )}

            {questionType === QUESTION_TYPES.MCSS.name && (
                <>
                    {Object.values(optionsMap).map((value, index) => {
                        return <label tabIndex={0} key={index}
                                      className={isCorrectAnswer(MCSSoptLower[index])}>{MCSSoptUpper[index]}.&nbsp;&nbsp;{[Object.values(optionsMap)[index]]}</label>
                    })}
                </>
            )}

            {questionType === QUESTION_TYPES.TF.name && (
                <>
                    {TFelements.map((value, index) => {
                        return <label tabIndex={0} key={index}
                                      className={isCorrectAnswer(TFelements[index])}>{[TFelements[index]]}</label>
                    })}
                </>
            )}

            {questionType === QUESTION_TYPES.FITB.name && (
                <>
                    {keys ? keys.map((value, index) => {
                        return (
                            <Row key={index} className="FITB-row">
                                <div style={{width: "20%", margin: "0px", paddingRight: "0px"}}>
                                    <label tabIndex={0} key={index} className="FITB-ans-blank">{value}: </label>
                                </div>
                                <div style={{width: "80%", margin: "0px", paddingLeft: "0px"}}>
                                    {correctAnswers[value].map((value, index) => {
                                        return <span tabIndex={0} key={index} className={"FITB-ans"}>{value}</span>
                                    })}
                                </div>
                            </Row>
                        )
                    }) : <></>}
                </>
            )}

            {questionType === QUESTION_TYPES.SA.name && (
                <>
                    {correctAnswers.map((value, index) => {
                        return <label key={index} className="MCSS-ans">{value}</label>
                    })}
                </>
            )}

            {questionType === QUESTION_TYPES.CI.name && (
                <>
                    {optionsMap.map((option, index) => {
                        return (
                            <label key={index} tabIndex={0} 
                                   className={isCorrectAnswer(index)}>
                                {`${String.fromCharCode(ASCII_CODE_A + index)}.   ${option.text}`}
                            </label>
                        );
                    })}
                </>
            )}
            {questionType === QUESTION_TYPES.MH.name && (
                <div> 
                <div> 
                {correctAnswers.map((element, index) => (
                <>
                {element.length > 1 ? 
                <div key={"Correct" + index} id={"CorrectOption" + index} className="questionPanelOptionsContainer" style={{marginBottom: "0px", gap: "20px"}}> 
                    <> 
                    <div tabIndex={0} className="questionPanelAnswersText" id={"CorrectPart1" + index} style={{marginRight: "0px", border: "3px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1}}>{element[0]}</div>
                    <img
                                 src={ArrowIcon}
                                 alt="Arrow icon"
                                 className="questions-page-icons cursor-pointer"
                                 style={{ width: "30px", margin: "0px 0px 0px 10px" }}
                             />
                    </> 
                    <div tabIndex={0} className="questionPanelAnswersText" id={"CorrectPart2" + index} style={{border: "3px solid green", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1}}>{element[1]}</div>
                </div>
                : null }
                </>
            ))}
            </div>
            </div> 
                
            )
            }

        </div>
    </>);
}
