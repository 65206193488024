import styles from "./Home.module.css";
import stylesDashboard from "./../DashboardPage/DashboardPage.module.css";
import { ReactComponent as EnrolledCourseIcon } from "./images/enrolled_courses_icon.svg";
import { ReactComponent as NoCoursesIcon } from "./images/no_courses_icon.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {React, useState, useEffect} from "react";
import useMobileAccess from "../../hooks/useMobileAccess";
import { COURSE_CODE_LABEL, COURSE_LABEL, TEXTBOX } from "../../utils/constants";

export default function EnrolledCourses({courseOptions=[], loading=true, initialized=false}) {
    const isMobile = useMobileAccess(950);


    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    return (
        <div className={stylesDashboard.panelContainer} style={{height: "29%", marginTop: "30px"}}>
            <div tabIndex={0} className={stylesDashboard.panelTitle} role={TEXTBOX}>
                <EnrolledCourseIcon style={{marginRight: "10px"}}/> Enrolled Courses
            </div>
            {!loading ? 
            <div className={stylesDashboard.panelBody}>
                {/* <Spin size={isMobile? "medium" : "large"} wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}> */}
                {initialized && !!courseOptions && Object.entries(courseOptions).length === 0 && 
                    <div style={{display: "flex",  justifyContent: "center", alignItems: "center"}}>
                        <NoCoursesIcon className={stylesDashboard.enrolledCourseIcon} style={{marginRight: "10px"}}/>
                        There are currently no course enrollments
                    </div>
                }
                {initialized && !!courseOptions && Object.entries(courseOptions).length > 0 &&
                    <div className={stylesDashboard.modifiedPollContainer}>
                        {courseOptions.map((course, index) => (
                            <div key={index} className={styles.courseTitle}>
                                <p tabIndex={0} aria-label={COURSE_LABEL + course.courseName} className={styles.courseTitle}>{course.courseName}</p>
                                <p tabIndex={0} aria-label={COURSE_CODE_LABEL + course.courseCode} className={styles.gradientText}>{course.courseCode}</p>
                            </div>
                        ))}
                    </div>
                }
                {/* </Spin> */}
            </div>
            : windowSize[0] >= 960 && windowSize[1] <= 770 ? 
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton height = {14.56005859375} width = "8vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton height = {14.56005859375} width = "5vw"  style={{ position: "relative", marginTop:"1vh"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"1vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "6vw"  style={{ position: "relative", marginTop:"1vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"1vh"}} />
            </div>
            </SkeletonTheme> </div>
            
            : windowSize[0] >= 960 && windowSize[1] <= 900 ? 
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton height = {14.56005859375} width = "8vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton height = {14.56005859375} width = "5vw"  style={{ position: "relative", marginTop:"1vh"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"1vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "6vw"  style={{ position: "relative", marginTop:"1vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"1vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"1vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"1vh"}} />
            </div>
            </SkeletonTheme> </div>
            :
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton height = {14.56005859375} width = "8vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton height = {14.56005859375} width = "5vw"  style={{ position: "relative", marginTop:"1vh"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"1vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "6vw"  style={{ position: "relative", marginTop:"1vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"1vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"1vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"1vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "9vw" style={{ position: "relative", marginTop:"1vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"1vh"}} />
            </div>
            </SkeletonTheme> </div>}
        </div>
    );
}
