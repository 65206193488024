import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import FormModal from "../../components/modals/FormModal";
import GradientButton from "../../public/buttons/GradientButton";
import { ACTIVATE_BUTTON, POLL_TYPE, TEXTBOX } from "../../utils/constants";
import { removePrefix } from "../../utils/helpers";
import { timePollCustomToSeconds } from './../../utils/questionUtils';
import styles from "./DashboardPage.module.css";
import { ReactComponent as ModifiedPollIcon } from "./images/gradient_history_icon.svg";
import { ReactComponent as NoResultIllustration } from "./images/no_modified_polls_found_illustration.svg";

export default function CreatorModifiedPolls({data=[], courseOptions=[], loading=true, initialized=false}) {
    const { publishPoll} = PollApiCalls();
    const history = useHistory();

    const [pollKey, setPollKey] = useState("");
    const [pollType, setPollType] = useState("");
    const [courseModalShow, setCourseModalShow] = useState(false);
    const [selectedCourseOpts, setSelectedCourseOpts] = useState([]);
    const [ shareAll, setShareAll ] = useState(0);
    const [ pollTimeLimit, setPollTimeLimit ] = useState("00:30:00");


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
 

    const clearForm = () => {
        setCourseModalShow(false);
        setPollTimeLimit("00:30:00");
        setSelectedCourseOpts([]);
        setShareAll(false);
        setStartDate(""); 
        setEndDate("");
    }

    const handlePublishFromModal = async () => {
        let body = {};
        let res;
        body.pollKey = removePrefix(pollKey);
        body.pollType = pollType;
        if (selectedCourseOpts.length !== 0) {
            body.courseId = selectedCourseOpts.value;
            body.courseCode = selectedCourseOpts.label.substring(0, selectedCourseOpts.label.indexOf(":")-1);
        }
        body.pollShareMode = shareAll === 2 ? "schedule" : shareAll === 1 ? "shareAll" : "shareEach";
        if (shareAll === 2 && startDate) {
            body.startTime = startDate;
        }
        if(shareAll === 2 && endDate) {
            body.endTime = endDate;
        }
        if (shareAll && pollType === "Graded Poll") {
            body.pollTimeLimit = timePollCustomToSeconds(pollTimeLimit);
        }
        console.log(body);
        res = await publishPoll(body);
        if (res) {
            const { uniqueCode } = res;
            const trimmedUniqueCode = removePrefix(uniqueCode);
            history.push(`/poll/share/${removePrefix(pollKey)}/${trimmedUniqueCode}`);
        }
    }

    const onGo = async (pollKey) => {
        setPollKey(pollKey);
        setCourseModalShow(true);
    }


    const modalConfig = {
        title: `Activate ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"}`,
        submitButtonText: "Activate",
        clearForm: clearForm,
        submit: handlePublishFromModal,
        activatePollType: pollType,

        form: [
            {
                label: `Would you like to activate the ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "survey" : "poll"} to a course?`,
                subOptions: ["Activate without a course", "Activate within a course"],
                value: selectedCourseOpts,
                options: courseOptions,
                isMulti: false,
                setFunction: setSelectedCourseOpts,
                type: "select2",
            },
            {
                label: `How would you like to release the ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "survey" : "poll"}?`,
                options: ["Activate Each Question Individually", `Activate the Entire ${pollType.toUpperCase() === POLL_TYPE.SURVEY ? "Survey" : "Poll"}`],
                isMulti: false,
                type: "select3"
            },
        ],
    };

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);
    

    return (
        <div className={styles.panelContainer} style={{height: "56%"}}>
            <div className={styles.panelTitle}>
                <ModifiedPollIcon style={{marginRight: "15px"}}/>
                <div className={styles.panelSubTitle}>
                    <div role={TEXTBOX} tabIndex={0}>Recent Polls</div>
                    <div role={TEXTBOX} tabIndex={0} style={{fontSize: "13px"}}>To activate a recently modified poll, select the 'Activate' button</div>
                </div>
            </div>
            {!loading ? 
            <div className={styles.panelBody}>
                {courseModalShow && <FormModal
                    show={courseModalShow}
                    setModalShow={setCourseModalShow}
                    modalConfig={modalConfig}
                    pollTimeLimit={pollTimeLimit}
                    setPollTimeLimit={setPollTimeLimit}
                    setShareAll={setShareAll}
                    shareAll={shareAll}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />}
                {/* <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}> */}
                    {initialized && (!data || !data.length) &&
                        <>
                            <NoResultIllustration style={{maxWidth: "100%", maxHeight: "100%", marginRight: "15px"}}/>
                            No recent modified polls found
                        </>
                    }
                    {initialized && (!!data && !!data.length) &&
                        <div className={styles.modifiedPollContainer}>
                            {JSON.parse(JSON.stringify(data)).splice(0, 6).map((poll, index) => (
                                <div key={index} className={styles.modifiedPollUnit} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                    <span tabIndex={0} role={TEXTBOX} className={styles.omitableText}>{poll.pollTitle}</span>
                                    <GradientButton role={TEXTBOX} label={ACTIVATE_BUTTON + poll.pollTitle} style={{marginLeft: "15px"}} onClick={() => {setPollType(poll.pollType); onGo(poll.pollKey)}}>
                                        Activate
                                    </GradientButton>
                                </div>
                            ))}
                        </div>
                    }
                {/* </Spin> */}
            </div>
            : windowSize[1] >= 915 ? 
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton height = {14.56005859375} width = "8vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton height = {14.56005859375} width = "5vw"  style={{ position: "relative", marginTop:"3vh"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "6vw"  style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "9vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "8vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div> 
            </SkeletonTheme> </div> 
            : windowSize[1] >= 800 ? 
            <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton height = {14.56005859375} width = "8vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton height = {14.56005859375} width = "5vw"  style={{ position: "relative", marginTop:"3vh"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "6vw"  style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "9vw" style={{ position: "relative", marginTop:"3vh"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"3vh"}} />
            </div> 
            </SkeletonTheme> </div> 
            : <div style = {{marginLeft:"25px", marginRight: "25px", display: "grid", gridTemplateColumns: "auto auto"}}> 
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
            <Skeleton height = {14.56005859375} width = "8vw" style = {{position: "relative", marginTop:"25px"}} />
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"25px"}} />
            </div>
            <Skeleton height = {14.56005859375} width = "5vw"  style={{ position: "relative", marginTop:"20px"}}/> 
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"20px"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "6vw"  style={{ position: "relative", marginTop:"20px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"20px"}} />
            </div>
            <Skeleton height = {14.56005859375}  width = "7vw" style={{ position: "relative", marginTop:"20px"}}/>
            <div style = {{display: "flex", justifyContent:"flex-end"}}> 
            <Skeleton height = {10} width = "3vw" style = {{position: "relative", marginTop:"20px"}} />
            </div></SkeletonTheme> </div> }
        </div>
    );
}