import { Spin } from "../../components/standardization/YuJaLoading";
import "antd/dist/antd.min.css";
import 'rc-datepicker/lib/style.css';
import React from "react";
import { Form, Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { MODAL_CLOSE_TEXT } from "../../utils/constants";
import "./modal.css";
import YuJaButton from "../standardization/YuJaButton";

export default function GradebookWarningModal({show, setModalShow, handleExportFromModal}) {
    const isLoading = false;

    const handleClose = () =>{
        setModalShow(false)
        modalConfig.clearForm();
    };

    const clearForm = () => {
        setModalShow(false);
    }

    const modalConfig = {
        title: "Important: Gradebook Sync Disabled!",
        submitButtonText: "Export Anyways",
        clearForm: clearForm,
        submit: handleExportFromModal,

        form: [
            {
                label: "Once you import the csv file to your course gradebook in the LMS, Engage will not send any more grade results.",
                isMulti: false,
                type: "warning",
            },
        ],
    };

    return (
    <Modal
      id="form-modal"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={isLoading ? "static" : true}
    >
        <Spin tip="Loading..." size="large" spinning={isLoading}>
            <ModalClose onClick={handleClose} className="modal-close" />
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title
                    className="warning-title"
                    id="contained-modal-title-vcenter"
                >
                    {modalConfig.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="customized-form" onSubmit={e => e.preventDefault()}>
                    {modalConfig.form.map((formItem, idx) => {
                        return (
                            <Form.Group style={{ marginBottom: "0.938rem" }} key={idx}>
                                <div className="form-check-label">{formItem.label}</div>
                            </Form.Group>
                        );
                    })}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton onClick={handleClose} type={"secondary"}>
                    {MODAL_CLOSE_TEXT}
                </YuJaButton>
                <YuJaButton
                    onClick={(e) => {
                    e.preventDefault();
                        modalConfig.submit();
                    }}
                >
                    {modalConfig.submitButtonText}
                </YuJaButton>
            </Modal.Footer>
        </Spin>
    </Modal>)
}
