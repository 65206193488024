import { React, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useCookie } from "../../hooks/useCookie";
import useHostname from "../../hooks/useHostname";
import useMobileAccess from "../../hooks/useMobileAccess";
import { INVALID_POLL_CODE_ERROR, JOIN_POLL, POLL_CODE_LENGTH,  REQUIRED_FIELD_ERROR, SCAN_QR, EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE, VIEWER_USER_TYPE, POLL_DISABLE_ANONYMOUS_WARNING, LOGIN_PAGE_FOOTER_TEXT, LOGIN_BUTTON_TEXT, WELCOME_YUJA_ENGAGE, JOIN_POLL_DESCRIPTION, YUJA_SUPPORT_LINK, JOIN_POLL_MODAL_NAME_REQUIRED_LABEL, JOIN_POLL_MODAL_NAME_OPTIONAL_LABEL, LOGIN_PATH} from "../../utils/constants";
import { fetchImg, formatErrorLog, newPageLog, notifyError } from "../../utils/helpers";
import { USER_ACCESS_POLL_ERROR } from "../../utils/toast-message-constants";
import { S3_PUBLIC_BUCKET_API_URL } from "../../utils/properties";
import defaultImage from "./images/main.jpg";
import Logo from "./images/yujalogo.svg";
import "./styles.css";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import YuJaButton from "../../components/standardization/YuJaButton";
import { JOIN_COURSE_POLL_ANONYMOUSLY_ERROR } from "../../utils/toast-message-constants";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import {ReactComponent as GoBack} from "../../images/goBack.svg";
import { CourseApiCalls } from "../../apis/CourseApiCalls";

export default function GuestLoginPage() {
  const { setUserNameToLocalStorage, getPollRecordFromUniqueCode} = ResponseApiCalls();
  const { checkMemberAccess } = CourseApiCalls();
  const [name, setName] = useState("");
  const [setCookie, , ] = useCookie("name", "Guest");
  let history = useHistory();
  let errorRed = "0px 0px 2px 2px #C93E3E";
  const isMobile = useMobileAccess(480);
  const isNarrow = useMobileAccess(950);
  const hostResource = useHostname(window.location.hostname);
  const [imgUrl, setImgUrl] = useState("");
  const [inputCodeInvalid, setInputCodeInvalid] = useState(false);

  const [setWarning, ] = useLocalStorageNew("warning", {});

  const [pollCode, setPollCode] = useState(['', '', '', '', '', '']);
  const [isNameRequired, setIsNameRequired] = useState(false);
  const [scanQRRequired, setScanQRRequired] = useState(true);
  const [showLoginJoin, setShowLoginJoin] = useState(false);
  const [, getSession] = useLocalStorageNew("session", {});
  const session = getSession(); 

  useEffect(() => {
    async function fetchData() {
      const { bgImgLocation = "" } = hostResource;
      let img;
      if (bgImgLocation) {
        img = await fetchImg(`${S3_PUBLIC_BUCKET_API_URL}/s3?key=${bgImgLocation}`);
      }
      setImgUrl(img ? img : defaultImage);
    }
    fetchData();
  }, [hostResource]);

  useEffect(() => {
    newPageLog("GuestLoginPage.js");
  }, [])

  const checkAuthorization = async(pollUniqueCode) => {
    const result = await getPollRecordFromUniqueCode(pollUniqueCode);
    if (!result || !result.poll) {
      notifyError(INVALID_POLL_CODE_ERROR);
      formatErrorLog(INVALID_POLL_CODE_ERROR);
      return;
    }

    const {index2Pk: courseId, lmsHomeUrl} = result.poll;
    if (!!lmsHomeUrl && !!session.gradedLink) {
      history.push(`/join/${pollUniqueCode}`);
      return
    } else if(!!lmsHomeUrl) {// if it's a LMS embedded poll and current user is not LMS user then redirect to guide page
      formatErrorLog(EMBEDDED_POLL_GUIDE_REDIRECT_LOG_MESSAGE);
      history.push(`/lti/${pollUniqueCode}/guide`);
      return;
    }

    if (session.authorization && session.userId) {
      if (await checkUserAccess(courseId)) {
          setWarning(false);
          setUserNameToLocalStorage(pollUniqueCode, session.userId, VIEWER_USER_TYPE.LOGGED_IN);
          history.push(`/join/${pollUniqueCode}`);
        }
    }
  }

  const checkUserAccess = async(courseId) => {
    if (courseId == null) {
        return true;
    }

    const accessibleResult = await checkMemberAccess(courseId.substring(2));
    console.log(accessibleResult)
    if (accessibleResult == null || accessibleResult.result == null || !accessibleResult.result) {
        notifyError(USER_ACCESS_POLL_ERROR);
        formatErrorLog("User cannot access the poll");
        return false; 
    }

    return true;
  }

  const validateCode = (code, warning=true) => {
    let returnVal = true;
    setInputCodeInvalid(false);
    if (!code) {
      if (warning) {
        document.getElementById("pErr").textContent = REQUIRED_FIELD_ERROR;
        setInputCodeInvalid(true);
      }
      returnVal = false;
    } else if (code.length !== POLL_CODE_LENGTH) {
      if (warning) {
        document.getElementById("pErr").textContent = INVALID_POLL_CODE_ERROR;
        setInputCodeInvalid(true);
      }
      returnVal = false;
    }
    if (!returnVal && warning) {
      const codeInputs = document.getElementsByName("guest-login-poll-input");
      for (const element of codeInputs) {
        element.style.boxShadow = errorRed;
      }
    }
    return returnVal;
  };

  const handleClick = (index, event) => {
    let inputValue = event.target.value;

    // Remove non-numeric characters
    inputValue = inputValue.replace(/[^0-9]/g, '');

    // Update the state
    const newPollCode = [...pollCode];
    newPollCode[index] = inputValue;
    setPollCode(newPollCode);
    if (inputValue.trim() === "") {
      return;
    }

    if (document.getElementById(`poll-code-input-${index + 1}`) !== null) {
      document.getElementById("poll-code-input-" + (index + 1)).select();
    }
}

  const handleJoin = async () => {
    let code = "" + document.getElementById("poll-code-input-0").value
        + document.getElementById("poll-code-input-1").value
        + document.getElementById("poll-code-input-2").value
        + document.getElementById("poll-code-input-3").value
        + document.getElementById("poll-code-input-4").value
        + document.getElementById("poll-code-input-5").value;


    if (!validateCode(code)) {
     return;
    }

    const result = await getPollRecordFromUniqueCode(code);
    if (!result || !result.poll) {
      notifyError(INVALID_POLL_CODE_ERROR);
      formatErrorLog(INVALID_POLL_CODE_ERROR);
      return false;
    }

    const {index2Pk: courseId} = result.poll;
    if (!!courseId) {
      formatErrorLog("This poll cannot be accessed anonymously!");
      notifyError(JOIN_COURSE_POLL_ANONYMOUSLY_ERROR);
      return false;
    }


    let nameTrimmed = name.trim();
    if (result.poll.hasOwnProperty("anonymousJoin") &&  result.poll["anonymousJoin"] === true && nameTrimmed === "") {
      notifyError(POLL_DISABLE_ANONYMOUS_WARNING);
      return false;
    }

    if (name) {
      setCookie(name);
      setUserNameToLocalStorage(code, name, VIEWER_USER_TYPE.MANUAL_GUEST);
    } else {
      setUserNameToLocalStorage(code);
    }

    history.push(`/join/${code}`);
  };

  const handleScan = async () => {
    history.push(`/scan`);
  };

  const handleName = (event) => {
    setName(event.target.value);
  }

  const handleChange = (event) => {
    if (event.keyCode === 13) {
      handleJoin();
      return;
    }
    const index = parseInt(event.target.id.substring("poll-code-input-".length));
    if (event.keyCode === 8) {//backspace
      if (document.getElementById(`poll-code-input-${index - 1}`) !== null) {
        document.getElementById("poll-code-input-" + (index - 1)).select();
      }
      return
    }
  };

  const loginJoin = async () => {
    let code = "" + document.getElementById("poll-code-input-0").value
        + document.getElementById("poll-code-input-1").value
        + document.getElementById("poll-code-input-2").value
        + document.getElementById("poll-code-input-3").value
        + document.getElementById("poll-code-input-4").value
        + document.getElementById("poll-code-input-5").value; 
    
    if (!validateCode(code)) {
          return;
    }
    const result = await getPollRecordFromUniqueCode(code);
    if (!result || !result.poll) {
      notifyError(INVALID_POLL_CODE_ERROR);
      formatErrorLog(INVALID_POLL_CODE_ERROR);
      return;
    }

    setWarning(false);
    history.push("/", {
      from: `/join/${code}`,
      pollCode: code,
    });
  };

  const anonJoin = async (pollUniqueCode) => {
    const result = await getPollRecordFromUniqueCode(pollUniqueCode);
    if (!result || !result.poll) {
      return;
    }

    const {index2Pk: courseId} = result.poll;
    if (!!courseId) {
      return;
    } else {
      setIsNameRequired(result.poll && result.poll.hasOwnProperty("anonymousJoin") && result.poll["anonymousJoin"] === true)
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    console.log(currentUrl);
    const code = currentUrl.split('/').pop();

    if (code && code !=="qr") {
      setPollCode(code);
    }
    if (validateCode(code, false)) {
      anonJoin(code);
      setScanQRRequired(false);
      setShowLoginJoin(true);
      checkAuthorization(code);
    }
  }, [])


  return (
    <>
      <Row style={{height: "100%", width: "100%", margin: 0, padding: 0}}>
        <div className="left-login-wrapper" style={{backgroundImage: !isMobile && isNarrow ? `url(${imgUrl})` : ""}}>
            <Container className="guest-login-page-container" style={{display: "flex", flexDirection: "column"}}>
            {scanQRRequired && <GoBack style={{marginLeft: "18px", cursor: "pointer"}} onClick={() => {window.location.href = LOGIN_PATH}}/> }

              <Row><Col><span className="d-flex justify-content-center"><img src={Logo} className="guest-login-logo" alt="YuJa Logo" /></span></Col></Row>

              <Row style={{margin: "2% 0% 5% 0%"}}><Col><span className="guest-login-title d-flex justify-content-center" style={{fontSize: "18px", color: "#000"}}>{WELCOME_YUJA_ENGAGE}</span></Col></Row>

              <Row><Col><span className="guest-login-subtitle d-flex justify-content-center">{JOIN_POLL_DESCRIPTION}</span></Col></Row>

              <Container style={{padding: "0px", width: !isMobile && isNarrow ? "60%" : "90%"}}>
                <Form className="name-cont">
                  <label className="guest-login-input-title" style={{paddingLeft: 0, paddingRight: 0}}>{isNameRequired ? JOIN_POLL_MODAL_NAME_REQUIRED_LABEL : JOIN_POLL_MODAL_NAME_OPTIONAL_LABEL}</label>
                  <YuJaTextBox value={name} onChange={handleName}/>
                </Form>

                <Form className="poll-cont">
                  <label className="guest-login-input-title" style={{paddingLeft: 0, paddingRight: 0}}>Poll Code</label>
                  <div className="input-code-container">
                    <YuJaTextBox id="poll-code-input-0" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ?"": isNarrow? "50px": "65px", maxHeight: isMobile ? "": isNarrow ? "60px" : "70px" }} isInvalid={inputCodeInvalid} inputAlign={"center"} inputMode="numeric" maxLength="1"  useValue = {true} value={pollCode[0]} onChange={(e) => {handleClick(0, e)}} onKeyUp={(e) => {handleChange(e)}}/>
                    <YuJaTextBox id="poll-code-input-1" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px",maxWidth: isMobile ? "": isNarrow? "50px": "65px", maxHeight: isMobile ? "" : isNarrow? "60px": "70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[1]} onChange={(e) => {handleClick(1, e)}} onKeyUp={(e) => {handleChange(e)}}/>
                    <YuJaTextBox id="poll-code-input-2" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ? "": isNarrow? "50px": "65px", maxHeight: isMobile ? "" : isNarrow? "60px" :"70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[2]}  onChange={(e) => {handleClick(2, e)}} onKeyUp={(e) => {handleChange(e)}}/>
                    <YuJaTextBox id="poll-code-input-3" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ? "": isNarrow? "50px": "65px", maxHeight: isMobile ? "" : isNarrow? "60px" :"70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"} inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[3]} onChange={(e) => {handleClick(3, e)}} onKeyUp={(e) => {handleChange(e)}}/>
                    <YuJaTextBox  id="poll-code-input-4" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ? "": isNarrow? "50px" : "65px", maxHeight: isMobile? "" : isNarrow? "60px" :"70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[4]} onChange={(e) => {handleClick(4, e)}} onKeyUp={(e) => {handleChange(e)}}/>
                    <YuJaTextBox  id="poll-code-input-5" containerStyle={{fontSize: 20, width: isMobile ? '10vw' : isNarrow ? "6vw": '3.5vw', height: isMobile ? '12vw' : isNarrow ? "8vw" : '4vw', padding: "12px 5px", maxWidth: isMobile ? "": isNarrow? "50px": "65px", maxHeight: isMobile ? "" : isNarrow ? "60px" : "70px"}} isInvalid={inputCodeInvalid} inputAlign={"center"}  inputMode="numeric" maxLength="1" useValue = {true} value={pollCode[5]} onChange={(e) => {handleClick(5, e)}} onKeyUp={(e) => {handleChange(e)}}/>
                  </div>
                  <Row><p id="pErr" className="err" /></Row>
                </Form>

                <div className="d-flex justify-content-center">
                  <YuJaButton onClick={handleJoin} style={{width: '100%', padding: '15px', backgroundColor: "#42296E", color: "#FFF", fontSize: "18px"}}>{JOIN_POLL}</YuJaButton>
                </div>
                {scanQRRequired &&
                <>
                <div className="or-line">
                  OR
                </div>
                <div className="d-flex justify-content-center" style={{fontSize: "18px", fontFamily: "Geomanist", color: "#42296E"}}>
                  You can Also <div onClick={handleScan} style={{textDecorationLine: "underline", marginLeft: "4px"}}>{SCAN_QR}</div>
                </div>
                </>
                }

                {showLoginJoin && <div style={{textDecorationLine: "underline", fontSize: "18px", fontFamily: "Geomanist", color: "#42296E", marginTop: "20px", textAlign: "center", cursor: "pointer"}} onClick={() => {loginJoin()}} > {LOGIN_BUTTON_TEXT} </div> }

              </Container>
              <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: isNarrow ? "center" : "left",flex: "1" }}>
                <div style={{flex: "1"}}> </div>
              <p className="guest-login-issues d-flex justify-content-center" style={{fontSize: "14px", textAlign: "center"}}>Having Issues? Contact&nbsp;<b onClick={() => window.open(YUJA_SUPPORT_LINK)} style={{cursor: "pointer"}}>YuJa Support</b></p>
                <p className="mobile-login-footer" style={{ fontSize: "14px", fontFamily: "Geomanist", color: "#000", width: "100%" , textAlign: "center" }}>{LOGIN_PAGE_FOOTER_TEXT}</p>
              </div>


            </Container>
          </div>
        {!isNarrow && (
          <div className="right-img-wrapper">
            <div style={{backgroundImage: `url(${imgUrl})`}} className="bg-img-container"/>
          </div>
        )}
      </Row>
    </>
  );
}
