import {DatePickerInput} from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import {useState, useRef} from "react";
import {Form} from "react-bootstrap";
import {notifyError} from '../../utils/helpers';
import {LOADING_SEARCH_ERROR} from '../../utils/toast-message-constants';
import styles from "./ManagePolls.module.css";
import {
    ACTIVATED_STATUS, ALL_MODE, ALL_STATUS, DRAFT_STATUS,COURSE_STATUS,  END_DATE, GRADED_MODE, SEARCH_TEXT, START_DATE, SURVEY, SEARCH_BOX_PLACEHOLDER, SEARCH_POLL, START_DATE_LABEL, END_DATE_LABEL, ALL_MODE_LABEL, ALL_STATUS_LABEL
} from '../../utils/constants';
import YuJaButton from "../../components/standardization/YuJaButton";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";

import {ReactComponent as SearchIcon} from "../../images/search_bar_icon.svg";

import ModeFilterDropDown from '../../components/ModeFilterDropDown';
import StatusFilterDropDown from '../../components/StatusFilterDropDown';
import "./styles.css";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import {useWindowDimensions} from "react-native-web";

export default function PollSearchForm({handleSubmit, disabled = false}) {
    const [keyword, setKeyword] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [modeFilter, setModeFilter] = useState("All Mode");
    const [statusFilter, setStatusFilter] = useState("All Status");


    const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1200);
    const {width} = useWindowDimensions();
    const datepickerRef = useRef(null);
    const datepickerRefEnd = useRef(null);

    const clickSearch = () => {
        if (disabled) {
            notifyError(LOADING_SEARCH_ERROR);
            return;
        }
        let formattedStartDate = startDate ? startDate.split("-").reverse().join("-") : "";
        let formattedEndDate = endDate ? endDate.split("-").reverse().join("-") : "";
        handleSubmit({
            keyword: keyword,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            mode: modeFilter,
            status: statusFilter
        });
    }

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch();
        }
    }


    return (
        <div style={{minWidth: "80%", width: "fit-content"}}>
            {!smallScreen ?
                (
                    <div className={styles.searchBox}>
                        <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}
                              style={{paddingLeft: "10px", paddingRight: "10px"}}>
                            <YuJaTextBox
                                label={SEARCH_POLL}
                                containerStyle={{minWidth: 250, fontSize: 13, flex: 1}}
                                placeholder={SEARCH_BOX_PLACEHOLDER}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyUp={enterKeyup}
                                value={keyword}
                                before={<SearchIcon style={{marginRight: 5, width: 20, height: 20}}/>}
                            />

                            <DatePickerInput
                                ref={datepickerRef}
                                displayFormat='DD-MM-YYYY'
                                style={{padding: "20px", minWidth: "160px"}}
                                placeholder={START_DATE}
                                onChange={value => {
                                    if (!isNaN(value)) {
                                        setStartDate(value.toISOString().split('T')[0]);
                                    } else {
                                        setStartDate("");
                                    }
                                }}
                                value={startDate}
                                aria-label={START_DATE_LABEL}
                                onFocus={() => datepickerRef.current?.show()}
                                onBlur={() => datepickerRef.current?.hide()}
                            />
                            <DatePickerInput
                                ref={datepickerRefEnd}
                                displayFormat='DD-MM-YYYY'
                                style={{padding: "20px", minWidth: "160px"}}
                                placeholder={END_DATE}
                                onChange={value => {
                                    if (!isNaN(value)) {
                                        setEndDate(value.toISOString().split('T')[0]);
                                    } else {
                                        setEndDate("");
                                    }
                                }}
                                value={endDate}
                                aria-label={END_DATE_LABEL}
                                onFocus={() => datepickerRefEnd.current?.show()}
                                onBlur={() => datepickerRefEnd.current?.hide()}
                            />
                            <YuJaDropdown
                                ariaLabel={modeFilter + ALL_MODE_LABEL}
                                data={[ALL_MODE, GRADED_MODE, SURVEY]}
                                getOptionLabel={item => item}
                                getOptionValue={item => item}
                                onChange={option => setModeFilter(option)}
                                value={modeFilter}
                                containerStyle={{marginRight: 15, width: 130}}
                                style={{padding: "8px 15px"}}
                            />

                            <YuJaDropdown
                                ariaLabel={statusFilter + ALL_STATUS_LABEL}
                                data={[ALL_STATUS, DRAFT_STATUS, ACTIVATED_STATUS, COURSE_STATUS]}
                                getOptionLabel={item => item}
                                getOptionValue={item => item}
                                onChange={option => setStatusFilter(option)}
                                value={statusFilter}
                                containerStyle={{marginRight: 15, width: 110}}
                                style={{padding: "8px 15px"}}
                            />

                            {/*<ModeFilterDropDown modeFilter={modeFilter} setModeFilter={setModeFilter}/>*/}

                            {/*<StatusFilterDropDown statusFilter={statusFilter} setStatusFilter={setStatusFilter}/>*/}

                            <YuJaButton onClick={clickSearch}>Search</YuJaButton>
                        </Form>
                    </div>
                ) : (
                    <div style={{display: "flex", flexDirection: "column", height: "fit-content"}} className={styles.searchBox}>
                        <Form
                            className={styles.searchBoxForm}
                            onSubmit={e => e.preventDefault()}
                            style={{paddingLeft: "5px", paddingRight: "5px"}}
                        >
                            <YuJaTextBox
                                containerStyle={{minWidth: 250, width: "auto", fontSize: 13}}
                                placeholder={SEARCH_BOX_PLACEHOLDER}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyUp={enterKeyup}
                                value={keyword}
                                before={<SearchIcon style={{marginRight: 5, width: 20, height: 20}}/>}
                            />

                            <DatePickerInput
                                displayFormat='DD-MM-YYYY'
                                style={{minWidth: "160px"}}
                                placeholder={START_DATE}
                                onChange={value => {
                                    if (!isNaN(value)) {
                                        setStartDate(value.toISOString().split('T')[0]);
                                    } else {
                                        setStartDate("");
                                    }
                                }}
                                value={startDate}
                            />
                            <DatePickerInput
                                displayFormat='DD-MM-YYYY'
                                style={{minWidth: "160px"}}
                                placeholder={END_DATE}
                                onChange={value => {
                                    if (!isNaN(value)) {
                                        setEndDate(value.toISOString().split('T')[0]);
                                    } else {
                                        setEndDate("");
                                    }
                                }}
                                value={endDate}
                            />
                        </Form>
                        <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}
                              style={{marginBottom: "5px", paddingLeft: "5px", paddingRight: "5px"}}>
                            <ModeFilterDropDown modeFilter={modeFilter} setModeFilter={setModeFilter}/>

                            <StatusFilterDropDown statusFilter={statusFilter} setStatusFilter={setStatusFilter}/>

                            <YuJaButton onClick={clickSearch}>Search</YuJaButton>
                        </Form>
                    </div>
                )
            }
        </div>
    );
}