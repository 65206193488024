import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useGlobalFilter, usePagination, useRowSelect, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import { CHECKBOX_COLUMN_ID, DEFAULT_ROWS_PER_PAGE, OPERATION_LABEL, ROLES, ROLE_LABEL, TEXTBOX } from "../../utils/constants";
import styles from "./ManageUserPage.module.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import {YuJaKebabMenu} from "../../components/standardization/YuJaKebabMenu";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
        return (
            <input className={"form-check-input"} type="checkbox" aria-label="checkbox" ref={resolvedRef} {...rest} />
        )
    }
)

export function UserTableNew({ data = [], globalFilterObj, columns = [], pageIdx=0, handleSelectUserId, openUpdateUserModal, clickDelete, handleDeleteSelected }) {

    const [hideDeleteSelectedBtn, setHideDeleteSelectedBtn] = useState(true);
    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        const filteredDate = globalFilterValue.date;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.userName && values.userName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }
            else if (values.firstName && values.firstName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true; 
            }
            else if (values.lastName && values.lastName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true 
            }
            else if (values.email && values.email.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true; 
            }
            return false;
        }).filter(r => {
            if (!filteredDate) {
                return true;
            }
            const values = r.values;
            return values.updatedDatePK && values.updatedDatePK === filteredDate;
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: pageIdx, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: CHECKBOX_COLUMN_ID,
                    // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    ),
                    width: 50,
                },
                ...columns,
            ])
        }
    );

    useEffect(() => {
        const map = selectedFlatRows.map(r => r.original.userId + "_" + r.original.institutionId);
        if(selectedFlatRows.length > 0) {
            setHideDeleteSelectedBtn(false);
        } else {
            setHideDeleteSelectedBtn(true);
        }
        handleSelectUserId(map, pageIndex);
    }, [selectedFlatRows.length]);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]);


    useEffect(() => {
        if (!pageOptions.length) {
            gotoPage(0);
            return;
        }

        if (pageIdx < pageOptions.length) {
            gotoPage(pageIdx);
        } else {
            gotoPage(pageIdx - 1);
        }

    }, [data, pageIdx, pageOptions]);

    const handleClick = (index) => {
        document.getElementById(`menu-${index}`).style.display = "none";
        document.getElementById(`options-${index}`).style.display = "inline";
    };

    return (
        <> 
            <div className={styles.tableContainer}>
                {!hideDeleteSelectedBtn &&
                    <div className={styles.deleteSelectedBtn}>
                        <YuJaButton type="delete"  onClick={handleDeleteSelected} >
                            Delete Selected
                        </YuJaButton>
                    </div>
                }
                <Table responsive hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <td tabIndex={column.id === CHECKBOX_COLUMN_ID ? -1 : 0} {...column.getHeaderProps({style: {
                                            width: column.width,
                                            minWidth: column.minWidth
                                        }})}
                                        className={column.id === CHECKBOX_COLUMN_ID ? styles.checkboxCell : styles.otherHeaderCell}
                                    >
                                        {column.render('Header')}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row, rowIdx) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        if (cell.column.Header === 'Operation') {
                                            return (
                                                <td style={{ textAlign: "center" }} {...cell.getCellProps()} >
                                                    <div tabIndex={0} aria-label={OPERATION_LABEL} role={TEXTBOX} style={{display: "flex", justifyContent: "center"}}>
                                                        <YuJaKebabMenu>
                                                            <YuJaKebabMenu.Menu>
                                                                <YuJaKebabMenu.Item onClick={() => openUpdateUserModal(row.original, pageIndex)}>Update</YuJaKebabMenu.Item>
                                                                <YuJaKebabMenu.Item onClick={() => clickDelete(row.original.userId, row.original.institutionId, pageIndex)}>Delete</YuJaKebabMenu.Item>
                                                            </YuJaKebabMenu.Menu>
                                                        </YuJaKebabMenu>
                                                    </div>
                                                </td>
                                            );
                                        }
                                        else if (cell.column.Header === 'Role') {
                                            return (
                                                <td tabIndex={0} aria-label={ROLE_LABEL + cell.value} {...cell.getCellProps()}>
                                                    {ROLES[cell.value] ? ROLES[cell.value].display : ""}
                                                </td>
                                            );
                                        }
                                        else {
                                            return (
                                                <td tabIndex={cell.column.id === CHECKBOX_COLUMN_ID ? -1 : 0} aria-label={cell.column.id + " " + cell.value}
                                                    className={cell.column.id === CHECKBOX_COLUMN_ID ? styles.checkboxCell : ""}
                                                    style={index !== 0 ? {overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"} : {}}
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }) : <tr><td colSpan="10" className={styles.noData}>No Results Found</td></tr>}
                    </tbody>
                </Table>
                <NormalPagination
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                />
            </div>
        </>
    );
}
