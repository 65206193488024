import React, {useEffect, useState} from "react";
import { Draggable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import { Radio } from "../../components/radio/Radio";
import CancelIcon from "../../images/cancel.svg";
import DragDropIcon from "../../images/drag_drop.svg";
import { updateCorrectAnswers} from "../../utils/questionUpdate";
import { notifyError } from "../../utils/helpers";
import {ASCII_CODE_A, BUTTON, CREATE_OPT_RADIAL, DELETE_OPT_BTN, MC_ANSWER_LENGTH, POLL_TYPE, TEXT_BOX} from "../../utils/constants";
import "../styles.css";
import { MC_CI_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import { SURVEY_TEXT } from "../../utils/constants";

export default function CIQuestionOption({
                                             optionData,
                                             selectedQuestion,
                                             questions,
                                             setQuestions,
                                             index,
                                             pollType
                                         }) {
    const [showDragIcon, setShowDragIcon] = useState(false);
    const { serialNo, correctAnswers } = selectedQuestion;
    const selectedQuestionIndex = serialNo - 1;

    const [text, setText] = useState("");
 

    useEffect(() => {
        setText(optionData.text ? optionData.text : "");
    }, [optionData]);

    const handleAnswerChange = (input, itemKey) => {
        const copyOfQuestions = [...questions];
        const newOptionsMap = [...copyOfQuestions[selectedQuestionIndex].optionsMap];
        newOptionsMap[itemKey].text = input;
        copyOfQuestions[selectedQuestionIndex].optionsMap = newOptionsMap;
        setQuestions(copyOfQuestions);
        setText(input);
    };

    const handleCorrectAnswerChange = (input) => {
        setQuestions(updateCorrectAnswers(input, selectedQuestionIndex, questions));
    };

    const handleDeleteOption = () => {
        let newQuestions = [];

        questions.forEach((q) => {
            if (q.serialNo === serialNo) {
                const newOptionsMap = [...q.optionsMap];
                newOptionsMap.splice(index, 1);
                let updatedQuestion = {
                    ...q,
                    optionsMap: newOptionsMap,
                    correctAnswers:
                        q.correctAnswers.includes(index) ? [] : q.correctAnswers,
                };
                newQuestions.push(updatedQuestion);
            } else {
                newQuestions.push({ ...q });
            }
        });
        setQuestions(newQuestions);
    };

    return (
        <Draggable draggableId={`${index}`} index={index} key={index}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}>
                    <div
                        style={{display: "flex", flex: 1, alignItems: "center", marginBottom: 25}}
                        onMouseEnter={() => setShowDragIcon(true)}
                        onMouseLeave={() => setShowDragIcon(false)}
                    >
                        <div
                            {...provided.dragHandleProps}
                            style={{width: 40,  display: "flex", justifyContent: "center"}}
                            title={"Drag to Reorder"}
                            tabIndex={-1}
                        >
                            {correctAnswers[0] === index &&  pollType !== SURVEY_TEXT &&<img src={DragDropIcon} alt="drag drop icon" />}
                        </div>

                        <Radio
                            ariaLabel={CREATE_OPT_RADIAL + String.fromCharCode(index + ASCII_CODE_A)}
                            text={String.fromCharCode(index + ASCII_CODE_A)}
                            value={index}
                            //currently only accept radio buttons
                            selected={correctAnswers[0]}
                            onChange={(value) => {
                                handleCorrectAnswerChange(value);
                            }}
                            index={index}
                            pollType={pollType}
                            transform={'translateX(70px)'}
                        />

                        {!!pollType && pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                        <YuJaTextBox
                            label={TEXT_BOX + String.fromCharCode(index + ASCII_CODE_A)}
                            name={"question-text"}
                            placeholder={`Type Option`}
                            value={text}
                            containerStyle={{ width: "100%", fontSize: 20, marginLeft: 25, marginTop: "5px", padding: "12.5px 12.5px 12.5px 55px", border: "1.13px solid #858687"}}
                            maxLength={MC_ANSWER_LENGTH}
                            onChange={(e) => {
                                if(e.target.value.length <= MC_ANSWER_LENGTH) {
                                    handleAnswerChange(e.target.value, index);
                                }
                                else {
                                    notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                                    handleAnswerChange(e.target.value.substring(0, MC_ANSWER_LENGTH), index);
                                }
                            }}
                        />
                        }

                        {!!pollType && pollType.toUpperCase() === POLL_TYPE.SURVEY &&
                        <YuJaTextBox
                            label={TEXT_BOX}
                            name={"question-text"}
                            placeholder={`Type Option`}
                            value={text}
                            containerStyle={{ width: "100%", fontSize: 20, marginLeft: 25, padding:  "12.5px", border: "1.13px solid #858687"}}
                            maxLength={MC_ANSWER_LENGTH}
                            onChange={(e) => {
                                if(e.target.value.length <= MC_ANSWER_LENGTH) {
                                    handleAnswerChange(e.target.value, index);
                                }
                                else {
                                    notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                                    handleAnswerChange(e.target.value.substring(0, MC_ANSWER_LENGTH), index);
                                }
                            }}
                        />
                        }


                        <div style={{width : 30, marginRight: 20}}>
                            <img
                                aria-label={DELETE_OPT_BTN + String.fromCharCode(index + ASCII_CODE_A)}
                                role={BUTTON}
                                tabIndex={0}
                                onClick={() => {
                                    handleDeleteOption();
                                }}
                                src={CancelIcon}
                                alt="delete icon"
                                title={"Delete Option"}
                                className="questions-page-icons cursor-pointer"
                                style={{width : "2vw"}}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}
