import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BaseQuestion from "../../questions/BaseQuestion";
import { POLL_TITLE_LENGTH, QUESTION_TYPES, QUESTION_BANK_TITLE_PLACEHOLDER } from "../../utils/constants";
import "../CreateQuizPage/styles.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";

export default function QuestionBankMain({
                                        title,
                                        setTitle,
                                        isEditPage = false,
                                        questions,
                                        setQuestions,
                                        savePoll,
                                        institutionId,
                                        currQueDisplay,
                                        changeCurrQueDisplay,
                                        saveButtonDisabled,
                                        questionBankId,
                                        isSaving,
                                        isBank=false,
                                      }) {


  const pollTitleLimit = POLL_TITLE_LENGTH;

  useEffect(() => {
      let questionsWithoutAnswers = questions;
      questionsWithoutAnswers.forEach((question) => {
        if (question.questionType === QUESTION_TYPES.MCSS.name || question.questionType === QUESTION_TYPES.TF.name || question.questionType === QUESTION_TYPES.SA.name) {
          question.correctAnswers = [];
        }
      })
      setQuestions(questionsWithoutAnswers);
   }, []);

   const saveButtonStyle = {
    fontSize: 16,
    width: "208px", 
    height: "35px" , 
    padding: "7.941px", 
    borderRadius: "4.21px", 
    border: "1.336px solid #42296E", 
    justifyContent: "center", 
    alignItems: "center",
    display: "flex",
  };

  return (
    <div>
        <Row style={{marginTop: "36px"}}>
          <Col className="quiz-title-col margin-vertical-auto no-padding ">
              <YuJaTextBox
                  containerStyle={{fontSize: "25px", fontFamily: "Geomanist", width: "auto", padding: 12, fontWeight: 700, borderRadius: "9.039px;"}}
                  inputAlign={"center"}
                  type="text"
                  name={"pollTitle"}
                  placeholder={QUESTION_BANK_TITLE_PLACEHOLDER}
                  value={title}
                  onChange={(e) => {
                      if (e.target.value.length <= pollTitleLimit) {
                          setTitle(e.target.value);
                      } else {
                          setTitle(e.target.value.substring(0, POLL_TITLE_LENGTH));
                      }
                  }}
              />
          </Col>
        </Row>

      <BaseQuestion
        questions={questions}
        setQuestions={setQuestions}
        currQueDisplay={currQueDisplay}
        changeCurrQueDisplay={changeCurrQueDisplay}
        selectedQuestion={questions[currQueDisplay-1]}
        pollType={"Graded Poll"}
        institutionId={institutionId}
        pollKey={""}
        questionBankId={questionBankId}
        isSaving={isSaving}
        isBank={isBank}
      />

      <div style={{ display: "flex", justifyContent: "end", margin: "10px 0" }}>
        <YuJaButton title={"Save Draft Poll"} onClick={() => savePoll(true)} style={saveButtonStyle } disabled={saveButtonDisabled}>
          {isEditPage ? "Update" : "Save"}
        </YuJaButton>
      </div>
    </div>
  );
}