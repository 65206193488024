import React, {useEffect, useState} from "react";
import {ResponseApiCalls} from "../../apis/ResponseApiCalls";
import {useHistory, useParams} from "react-router-dom";
import {formatErrorLog, notifyError} from "../../utils/helpers";
import {
    EMBEDDED_POLL_GUIDE_DESCRIPTION,
    EMBEDDED_POLL_GUIDE_STEP_1_PREFIX,
    EMBEDDED_POLL_GUIDE_STEP_1_SUFFIX,
    EMBEDDED_POLL_GUIDE_STEP_2,
    EMBEDDED_POLL_GUIDE_STEP_3, EMBEDDED_POLL_GUIDE_TITLE, EMBEDDED_POLL_TITLE,
    INVALID_POLL_CODE_ERROR,
} from "../../utils/constants";
import styles from "./EmbeddedPollGuide.module.css"
import {useWindowDimensions} from "react-native-web";

import {ReactComponent as TITLE_ICON} from "./images/cardTitleIcon.svg"
import {ReactComponent as ILLUSTRATION_ICON} from "./images/cardIllustration.svg"

export default function EmbeddedPollGuide() {
    const {width} = useWindowDimensions();
    const { getPollRecordFromUniqueCode} = ResponseApiCalls();
    const { pollCode } = useParams();
    const history = useHistory();
    const [lmsLink, setLmsLink] = useState("");

    useEffect(async () => {
        const result = await getPollRecordFromUniqueCode(pollCode);
        if (!result || !result.poll || !result.poll.lmsHomeUrl) {
            notifyError(INVALID_POLL_CODE_ERROR);
            formatErrorLog(INVALID_POLL_CODE_ERROR);
            history.push("/");
            return;
        }

        setLmsLink(result.poll.lmsHomeUrl);
        // setLmsLink("https://www.google.com");
        if (document.getElementById("engagePageIdentifier") != null) {
            document.getElementById("engagePageIdentifier").innerText = "Join Poll Guide";
        }
    }, []);

    return (
        <div className={styles.container}>
            <span className={styles.title}>{EMBEDDED_POLL_TITLE}</span>
            <div className={styles.card}>
                <div className={styles.cardTitleBox}>
                    <TITLE_ICON style={{marginRight: 10}}/>
                    <div className={styles.cardTitleText}>{EMBEDDED_POLL_GUIDE_TITLE}</div>
                </div>
                <div
                    className={styles.cardBody}
                    style={{
                        width: Math.min(width * 0.8, 1000),
                        flexDirection: width > 500 ? "row" : "column",
                        alignItems: "center",
                        gap: 15
                    }}
                >
                    <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                        <span className={styles.steps} style={{marginBottom: 20}}>{EMBEDDED_POLL_GUIDE_DESCRIPTION}</span>
                        <span className={styles.steps}>{EMBEDDED_POLL_GUIDE_STEP_1_PREFIX} <span className={styles.link} onClick={() => window.open(lmsLink, "_blank")}>{EMBEDDED_POLL_GUIDE_STEP_1_SUFFIX}</span></span>
                        <span className={styles.steps}>{EMBEDDED_POLL_GUIDE_STEP_2}</span>
                        <span className={styles.steps}>{EMBEDDED_POLL_GUIDE_STEP_3}</span>
                    </div>
                    { width > 500 && <ILLUSTRATION_ICON/>}
                </div>
            </div>
            <div style={{flex: 1}}/>
        </div>
    );
}