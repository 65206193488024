import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import { useFetchWrapper } from "../../hooks/useFetchWrapper";
import { useLoading } from "../../utils/LoadingContext";
import { isBefore } from "../../utils/helpers";
import { INTEGRATION_API_URL } from "../../utils/properties";
import styles from "./PollChooser.module.css";
import {
    DEFAULT_ROWS_PER_PAGE
} from "../../utils/constants";
import PollChooserEmbeddedConfirm from "../../components/modals/PollChooserEmbeddedConfirm";

export default React.memo(PollChooserTable);
function PollChooserTable({ data = [], columns = [], globalFilterObj = {}, getPollInformation, secret, intType }) {

    const history = useHistory();
    const fetchWrapper = useFetchWrapper();
    const [htmlText, setHtmlText] = useState({__html: ""});
    const [confirmShow, setConfirmShow] = useState(false);
    const [pollKey, setPollKey] = useState("");
    const [pollCode, setPollCode] = useState("");

    const {setLoading} = useLoading();
    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        const filteredStartDate = globalFilterValue.startDate;
        const filteredEndDate = globalFilterValue.endDate;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            return !!(values.pollTitle && values.pollTitle.toLowerCase().indexOf(filteredKeyword) > -1);
        }).filter(r => {
            if (!filteredStartDate && !filteredEndDate) {
                return true;
            }
            if (!filteredStartDate) {
                return isBefore(r.values.updatedDatePK, filteredEndDate);
            }
            if (!filteredEndDate) {
                return isBefore(filteredStartDate, r.values.updatedDatePK);
            }
            const values = r.values;
            return values.updatedDatePK && isBefore(filteredStartDate, r.values.updatedDatePK) && isBefore(r.values.updatedDatePK, filteredEndDate);
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setLoading(true);
        setGlobalFilter(globalFilterObj);
        setLoading(false);
    }, [globalFilterObj, setGlobalFilter])

    const onAddToLMS = (pollKey, uniqueCode) => {
        const redirectBody = {"secret": secret, "pollKey": pollKey, "intType": intType, "uniqueCode": uniqueCode.substring(7), "apiURL": INTEGRATION_API_URL};
        return fetchWrapper.post({
            url: `${INTEGRATION_API_URL}/lti/redirect`,
            body: redirectBody
        }).then(function (result) {
            const deepLinkURL = result.URL;
            const signedJWT = result.JWT;
            history.push({pathname: "/lti/deeplink/form", state : { url : deepLinkURL, jwt: signedJWT }});
        }).catch((error) => {
            console.log("Error in Redirecting", error);
        });
    }

    const handleAddToLMS = (pollKey, uniqueCode) => {
        // const disable = getDisablePollChooserEmbeddedConfirm();
        // if (!disable) {
            setPollKey(pollKey);
            setPollCode(uniqueCode);
            setConfirmShow(true);
        // } else {
        //     onAddToLMS(pollKey, uniqueCode).then(result => setHtmlText(result));
        // }

    };

    const confirmConfig = {
        submit: () => {
            onAddToLMS(pollKey, pollCode).then(result => setHtmlText(result));
            // if (doNotShowAgain) {
            //     setDisablePollChooserEmbeddedConfirm(true);
            // }
            setConfirmShow(false);
        },
        close: async () => {
            setConfirmShow(false);
        },
    };

    return (
        <>
            <div className={styles.tableContainer}>
                <Table responsive hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth
                                        }
                                    })}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page && page.length !== 0 ? page.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.Header === 'Insert') {
                                            return (
                                                <td {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                    <div id={`options-${index}`}>
                                                        {
                                                            cell.row.values['pollSortKey'] === 'Draft'
                                                            ? 
                                                            <div></div>
                                                            :
                                                            <div className={`${styles.tableBtn} ${styles.tablePublishBtn}`} onClick={() => {handleAddToLMS(cell.value, cell.row.values['pollSortKey']);}} />
                                                        }
                                                    </div>
                                                </td>
                                            );
                                        } else if (cell.column.Header === 'Joining Code') {
                                            return (<>
                                                <td {...cell.getCellProps()}>
                                                     {cell.value.startsWith('PUB#UC#') ? cell.value.replace('PUB#UC#', '') : cell.value}
                                                 </td>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.value}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }) : <tr><td colSpan="5" className={styles.noData}>No Results Found</td></tr>}
                    </tbody>
                </Table>

                <NormalPagination
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                />
            </div>

            { htmlText && htmlText !== "" && <div dangerouslySetInnerHTML={htmlText} /> }

            <PollChooserEmbeddedConfirm
                show={confirmShow}
                config={confirmConfig}
            />
        </>
    );
}