import { Spin } from "../../components/standardization/YuJaLoading";
import { React, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Countdown from 'react-countdown';
import { Rnd } from "react-rnd";
import { useParams } from "react-router-dom";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import {
    ASCII_CODE_A,
    CI_COORDINATE_SIZE,
    FIXED_ANSWER,
    POLL_TYPE,
    QUESTION_IMAGE_ID,
    QUESTION_TYPES,
    RESPONSES,
    UNANSWERED,
    WORD_CLOUD_DATA,
    POLL_SHARE_MODE,
    SHOW_OR_HIDE_CORRECT_ANSWER_TOGGLE_LABEL
} from "../../utils/constants";
import { fetchImg, getInstitutionId } from "../../utils/helpers";
import { S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME } from "../../utils/properties";
import { decodeCIParam, questionCodeToName } from "../../utils/questionUtils";
import WordCloudResult from "../JoinQuizPage/WordCloudResult";
import { ReactComponent as BackIcon } from "./images/BACK_ARROW_ICON.svg";
import { ReactComponent as NextIcon } from "./images/NEXT_ARROW_ICON.svg";
import { ReactComponent as ShowCorrectIcon } from "./images/ShowCorrectAnswer.svg";
import { ReactComponent as HideCorrectIcon } from "./images/HideCorrectAnswer.svg";
import QuestionBarChart from "./QuestionBarChart.js";
import useMobileAccess from "../../hooks/useMobileAccess";
import ArrowIcon from "../../images/arrow_right.svg"
import "./styles.css";
import {YuJaToggle} from "../../components/standardization/YuJaToggle";

export default function ViewQuestions({data=[], answerMsg, removeAnsMsg, reactionMsg, loading=true, initialized=false, localState, setCurrentQuestion, selectedQuestion, currentQuestion, points, totalPoints, countDownRef, timeUp, renderer, pollEnd, queEnd, sharingAll, pollResults, loadingResponses, initializedResponses, pollMode}) {
    let sharingStates = ["SHARED_ALL", "SHARED", "GET_READY_ALL", "GET_READY"];
    const { getPollReaction, getClassResult } = ResponseApiCalls();
    const [questionresult, setQuestionResult] = useState([]);
    const [newItems, setNewItems] = useState([]);
    const [newFITBItems, setNewFITBItems] = useState([]);
    const {poll={}} = data;
    const {pollType=''} = poll;
    const [questionImageURL, setQuestionImageURL] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [setSAResult, getSAResult] = useLocalStorageNew("SAResult", []);
    const [setFITBResult, getFITBResult] = useLocalStorageNew("FITBResult", []);
    const [setCurrIndex, getCurrIndex]  = useLocalStorageNew("Curr_index", 1);
    const [setWordCloudData, ] = useLocalStorageNew(WORD_CLOUD_DATA, {});
    const imageRef = useRef(null);
    const { pollKey } = useParams();
    const [pastCorrAns, setPastCorrAns] = useState(0);
    const [ setCurr_index, getCurr_index]  = useLocalStorageNew("Curr_index", 1);
    const [thumbUpCount, setThumbUpCount] = useState(0); 
    const [thumbDownCount, setThumbDownCount] = useState(0); 
    const [animateThumbUp, setAnimateThumbUp] = useState(false);
    const [animateThumbDown, setAnimateThumbDown] = useState(false);
    const [loadingBarChart, setLoadingBarChart] = useState(false);
    const isNarrow = useMobileAccess(950);
    const [isSwitching, setIsSwitching] = useState(false);
    const [isVideo, setIsVideo] = useState(false);


    let currQues = null;
    if(!loading && initialized) {
        currQues = data.questions[currentQuestion-1];
    }

    useEffect(() => {
        if(reactionMsg.thumbUpCount && reactionMsg.thumbUpCount === 1) {
            setThumbUpCount(thumbUpCount+1);
        }

        if(reactionMsg.thumbDownCount && reactionMsg.thumbDownCount === 1) {
            setThumbDownCount(thumbDownCount+1);
        }

        if(reactionMsg.thumbUpCount && reactionMsg.thumbUpCount === -1) {
            setThumbUpCount(thumbUpCount <= 0 ? 0 : thumbUpCount-1);
        }

        if(reactionMsg.thumbDownCount && reactionMsg.thumbDownCount === -1) {
            setThumbDownCount(thumbDownCount <= 0 ? 0 : thumbDownCount-1);
        }
    }, [reactionMsg]);

    useEffect(() => {
        if(answerMsg.fitb) {
            questionresult.push(answerMsg.fitb);
            setQuestionResult(questionresult);
            updateFITBItems(questionresult);
            setFITBResult(questionresult);
        }

        if(answerMsg.sa) {
            questionresult.push(answerMsg.sa);
            setQuestionResult(questionresult);
            updateNewItems(questionresult);
            setSAResult(questionresult);
        }

        if(answerMsg.wc) {
            questionresult.push(answerMsg.wc);
            setQuestionResult([...questionresult]);
            setIsSwitching(true);
        }
    }, [answerMsg]);

    useEffect(() => {
        if(removeAnsMsg.prevSA) {
            let index = 0;
            for(let elem of questionresult) {
                if(JSON.stringify(elem) === JSON.stringify(removeAnsMsg.prevSA)) {
                    break;
                }
                index++;
            }
            questionresult.splice(index, 1);
            setQuestionResult(questionresult);
            updateNewItems(questionresult);
            setSAResult(questionresult);
        }

        if(removeAnsMsg.prevFITB) {
            let index = 0;
            for(let elem of questionresult) {
                if(JSON.stringify(elem) === JSON.stringify(removeAnsMsg.prevFITB)) {
                    break;
                }
                index++;
            }
            questionresult.splice(index, 1);
            setQuestionResult(questionresult);
            updateFITBItems(questionresult);
            setFITBResult(questionresult);
        }
        
        if(removeAnsMsg.prevWC) {
            let index = 0;
            for(let elem of questionresult) {
                if(elem === removeAnsMsg.prevWC) {
                    break;
                }
                index++;
            }
            let newResult = questionresult.filter((value, i) => i !== index);
            setQuestionResult(newResult);
            setIsSwitching(true);
        }
    }, [removeAnsMsg]);

    function updateNewItems(arr1) {
        const SAResult = getSAResult(); 
        const list = [];
      
        if (arr1.length >= SAResult.length) {
            const smallerLength = Math.min(arr1.length, SAResult.length);
            const length_diff = Math.abs(arr1.length - SAResult.length);
            for (let i = 0; i < smallerLength; i++) {
                if (arr1[i + length_diff] !== FIXED_ANSWER.UNANSWERED && SAResult[i]["0"] != FIXED_ANSWER.UNANSWERED && arr1[i + length_diff]["0"] !== SAResult[i]["0"]) {
                    list.push(arr1[i + length_diff]);
                }
            }
            const remainingArray = arr1.length >= SAResult.length ? arr1 : SAResult;
            for (let i = 0; i < length_diff; i++) {
                if (remainingArray[i] !== FIXED_ANSWER.UNANSWERED) {
                    list.push(remainingArray[i]);
                }
            }
        }
        else {
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== FIXED_ANSWER.UNANSWERED) {
                    list.push(arr1[i]);
                }
            }
        }

        setNewItems(list);
    }

    
    function updateFITBItems(arr1) {
        if (!arr1) {
            return; 
        }
        const FITBResult = getFITBResult();
        const list = [];
      
        if (arr1.length >= FITBResult.length) {
          const smallerLength = Math.min(arr1.length, FITBResult.length);
          const length_diff = Math.abs(arr1.length - FITBResult.length);
      
          for (let i = 0; i < smallerLength; i++) {
            if (arr1[i + length_diff] != FIXED_ANSWER.UNANSWERED && FITBResult[i] != FIXED_ANSWER.UNANSWERED){
                if (Object.keys(arr1[i + length_diff]).lenth !== Object.keys(FITBResult[i])) {
                    list.push(arr1[i + length_diff]);
                }
                else {
                    const keys = Object.keys(arr1[i + length_diff]);
                    let valuesMatch = true;
      
                    for (let j = 0; j < keys.length; j++) {
                        const key = keys[j];
                        if (arr1[i + length_diff][key] !== FITBResult[i][key]) {
                        valuesMatch = false;
                        break;
                    }
                    }
      
                    if (!valuesMatch) {
                        list.push(arr1[i + length_diff]);
                    }
            }
          }
        }
      
        const remainingArray = arr1.length >= FITBResult.length ? arr1 : FITBResult;
          for (let i = 0; i < length_diff; i++) {
            if (remainingArray[i] !== FIXED_ANSWER.UNANSWERED) {
              list.push(remainingArray[i]);
            }
          }
        } else {
          for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== FIXED_ANSWER.UNANSWERED) {
              list.push(arr1[i]);
            }
          }
        }
      
        setNewFITBItems(list);
    }


    useEffect(() => {
        if (!!currQues && (localState !== "SHARED_ALL" || pollType === POLL_TYPE.SURVEY) &&
                (currQues.questionType === QUESTION_TYPES.SA.name ||
                    currQues.questionType === QUESTION_TYPES.FITB.name ||
                    currQues.questionType === QUESTION_TYPES.WC.name)) {
            getClassResult(pollKey, currQues.sk.split("#")[2], currQues, null, true).then((result) => {
                if (result.length > 0) {
                    setQuestionResult(result);
                    if (currQues.questionType === QUESTION_TYPES.SA.name) {
                        updateNewItems(result)
                        setSAResult(result);
                    } else if (currQues.questionType === QUESTION_TYPES.FITB.name) {
                        updateFITBItems(result); 
                        setFITBResult(result);
                    }
                }
            });
        }
        /*const interval = setInterval(() => {
            if (!!currQues &&
                    (currQues.questionType === QUESTION_TYPES.SA.name ||
                        currQues.questionType === QUESTION_TYPES.FITB.name ||
                        currQues.questionType === QUESTION_TYPES.WC.name)) {
                getClassResult(pollKey, currQues.sk.split("#")[2], currQues, null,  true).then((result) => {
                    if (result.length > 0 && currQues.serialNo == getCurrIndex()) {
                        setQuestionResult(result);
                        if (currQues.questionType === QUESTION_TYPES.SA.name) {
                            updateNewItems(result)
                            setSAResult(result);
                        } else if (currQues.questionType === QUESTION_TYPES.FITB.name) {
                            updateFITBItems(result); 
                            setFITBResult(result);
                        }
                    }
                });
            }
        } , 5000);
        return () => clearInterval(interval);*/
    }, [currQues, pollKey, data, initialized, localState]);


    useEffect(() => {
        setSAResult([]);
        setFITBResult([]);
        setQuestionResult([]);
        setWordCloudData({});
    }, [currQues, selectedQuestion]);

    useEffect(() => {
        setCurrIndex(1); 
    }, [pollKey])

    const onLoad = () => {
        setIsLoaded(true);
    }

    const updateReactions = (questionNumber) => {
        getPollReaction(pollKey, currQues.sk.split("#")[2], questionNumber, pollMode === POLL_SHARE_MODE.SHARE_ALL ? "1": "0").then(resp => {
            if (resp && resp.hasOwnProperty("1") && resp.hasOwnProperty("2")) {
                    setThumbUpCount(resp["1"]); 
                    setThumbDownCount(resp["2"]); 
            }
        }
        )
        
        // getActiveStudentsInPoll(pollKey, currQues.sk.split("#")[2]).then(resp => {
        //     console.log(resp);
        //     let thumbup = 0; 
        //     let thumbdown = 0; 

        //     resp.students.map((item) => {
        //         if (item.reaction && item.reaction[questionNumber] && item.reaction[questionNumber][0] == "1") {
        //             thumbup +=1 ; 
        //         }
        //         else if (item.reaction && item.reaction[questionNumber] && item.reaction[questionNumber][0] == "2") {
        //             thumbdown += 1 ; 
        //         }
        //     });

        //     setThumbUpCount(thumbup); 
        //     console.log(thumbup);
        //     setThumbDownCount(thumbdown); 
        //     console.log(thumbdown);
        // });
    }

    useEffect(() => {
        setAnimateThumbUp(true);
      }, [thumbUpCount]);
    
    const onAnimationEndThumbUp = () => {
        setAnimateThumbUp(false);
    };

    useEffect(() => {
        setAnimateThumbDown(true);
      }, [thumbDownCount]);
    
    const onAnimationEndThumbDown = () => {
        setAnimateThumbDown(false);
    };
    

    useEffect(() => {
        if (!!currQues && !!pollMode && (pollMode === POLL_SHARE_MODE.SHARE_EACH || localState !== "SHARED_ALL")) {
            const questionNumber = currQues.serialNo;
            updateReactions(questionNumber);

            /*const interval = setInterval(() => {
                if (document.visibilityState === "visible") {
                    updateReactions(questionNumber);
                }
            }, 10000);
            return () => clearInterval(interval);*/
        }
        if(localState === "SHARED_ALL") {
            setThumbDownCount(0);
            setThumbUpCount(0);
        }
    }, [selectedQuestion, currQues, pollMode, localState]);

    const renderTitle = () => {
        if (currQues.questionType !== QUESTION_TYPES.FITB.name) {
          return (<p className="questionPanelTitle">{currQues.queTitle}</p>);
        }
        else {
            let currIndex = 0;
            return (
                <>
                <div className="questionPanelTitleFITB" style={{marginBottom: "30px"}}>
                    {currQues.queTitle.split(/(\(.*?\))/g).map((str, i) => {
                        let re = new RegExp(/\([^\(\)]+\)/g);
                        if (!re.test(str)) {
                            const oneLine = str.replace(/[\r\n]/g, " @newLine ");
                            const wordArr = oneLine.split(" ");
                            return (
                                <>
                                    {wordArr.map((s, idx) => (<>
                                        {s === '@newLine' &&
                                            <div key={idx} style={{width: "100%"}}></div>
                                        }
                                        {!!s && s !== '@newLine' &&
                                            <div key={idx} style={{marginRight: "5px"}}>{s}</div>
                                        }
                                    </>))}
                                </>
                            );
                        } else {
                            // const id = currIndex;
                            currIndex++;
                            return (<>
                                {Object.entries(currQues.correctAnswers)[currIndex-1][1].length === 1 &&
                                    <div className="fitbBlankContainers">
                                        <span className="fitbBlankWrap" style={{visibility: "hidden"}}>{Object.entries(currQues.correctAnswers)[currIndex-1][1]}</span>
                                    </div>
                                }
                                {Object.entries(currQues.correctAnswers)[currIndex-1][1].length > 1 &&
                                    <div className="fitbBlankContainers">
                                        <span className="fitbBlankWrap" >{renderAllCorrectOptions(currIndex-1)}</span>
                                    </div>
                                }
                        </>);
                        }
                    })}
                </div>
                </>
                
            );
        }
    };

    const renderAllCorrectOptions = (index) => {
        let allOpts = "";
        for(let element of Object.entries(currQues.correctAnswers)[index][1]) {
            if(allOpts !== "") {
                allOpts += " / " + element;
            }
            else {
                allOpts += element;
            }
        }
        return allOpts;
    };

    const formatResponseData = () => {
        let responseData = [];
        if(!!pollResults) {
            pollResults.forEach(element => {
                if(!!element && !!element.ans && element.ans !== UNANSWERED) {
                    responseData.push(element);
                }
            });
        }
        return responseData;
    }

    const renderQuestionOptions = () => {
        if(currQues.questionType === QUESTION_TYPES.MCSS.name || currQues.questionType === QUESTION_TYPES.TF.name || currQues.questionType === QUESTION_TYPES.CI.name) {
            return (
                <QuestionBarChart
                    loading={loadingBarChart}
                    correctAnswers={currQues.correctAnswers}
                    chartData={formatResponseData()}
                    questionType={currQues.questionType}
                    pollType={pollType}
                    localState={localState}
                />
            );
        } else if (currQues.questionType == QUESTION_TYPES.MH.name) {
            return (
                <div style={{overflow: "auto"}}> 
                <div style={{display: "flex", width:"100%", justifyContent: "center"}}> {currQues.image && questionImageURL && isNarrow && <img ref={imageRef} 
                                            style={{ maxWidth: "450px", maxHeight: "100%", mixBlendMode: "normal", 
                                            boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)", 
                                            borderRadius: "12px"}} 
                                            src={questionImageURL} onLoad={onLoad} alt="Question" /> } </div>
                {showAnswer ?
                <div style = {{ display: "flex" }}> 
                <div> 
                {currQues.correctAnswers.map((element, index) => (
                <> 
                {element.length > 1 ? 
                <div key={"Correct" + index} id={"CorrectOption" + index} className="questionPanelOptionsContainer" style={{marginBottom: "0px", gap: "20px"}}>
                    <div className="questionPanelAnswersText" id={"CorrectPart1" + index} style={{marginRight: "0px", border: "3px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1}}>{element[0]}</div>
                    <div className="questionPanelAnswersText" id={"CorrectPart2" + index} style={{border: "3px solid green", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1}}>{element[1]}</div>
                </div>
                : null} 
                </>
            ))}
            </div>
            </div> 
             : <div style={{ display: "flex", overflow: "auto"}}>
                <div >
               {currQues.optionsMap.map((element, index) => (
                 <div key={"Normal" + index} id={"NormalOption" + index} className="questionPanelOptionsContainer" style={{ marginBottom: "0px", gap: "10px"}}>
                     {element.length > 1 ?
                     <>
                             <div className="questionPanelAnswersText" id={"NormalPart1" + index} style={{ marginRight: "0px", border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1 }}>{element[0]}</div>
                             <img
                                 src={ArrowIcon}
                                 alt="Arrow icon"
                                 className="questions-page-icons cursor-pointer"
                                 style={{ width: "30px", margin: "0px 0px 0px 10px" }}
                             /> </> : <> <div className="questionPanelAnswersText" id={"NormalPart1" + index} style={{ marginRight: "0px", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1 }}></div> 
                             <img
                             className="questions-page-icons cursor-pointer"
                             style={{ width: "30px", margin: "0px 0px 0px 10px" }}
                            /> </>}
                              <div className="questionPanelAnswersText" id={"NormalPart2" + index} style={{ border: ".5px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1}}>{element.length > 1 ? element[1] : element[0]}</div>
                 </div>
             ))}
         </div>
         </div>
        }
        </div>
            )
        }
        
        else if(currQues.questionType === QUESTION_TYPES.SA.name) {
            return (
                // {/* <div style={{marginBottom: "10px", height: "210px", overflow: "hidden", position: "relative"}}>
                //     <div id="frontSAAnswer" className="cardFront"> {Object.entries(currQues.correctAnswers)[SAAnswerIndex][1]}</div>
                //     <div id="backSAAnswer"  className="cardBack">  {Object.entries(currQues.correctAnswers)[SAAnswerIndex][1]}</div>
                // </div>

                // {Object.entries(currQues.correctAnswers).length > 1 && 
                // <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "20px"}}>
                //     <div className="shuffleAnswerToolTip" style={{width: "30px", height: "25px"}}>
                //         <ShuffleIcon style={{ cursor: "pointer"}} 
                //             onClick={() => {
                //                 document.getElementById("frontSAAnswer").classList.toggle('flipped');
                //                 document.getElementById("backSAAnswer").classList.toggle('flipped');
                //                 setSAAnswerIndex(SAAnswerIndex === Object.entries(currQues.correctAnswers).length-1 ? 0 : SAAnswerIndex+1)
                //             }}
                //         />
                //     </div>
                // </div>} */}
                <div>
                    {questionresult.map((item) =>
                        item !== FIXED_ANSWER.UNANSWERED ? (
                            <div
                                className= {`questionPanelAnswersText ${newItems.includes(item) ? 'resultAnimation' : ''}`}
                                style={{ marginTop: "20px", backgroundColor: "#f4f4f4", width: "fit-content", padding: "15px"}}
                            >
                                {`"${item["0"]}"`}
                            </div>
                        ) : <></>
                    )}
                </div>
            );
        } else if (currQues.questionType === QUESTION_TYPES.FITB.name) {
            return (
                <div>
                    {questionresult.map((item) => {
                        if (item !== "unanswered") {
                            const combinedString = Object.keys(item)
                                .map((key) => `"${item[key]}"`)
                                .join(", ");

                            return (
                                <div
                                    className={`questionPanelAnswersText ${newFITBItems.includes(item) ? "resultAnimation" : ""
                                        }`}
                                    style={{
                                        marginTop: "20px",
                                        backgroundColor: "#f4f4f4",
                                        width: "fit-content",
                                        padding: "15px",
                                    }}
                                    key={combinedString}
                                >
                                    {combinedString}
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>

            );
        } else if (currQues.questionType === QUESTION_TYPES.WC.name) {
            return (
                <WordCloudResult
                    data={questionresult}
                    pollType={pollType}
                    isSwitching={isSwitching}
                    setIsSwitching={setIsSwitching}
                />
            );
        }
    };

    const renderQuestionType = () => {        
        return <div className="questionPanelQuestionType">{questionCodeToName(currQues.questionType)}</div>;
    };

    const removeHighlight = () => {
        if(document.getElementById("OptionPart1"+pastCorrAns) !== null) {
            document.getElementById("OptionPart1"+pastCorrAns).style.color = "";
            document.getElementById("OptionPart1"+pastCorrAns).style.fontWeight = "400";
        }
        if(document.getElementById("OptionPart2"+pastCorrAns) !== null) {
            document.getElementById("OptionPart2"+pastCorrAns).style.color = "";
            document.getElementById("OptionPart2"+pastCorrAns).style.fontWeight = "400";
        }
    };

    const showCorrectAnswer = () => {
        if (!currQues) {
            return;
        }
        let index = 0;
        if(!!document.getElementById("Option"+index)) {
            if(QUESTION_TYPES.MCSS.name === currQues.questionType) {
                index = currQues.correctAnswers[0].charCodeAt(0)-97;
                document.getElementById("OptionPart1"+index).style.color = "#268726";
                document.getElementById("OptionPart2"+index).style.color = "#268726";
                document.getElementById("OptionPart1"+index).style.fontWeight = "700";
                document.getElementById("OptionPart2"+index).style.fontWeight = "700";
                setPastCorrAns(index);
            } else if(QUESTION_TYPES.TF.name === currQues.questionType) {
                if(currQues.correctAnswers === "False") {
                    index = 1;
                }
                document.getElementById("OptionPart1"+index).style.color = "#268726";
                document.getElementById("OptionPart1"+index).style.fontWeight = "700";
                setPastCorrAns(index);
            } else if(QUESTION_TYPES.CI.name === currQues.questionType) {
                index = currQues.correctAnswers[0];
                document.getElementById("OptionPart1"+index).style.color = "#268726";
                document.getElementById("OptionPart2"+index).style.color = "#268726";
                document.getElementById("OptionPart1"+index).style.fontWeight = "700";
                document.getElementById("OptionPart2"+index).style.fontWeight = "700";
                setPastCorrAns(index);
            }
        }

        if (QUESTION_TYPES.FITB.name === currQues.questionType) {
            let eleArr = document.getElementsByClassName("fitbBlankContainers");
            for (const ele of eleArr) {
                ele.style.border = "3px solid #268726";
            }

            eleArr = document.getElementsByClassName("fitbBlankWrap");
            for (const ele of eleArr) {
                ele.style.visibility = "visible";
            }
        }
        // else if (QUESTION_TYPES.WC.name === currQues.questionType) { // needed for graded WC

        // }
    };


    const hideCorrectAnswer = () => {
        if (!currQues) {
            return;
        }
        let index = 0;
        if(!!document.getElementById("Option"+index)) {
            if(QUESTION_TYPES.MCSS.name === currQues.questionType) {
                index = currQues.correctAnswers[0].charCodeAt(0)-97;
                document.getElementById("OptionPart1"+index).style.color = "#000000";
                document.getElementById("OptionPart2"+index).style.color = "#000000";
                document.getElementById("OptionPart1"+index).style.fontWeight = "400";
                document.getElementById("OptionPart2"+index).style.fontWeight = "400";
                setPastCorrAns(index);
            }
            else if(QUESTION_TYPES.TF.name === currQues.questionType) {
                if(currQues.correctAnswers === "False") {
                    index = 1;
                }
                document.getElementById("OptionPart1"+index).style.color = "#000000";
                document.getElementById("OptionPart1"+index).style.fontWeight = "400";
                setPastCorrAns(index);
            }
            else if(QUESTION_TYPES.CI.name === currQues.questionType) {
                index = currQues.correctAnswers[0];
                document.getElementById("OptionPart1"+index).style.color = "#000000";
                document.getElementById("OptionPart2"+index).style.color = "#000000";
                document.getElementById("OptionPart1"+index).style.fontWeight = "400";
                document.getElementById("OptionPart2"+index).style.fontWeight = "400";
                setPastCorrAns(index);
            }
        }

        if (QUESTION_TYPES.FITB.name === currQues.questionType) {
            let eleArr = document.getElementsByClassName("fitbBlankContainers");
            for (const ele of eleArr) {
                ele.style.border = null;
            }

            eleArr = document.getElementsByClassName("fitbBlankWrap");
            for (const ele of eleArr) {
                ele.style.visibility = null;
            }
        }
        // else if (QUESTION_TYPES.WC.name === currQues.questionType) { // needed for graded WC

        // }
    };

    useEffect(() => {
        setIsLoaded(false);
        setQuestionImageURL("");
        async function fetchData() {
          if (initialized && (!!currQues.directLinkEvp) && pollKey && currentQuestion) {
            setQuestionImageURL(currQues.directLinkEvp);
            setIsVideo(true);
          }
          else if (initialized && (!!currQues.image || !!currQues.suffix) && getInstitutionId(data.poll.index5Pk) && pollKey && currentQuestion) {
            let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${getInstitutionId(data.poll.index5Pk)}/${pollKey}`;
    
            url = !!currQues.image ? `${url}/${currQues.image}` : `${url}/temp-${currentQuestion}${currQues.suffix}`;
            let img = await fetchImg(url);
            setQuestionImageURL(img ? img : "");
            setIsVideo(false);
          }
        }
        fetchData();

        if(!loading && initialized && data.poll.pollType === POLL_TYPE.GRADED_POLL) {
            removeHighlight();
            setShowAnswer(false);
            // handleCorrectAnswer();
        }
    }, [currentQuestion, data, selectedQuestion]);

    useEffect(() => {
        if (showAnswer) {
            showCorrectAnswer();
        } else {
            hideCorrectAnswer();
        }
    }, [showAnswer]);

    return (
        <div className="questionPanel" style={{height: "100%"}}>
            <Spin size="large" wrapperClassName="panelSpin" spinning={false} tip="Loading..." style={{height: "100%"}}>
                {initialized && !selectedQuestion &&
                    <>
                        <div  style={{display: "flex", justifyContent: "space-between", width: "100%", height: "50px", marginBottom: "20px", alignItems: "center"}}>
                            <p className="questionPanelTotalQuestionsPoll">{data.questions.length} {data.questions.length === 1 ? "Question" : "Questions"}</p>
                            <span className="gradientWrapQuesType">
                                <span className="questionPanelQuestionTypeContainer">
                                    <div className="questionPanelQuestionType">All Type of Questions</div>
                                </span>
                            </span>
                            {data.poll.pollType === POLL_TYPE.GRADED_POLL &&
                                <span className="gradientWrapQuesType">
                                    <span className="questionPanelQuestionTypeContainer">
                                        <div className="questionPanelQuestionType">Total {totalPoints} Point{totalPoints === 1 ? "" : "s"}</div>
                                    </span>
                                </span>
                            }
                            <div style={{flex: 1, display:"flex", justifyContent: "flex-end"}}>
                                {(localState === 'GET_READY_ALL' || pollType.toUpperCase() !== POLL_TYPE.SURVEY) &&
                                    <span className="gradientWrapQuesType">
                                    <span className="questionPanelQuestionTypeContainerTimer">
                                        {!loading && initialized &&
                                            <Countdown
                                                ref={countDownRef}
                                                className="pollPanelTimerText"
                                                autoStart={sharingStates.includes(localState)}
                                                date={sharingAll ? pollEnd : queEnd}
                                                key={sharingAll ? pollEnd : queEnd}
                                                renderer={renderer}
                                                onComplete={timeUp}>
                                            </Countdown>
                                        }
                                    </span>
                                </span>
                                }
                            </div>
                        </div>
                        <div style={{flex: 4, width: "100%", overflowY: "auto"}}>
                            {data.questions.map((question, index) =>
                                <div key={index} style={{display: "flex", minHeight: "40px", marginBottom: "30px"}}>
                                    <p className="questionPanelAllQSelect">Q{question.serialNo}</p>
                                    <p className="questionPanelTitleText">{question.queTitle}</p>
                                </div>
                            )}
                        </div>
            </>
                }
                {initialized && selectedQuestion &&
                    <>
                        <div className={"questionPanelTitle"} style={{display: "flex", width: "100%", height: "50px",  marginBottom: "20px", alignItems: "center", overflowX: "auto"}}>
                            <span className="questionPanelTotalQuestions">{currentQuestion}</span>
                            <span className="questionPanelTotalQuestions2"> of {data.questions.length}</span>
                            <span className="gradientWrapQuesType" style={{width: isNarrow? "fit-content" : ""}}>
                                <span className="questionPanelQuestionTypeContainer" style={{width: isNarrow? "fit-content" : ""}}>
                                    {renderQuestionType()}
                                </span>
                            </span>
                            {data.poll.pollType === POLL_TYPE.GRADED_POLL &&
                                <>
                                    <span className="gradientWrapQuesType">
                                        <span className="questionPanelQuestionTypeContainer" style={{width: isNarrow? "fit-content" : ""}}>
                                            <div className="questionPanelQuestionType" style={{width: isNarrow? "fit-content" : ""}}>{currQues.weightage} point{currQues.weightage === 1 ? "" : "s"}</div>
                                        </span>
                                    </span>
                                    {currQues.questionType !== QUESTION_TYPES.SA.name && !showAnswer &&
                                    <div onClick={() => setShowAnswer(true)} style={{marginLeft: "15px", cursor: "pointer", borderRadius: "4.21px", width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center",  background: "linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%)"}}>
                                        <div style={{borderRadius: "4.21px", width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#FFF"}}>
                                        <ShowCorrectIcon />
                                        </div>
                                    </div>
                                        // <YuJaToggle style={{fontSize: 15, fontWeight: 500, marginLeft: 10}} toggled={showAnswer} onClick={() => setShowAnswer(!showAnswer)} label={SHOW_OR_HIDE_CORRECT_ANSWER_TOGGLE_LABEL}/>
                                    }
                                    {currQues.questionType !== QUESTION_TYPES.SA.name && showAnswer &&
                                    <div onClick={() => setShowAnswer(false)} style={{marginLeft: "15px", cursor: "pointer", borderRadius: "4.21px", width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center",  background: "linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%)"}}>
                                        <HideCorrectIcon />
                                        </div>
                                    }
                                </>
                            }
                            <div style={{flex: 1, display: "flex", justifyContent: "flex-end"}}>
                                {(localState === 'GET_READY' || pollType.toUpperCase() !== POLL_TYPE.SURVEY) && poll.pollShareMode!== POLL_SHARE_MODE.SCHEDULE &&
                                    <span className="gradientWrapQuesType" style={{marginLeft: "0px"}}>
                                        <span className="questionPanelQuestionTypeContainerTimer">
                                            {!loading && initialized &&
                                                <Countdown
                                                    ref={countDownRef}
                                                    className="pollPanelTimerText"
                                                    autoStart={sharingStates.includes(localState)}
                                                    date={sharingAll ? pollEnd : queEnd}
                                                    key={sharingAll ? pollEnd : queEnd}
                                                    renderer={renderer}
                                                    onComplete={timeUp}>
                                                </Countdown>
                                            }
                                        </span>
                                    </span>
                                }
                            </div>
                        </div>
                        <div id="questioncontainer" style={{width: "100%", display: "flex", flex: 4, overflowY: "auto", flexDirection: "column"}}>
                            <div style={{width: "100%"}}>
                                {renderTitle()}
                            </div>
                            <div style={{display: "flex", alignItems: "center", width: "100%", flex:1, overflowY: "auto", minHeight: '200px'}}>
                                <div style={questionImageURL ? {flex: 5, overflowY: "auto", height: "100%"} : {overflowY: "auto", height: "100%", width:"100%"}}>
                                    {renderQuestionOptions()}
                                </div>
                                {(currQues.image || currQues.directLinkEvp) && questionImageURL && !(currQues.questionType === QUESTION_TYPES.MH.name && isNarrow) && (
                                    <div className="img-container-share" id={QUESTION_IMAGE_ID} style={{height:"100%"}}>
                                        <div style={{position: "relative"}}>
                                        {!isVideo && <img ref={imageRef} 
                                            style={{ maxWidth: "450px", maxHeight: "100%", mixBlendMode: "normal", 
                                            boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)", 
                                            borderRadius: "12px"}} 
                                            src={questionImageURL} onLoad={onLoad} alt="Question" />}
                                        {isVideo && <iframe allowFullScreen
                                            style={{ width: "450px", height: "90%", mixBlendMode: "normal", 
                                            boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)", 
                                            borderRadius: "12px"}} 
                                            src={questionImageURL} alt="Question" />}
                                        {isLoaded && currQues.questionType === QUESTION_TYPES.CI.name &&
                                            currQues.optionsMap.map((area, idx) =>
                                                <Rnd
                                                    key={idx}
                                                    id={`option-area-${idx}`}
                                                    size={{
                                                        width: decodeCIParam(area.width, imageRef.current.width),
                                                        height: decodeCIParam(area.height, imageRef.current.height)
                                                    }}
                                                    position={{
                                                        x: decodeCIParam(area.x, imageRef.current.width),
                                                        y: decodeCIParam(area.y, imageRef.current.height)
                                                    }}
                                                    enableResizing={false}
                                                    disableDragging={true}
                                                    bounds={"parent"}
                                                >
                                                    <div style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        border: "solid red 1.5px",
                                                        fontWeight: "700",
                                                        fontSize: "16px",
                                                        backgroundColor: "hsla(0,0%,73.3%,.5)",
                                                    }}>
                                                        <span style={{zIndex: "1000"}}>{String.fromCharCode(ASCII_CODE_A + idx)}</span>
                                                    </div>
                                                </Rnd>
                                            )
                                        }

                                        {isLoaded && currQues.questionType === QUESTION_TYPES.CI.name &&
                                            points.map((point, idx)=>
                                                <COORDINATE
                                                    key={idx}
                                                    style={{
                                                        position: "absolute",
                                                        top: `${decodeCIParam(point.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                                        left: `${decodeCIParam(point.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                                    }}
                                                />
                                            )

                                        }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div style={{display: "flex", width: "100%", justifyContent: "flex-start", marginTop: "15px"}}>
                            {data.questions.length !== 1 && 
                            <div style={{display: "flex", justifyContent: "flex-end"}} id="buttonsContainer">
                            <div className="previousToolTip" style={{cursor: "pointer"}}>
                            <div  className= {currentQuestion === 1 ? "grayedGradientWrap" : "gradientWrap"} style={{marginRight: "45px"}} onClick={() => {
                                    setLoadingBarChart(true);
                                    setCurrentQuestion(currentQuestion === 1 ? 1 : currentQuestion-1); 
                                    setCurr_index(currentQuestion === 1 ? currentQuestion : currentQuestion-1);
                                    setQuestionResult([]);
                                    setTimeout(() => {
                                        setLoadingBarChart(false);
                                    }, 1);
                                }}>
                                <span className= {currentQuestion === 1 ? "grayedBtnArrows": "gradientBtnArrows"}>
                                <BackIcon/>
                                </span>
                            </div>
                            </div>
                            <div className="nextToolTip" style={{cursor: "pointer"}}>
                            <div className= {currentQuestion === data.questions.length ? "grayedGradientWrap" : "gradientWrap"} onClick={() => {
                                    setLoadingBarChart(true);
                                    setCurrentQuestion(currentQuestion === data.questions.length ? currentQuestion : currentQuestion+1); 
                                    setCurr_index(currentQuestion === data.questions.length  ? currentQuestion : currentQuestion+1);
                                    setQuestionResult([]);
                                    setTimeout(() => {
                                        setLoadingBarChart(false);
                                    }, 1);
                                }}>
                                <span className= {currentQuestion === data.questions.length ? "grayedBtnArrows": "gradientBtnArrows"}>
                                <NextIcon/>
                                </span>
                            </div>
                            </div>
                            </div>}
                            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", flexDirection: 'column', rowGap: "0"}}>
                        <div style={{ display: "flex", width: "100%", columnGap: "20px", justifyContent: "flex-end", alignItems:"center", height: "5px"}}>
                            <div style={{ width: '45px', height: '45px', display: 'flex', alignItems: "start", justifyContent: "center"}}> 
                            <div style={{
                                backgroundColor: "#F6EEF6", color: '#000000', height: "20px", borderRadius: "10px", alignItems: "center", fontSize: '16px',
                                lineHeight: "18px", fontFamily: "Geomanist",
                                display: 'flex',
                                justifyContent: "center",
                                transform: "translateY(10px)",
                                minWidth: "20px"
                            }}> {thumbUpCount} </div>
                            </div> 
                            <div style={{ width: '45px', height: '45px', display: 'flex', alignItems: "start", justifyContent: "center"}}>
                            <div style={{
                                backgroundColor: "#F6EEF6", color: '#000000', minWidth: "20px", height: "20px", borderRadius: "10px", alignItems: "center", fontSize: '16px',
                                lineHeight: "18px", fontFamily: "Geomanist",
                                display: 'flex',
                                justifyContent: "center",
                                transform: "translateY(10px)"
                            }}> {thumbDownCount}</div>
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%", columnGap: "20px", justifyContent: "flex-end"}}>
                            <div className={animateThumbUp ? "reaction-animation" : ""}
                                style={{
                                    width: '45px',
                                    height: '45px',
                                    borderRadius: '50%',
                                    backgroundColor: '#712B90',
                                    alignItems: "center",
                                    display: 'flex',
                                    justifyContent: "center"
                                }}
                                onAnimationEnd={onAnimationEndThumbUp}
                            ><div className="thumbupicon-activated" ></div></div>
                            <div className={animateThumbDown ? "reaction-animation" : ""}
                                style={{
                                    width: '45px',
                                    height: '45px',
                                    borderRadius: '50%',
                                    backgroundColor: '#712B90',
                                    alignItems: "center",
                                    display: 'flex',
                                    justifyContent: "center",
                                }}
                                onAnimationEnd={onAnimationEndThumbDown}
                            >  <div className="thumbdownicon-activated"></div>
                            </div>
                        </div> 

                    </div>
                        </div>
                    </>
                }
            </Spin>
        </div>
    );
}
