import React, { useEffect, useRef, useState } from "react";
import { Col, Container } from "react-bootstrap";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as CustomEntriesIcon } from "../../images/entries_black.svg";
import { ReactComponent as CustomWeightageIcon } from "../../images/points_black.svg";
import { ReactComponent as CustomTimeLimitIcon } from "../../images/time_limit_black.svg";
import { BUTTON, CREATE_POLL_CARROT, CREATE_POLL_CARROT_2, CREATE_POLL_CARROT_3, CREATE_POLL_CARROT_4, CREATE_POLL_CARROT_5, CREATE_POLL_CARROT_6, CUSTOM_COUNTDOWN, CUSTOM_ENTRIES_FOOTER, CUSTOM_POINTS, CUSTOM_TEXT, CUSTOM_TIME_LIMIT, DEFAULT_ENTRIES_PER_PARTICIPANT, DEFAULT_GET_READY_TIME_LIMIT, DEFAULT_TIME_LIMIT, ENTRIES_SUBTITLE, ENTRIES_TEXT, ENTRIES_TITLE, POLL_TYPE, QUESTION_TYPES, TEXTBOX } from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import { updateEntries, updateGetReadyTimeLimit, updateTimeLimit, updateWeightage } from "../../utils/questionUpdate";
import { TimeCustomToStringLabel, compareEntries, comparePoints, entriesToString, isCustomEqual, questionCodeToName, timeCustomToSeconds, timeCustomToString, timeSecondsToCustom, validCustomTime, weightagePointsToString } from "../../utils/questionUtils";
import { CUSTOME_TIME_LIMIT_FORMAT_ERROR, CUSTOM_TIME_LIMIT_MAX_ERROR, CUSTOM_TIME_LIMIT_MIN_ERROR } from "../../utils/toast-message-constants";
import EntriesOptionRow from "./EntriesOptionRow";
import OptionButton from "./OptionButton";
import PointsOptionRow from "./PointsOptionRow";
import TimeLimitOptionRow from "./TimeLimitOptionRow";
import { ReactComponent as DropDown } from "./images/CaretCircleBottomPurple.svg";
import { ReactComponent as DropRight } from "./images/CaretCircleRight.svg";
import "./styles.css";

export default function CreateQuestionDetailSidebar({
    questions,
    setQuestions,
    currQueDisplay,
    pollType,
    showAnswerOnViewer,
    setShowAnswerOnViewer,
    anonymousJoin, 
    setAnonymousJoin, 
    isBank=false,
}) {
    const [questionType, setQuestionType] = useState(QUESTION_TYPES.MCSS.description);
    const [collapsedQuestionType, setCollapsedQuestionType] = useState(false);
    const [timeLimit, setTimeLimit] = useState("00:15");
    const [customTimeLimit, setCustomTimeLimit] = useState("");
    const [collapsedTimeLimit, setCollapsedTimeLimit] = useState(true);
    const [points, setPoints] = useState("1");
    const [customPoints, setCustomPoints] = useState("");
    const [collapsedPoints, setCollapsedPoints] = useState(true);
    const [getReadyTimeLimit, setGetReadyTimeLimit] = useState("" + DEFAULT_GET_READY_TIME_LIMIT);
    const [customGetReadyTimeLimit, setCustomGetReadyTimeLimit] = useState("");
    const [collapsedGetReadyTimeLimit, setCollapsedGetReadyTimeLimit] = useState(true);
    const [entries, setEntries] = useState("" + DEFAULT_ENTRIES_PER_PARTICIPANT);
    const [customEntries, setCustomEntries] = useState("");
    const [collapsedEntries, setCollapsedEntries] = useState(true);
    // const [collapsedProfileSetting, setCollapsedProfileSetting] = useState(true);
    // const [profileSettingModalShow, setProfileSettingModalShow] = useState(false);
    // const [importingModal, setImportingModal] = useState(false);
    // const gradebookOptionsStyle = {
    //     fontFamily: "Poppins",
    //     fontStyle: "normal",
    //     fontWeight: 400,
    //     fontSize: "14px",
    //     margin: "0px",
    //     padding: "8px",
    //     width: "100%",
    //     border: "0.15rem solid #0277AD",
    // };

    const questionTypeOptions = useRef([]);
    questionTypeOptions.current = [
        QUESTION_TYPES.MCSS,
        QUESTION_TYPES.SA,
        QUESTION_TYPES.TF,
        QUESTION_TYPES.FITB,
        QUESTION_TYPES.CI,
    ];
    if (isBank || (!!pollType && pollType.toUpperCase() === POLL_TYPE.SURVEY)) {
        questionTypeOptions.current.push(QUESTION_TYPES.WC);
        if(isBank) {
            questionTypeOptions.current.push(QUESTION_TYPES.MH);
        }
    }
    else {
        questionTypeOptions.current.push(QUESTION_TYPES.MH);
    }

    let narrow = useMobileAccess(1250);
    const [isNarrow, setIsNarrow] = useState(narrow);
    const [optionsPerRow, setOptionsPerRow] = useState(narrow ? 2 : 3);
    const timeLimitOptions = useRef(["00:10", "00:15", "00:30", "00:45", "01:00", "05:00"]);
    const timeLimitRow = useRef([]);
    const pointsOptions = useRef(["1", "5", "10", "15", "20", "50"]);
    const pointsRow = useRef([]);
    const entriesOptions = useRef(["1", "5", "10"]);
    const entriesRow = useRef([]);
    const getReadyTimeLimitOptions = useRef(["10", "15", "30"]);
    const getReadyTimeLimitRow = useRef([]);

    useEffect(() => {
        setIsNarrow(window.innerWidth < 1250);
        setOptionsPerRow(window.innerWidth < 1250 ? 2 : 3);
        timeLimitRow.current = [];
        pointsRow.current = [];
        getReadyTimeLimitRow.current = [];
    }, [window.innerWidth]);
    
    const handleTimeLimitUpdate = (tLimit) => {
        setTimeLimit(tLimit);
        setQuestions(updateTimeLimit(timeCustomToSeconds(tLimit), currQueDisplay - 1, questions));
    };
    
    const handleGetReadyTimeLimitUpdate = (grtLimit) => {
        setGetReadyTimeLimit(grtLimit);
        setQuestions(updateGetReadyTimeLimit(grtLimit, currQueDisplay - 1, questions));
    };

    // const handleCustomTimeLimitUpdate = (e) => {
    //     let t = e.target.value.replace(/[^0-9:]/g, '');
    //     let timeArr = t.split(":");
    //     let min = timeArr[0] !== "00" ? parseInt(timeArr[0]) : timeArr[0];
    //     let sec = timeArr[1] !== "00" ? parseInt(timeArr[1]) : timeArr[1];
    //     let t1 = min + ":" + sec;
    //     setCustomTimeLimit(t1);
    //     // if ((min === 0 && sec === 0) || !validCustomTime(t1)){
    //     //     return false;  
    //     // }
    //     setTimeLimit(timeSecondsToCustom(timeCustomToSeconds(t1)));
    //     setQuestions(updateTimeLimit(timeCustomToSeconds(t1), currQueDisplay - 1, questions));
    // }
    
    const getSecondsFromHHMMSS = (value) => {
        const [str1, str2, str3] = value.split(":");
    
        const val1 = Number(str1);
        const val2 = Number(str2);
        const val3 = Number(str3);
    
        if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
        // seconds
        if(val1 > 3600) {
            notifyError(CUSTOM_TIME_LIMIT_MAX_ERROR);
            return 0;
        } else if(val1 < 10) {
            notifyError(CUSTOM_TIME_LIMIT_MIN_ERROR);
            return 0;
        }
          return val1;
        }
    
        if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
        // minutes * 60 + seconds
            if((val1 * 60 + val2) > 3600) {
                notifyError(CUSTOM_TIME_LIMIT_MAX_ERROR);
                return 0;
            }
          return val1 * 60 + val2;
        }
    
        if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
        // hours * 60 * 60 + minutes * 60 + seconds
            notifyError(CUSTOME_TIME_LIMIT_FORMAT_ERROR);
            return 0;
        //   return val1 * 60 * 60 + val2 * 60 + val3;
        }
    
        return 0;
    };

    const onBlurCustomTime = (event) => {
        const value = event.target.value;
        if(value.trim() === "") {
            return
        }
        const seconds = Math.max(0, getSecondsFromHHMMSS(value));
        if(seconds === 0 || seconds === 3600) {
            setCustomTimeLimit("00:15");
            setTimeLimit("00:15");
            setQuestions(
                updateTimeLimit(
                  DEFAULT_TIME_LIMIT,
                  currQueDisplay - 1,
                  questions
                )
              );
        } else {
            const time = toHHMMSS(seconds);
            setCustomTimeLimit(time);
            setTimeLimit(time);
            setQuestions(
              updateTimeLimit(
                timeCustomToSeconds(time),
                currQueDisplay - 1,
                questions
              )
            );
        }
    };

    const onChangeCustomTime = (event) => {
        setCustomTimeLimit(event.target.value);
    };

    const toHHMMSS = (secs) => {
        const secNum = parseInt(secs.toString(), 10);
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor(secNum / 60) % 60;
        const seconds = secNum % 60;
    
        return [hours, minutes, seconds]
          .map((val) => (val < 10 ? `0${val}` : val))
          .filter((val, index) => val !== "00" || index > 0)
          .join(":")
          .replace(/^0/, "");
    };

    const handlePointsUpdate = (points) => {
        setPoints(points);
        setQuestions(updateWeightage(points, currQueDisplay - 1, questions));
    };

    const onChangeCustomPoints = (e) => {
        let w = e.target.value.replace(/[^0-9]/g, '');
        w = parseInt(w);
        if (w > 100) {
            return;
        }
        setCustomPoints(w);
        setPoints(w);
    };

    const onBlurCustomPoints = () => {
        setQuestions(updateWeightage(customPoints, currQueDisplay - 1, questions));
    };

    const onBlurCustomGetReadyTime = (event) => {
        const value = event.target.value;
        if(value.trim() === "") {
            return
        }
        const seconds = Math.max(0, value);
        if (seconds === 0 || seconds === 60) {
            setCustomGetReadyTimeLimit("10");
            setGetReadyTimeLimit("10");
            setQuestions(
                updateGetReadyTimeLimit(
                    DEFAULT_GET_READY_TIME_LIMIT,
                    currQueDisplay - 1,
                    questions
                )
              );
        } else {
            const time = seconds;
            // setCustomGetReadyTimeLimit(time);
            setGetReadyTimeLimit(time);
            setQuestions(
                updateGetReadyTimeLimit(
                    time,
                    currQueDisplay - 1,
                    questions
                )
            );
        }
    };

    const onChangeCustomGetReadyTime = (event) => {
        let value = event.target.value.replace(/[^0-9]/g, '');
        if (value.length > 2) {
            return;
        }
        setCustomGetReadyTimeLimit(value);
    };

    const handleEntriesUpdate = (entries) => {
        setEntries(entries);
        setQuestions(updateEntries(entries, currQueDisplay - 1, questions));
    };

    const onChangeCustomEntries = (e) => {
        let entries = e.target.value.replace(/[^0-9]/g, '');
        entries = parseInt(entries);
        if (entries > 10) {
            return;
        }
        setCustomEntries(entries);
        setEntries(entries);
    };

    const onBlurCustomEntries = (e) => {
        setQuestions(updateEntries(customEntries, currQueDisplay - 1, questions));
    };

    useEffect(() => {
        // setCollapsedQuestionType(true);
        // setCollapsedTimeLimit(true);
        // setCollapsedPoints(true);
        // setCollapsedGetReadyTimeLimit(true);

        setQuestionType(questionCodeToName(questions[currQueDisplay - 1].questionType));
        let customTime = timeSecondsToCustom(questions[currQueDisplay - 1].timeLimit);
        if (isBank && questions[currQueDisplay - 1].timeLimit === 0) {
            setTimeLimit("00:15");
        }
        else {
            setTimeLimit(customTime);
        }
        setCustomTimeLimit(timeLimitOptions.current.includes(customTime) ? "" : customTime);
        
        let customPoints = questions[currQueDisplay - 1].weightage;
        setPoints(customPoints);
        setCustomPoints(pointsOptions.current.includes("" + customPoints) ? "" : customPoints);

        if (!!questions[currQueDisplay - 1].getReadyTimeLimit) {
            let customGRTime = questions[currQueDisplay - 1].getReadyTimeLimit;
            setGetReadyTimeLimit(customGRTime);
            setCustomGetReadyTimeLimit(getReadyTimeLimitOptions.current.includes("" + customGRTime) ? "" : customGRTime);
        }

        if (!!questions[currQueDisplay - 1].entries) {
            let customEntries = questions[currQueDisplay - 1].entries;
            setEntries(customEntries);
            setCustomEntries(entriesOptions.current.includes("" + customEntries) ? "" : customEntries);
        }
    }, [questions, currQueDisplay, pollType]);

    return (
    <>
        <Container style={{height: 'calc(100% - 45px)'}}>
            {/* {!!pollType &&
                <Col>
                    <Row>
                        <p className="pollDetailTitle">Poll Details</p>
                    </Row>
                    {pollType.toUpperCase() !== POLL_TYPE.SURVEY && 
                    <Row className="justify-content-md-center">
                        <Col>
                            <YuJaToggle style={{fontSize: 15}} toggled={showAnswerOnViewer} onClick={() => setShowAnswerOnViewer(!showAnswerOnViewer)} label={"Show Correct Answer to Viewers"}/>
                        </Col>
                    </Row>
                    }

                    <Row className="justify-content-md-center">
                        <Col>
                            <YuJaToggle style={{fontSize: 15}} toggled={anonymousJoin} onClick={() => setAnonymousJoin(!anonymousJoin)} label={DISABLE_ANONYMOUS_SIDE_BAR}/>
                        </Col>
                    </Row>

                    {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                    <>
                    <hr />

                    <Row className="justify-content-md-center">
                        <Col>
                            <YuJaButton title={"Configure Gradebook Options"} onClick={() => setShowGradebookOptions(true)} style={{width: "100%"}}>
                                {CONFIGURE_GRADEBOOK_OPTIONS_TEXT}
                            </YuJaButton>
                        </Col>
                    </Row>
                    </>
                    }
                    
                </Col>
            } */}
            <div className= "question-detail-side-bar-height" style={{overflowY: "auto", maxHeight: "70vh", paddingRight: "2px"}}>
            <Col>
                {/* <Row>
                    <p className="questionDetailTitle">Question Details</p>
                </Row> */}

                {(isBank || (!!pollType && pollType.toUpperCase() !== POLL_TYPE.SURVEY)) &&
                    <>
                        <div className={`animated-drop-down ${!collapsedTimeLimit? "open" : "closed"}`}> 
                        {collapsedTimeLimit ?
                        <div className="question-detail-collapsed-panel justify-content-md-center">
                            <Col>
                                <div className="detailSidebarWrapper" style={{justifyContent: "space-between", gap: "10px"}} onClick={() => setCollapsedTimeLimit(false)} >
                                    {/* <TimeLimitIcon width="25px" height="25px"/> */}
                                    <div style={{display: "flex"}}> 
                                    <DropRight tabIndex={0} role={BUTTON} aria-label={CREATE_POLL_CARROT} className="dropIcon" style={{marginLeft: "0px" }}/>
                                    <div className="detailSidebarTitle" style={{fontFamily: "Geomanist" , fontSize: "14px", fontWeight: "400"}}>
                                        Time Limit
                                    </div>
                                    </div> 
                                    <div className="question-detail-collapsed-panel-icon-wrapper" style={{fontSize: "14px", fontFamily: "Geomanist", width: parseInt(points) >= 100 ? "76px" : ""}}> 
                                            {TimeCustomToStringLabel(timeLimit)}
                                    </div> 
                                </div>

                                {/* <div style={{width: "100%", margin: "10px 0px 10px 0px", display: isNarrow ? "block" : "flex"}}>
                                    <div
                                        className="collapsed-custom-box"
                                        style={{
                                            width: isNarrow ? "100%" : "50%",
                                            background: "#0277AD"
                                        }}
                                    >
                                        <div
                                            className="collapsed-custom-text"
                                            style={{ background: "#0277AD", color: "#FFFFFF" }}
                                        >
                                            {timeLimit}
                                        </div>
                                        <div
                                            className="custom-caption"
                                            style={{ color: "#FFFFFF"}}
                                        >
                                            Minute : Second
                                        </div>
                                    </div>
                                </div> */}
                            </Col>
                        </div>
                        :
                        <div className="question-detail-panel justify-content-md-center">
                            <Col>
                                <div className="detailSidebarWrapper" style={{alignItems: "center", gap: "10px"}}>
                                    {/* <TimeLimitIcon width="25px" height="25px"/>
                                    <div className="detailSidebarTitle">
                                        Time Limit
                                    </div> */}
                                    <DropDown tabIndex={0} aria-label={CREATE_POLL_CARROT_2} role={BUTTON}  className="dropIcon" onClick={() => setCollapsedTimeLimit(true)} style={{marginLeft: "0px" }}/>
                                    <p tabIndex={0} role={TEXTBOX} className="questionDetailDesc" style={{marginBottom: "0px", fontSize: "14px", color: "#000"}}>Choose the Question's Time Limit.</p>
                                </div>

                                {Object.entries(timeLimitOptions.current).map((optionData, index) => {
                                    if (timeLimitRow.current.length === optionsPerRow) {
                                        timeLimitRow.current = [];
                                    }
                                    timeLimitRow.current.push(optionData);
                                    if (index === timeLimitOptions.current.length - 1 || timeLimitRow.current.length === optionsPerRow) {
                                        return (
                                            <div key={index}>
                                                <TimeLimitOptionRow
                                                    timeLimitRow={timeLimitRow.current}
                                                    timeLimit={timeLimit}
                                                    // customTimeLimit={customTimeLimit}
                                                    handleTimeLimitUpdate={handleTimeLimitUpdate}
                                                />
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}

                                {!validCustomTime(customTimeLimit) || timeLimitOptions.current.some(t => isCustomEqual(t, customTimeLimit)) ? null :
                                    <div className="options-row align-left">
                                        <OptionButton
                                            // key={"custom-time-limit-button"}
                                            value={customTimeLimit}
                                            textDisplay={timeCustomToString(customTimeLimit)}
                                            valueToEqual={timeLimit}
                                            compareValue={isCustomEqual}
                                            handleClick={handleTimeLimitUpdate}
                                            isCustom={true}
                                        />
                                    </div>
                                }

                                <div style={{width: "100%", margin: "10px 0px 10px 0px", display: isNarrow ? "block" : "flex"}}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <div
                                            className="custom-icon"
                                            // style={{height: isNarrow ? "32px" : "60px"}}
                                        >
                                            <CustomTimeLimitIcon width="32px" height="32px"/>
                                        </div>

                                        <div
                                            className="custom-text"
                                            style={{
                                                width: isNarrow ? "calc(100% - 52px)" : "45px",
                                                // height: isNarrow ? "32px" : "60px"
                                            }}
                                        >
                                            Custom
                                        </div>
                                    </div>

                                    <div
                                        className="custom-box"
                                        style={{width: isNarrow ? "100%" : "calc(50% + 35px)", // 100% = 2 * x(equal paddings) + 3 * 70px(box width) => 140px + x = 50% + 35px
                                            background: (isCustomEqual(customTimeLimit, timeLimit) &&
                                                !timeLimitOptions.current.some(t => isCustomEqual(t, customTimeLimit))) ? "#42296E"  : "#FFFFFF"}}
                                    >
                                        <input
                                            className="custom-input"
                                            aria-label={CUSTOM_TIME_LIMIT}
                                            type="text"
                                            pattern="[0-5][0-9]:[0-5][0-9]"
                                            placeholder="00:00"
                                            value={customTimeLimit}
                                            onChange={onChangeCustomTime}
                                            onBlur={onBlurCustomTime}
                                            style={{background: (isCustomEqual(customTimeLimit, timeLimit) &&
                                                    !timeLimitOptions.current.some(t => isCustomEqual(t, customTimeLimit))) ? "#42296E"  : "#FFFFFF",
                                                color: (isCustomEqual(customTimeLimit, timeLimit) &&
                                                    !timeLimitOptions.current.some(t => isCustomEqual(t, customTimeLimit))) ? "#FFFFFF" : "#000000", 
                                                fontFamily: "Geomanist",
                                                fontSize: "15px",
                                                fontWeight: "700", 
                                                lineHeight: "15px", 
                                                    }}
                                        />
                                        <div
                                            className="custom-caption"
                                            style={{color: (isCustomEqual(customTimeLimit, timeLimit) &&
                                                    !timeLimitOptions.current.some(t => isCustomEqual(t, customTimeLimit))) ? "#FFFFFF" : "#000000"}}
                                        >
                                            Minute : Second
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>}
                        </div> 

                        {/* <hr/> */}
                        <div className={`animated-drop-down ${!collapsedPoints? "open" : "closed"}`}> 
                        {collapsedPoints ? 
                        <div className="question-detail-collapsed-panel justify-content-md-center">
                            <Col>
                                <div className="detailSidebarWrapper" style={{justifyContent: "space-between", gap: "10px"}} onClick={() => setCollapsedPoints(false)}>
                                    {/* <TimeLimitIcon width="25px" height="25px"/> */}
                                    <div style={{display: "flex"}}> 
                                    <DropRight tabIndex={0} role={BUTTON} aria-label={CREATE_POLL_CARROT_3} className="dropIcon" style={{marginLeft: "0px" }}/>
                                    <div className="detailSidebarTitle" style={{fontFamily: "Geomanist" , fontSize: "14px", fontWeight: "400"}}>
                                        Points
                                    </div>
                                    </div> 
                                    <div className="question-detail-collapsed-panel-icon-wrapper" style={{fontSize: "14px", fontFamily: "Geomanist", width: parseInt(points) >= 100 ? "76px" : ""}}> 
                                        {points} Points
                                    </div>
                                    {/* <WeightageIcon width="25px" height="25px"/> */}
                                </div>

                                {/* <div style={{
                                    width: "100%",
                                    margin: "10px 0px 1px 0px",
                                    display: isNarrow ? "block" : "flex"
                                }}>
                                    <div
                                        className="collapsed-custom-box"
                                        style={{
                                            width: isNarrow ? "100%" : "50%",
                                            background: "#0277AD"
                                        }}
                                    >
                                        <div
                                            className="collapsed-custom-text"
                                            style={{ background: "#0277AD", color: "#FFFFFF" }}
                                        >
                                            {points}
                                        </div>
                                        <div
                                            className="custom-caption"
                                            style={{ color: "#FFFFFF" }}
                                        >
                                            Points
                                        </div>
                                    </div>
                                </div> */}
                            </Col>
                        </div> :
                        <div className="question-detail-panel justify-content-md-center">
                            <Col>
                                    <div className="detailSidebarWrapper" style={{alignItems: "center", gap: "10px"}}>
                                    {/* <WeightageIcon width="25px" height="25px"/> */}
                                    <DropDown tabIndex={0} aria-label={CREATE_POLL_CARROT_4} role={BUTTON} className="dropIcon" onClick={() => setCollapsedPoints(true)} style={{marginLeft: "0px" }}/>
                                    <p tabIndex={0} role={TEXTBOX} className="questionDetailDesc" style={{marginBottom: "0px", fontSize: "14px", color: "#000"}} >Enter how many points earned.</p>
                                    {/* <div className="detailSidebarTitle">
                                        Points
                                    </div> */}
                                    {/* <DropUp className="dropIcon" onClick={() => setCollapsedPoints(true)}/> */}
                                </div>

                                {Object.entries(pointsOptions.current).map((optionData, index) => {
                                    if (pointsRow.current.length === optionsPerRow) {
                                        pointsRow.current = [];
                                    }
                                    pointsRow.current.push(optionData);
                                    if (index === pointsOptions.current.length - 1 || pointsRow.current.length === optionsPerRow) {
                                        return (
                                            <div key={index} style={{width: "100%"}}>
                                                <PointsOptionRow
                                                    pointsRow={pointsRow.current}
                                                    points={points}
                                                    // customPoints={customPoints}
                                                    handlePointsUpdate={handlePointsUpdate}
                                                />
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}

                                {(customPoints === "" || isNaN(customPoints) || parseInt(customPoints) === 0) ||
                                    pointsOptions.current.some(p => comparePoints(p, customPoints)) ? null :
                                    <div className="options-row align-left">
                                        <OptionButton
                                            label={weightagePointsToString(customPoints)}
                                            // key={"custom-points-button"}
                                            value={customPoints}
                                            textDisplay={weightagePointsToString(customPoints)}
                                            valueToEqual={points}
                                            compareValue={comparePoints}
                                            handleClick={handlePointsUpdate}
                                            isCustom={true}
                                        />
                                    </div>
                                }

                                <div style={{
                                    width: "100%",
                                    margin: "10px 0px 1px 0px",
                                    display: isNarrow ? "block" : "flex"
                                }}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <div className="custom-icon">
                                            <CustomWeightageIcon width="32px" height="32px"/>
                                        </div>

                                        <div
                                            className="custom-text"
                                            style={{width: isNarrow ? "calc(100% - 52px)" : "45px"}}
                                        >
                                            Custom
                                        </div>
                                    </div>

                                    <div
                                        className="custom-box"
                                        style={{
                                            width: isNarrow ? "100%" : "calc(50% + 35px)",
                                            background: (comparePoints(customPoints, points) &&
                                                !pointsOptions.current.some(p => comparePoints(customPoints, p))) ? "#42296E"  : "#FFFFFF"
                                        }}
                                    >
                                        <input
                                            className="custom-input"
                                            aria-label={CUSTOM_POINTS}
                                            type="number"
                                            // pattern="[0-9]*"
                                            max="100"
                                            // maxLength="4"
                                            placeholder="00"
                                            value={customPoints}
                                            onChange={onChangeCustomPoints}
                                            onBlur={onBlurCustomPoints}
                                            style={{
                                                background: (comparePoints(customPoints, points) &&
                                                    !pointsOptions.current.some(p => comparePoints(customPoints, p))) ? "#42296E"  : "#FFFFFF",
                                                color: (parseInt(customPoints) === parseInt(points) &&
                                                    !pointsOptions.current.some(p => comparePoints(customPoints, p))) ? "#FFFFFF" : "#000000"
                                            }}
                                        />
                                        <div
                                            className="custom-caption"
                                            style={{
                                                color: (comparePoints(customPoints, points) &&
                                                    !pointsOptions.current.some(p => comparePoints(customPoints, p))) ? "#FFFFFF" : "#000000"
                                            }}
                                        >
                                            Type Custom Points
                                        </div>
                                    </div>
                                </div>
                                <div className="max-points float-end">Max 100</div>
                            </Col>
                        </div>}
                        </div>
                    </>
                }
                  <div className={`animated-drop-down ${!collapsedGetReadyTimeLimit ? "open" : "closed"}`}> 
                {collapsedGetReadyTimeLimit ?
                <div className="question-detail-collapsed-panel justify-content-md-center">
                    <Col>
                        <div className="detailSidebarWrapper" style={{justifyContent: "space-between", gap: "10px"}} onClick={() => setCollapsedGetReadyTimeLimit(false)}>
                            {/* <TimeLimitIcon width="25px" height="25px"/> */}
                            <div style={{display: "flex"}}> 
                                    <DropRight tabIndex={0} role={BUTTON} aria-label={CREATE_POLL_CARROT_5} className="dropIcon" style={{marginLeft: "0px" }}/>
                                <div className="detailSidebarTitle" style={{fontFamily: "Geomanist" , fontSize: "14px", fontWeight: "400"}}>
                                    Countdown
                                </div>
                                </div> 
                                <div className="question-detail-collapsed-panel-icon-wrapper" style={{fontSize: "14px", fontFamily: "Geomanist", width: ((isBank || pollType.toUpperCase() === POLL_TYPE.SURVEY) && parseInt(entries) >= 10 || parseInt(points) >= 100) ? "76px" : ""}}> 
                                    {getReadyTimeLimit} Secs
                                </div>
                            {/* <DropDown className="dropIcon" onClick={() => setCollapsedGetReadyTimeLimit(false)}/> */}
                        </div>

                        {/* <div style={{width: "100%", margin: "10px 0px 10px 0px", display: isNarrow ? "block" : "flex"}}>
                            <div
                                className="collapsed-custom-box"
                                style={{
                                    width: isNarrow ? "100%" : "50%",
                                    background: "#0277AD"
                                }}
                            >
                                <div
                                    className="collapsed-custom-text"
                                    style={{ background: "#0277AD", color: "#FFFFFF" }}
                                >
                                    {getReadyTimeLimit}
                                </div>
                                <div
                                    className="custom-caption"
                                    style={{ color: "#FFFFFF"}}
                                >
                                    Second
                                </div>
                            </div>
                        </div> */}
                    </Col>
                </div>
                :
                <div className="question-detail-panel justify-content-md-center">
                    <Col>
                        <div className="detailSidebarWrapper" style={{alignItems: "center", gap: "10px"}}>
                            <DropDown tabIndex={0} aria-label={CREATE_POLL_CARROT_6} role={BUTTON} className="dropIcon" onClick={() => setCollapsedGetReadyTimeLimit(true)} style={{marginLeft: "0px" }}/>
                            <p tabIndex={0} role={TEXTBOX} className="questionDetailDesc" style={{marginBottom: "0px", fontSize: "14px", color: "#000"}}>Specify the length of the initial countdown.</p>
                        </div>

                        {Object.entries(getReadyTimeLimitOptions.current).map((optionData, index) => {
                            if (getReadyTimeLimitRow.current.length === optionsPerRow) {
                                getReadyTimeLimitRow.current = [];
                            }
                            getReadyTimeLimitRow.current.push(optionData);
                            if (index === getReadyTimeLimitOptions.current.length - 1 || getReadyTimeLimitRow.current.length === optionsPerRow) {
                                return (
                                    <div key={index}>
                                        <TimeLimitOptionRow
                                            timeLimitRow={getReadyTimeLimitRow.current}
                                            timeLimit={getReadyTimeLimit}
                                            // customTimeLimit={customTimeLimit}
                                            handleTimeLimitUpdate={handleGetReadyTimeLimitUpdate}
                                            forGetReady={true}
                                        />
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}

                        {customGetReadyTimeLimit === "" ||
                        parseInt(customGetReadyTimeLimit) < 0 ||
                        parseInt(customGetReadyTimeLimit) > 99 ||
                        getReadyTimeLimitOptions.current.some(t => t == customGetReadyTimeLimit) ?
                            null
                            :
                            <div className="options-row align-left">
                                <OptionButton
                                    label={customGetReadyTimeLimit + " Seconds"}
                                    // key={"custom-time-limit-button"}
                                    value={customGetReadyTimeLimit}
                                    textDisplay={customGetReadyTimeLimit + " Sec" + (customGetReadyTimeLimit > 1 ? "s" : "")}
                                    valueToEqual={getReadyTimeLimit}
                                    compareValue={(x, y) => (x == y)}
                                    handleClick={handleGetReadyTimeLimitUpdate}
                                    isCustom={true}
                                />
                            </div>
                        }

                        <div style={{width: "100%", margin: "10px 0px 10px 0px", display: isNarrow ? "block" : "flex"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div
                                    className="custom-icon"
                                    // style={{height: isNarrow ? "32px" : "60px"}}
                                >
                                    <CustomTimeLimitIcon width="32px" height="32px"/>
                                </div>

                                <div
                                    className="custom-text"
                                    style={{
                                        width: isNarrow ? "calc(100% - 52px)" : "45px",
                                        // height: isNarrow ? "32px" : "60px"
                                    }}
                                >
                                    Custom
                                </div>
                            </div>

                            <div
                                className="custom-box"
                                style={{width: isNarrow ? "100%" : "calc(50% + 35px)", // 100% = 2 * x(equal paddings) + 3 * 70px(box width) => 140px + x = 50% + 35px
                                    background: (customGetReadyTimeLimit == getReadyTimeLimit &&
                                        !getReadyTimeLimitOptions.current.some(t => t == customGetReadyTimeLimit)) ? "#42296E" : "#FFFFFF"}}
                            >
                                <input
                                    className="custom-input"
                                    type="number"
                                    // pattern="[0-9]*"
                                    min="1"
                                    max="99"
                                    // maxLength="2"
                                    placeholder="00"
                                    aria-label={CUSTOM_COUNTDOWN}
                                    value={customGetReadyTimeLimit}
                                    onChange={onChangeCustomGetReadyTime}
                                    onBlur={onBlurCustomGetReadyTime}
                                    style={{background: (customGetReadyTimeLimit == getReadyTimeLimit &&
                                            !getReadyTimeLimitOptions.current.some(t => t == customGetReadyTimeLimit)) ? "#42296E" : "#FFFFFF",
                                        color: (customGetReadyTimeLimit == getReadyTimeLimit &&
                                            !getReadyTimeLimitOptions.current.some(t => t == customGetReadyTimeLimit)) ? "#FFFFFF" : "#000000"}}
                                />
                                <div
                                    className="custom-caption"
                                    style={{color: (customGetReadyTimeLimit == getReadyTimeLimit &&
                                            !getReadyTimeLimitOptions.current.some(t => t == customGetReadyTimeLimit)) ? "#FFFFFF" : "#000000"}}
                                >
                                    Second
                                </div>
                            </div>
                        </div>
                    </Col>
                </div>}
                </div> 

                {questionType === QUESTION_TYPES.WC.description && <>
                    <div className={`animated-drop-down ${!collapsedEntries ? "open" : "closed"}`}> 
                    {collapsedEntries ?
                    <div className="question-detail-collapsed-panel justify-content-md-center">
                        <Col>
                            <div className="detailSidebarWrapper" style={{justifyContent: "space-between", gap: "10px"}} onClick={() => setCollapsedEntries(false)}>
                                 <div style={{display: "flex"}}> 
                                 <DropRight className="dropIcon"/>
                                <div className="detailSidebarTitle" style={{fontFamily: "Geomanist" , fontSize: "14px", fontWeight: "400"}}>
                                    {ENTRIES_TITLE}
                                </div>
                                </div>
                                <div className="question-detail-collapsed-panel-icon-wrapper" style={{fontSize: "14px", fontFamily: "Geomanist", width: parseInt(entries) >= 10 ? "76px" : ""}}> 
                                    {entries + " " + ENTRIES_TEXT}
                                </div>
                            </div>

                            {/* <div style={{
                                width: "100%",
                                margin: "10px 0px 1px 0px",
                                display: isNarrow ? "block" : "flex"
                            }}>
                                <div
                                    className="collapsed-custom-box"
                                    style={{
                                        width: isNarrow ? "100%" : "50%",
                                        background: "#0277AD"
                                    }}
                                >
                                    <div
                                        className="collapsed-custom-text"
                                        style={{ background: "#0277AD", color: "#FFFFFF" }}
                                    >
                                        {entries}
                                    </div>
                                    <div
                                        className="custom-caption"
                                        style={{ color: "#FFFFFF" }}
                                    >
                                        {ENTRIES_TEXT}
                                    </div>
                                </div>
                            </div> */}
                        </Col>
                    </div> :
                    <div className="question-detail-panel justify-content-md-center">
                        <Col>
                            <div className="detailSidebarWrapper" style={{alignItems: "center", gap: "10px"}}>
                                <DropDown className="dropIcon" onClick={() => setCollapsedEntries(true)} style={{marginLeft: "0px" }}/>
                                <p style={{marginBottom: "0px"}} className="questionDetailDesc">{ENTRIES_SUBTITLE}</p>
                            </div>

                            {Object.entries(entriesOptions.current).map((optionData, index) => {
                                if (entriesRow.current.length === optionsPerRow) {
                                    entriesRow.current = [];
                                }
                                entriesRow.current.push(optionData);
                                if (index === entriesOptions.current.length - 1 || entriesRow.current.length === optionsPerRow) {
                                    return (
                                        <div key={index} style={{width: "100%"}}>
                                            <EntriesOptionRow
                                                entriesRow={entriesRow.current}
                                                entries={entries}
                                                // customEntries={customEntries}
                                                handleEntriesUpdate={handleEntriesUpdate}
                                            />
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}

                            {(customEntries === "" || isNaN(customEntries) || parseInt(customEntries) === 0) ||
                                entriesOptions.current.some(e => compareEntries(e, customEntries)) ? null :
                                <div className="options-row align-left">
                                    <OptionButton
                                        // key={"custom-entries-button"}
                                        value={customEntries}
                                        textDisplay={entriesToString(customEntries)}
                                        valueToEqual={entries}
                                        compareValue={compareEntries}
                                        handleClick={handleEntriesUpdate}
                                        isCustom={true}
                                    />
                                </div>
                            }

                            <div style={{
                                width: "100%",
                                margin: "10px 0px 1px 0px",
                                display: isNarrow ? "block" : "flex"
                            }}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div className="custom-icon">
                                        <CustomEntriesIcon width="32px" height="32px"/>
                                    </div>

                                    <div
                                        className="custom-text"
                                        style={{width: isNarrow ? "calc(100% - 52px)" : "45px"}}
                                    >
                                        {CUSTOM_TEXT}
                                    </div>
                                </div>

                                <div
                                    className="custom-box"
                                    style={{
                                        width: isNarrow ? "100%" : "calc(50% + 35px)",
                                        background: (compareEntries(customEntries, entries) &&
                                            !entriesOptions.current.some(e => compareEntries(customEntries, e))) ? "#42296E" : "#FFFFFF"
                                    }}
                                >
                                    <input
                                        className="custom-input"
                                        type="number"
                                        // pattern="[0-9]*"
                                        min="1"
                                        max="10"
                                        // maxLength="4"
                                        placeholder="0"
                                        value={customEntries}
                                        onChange={onChangeCustomEntries}
                                        onBlur={onBlurCustomEntries}
                                        style={{
                                            background: (compareEntries(customEntries, entries) &&
                                                !entriesOptions.current.some(e => compareEntries(customEntries, e))) ? "#42296E" : "#FFFFFF",
                                            color: (compareEntries(customEntries, entries) &&
                                                !entriesOptions.current.some(e => compareEntries(customEntries, e))) ? "#FFFFFF" : "#000000"
                                        }}
                                    />
                                    <div
                                        className="custom-caption"
                                        style={{
                                            color: (compareEntries(customEntries, entries) &&
                                                !entriesOptions.current.some(e => compareEntries(customEntries, e))) ? "#FFFFFF" : "#000000"
                                        }}
                                    >
                                        {ENTRIES_TEXT}
                                    </div>
                                </div>
                            </div>
                            <div className="max-entries float-end">{CUSTOM_ENTRIES_FOOTER}</div>
                        </Col>
                    </div>}
                    </div>
                </>}
            </Col>
            </div>
        </Container>
    </>);
}
