import { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { BUTTON, CREATOR_MENU_LIST, POLL_TYPE, ROLES, TEXTBOX } from "../../utils/constants";
import { transferConstantPollType } from "../../utils/helpers";
import { questionError } from "../../utils/questionUtils";
import CreatorActivePolls from "./CreatorActivePolls";
import CreatorModifiedPolls from "./CreatorModifiedPolls";
import CreatorRecentResults from "./CreatorRecentResults";
import CreatorSupportCenter from "./CreatorSupportCenter";
import styles from "./DashboardPage.module.css";

export default function CreatorDashboard() {
    const history = useHistory();
    const { pathname } = useLocation();
    const [, getSession] = useLocalStorageNew("session", {});
    const { getCourseByUser } = CourseApiCalls();
    const { getPollsFromUser, getActivePollsFromUser } = PollApiCalls();


    const [activePollsLoading, setActivePollsLoading] = useState(false);
    const [modifiedPollsLoading, setModifiedPollsLoading] = useState(false);
    const [recentResultLoading, setRecentResultLoading] = useState(false);
    const [activePollsInitialized, setActivePollsInitialized] = useState(false);
    const [modifiedPollsInitialized, setModifiedPollsInitialized] = useState(false);
    const [recentResultInitialized, setRecentResultInitialized] = useState(false);
    const [draftPolls, setDraftPolls] = useState([]);
    const [activePolls, setActivePolls] = useState([]);
    const [publishedPolls, setPublishedPolls] = useState([]);
    const [courseOptions, setCourseOptions] = useState([]);

    useEffect(() => {
        if(document.getElementById("engagePageIdentifier") != null) {
            document.getElementById("engagePageIdentifier").innerText = CREATOR_MENU_LIST[0].name;
        }
    }, []);

    const validatePoll = (poll) => {
        for (let q of poll.questions) {
          if (q.weightage > 1000) {
            return false;
          }
    
          let {quesError} = questionError(q, poll.pollType);
          if (quesError) {
            return false;
          }
        }
        return true;
    };

    const getPollInformation = async () => {
        let res = await getPollsFromUser(true);
        res = res.sort((p1, p2) => Date.parse(p2.updatedTimeSK) - Date.parse(p1.updatedTimeSK));
        const data1 = res.filter(p => p.pollSortKey === 'Draft' && validatePoll(p));
        const data2 = res.filter(p => p.pollSortKey !== 'Draft' && transferConstantPollType(p.pollType) === POLL_TYPE.GRADED_POLL);
        setDraftPolls(data1);
        setPublishedPolls(data2);
        setRecentResultLoading(false);
        setRecentResultInitialized(true);
    };

    const getActivePolls = async () => {
        setActivePollsLoading(true);
        let res = await getActivePollsFromUser();
        console.log(res);
        setActivePolls(res);
        setActivePollsLoading(false);
        setActivePollsInitialized(true);
    };


    const getCoursesOpts = async () => {
        const session = getSession();
        let res;
        if(session.idpType && session.intType) {
            res = await getCourseByUser(session.idpType.toLowerCase(), session.intType.toLowerCase());
        } else {
            res = await getCourseByUser();
        }

        if(res !== undefined) {
            const options = res.filter(course => course.courseRole && course.courseRole !== ROLES.VIEWER.value)
                .map(course => ({ value: course.courseId, label: `${course.courseCode} : ${course.courseName}` }));
                setCourseOptions(options);
        } else {
            setCourseOptions();
        }
    };

    const createPoll = () => {
        history.push({
            pathname: "/create",
            state: {pollType: "Graded Poll", fromNavbar: true, previous: pathname}
        });
    };

    useEffect(() => {
        getActivePolls();
        setModifiedPollsLoading(true);
        setRecentResultLoading(true);
        Promise.all([getPollInformation(), getCoursesOpts()]).finally(() => {
            setModifiedPollsLoading(false);
            setModifiedPollsInitialized(true);
        });

    }, []);


    return (
        <div className={styles.dashBoardContainer}>
            <div className={`${styles.panelContainer} ${styles.createPollPanel}`}>
                <span tabIndex={0} role={TEXTBOX}>To create a new poll, click on the Create Poll button.</span>
                <span tabIndex={0} role={BUTTON} className={styles.creatBtn} onClick={createPoll}><BsPlusLg style={{marginRight: "10px"}}/>Create Poll</span>
            </div>
            <div style={{display: "flex", height: "80%", justifyContent: "space-between"}}>
                <div style={{width: "45%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                    <CreatorModifiedPolls
                        initialized={modifiedPollsInitialized}
                        loading={modifiedPollsLoading}
                        data={draftPolls}
                        courseOptions={courseOptions}
                    />
                    <CreatorActivePolls
                        initialized={activePollsInitialized}
                        loading={activePollsLoading}
                        data={activePolls}
                    />
                </div>

                <div style={{width: "52%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                    <CreatorRecentResults initialized={recentResultInitialized} loading={recentResultLoading} data={publishedPolls}/>
                    <CreatorSupportCenter/>
                </div>
            </div>

        </div>
    );
}