import React from "react";
import { BiCheckDouble } from "react-icons/bi";
import "./styles.css";

export default function TableCorrectAnswersRow({ correctAnswers }) {
  const responseIconStyle = {
    margin: "3px",
    bottom: "10%",
    position: "relative",
    float: "left",
  };
  return (
    <td>
      <div className="student-response actual">
        <BiCheckDouble
          size={27}
          style={{ ...responseIconStyle, color: "#333333" }}
        />
        <div className="student-response-text">{correctAnswers}</div>
      </div>
    </td>
  );
}
