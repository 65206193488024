import React from "react";
import { YuJaTextBox } from "../components/standardization/YuJaTextBox";
import { CREATE_QUES_LABEL, QUESTION_LENGTH } from "../utils/constants";

export default function BaseQuestionTitle({
  questionTitle="",
  handleTitleUpdate,
  placeholder,
  editable,
}) {
  return (
    <>
      {editable ? (
          <YuJaTextBox
              aria-label={CREATE_QUES_LABEL}
              name={"question-text"}
              containerStyle={{color: "#006DC7", marginBottom: "22.6px", fontSize: "20px", borderRadius: "4.519px", border: "1.13px solid #858687", padding: "13.558px 28.247px 13.558px 20.337px"}}
              placeholder= {placeholder}
              value={questionTitle}
              maxLength={QUESTION_LENGTH}
              onChange={handleTitleUpdate}
              inputClass={"PollTitleInput"}
          />
        ) : (
          <div className="text-container-without-counter">
            <span className="poll-sub-text focus-outline-none cursor-default" style={{fontSize: "18px"}}>{placeholder}</span>
          </div>
        )
      }
  </>
  );
}
