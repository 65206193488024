import React , { useEffect, useState } from "react";
import { Spin } from "../../components/standardization/YuJaLoading";
import { Modal } from "react-bootstrap";
import "antd/dist/antd.min.css";
import 'rc-datepicker/lib/style.css';
import "./modal.css";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import "react-datepicker/dist/react-datepicker.css";
import {ReactComponent as TimerIcon} from "../../images/Timer.svg";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { removePrefix } from "../../utils/helpers";
import { notifyError } from "../../utils/helpers";
import { timePollCustomToSeconds, validCustomPollTime, timeSecondsToCustomNew} from "../../utils/questionUtils";
import DatePickerComponentNew from "../datePickerComponent";
import { ACTIVATE_TIME, MODAL_CLOSE_TEXT, MODAL_SAVE_TEXT, SCHEDULE_POLL_EDIT_MODAL_MESSAGE, SCHEDULE_POLL_EDIT_MODAL_TITLE, SCHEDULE_POLL_TIME_MESSAGE, TEXTBOX } from "../../utils/constants";
import { INVALID_SCHEDULE_END_DATE_ERROR, INVALID_SCHEDULE_END_TIME_ERROR, INVALID_SCHEDULE_START_TIME_ERROR, INVALID_TIME_LIMIT_ERROR, MISSING_SCHEDULE_START_END_TIME_ERROR, SCHEDULE_POLL_START_END_GAP_ERROR } from "../../utils/toast-message-constants";
import YuJaButton from "../standardization/YuJaButton";


export default function EditDateModal({setModalShow, show, pollKey, pollUniqueCode, setDate=null}) {
  const { editPublishedPollDataFromKey, getPublishedPollDataFromKey } = PollApiCalls();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [ pollTimeLimit, setPollTimeLimit ] = useState("00:30:00");
    const [ pollTimeLimitH, setPollTimeLimitH] = useState("00");
    const [ pollTimeLimitM, setPollTimeLimitM] = useState("30");
    const [ pollTimeLimitS, setPollTimeLimitS] = useState("00");
    const isLoading = false; 

    const [disableEditStart, setDisableEditStart] = useState(false);
    const [disableEditEnd, setDisableEditEnd] = useState(false);


    const handlePollTimeChangeH = (e) => {
        let hours = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
        setPollTimeLimitH(hours);
    
        if(hours === "") {
          hours = "00";
        } else if(hours.length === 1) {
          hours = "0"+hours;
        }
        setPollTimeLimit(hours + pollTimeLimit.substring(2));
      };

      const handlePollTimeChangeM = (e) => {
        let mins = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
        setPollTimeLimitM(mins);
    
          
        if(mins === "") {
          mins = "00";
        } else if(mins.length === 1) {
          mins = "0"+mins;
        }
        setPollTimeLimit(pollTimeLimit.substring(0,3) + mins + pollTimeLimit.substring(5));
      };

      const handlePollTimeChangeS = (e) => {
        let secs = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
        setPollTimeLimitS(secs);
    
        if(secs === "") {
          secs = "00";
        } else if(secs.length === 1) {
          secs = "0"+secs;
        }
        setPollTimeLimit(pollTimeLimit.substring(0,6) + secs);
      };


    const handleClose = () =>{
      setModalShow(false);
    };

    useEffect (async () => {
      if (pollKey && pollUniqueCode) {
        const result = await getPublishedPollDataFromKey(removePrefix(pollKey), pollUniqueCode.substring(7)); 
        if (result.poll && result.poll.startTime && result.poll.endTime) {
          let timeString = timeSecondsToCustomNew(result.poll.pollTimeLimit); 
          setPollTimeLimit(timeString);
          setPollTimeLimitH(timeString.substring(0,2));
          setPollTimeLimitM(timeString.substring(3,5));
          setPollTimeLimitS(timeString.substring(6));

          const tempStartDate = new Date(result.poll.startTime);
          const tempEndDate = new Date(result.poll.endTime);

          if (new Date() > tempStartDate) {
            setDisableEditStart(true);
          }
          if (new Date() > tempEndDate) {
            setDisableEditEnd(true);
          }
        }
      }
    }, [pollKey, pollUniqueCode])


    const handleSubmit = async () => {
      let body = {};
      let res;
      let pollKeyResult = removePrefix(pollKey);
      let pollCode = pollUniqueCode.substring(7); 
      if (startDate) {
          body.startTime = startDate; 
      }
      if(endDate) {
          body.endTime = endDate; 
      }
      if (!startDate && !endDate) {
          notifyError(MISSING_SCHEDULE_START_END_TIME_ERROR); 
          return; 
      }
      if (endDate < startDate ){
        notifyError(INVALID_SCHEDULE_END_DATE_ERROR);
        return; 
      }
      if (!disableEditEnd && endDate < new Date() ) {
        notifyError(INVALID_SCHEDULE_END_TIME_ERROR); 
        return ; 
      }
      if (!disableEditStart && startDate < new Date()) {
        notifyError(INVALID_SCHEDULE_START_TIME_ERROR);
        return;
      }
      if (endDate > startDate.getTime() + 30 * 24 * 60 * 60 * 1000) {
        notifyError(SCHEDULE_POLL_START_END_GAP_ERROR); 
        return ; 
      }

      body.pollTimeLimit = timePollCustomToSeconds(pollTimeLimit);
      console.log((endDate - startDate)/1000);
      if ((endDate - startDate)/1000 < body.pollTimeLimit) {
        notifyError(INVALID_TIME_LIMIT_ERROR);
        return ;
      }
      console.log(body);
      if(setDate) {
        setDate(startDate);
      }
      await editPublishedPollDataFromKey(body, pollKeyResult, pollCode);
      setModalShow(false);
  }


  const validateForm = () => {
      setPollTimeLimitH(pollTimeLimit.substring(0,2));
      setPollTimeLimitM(pollTimeLimit.substring(3,5));
      setPollTimeLimitS(pollTimeLimit.substring(6));
      if(!validCustomPollTime(pollTimeLimit)) {
        document.getElementById("error2").innerText = "Please enter a valid poll time that is 10+ seconds!";
        return false;
      }
    return true;
  };

    return (
        <Modal
          id="form-modal"
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop={isLoading ? "static" : true}
          dialogClassName= {"ed-modal"}
        >   
        <Spin tip="Loading..." size="large" spinning={isLoading}>
            <ModalClose onClick={handleClose} className="modal-close" />
            <Modal.Header
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              
            >
            <Modal.Title
              className="warning-title"
              id="contained-modal-title-vcenter"
            >
              {SCHEDULE_POLL_EDIT_MODAL_TITLE}
            </Modal.Title>
    
    
            </Modal.Header>
            <Modal.Body>
              <div style={{color: "#000", fontSize: "13px", fontFamily: "Geomanist", marginBottom: "23px"}}> 
              {SCHEDULE_POLL_EDIT_MODAL_MESSAGE}
              </div>
              <DatePickerComponentNew  startDate={startDate} endDate={endDate} setStartDate= {setStartDate} setEndDate={setEndDate} pollKey={pollKey} pollUniqueCode={pollUniqueCode}/>
                          <div style={{width: "100%", background: "white", display: "flex", marginTop: "25px", justifyContent: "space-between"}}>
                            <div tabIndex={0} role={TEXTBOX} aria-label={ACTIVATE_TIME} style={{color: "#000", fontFamily: "Geomanist", fontSize: "13.5px", fontWeight: "400", display: "flex", alignItems: "center"}}> 
                            {SCHEDULE_POLL_TIME_MESSAGE}
                            </div>
                            <div style={{width: "225px", height: "35px", borderRadius: "3.204px", border: "1px solid #D0D0D0", background: "#FFF", alignItems: "center", display: "flex", padding: "5.394px 11.549px 5.606px 11.451px"}}> 
                            <input
                              className="custom-input2"
                              type="text"
                              value={pollTimeLimitH}
                              onChange={handlePollTimeChangeH}
                              style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", height: "21px"}}
                            />
                            <div style={{alignItems: "end", justifyContent: "center", display: "flex", height: "21px"}}> : </div> 
                            <input
                              className="custom-input2"
                              type="text"
                              value={pollTimeLimitM}
                              onChange={handlePollTimeChangeM}
                              style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", height: "21px"}}
                            />
                             <div style={{alignItems: "end", justifyContent: "center", display: "flex", height: "21px"}}> : </div> 
                            <input
                              className="custom-input2"
                              type="text"
                              value={pollTimeLimitS}
                              onChange={handlePollTimeChangeS}
                              style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", height: "21px"}}
                            />
                            <div style={{flex: "1", justifyContent: "end", display: "flex"}}> 
                            <TimerIcon />
                            </div>
                            </div>
                        </div>
            </Modal.Body>
            <Modal.Footer>
              <YuJaButton onClick={handleClose} type={"secondary"}>{MODAL_CLOSE_TEXT}</YuJaButton>
              <YuJaButton
                  onClick={(e) => {
                      e.preventDefault();
                      if(validateForm()) {
                        handleSubmit();
                      }
                  }}
            >
                  {MODAL_SAVE_TEXT}
            </YuJaButton>
    
            </Modal.Footer>
            </Spin>
        </Modal>
        )
    }