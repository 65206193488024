import React from "react";
import { Col } from "react-bootstrap";
import { comparePoints, weightagePointsToString } from "../../utils/questionUtils";
import OptionButton from "./OptionButton";
import "./styles.css";

export default function PointsOptionRow({
  pointsRow,
  points,
  // customPoints,
  handlePointsUpdate
}) {
  return (
    <div className="options-row">
      {Object.values(pointsRow).map((pointsCol, idx) => {
        return (
          <Col key={idx} className={`d-flex no-padding options-row-outer ${idx % 3 === 0 ? "justify-content-start" : (idx % 3 === 1 ? "justify-content-center" : "justify-content-end")}`}>
            <OptionButton
              key={idx}
              value={parseInt(pointsCol[1])}
              textDisplay={weightagePointsToString(pointsCol[1])}
              valueToEqual={parseInt(points)}
              compareValue={comparePoints}
              // valueToNotEqual={customPoints}
              handleClick={handlePointsUpdate}
            />
          </Col>
        );
      })}
    </div>);
}
