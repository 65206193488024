import React, {useEffect, useRef, useState} from "react";
import styles from "./YuJaKebabMenu.module.css";
import {ReactComponent as MenuIcon} from "../../images/cebabe_menu_icon.svg"
import {useYuJaEventBus} from "./YuJaEventBusProvider";
import { ReactComponent as DropDown } from "../../images/dropdown_question.svg";
import {ReactComponent as FITBIcon} from "../../images/fitb_purple.svg";
import {ReactComponent as MCSSIcon} from "../../images/mcss_purple.svg";
import {ReactComponent as MHIcon } from "../../images/matching_purple.svg"
import {ReactComponent as SAIcon} from "../../images/sa_purple.svg";
import {ReactComponent as TFIcon} from "../../images/tf_purple.svg";
import {ReactComponent as ICIcon} from "../../images/clickable_purple.svg";
import {ReactComponent as WCIcon} from "../../images/wcloud_purple.svg";
import { CREATE_QUES_TYPE, QUESTION_TYPES, TEXTBOX } from "../../utils/constants";
import { questionCodeToName } from "../../utils/questionUtils";

export const CreatePollDropDown = ({isCreateOpen, setIsCreateOpen, questionType, children, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const iconRef = useRef();

    const open = () => {
            setIsCreateOpen(true); 
    }


    return (
        <div className={styles.container} >
            <div ref={iconRef} onClick={open} tabIndex={0} role={TEXTBOX} aria-label={questionCodeToName(questionType) + CREATE_QUES_TYPE} style={{padding: "8px 10px", display: "flex", justifyContent: "center", alignItems: "center", gap: "8.41px", whiteSpace: "nowrap", cursor: "pointer"}}> 
                {questionType === QUESTION_TYPES.MCSS.name && <MCSSIcon alt="MCSS Icon" style={{width: "24px" , height: "24px"}}/>}
                {questionType  === QUESTION_TYPES.SA.name && <SAIcon alt="SA Icon" style={{width: "24px" , height: "24px"}}/>}
                {questionType  === QUESTION_TYPES.TF.name && <TFIcon alt="TF Icon" style={{width: "24px" , height: "24px"}}/>}
                {questionType  === QUESTION_TYPES.FITB.name && <FITBIcon alt="FITB Icon" style={{width: "24px" , height: "24px"}}/>}
                {questionType  === QUESTION_TYPES.CI.name && <ICIcon alt="CI Icon" style={{width: "24px" , height: "24px"}}/>}
                {questionType  === QUESTION_TYPES.WC.name && <WCIcon alt="WC Icon" style={{width: "24px" , height: "24px"}}/>}
                {questionType  === QUESTION_TYPES.MH.name && <MHIcon alt="MH Icon" style={{width: "24px" , height: "24px"}}/>}
                {" " + questionCodeToName(questionType )}
            <DropDown/>
            </div>
            {
                children &&
                React.cloneElement(
                    children,
                    {
                        ...children.props,
                        isOpen:  isCreateOpen,
                        setIsOpen:  setIsCreateOpen,
                        iconRef: iconRef,
                    }
                )
            }
        </div>
    );
}


function Menu({isOpen, setIsOpen, iconRef, children}) {
    const menuRef = useRef();
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();

    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && menuRef.current && !iconRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            addClickEventHandler(handleGlobalClick);
        }

        return () => {
            removeClickEventHandler(handleGlobalClick);
        };
    }, [isOpen]);

    return (
        <>
            {isOpen &&
                <>
                    <div className={styles.createMenuContainer} ref={menuRef}>
                        <div className={`${styles.menuList}`} style={{width: "183px"}} >
                            {
                                children && Array.isArray(children) &&
                                    children.map((item, index) => (
                                       <>
                                           {item &&
                                               React.cloneElement(
                                                   item,
                                                   {
                                                       ...item.props,
                                                       id: index,
                                                       key: index,
                                                       isOpen: isOpen,
                                                       setIsOpen: setIsOpen
                                                   }
                                               )
                                           }
                                       </>
                                    ))
                            }


                            {
                                children && !Array.isArray(children) &&
                                    React.cloneElement(
                                        children,
                                        {
                                            ...children.props,
                                            isOpen: isOpen,
                                            setIsOpen: setIsOpen
                                        }
                                    )

                            }
                        </div>
                    </div>
                    {/*<div className={styles.backDrop} onClick={() => setIsOpen(false)}/>*/}
                </>
            }
        </>
    );
}


function Item({children, ariaLabel, isOpen, setIsOpen, ...props}) {
    const nodeRef = useRef();

    useEffect(() => {
            const textNode = nodeRef.current;
            if (textNode && textNode.offsetWidth < textNode.scrollWidth) {
                textNode.title = textNode.innerText;
            }
            console.log(`isOpen: ${isOpen}`)
    }, []);

    const onClick =(e) => {
        setIsOpen(false);
        if (props.onClick) {
            props.onClick(e);
        }

    }

    return (
        <div className={`${styles.menuItem}`} role={TEXTBOX} onClick={onClick} tabIndex={0} aria-label={ariaLabel}>
            <span ref={nodeRef} className={styles.menuItemText}>{children}</span>
        </div>
    );
}

CreatePollDropDown.Menu= Menu;
CreatePollDropDown.Item= Item;