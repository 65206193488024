import React, { useCallback, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory, useLocation } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import useMobileAccess from "../../hooks/useMobileAccess";
import {
  ACTION_HEADER,
  ACTIVATION_STATUS_HEADER,
  CREATE_POLL,
  CREATOR_MENU_LIST,
  DATE_HEADER,
  DEFAULT_ROWS_PER_PAGE,
  NO_POLLS_TEXT,
  POLLS,
  POLL_KEY,
  POLL_SORT_KEY,
  POLL_TITLE,
  POLL_TITLE_HEADER,
  POLL_TYPE_ACCESSOR,
  POLL_TYPE_HEADER,
  QUESTION_HEADER,
  TEXTBOX,
  TOTAL_QUESTIONS,
  UPDATED_DATE_PK
} from "../../utils/constants";
import PublishModal from "../CreateQuizPage/PublishModal";
import styles from "./ManagePolls.module.css";
import PollSearchForm from "./PollSearchForm";
import PollTable from "./PollTable";
import { ReactComponent as NoResultIllustration } from "./images/no_polls_found.svg";

export default function ManagePollsPage(props) {
  const location = useLocation();
  let pollCode;
  let pollKey;
  if (location.state && location.state.pollCode) {
    pollCode = location.state.pollCode;
    pollKey = location.state.pollKey;
  }
  const [ publishModalShow, setPublishModalShow ] = useState(pollCode != undefined);
  const [filter, setFilter] = useState({});
  const [pollData, setPollData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [dataReady, setDataReady] = useState(false);
  const { getPollsFromUser } = PollApiCalls();
  const history = useHistory();
  const [dateSort, setDateSort] = useState(0);
  const [titleSort, setTitleSort] = useState(1); 
  const [modeSort, setModeSort] = useState(1); 

  useEffect(() => {
    if(document.getElementById("engagePageIdentifier") != null) {
      document.getElementById("engagePageIdentifier").innerText = CREATOR_MENU_LIST[2].name;
    }
  }, []);


  const sortDateHelper = () => {
    if(!!pollData) {
      if(dateSort === 0){
        setPollData(pollData.sort(function (a, b) {
            return new Date(Date.parse(a.updatedTimeSK)) - new Date(Date.parse(b.updatedTimeSK));
          }));
          setDateSort(1);
        }
        else {
          setPollData(pollData.sort(function (a, b) {
            return new Date(Date.parse(b.updatedTimeSK)) - new Date(Date.parse(a.updatedTimeSK));
          }));
          setDateSort(0);
        }
      }
  }

  const sortTitleHelper = () => {
    if(!!pollData) {
        setPollData(pollData.sort(function (a, b) {
            const nameA = a.pollTitle.toLowerCase();
            const nameB = b.pollTitle.toLowerCase();

            // implement the logic that symbol has the highest priority then number followed by letters
            const getPriority = (name) => {
              const firstChar = name[0];
              if (!isNaN(firstChar)) {
                  return 1; // Titles starting with a number
              } else if (firstChar.match(/[a-zA-Z]/)) {
                  return 2; // Titles starting with a letter
              } else {
                  return 0; // Titles starting with a symbol
              }
          };
            const priorityA = getPriority(nameA);
            const priorityB = getPriority(nameB);

            if (priorityA < priorityB) return -1 * titleSort;
            if (priorityA > priorityB) return 1 * titleSort;

            if (nameA < nameB) return -1 * titleSort;
            if (nameA > nameB) return 1 * titleSort;
            return 0;
          }));
          setTitleSort(-titleSort);
      }
  }

  const sortModeHelper = () => {
    if(!!pollData) {
        setPollData(pollData.sort(function (a, b) {
            const nameA = a.pollType.toLowerCase();
            const nameB = b.pollType.toLowerCase();
      
            if (nameA < nameB) return -1 *  modeSort;
            if (nameA > nameB) return 1 * modeSort;
            return 0;
          }));
          setModeSort(-modeSort);
      }
  }


  const getPollInformation = async (pageIndex=0) => {
    setDataReady(false);
    const res = await getPollsFromUser(true);
    res.sort(function (a, b) {
      return new Date(Date.parse(b.updatedTimeSK)) - new Date(Date.parse(a.updatedTimeSK));
    });
    setPollData(res);
    setPageIndex(pageIndex);
    setDataReady(true);
  };

  useEffect(() => {
    getPollInformation();
  }, []);

  const handleSubmit = useCallback(({ ...filterObj }) => {
    setFilter(filterObj);
    setPageIndex(0);
  }, []);

  useEffect(() => {
    console.log(`filter:${JSON.stringify(filter)}`);
  }, [filter]);

  const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: DATE_HEADER, accessor: UPDATED_DATE_PK, width: isMobileScreen ? 100 : 120 },
    { Header: POLL_TITLE_HEADER, accessor: POLL_TITLE, width: 250 },
    { Header: POLL_TYPE_HEADER, accessor: POLL_TYPE_ACCESSOR, width: isMobileScreen ? 90 : 110 },
    { Header: QUESTION_HEADER, accessor: TOTAL_QUESTIONS, width: isMobileScreen ? 100 : 120 },
    { Header: ACTIVATION_STATUS_HEADER, accessor: POLL_SORT_KEY, width: isMobileScreen ? 160 : 180 },
    { Header: ACTION_HEADER, accessor: POLL_KEY, width: 155, id: 'action' }
  ];

  const clickCreate = () => {
    history.push({ pathname: "/create" });
  };

  const clickOkay = () => {
    history.push(`/poll/share/${pollKey}/${pollCode.uniqueCode}`);
  };

  const clickHide = () => {
    setPublishModalShow(false);
    getPollInformation();
  };


  return (
    <>
      {typeof(pollCode) != "undefined" && (
        <PublishModal
          show={publishModalShow}
          setModalShow={setPublishModalShow}
          pollCode={pollCode}
          handleClickClose={clickHide}
          handleClickOkay={clickOkay}
          isGenerated={true}
        />
      )}
      {dataReady && pollData && pollData.length !== 0 &&  (
        <>
          <div className={styles.container}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>{POLLS}</div>
            </div>
            <PollSearchForm handleSubmit={handleSubmit} />
            <PollTable columns={columns} data={pollData} pageIdx={pageIndex} globalFilterObj={filter} getPollInformation={getPollInformation} Datesort={sortDateHelper} dateSort={dateSort} Titlesort={sortTitleHelper} Modesort={sortModeHelper} titleSort={titleSort} modeSort={modeSort}/>
          </div>
        </>
      )}
      {dataReady && (!pollData || pollData.length === 0) && (
        <>
          <div className={styles.c2}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>{POLLS}</div>
            </div>
            <NoResultIllustration style={{width: "100%", maxHeight: "100%", marginRight: "15px"}}/>
            <div role={TEXTBOX} className={styles.text}>{NO_POLLS_TEXT}</div>
            <div className={styles.center}>
              <button
                className={styles.createButton}
                onClick={() => clickCreate()}
              >
                {CREATE_POLL}
              </button>
            </div>
          </div>
        </>
      )}
      {!dataReady && (
        <>
          <div className={styles.container}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} className={styles.titleBoxTitle}>{POLLS}</div>
            </div>
            <PollSearchForm handleSubmit={handleSubmit} disabled={true} />
            <div className={styles.skeletonTableContainer}>
              <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                <div className={styles.skeletonHeaderContainer}>
                  <div className={styles.skeletonHeaderCell}>{DATE_HEADER}</div>
                  <div className={styles.skeletonHeaderCell}>{POLL_TITLE_HEADER}</div>
                  <div className={styles.skeletonHeaderCell}>{POLL_TYPE_HEADER}</div>
                  <div className={styles.skeletonHeaderCell}>{QUESTION_HEADER}</div>
                  <div className={styles.skeletonHeaderCell}>{ACTIVATION_STATUS_HEADER}</div>
                  <div className={styles.skeletonHeaderCell}>{ACTION_HEADER}</div>
                  {/* <Skeleton height={16} width="3vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="3vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="3vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="5vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="7vw" className={styles.skeletonHeaderCell} />
                  <Skeleton height={16} width="4vw" className={styles.skeletonHeaderCell} /> */}
                </div>
                <div className={styles.skeletonBodyContainer}>
                  {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                    return (<>
                      <Skeleton height={20} width={`${6 + Math.random()}vw`} className={styles.skeletonBodyCell} />
                      <Skeleton height={20} width={`${3 + (Math.random()*7)}vw`} className={styles.skeletonBodyCell} />
                      <Skeleton height={20} width={`${idx % 2 === 0 ? 3.5 : 3}vw`} className={styles.skeletonBodyCell} />
                      <Skeleton height={20} width={`${1 + Math.random()}vw`} className={styles.skeletonBodyCell} />
                      <Skeleton height={20} width={`${2 + (Math.random()*3)}vw`} className={styles.skeletonBodyCell} />
                      <Skeleton height={20} width="2vw" className={styles.skeletonBodyCell} />
                    </>)
                  })}
                </div>
              </SkeletonTheme>
            </div>
          </div>
        </>
      )}
    </>
  );
}