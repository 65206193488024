import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useLoading } from "../../utils/LoadingContext";
import { IS_CORRECT_PREFIX, LOSE_CHANGE_MESSAGE, SEARCH_BOX_PLACEHOLDER, SEARCH_POLL, SEARCH_TEXT, SWITCH_POLL_MODAL_TEXT, SWITCH_POLL_MODAL_TITLE } from "../../utils/constants";
import { fetchImg, getAnswer } from "../../utils/helpers";
import { S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME } from "../../utils/properties";
import styles from "./GradeQuestion.module.css";
import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import {ReactComponent as SearchIcon} from "../../images/search_bar_icon.svg";
import YuJaButton from "../../components/standardization/YuJaButton";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import { FilterDropDown } from "./FilterDropDown";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";

export default function GradeQuestion({
    question,
    // questions,
    attemptNo,
    response,
    setResponse,
    responses,
    // setResponses,
    institutionId,
    pollKey,
    // pollCode,
    pollTitle,
    isCorrect,
    setIsCorrect,
    setChangedCorrect, 
    // handleClose,
    // autoUpdateScore,
    // autoSync
}) {
    // const history = useHistory();
    const { queTitle, serialNo, suffix="", image="" } = question;
    // const { gradeQuestion } = ResponseApiCalls();
    const { setLoading } = useLoading();
    const [questionImageURL, setQuestionImageURL] = useState("");
    const students = responses.filter(r => r.attempts).map(r => ({label: `${r.firstName}${r.lastName ? " " + r.lastName : ""}`, value: `${r.userId}`}));
    students.sort((a, b) => a.label.localeCompare(b.label));
    const [data, setData] = useState(students);
    const [student, setStudent] = useState(students.find(r => r.value === response.userId));
    const [isVideo, setIsVideo] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [currAttempt , setCurrAttempt] = useState(`${attemptNo}`);
    
    useEffect(() => {
        setStudent(!!response ? {label: `${response.firstName}${response.lastName ? " " + response.lastName : ""}`, value: `${response.userId}`} : {});
    }, []);
    
    // useEffect(() => {
    //     setIsCorrect(prevState => ({
    //         ...prevState,
    //         [response.userId]: !!response && response.attempts[attemptNo].hasOwnProperty(IS_CORRECT_PREFIX + serialNo) ? response.attempts[attemptNo][IS_CORRECT_PREFIX + serialNo] : false
    //       }));
    // }, [response]);
    
    useEffect(() => {
        setLoading(true);
        setResponse(responses.find(r => r.userId === student.value));
        setCurrAttempt("1");
        // if (!isCorrect.hasOwnProperty(student.value)){
        // setIsCorrect(prevState => ({
        //     ...prevState,
        //     [student.value]: !!response && response.attempts[attemptNo].hasOwnProperty(IS_CORRECT_PREFIX + serialNo) ? response.attempts[attemptNo][IS_CORRECT_PREFIX + serialNo] : false
        //   }));
        // }
        setLoading(false);
    }, [student]);

    useEffect(() => {
        async function fetchData() {
            if(question.directLinkEvp) {
                setQuestionImageURL(question.directLinkEvp);
                setIsVideo(true);
            }
            else if ((!!image || !!suffix) && institutionId && pollKey && serialNo) {
                let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;
                url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
                let img = await fetchImg(url);
                setQuestionImageURL(img ? img : "");
                setIsVideo(false);
            }
        }
        fetchData();
    }, [suffix, institutionId, pollKey, serialNo]);

    const SearchName = (keyword) => {
        let result = students.filter(student => student.label.toLowerCase().includes(keyword.toLowerCase()))
        setData(result.sort((a, b) => a.label.localeCompare(b.label)));
    }

    const FilterStudents =(correct) => {
        if (correct) {
            let result = students.filter(student => isCorrect.hasOwnProperty(student.value) && isCorrect[student.value]);
            setData(result.sort((a, b) => a.label.localeCompare(b.label)));
        } else {
            let result = students.filter(student => !isCorrect.hasOwnProperty(student.value) ||  !isCorrect[student.value]);
            setData(result.sort((a, b) => a.label.localeCompare(b.label)));
        }
    }

    const EditStudent = (studentId, correct) => {
        setIsCorrect(prevState => ({
              ...prevState,
              [studentId]: {
                ...prevState[studentId],
                [currAttempt]: correct,
              },
            }));
        setChangedCorrect(prevState => ({
                ...prevState,
                [studentId]: {
                  ...prevState[studentId],
                  [currAttempt]: correct,
                },
              }));
    }

    useEffect(() => {
        for (const response of responses) {
            if (!isCorrect.hasOwnProperty(response.userId) && response.userId === student.value ){
                setIsCorrect(prevState => ({
                    ...prevState,
                    [response.userId]: { [attemptNo] : !!response && response.attempts[attemptNo].hasOwnProperty(IS_CORRECT_PREFIX + serialNo) ? response.attempts[attemptNo][IS_CORRECT_PREFIX + serialNo] : false}
                  }));
            }
            else if (!isCorrect.hasOwnProperty(response.userId) && response.attempts) {
                setIsCorrect(prevState => ({
                    ...prevState,
                    [response.userId]: { "1" : !!response && response.attempts["1"].hasOwnProperty(IS_CORRECT_PREFIX + serialNo) ? response.attempts["1"][IS_CORRECT_PREFIX + serialNo] : false}
                  }));
            }
        }
    }, [responses])

    useEffect(() => {
        if (isCorrect.hasOwnProperty(student.value) && !isCorrect[student.value].hasOwnProperty(currAttempt) ){
          setIsCorrect(prevState => ({
            ...prevState,
            [student.value]: {
              ...prevState[student.value],
              [currAttempt]: !!response && response.attempts[currAttempt].hasOwnProperty(IS_CORRECT_PREFIX + serialNo) ? response.attempts[currAttempt][IS_CORRECT_PREFIX + serialNo] : false,
            },
          }));
        }
    }, [currAttempt])

    // const onSave = () => {
    //     // if (newResponse[IS_GRADED_PREFIX + serialNo] && response[IS_CORRECT_PREFIX + question.serialNo] === correct) {
    //     //     handleClose();
    //     //     return;
    //     // }

    //     // let newResponse = {...response};
    //     // newResponse[IS_GRADED_PREFIX + serialNo] = true;
    //     // newResponse[IS_CORRECT_PREFIX + serialNo] = correct;

    //     // var earnedPoints = 0;
    //     // var totalPoints = 0;
        
    //     // for (const question of questions) {
    //     //     if (newResponse[IS_CORRECT_PREFIX + question.serialNo]) {
    //     //         earnedPoints += question.weightage;
    //     //         console.log(`${question.weightage} / ${question.weightage}`);
    //     //     } else {
    //     //         console.log(`0 / ${question.weightage}`);
    //     //     }
    //     //     totalPoints += question.weightage;
    //     // }
    //     // const score = ((earnedPoints/totalPoints)*100).toFixed(2); // 2 Decimal Places
    //     // console.log(score);
    //     // autoUpdateScore(score);
    //     // autoSync(pollCode, newResponse["userId"], newResponse["score"]);
  
    //     // let newResponses = [...responses];
    //     // if (newResponses.find(r => r.userId === student.value)) {
    //     //     newResponses[newResponses.findIndex(r => r.userId === student.value)].attempts[attemptNo] = newResponse;
    //     // }
    //     // setResponses(newResponses);
    //     setLoading(true);
    //     try {
    //         var questionObj = question;
    //         questionObj["selectedOption"] = getAnswer();
    //         gradeQuestion(institutionId, pollKey, pollCode, response.userId, correct, questionObj, attemptNo);
    //         notifySuccess(GRADE_QUESTION_SUCCESS);
    //     } catch (error) {
    //         console.log(error);
    //         notifyError(GRADE_QUESTION_ERROR);
    //     }
    //     history.push({
    //         pathname: "/gradebook", 
    //         state: {
    //             pollKey : removePrefixNew(pollKey),
    //             uniqueCode: removePrefixNew(pollCode),
    //             autoSync: {
    //                 userId: response.userId,
    //                 // score: newResponses[newResponses.findIndex(r => r.userId === student.value)].attempts[0] // score picked from main attempt
    //             }
    //         }
    //     });
    //     handleClose();
    //     setLoading(false);
    // };

    // const getAnswer = () => {
    //     let answer = FIXED_ANSWER.UNANSWERED;
    //     if (!!response && response.attempts[attemptNo].hasOwnProperty("A" + serialNo)) {
    //         if (response.attempts[attemptNo]["A" + serialNo] !== FIXED_ANSWER.UNANSWERED) {
    //             answer = response.attempts[attemptNo]["A" + serialNo][0];
    //         }
    //     }
    //     return answer;
    // };

    return (
        <>
        <div style={{display: "flex"}}>
            <div style={{flex: "1", paddingRight: "25px", width: "50%", maxHeight: "400px", overflowY: "auto"}}>
                            <div style={{display: "flex", gap: "15px", alignItems: "center"}}> 
                            <div>
                            <FilterDropDown isDropDownOpen={isDropDownOpen} setIsDropDownOpen={setIsDropDownOpen}>
                            <FilterDropDown.Menu >
                                        <FilterDropDown.Item onClick={() => {FilterStudents(true)}}>
                                            <div style={{fontSize: "13px", fontFamily: "Geomanist", color: "#000"}}>
                                                Correct
                                            </div>
                                        </FilterDropDown.Item>

                                        <FilterDropDown.Item onClick={() => {FilterStudents(false)}}>
                                        <div style={{fontSize: "13px", fontFamily: "Geomanist", color: "#000"}}>
                                               Incorrect 
                                        </div>
                                        </FilterDropDown.Item>
                                </FilterDropDown.Menu>
                            </FilterDropDown>
                            </div>
                            <YuJaTextBox
                                label={SEARCH_POLL}
                                containerStyle={{fontSize: 13, flex: 1}}
                                placeholder={SEARCH_TEXT}
                                before={<SearchIcon style={{marginRight: 5, width: 20, height: 20}}/>}
                                onChange={e => setKeyword(e.target.value)}
                            />
                             <YuJaButton style={{height: "32px", width: "61px", fontSize: "14px", display: "flex", justifyContent: "center"}} onClick={() => {SearchName(keyword)}}>Search</YuJaButton>
                             </div>
                            <div style={{marginTop: "10px"}}> 
                                {data.map((item, index) => (
                                    <div onClick={() => {setStudent(item)}} style={{cursor: "pointer", border: "0.459px solid #787878", borderTop: index === 0 ? "0.459px solid #787878" : "none" , fontSize: "14px", color: "#585858", padding: "8.263px 11.935px"}}> {item.label} </div> 
                                )
                                
                                )}
                            </div>
            </div>
            <div style={{flex: "1", paddingLeft: "25px", borderLeft: "0.5px solid #858585", display: "flex", flexDirection: "column", width: "50%"}}> 
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px", alignItems: "center"}}> 
                        <div style={{color: "#000", fontFamily: "Geomanist", fontSize: "14px", fontWeight: "700", marginBottom: "8px"}}> {student.label} 
                        </div> 
                        <YuJaDropdown
                                            data={Object.keys(response.attempts).filter(key => key !== '0')}
                                            getOptionLabel={option => "Attempt " + option}
                                            getOptionValue={option => option}
                                            value={currAttempt}
                                            textStyle={{wordBreak: "break-all"}}
                                            onChange={(option) => {
                                              setCurrAttempt(option);
                                            }}
                                            style={{fontSize: 14, padding: 10, gap: 5, height: "34px", borderRadius: "3px", borderColor: "#909090", minWidth: 90
                                             }}
                                        />
                    </div>
                    <hr style={{margin: "0px"}} />
                    <div style={{minHeight: "125px",  maxHeight: "350px", overflowY: "auto", border: "0.5px solid #858585", marginTop: "15px", borderRadius: "4px", display: "flex", flexDirection: "column", gap: "11px", padding: "13px"}}> 
                    <div style={{display: "flex", gap: "10px"}}> 
                    <div style={{fontSize: "14px", fontFamily: "Geomanist", fontWeight: "700", color: "#42296E"}}> Question {serialNo} : 
                    </div>
                    <div style={{color: "#000", fontFamily: "Geomanist", fontSize: "14px", fontWeight: "400", wordBreak: "break-all"}}> 
                    {queTitle}
                    </div>
                    </div>
                    {questionImageURL && (
                    <div className={`${styles.questionImgContainer}`}>
                        {!isVideo && <img className={`${styles.saImg}`} src={questionImageURL} alt="Question" />}
                        {isVideo && <iframe className={`${styles.saImg}`} allowFullScreen src={questionImageURL} alt="Question"/>}
                    </div>
                    )}
                    <div style={{display: "flex", gap: "10px"}}> 
                    <div style={{fontSize: "14px", fontFamily: "Geomanist", fontWeight: "700", color: "#42296E"}}> Answer: 
                    </div>
                    <div style={{color: "#000", fontFamily: "Geomanist", fontSize: "14px", fontWeight: "400", wordBreak: "break-all"}}> 
                    {getAnswer(response, currAttempt, serialNo)}
                    </div>
                    </div>
                    <div style={{display: "flex", gap: "20px"}}>
                            <YuJaRadioBox
                                value="correct"
                                name="isCorrect"
                                onClick={() => EditStudent( student.value, true)}
                                checked={isCorrect && isCorrect[student.value] && isCorrect[student.value][currAttempt]}
                                label={"Correct"}
                                style={{fontSize: "14px"}}
                            />
                            <YuJaRadioBox
                                value="incorrect"
                                name="isCorrect"
                                onClick={() => EditStudent( student.value, false)}
                                checked={!isCorrect || !isCorrect[student.value] || !isCorrect[student.value][currAttempt]}
                                label={"Incorrect"}
                                style={{fontSize: "14px"}}
                            />

                    </div>
                    </div>
            </div>
        </div>
        </>
    );
}