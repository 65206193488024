import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import { Form, Row } from "react-bootstrap";
import { fetchImg } from "../../utils/helpers";
import { S3_PUBLIC_BUCKET_API_URL } from "../../utils/properties";
import {ReactComponent as YuJaLogo} from "../../images/YuJa_Logo_Login.svg";
import {useHistory, useParams} from "react-router-dom";
import IconCorrect from './images/IconCorrect.svg';
import IconIncorrect from './images/IconIncorrect.svg';
import defaultImage from "./images/main.jpg";
import useHostname from "../../hooks/useHostname";
import Check from './images/BigCorrect.svg';
import EyeOpen from "./images/EyeOpen.svg"
import EyeClosed from "./images/EyeClosed.svg"
import {AuthenticationApiCalls} from "../../apis/AuthenticationApiCalls";
import {useLoading} from "../../utils/LoadingContext";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";

export default function PasswordResetPage() {
  const { resetCode } = useParams();
  const hostResource = useHostname(window.location.hostname);
  const history = useHistory();
  const [reset, setReset] = useState(false);
  const [newPw, setNewPw] = useState("");
  const [conPw, setConPw] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [newFocus, setNewFocus] = useState(false);
  const [reqNewLen, setNewReqLen] = useState(false);
  const [reqNewCap, setNewReqCap] = useState(false);
  const [reqNewNum, setNewReqNum] = useState(false);
  const [reqNewSpe, setNewReqSpe] = useState(false);
  const [conFocus, setConFocus] = useState(false);
  const [reqConLen, setConReqLen] = useState(false);
  const [reqConCap, setConReqCap] = useState(false);
  const [reqConNum, setConReqNum] = useState(false);
  const [reqConSpe, setConReqSpe] = useState(false);
  const [reqConPwmatch, setReqConPwmatch] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [conPasswordShown, setConPasswordShown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const testCap = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).+$");
  const testSpe = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$");
  const maxW = 520;

  // const isMobile = window.innerHeight / window.innerWidth > 1 && window.innerWidth < 950;

  const {resetPwd} = AuthenticationApiCalls();
  const { setLoading } = useLoading();

  useEffect(() => {
    async function fetchData() {
      const { bgImgLocation = "" } = hostResource;
      const img = await fetchImg(`${S3_PUBLIC_BUCKET_API_URL}/s3?key=${bgImgLocation}`);
      setImgUrl(img ? img : defaultImage);
    }
    fetchData();
  }, [hostResource])

  useEffect(() => {
    function handleResize() { 
      calcWidth(0); 
    }
    setIsMobile(window.innerHeight / window.innerWidth > 1 && window.innerWidth < 950);
    window.addEventListener('resize', handleResize);
  }, [])

  const validateForm = () => {
    if (!newPw || !conPw) {
      setAlertMsg("Passwords field is blank.");
      return false;
    }

    if(!(newPw.length >= 12) || !testCap.test(newPw) || !/\d/.test(newPw) || !testSpe.test(newPw)) {
      setAlertMsg("Passwords requirements not met.");
      return false;
    }
    
    if(!(conPw.length >= 12) || !testCap.test(conPw) || !/\d/.test(conPw) || !testSpe.test(conPw)) {
      setAlertMsg("Passwords requirements not met.");
      return false;
    }

    if (newPw !== conPw) {
      setAlertMsg("Passwords do not match.");
      return false;
    }

    if (newPw === conPw){
      return true;
    }

    setAlertMsg("Passwords do not match.");
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlertMsg("");

    if (!validateForm())
      return;

    setLoading(true);
    //call API to change password
    const res = await resetPwd({resetCode, "pwd": conPw})
    setLoading(false);
    setReset(res);
  }

  // const editNewPassword = (e) => {
  //   setNewPw(e.target.value)
  //   setNewReqLen(e.target.value.length >= 12);
  //   setNewReqCap(testCap.test(e.target.value));
  //   setNewReqNum(/\d/.test(e.target.value));
  //   setNewReqSpe(testSpe.test(e.target.value));
  //   setAlertMsg("");
  // }

  const editConPassword = (e) => {
    if(e.target.id === "input-new-pw") {
      setNewPw(e.target.value);
    } else {
      setConPw(e.target.value);
    }
    setConReqLen(e.target.value.length >= 12);
    setConReqCap(testCap.test(e.target.value));
    setConReqNum(/\d/.test(e.target.value));
    setConReqSpe(testSpe.test(e.target.value));
    setAlertMsg("");
  }

  useEffect(() => {
    setReqConPwmatch(newPw === conPw);
  }, [newPw, conPw]);

  const goHome = () => {
    history.push({ pathname: "/" });
  }

  const toggleNewPw = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setNewPasswordShown(!newPasswordShown);
    document.getElementById('input-new-pw').focus();
  }

  const toggleConPw = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setConPasswordShown(!conPasswordShown);
    document.getElementById('input-con-pw').focus();
  }

  const calcWidth = (ele) => {
    setIsMobile(window.innerHeight / window.innerWidth > 1 && window.innerWidth < 950);
    let row = document.getElementById("new-input-row");
    let conPwLabel = document.getElementById("con-input-label-holder");
    let newPwIn = document.getElementById("new-input-box-holder");
    let conPwIn = document.getElementById("con-input-box-holder");
    let newPwLabel = document.getElementById("new-input-label-holder");

    if (row !== null && conPwLabel !== null && newPwLabel !== null && conPwIn !== null && newPwIn !== null) {
     if (row.offsetWidth > maxW) {
        newPwIn.style.width = "67%"
        conPwIn.style.width = "67%"
        newPwLabel.style.width = "33%"
        conPwLabel.style.width = "33%"
        return ele === 0 ? "67%" : "33%";
      }
      else if (row.offsetWidth <= maxW) {
        newPwIn.style.width = "100%"
        conPwIn.style.width = "100%"
        newPwLabel.style.width = "100%"
        conPwLabel.style.width = "100%"
        return "100%";
      }
    }
  }

  return (
    <>
      {/* Loading spinner without indicator and text */}
        <Row style={{ height: "100%", width: "100%", margin: 0 }}>
            <div className={styles.leftWrapper} style={{width: isMobile ? '100%' : '38%'}}>
              {reset ? (
                <>
                  <div className={styles.titleResetPwd}><YuJaLogo />YuJa Engage</div>
                  <div className={styles.formWrapper}>
                    <div>
                      <img src={Check} alt="check"/>
                      <span className={styles.title2}>Password Reset Successful</span>
                    </div>
                    <div className={styles.title3}>Please login and try again. </div>
                    <button className={styles.signInButton} style={{ marginTop: "5%" }} onClick={() => goHome()}>LOGIN</button>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.titleResetPwd}><YuJaLogo /> YuJa Engage </div>
                  <div className={styles.formWrapper}>
                    <div className={styles.subtitle}>Reset Password</div>
                    <Form style={{ marginTop: "40px" }}>
                      {(!!alertMsg) && <p className={styles.alert}>{alertMsg}</p>}
                      <Form.Group className="mb-3">
                        <Row id="new-input-row">
                          <div id="new-input-label-holder" className={styles.inputTitleBoxReset}>
                            <Form.Label className={styles.inputTitle}>New Password</Form.Label>
                          </div>
                          <div id="new-input-box-holder" className={styles.pwInHolder} >
                            <div
                              style={{ display: "flex", position: "relative" }}
                              id="pw-div"
                              className={styles.paswwordInputBoxHolder}
                              tabIndex="1"
                            >
                              <YuJaTextBox
                                id="input-new-pw"
                                containerStyle={{fontSize: 18, padding: 12}}
                                type={newPasswordShown ? "text" : "password"}
                                placeholder="New Password"
                                value={newPw}
                                onChange={e => editConPassword(e)}
                                onFocus={() => {setAlertMsg("")}}
                                isInvalid={!!alertMsg}
                                after={
                                  <>
                                  {newPw.length > 0 && (
                                    <>
                                      <img
                                        onClick={toggleNewPw}
                                        className={styles.eye}
                                        id="EYE"
                                        src={newPasswordShown ? EyeOpen : EyeClosed}
                                        alt="eye"
                                        style={{cursor: "pointer"}}
                                      />
                                    </>
                                  )}
                                  </>
                                }
                              />
                            </div>
                            {/* {!!newPw && newFocus && (
                              <>
                                <div>
                                  <img className={styles.pwHintIcon} src={reqNewLen ? IconCorrect : IconIncorrect} alt="Length Valid"/>
                                  <span className={styles.pwHintText}>At least 12 characters</span>
                                </div>
                                <div>
                                  <img className={styles.pwHintIcon} src={reqNewCap ? IconCorrect : IconIncorrect} alt="Capitals Valid"/>
                                  <span className={styles.pwHintText}>A mixture of both uppercase and lowercase letters</span>
                                </div>
                                <div>
                                  <img className={styles.pwHintIcon} src={reqNewNum ? IconCorrect : IconIncorrect} alt="Numbers Valid"/>
                                  <span className={styles.pwHintText}>A mixture of letters and numbers</span>
                                </div>
                                <div>
                                  <img className={styles.pwHintIcon} src={reqNewSpe ? IconCorrect : IconIncorrect} alt="Special Chars Valid"/>
                                  <span className={styles.pwHintText}>Inclusion of at least one special character, e.g., ! @ # ?</span>
                                </div>
                              </>
                            )} */}
                          </div>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Row id="con-input-row">
                          <div id="con-input-label-holder" className={styles.inputTitleBoxReset} >
                            <Form.Label className={styles.inputTitle}> Confirm Password</Form.Label>
                          </div>
                          <div id="con-input-box-holder" className={styles.pwInHolder} >
                            <div
                              style={{ display: "flex", position: "relative" }}
                              id="pw-div"
                              className={styles.paswwordInputBoxHolder}
                              tabIndex="1"
                            >
                              <YuJaTextBox
                                id="input-con-pw"
                                containerStyle={{fontSize: 18, padding: 12}}
                                type={conPasswordShown ? "text" : "password"}
                                placeholder="Confirm Password"
                                value={conPw}
                                onChange={e => editConPassword(e)}
                                isInvalid={!!alertMsg}
                                onFocus={() => {setAlertMsg("")}}
                                after={
                                  <>
                                  {conPw.length > 0 && (
                                <>
                                  <img
                                    onClick={toggleConPw}
                                    className={styles.eye}
                                    id="EYE"
                                    src={conPasswordShown ? EyeOpen : EyeClosed}
                                    alt="eye"
                                    style={{cursor: "pointer"}}
                                  />
                                </>
                              )}
                                  </>
                                }
                              />
                            </div>
                            {(newPw || conPw) && (<>
                              <div>
                                <img className={styles.pwHintIcon} src={reqConLen ? IconCorrect : IconIncorrect} alt="Length Valid"/>
                                <span className={styles.pwHintText}>At least 12 characters</span>
                              </div>
                              <div>
                                <img className={styles.pwHintIcon} src={reqConCap ? IconCorrect : IconIncorrect} alt="Capitals Valid"/>
                                <span className={styles.pwHintText}>A mixture of both uppercase and lowercase letters</span>
                              </div>
                              <div>
                                <img className={styles.pwHintIcon} src={reqConNum ? IconCorrect : IconIncorrect} alt="Numbers Valid"/>
                                <span className={styles.pwHintText}>A mixture of letters and numbers</span>
                              </div>
                              <div>
                                <img className={styles.pwHintIcon} src={reqConSpe ? IconCorrect : IconIncorrect} alt="Special Chars Valid"/>
                                <span className={styles.pwHintText}>Inclusion of at least one special character, e.g., ! @ # ?</span>
                              </div>
                              <div>
                                <img className={styles.pwHintIcon} src={reqConPwmatch ? IconCorrect : IconIncorrect} alt="Passwords Must Match"/>
                                <span className={styles.pwHintText}>Passwords must match</span>
                              </div>
                            </>)}
                          </div>
                        </Row>
                      </Form.Group>
                      <button className={styles.signInButton} style={{ marginTop: "5%" }} onClick={handleSubmit}>Reset Password</button>
                    </Form>
                  </div>
                </>
              )}
            </div>
          {!isMobile && (
              <div style={{width: "62%", padding: "0%"}}>
                <div style={{backgroundImage: `url(${imgUrl})`}} className={styles.container}/>
              </div>
          )}
        </Row>
    </>
  );
}
