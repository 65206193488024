import React, { useEffect, useRef, useState } from "react";
import { PollApiCalls } from "../apis/PollApiCalls";
import YuJaGeneralAlert from "../components/modals/YuJaGeneralAlert";
import useHostname from "../hooks/useHostname";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import { ReactComponent as UploadImage } from "../images/upload_purple.svg";
import { OVERRIDE_IMAGE_BUTTON, OVERRIDE_IMAGE_TEXT, OVERRIDE_IMAGE_TITLE, QUESTION_TYPES, TEXTBOX, UPLOAD_MEDIA, imageSuffixArr, videoSuffixArr, FILE_SIZE_TOO_LARGE } from "../utils/constants";
import { fetchImg, getFormattedCurrentTime, notifyError } from "../utils/helpers";
import { S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME } from "../utils/properties";
import { INVALID_FILE_FORMAT_ERROR, SELECT_FILE_ERROR, UPLOAD_ONE_IMAGE_ERROR } from "../utils/toast-message-constants";
import "./styles.css";

export default function UploadQuestionImage({
                                              selectedQuestion,
                                              setQuestions,
                                              questions,
                                              // currQueDisplay,
                                              pollKey,
                                              questionBankId = null
}) {
  const hostResource = useHostname(window.location.hostname);
  const {institutionId = ""} = hostResource;
  const ref = useRef();
  const { tempSaveImage, deleteImage, uploadVideo, getUploadLinkForVideo, deleteVideo} = PollApiCalls();

  const [setQues, ] = useLocalStorageNew("questions", []);
  
  const [, getSession] = useLocalStorageNew("session", {});

  const [alertShow, setAlertShow] = useState(false);
  const [tempFile, setTempFile] = useState(null);

  const session = getSession();
  const userId = session.userId;

  useEffect(() => {
    if (!!selectedQuestion.imgUrl) {
      return;
    }
    if (!!questionBankId ) {
      fetchImage(selectedQuestion.image, `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/questionbank/${institutionId}/${userId}/${questionBankId}`);
      return;
    }

    if (!!selectedQuestion.image && !!pollKey) {
      fetchImage(selectedQuestion.image, `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`);
      return;
    }

    if (!!selectedQuestion.suffix && !!pollKey) {
      fetchImage(selectedQuestion.suffix, `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`);
      return;
    }

    if (!!selectedQuestion.image && !pollKey) {
      fetchImage(selectedQuestion.image);
      return;
    }

    if (!!selectedQuestion.suffix && !pollKey) {
      fetchImage(selectedQuestion.suffix);
    }
  }, [institutionId, pollKey, selectedQuestion]);

  function fetchImage(name, url=`${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${userId}`) {
    if (!name) {
      return;
    }
    //backward compatible(suffix)
    const fullUrl = name.startsWith(".") ? `${url}/temp-${selectedQuestion.serialNo}${name}` : `${url}/${name}`;

    fetchImg(fullUrl).then((tempUrl) => {
      updateImgUrl(tempUrl ? tempUrl : "", name);
    });
  }

  function updateImgUrl(tempUrl="", name="") {
    let newQuestions = [];
    questions.forEach((q) => {
      if (q.serialNo === selectedQuestion.serialNo) {
        const {imgUrl, suffix, image, ...rest} = q;
        const newQuestion = {...rest, imgUrl: tempUrl};
        if (name.startsWith(".")) {
          newQuestion.suffix = name;
        } else {
          newQuestion.image = name;
        }
        newQuestions.push(newQuestion);
      } else {
        newQuestions.push({ ...q });
      }
    });
    if (JSON.stringify(newQuestions) !== JSON.stringify(questions)) {
      setQuestions(newQuestions);
      setQues(newQuestions);
    }
  }

  const alertConfig = {
    title: OVERRIDE_IMAGE_TITLE,
    okText: OVERRIDE_IMAGE_BUTTON,
    submit: async () => {
      setAlertShow(false);
      if (tempFile) {
          upload(tempFile); 
      }
    },
    close:  async () => {
      setAlertShow(false);
    },
  };

  const handleVideoUpload = async(file) => {
    // 1. Get upload link
    const res = await getUploadLinkForVideo();

    // 2. PUT to s3 bucket
    await fetch(res.url, {
      headers: {
        'Content-Type': "Video/mp4",
      },
      method: "PUT",
      body: file
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));

    // 3. POST /upload/video/
    const res3 = await uploadVideo(res.key, file.name, pollKey, selectedQuestion.serialNo);
    if(res3) {
      selectedQuestion.directLinkEvp = res3;
      
      let newQuestions = [];
      questions.forEach((q) => {
        if (q.serialNo === selectedQuestion.serialNo) {
          const newQuestion = {...q};
          newQuestion.image = file.name;
          newQuestion.directLinkEvp = res3;
          newQuestions.push(newQuestion);
        } else {
          newQuestions.push({ ...q });
        }
      });
      
      if (JSON.stringify(newQuestions) !== JSON.stringify(questions)) {
        setQuestions(newQuestions);
        setQues(newQuestions);
      }
    }
  };

  const upload = async (file) => {
    console.log(file);
    if(!!selectedQuestion.directLinkEvp) {
      const res = await deleteVideo(pollKey, selectedQuestion.serialNo, true);
      if(res === "success") {
        selectedQuestion.directLinkEvp = null;
      }
    }

    const suffix = file.name.substring(file.name.lastIndexOf('.'));
    if(selectedQuestion.questionType === QUESTION_TYPES.CI.name && videoSuffixArr.includes(suffix)) {
      notifyError("You can only upload images for the Clickable Image question type");
      return;
    }

    if (!imageSuffixArr.includes(suffix)) {
      notifyError(INVALID_FILE_FORMAT_ERROR);
      return;
    }

    if(file.size > 5e+7) {
      notifyError(FILE_SIZE_TOO_LARGE);
      return;
    }

    if (!file || !file.name) { // if no image is selected
      notifyError(SELECT_FILE_ERROR);
      return;
    }

    const newFileName = `${file.name.substring(0, file.name.lastIndexOf("."))}-${getFormattedCurrentTime()}${suffix}`
    const f = new File([file], newFileName); // Make new File with name temp-<currQueDisplay>.<suffix>
    // updateSuffix(suffix);
    if(videoSuffixArr.includes(suffix)) {
      await handleVideoUpload(f);
    } else {
      await tempSaveImage(f, fetchImage); // Get the url of the uploaded image from the backend
    }

    if (ref.current) {
      ref.current.value = '';
    }
  };

  const handleUpload = (e) => {
    if (!!selectedQuestion.imgUrl || !!selectedQuestion.directLinkEvp) {
      setTempFile(e.target.files[0]);
      e.target.value = "";
      setAlertShow(true); 
    }
    else {
      upload(e.target.files[0]);
    }
  };

  const handleClose = async (e) => {
    if (!!selectedQuestion.suffix) {
      await deleteImage(`poll/${userId}/temp-${selectedQuestion.serialNo}${selectedQuestion.suffix}`);
    }

    if (!!selectedQuestion.image) {
      await deleteImage(`poll/${userId}/${selectedQuestion.image}`);
    }


    //clear options
    let newQuestions = [];
    for (const q of questions) {
      if (q.serialNo !== selectedQuestion.serialNo) {
        newQuestions.push(q);
        continue;
      }

      const updatedQuestion = {...q, imgUrl: "", suffix: "", image: ""};
      if (selectedQuestion.questionType === QUESTION_TYPES.CI.name) {
        updatedQuestion.optionsMap = [];
        updatedQuestion.correctAnswers = [];
      }
      newQuestions.push(updatedQuestion);
    }

    setQuestions(newQuestions);
    setQues(newQuestions);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 1) {             // if more than one image is selected
      notifyError(UPLOAD_ONE_IMAGE_ERROR);
      return;
    }
    if (!!selectedQuestion.imgUrl) {
      setTempFile(files[0]);
      e.target.value = "";
      setAlertShow(true); 
    }
    else {
      upload(files[0]);       
    }            // Upload the file
  };

  return (
    <>
      <YuJaGeneralAlert
      show={alertShow}
      setModalShow={setAlertShow}
      content={OVERRIDE_IMAGE_TEXT}
      config={alertConfig}
    />
      <div style={{display: "flex", justifyContent: "center", flexDirection: "column", width: "100%"}}>
      <div
        className="image-upload-container"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop} 
        onClick={(e) => ref.current.click(e)}
        style={{cursor: "pointer"}}
        >

          <div className="justify-content-center" style={{display: "flex", alignItems: "center", gap: "10px", marginLeft: "0px"}}>
          <div tabIndex={0} aria-label={UPLOAD_MEDIA} role={TEXTBOX} className="upload-icon" style={{marginLeft: "0px"}}><UploadImage style={{width: "40px", height: "40px"}}/></div>
            <p tabIndex={0} aria-label={UPLOAD_MEDIA} role={TEXTBOX} className="drag-drop-text d-flex" style={{fontSize: "15.818px", fontFamily: "Geomanist", width: "auto", height: "100%", margin: "0", alignItems: "center"}}>Drag & Drop or <div style={{color: "#006DC7", fontSize: "15.818px", textDecoration: "underline", fontFamily: "Geomanist", fontWeight: "700", marginLeft: "5px", marginRight: "5px"}}> Choose Media </div> to Upload</p>
            {/* <p style={{marginBottom: 5}}>or</p>
            <YuJaButton  onClick={(e) => ref.current.click(e)}>Browse</YuJaButton> */}
            <input
              ref={ref}
              className="browse-box"
              type="file"
              accept="image/png, image/jpg, image/jpeg, image/jfif, image/pjpeg, image/pjp, video/*"
              onChange={handleUpload}
              aria-label="upload image"
            />
            </div>
            </div>
      </div>
    </>
  );
}
