import React from "react";
import { Col, Row } from "react-bootstrap";
import { Radio } from "../../components/radio/Radio";
import "../styles.css";
import { RADIAL_BTN_FALSE, RADIAL_BTN_TRUE, TEXTBOX } from "../../utils/constants";

export default function TFQuestionOption({
  option,
  correctAnswers,
  handleCorrectAnswerChange,
  index,
  pollType
}) {
  return (
    <div className="question-row" style={{display: "flex", alignItems: "center"}}>
        <Radio
          ariaLabel={option === "True" ? RADIAL_BTN_TRUE : RADIAL_BTN_FALSE}
          value={option}
          selected={correctAnswers}
          onChange={(value) => {
            handleCorrectAnswerChange(value);
          }}
          index={index}
          pollType={pollType}
        />
          <span
            style={{ width: "100%" }}
            className="poll-sub-text"
            tabIndex={0}
            role={TEXTBOX}
          >
            {option}
          </span>
    </div>
  );
}
