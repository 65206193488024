import { Spin } from "../../components/standardization/YuJaLoading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { POLL_TYPE, QUESTION_TYPES, WORD_CLOUD_DATA } from "../../utils/constants";
import { questionCodeToName } from "../../utils/questionUtils";
import TabParticipants from "./TabParticipants";
import TabResults from "./TabResults";
import INFO_ICON from './images/INFO_ICON.svg';
import POLL_RESULT from './images/POLL_RESULT.svg';
import "./styles.css";

export default function ViewExpiredQuestion({question, pollType, institutionId}) {  
  const { pollKey, pollCode } = useParams();
  const {getClassResult} = ResponseApiCalls();
  const [setWordCloudData, ] = useLocalStorageNew(WORD_CLOUD_DATA, {});
  const { questionType } = question;
  const [pollResults, setPollResults] = useState([]);
  const [wcResults, setWcResults] = useState([]);
  const [ loading, setLoading] = useState(true);
  const [points, setPoints] = useState([]);

  // let CA;
  // if (questionType === QUESTION_TYPES.MCSS.name) {
  //   CA = correctAnswers;
  // } else if (questionType === QUESTION_TYPES.TF.name) {
  //   CA = correctAnswers;
  // } else if (questionType === QUESTION_TYPES.CI.name) {
  //   CA = correctAnswers;
  // } else {
  //   CA = null;
  // }

  useEffect(() => {
    setWordCloudData({});
  }, []);

  useEffect( () => {
    if (!question || !pollType) return;
    setLoading(true);
    getClassResult(pollKey, pollCode, question, classResultCallback).then(res => {
      setPollResults(res);
      setLoading(false);
    });
  }, [question, pollType]);

  const classResultCallback = (qType, res) => {
    if (qType !== QUESTION_TYPES.CI.name && qType !== QUESTION_TYPES.WC.name) {
      return;
    }
    const {points} = res;
    setPoints(points ? points: []);
    if (!!res.responses) {
      setWcResults(res.responses);
    }
  };

  return (
    <div className="expired-question-container">
      
      <div className="tab-results-container">
        <div className="tab-results-title">
          <p style={{marginTop: "18px", marginBottom: "20.18px", marginLeft: "27px"}}><img src={INFO_ICON} alt="Info Icon"></img> {"Question Details & Poll Result: " + questionCodeToName(questionType)}</p>
          <p style={{marginTop: "20px", marginBottom: "20px", marginRight: "27px", fontSize: "16px"}}>{question.weightage} point{question.weightage === 1 ? "" : "s"}</p>
        </div>
        <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{marginTop: "75px", height: "150px"}} />
        {!loading &&
          <div style={{flex: 6, backgroundColor: "white"}}>
            <TabResults
              chartData={questionType === QUESTION_TYPES.WC.name ? wcResults : pollResults}
              institutionId={institutionId}
              pollType={pollType}
              question={question}
              points={points}
            />
          </div>
        }
      </div>

      <div className="tab-participants-container">
        <div className="tab-results-title">
          <p style={{marginTop: "18px", marginLeft: "27px"}}><img src={POLL_RESULT} alt="Poll Result Icon"></img> Participants Summary</p>
        </div>
        <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{marginTop: "75px", height: "150px"}}></Spin>
          {!loading && 
          <div style={{flex: 6, backgroundColor: "white"}}>
            <TabParticipants
              chartData={pollResults}
              questionType={questionType}
            />
          </div>
        }
      </div>
    </div>
  );
}
