import "bootstrap/dist/css/bootstrap.css";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import { Card } from "react-bootstrap";
import reactStringReplace from "react-string-replace";
import GenericModal from "../../components/modals/GenericModal";
import BigLock from "../../images/BigLock.svg";
import Lottie from "react-lottie";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import useMobileAccess from "../../hooks/useMobileAccess";
import UnansweredImg from "../../images/UnansweredImg.svg";
import ArrowIcon from "../../images/arrow-right-big.svg";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import NormalBox from "../../public/boxes/NormalBox";
import {
  BUTTON,
  CI_COORDINATE_SIZE,
  CLOSE_MODAL,
  CONFIRM_UNANSWERED_SUBMIT_CONTENT,
  CONFIRM_UNANSWERED_SUBMIT_TITLE,
  DEFAULT_ENTRIES_PER_PARTICIPANT,
  DEFAULT_SHOWN_ENTRIES,
  DIVISOR_LABEL,
  FIRST_WC_PLACEHOLDER,
  FITB_ANSWER_LENGTH,
  FIXED_ANSWER,
  MCSSoptLower,
  MCSSoptUpper, NETWORK_EFFECTIVE_TYPE,
  OTHER_WC_PLACEHOLDER,
  POLL_TITLE_LABEL,
  POLL_SHARE_MODE,
  POLL_TYPE,
  PREMISE,
  QUESTIONS_LABEL,
  QUESTION_TITLE,
  QUESTION_TYPES,
  QUIZ_STATE,
  RESPONSE,
  RESPONSE_CORRECT,
  SUBMIT_TEXT,
  TEXTBOX,
  THUMBS_DOWN,
  THUMBS_UP,
  TOTAL_SHARED_LABEL,
  WC_ANSWER_LENGTH
} from "../../utils/constants";
import {
  checkBlankAnswered,
  checkUnanswered,
  fetchImg,
  handleUserAns,
  parseWCId,
  newPageLog,
} from "../../utils/helpers";
import { RESPONSE_API_URL, S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME } from "../../utils/properties";
import { decodeCIParam, encodeCIParam, getOption4CI } from "../../utils/questionUtils";
import CountdownTimer from "./CountdownTimer";
import correct from "./animations/correct.json";
import incorrect from "./animations/incorrect.json";
import deleteIcon from "./images/redDelete.svg";
import { ReactComponent as Submitted } from "./images/submitted.svg";
import "./styles.css";
import styles from "./styles.module.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import {YuJaGlobalState} from "../../utils/LoadingContext";
import NetworkStatus from "./NetworkStatus";
import {ReactComponent as THUMB_UP} from "../../images/thumbuphovered.svg";
import {ReactComponent as THUMB_DOWN} from "../../images/thumbdownhovered.svg";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";

export default React.memo(PollReady, (prevProps, nextProps) => {
  return (prevProps.pollInfo === nextProps.pollInfo && prevProps.currentQuestion === nextProps.currentQuestion);
});

function PollReady({
                     pollInfo,
                     currentQuestion = {},
                     handleQuestionComplete,
                     handleSAQuestionComplete,
                     state,
                     stopped,
                     institutionId,
                     quesShareCount,
                     changeCurQue,
                     websocketState,
                     allSharecount,
                     totalQuestion
}) {
  const isMobile = useMobileAccess(480);
  const isNarrow = useMobileAccess(1200);
  const {isOnline, effectiveType} = useContext(YuJaGlobalState);
  const { pollKey, uniqueCode, userName, pollTitle, showAnswerOnViewer, pollType } = pollInfo;
  const { question, questionEndTime, questionResponse = {} } = currentQuestion;
  const {
    queTitle,
    correctAnswers,
    optionsMap,
    questionType,
    weightage,
    timeLimit = 15,
    entries = DEFAULT_ENTRIES_PER_PARTICIPANT,
    serialNo,
    suffix = "",
    image = "",
  } = question;


  const [isDropdownOpen, setIsDropdownOpen] = useState(null);

  const { selectedOption = null, isCorrect = null } = questionResponse;
  const [isResponseCorrect, setIsResponseCorrect] = useState(isCorrect);
  const [modalShow, setModalShow] = useState(!!selectedOption);
  const [questionImageURL, setQuestionImageURL] = useState("");
  const [setRc, getRc] = useLocalStorageNew(RESPONSE_CORRECT, "");

  const [isLoaded, setIsLoaded] = useState(false);
  const [canInput, setCanInput] = useState(true);
  const imageRef = useRef(null);
  const timeoutRedirectRef = useRef(null);
  const wcInputValRef = useRef(null);
  const { getAudienceResponseByUser, handleSubmitIndividualResponse, updateReaction } = ResponseApiCalls();
  const [thumbup, setThumbUp] = useState(false);
  const [thumbdown, setThumbDown] = useState(false);
  const [WCElements, setWCElements] = useState([]);
  const [shownEntries, setShownEntries] = useState(Math.min(entries, DEFAULT_SHOWN_ENTRIES)); // (isNarrow || entries == 1 ? 1 : 2);
  const [alertShow, setAlertShow] = useState(false);
  const [hoveringThumbUp, setHoveringThumbUp] = useState(false);
  const [hoveringThumbDown, setHoveringThumbDown] = useState(false);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  const [curAnswer, setCurAnswer] = useState(() => {
    //FITB
    if (questionType === QUESTION_TYPES.FITB.name && checkBlankAnswered(selectedOption)) {
      const ans = {};
      for (const key of Object.keys(correctAnswers)) {
        ans[key] = "";
      }
      return ans;
    }

    //WC
    if (questionType === QUESTION_TYPES.WC.name && checkBlankAnswered(selectedOption)) {
      const ans = {};
      for (let i = 0; i < entries; i++) {
        ans[i] = "";
      }
      return ans;
    }

    if (questionType === QUESTION_TYPES.MH.name && checkBlankAnswered(selectedOption)) {
      const ans = {};
      for (let i = 0; i < correctAnswers.length; i++) {
        ans[i] = "";
      }
      return ans;
    }

    //Survey MCSS
    if (pollType === POLL_TYPE.SURVEY
        && questionType === QUESTION_TYPES.MCSS.name
        && checkUnanswered(pollType, questionType, selectedOption)) {
      return [];
    }

    if (checkUnanswered(pollType, questionType, selectedOption)) {
      return FIXED_ANSWER.UNANSWERED;
    }

    return selectedOption;
  });
  const [isAnsweredState, setIsAnsweredState] = useState(!!selectedOption);
  // BEGIN VARIABLE INIT FOR QUESTION TYPES
  let MCSSelements = [];
  let newOptionsMap = questionType === QUESTION_TYPES.MCSS.name ? optionsMap : {};
  const MCSSnum = Object.keys(newOptionsMap).length;

  let TFelements = ["TF0", "TF1"];
  const TFnum = 2;
  const TFopt = ["True", "False"];

  let FITBElements = [];
  let idx = -1;

  if (questionType === QUESTION_TYPES.MCSS.name) {
    for (let i = 0; i < MCSSnum; i++) {
      MCSSelements.push("MCSS" + i);
    }
  } else if (questionType === QUESTION_TYPES.FITB.name) {
    for (const key of Object.keys(correctAnswers)) {
      FITBElements.push(key);
    }
  }

  const corrOptions = useMemo(() => {
    return {
    loop: true,
    autoplay: true,
    animationData: correct,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      // filterSize: {
      //   width: '200%',
      //   height: '200%',
      //   x: '-50%',
      //   y: '-50%',
      // }
      }
    };
  }, [correct]);

  const incorrOptions = useMemo(() => {
    return {
      loop: true,
      autoplay: true,
      animationData: incorrect,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        // filterSize: {
        //   width: '200%',
        //   height: '200%',
        //   x: '-50%',
        //   y: '-50%',
        // }
      }
    };
  }, [incorrect]);


  const onBlurWC = (i) => {
    document.getElementById(`WC-IN-${i}`).style.border = "1px solid #333333";
  };
  // END VARIBALE INIT

  // BEGIN DOCUMENT FORMATTING
  const [rootH, setRootH] = useState(window.innerHeight);
  const [varH, setVarH] = useState(485 + 30 * Math.ceil(queTitle.length / 50) + 42 * MCSSnum);

  useEffect(() => { 
    newPageLog("PollReady.js");
    if(isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }

    if (document.getElementById("everything") !== null) {
      setVarH(document.getElementById("everything").offsetHeight + 90);
    }
    if (document.getElementById("root") !== null) {
      setRootH(document.getElementById("root").offsetHeight)
    }
  }, []);

  if (document.getElementById("root") !== null) {
    document.getElementById("root").style.backgroundImage = "none";
  }
  // END DOCUMENT FORMATTING

  const enableInput = () => {
    console.log("enableInput", questionType, pollType, curAnswer, isAnsweredState);
    if (questionType === QUESTION_TYPES.MCSS.name) {
      for (let i = 0; i < MCSSnum; i++) {
        if (document.getElementById("MCSS" + i) !== null)
          document.getElementById("MCSS" + i).disabled = false;
      }
      if (pollType === POLL_TYPE.GRADED_POLL && !isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED) {
        document.getElementById("sub-btn").style.display = "block";
      }

      if (pollType === POLL_TYPE.SURVEY && !isAnsweredState && !!curAnswer && curAnswer.length) {
        document.getElementById("sub-btn").style.display = "block";
      }
    } else if (questionType === QUESTION_TYPES.TF.name) {
      for (let i = 0; i < TFnum; i++) {
        document.getElementById("TF" + i).disabled = false;
      }

      if (!isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED) {
        document.getElementById("sub-btn").style.display = "block";
      }
    } else if (questionType === QUESTION_TYPES.SA.name) {
      document.getElementById("SA-IN").disabled = false;
      if (!isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED && Object.keys(curAnswer).length) {
        document.getElementById("sub-btn").style.display = "block";
      }
    } else if (questionType === QUESTION_TYPES.FITB.name) {
      for (const key of Object.keys(correctAnswers)) {
        document.getElementById(key).disabled = false;
      }
      if (!isAnsweredState) {
        document.getElementById("sub-btn").style.display = checkBlanks(curAnswer) ? "none" : "block";
      }
    } else if (questionType === QUESTION_TYPES.CI.name) {
      setCanInput(true);
      if (!isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED && Object.keys(curAnswer).length) {
        document.getElementById("sub-btn").style.display = "block";
      }

      //for multiple click survey
      // if (pollType === POLL_TYPE.SURVEY && !isAnsweredState && !!curAnswer && curAnswer.length) {
      //   document.getElementById("sub-btn").style.display = "block";
      // }
    } else if (questionType === QUESTION_TYPES.WC.name) {
      for (let i = 0; i < entries; i++) {
        document.getElementById(`WC-IN-${i}`).disabled = false;
      }
      if (!isAnsweredState) {
        document.getElementById("sub-btn").style.display = checkBlankAnswered(curAnswer) ? "none" : "block";
      }
    }
  };

  const disableInput = () => {
    if (document.getElementById("sub-btn")) {
      document.getElementById("sub-btn").style.display = "none";
    }

    if (questionType === QUESTION_TYPES.MCSS.name) {
      for (let i = 0; i < MCSSnum; i++) {
        if (document.getElementById("MCSS" + i) !== null) {
          document.getElementById("MCSS" + i).disabled = true;
        }
      }
    } else if (questionType === QUESTION_TYPES.TF.name) {
      for (let i = 0; i < TFnum; i++) {
        document.getElementById("TF" + i).disabled = true;
      }
    } else if (questionType === QUESTION_TYPES.SA.name) {
      document.getElementById("SA-IN").disabled = true;
    } else if (questionType === QUESTION_TYPES.FITB.name) {
      for (const key of Object.keys(correctAnswers)) {
        document.getElementById(key).disabled = true;
      }
    } else if (questionType === QUESTION_TYPES.CI.name) {
      setCanInput(false);
    } else if (questionType === QUESTION_TYPES.WC.name) {
      for (let i = 0; i < entries; i++) {
        if(document.getElementById(`WC-IN-${i}`)) {
          document.getElementById(`WC-IN-${i}`).disabled = true;
        }
      }
    } else if (questionType === QUESTION_TYPES.MH.name) {
      for (let element of document.getElementsByName("MH-dropdown")) {
        element.disabled = false;
      }
    }
  };

  useEffect(() => {
    const subBtn = document.getElementById("sub-btn");
    if (state === QUIZ_STATE.POLL_TIME && pollType === POLL_TYPE.SURVEY && subBtn) {
      subBtn.style.display = "block";
    }

    if (state === QUIZ_STATE.RESUMED) {
      const responseCorrect = getRc();
      if (pollType === POLL_TYPE.SURVEY && responseCorrect !== '') {
        handleSAQuestionComplete();
        return;
      }

      console.log("RESUMED", questionType, pollType, responseCorrect, curAnswer, isAnsweredState);
      isAnsweredState ? disableInput() : enableInput();
    } else if (state === QUIZ_STATE.LOCKED) {
        timeUp();
    } else if (state === QUIZ_STATE.PAUSED) {
      const responseCorrect = getRc();
      if (pollType === POLL_TYPE.SURVEY && responseCorrect !== '') {
        handleQuestionComplete();
      }

      disableInput();
    }

  }, [state]);

  useEffect(() => {
    if (isResponseCorrect === null) {
      return;
    }

    if (pollType === POLL_TYPE.SURVEY) {
      handleQuestionComplete();
      return;
    }
    setModalShow(true);

    if (state === QUIZ_STATE.POLL_TIME || state === QUIZ_STATE.RESUMED || state === QUIZ_STATE.PAUSED) {
      if (questionType === QUESTION_TYPES.SA.name) {
        document.body.classList.add("not-graded");
      } else if (isResponseCorrect) {
        document.body.classList.add("correct-ans");
      } else {
        document.body.classList.add("wrong-ans");
      }
    }

    return () => {
      if (questionType === QUESTION_TYPES.SA.name) {
        document.body.classList.remove("not-graded");
      } else if (isResponseCorrect) {
        document.body.classList.remove("correct-ans");
      } else {
        document.body.classList.remove("wrong-ans");
      }
    };
  }, [isResponseCorrect]);

  useEffect(() => {
    if (!isOnline) {
      return;
    }

    async function fetchData() {
      if(question.directLinkEvp) {
        setQuestionImageURL(question.directLinkEvp);
        setIsVideo(true);
      }
      else if ((!!image || !!suffix) && institutionId && pollKey && serialNo) {
        let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;

        url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
        let img = await fetchImg(url);
        setQuestionImageURL(img ? img : "");
        setIsVideo(false);
      }
    }
    fetchData();
  }, [suffix, institutionId, pollKey, serialNo, isOnline]);

  const handleType = (e) => {
    e.stopPropagation();
    if (isAnsweredState) {
      return;
    }
    if (questionType === QUESTION_TYPES.SA.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("SA-IN").value = "";
        setCurAnswer(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurAnswer(ans);
      }
      if (pollType !== POLL_TYPE.SURVEY) {
        document.getElementById("sub-btn").style.display = e.target.value === "" ? "none" : "block";
      }
    } else if (questionType === QUESTION_TYPES.FITB.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      }
      const copy = curAnswer === FIXED_ANSWER.UNANSWERED ? {} : { ...curAnswer };
      copy[e.target.getAttribute("id")] = e.target.value.toString();
      setCurAnswer(copy);
      if (pollType !== POLL_TYPE.SURVEY) {
        document.getElementById("sub-btn").style.display = checkBlanks(copy) ? "none" : "block";
      }
    } else if (questionType === QUESTION_TYPES.WC.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      } else {
        if (parseWCId(e.target.getAttribute("id")) == shownEntries-1 && shownEntries < entries && wcInputValRef.current.length < e.target.value.toString().length) {
          // const diff = entries - shownEntries;
          // if (diff === 1) {
            setShownEntries(shownEntries+1);
          // } else if (diff > 1) {
            // if (isNarrow) setShownEntries(shownEntries+1);
            // else setShownEntries(shownEntries+2-(shownEntries % 2));
          // }
        }
      }
      const copy = curAnswer === FIXED_ANSWER.UNANSWERED ? {} : { ...curAnswer };
      copy[parseWCId(e.target.getAttribute("id"))] = e.target.value.toString();
      // console.log("copy", copy);
      setCurAnswer(copy);
      // console.log("answered", checkBlankAnswered(copy));
      wcInputValRef.current = e.target.value;
      if (pollType !== POLL_TYPE.SURVEY) {
        document.getElementById("sub-btn").style.display = checkBlankAnswered(copy) ? "none" : "block";
      }
    }
  };


 const handleDelete = (i) => {
    const copy = { ...curAnswer };
    copy[i] = "";
    for (let j = i; j < entries-1; ++j) {
      copy[j] = copy[j+1];
    }
    copy[entries-1] = "";
    setCurAnswer(copy);
    if (shownEntries > 1) {
      setShownEntries(shownEntries-1);
    }
  };

  const handleChooseOption = (e) => {
    if (isAnsweredState) {
      return;
    }
    if (pollType === POLL_TYPE.SURVEY && questionType === QUESTION_TYPES.MCSS.name) {
      const copy = [...curAnswer];
      if (e.target.checked) {
        copy.push(e.target.value);
        setCurAnswer(copy);
      } else {
        copy.splice(copy.indexOf(e.target.value), 1);
        setCurAnswer(copy);
      }
      // document.getElementById("sub-btn").style.display = copy.length ? "block" : "none";
    } else {
      document.getElementById("sub-btn").style.display = "block";
      setCurAnswer(e.target.value);
    }

  };

  const handleClickImage = (e) => {
    if (isAnsweredState || !isLoaded || !canInput) {
      return;
    }

    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    const encodedX = encodeCIParam(x, imageRef.current.width);
    const encodedY = encodeCIParam(y, imageRef.current.height);
    const option = getOption4CI(encodedX, encodedY, optionsMap);
    setCurAnswer(option === FIXED_ANSWER.UNANSWERED
        ? FIXED_ANSWER.UNANSWERED
        : {x: encodedX, y: encodedY, selected: option});
    document.getElementById("sub-btn").style.display = "block";
  }

  // const handleMultipleClick = (e) => {
  //   if (!isLoaded || !canInput) {
  //     return;
  //   }

  //   const x = e.nativeEvent.offsetX;
  //   const y = e.nativeEvent.offsetY;

  //   const encodedX = encodeCIParam(x, imageRef.current.width);
  //   const encodedY = encodeCIParam(y, imageRef.current.height);
  //   const option = getOption4CI(encodedX, encodedY, optionsMap);
  //   let curAnswerCopy = JSON.parse(JSON.stringify(curAnswer));

  //   curAnswerCopy.push(option === FIXED_ANSWER.UNANSWERED
  //       ? FIXED_ANSWER.UNANSWERED
  //       : {x: encodedX, y: encodedY, selected: option});

  //   if (curAnswerCopy.includes(FIXED_ANSWER.UNANSWERED)) {
  //     curAnswerCopy = curAnswerCopy.filter(answer => answer !== FIXED_ANSWER.UNANSWERED);
  //   }
  //   setCurAnswer(curAnswerCopy);
  //   document.getElementById("sub-btn").style.display = "block";
  // }

  const checkBlanks = (opts) => { // returns true if all blanks are empty
    for (const key of Object.keys(correctAnswers)) {
      if (!!opts && !!opts[key] && opts[key] !== "") {
        return false;
      }
    }

    return true;
  };

  const updateFITB = async () => {
    if (state === "LOCKED" && !curAnswer) {
      await setCurAnswer(FIXED_ANSWER.UNANSWERED);
      return;
    }

    console.log(`get selectedOpt before update: ${JSON.stringify(curAnswer)}`);
    const ans = checkBlanks(curAnswer) ? FIXED_ANSWER.UNANSWERED : curAnswer;
    await setCurAnswer(ans);
    return ans;
  };

  const updateWC = async () => {
    if (state === "LOCKED" && !curAnswer) {
      await setCurAnswer(FIXED_ANSWER.UNANSWERED);
      return;
    }

    console.log(`get selectedOpt before update: ${JSON.stringify(curAnswer)}`);
    const ans = checkBlankAnswered(curAnswer) ? FIXED_ANSWER.UNANSWERED : curAnswer;
    await setCurAnswer(ans);
    return ans;
  };

  const updateMH = async() => {
    if (state === "LOCKED" && !curAnswer) {
      await setCurAnswer(FIXED_ANSWER.UNANSWERED);
      return;
    }

    console.log(`get selectedOpt before update: ${JSON.stringify(curAnswer)}`);
    const ans = checkBlankAnswered(curAnswer) ? FIXED_ANSWER.UNANSWERED : curAnswer;
    await setCurAnswer(ans);
    return ans;
  }

  useEffect(() => {
    if (questionType === QUESTION_TYPES.WC.name) {
      let tempWCElements = [];
      for (let i = 0; i < shownEntries; i++) {
        tempWCElements.push(`WC-IN-${i}`);
      }
      setWCElements(tempWCElements);
    }
  }, [shownEntries]);


  useEffect(() => {
    if(stopped) {
      timeUp();
    }
  }, [stopped]);

  useEffect(() => {
    if (pollType !== POLL_TYPE.SURVEY && isTimeOut) {
      timeUp();
    }
  }, [pollType, isTimeOut]);

  const timeUp = async () => {
    disableInput();

    if (isAnsweredState === false) {
      await handleSub();
    }

    // handleMCTFHighlight();
    //delRc();
    if (questionType === QUESTION_TYPES.SA.name && pollType === POLL_TYPE.GRADED_POLL) {
      handleSAQuestionComplete();
    } else {
      handleQuestionComplete();
    }
  };

  const alertConfig = {
    title: CONFIRM_UNANSWERED_SUBMIT_TITLE,
    okText: SUBMIT_TEXT,
    submit: () => {
      setAlertShow(false);
      handleSub();
    },
    close:  () => setAlertShow(false),
  };

  const handleClickSub = () => {
    if (pollType === POLL_TYPE.SURVEY && !isAnsweredState &&
      ((questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.WC.name) ?
        checkBlankAnswered(curAnswer) : checkUnanswered(pollType, questionType, curAnswer))
    ) {
      setAlertShow(true);
    } else {
      handleSub();
    }
  };

  const handleSub = async () => {
    let copy = curAnswer;
    if (questionType === QUESTION_TYPES.FITB.name) {
      copy = await updateFITB();
    } else if (questionType === QUESTION_TYPES.WC.name) {
      copy = await updateWC();
      console.log(copy);
    } else if (questionType === QUESTION_TYPES.MH.name) {
      copy = await updateMH();
      console.log(copy);
    }

    if (pollType === POLL_TYPE.SURVEY && questionType === QUESTION_TYPES.MCSS.name && (!curAnswer || curAnswer.length === 0)) {
      copy = [FIXED_ANSWER.UNANSWERED];
    }

    const isCorr = await handleUserAns(question, copy);
    setIsAnsweredState(true);
    await setIsResponseCorrect(isCorr);
    await handleSubmitResponse(copy, isCorr);
    const curQueCopy = JSON.parse(JSON.stringify(currentQuestion));
    curQueCopy.questionResponse = { selectedOption: copy, isCorrect: isCorr };
    changeCurQue(curQueCopy);
    setRc(isCorr);
    // handleMCTFHighlight();
  };

  const handleSubmitResponse = async (ansIn, isCorr) => {
    const liveQuestion = question;
    const answer = {
      serialNo: liveQuestion.serialNo,
      questionType: liveQuestion.questionType,
      queTitle: liveQuestion.queTitle,
      optionsMap: liveQuestion.optionsMap,
      isCorrect: isCorr,
      attemptsCount: 1,
      selectedOption: ansIn,
      correctAnswers: liveQuestion.correctAnswers,
    };

    await handleSubmitIndividualResponse(pollKey, uniqueCode, pollType, answer);
  };

  const handleReaction = async (reaction) => {
    if (reaction == 1) {
      if(thumbdown == true) {
        setThumbDown(false);
      }
      setThumbUp(true);

    }
    else {
      if(thumbup == true) {
        setThumbUp(false);
      }
      setThumbDown(true);
    }

    const reactionMap = {};
    let allShareArray = JSON.parse(allSharecount.replace(/null/g, "null"));
    reactionMap[allShareArray[serialNo - 1]] = [reaction];
    const questionReactionMap = {};
    questionReactionMap[serialNo] = reactionMap;
    await updateReaction(pollKey, uniqueCode, questionReactionMap, null , serialNo);
  }

  useEffect(async () => {
    let res = await getAudienceResponseByUser(userName, uniqueCode);
    if(!res.hasOwnProperty("data")) {
      res.data = []; 
    }
    let currentPollRes = res.data;
    let isSet = false;
    console.log(currentPollRes);
    let allShareArray = JSON.parse(allSharecount.replace(/null/g, "null"));
    for (const tempResult of currentPollRes) {
      if (tempResult.reactionMap && tempResult.reactionMap[serialNo]) {
        if (tempResult.reactionMap[serialNo][allShareArray[serialNo - 1]][0] == "1") {
          setThumbUp(true);
          setThumbDown(false);
          isSet= true;
        } else if (tempResult.reactionMap[serialNo][allShareArray[serialNo - 1]][0] == "2") {
          setThumbDown(true);
          setThumbUp(false);
          isSet= true;
        }
      }  else if (tempResult.reaction && tempResult.reaction[serialNo]) {
        if (tempResult.reaction[serialNo][0] == "1") {
          setThumbUp(true);
          setThumbDown(false);
          isSet= true;
        } else if (tempResult.reaction[serialNo][0] == "2") { 
          setThumbDown(true);
          setThumbUp(false);
          isSet= true;
        }
      }
    }
    if (!isSet) {
      setThumbUp(false);
      setThumbDown(false);
    }
  }, [allSharecount]);


  const onLoad = () => {
    setIsLoaded(true);
  }

  const handleMouseEnterThumbUp = (reaction) => {
    if (reaction === 1) {
      setHoveringThumbUp(true);
    }
    else if (reaction === 2) {
      setHoveringThumbDown(true)
    }
  };

  const handleMouseLeaveThumbUp = (reaction) => {
    if (reaction === 1) {
      setHoveringThumbUp(false);
    }
    else if (reaction === 2) {
      setHoveringThumbDown(false)
    }
  };

  // const onMouseEnter = (idx) => {
  //   document.getElementById(`coordinate-close${idx}`).style.display = "block";
  // }

  // const onMouseLeave = (idx) => {
  //   document.getElementById(`coordinate-close${idx}`).style.display = "none";
  // }

  // const onDelete = (idx) => {
  //   const curAnswerCopy = JSON.parse(JSON.stringify(curAnswer));
  //   curAnswerCopy.splice(idx, 1);
  //   if (!curAnswerCopy.length) {
  //     document.getElementById("sub-btn").style.display = "none";
  //   }
  //   setCurAnswer(curAnswerCopy);
  // }

  return (
    <div id="everything" className={`${styles.centerLayout} ${styles.pollShareAllLayOut}`}>
      <YuJaGeneralAlert
        show={alertShow}
        setModalShow={setAlertShow}
        content={CONFIRM_UNANSWERED_SUBMIT_CONTENT}
        config={alertConfig}
      />

      <NormalBox
        boxType="lightBlue"
        className={`${styles.shadow} ${styles.centerLayout} ${styles.pollShareAllLayOut}`}
      >
        {/* {isNarrow &&
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: '87.5%'}}>
              {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                <CountdownTimer
                    duration={timeLimit}
                    questionEndTime={questionEndTime}
                    setIsTimeOut={setIsTimeOut}
                    state={state}
                />
              }
              <NetworkStatus tooltipPlacement={'bottom'} websocketState={websocketState}/>
            </div>
        } */}
        {!isMobile && window.innerWidth >= 1200 &&
          <NormalBox boxType="light" className={`${styles.containerTop}`} style={{padding: "0px 3vw 0px 3vw"}}>
            <div id="header" className="d-flex justify-content-between" style={{height: "90px"}}>
              <div tabIndex={0} aria-label={POLL_TITLE_LABEL + pollTitle} role={TEXTBOX} className="poll-title">{pollTitle}</div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20}}>
                  <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState}/>
                  {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                      <CountdownTimer
                          duration={timeLimit}
                          questionEndTime={questionEndTime}
                          setIsTimeOut={setIsTimeOut}
                          state={state}
                      />
                  }
                </div>
            </div>
          </NormalBox>
        }
        <div 
          className={`${styles.containerBottom}`} style={{display: "flex" , 
            flexDirection: "column", maxHeight: "calc(100vh - 75px)", padding: "0px"}}
        >
          {(isMobile || window.innerWidth < 1200) &&
                        <>
                            <div style={{position: "fixed", top: "20px", left: isMobile ? "calc((100% - 120px)/2)" : "calc((100% - 170px)/2)"}}>
                            {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                                <CountdownTimer
                                duration={timeLimit}
                                questionEndTime={questionEndTime}
                                setIsTimeOut={setIsTimeOut}
                                state={state}
                                />
                            }
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20, padding: "0px 21px 0px 21px"}}>
                          <div className="poll-title" style={{maxWidth: "100%", flex: 1}}>{pollTitle}</div>
                          <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState}/>
                          </div>
                          <div style={{padding: "0px 21px 0px 21px"}}> <hr/> </div> 
                        </>
                    }
          <div style={{display: "flex", flexDirection: "column", overflowY : "auto", height: "100%", flex: 1, padding: "0px 21px 5px 21px"}}>
          {!isMobile && window.innerWidth >= 1200 ?
          <div className="header-board" style={{display: "flex", alignItems: "center", gap: "10px"}}>
            <div tabIndex={0} role={TEXTBOX} className="ques-name">Question {serialNo} of {totalQuestion}</div>
            {questionType !== QUESTION_TYPES.FITB.name && (

              <>
                <ArrowRight style={{flexShrink: "0"}}/>
                <p tabIndex={0} aria-label={QUESTION_TITLE + queTitle} className="ques-desc" style={{ fontSize: "24px", letterSpacing: "0.84px", lineHeight: "normal", color: "#000", order: "unset", marginTop: "0px" , flexShrink: "1"}}>
                  {queTitle}
                </p>
              </>
            )}
            <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1"}}> 
              {/* <span className="questionShareCountContainer">
                <span className="questionShareCountTextCont">
                  <div tabIndex={0} role={TEXTBOX} aria-label={TOTAL_SHARED_LABEL + quesShareCount?.replace("/", DIVISOR_LABEL) + QUESTIONS_LABEL} className="questionShareCountBorder">Total Shared: {quesShareCount}</div>
                </span>
            </span> */}
            {pollType === POLL_TYPE.SURVEY ? <></> :
            <span className="gradientWrapQuesType" style={{height: "45px", width: "195px"}}>
                                    <span className="questionPanelQuestionTypeContainer" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <div tabIndex={0} role={TEXTBOX} className="questionPanelQuestionType" style={{fontSize: "18px", lineHeight: "normal"}}>  {weightage} Points</div>
                                    </span>
                      </span>
            }
            </div>
          </div>
          :  <> <div className="header-board" style={{display: "flex", justifyContent: "space-between"}}>
            <div tabIndex={0} role={TEXTBOX} className="ques-name">Question {serialNo} of {totalQuestion}</div>
            {pollType !== POLL_TYPE.SURVEY && 
                     <span className="gradientWrapQuesType" style={{ height: "23px", width: "109px", marginLeft: "0px"}}>
                                <span className="questionPanelQuestionTypeContainer" style={{ width: "107px", padding: "0px", height:  "20px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div className="questionPanelQuestionType" role={TEXTBOX} style={{ fontSize: "12px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height:  "12px"}}> {weightage} Points </div>
                                </span>
                            </span>}
            {/* <div tabIndex={0} role={TEXTBOX} aria-label={TOTAL_SHARED_LABEL + quesShareCount?.replace("/", DIVISOR_LABEL + QUESTIONS_LABEL)} className="ques-name">Total Shared: {quesShareCount}</div> */}
          </div> 
          {questionType !== QUESTION_TYPES.FITB.name && (
             <p tabIndex={0} aria-label={QUESTION_TITLE + queTitle} className="ques-desc" style={{order: "initial"}}>
                {queTitle}
             </p>
         )}
          
          </>}
          {/* {isNarrow && <div className="header-board-question">
            <div className="ques-name">Question {serialNo} {pollType === POLL_TYPE.SURVEY ? <></> : <p className="ques-pts"> - {weightage} points</p>}</div>
            <div className="ques-name">Total Shared: {quesShareCount}</div>
          </div>} */}


                {questionImageURL && isMobile &&(
                <div className="img-container-question" style={{alignSelf: "center", margin: "0px", marginTop: "30px", width: questionType === QUESTION_TYPES.CI.name ? "100%" : ""}}>
                  {questionType !== QUESTION_TYPES.CI.name && <>
                  {!isVideo && <img className="img" src={questionImageURL} alt="Question"/>}
                  {isVideo && <iframe allowFullScreen src={questionImageURL} alt="Question"/>}
                  </>}
                  {questionType === QUESTION_TYPES.CI.name &&
                      <div style={{position: "relative"}}>
                        <img
                            className="img"
                            ref={imageRef}
                            src={questionImageURL}
                            onLoad={onLoad}
                            onClick={handleClickImage}
                            alt="Question"/>
                        {isLoaded
                            && !!curAnswer
                            && curAnswer.hasOwnProperty("x")
                            && curAnswer.hasOwnProperty("y")
                            &&
                            <COORDINATE
                                style={{
                                  position: "absolute",
                                  top: `${decodeCIParam(curAnswer.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                  left: `${decodeCIParam(curAnswer.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                }}
                            />
                        }
                      </div>
                  }
                </div>
              )}
          <div className="main-body">
            <div style={{display: "flex", gap: "70px"}}>

              {/* Create the options for MCSS */}
              {questionType === QUESTION_TYPES.MCSS.name && (
                <div id="body-MCSS" style={{display: "flex", flexDirection: "column", gap: isMobile ? "17px" :"30px", flex: "1", marginTop: isMobile ? "20px" : "", width: "100%"}}>
                  {MCSSelements.map((value, index) => {
                    return (
                      <div>
                        {!isMobile && index === 0 && <div style={{ display: "block", height: "30px" }} />}

                        {pollType === POLL_TYPE.SURVEY && (
                          <input
                            disabled={isAnsweredState}
                            type="checkbox"
                            id={value}
                            value={MCSSoptLower[index]}
                            name="MCSS"
                            checked={
                              !!curAnswer &&
                              curAnswer.includes(
                                MCSSoptLower[index]
                              )
                            }
                            className="MCSS-btn"
                            style={{ display: "none" }}
                            onChange={handleChooseOption}
                            tabIndex={0}
                          />
                        )}

                        {pollType === POLL_TYPE.GRADED_POLL && (
                          <input
                            disabled={isAnsweredState}
                            type="radio"
                            id={value}
                            value={MCSSoptLower[index]}
                            name="MCSS"
                            checked={
                              !!curAnswer &&
                              MCSSoptLower[index] ===
                              curAnswer
                            }
                            className="MCSS-btn"
                            onChange={handleChooseOption}
                            tabIndex={0}
                          />
                        )}

                        <label htmlFor={value} className="MCSS-label" id={"MCSSButton" + index} tabIndex={0} style={{ width: "100%", minHeight: isMobile ? "45px" : "", borderRadius: isMobile ? "4px" : "", border: isMobile ? "1.5px solid #909090" : "" }}>
                          <div className="MCSS-letter-container" style={{
                            border: (!!curAnswer &&
                              (MCSSoptLower[index] ===
                              curAnswer) || (Array.isArray(curAnswer) && curAnswer.includes(MCSSoptLower[index]))) ? "1.5px solid #FFF" : isMobile ? "1.5px solid #000" : "2.5px solid #000"
                          }}>
                            <span className="MCSS-letter" id={"MCSSLetter" + index} style={{color: (!!curAnswer &&
                              (MCSSoptLower[index] ===
                              curAnswer) || (Array.isArray(curAnswer) && curAnswer.includes(MCSSoptLower[index]))) ? "#42296E" : "#000"}}>
                              {MCSSoptUpper[index]}
                            </span>
                            </div>
                            <p className="MCSS-option" id={index + value} style={{
                              color: (!!curAnswer &&
                                (MCSSoptLower[index] ===
                                curAnswer || (Array.isArray(curAnswer) && curAnswer.includes(MCSSoptLower[index])))) ? "#FFF" : "#000",
                                maxWidth: isMobile ? "70%" : window.innerWidth < 1200 ? "80%" : "90%",
                                paddingRight: "5px", fontSize: isMobile? "14px" : ""
                            }}>
                              {newOptionsMap[MCSSoptLower[index]]}
                            </p>
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Create the TF options */}
              {questionType === QUESTION_TYPES.TF.name && (
                <div id="body-TF" className= "TF-option" style={{flex: "1"}}>
                  {TFelements.map((value, index) => {
                    return (
                        <YuJaRadioBox
                            size={32}
                            disabled={isAnsweredState}
                            id={value}
                            value={TFopt[index]}
                            name="TF"
                            checked={
                              !!curAnswer &&
                              TFopt[index] === curAnswer
                            }
                            onClick={handleChooseOption}
                            labelStyle={{margin: "0px 0px 0px 16px"}}
                            label={TFopt[index]}
                            style={{fontSize: isMobile ? 18 : 24, marginTop: 30}}
                        />
                    );
                  })}
                </div>
              )}

              {/* Create the input box for SA */}
              {questionType === QUESTION_TYPES.SA.name && (
                <div id="body-SA" className="sa-center" style={{flex: "1"}}>
                  <textarea
                    disabled={isAnsweredState}
                    className="sa-ans"
                    id="SA-IN"
                    name="sa-in"
                    placeholder="Type short answer here."
                    value={
                      !curAnswer ||
                        curAnswer === FIXED_ANSWER.UNANSWERED
                        ? ""
                        : curAnswer["0"]
                    }
                    onChange={handleType}
                  />
                </div>
              )}

              {/* Create the input box for FITB */}
              {questionType === QUESTION_TYPES.FITB.name && (
                <div
                  id="body-FITB"
                  style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "5px", marginTop: "30px" , flex: "1"}}
                >
                  {/* {reactStringReplace(queTitle, /(\(.*?\))/g, (m, i, o) => {
                     if (m !== "()") {
                    idx += 1;
                    return (
                      <YuJaTextBox
                        disabled={isAnsweredState}
                        key={`FITB${i}`}
                        id={Object.values(FITBElements)[idx]}
                        containerStyle={{width: 150}}
                        onChange={handleType}
                        maxLength={FITB_ANSWER_LENGTH}
                        value={
                          !curAnswer ||
                            curAnswer === FIXED_ANSWER.UNANSWERED ||
                            !curAnswer.hasOwnProperty(FITBElements[idx])
                            ? ""
                            : curAnswer[FITBElements[idx]]
                        }
                      />
                    );
                  }
                  else {
                    return ("()");
                  }
                  })} */}

                          {
                               queTitle.split(/(\(.*?\))/g).map((str, i) => {
                                let re = new RegExp(/\([^\(\)]+\)/g);
                                if (!re.test(str)) {
                                    const oneLine = str.replace(/[\r\n]/g, " @newLine ");
                                    const wordArr = oneLine.split(" ");
                                    return (
                                        <>
                                            {wordArr.map((s, idx) => (<>
                                                {s === '@newLine' &&
                                                    <div key={idx} style={{width: "100%"}}></div>
                                                }
                                                {!!s && s !== '@newLine' &&
                                                    <div key={idx}>{s}</div>
                                                }
                                            </>))}
                                        </>
                                    );
                                } else {
                                  idx += 1;
                                  return (
                                    <YuJaTextBox
                                      disabled={isAnsweredState}
                                      key={`FITB${i}`}
                                      id={Object.values(FITBElements)[idx]}
                                      containerStyle={{ width: 150 }}
                                      onChange={handleType}
                                      maxLength={FITB_ANSWER_LENGTH}
                                      value={
                                        !curAnswer ||
                                          curAnswer === FIXED_ANSWER.UNANSWERED ||
                                          !curAnswer.hasOwnProperty(FITBElements[idx])
                                          ? ""
                                          : curAnswer[FITBElements[idx]]
                                      }
                                    />
                                  );
                                } } )}
                </div>
              )}
              {questionType === QUESTION_TYPES.MH.name && (<>
                <div id="body-MCSS" style={{flex: "1", maxWidth: "100%"}}>
                      <div>
                        <div style={{ display: "flex", marginTop: "30px", width: "100%"}}>
                          <div style={{width: "100%"}}>
                            {correctAnswers.map((element, index) => (
                              <div key={element[0]} id={"Option" + index} className="questionPanelOptionsContainer" style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "0px", display: "flex", alignItems: "center" }}>
                                {element.length > 1 ?
                                  <div style={{ display: "flex", flex: 1,  width: "100%", borderTop: isNarrow && index ===0 ? "none" : ""}} className={isNarrow ? styles.MatchingNarrow : ""}>
                                    {isNarrow && <div style={{fontWeight: "700", fontSize: "45", marginTop:"10px", marginBottom: "10px"}}> {PREMISE} </div>}
                                    <div
                                        tabIndex={0}
                                        role={TEXTBOX}
                                        className="questionPanelAnswersText matchingPremise"
                                        id={"OptionPart1" + index}
                                        style={{
                                          marginRight: "0px",
                                          border: "1px solid #909090",
                                          padding: "10px",
                                          borderRadius: "10px",
                                          backgroundColor: "#F5F5F5",
                                          minWidth: !isNarrow ? "250px": "",
                                          height: "fit-content",
                                          flex: 1,
                                          wordBreak: "keep-all"
                                    }}
                                    >{element[0]}</div>
                                    <img
                                      src={ArrowIcon}
                                      alt="Arrow icon"
                                      className="questions-page-icons cursor-pointer"
                                      style={{ width: "48px", margin: "0 20px", height: "fit-content", display: isNarrow ? "none" : "block"}}
                                    /> 
                                      <div style={{minWidth: "50%", position: "relative", flex: "1"}} onBlur={() => {if(isDropdownOpen != null) {setIsDropdownOpen(null)}}}>
                                      {isNarrow && <div style={{fontWeight: "700", fontSize: "45", marginTop:"10px", marginBottom: "10px"}}> {RESPONSE} </div>}
                                        <YuJaDropdown
                                          data={optionsMap}
                                          getOptionLabel={option => option.length > 1 ? option[1]: option[0]}
                                          getOptionValue={option => option.length > 1 ? option[1]: option[0]}
                                          value={curAnswer[index]}
                                          disabled={isAnsweredState}
                                          itemStyle={{borderWidth: "2.5px", borderColor: "#909090" }}
                                          placeholder={"Select an option"}
                                          textStyle={{wordBreak: "break-all"}}
                                          onChange={(option) => {
                                            let newAnswer = {[index]: option.length > 1 ? option[1]: option[0]};
                                            if(curAnswer !==  FIXED_ANSWER.UNANSWERED) {
                                              newAnswer = { ...curAnswer, [index]: option.length > 1 ? option[1]: option[0] };
                                            }
                                            console.log(newAnswer)
                                            setCurAnswer(newAnswer);
                                            document.getElementById("sub-btn").style.display = "block";
                                            setIsDropdownOpen(false);
                                          }}
                                          style={{fontSize: 18, padding: 10, borderRadius: "10px",
                                          border: "2.5px solid var(--color-guide-primary-color, #42296E)"}}
                                          arrowSize={"24px"}
                                        />
                                      </div>
                                    </div> : <></>}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                </div>
              </> ) } 
              {/* Create the input boxes for WC */}
              {questionType === QUESTION_TYPES.WC.name &&
                  <>
                    {WCElements.length > 1 &&
                        <div id="body-WC" className={isNarrow ? "wc-center-narrow" : "wc-center"} style={{flex: "1", height: "fit-content"}}>
                          {Array(shownEntries).fill(null).map((id, i) => {
                            return (
                                <div className="wc-ans-container" key={i} style={{width: isNarrow ? "100%" : ""}}>
                                  <YuJaTextBox
                                      disabled={isAnsweredState}
                                      id={`WC-IN-${i}`}
                                      name="wc-in"
                                      placeholder={i === 0 ? FIRST_WC_PLACEHOLDER : OTHER_WC_PLACEHOLDER}
                                      maxLength={WC_ANSWER_LENGTH}
                                      style={{paddingRight: "20px", boxSizing: "border-box"}}
                                      value={checkBlankAnswered(curAnswer) ? "" : curAnswer[i]}
                                      onChange={(e) => {
                                        const copy = curAnswer === FIXED_ANSWER.UNANSWERED ? {} : {...curAnswer}
                                        copy[i] = e.target.value;
                                        setCurAnswer(copy);
                                        wcInputValRef.current = e.target.value;
                                        if (i === shownEntries - 1 && shownEntries < entries && e.target.value.length) {
                                          setShownEntries(shownEntries + 1);
                                        }
                                      }}
                                      containerStyle={{border: "2.5px solid #D0D0D0", borderRadius: "10px"}}

                                  />
                                  <div className="wc-char-count">
                                    {WC_ANSWER_LENGTH - (curAnswer[i] ? curAnswer[i].length : 0)}
                                  </div>

                                  <img
                                                src={deleteIcon}
                                                className="delete-entry-icon"
                                                tabIndex={0}
                                                role={BUTTON}
                                                alt="Delete Answer"
                                                onClick={() => handleDelete(i)}
                                                style={{width: "38px", height: "38px", marginLeft: "15px"}}
                                            />

                                </div>
                            );
                          })}
                        </div>
                    }

                    {WCElements.length === 1 &&
                        <div id="body-WC-odd" className="wc-center-odd">
                          <div className="wc-ans-container">
                            <YuJaTextBox
                                containerStyle={{width: "40vw", border: "2.5px solid #D0D0D0", borderRadius: "10px"}}
                                disabled={isAnsweredState}
                                id={WCElements[WCElements.length - 1]}
                                name="wc-in"
                                placeholder={WCElements.length === 1 ? FIRST_WC_PLACEHOLDER : OTHER_WC_PLACEHOLDER}
                                maxLength={WC_ANSWER_LENGTH}
                                style={{paddingRight: "20px", boxSizing: "border-box"}}
                                value={checkBlankAnswered(curAnswer) ? "" : curAnswer[0]}
                                onChange={(e) => {
                                  const copy = curAnswer === FIXED_ANSWER.UNANSWERED ? {} : {...curAnswer}
                                  copy[0] = e.target.value;
                                  setCurAnswer(copy);
                                  wcInputValRef.current = e.target.value;
                                }}
                            />
                            <div className="wc-char-count">
                              {WC_ANSWER_LENGTH - (curAnswer[0] ? curAnswer[0].length : 0)}
                            </div>
                            <img
                                src={deleteIcon}
                                className="delete-entry-icon"
                                role={BUTTON}
                                tabIndex={0}
                                alt="Delete Answer"
                                onClick={() => handleDelete(0)}
                                style={{width: "38px", height: "38px", marginLeft: "15px"}}
                            />
                          </div>
                        </div>
                    }
                  </>
              }
              {questionImageURL && !isMobile &&(
                <div className="img-container-question" style={{alignSelf: "center", margin: "0px", marginTop: "30px", width: questionType === QUESTION_TYPES.CI.name ? "100%" : ""}}>
                  {questionType !== QUESTION_TYPES.CI.name && <>
                    {!isVideo && <img className="img-question" src={questionImageURL} alt="Question"/>}
                    {isVideo && <iframe allowFullScreen className="img-question" src={questionImageURL} alt="Question"/>}                  
                  </>}
                  {questionType === QUESTION_TYPES.CI.name &&
                      <div style={{position: "relative"}}>
                        <img
                            className="img"
                            ref={imageRef}
                            src={questionImageURL}
                            onLoad={onLoad}
                            onClick={handleClickImage}
                            alt="Question"
                            tabIndex={0} />
                        {isLoaded
                            && !!curAnswer
                            && curAnswer.hasOwnProperty("x")
                            && curAnswer.hasOwnProperty("y")
                            &&
                            <COORDINATE
                                style={{
                                  position: "absolute",
                                  top: `${decodeCIParam(curAnswer.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                  left: `${decodeCIParam(curAnswer.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                }}
                            />
                        }
                      </div>
                  }
                  {/*multiple click image*/}
                  {/*{questionType === QUESTION_TYPES.CI.name && pollType === POLL_TYPE.SURVEY  &&*/}
                  {/*    <div style={{position: "relative"}}>*/}
                  {/*      <img*/}
                  {/*          className="img"*/}
                  {/*          ref={imageRef}*/}
                  {/*          src={questionImageURL}*/}
                  {/*          onLoad={onLoad}*/}
                  {/*          onClick={handleMultipleClick}*/}
                  {/*          alt="Question"/>*/}
                  {/*      {isLoaded &&*/}
                  {/*          curAnswer.filter(point => point !== FIXED_ANSWER.UNANSWERED).map((point, idx) => (*/}
                  {/*              <div*/}
                  {/*                  onMouseEnter={() => onMouseEnter(idx)}*/}
                  {/*                  onMouseLeave={() => onMouseLeave(idx)}*/}
                  {/*                  key={idx}*/}
                  {/*                  style={{*/}
                  {/*                    position: "absolute",*/}
                  {/*                    display: "flex",*/}
                  {/*                    padding: "0.5px",*/}
                  {/*                    top: `${decodeCIParam(point.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,*/}
                  {/*                    left: `${decodeCIParam(point.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`*/}
                  {/*                  }}*/}
                  {/*              >*/}
                  {/*                <COORDINATE/>*/}
                  {/*                <RiCloseCircleFill*/}
                  {/*                    onClick={() => onDelete(idx)}*/}
                  {/*                    className={"coordinate-close"}*/}
                  {/*                    id={`coordinate-close${idx}`}*/}
                  {/*                    style={{height: "15px", width: "15px"}}*/}
                  {/*                />*/}
                  {/*              </div>*/}

                  {/*          ))*/}
                  {/*      }*/}
                  {/*    </div>*/}
                  {/*}*/}
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", paddingBottom: "85px", paddingLeft: isMobile ? "10px" : "21px", paddingRight: isMobile ? "10px" : "21px"}}>
        {(!isAnsweredState) &&
              <>
                <div style={{width: isMobile? "75px" : "95px"}} />
              </>
           }
            <div style={{display: "flex", gap: "10px", flex: 1, justifyContent: "center"}}> 
              <div
                className={`reaction-container ${thumbup ? "reaction-container-activated": ""}`}
                onClick={() => {handleReaction(1)}}
                tabIndex={0} 
                aria-label={THUMBS_UP}
                role={BUTTON}
              >
                <THUMB_UP/>
              </div>
              <div
                className={`reaction-container ${thumbdown ? "reaction-container-activated": ""}`}
                onClick={() => {handleReaction(2)}}
                tabIndex={0} 
                aria-label={THUMBS_DOWN}
                role={BUTTON}
              >
                <THUMB_DOWN/>
              </div>
              </div>
            {(!isAnsweredState) &&
              <>
                <div id="sub-spc"/>

                <div style={{ right: isMobile ? "21px" : "61px", display: "flex", justifyContent: "end"}}>
                  <YuJaButton id="sub-btn" onClick={handleClickSub} style={{fontSize: isMobile ? "16px" : "20px", padding: isMobile ? "10px" : "10px 15px", width: isMobile? "75px" : "95px"}}>
                    {SUBMIT_TEXT}
                  </YuJaButton>
                </div>
              </>
           }
           </div>
        </div> 

        <GenericModal
          className={questionType === QUESTION_TYPES.SA.name ? "sa-container-message2" : "container-message2"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop={true}
          content={
            <div className={questionType === QUESTION_TYPES.SA.name ? "sa-container-message" : "container-message"}>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                  <button role={BUTTON} onClick={() => setModalShow(false)} type="button" className="btn-close" aria-label={CLOSE_MODAL}/>
                </div>
                {pollType === POLL_TYPE.GRADED_POLL && curAnswer === FIXED_ANSWER.UNANSWERED &&
                  (state === QUIZ_STATE.POLL_TIME || state === QUIZ_STATE.RESUMED) &&
                    <div>
                      <Card className="pop-up-card">
                        <h1
                        
                        
                        
                        tabIndex={0} role={TEXTBOX} className="pop-up-header">Times Up!</h1>
                        <p tabIndex={0} role={TEXTBOX} className="pop-up-body">
                          It's time to see the correct answer.
                        </p>
                        <img src={UnansweredImg} alt="unanswered img" />
                      </Card>
                    </div>
                }

                {showAnswerOnViewer && isResponseCorrect && questionType !== QUESTION_TYPES.SA.name &&
                  (state === QUIZ_STATE.POLL_TIME || state === QUIZ_STATE.RESUMED || state === QUIZ_STATE.PAUSED) &&
                  curAnswer !== FIXED_ANSWER.UNANSWERED &&
                    <div>
                      <Card className="pop-up-card">
                        <h1 className="pop-up-header">Well Done! Correct.</h1>
                        <p className="pop-up-body">
                          Class results will appear in a few moments.
                        </p>
                        <div className="lottie-container-w-shadow">
                          <Lottie
                            options={corrOptions}
                            height={(isMobile || window.innerHeight < 700) ? 180 : 300}
                            width={(isMobile || window.innerHeight < 700) ? 180 : 300}
                            isClickToPauseDisabled={true}
                          />
                        </div>
                      </Card>
                    </div>
                }

                {showAnswerOnViewer && !isResponseCorrect && questionType !== QUESTION_TYPES.SA.name &&
                  (state === QUIZ_STATE.POLL_TIME || state === QUIZ_STATE.RESUMED || state === QUIZ_STATE.PAUSED) &&
                  curAnswer !== FIXED_ANSWER.UNANSWERED &&
                    <div>
                      <Card className="pop-up-card">
                        <h1 className="pop-up-header">
                          Not quite the answer we were looking for.
                        </h1>
                        <p className="pop-up-body">
                          Class results will appear in a few moments.
                        </p>
                        <div className="lottie-container-w-shadow">
                          <Lottie
                            options={incorrOptions}
                            height={(isMobile || window.innerHeight < 700) ? 180 : 300}
                            width={(isMobile || window.innerHeight < 700) ? 180 : 300}
                            isClickToPauseDisabled={true}
                          />
                        </div>
                      </Card>
                    </div>
                }

                {!showAnswerOnViewer && pollType === POLL_TYPE.GRADED_POLL && questionType !== QUESTION_TYPES.SA.name &&
                    (state === QUIZ_STATE.POLL_TIME || state === QUIZ_STATE.RESUMED || state === QUIZ_STATE.PAUSED) &&
                    curAnswer !== FIXED_ANSWER.UNANSWERED &&
                    <div>
                      <Card className="sa-pop-up-card">
                        <Submitted />
                        <h1 className="sa-pop-up-header"  tabIndex={0}>Your answer has been submitted.</h1>
                      </Card>
                    </div>
                }

                {questionType === QUESTION_TYPES.SA.name &&
                  (state === QUIZ_STATE.POLL_TIME || state === QUIZ_STATE.RESUMED || state === QUIZ_STATE.PAUSED) &&
                  curAnswer !== FIXED_ANSWER.UNANSWERED &&
                    <div>
                      <Card className="sa-pop-up-card">
                        <Submitted />
                        <h1 className="sa-pop-up-header" tabIndex={0}>
                          Your answer has been submitted.
                        </h1>
                        <p tabIndex={0} className="sa-pop-up-body">
                          Your instructor will review the submission, and apply a grade shortly.
                        </p>
                      </Card>
                    </div>
                }

                {state === QUIZ_STATE.LOCKED && (
                  <div>
                    <Card className="pop-up-card set-middle">
                      <img src={BigLock} alt="big lock" className="big-lock" />
                      <p className="pop-up-body">
                        Poll submission has been locked
                      </p>
                    </Card>
                  </div>
                )}
            </div>
          }
        />
      </NormalBox>
    </div>
  );
}
