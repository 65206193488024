import React from "react";
import { Col, Row } from "react-bootstrap";
import { isCustomEqual, timeCustomToString } from "../../utils/questionUtils";
import OptionButton from "./OptionButton";
import "./styles.css";

export default function TimeLimitOptionRow({
  timeLimitRow,
  timeLimit,
  // customTimeLimit,
  handleTimeLimitUpdate,
  forGetReady = false
}) {
  return (
    <div className="options-row">
      {Object.values(timeLimitRow).map((timeLimitCol, idx) => {
        return (
          <Col key={idx} className={`d-flex no-padding options-row-outer ${idx % 3 === 0 ? "justify-content-start" : (idx % 3 === 1 ? "justify-content-center" : "justify-content-end")}`}>
            <OptionButton
              tabIndex={0}
              key={idx}
              value={timeLimitCol[1]}
              textDisplay={forGetReady ? (timeLimitCol[1] + " Sec" + (timeLimitCol[1] > 1 ? "s" : "")) : timeCustomToString(timeLimitCol[1])}
              valueToEqual={timeLimit}
              compareValue={forGetReady ? (x, y) => (x == y) : isCustomEqual}
              // valueToNotEqual={customTimeLimit}
              handleClick={handleTimeLimitUpdate}
            />
          </Col>
        );
      })}
    </div>);
}
