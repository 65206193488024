import { BLANK_REGEX, DEFAULT_ENTRIES_PER_PARTICIPANT, QUESTION_TYPES } from "./constants";
import { initialQuestionData, questionNameToCode } from "./questionUtils";

export const updateAnswers = (input, itemKey, indexToChange, questions) => {
    // Make a shallow copy of the items
    let copyOfQuestions = [...questions];
    // Make a shallow copy of the item you want to mutate
    let newOptionsMap = { ...copyOfQuestions[indexToChange].optionsMap };
    // Replace the property you're intested in
    newOptionsMap[itemKey] = input;
    // Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    copyOfQuestions[indexToChange].optionsMap = newOptionsMap;
    // Return our new copy
    return copyOfQuestions;
  };
  
  const getCorrectAnswers = (questionType, input) => {
    switch (questionType) {
      case QUESTION_TYPES.MCSS.name:
        if (typeof input !== "string") {
          throw new Error(
            "Input type is wrong, should be string however received: " +
              typeof input
          );
        }
        return [input];
      case QUESTION_TYPES.TF.name:
        if (typeof input !== "string") {
          throw new Error(
            "Input type is wrong, should be string however received: " +
              typeof input
          );
        }
        return input;
      case QUESTION_TYPES.FITB.name:
        if (typeof input !== "object") {
          throw new Error(
            "Input type is wrong, should be object however received: " +
              typeof input
          );
        }
        return input;
      case QUESTION_TYPES.SA.name:
        if (!Array.isArray(input)) {
          throw new Error("Input type is wrong, should be array.");
        }
        return input;
      case QUESTION_TYPES.CI.name:
        if (typeof input !== "number") {
          throw new Error(
              "Input type is wrong, should be string however received: " +
              typeof input
          );
        }
        return [input];
      default:
        throw new Error(
          "Unhandled question type detected, question type code: " + questionType
        );
    }
  };

export const updateCorrectAnswers = (input, indexToChange, questions) => {
    // Make a shallow copy of the items
    let copyOfQuestions = [...questions];
    // Make a shallow copy of the item you want to mutate
    let item = { ...copyOfQuestions[indexToChange] };
    // Replace the property you're intested in
    item.correctAnswers = getCorrectAnswers(item.questionType, input);
    // Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    copyOfQuestions[indexToChange] = item;
    // Return our new copy
    return copyOfQuestions;
  };
  
  export const updateTitleInput = (input, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion["queTitle"] = input;
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };

  export const updateWeightage = (weightage, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion["weightage"] = weightage ? weightage : 1;
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };
  
  export const updateEntries = (entries, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion["entries"] = entries ? entries : 1;
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };

  export const updateTimeLimit = (timeLimit, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion["timeLimit"] = timeLimit;
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };

  export const updateGetReadyTimeLimit = (grtimeLimit, indexToChange, questions) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    newQuestion["getReadyTimeLimit"] = grtimeLimit;
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };
  
  export const updateQuestionType = (qType, indexToChange, questions, oldQType) => {
    let copyOfQuestions = [...questions];
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    const qTypeCode = questionNameToCode(qType);
    newQuestion["questionType"] = qTypeCode;
    if (!!copyOfQuestions[indexToChange].queTitle && copyOfQuestions[indexToChange].queTitle !== "" && oldQType !== QUESTION_TYPES.FITB.name ) {
      newQuestion["queTitle"] = copyOfQuestions[indexToChange].queTitle;
    } else {
      newQuestion["queTitle"] = "";
    }
    const { optionsMap, correctAnswers } = initialQuestionData(qTypeCode);
    copyOfQuestions[indexToChange] = newQuestion;
    let newCorrectAns;
    if(copyOfQuestions[indexToChange].queTitle !== "" && copyOfQuestions[indexToChange].queTitle.match(BLANK_REGEX) && QUESTION_TYPES.FITB.description === qType) {
        let temp = [];
        temp = updateCorrectAnswers(arrangeBlanks(copyOfQuestions[indexToChange].queTitle.match(BLANK_REGEX)), indexToChange, copyOfQuestions);
        newCorrectAns = temp[indexToChange].correctAnswers;
    } else {
      newCorrectAns = correctAnswers;
    }
    newQuestion["optionsMap"] = optionsMap;
    newQuestion["correctAnswers"] = newCorrectAns;
    newQuestion["suffix"] = ""; // Remove image suffix
    if (qTypeCode === QUESTION_TYPES.WC.name) {
      newQuestion["entries"] = DEFAULT_ENTRIES_PER_PARTICIPANT;
    }
    copyOfQuestions[indexToChange] = newQuestion;
    return copyOfQuestions;
  };

  export const updateAnswerImage = (file, itemKey, indexToChange, questions) => {
    const imageKey = "image" + itemKey;
    // Make a shallow copy of the items
    let copyOfQuestions = [...questions];
    // Make a shallow copy of the item you want to mutate
    let newQuestion = { ...copyOfQuestions[indexToChange] };
    // Replace the property you're intested in
    newQuestion[imageKey] = file;
    // Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    copyOfQuestions[indexToChange] = newQuestion;
    // Return our new copy
    return copyOfQuestions;
  };
  
  const arrangeBlanks = (blankKeys) => {
    let newBlanks = {};
    if (blankKeys) {
      blankKeys.forEach((key) => {
        key = key.replaceAll(")", "").replaceAll("(", "");
        newBlanks[key] = [""];
    });
  }
    return newBlanks;
  };