import React, { useEffect, useState } from "react";
import styles from "./platform.module.css";
import {Tab, Tabs} from "react-bootstrap";
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import PlatformSettingsTab from "./PlatformSettingTab";
import UserSettingsTab from "./UserSettingTab";
import BrandingTab from "./BrandingTab";
import {UserApiCalls} from "../../apis/UserApiCalls";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import {
    CREATOR_MENU_LIST,
    DEFAULT_LOGIN_METHODS,
    DEFAULT_TOAST_MESSAGE_DURATION, PLATFORM_SETTING_SAVE_ERROR_MSG,
    PLATFORM_SETTINGS,
    ROLES,
    TEXTBOX,
    USER_SETTINGS
} from "../../utils/constants";
import YuJaButton from "../../components/standardization/YuJaButton";
import {fetchImg, notifyError} from "../../utils/helpers";
import {S3_PUBLIC_BUCKET_API_URL} from "../../utils/properties";
import useHostname from "../../hooks/useHostname";
import defaultImage from "../LoginPage/images/main.jpg";

export default function PlatformSetting() {
    const [, getSession] = useLocalStorageNew("session", {});
    const [platformSettings, setPlatformSettings] = useState({});
    const {uploadImg} = InstitutionApiCalls();
    const session = getSession();
    const [imgUrl, setImgUrl] = useState("");
    const {role = ROLES.ANONYMOUS.value } = session;
    const {bgImgLocation=""} = useHostname(window.location.hostname);
    const [userSettings,  setUserSettings] = useState(() => {
        const {settings={}} = session;
        if (!settings.hasOwnProperty(USER_SETTINGS.TOAST_MESSAGE_DURATION)) {
            settings[USER_SETTINGS.TOAST_MESSAGE_DURATION] = DEFAULT_TOAST_MESSAGE_DURATION.toString();
        }
        return settings;
    });
    const {getSetting, updateSetting} = InstitutionApiCalls();
    const {saveSettings} = UserApiCalls();

    const fetchData = () => {
        getSetting().then((result) => {
            if (result !== null) {
                setPlatformSettings(result);
            }
        })
    }

    useEffect(() => {
        fetchData();

        if(document.getElementById("platformLastElem") != null) {
            document.getElementById("platformLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("settingsSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('settingsSaveBtn').focus();
                }
            });
        }

        if(document.getElementById("brandingLastElem") != null) {
            document.getElementById("brandingLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("settingsSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('settingsSaveBtn').focus();
                }
            });
        }

        if(document.getElementById("userLastElem") != null) {
            document.getElementById("userLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("settingsSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('settingsSaveBtn').focus();
                }
            });
        }
    }, []);

    const handleSavePlatformSettings = async () => {
        if (platformSettings[PLATFORM_SETTINGS.YUJA_LOGIN] === "true" && platformSettings[PLATFORM_SETTINGS.DEFAULT] === DEFAULT_LOGIN_METHODS.YUJA_LOGIN) {
            notifyError(PLATFORM_SETTING_SAVE_ERROR_MSG)
            return;
        }
        updateSetting(platformSettings);
    };

    const handleSaveUserSettings = async () => {
        saveSettings(userSettings);
    };

    const fetchImage = () => {
        fetchImg(`${S3_PUBLIC_BUCKET_API_URL}/s3?key=${bgImgLocation}`).then(img => {
            setImgUrl(img ? img : defaultImage)
        });
    };

    const handleSubmit = async () => {
        const imageData = document.querySelector('input[type="file"]').files[0];
        if (!imageData || !imageData.name) {
            return;
        }
        await uploadImg(imageData, fetchImage);
    };

    const handleSave = () => {
        handleSavePlatformSettings();
        handleSaveUserSettings();
        handleSubmit();
    };

    useEffect(() => {
        if(document.getElementById("engagePageIdentifier") != null) {
            document.getElementById("engagePageIdentifier").innerText = CREATOR_MENU_LIST[7].name;
        }
    }, []);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.btnCenterAlign}>
                <YuJaButton index={1} id="settingsSaveBtn" onClick={handleSave}>Save All Changes</YuJaButton>
            </div>
            <div className={styles.body}>
                <div className={styles.titleBox}>
                    <div className={styles.titleBoxIcon} />
                    <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>Settings</div>
                </div>
                <div className={styles.container}>
                    {role === ROLES.CREATOR.value &&
                        <UserSettingsTab saveSettings={handleSaveUserSettings} userSettings={userSettings} setUserSettings={setUserSettings}/>
                    }

                    {(role === ROLES.ADMINISTRATOR.value || role === ROLES.IT_MANAGER.value) &&
                        <Tabs defaultActiveKey="platform">
                            <Tab eventKey="platform" title="Platform">
                                <PlatformSettingsTab platformSettings={platformSettings} setPlatformSettings={setPlatformSettings}/>
                            </Tab>
                            <Tab eventKey="user" title="User">
                                <UserSettingsTab userSettings={userSettings} setUserSettings={setUserSettings}/>
                            </Tab>
                            <Tab eventKey="branding" title="Branding">
                                <BrandingTab imgUrl={imgUrl} setImgUrl={setImgUrl}/>
                            </Tab>
                        </Tabs>
                    }
                </div>
            </div>
        </div>
    )

}