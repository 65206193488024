import { Spin } from "../../components/standardization/YuJaLoading";
import React, { useState } from "react";
import { POLL_SHARE_MODE, POLL_TYPE } from "../../utils/constants";
import TabParticipantsLive from "./TabParticipantsLive";
import { ReactComponent as DropDown } from "./images/DROPDOWN_ICON.svg";
import { ReactComponent as DropUp } from "./images/DROPUP_ICON.svg";
import { ReactComponent as NoResponsesIcon } from "./images/NO_RESPONSES.svg";
import { ReactComponent as NoResponsesIconSmall } from "./images/NO_RESPONSES_SMALL.svg";
import { ReactComponent as ShiftNext } from "./images/SHIFT_Q_NEXT.svg";
import { ReactComponent as ShiftPrev } from "./images/SHIFT_Q_PREV.svg";
import "./styles.css";

export default function ViewResponses({data=[], loading=true, initialized=false, pollData=[], currQues, setCurrQues, localState}) {
    const [minimized, setMinimized] = useState(true);


    return(<div style={{marginBottom: "15px"}}>
        {minimized && 
            <div className="smallPanelBodyNew" style={{width: "350px"}}>
                <span className="smallPanelTitle">Responses</span>
                <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
            {/*<div className="smallPanelBody" style={{width: "350px"}}>
                <Spin size="medium" wrapperClassName="panelSpin" spinning={loading} style={{height: "100%"}}>
                    {initialized && (!data || !data.length || data[0].total === 0 || (pollData.poll.pollType === POLL_TYPE.SURVEY)) &&
                        <div style={{display: "flex", width: "100%"}}>
                            <div style={{display: "flex", flex: 5, alignItems: "center", flexDirection: "column"}}>
                                <NoResponsesIconSmall/>
                                <div>No responses found</div>
                            </div>
                            <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
                        </div>
                    }
                    {initialized && (!!data && !!data.length && data[0].total !== 0) && (!!pollData.questions && !!pollData.questions.length) && pollData.poll.pollType === POLL_TYPE.GRADED_POLL && <div style={{width: "100%"}}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <span className="smallPanelTitle">Responses</span>
                            <DropDown style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
                        </div>
                        <div className="pollCodePanelBody">
                            <TabParticipantsLive
                                correctAnswers={pollData.questions[currQues-1].correctAnswers}
                                chartData={data}
                                questionType={pollData.questions[currQues-1].questionType}
                                minimized={true}
                                pollData={pollData}
                                currQues={currQues}
                                setCurrQues={setCurrQues}
                            />
                        </div>
                    </div>}
                </Spin>
                </div>*/}
            </div>
        }
        {!minimized &&
        <div className="smallPanelBody2" style={{width: "350px"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span className="smallPanelTitle">Responses</span>
                <DropUp style={{cursor: "pointer"}} onClick={() => setMinimized(true)}/>
            </div>
            <span className="smallPanelSubtitle">See breakdown of {pollData.poll.pollType === POLL_TYPE.SURVEY ? "responses" : "Q"+currQues + " responses"}</span>
            <div className="pollCodePanelBody">
            <Spin size="large" wrapperClassName="panelSpin" spinning={loading} tip="Loading..." style={{height: "100%"}}>
                {initialized && (!data || !data.length || data[0].total === 0 || (pollData.poll.pollType === POLL_TYPE.SURVEY) || localState === "SHARED_ALL") &&
                    <>
                        <NoResponsesIcon style={{maxWidth: "100%", maxHeight: "100%"}}/>
                        <span>No responses found</span>
                        {pollData.poll.pollShareMode === POLL_SHARE_MODE.SHARE_ALL && pollData.questions.length > 1 && pollData.poll.pollType !== POLL_TYPE.SURVEY &&
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", bottom: "0"}} className="shiftQText">
                                <ShiftPrev style={{marginRight: "10px", cursor: "pointer"}} onClick={() => {setCurrQues(currQues === 1 ? 1 : currQues-1)}}/>Question {currQues}
                                <ShiftNext style={{marginLeft: "10px", cursor: "pointer"}} onClick={() => {setCurrQues(currQues === pollData.questions.length ? currQues : currQues+1)}}/>
                            </div>
                        }
                    </>
                }
                {initialized && (!!data && !!data.length && data[0].total !== 0) && (!!pollData.questions && !!pollData.questions.length) && pollData.poll.pollType === POLL_TYPE.GRADED_POLL && localState !== "SHARED_ALL" &&
                    <TabParticipantsLive
                        correctAnswers={pollData.questions[currQues-1].correctAnswers}
                        chartData={data}
                        questionType={pollData.questions[currQues-1].questionType}
                        minimized={false}
                        pollData={pollData}
                        currQues={currQues}
                        setCurrQues={setCurrQues}
                    />
                }
            </Spin>
            </div>
        </div>}
    </div>);
}
