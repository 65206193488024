import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { DEFAULT_POLL_TIME_LIMIT, DEFAULT_TIME_LIMIT, POLL_STATE, RESULTS, WAIT_LENGTH } from "../../utils/constants";
import { getInstitutionId, notifyError, notifySuccess} from "../../utils/helpers";
import ViewExpiredQuestion from "./ViewExpiredQuestion";
import MANAGE_POLLS_ICON from './images/MANAGE_POLLS_ICON.svg';
import "./styles.css";
import { TIME_SHARE_ALL_ERROR } from "../../utils/toast-message-constants";

export default function PublishedPollPage({ setSidebarContent }) {
    const { pollKey, pollCode } = useParams();
    const { getPublishedPollDataFromKey, shareQuestion, shareAllQuestions, stopQuestion, stopAllQuestions, lockQuestion, pauseQuestion, resumeQuestion } = PollApiCalls();
    const [pollData, setPollData] = useState({});
    const countDownRef = useRef();
    const handleClockStart = () => countDownRef.current.start();
    const handleClockPause = () => countDownRef.current.pause();
    const [queTimeLimit, setQueTimeLimit] = useState(0);
    const [, setQueEnd] = useState(0);
    const [, setPollEnd] = useState(0);
    const [pollTimeLimit, setPollTimeLimit] = useState(0);
    const [pollType, setPollType] = useState("");
    // const [timeRemaining, setTimeRemaining] = useState(0);
    const timeRemainingRef = useRef(0);
    const [isTimed, setIsTimed] = useState(true);
    const [localState, setLocalState] = useState("START POLL");
    const [curQue, setCurQue] = useState({});
    const [, getTimeOffset] = useLocalStorageNew("timeOffset", 0);
    const [questionButtonStatuses, setQuestionButtonStatuses] = useState([]);
    const [, setLivePollTime] = useState(0);
    const [sharingAll, setSharingAll] = useState(false);
    const [institutionId, setInstitutionId] = useState("");
    const [currentQuestion, setCurrentQuestion] = useState(1);

    useEffect(() => {
        if(document.getElementById("engagePageIdentifier") != null) {
            document.getElementById("engagePageIdentifier").innerText = RESULTS;
        }
    }, []);

    const removeHighlight = () => {
        if(document.getElementById("QuestionTitle" + currentQuestion) !== null) {
            document.getElementById("QuestionSelect" + currentQuestion).classList.remove("outer-question-on");
            document.getElementById("QuestionHead1" + currentQuestion).classList.remove("question-index-on");
            document.getElementById("QuestionHead2" + currentQuestion).classList.remove("question-body-on");
            document.getElementById("QuestionTitle" + currentQuestion).classList.remove("question-title-container-on");
            document.getElementById("QuestionTab" + currentQuestion).classList.remove("question-tab-select-on");
        }
    }

    const handleQuestionSelect = (question) => {
        removeHighlight();
        document.getElementById("QuestionSelect" + question.serialNo).classList.add("outer-question-on");
        document.getElementById("QuestionHead1" + question.serialNo).classList.add("question-index-on");
        document.getElementById("QuestionHead2" + question.serialNo).classList.add("question-body-on");
        document.getElementById("QuestionTab" + question.serialNo).classList.add("question-tab-select-on");
        document.getElementById("QuestionTitle" + question.serialNo).classList.add("question-title-container-on");
        setCurrentQuestion(question.serialNo);
    }


    const setButtonStatuses = async (data) => {
        if(typeof(data.questions) != 'undefined'){
            let len = data.questions.length;
            setQuestionButtonStatuses(Array(len).fill(false));
        }
    }

    useEffect( () => {
        if(document.getElementById("QuestionTitle1") !== null) {
            document.getElementById("QuestionSelect" + currentQuestion).classList.add("outer-question-on");
            document.getElementById("QuestionHead1" + currentQuestion).classList.add("question-index-on");
            document.getElementById("QuestionHead2" + currentQuestion).classList.add("question-body-on");
            document.getElementById("QuestionTab" + currentQuestion).classList.add("question-tab-select-on");
            document.getElementById("QuestionTitle" + currentQuestion).classList.add("question-title-container-on");
        }
    }, [pollData]);

    const handleShare = async (que) => {
        const {serialNo} = que;
        
        let newButtonStatuses = Array(questionButtonStatuses.length).fill(true);
        newButtonStatuses[serialNo - 1] = false;
        setQuestionButtonStatuses(newButtonStatuses);

        let res = await shareQuestion(pollKey, pollCode, serialNo);
        setLocalState('GET_READY');
        setCurQue(que);
        return res.liveQuestionTime + parseInt(getTimeOffset());
    };

    const handleShareAll = async () => {

        let pollTimeLimit;

        try {
            let hours = document.getElementById('hrs').value === "" ? 0 : parseInt(document.getElementById('hrs').value);
            let minutes = document.getElementById('mins').value === "" ? 0 : parseInt(document.getElementById('mins').value);
            let seconds = document.getElementById('secs').value === "" ? 0 : parseInt(document.getElementById('secs').value);

            pollTimeLimit = hours * 3600 + minutes * 60 + seconds;
            if (pollTimeLimit <= 0) {
                // pollTimeLimit = UNTIMED_POLL_TIME_LIMIT;
                setIsTimed(false);
                notifyError(TIME_SHARE_ALL_ERROR);
                return;
            }
            // console.log(pollTimeLimit);
        } catch (e) {
            console.log(e);
            pollTimeLimit = DEFAULT_POLL_TIME_LIMIT;
        }

        let res = await shareAllQuestions(pollKey, pollCode);
        // Freeze all buttons
        let newButtonStatuses = Array(questionButtonStatuses.length).fill(true);
        setQuestionButtonStatuses(newButtonStatuses);
        // if (isTimed) {
            setLocalState('GET_READY_ALL');
        // } else {
            // setLocalState('SHARED_ALL');
        // }
        setPollEnd(res.liveQuestionTime + parseInt(getTimeOffset()) + pollTimeLimit * 1000);
        setPollTimeLimit(pollTimeLimit);
        setSharingAll(true);
    };

    const handleStop = async (que) => {
        const {serialNo} = que;

        let newButtonStatuses = Array(questionButtonStatuses.length).fill(false);
        setQuestionButtonStatuses(newButtonStatuses);

        await stopQuestion(pollKey, pollCode, serialNo);
        setLocalState('STOPPED');
        setQueEnd(Date.now());
        setCurQue(que);
    };

    const handleStopAll = async () => {
        let newButtonStatuses = Array(questionButtonStatuses.length).fill(false);
        setQuestionButtonStatuses(newButtonStatuses);

        await stopAllQuestions(pollKey, pollCode);
        setLocalState('STOPPED_ALL');
    };


    const handleLock = async (que) => {
        const {serialNo} = que;

        let newButtonStatuses = Array(questionButtonStatuses.length).fill(true);
        newButtonStatuses[serialNo - 1] = false;
        setQuestionButtonStatuses(newButtonStatuses);

        await lockQuestion(pollKey, pollCode, serialNo);
        setLocalState('LOCKED');
        setQueEnd(Date.now());
        setCurQue(que);
    };

    const handlePause = async (que) => {
        const {serialNo} = que;

        let newButtonStatuses = Array(questionButtonStatuses.length).fill(true);
        newButtonStatuses[serialNo - 1] = false;
        setQuestionButtonStatuses(newButtonStatuses);

        await pauseQuestion(pollKey, pollCode, serialNo, timeRemainingRef.current);
        setLocalState('PAUSED');
        setCurQue(que);
        handleClockPause();
    };

    const handleResume = async (que) => {
        await resumeQuestion(pollKey, pollCode, que, timeRemainingRef.current);
        setLocalState('RESUMED');
        setCurQue(que);
        handleClockStart();
    };

    useEffect(() => {

        getPublishedPollDataFromKey(pollKey, pollCode).then(res => {
            const {poll, questions} = res;
            setPollData(res);
            setButtonStatuses(res);
            setPollType(res.poll.pollType);
            const {liveQuestionNo = 0, pollState = '', index4Sk: updateTimeEpoch, liveQuestionTime = 0, pollTimeLimit, index5Pk} = poll;
            setInstitutionId(getInstitutionId(index5Pk));

            if (pollState === "SHARED_ALL") {

                setPollData(res);
                setButtonStatuses(res);

                // Allow for user input of poll time limit (TBD)

                let timeLimit, pollEndPoint, classResultEndTime;
                if (pollTimeLimit) {
                    timeLimit = pollTimeLimit;
                } else {
                    try {
                        let hours = document.getElementById('hrs').value === "" ? 0 : parseInt(document.getElementById('hrs').value);
                        let minutes = document.getElementById('mins').value === "" ? 0 : parseInt(document.getElementById('mins').value);
                        let seconds = document.getElementById('secs').value === "" ? 0 : parseInt(document.getElementById('secs').value);

                        console.log(hours);
                        console.log(minutes);
                        console.log(seconds);

                        timeLimit = hours * 3600 + minutes * 60 + seconds;
                    } catch (e) {
                        console.log(e);
                        timeLimit = DEFAULT_POLL_TIME_LIMIT;
                    }
                }
                
                setLivePollTime(liveQuestionTime);
                pollEndPoint = liveQuestionTime + timeLimit * 1000;
                setPollTimeLimit(timeLimit);
                setPollEnd(pollEndPoint)
                classResultEndTime = pollEndPoint + (WAIT_LENGTH + 2) * 1000;
                const currentTime = Date.now();
                

                if (isTimed && liveQuestionTime > currentTime) {
                    setLocalState("GET_READY_ALL");
                    setSharingAll(true);
                    return;
                }
    
                if (pollEndPoint > currentTime) {
                    setLocalState("SHARED_ALL");
                    setSharingAll(true);
                    return;
                }
    
                if (classResultEndTime > currentTime) {
                    setLocalState("TIMEUP_ALL");
                    setSharingAll(false);
                    return;
                }


            } else {
                if (liveQuestionNo < 1) {
                    return;
                }
                const liveQue = questions[liveQuestionNo - 1];
                setCurQue(liveQue);
                const { timeLimit = DEFAULT_TIME_LIMIT, } = curQue;
                let queEndPoint = pollState === 'LOCKED' ? Date.parse(updateTimeEpoch) : liveQuestionTime + timeLimit * 1000;
                let classResultEndTime = queEndPoint + (WAIT_LENGTH + 2) * 1000;
                const currentTime = Date.now();
    
                setQueTimeLimit(timeLimit);
                if (pollState.includes(POLL_STATE.PAUSED)) {
                    setLocalState(POLL_STATE.PAUSED);
                    const timeLeft = pollState.split('-')[1];
                    queEndPoint = parseInt(timeLeft) * 1000 + currentTime;
                    timeRemainingRef.current = parseInt(timeLeft);
                    setQueEnd(queEndPoint);
                    handleClockPause();
                    return;
                }
    
                setQueEnd(queEndPoint);
                if (classResultEndTime < currentTime || pollState === POLL_STATE.LOCKED) {
                    setLocalState("TIMEUP");
                    return;
                }
    
                if (liveQuestionTime > currentTime) {
                    setLocalState("GET_READY");
                    return;
                }
    
    
                if (queEndPoint > currentTime) {
                    setLocalState("SHARED");
                    return;
                }
    
                if (classResultEndTime > currentTime) {
                    setLocalState('TIMEUP');
                }
            }
        })
    }, []);

    useEffect(() => {
        if (localState !== "TIMEUP" && localState !== "TIMEUP_ALL" && localState !== "STOPPED" && localState !== "GET_READY_ALL" && localState !== "STOPPED_ALL" && localState !== "LOCKED") {
            let newButtonStatuses = Array(questionButtonStatuses.length).fill(true);
            newButtonStatuses[curQue.serialNo - 1] = false;
            setQuestionButtonStatuses(newButtonStatuses);
        } else if (localState === "GET_READY_ALL") {
            let newButtonStatuses = Array(questionButtonStatuses.length).fill(true);
            setQuestionButtonStatuses(newButtonStatuses);
        } else {
            let newButtonStatuses = Array(questionButtonStatuses.length).fill(false);
            setQuestionButtonStatuses(newButtonStatuses);
        }

    }, [localState, curQue])


    const timeUp = () => {
        let newButtonStatuses = Array(questionButtonStatuses.length).fill(false);
        setQuestionButtonStatuses(newButtonStatuses);
        setIsTimed(true);
        sharingAll ? setLocalState("TIMEUP_ALL") : setLocalState("TIMEUP");
        setSharingAll(false);
    };

    const renderer = ({ hours, minutes, seconds }) => {
        if (localState === 'STOPPED' || localState === 'LOCKED' || localState === 'START POLL') {
            return (
                <>
                    <div className="timer-outer">
                        <div className="img-wrap timer-holder-share">
                            <span className="timer-count" id="tc">{localState}</span>
                        </div>
                    </div>
                </>
            );
        }

        if (localState === 'TIMEUP_ALL') {
            return (
                <>
                    <div className="timer-outer">
                        <div className="img-wrap timer-holder-share">
                            <span className="timer-count" id="tc">Poll Done</span>
                        </div>
                    </div>
                </>
            );
        }

        if (localState === 'TIMEUP') {
            return (
                <>
                    <div className="timer-outer">
                        <div className="img-wrap timer-holder-share">
                            <span className="timer-count" id="tc">Question Done</span>
                        </div>
                    </div>
                </>
            );
        }

        const hoursView = ('00' + hours).slice(-2);
        const minutesView = ('00' + minutes).slice(-2);
        const secondsView = ('00' + seconds).slice(-2);
        const urgent = hours === 0 && minutes === 0 && seconds < 4 && seconds !== 0;
        if (localState === 'PAUSED') {
            return (
                <>
                    <div className="timer-outer">
                        <div className="img-wrap timer-holder-share">
                            <span className={`timer-count ${urgent ? 'urgent' : ''}`} id="tc" >{hoursView}:{minutesView}:{secondsView}</span>
                        </div>
                    </div>
                </>
            );
        }
        const time = (seconds + minutes * 60 + hours * 3600);
        timeRemainingRef.current = time;
        if (localState === "GET_READY_ALL" && time > pollTimeLimit) {
            return (
                <>
                    <div className="timer-outer">
                        <div className="img-wrap timer-holder-share">
                            <span className="timer-count" id="tc">Get Ready: {time - pollTimeLimit - 1}</span>
                        </div>
                    </div>
                </>
            );
        }


        if (localState === "GET_READY" && time > queTimeLimit) {
            return (
                <>
                    <div className="timer-outer">
                        <div className="img-wrap timer-holder-share">
                            <span className="timer-count" id="tc">Get Ready: {time - queTimeLimit - 1}</span>
                        </div>
                    </div>
                </>
            );
        }
        
        // pad with 2 leading zeros
        if(time && sharingAll && time <= pollTimeLimit){
            setLocalState('SHARED_ALL');
            return (
                <>
                    <div className="timer-outer">
                        <div className="img-wrap timer-holder-share">
                            <span className={`timer-count ${urgent ? 'urgent' : ''}`}  id="tc">{hoursView}:{minutesView}:{secondsView}</span>
                        </div>
                    </div>
                </>
            );
        }

        if (time && time <= queTimeLimit) {
            setLocalState('SHARED');
            return (
                <>
                    <div className="timer-outer">
                        <div className="img-wrap timer-holder-share">
                            <span className={`timer-count ${urgent ? 'urgent' : ''}`}  id="tc">{hoursView}:{minutesView}:{secondsView}</span>
                        </div>
                    </div>
                </>
            );
        }

        return <></>;
    };

    return (
        <>
            <div className="share-body">
                {(pollData !== null && Object.entries(pollData).length > 0) && (<>
                    <div style={{ display: "flex" }}>
                        <div className="question-container">
                            <h3 className="poll-title-container">
                                <div className="poll-title-icon-container">
                                    <img src={MANAGE_POLLS_ICON} alt="Manage Polls Icon"></img>
                                </div>
                                <div
                                    style={{marginLeft: "18px"}}>{pollData.poll.pollTitle} ( {pollCode} )
                                </div>
                            </h3>
                            {pollData.questions.map((question, index) => {
                                return (
                                    <div key={question.serialNo} className="outer-question">
                                        <div id={"QuestionSelect" + question.serialNo} style={{flex: 0.1}}></div>
                                        <div
                                            id={"QuestionTitle"+ question.serialNo}
                                            className="question-title-container"
                                            onClick={() => {
                                                handleQuestionSelect(question);
                                            }}
                                            tabIndex={0}
                                            >
                                            <div id={"QuestionTab" + question.serialNo} className="question-tab-select"></div>
                                            <h5 id={"QuestionHead1" + question.serialNo} className="question-index">Q{question.serialNo}</h5>
                                            <h5 id={"QuestionHead2" + question.serialNo} className="question-body">{question.queTitle}</h5>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="published-poll-data">
                            <ViewExpiredQuestion question={pollData.questions[currentQuestion-1]} pollType={pollType} institutionId={institutionId}/>
                        </div>
                    </div>
                </>)}
            </div>
        </>
    );
}