import { useState } from "react";
import { Form } from "react-bootstrap";
import styles from "./ManageUserPage.module.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import {ReactComponent as SearchIcon} from "../../images/search_bar_icon.svg";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import { SEARCH_USER, USERS_SEARCH } from "../../utils/constants";

export default function UserSearchForm({ handleSubmit }) {
    const [keyword, setKeyword] = useState("");
    const [date] = useState("");

    const clickSearch = () => {
        let formattedDate = date ? date.split("-").reverse().join("-") : "";
        handleSubmit({ keyword: keyword, date: formattedDate });
    }

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch();
        }
    }

    return (
        <div className={styles.searchBox}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
                <YuJaTextBox
                    containerStyle={{width: "30vw", fontSize: 13, marginRight: 20}}
                    placeholder={SEARCH_USER}
                    onChange={e => setKeyword(e.target.value)}
                    onKeyUp={enterKeyup}
                    value={keyword}
                    before={<SearchIcon style={{marginRight: 5}}/>}
                    label={USERS_SEARCH}
                />

                <YuJaButton onClick={clickSearch}>Search</YuJaButton>
            </Form>
        </div>
    );
}
