import { TOGGLE, TOGGLE_LABEL } from "../../utils/constants";
import styles from "./YuJaToggle.module.css";


export const YuJaToggle = ({toggled=false, ariaLabel, onClick, label="",...props}) => {
    return (
        <div className={styles.container} {...props}>
            <div className={styles.toggleContainer} onClick={onClick}>
                <input className={styles.actualCheckBox} type="checkbox" aria-label={ariaLabel} checked={toggled} onChange={() => {}}/>
                <span className={styles.backgroundWrapper} />
            </div>
            {label &&
                <span className={styles.label}>{label}</span>   
            }
        </div>

    )
}