import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import GenericModal from "../../components/modals/GenericModal";
import CancelIcon from "../../images/cancel.svg";
import NormalButton from "../../public/buttons/NormalButton";
import { WSS_BASE_URL } from "../../utils/properties";
import PublishModalLeftPane from "./PublishModalLeftPane";
import PublishModalRightPane from "./PublishModalRightPane";
import "./styles.css";

export default function PublishModal({
  pollCode,
  show,
  setModalShow,
  isGenerated,
  handleClickOkay,
  handleClickClose,
  isFromCreating = true
}) {
  const pollButtonsStyle = {
    float: "right",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11.6923px",
    paddingTop: "10px",
    paddingBottom: "10px",
    margin: "16px 0px 20px",
  };

  const { uniqueCode } = pollCode;
  const {fakeCall} = ResponseApiCalls();

  useEffect(() => {
    if (show) {
      Promise.all([new Promise((resolve) => {
        new WebSocket(WSS_BASE_URL + "?pollKey=123456");
        resolve();
      }), new Promise((resolve) => {
        fakeCall().then();
        resolve();
      })]).then();
    }

  }, [show])

  return (
    <GenericModal
      show={show}
      onHide={handleClickClose}
      content={
        <Container className="center">
          <Row>
            <Col>
              <img
                src={CancelIcon}
                onClick={handleClickClose}
                alt="delete icon"
                className="publish-modal-close-button cursor-pointer"
              />
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                marginBottom: "0.875rem",
              }}
            >
              {isGenerated ? (
                <span className="publish-modal-title">
                  Poll Code is{" "}
                  <span style={{ color: "#0a820e" }}>
                    successfully generated.
                  </span>
                </span>
              ) : (
                <span className="publish-modal-title">Poll Code</span>
              )}
            </Col>
          </Row>
          <Row className="publish-modal-subtext-row">
            <Col>
              <span className="publish-modal-subtitle">
                Copy code or scan QR code and share it with students. Students
                will use the code to join the poll.
              </span>
            </Col>
          </Row>
          <Row>
            <Col style={{ borderRight: "1px solid #C8C8C8" }}>
              <PublishModalLeftPane uniqueCode={uniqueCode} />
            </Col>
            <Col>
              <PublishModalRightPane uniqueCode={uniqueCode} />
            </Col>
          </Row>
          {isFromCreating ?
            (<Row>
              <Col style={{ borderTop: "0.5px solid #C8C8C8" }}>
                <NormalButton style={pollButtonsStyle} onClick={handleClickOkay}>Share Now</NormalButton>
              </Col>
            </Row>) :
            (<Row style={{ height: "3rem" }}>
              <Col className="margin-auto">
              </Col>
            </Row>)}
        </Container>
      }
    />
  );
}
