import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Container, Row } from "react-bootstrap";
import AddButton from "../../components/buttons/AddButton";
import DropDown from "../../components/DropDown";
import { ReactComponent as DeleteIcon} from "../../images/delete.svg";
import { ReactComponent as DragDropIcon} from "../../images/drag_drop_grey.svg";
import { ReactComponent as DuplicateIcon} from "../../images/duplicate.svg";
import {
  addQuestion,
  deleteQuestion,
  duplicateQuestion,
  handleQuestionOrderChange,
  questionCodeToName
} from "../../utils/questionUtils";
import SidebarIcons from "./SidebarIcons";
import "./styles.css";
import { YuJaToggle } from "../../components/standardization/YuJaToggle";
import {
    QUESTION_TYPES,
    SWITCH_POLL_MESSAGE,
    SWITCH_POLL_MODAL_TEXT,
    SWITCH_POLL_MODAL_TITLE,
    GRADED_POLL,
    SURVEY,
    ADD_QUESTION_TEXT,
    IMPORT_BANK_TEXT,
    CREATE_QUESTION_PROFILE_MODAL_TITLE,
    QUESTION_DEFAULT_OPTIONS,
    POLL_TYPE,
    DISABLE_ANONYMOUS_SIDE_BAR,
    CONFIGURE_GRADEBOOK_OPTIONS_TEXT,
    SHOW_CORRECT_ANSWER_CHECKBOX_LABEL,
    SURVEY_TEXT,
    QUES_LABEL_1,
    QUES_LABEL_2,
    QUES_LABEL_3,
    DUPLICATE_BTN,
    BUTTON,
    DELETE_BTN,
    QUES_BANK_SAVE,
    TEXTBOX
} from "../../utils/constants";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import useHostname from "../../hooks/useHostname";
import {S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME} from "../../utils/properties";
import {
    fetchImgFile,
    getFileNameFromImageName,
    getFormattedCurrentTime,
    getSuffixFromImageName,
    notifyError
} from "../../utils/helpers";
import {PollApiCalls} from "../../apis/PollApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import "../../components/buttons/addButton.css";
import useMobileAccess from "../../hooks/useMobileAccess";
import CreateQuestionProfileModal from "../../components/modals/CreateQuestionProfileModal";
import QuestionProfileModal from "../../components/modals/QuestionProfileModal";
import { SAVE_BANK_WARNING_MESSAGE } from "../../utils/toast-message-constants";
import YuJaButton from "../../components/standardization/YuJaButton";
import {ReactComponent as PlusIcon} from "../../images/plus.svg";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import CreateQuestionDetailSidebar from "./CreateQuestionDetailSidebar";
import GradebookOptionsModal from "../../components/modals/GradebookOptionsModal";

export default function CreateQuizSidebar({
                                            setQuestions,
                                            questions,
                                            currQueDisplay,
                                            setCurrQueDisplay,
                                            pollKey,
                                            pollType,
                                            recordAttempt,
                                            setRecordAttempt,
                                            lmsAttempt,
                                            setLMSAttempt,
                                            maxAttempts,
                                            setMaxAttempts,
                                            showAnswerOnViewer,
                                            setShowAnswerOnViewer,
                                            anonymousJoin, 
                                            setAnonymousJoin, 
                                            isBank= false,                                     
}) {
    const hostResource = useHostname(window.location.hostname);
    const {institutionId = ""} = hostResource;
    const [, getSession] = useLocalStorageNew("session", {});
    const { tempSaveImage } = PollApiCalls();
    const isNarrowScreen = useMobileAccess(1200)
    const [showGradebookOptions, setShowGradebookOptions] = useState(false);

    const [showDragIcon, setShowDragIcon] = useState(null);

    // const [dragIconShowSerialNo, setDragIconShow] = useState();

    const [showButton, setShowButton] = useState(true);
    // const [alertShow, setAlertShow] = useState(false);
    // const [alertContent,] = useState(SWITCH_POLL_MESSAGE);
    const [setType,] = useLocalStorageNew("pollType", "");

    const [importingModal, setImportingModal] = useState(false); 
    const [profileSettingModalShow, setProfileSettingModalShow]= useState(false); 

    const [currentView, setCurrentView] = useState(0); 

    const handleDragStart = () => {
      setShowButton(false);
    };

    const handleDragEnd = () => {
      setShowButton(true);
    };

    const clickNewQuestion = (e) => {
        e.stopPropagation();
        const newQuestions = addQuestion(questions, QUESTION_TYPES.MCSS.name);
        setQuestions(newQuestions);
        setCurrQueDisplay(newQuestions.length);
    };

    const clickDuplicate = async (selectedQuestion, e) => {
        e.stopPropagation();
        const {userId} = getSession();

        if (!institutionId || !userId) {
            return;
        }

        //check if the current question have to copy image
        let file;
        if ((selectedQuestion.image || selectedQuestion.suffix) && !selectedQuestion.directLinkEvp) {
            let url = !!pollKey
                ? `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`
                : `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${userId}`;
            url  = !!selectedQuestion.suffix
                ? `${url}/temp-${selectedQuestion.serialNo}${selectedQuestion.suffix}`
                : `${url}/${selectedQuestion.image}`;
            url = !!selectedQuestion.imgUrl ? selectedQuestion.imgUrl : url;

            file = await fetchImgFile(url);
            file.name = !!selectedQuestion.suffix
                ? `temp-${getFormattedCurrentTime()}${selectedQuestion.suffix}`
                : getFileNameFromImageName(selectedQuestion.image) + "-" +
                getFormattedCurrentTime() + getSuffixFromImageName(selectedQuestion.image);
            tempSaveImage(file);
        }

        const imageName = !!file ? file.name: "";
        const imageUrl = !!file ? URL.createObjectURL(file) : "";
        const newQuestions = duplicateQuestion(selectedQuestion, questions, imageName, imageUrl);
        const {serialNo} = selectedQuestion;
        setQuestions(newQuestions);
        setCurrQueDisplay(serialNo + 1);
    };

    const clickDelete = (selectedQuestion, e) => {
        e.stopPropagation();
        const {serialNo} = selectedQuestion;
        const newQuestions = deleteQuestion(selectedQuestion, questions);
        setCurrQueDisplay(serialNo === 1 ? 1 : serialNo - 1);
        setQuestions(newQuestions);
    };

  //   const alertConfig = {
  //   title: SWITCH_POLL_MODAL_TITLE,
  //   okText: SWITCH_POLL_MODAL_TEXT,
  //   submit: async () => {
  //     console.log(pollType);
  //     setAlertShow(false);
  //     var otherPoll = (pollType === GRADED_POLL ? SURVEY : GRADED_POLL); 
  //     setDesiredPollMode(otherPoll);
  //     await handlePollTypeChange(otherPoll, true);
  //     setPollType(otherPoll);  
  //     setType(otherPoll);
  //   },
  //   close:  async () => {
  //     setAlertShow(false);
  //   }, 
  // };

  const questionBankCheckbox = () => {
    let show = false; 
    questions.forEach((question) => {
      if(question.queTitle !== "") {
        show = true; 
      }
      else if (question.hasOwnProperty("image") && question.image !== "") {
        show = true; 
      }
      else if (question.questionType == QUESTION_TYPES.MCSS.name && JSON.stringify(question.optionsMap) !== QUESTION_DEFAULT_OPTIONS.MCSS) {
        show = true; 
      }
    })
    return show; 
  }

  const handleCheckboxChange = () => {
    if(questionBankCheckbox()){
      setProfileSettingModalShow(true);
    }
    else {
      notifyError(SAVE_BANK_WARNING_MESSAGE);
    }
  }

  return (
    <> 
    <GradebookOptionsModal
            show={showGradebookOptions}
            setShow={setShowGradebookOptions}
            recordAttempt={recordAttempt}
            setRecordAttempt={setRecordAttempt}
            lmsAttempt={lmsAttempt}
            setLMSAttempt={setLMSAttempt}
            maxAttempts={maxAttempts}
            setMaxAttempts={setMaxAttempts}
        />
    <CreateQuestionProfileModal setModalShow={setProfileSettingModalShow} show={profileSettingModalShow} questions={questions} pollKey={pollKey}/>
    <QuestionProfileModal show={importingModal} setModalShow={setImportingModal} setQuestions={setQuestions} questions={questions} pollType={pollType}/>
    {/* <YuJaGeneralAlert
            show={alertShow}
            setModalShow={setAlertShow}
            content={alertContent}
            config={alertConfig}
        /> */}
         {currentView === 0 ? 
        <div style={{paddingBottom: '100px'}}> 
    <DragDropContext
      onDragStart={handleDragStart}
      onDragEnd={(result) => {
        const updatedQuestions = handleQuestionOrderChange(result, questions);
        if (updatedQuestions) {
          setQuestions(updatedQuestions);
        }
        handleDragEnd(); 
      }}
    >
      <Droppable droppableId="droppable-sidebar">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dropHandleProps}
          >
            <Container className="max-height">
              <div style={{display: "flex", width: "100%", height: "45px", marginTop: "36px"}}> 
              <div style={{fontWeight: "700", fontFamily: "Geomanist", display: "flex", justifyContent: "center", fontSize: "14.688px", backgroundColor: "#F7EFF6", width: "50%", alignItems: "center", borderTopLeftRadius: "10.2px", borderBottom: currentView === 0 ? "2px solid #6750A4": "", cursor: "pointer"}} tabIndex={0} role={TEXTBOX} onClick={()=> {setCurrentView(0)}}> Dashboard </div>
              <div style={{fontWeight: "400", fontFamily: "Geomanist",  display: "flex", justifyContent: "center", fontSize: "14.688px", backgroundColor: "#F7EFF6", width: "50%" , alignItems: "center", borderTopRightRadius: "10.2px",  borderBottom: currentView === 1 ? "2px solid #6750A4": "", cursor: "pointer"}} tabIndex={0} role={TEXTBOX} onClick={()=> {setCurrentView(1)}}> Question Details </div>
              </div>
              <div className="question-dashboard-list" style={{marginTop: "22px", height: `${questions.length * 60.8}px`}}>
              {questions.map((question, index) => {
                const { serialNo, questionType, queTitle } = question;
                return (
                  <div
                    key={index}
                    onClick={(event) => {
                      setCurrQueDisplay(serialNo);
                    }}
                  >
                    <Draggable
                      key={String(serialNo)}
                      draggableId={String(serialNo)}
                      index={index}
                    >
                        {(draggableProvided) => (
                            <div
                                className={`question-bar-container ${serialNo === currQueDisplay ? "is-cur" : ""}`}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                ref={draggableProvided.innerRef}
                                aria-label={QUES_LABEL_1 + serialNo + " " + questionCodeToName(questionType) + QUES_LABEL_2 + QUES_LABEL_3}
                                onMouseEnter={() => setShowDragIcon(index)}
                                onMouseLeave={() => setShowDragIcon(null)}
                                role={TEXTBOX}
                            >
                                <div style={{height: "50.84px", width: "4.47px", borderRadius: "9px", backgroundColor: serialNo === currQueDisplay ? "#6750A4" : "", marginRight: "7px"}}> 

                                </div>
                                <div style={{width: "25px", height: "25px"}}> 
                                {!isNarrowScreen && showDragIcon === index &&
                                    <DragDropIcon
                                        alt="drag drop icon"
                                        title={"Drag to Recorder"}
                                        style={{marginRight: 16, width: "17.831px", height: "27.886px"}}
                                    />
                                }
                                </div> 
                                <div style={{flex: 1}}>
                                    <div className="sidebar-question" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", wordBreak: "keep-all", maxWidth: "200px"}}>
                                        {!!queTitle
                                            ? queTitle
                                            : `Question ${serialNo}`}
                                    </div>
                                    <div className="sidebar-question-type">
                                        {questionCodeToName(questionType)}
                                    </div>
                                </div>
                                <DuplicateIcon
                                    title={"Duplicate Question"}
                                    alt="duplicate icon"
                                    onClick={(e) => clickDuplicate(question, e)}
                                    style={{height: "28.646px", width: "28.646px"}}
                                    tabIndex={0}
                                    aria-label={DUPLICATE_BTN + (index+1)}
                                    role={BUTTON}
                                />
                                <DeleteIcon
                                    title={"Delete Question"}
                                    alt="delete icon"
                                    onClick={(e) => clickDelete(question, e)}
                                    tabIndex={0}
                                    aria-label={DELETE_BTN + (index+1)}
                                    role={BUTTON}
                                    style={{height: "28.646px", width: "28.646px"}}
                                />
                            </div>
                        )}
                    </Draggable>
                  </div>
                );
              })}
              </div>
            </Container>
            { showButton ?
               <div style={{display:"flex", textAlign:"center", justifyContent: !isBank ? "space-between" : 'center', padding: "12px", flexWrap: "wrap", gap: 5}}>
                   <YuJaButton  title={ADD_QUESTION_TEXT} onClick={clickNewQuestion} style={{fontSize: 14}}>
                       <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {ADD_QUESTION_TEXT}
                  </YuJaButton>
                  {!isBank && 
                   <YuJaButton  title={IMPORT_BANK_TEXT} onClick={() => { setImportingModal(true) }} style={{fontSize: 13}}>
                       <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {IMPORT_BANK_TEXT}
                   </YuJaButton>
                  }
              </div>  
            : null }

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    <div> 
    </div>
    { !isBank &&
    <div style={{display: "flex", gap: "10px", flexDirection: "column", paddingLeft: "20px", position: "absolute", bottom: "40px", height: "60px", paddingRight: "20px", fontSize: "14px"}}>
            <YuJaCheckbox ariaLabel={QUES_BANK_SAVE + (profileSettingModalShow ? "checked" : "unchecked")} checked={profileSettingModalShow} onClick={handleCheckboxChange} label={CREATE_QUESTION_PROFILE_MODAL_TITLE} color={"#0C7086"} style={{marginBottom: "10px" }}/>
              {pollType !== SURVEY_TEXT &&
                <YuJaButton onClick={() => setShowGradebookOptions(true)} style={{ borderRadius: "4.21px", border: "1.336px solid #42296E", height: "35px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                  {CONFIGURE_GRADEBOOK_OPTIONS_TEXT}
                </YuJaButton>
              }
      </div>
    }
    </div>
    : 
    <Container className="max-height" style={{maxHeight: "100%", paddingTop: "36px"}}>
    <div style={{display: "flex", width: "100%", height: "45px"}}> 
    <div style={{fontWeight: "400", fontFamily: "Geomanist", display: "flex", justifyContent: "center", fontSize: "14.688px", backgroundColor: "#F7EFF6", width: "50%", alignItems: "center", borderTopLeftRadius: "10.2px", borderBottom: currentView === 0 ? "2px solid #6750A4": "", cursor: "pointer"}} role={TEXTBOX} tabIndex={0} onClick={()=> {setCurrentView(0)}}> Dashboard </div>
    <div style={{fontWeight: "700", fontFamily: "Geomanist",  display: "flex", justifyContent: "center", fontSize: "14.688px", backgroundColor: "#F7EFF6", width: "50%" , alignItems: "center", borderTopRightRadius: "10.2px",  borderBottom: currentView === 1 ? "2px solid #6750A4": "", cursor: "pointer"}}  role={TEXTBOX} tabIndex={0} onClick={()=> {setCurrentView(1)}}> Question Details </div>
    </div>
    <div style={{paddingBottom: "100px", position: "relative"}}>
    <CreateQuestionDetailSidebar 
        title={"Question Details"}
        noContentText={"Question Details"}
        questions={questions}
        setQuestions={setQuestions}
        currQueDisplay={currQueDisplay}
        pollType={pollType}
        showAnswerOnViewer={showAnswerOnViewer}
        setShowAnswerOnViewer={setShowAnswerOnViewer}
        anonymousJoin={anonymousJoin}
        setAnonymousJoin={setAnonymousJoin}
        recordAttempt={recordAttempt}
        setRecordAttempt={setRecordAttempt}
        lmsAttempt={lmsAttempt}
        setLMSAttempt={setLMSAttempt}
        maxAttempts={maxAttempts}
        setMaxAttempts={setMaxAttempts} 
        isBank={isBank}/>
    </div>
        {!!pollType && !isBank &&
                <div style={{ position: "absolute", bottom: "40px", height: "60px", paddingRight: "20px"}}> 
                <Col>
                    {pollType.toUpperCase() !== POLL_TYPE.SURVEY && 
                    <Row className="justify-content-md-center" style={{marginBottom: "10px"}}>
                        <Col>
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%", gap:  "5px"}}>
                            <div style={{fontSize: "14px", fontFamily: "Geomanist"}}>{SHOW_CORRECT_ANSWER_CHECKBOX_LABEL}</div>
                            <YuJaToggle style={{width: "auto"}} ariaLabel={SHOW_CORRECT_ANSWER_CHECKBOX_LABEL} toggled={showAnswerOnViewer} onClick={() => setShowAnswerOnViewer(!showAnswerOnViewer)}/>
                            </div>
                        </Col>
                    </Row>
                    }

                    <Row className="justify-content-md-center">
                        <Col>
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%", gap:  "5px"}}>
                            <div style={{fontSize: "14px", fontFamily: "Geomanist"}}> {DISABLE_ANONYMOUS_SIDE_BAR}</div>
                            <YuJaToggle style={{width: "auto"}} ariaLabel={DISABLE_ANONYMOUS_SIDE_BAR}  toggled={anonymousJoin} onClick={() => {setAnonymousJoin(!anonymousJoin);}} />
                            </div>
                        </Col>
                    </Row>
                </Col>
                </div>
            }
    </Container>
    }

    </>
  );
}
