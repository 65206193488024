import "./styles.css";
import React from "react";
import { ResponsiveContainer, PieChart, Pie, Legend, Label } from 'recharts';
import {QUESTION_TYPES} from "../../utils/constants";

export default function TabParticipants({ chartData=[], questionType }) {

  const data = [{name: "Attempted", number: 0, fill: ""}, {name: "No Response", number: 0, fill: ""}];
  let total = 0;
  let ratio = "";
  let attemptedColor = "";
  let unattemptedColor = "";


  if (questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.SA.name) {
    for (const element of chartData) {
      if (element.ans === "Incorrect" || element.ans === "Correct" || element.ans === "Ungraded") {
        data[0].number += element.people;
      }
      else {
        data[1].number = element.people;
      }
      if(questionType === QUESTION_TYPES.FITB.name) {
        attemptedColor = "#ff7700";
        unattemptedColor = "#ffc796";
        data[1].fill = unattemptedColor;
        data[0].fill = attemptedColor;
      }
      else {
        attemptedColor = "#2b992b";
        unattemptedColor = "#95cc96";
        data[1].fill = unattemptedColor;
        data[0].fill = attemptedColor;
      }
      total += element.people;
    }
  } else if (questionType === QUESTION_TYPES.MCSS.name) {
    attemptedColor = "#0277ad";
    unattemptedColor = "#96c8df";
    data[1].fill = unattemptedColor;
    data[0].fill = attemptedColor;

    for (const element of chartData) {
      if (element.ans === "Unanswered") {
        data[1].number += element.people;
      } 
      total = element.total;
      data[0].number = total - data[1].number;
    }
  } else if (questionType === QUESTION_TYPES.TF.name) {
    attemptedColor = "#602bf8";
    unattemptedColor = "#cabdee";
    data[1].fill = unattemptedColor;
    data[0].fill = attemptedColor;

    for (const element of chartData) {
      if (element.ans === "Unanswered") {
        data[1].number += element.people;
      } else {
        data[0].number += element.people;
      }
      total += element.people;
    }
  } else if (questionType === QUESTION_TYPES.CI.name) {
    attemptedColor = "#0821a3";
    unattemptedColor = "#a4b1f3";
    data[1].fill = unattemptedColor;
    data[0].fill = attemptedColor;

    for (const element of chartData) {
      if (element.ans === "Unanswered") {
        data[1].number += element.people;
      } else {
        data[0].number += element.people;
      }
      total += element.people;
    }
  } else if (questionType === QUESTION_TYPES.WC.name) {
    attemptedColor = "#BF045B";
    unattemptedColor = "#F5AFD0";
    data[0].fill = attemptedColor;
    data[1].fill = unattemptedColor;

    // console.log(data, chartData);
    for (const element of chartData) {
      // console.log(element);
      if (element.ans === "Unanswered") {
        data[1].number += element.people;
      } else {
        data[0].number += element.people;
      }
      total += element.people;
    }
    // console.log(data);
  }
  else if (questionType === QUESTION_TYPES.MH.name) {
    attemptedColor = "#873100";
    unattemptedColor = "#D9B6A3";
    data[0].fill = attemptedColor;
    data[1].fill = unattemptedColor;

    // console.log(data, chartData);
    for (const element of chartData) {
      // console.log(element);
      if (element.ans === "Unanswered") {
        data[1].number += element.people;
      } else {
        data[0].number += element.people;
      }
      total += element.people;
    }
    // console.log(data);
  }

  ratio = data[0].number + "/" + total;

  if(data[0].number === 0 && data[1].number === 0) {
    let tempObj = {name: "No Value", number: 1, fill: "#D3D3D3"};
    data.push(tempObj);
  }

  return (<>
    <div style={{display: "flex"}}>
      <div style ={{flex: 3.5}}>
        <p className="participants-title" style={{marginLeft: "58px"}}>Participants:</p>
      </div>
      <div style={{flex: 2}}>
        <ResponsiveContainer width={200} height={215}>
          <PieChart>
            <Pie
                data={data}
                innerRadius={69}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="number"
            >
            <Label className="ratio-text" position="center">{ratio}</Label>
            </Pie>
          </PieChart>
        </ ResponsiveContainer>
      </div>
      <div className="legend-text" style ={{flex: 4.5, marginTop: "60px"}}>
        <p> 
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: "10px"}}>
            <path d="M2 17.7494V2.00098H19.2483L14.7487 7.62542L19.2483 13.2499H2" stroke={attemptedColor} strokeWidth="2.93721" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
           Attempted: {data[0].number}</p>
        <p> 
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: "10px"}}>
            <path d="M2 17.7494V2.00098H19.2483L14.7487 7.62542L19.2483 13.2499H2" stroke={unattemptedColor} strokeWidth="2.93721" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
           Unattempted: {total-data[0].number}</p>
      </div>
    </div>
  </>);
}
