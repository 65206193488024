import React, {useState, useEffect, useRef} from "react";
import {Rnd} from 'react-rnd';
import {notifyError} from "../../utils/helpers";
import {decodeCIParam, encodeCIParam} from "../../utils/questionUtils";
import {ASCII_CODE_A, OPT_LABEL, TEXTBOX} from "../../utils/constants";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AREA_OVERLAP_ERROR } from "../../utils/toast-message-constants";
export default function ClickableImage({questions=[], setQuestions, selectedQuestion}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [imageWidth, setImageWidth] = useState(1);
    const [imageHeight, setImageHeight] = useState(1);
    const imageRef = useRef();


    const clickImage = (e) => {
        const EDGE = imageWidth / 10;

        const xCoordinate = e.nativeEvent.offsetX;
        const yCoordinate = e.nativeEvent.offsetY;
        const newOption =  {
            text: "",
            x: encodeCIParam(xCoordinate - EDGE / 2, imageWidth),
            y: encodeCIParam(yCoordinate - EDGE / 2, imageHeight),
            width: encodeCIParam(EDGE, imageWidth),
            height: encodeCIParam(EDGE, imageHeight)
        };


        if (xCoordinate + EDGE / 2 > imageWidth
            || xCoordinate - EDGE / 2 < 0
            || yCoordinate + EDGE / 2 > imageHeight
            || yCoordinate - EDGE / 2 < 0) {
            // notifyError("The pointed space is not enough to add a new Area");
            setAdjustedOption(EDGE, newOption);
            return;
        }

        const questionsCopy = JSON.parse(JSON.stringify(questions));
        const {serialNo} = selectedQuestion;
        const curQuestion = questionsCopy.find(q => q.serialNo === serialNo);

        // for(let i = 0; i < curQuestion.optionsMap.length; i++) {
        //     for(let j = curQuestion.optionsMap.length - 1; j > i; j--) {
        //         if (hasIntersection(curQuestion.optionsMap[i], curQuestion.optionsMap[j])) {
        //             notifyError("Two different areas can not be overlapped");
        //             return;
        //         }
        //     }
        // }

        const hasIntersection = hasIntersectionWithCurOptions(newOption, curQuestion.optionsMap);
        if (!hasIntersection) {
            curQuestion.optionsMap.push(newOption);
            setQuestions(questionsCopy);
            return;
        }
        setAdjustedOption(EDGE, newOption);
    }


    const setAdjustedOption = (edge, newOption) => {
        const questionsCopy = JSON.parse(JSON.stringify(questions));
        const {serialNo} = selectedQuestion;
        const curQuestion = questionsCopy.find(q => q.serialNo === serialNo);
        const options = curQuestion.optionsMap;

        let {x, y, width, height} = newOption;
        x = decodeCIParam(x, imageWidth);
        y = decodeCIParam(y, imageHeight);


        let adjustedX = 0;
        let adjustedY = 0;
        const availableArr = [];
        for (let offsetX = -1 * edge / 2; offsetX <= edge / 2; offsetX++) {
            for (let offsetY = -1 * edge / 2; offsetY <= edge / 2; offsetY++) {
                adjustedX = x + offsetX;
                adjustedY = y + offsetY;
                if (adjustedX + edge > imageWidth
                    || adjustedX < 0
                    || adjustedY + edge > imageHeight
                    || adjustedY < 0) {
                    continue;
                }

                const adjustedOpt = {
                    x: encodeCIParam(adjustedX, imageWidth),
                    y: encodeCIParam(adjustedY, imageHeight),
                    width,
                    height,
                    distance: (x - adjustedX) ** 2 + (y - adjustedY) ** 2
                };
                if (!hasIntersectionWithCurOptions(adjustedOpt, options)) {
                    availableArr.push(adjustedOpt);
                }
            }
        }

        if (!availableArr.length) {
            return;
        }

        const adjustedOpt = availableArr.sort((a, b) => a.distance - b.distance)[0];
        delete adjustedOpt.distance;

        options.push(adjustedOpt);
        setQuestions(questionsCopy);
    };



    const hasIntersectionWithCurOptions = (newOption, options) => {
        for(let i = 0; i < options.length; i++) {
            if (hasIntersection(newOption, options[i])) {
                return true;
            }
        }

        return false;
    }

    const onResize = (...[, , target, delta, position]) => {
        const eleId = target.id

        const questionsCopy = JSON.parse(JSON.stringify(questions));
        const {serialNo} = selectedQuestion;
        const curQuestion = questionsCopy.find(q => q.serialNo === serialNo);
        const area = curQuestion.optionsMap[eleId.split("-")[2]];

        area.width = encodeCIParam(area.width * imageWidth + delta.width, imageWidth);
        area.height = encodeCIParam(area.height * imageHeight + delta.height, imageHeight);
        area.x = encodeCIParam(position.x, imageWidth);
        area.y = encodeCIParam(position.y, imageHeight);

        for(let i = 0; i < curQuestion.optionsMap.length; i++) {
            for(let j = curQuestion.optionsMap.length - 1; j > i; j--) {
                if (hasIntersection(curQuestion.optionsMap[i], curQuestion.optionsMap[j])) {
                    notifyError(AREA_OVERLAP_ERROR);
                    return;
                }
            }
        }
        setQuestions(questionsCopy);
    }

    const onDrag = (e, data) => {
        const eleId = data.node.id

        const questionsCopy = JSON.parse(JSON.stringify(questions));
        const {serialNo} = selectedQuestion;
        const curQuestion = questionsCopy.find(q => q.serialNo === serialNo);
        const area = curQuestion.optionsMap[eleId.split("-")[2]];

        area.x = encodeCIParam(data.x, imageWidth);
        area.y = encodeCIParam(data.y, imageHeight);

        for(let i = 0; i < curQuestion.optionsMap.length; i++) {
            for(let j = curQuestion.optionsMap.length - 1; j > i; j--) {
                if (hasIntersection(curQuestion.optionsMap[i], curQuestion.optionsMap[j])) {
                    // notifyError("Two different areas can not be overlapped");
                    return;
                }
            }
        }
        setQuestions(questionsCopy);
    }

    const onLoad = (e) => {
        setIsLoaded(true);
        setImageWidth(e.target.width);
        setImageHeight(e.target.height);
    }


    const hasIntersection = (area1, area2) => {
        const xStart1 = area1.x,
            xEnd1 = area1.x + area1.width,
            yStart1 = area1.y,
            yEnd1 = area1.y + area1.height,
            xStart2 = area2.x,
            xEnd2 = area2.x + area2.width,
            yStart2 = area2.y,
            yEnd2 = area2.y + area2.height;
        const isOutSide = (xEnd1 < xStart2) // area1 is on the left side of area2
            || (xStart1 > xEnd2)  // area1 is on the right side of area2
            || (yEnd1 < yStart2) // area1 is on the top side of area2
            || (yStart1 > yEnd2);// area1 is on the bottom side of area2


        return !isOutSide;
    }

    const onMouseEnter = (idx) => {
        document.getElementById(`delete-option-icon-${idx}`).style.display = "block";
        for (let i = 0; i < selectedQuestion.optionsMap.length; i++) {
            if (i === idx) {
                document.getElementById(`option-area-${i}`).style.zIndex = "1";
            } else {
                document.getElementById(`option-area-${i}`).style.zIndex = "0";
            }
        }
    }


    const onMouseLeave = (idx) => {
        document.getElementById(`delete-option-icon-${idx}`).style.display = "none";
        for (let i = 0; i < selectedQuestion.optionsMap.length; i++) {
            document.getElementById(`option-area-${i}`).style.zIndex = "0";
        }
    }

    const onDelete = (idx) => {
        const questionsCopy = JSON.parse(JSON.stringify(questions));
        const {serialNo} = selectedQuestion;
        const curQuestion = questionsCopy.find(q => q.serialNo === serialNo);
        const optionsMap = curQuestion.optionsMap;
        if (idx >= optionsMap.length) {
            return;
        }
        optionsMap.splice(idx, 1);
        const correctAnswers = curQuestion.correctAnswers;
        if (!!correctAnswers && !!correctAnswers.length && correctAnswers.includes(idx)) {
            const index = correctAnswers.indexOf(idx);
            correctAnswers.splice(index, 1);
        }
        setQuestions(questionsCopy);
    }

      
    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
          if (imageRef.current) {
            setImageWidth(imageRef.current.clientWidth)
            setImageHeight(imageRef.current.clientHeight)
          }
        });
    
        if (imageRef.current) {
          resizeObserver.observe(imageRef.current);
    
          return () => {
            resizeObserver.disconnect();
          };
        }
      }, []);



    return(
        <>
            { !!selectedQuestion && !!selectedQuestion.imgUrl &&
                <div className= "clickable-image-container">
                    <div style={{width: "100%", height: "100%"}}>
                    <img
                        tabIndex={0}
                        aria-label={"Uploaded Clickable Image"}
                        role={TEXTBOX}
                        ref={imageRef}
                        onClick={clickImage}
                        src={`${selectedQuestion.imgUrl}`}
                        alt={`Q${selectedQuestion.suffix} preview`}
                        onLoad={onLoad}
                        style={{maxWidth: "100%", maxHeight: "100%"}}
                    />
                    </div>
                    {isLoaded && !!selectedQuestion.imgUrl &&
                        selectedQuestion.optionsMap.map((area, idx) =>
                            <Rnd
                                tabIndex={0}
                                role={TEXTBOX}
                                aria-label={OPT_LABEL + String.fromCharCode(idx + ASCII_CODE_A)}
                                key={idx}
                                id={`option-area-${idx}`}
                                size={{
                                    width: decodeCIParam(area.width, imageWidth),
                                    height: decodeCIParam(area.height, imageHeight)
                                }}
                                position={{
                                    x: decodeCIParam(area.x, imageWidth),
                                    y: decodeCIParam(area.y, imageHeight)
                                }}
                                enableResizing={{
                                    bottom: true,
                                    bottomLeft: true,
                                    bottomRight: true,
                                    left: true,
                                    right: true,
                                    top: true,
                                    topLeft: true,
                                    topRight: false,
                                }}
                                bounds={"parent"}
                                onResizeStop={onResize}
                                onDragStop={onDrag}
                                onMouseEnter={() => onMouseEnter(idx)}
                                onMouseLeave={() => onMouseLeave(idx)}
                            >
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: "solid red 1.5px",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    backgroundColor: "hsla(0,0%,73.3%,.5)",
                                }}>
                                    {String.fromCharCode(ASCII_CODE_A + idx)}
                                    <IoCloseCircleOutline
                                        id={`delete-option-icon-${idx}`}
                                        onClick={() => onDelete(idx)}
                                        style={{
                                            display: "none",
                                            position: "absolute",
                                            top: "-3px",
                                            right: "-20px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            </Rnd>
                        )
                    }
                </div>
            }
        </>
    );
}