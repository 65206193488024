import React, {useEffect, useState} from "react";
import {ASCII_CODE_A, FIXED_ANSWER, POLL_TYPE, QUESTION_TYPES} from "../../utils/constants";
import "../JoinQuizPage/styles.css";
import styles from "../JoinQuizPage/styles.module.css";
import {checkUnanswered} from "../../utils/helpers";

export default function QuestionBarChart({ loading, correctAnswers, chartData, questionType, pollType, queResponse, localState}) {
    const [data, setData] = useState([]);
    const [updatedValue, setUpdatedValue] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState([]);
    const [, setTotalData] = useState(0);
    const [, setCorrectAnswerIndex] = useState(-1);

    useEffect(() => {
        const interval = setInterval(() => {
            setUpdatedValue(data);
        }, 1000);

        return () => clearInterval(interval);
    }, [data]);

    useEffect(() => {
        let tempArrData = JSON.parse(JSON.stringify(data));
        tempArrData.forEach((entry) => {
            if(entry.value != null) {
                entry.value = 0;
            }
        })
        setUpdatedValue(tempArrData);
    }, [correctAnswers]);

    useEffect(() => {
        let optionArrData = [];
        const selectedIdxCopy = []
        let tempTotalData = 0;

        chartData.forEach((result, index) => {
            if (questionType === QUESTION_TYPES.MCSS.name) {
                optionArrData.push({ index: result.ans.split(". ")[0], value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
            } else if (questionType === QUESTION_TYPES.CI.name) {
                if (result.ans === FIXED_ANSWER.OTHER) {
                    optionArrData.push({ index: "Other", value: result.people});
                } else {
                    optionArrData.push({ index: result.ans.split(". ")[0].toUpperCase(), value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
                }
            } else if (questionType === QUESTION_TYPES.TF.name) {
                optionArrData.push({ index: result.ans, value: result.people });
            }

            if (!!correctAnswers && !!Object.entries(correctAnswers).length) {
                if ((questionType === QUESTION_TYPES.MCSS.name && correctAnswers.includes(optionArrData[index]?.index?.toLowerCase()))
                    || (questionType === QUESTION_TYPES.CI.name && correctAnswers.includes(result.name))
                    || (questionType === QUESTION_TYPES.TF.name && result.name.toString().includes(correctAnswers))) {
                    setCorrectAnswerIndex(index);
                }
            }

            tempTotalData += result.people;

            if (!checkUnanswered(pollType, questionType, queResponse?.selectedOption)) {
                if (questionType === QUESTION_TYPES.MCSS.name && pollType === POLL_TYPE.SURVEY && queResponse.selectedOption.includes(optionArrData[index]?.index?.toLowerCase())) {
                    selectedIdxCopy.push(index);
                }else if (questionType === QUESTION_TYPES.MCSS.name && pollType !== POLL_TYPE.SURVEY && queResponse.selectedOption === optionArrData[index]?.index?.toLowerCase()) {
                    selectedIdxCopy.push(index);
                }else if ( questionType === QUESTION_TYPES.TF.name && queResponse.selectedOption?.toLowerCase() === optionArrData[index]?.index?.toLowerCase()) {
                    selectedIdxCopy.push(index);
                } else if (questionType === QUESTION_TYPES.CI.name) {
                    const selectedOptIdx = isNaN(queResponse.selectedOption.selected) ? FIXED_ANSWER.OTHER : String.fromCharCode(ASCII_CODE_A + parseInt(queResponse.selectedOption.selected));
                    if (optionArrData[index]?.index?.toUpperCase() === selectedOptIdx.toUpperCase()) {
                        selectedIdxCopy.push(index);
                    }
                }
            }

        })

        if(localState === "SHARED_ALL" && pollType !== POLL_TYPE.SURVEY) {
            optionArrData.forEach((entry) => {
                if(entry.value != null) {
                    entry.value = 0;
                }
            })
        }

        setData(optionArrData);
        setTotalData(tempTotalData);
        setSelectedIdx(selectedIdxCopy);
        setTimeout(() => {
            optionArrData.forEach((entry, index) => {
                if(document.getElementById(`${queResponse?.serialNo ? queResponse.serialNo : ''}option${index}`) != null) {
                    document.getElementById(`${queResponse?.serialNo ? queResponse.serialNo : ''}option${index}`).style.width = `${!!tempTotalData ? (entry.value / tempTotalData) * 100 : 0}%`;
                }
            });
        }, 400);
    }, [chartData]);

    return (
        <div className={styles.chartContainerSharePoll} >
            {!loading && !!data && data.map((entry, index) => (
                <div className={styles.optionContainer} key={index}>
                    <div className={styles.option} id={"Option" + index}>
                        <div id={"OptionPart1" + index} className={(questionType === QUESTION_TYPES.TF.name || entry.index === "Other") ? "questionPanelAnswersTextMC" : "questionPanelOptionsTextMC"}>
                            {entry.index}
                        </div>
                        
                        {!!entry.answer &&
                            <div id={"OptionPart2" + index} className="questionPanelAnswersTextMC">
                                {entry.answer}
                            </div>
                        }

                        <div id={`${queResponse?.serialNo ? queResponse.serialNo : ''}option${index}`}
                            className={`${styles.optionCountBarMC} ${selectedIdx.includes(index) ? styles.correct : ''}`}
                        />
                        
                        <div className="questionPanelData">{updatedValue.length > index ? updatedValue[index].value : 0}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}
