import React, { useCallback, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import CreateCourseModal from "../../components/modals/CreateCourseModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import { COURSES, COURSE_CODE, COURSE_NAME, CREATE_COURSE, CREATOR_MENU_LIST, DEFAULT_ROWS_PER_PAGE, MODAL_EDIT_TEXT, TEXTBOX, VIEW_COURSE } from "../../utils/constants";
import CourseSearchForm from "./CourseSearchForm";
import CourseTable from "./CourseTable";
import styles from "./ManageCourses.module.css";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";

export default function ManageCoursesPage(props) {
  const [filter, setFilter] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [courseData, setCourseData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { getCourseByUser } = CourseApiCalls();
  const [, getSession] = useLocalStorageNew("session", {});
  const history = useHistory();
  
  const getCourses = async (pageIndex=0) => {
    setDataReady(false);
    const session = getSession();
    let res;
    if(session.idpType && session.intType) {
        res = await getCourseByUser(session.idpType.toLowerCase(), session.intType.toLowerCase());
    } else {
        res = await getCourseByUser();
    }

    res.sort(function (a, b) {
      return new Date(Date.parse(b.updateTime)) - new Date(Date.parse(a.updateTime));
    })
    setCourseData(res);
    setPageIndex(pageIndex);
    setDataReady(true);
  };

  useEffect(() => {
    getCourses();
    if(document.getElementById("engagePageIdentifier") != null) {
        document.getElementById("engagePageIdentifier").innerText = CREATOR_MENU_LIST[5].name;
    }
  }, []);

  useEffect(() => {
    if (props.show === "nav") {
      history.push("/course/user")
      setModalShow(true)
    }
  }, [props.show]);

  const handleSubmit = useCallback(({ ...filterObj }) => {
    setFilter(filterObj);
    setPageIndex(0);
  }, []);

  const openCourseModal = () => {
    setModalShow(true);  
  };

  const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: 'Course Name', accessor: 'courseName', width: isMobileScreen ? 100 : 120 },
    { Header: 'Course Code', accessor: 'courseCode', width: isMobileScreen ? 100 : 120 },
    // { Header: 'Instructor\'s Name', accessor: 'instructorsName', width: 120 },
    // { Header: 'Start Date', accessor: 'startDate', width: isMobileScreen ? 120 : 140 },
    { Header: 'Edit', accessor: 'courseId', width: 100, id: 'edit' },
    { Header: 'View Course', accessor: 'courseId', width: 100 }
  ];

  const clickCreate = () => {
    history.push({ pathname: "/course/user/create" });
  };

  return (
    <>
      {!dataReady && 
        <div className={styles.container}>
          <div className={styles.titleBox}>
            <div className={styles.titleBoxIcon} />
            <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>{COURSES}</div>
          </div>
          <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <CourseSearchForm handleSubmit={handleSubmit} openCourseModal={openCourseModal}/>
            <YuJaButton onClick={openCourseModal} style={{marginLeft: 15}}><PlusIcon style={{width: 20}}/>{CREATE_COURSE}</YuJaButton>
          </div>
          <div className={styles.skeletonTableContainer}>
              <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                <div className={styles.skeletonHeaderContainer}>
                    <div className={styles.skeletonHeaderCell}>{COURSE_NAME}</div>
                    <div className={styles.skeletonHeaderCell}>{COURSE_CODE}</div>
                    <div className={styles.skeletonHeaderCell}>{MODAL_EDIT_TEXT}</div>
                    <div className={styles.skeletonHeaderCell}>{VIEW_COURSE}</div>
                </div>
                <div className={styles.skeletonBodyContainer}>
                {Array.apply(null, Array(DEFAULT_ROWS_PER_PAGE)).map((_, idx) => {
                    return (<>
                        <Skeleton height={20} width={`${4 + (Math.random()*7)}vw`}className={styles.skeletonBodyCell} />
                        <Skeleton height={20} width={`${4 + (Math.random())}vw`} className={styles.skeletonBodyCell} />
                        <Skeleton height={20} width={`${3 + (Math.random())*3}vw`} className={styles.skeletonBodyCell} />
                        <Skeleton height={20} width={`${2 + Math.random()}vw`} className={styles.skeletonBodyCell} />
                    </>)
                })}
                </div>
              </SkeletonTheme>
          </div>
        </div>
      }
      {courseData && courseData.length !== 0 && dataReady && (
        <>
          <div className={styles.container}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxIcon} />
              <div tabIndex={0} className={styles.titleBoxTitle}>{COURSES}</div>
            </div>
            <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <CourseSearchForm handleSubmit={handleSubmit} openCourseModal={openCourseModal}/>
              <YuJaButton onClick={openCourseModal} style={{marginLeft: 15}}><PlusIcon style={{width: 20}}/>{CREATE_COURSE}</YuJaButton>
            </div>
            <CourseTable columns={columns} data={courseData} pageIdx={pageIndex} globalFilterObj={filter} getCourses={getCourses} />
          </div>
        </>
      ) }
      {(!courseData || courseData.length === 0) && (
        <>
          <div className={styles.c2}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxTitle}>You have not created any courses.</div>
            </div>
            <div className={styles.text}>Press the button below to create a course.</div>
            <div className={styles.center}>
              <YuJaButton onClick={() => clickCreate()} style={{fontSize: 20}}>Create Course</YuJaButton>
            </div>

          </div>

          
        </>
      )}
      <CreateCourseModal
            show={modalShow}
            setModalShow={setModalShow}
            getCourses={getCourses}
          />
    </>
  );
}