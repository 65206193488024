import React, { useEffect, useState } from "react";
import { Form, Row} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { AuthenticationApiCalls } from "../../apis/AuthenticationApiCalls";
import useHostname from "../../hooks/useHostname";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as YuJaLogo } from "../../images/YuJa_Logo_Login.svg";
import {ANY, PLATFORM_SETTINGS, LOGIN_METHODS, JOIN_PARTICIPANT_PATH} from "../../utils/constants";
import {fetchImg, notifyError, checkAllEmpty, newPageLog } from "../../utils/helpers";
import { S3_PUBLIC_BUCKET_API_URL } from "../../utils/properties";
import {ReactComponent as Down} from "../../images/links/down.svg";
import {ReactComponent as Up} from "../../images/links/up.svg";
import EyeClosed from "./images/EyeClosed.svg";
import EyeOpen from "./images/EyeOpen.svg";
import defaultImage from "./images/main.jpg";
import styles from "./Login.module.css";
import {BsCheck2} from "react-icons/bs";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import YuJaButton from "../../components/standardization/YuJaButton";

export default function LoginPage() {
  const { login, getSAMLRedirectURL } = AuthenticationApiCalls();
  const hostResource = useHostname(window.location.hostname);
  const [,getSession] = useLocalStorageNew("session", {});
  const history = useHistory();
  const location = useLocation();
  const [keepSignIn, setKeepSignIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [setGuestSession, ] = useLocalStorageNew("guestSession", {});

  const [loginMethodOptions, setLoginMethodOptions] = useState(() => {
    const opts = Object.entries(LOGIN_METHODS).map((entry, index) => {
      return {defaultName: entry[1], abbr: "", key: entry[0], no: index + 1, enabled: entry[1] === LOGIN_METHODS.YUJA, selected: entry[1] === LOGIN_METHODS.YUJA,}
    });
    return opts;
  });

  // function enables accessibility shortcuts by pressing enter
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        if(!loginMethodOptions[0].selected) {
          document.getElementById("loginViaSAML").click();
        } else {
          document.getElementById("loginBtn").click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const isNarrow = useMobileAccess(950, ANY);
  // const isTablet = useMobileAccess(1024);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionSelect = (idx) => {
    let optsCopy = JSON.parse(JSON.stringify(loginMethodOptions));
    optsCopy = optsCopy.map(item => ({...item, selected: false}));
    optsCopy[idx].selected = true;
    setLoginMethodOptions(optsCopy);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (location.state && location.state.msg) {
      notifyError(location.state.msg);
      const newState = {...location.state};
      delete newState.msg
      window.history.replaceState(newState, document.title)
    }
  }, [location.state]);

  useEffect(() => {
    if (getSession().authorization) {
      history.push({
        pathname: "/home"
      });
      return;
    }

    async function fetchData() {
      const { bgImgLocation = "" } = hostResource;
      let img;
      if (bgImgLocation) {
        img = await fetchImg(`${S3_PUBLIC_BUCKET_API_URL}/s3?key=${bgImgLocation}`);
      }
      setImgUrl(img ? img : defaultImage);
    }
    fetchData();

    //check institution sso config and enable SSO login methods
    const {platformSetting, ssoConfig={}} = hostResource;

    let optsCopy = JSON.parse(JSON.stringify(loginMethodOptions));
    optsCopy.find(item => item.defaultName === LOGIN_METHODS.ADFS).enabled = ssoConfig.ADFS && !checkAllEmpty(ssoConfig.ADFS);
    optsCopy.find(item => item.defaultName === LOGIN_METHODS.SHIBBOLETH).enabled = ssoConfig.SHIBBOLETH && !checkAllEmpty(ssoConfig.SHIBBOLETH);
    optsCopy.find(item => item.defaultName === LOGIN_METHODS.DCAM).enabled = ssoConfig.DCAM && !checkAllEmpty(ssoConfig.DCAM);
    optsCopy.find(item => item.defaultName === LOGIN_METHODS.GOOGLE).enabled = ssoConfig.GOOGLE && !checkAllEmpty(ssoConfig.GOOGLE);
    optsCopy.find(item => item.defaultName === LOGIN_METHODS.AZURE).enabled = ssoConfig.AZURE && !checkAllEmpty(ssoConfig.AZURE);

    if (!platformSetting) {
      return
    }
    //set default login method
    optsCopy = optsCopy.map(item => ({...item, selected: false}));
    if (!!platformSetting[PLATFORM_SETTINGS.DEFAULT]) {
      const no = parseInt(platformSetting[PLATFORM_SETTINGS.DEFAULT])
      optsCopy.find((item => item.no === no)).selected = true
    }

    //check if needs to disable YuJa login
    if (!!platformSetting[PLATFORM_SETTINGS.YUJA_LOGIN]) {
      optsCopy.find(item => item.defaultName === LOGIN_METHODS.YUJA).enabled = platformSetting[PLATFORM_SETTINGS.YUJA_LOGIN] !== "true";
    }
    if (!optsCopy.find(item => item.defaultName === LOGIN_METHODS.YUJA).enabled) {
      optsCopy.find(item => item.defaultName === LOGIN_METHODS.YUJA).selected = false;
    }


    //check if needs to update the login method name
    if (!!platformSetting[PLATFORM_SETTINGS.YUJA_NAME] && platformSetting[PLATFORM_SETTINGS.YUJA_NAME].trim() !== "") {
      optsCopy.find(item => item.defaultName === LOGIN_METHODS.YUJA).abbr = platformSetting[PLATFORM_SETTINGS.YUJA_NAME];
    }
    if (!!platformSetting[PLATFORM_SETTINGS.SSONAME_ADFS] && platformSetting[PLATFORM_SETTINGS.SSONAME_ADFS].trim() !== "") {
      optsCopy.find(item => item.defaultName === LOGIN_METHODS.ADFS).abbr = platformSetting[PLATFORM_SETTINGS.SSONAME_ADFS];
    }
    if (!!platformSetting[PLATFORM_SETTINGS.SSONAME_SHI] && platformSetting[PLATFORM_SETTINGS.SSONAME_SHI].trim() !== "") {
      optsCopy.find(item => item.defaultName === LOGIN_METHODS.SHIBBOLETH).abbr = platformSetting[PLATFORM_SETTINGS.SSONAME_SHI];
    }
    if (!!platformSetting[PLATFORM_SETTINGS.SSONAME_DCAM] && platformSetting[PLATFORM_SETTINGS.SSONAME_DCAM].trim() !== "") {
      optsCopy.find(item => item.defaultName === LOGIN_METHODS.DCAM).abbr = platformSetting[PLATFORM_SETTINGS.SSONAME_DCAM];
    }
    if (!!platformSetting[PLATFORM_SETTINGS.SSONAME_GOOGLE] && platformSetting[PLATFORM_SETTINGS.SSONAME_GOOGLE].trim() !== "") {
      optsCopy.find(item => item.defaultName === LOGIN_METHODS.GOOGLE).abbr = platformSetting[PLATFORM_SETTINGS.SSONAME_GOOGLE];
    }
    if (!!platformSetting[PLATFORM_SETTINGS.SSONAME_AZURE] && platformSetting[PLATFORM_SETTINGS.SSONAME_AZURE].trim() !== "") {
      optsCopy.find(item => item.defaultName === LOGIN_METHODS.AZURE).abbr = platformSetting[PLATFORM_SETTINGS.SSONAME_AZURE];
    }

    if (!optsCopy.find(item => item.selected)) {
      optsCopy.find(item => item.enabled).selected = true;
    }
    setLoginMethodOptions(optsCopy);
  }, [hostResource]);


  useEffect(() => {
    newPageLog("LoginPage.js");
    function handleResize() { calcWidth(); }
    window.addEventListener('resize', handleResize);
  }, [])

  const validateForm = () => {
    let returnVal = true;
    if (!userName) {
      returnVal = false;
      setUserNameError("please fill a username");
    } else {
      setUserNameError("");
    }

    if (!password) {
      returnVal = false;
      setPasswordError("please fill a password");
    } else {
      setPasswordError("");
    }
    return returnVal;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlertMsg("");
    if (!validateForm()) {
      return;
    }
    setGuestSession({});
    await login({ userName, password, keepSignIn }, setAlertMsg);
  }

  const loginViaSAML = async(e) => {
    e.preventDefault();
    const selectedOpt = loginMethodOptions.find(opt => opt.selected);
    if (!selectedOpt) {
      return;
    }
    await getSAMLRedirectURL(selectedOpt.key).then((redirectURL) => {
      if (redirectURL !== false) {
        window.open(redirectURL, "_self");
      }
    });
  }

  const editUsername = (e) => {
    setUserName(e.target.value);
    setUserNameError("");
    setAlertMsg("");
  }
  const editPassword = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
    setAlertMsg("");
  }

  const goReset = () => {
    history.push({ pathname: "/forgot" });
  }

  const togglePw = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await setPasswordShown(!passwordShown);
    const input = document.getElementById('input-pw');
    input.focus();
    input.setSelectionRange(input.value.length, input.value.length);
  }

  const calcWidth = () => {
    let ele = document.getElementById("username-input-label-holder")
    let un = document.getElementById("username-input-box-holder");
    let pw = document.getElementById("paswword-input-box-holder");

    if (!!ele && !!un && !!pw) {
      if (ele.offsetWidth > 90) {
        un.style.width = "80%";
        pw.style.width = "80%";
        return "80%";
      } else if (ele.offsetWidth <= 90) {
        un.style.width = "100%";
        pw.style.width = "100%";
        return "100%";
      }
    }
  }

  return (
      <>
        <Row style={{height: "100%", width: "100%", margin: 0}}>
            <div className={styles.leftWrapper} style={{width: isNarrow ? '100%' : '40%'}}>
              <div className={styles.formWrapper}>
              <div className={styles.title}><YuJaLogo style={{marginRight: "15px"}}/>YuJa Engage</div>
                <div className={styles.subtitle}>Please sign in to your account.</div>
                <Form style={{marginTop: "30px"}} onSubmit={() => {}}>
                  {(!!alertMsg || !!userNameError || !!passwordError) &&
                      <p className={styles.alert}>Incorrect username or password. Please try again. </p>
                  }
                      <Form.Group className="mb-3">
                          <div id="username-input-label-holder" className={styles.inputTitleBoxLogin}>
                            <Form.Label id="username-input-label" className={styles.inputTitle}> Login Using </Form.Label>
                          </div>
                        <YuJaDropdown
                            style={{fontSize: 18, padding: 12}}
                            data={loginMethodOptions.filter(item => item.enabled)}
                            getOptionValue={(item) => item.key}
                            getOptionLabel={(item) => item.abbr ? item.abbr : item.defaultName}
                            value={loginMethodOptions.find((item => item.selected))?.key}
                            onChange={(item) => {
                              let optsCopy = JSON.parse(JSON.stringify(loginMethodOptions));
                              optsCopy = optsCopy.map(item => ({...item, selected: false}));
                              optsCopy.find(i => i.key === item.key).selected = true
                              setLoginMethodOptions(optsCopy);
                            }}
                        />
                      </Form.Group>
                  {loginMethodOptions[0].selected &&
                      <>
                        <Form.Group className="mb-3">
                          <div id="username-input-label-holder" className={styles.inputTitleBoxLogin}>
                            <Form.Label id="username-input-label" className={styles.inputTitle}>Name</Form.Label>
                          </div>
                            <YuJaTextBox
                                containerStyle={{fontSize: 18, padding: 12}}
                                id="username-input-box"
                                placeholder="Enter your username"
                                value={userName}
                                onChange={editUsername}
                                isInvalid={!!userNameError || !!alertMsg}
                                autoComplete="on"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <div className={styles.inputTitleBoxLogin}>
                            <Form.Label className={styles.inputTitle}>Password</Form.Label>
                          </div>
                            <YuJaTextBox
                                containerStyle={{fontSize: 18, padding: 12}}
                                id="input-pw"
                                type={passwordShown ? "text" : "password"}
                                placeholder="Enter your password"
                                value={password}
                                onChange={editPassword}
                                isInvalid={!!passwordError || !!alertMsg}
                                autoComplete="on"
                                after={
                                  <>
                                    {!!password && (
                                        <>
                                          <img
                                              id="input-pw-eye"
                                              onClick={togglePw}
                                              className={styles.eye}
                                              src={passwordShown ? EyeOpen : EyeClosed}
                                              alt="eye"
                                              style={{cursor: "pointer"}}
                                          />
                                        </>
                                    )}
                                  </>
                                }
                            />

                        </Form.Group>
                        <Form.Group className="mb-3">
                          <YuJaCheckbox
                              label="Remember Me"
                              onClick={e => setKeepSignIn(e.target.checked)}
                              checked={keepSignIn}
                          />
                        </Form.Group>
                        <YuJaButton
                            onClick={handleSubmit}
                            style={{width: "100%", fontSize: "1.2rem", padding: "15px 0"}}
                            id="loginBtn"
                        >
                          LOGIN
                        </YuJaButton>

                        <div className={styles.forgotPasswordText} onClick={() => goReset()}>Forgot Username or
                          Password?
                        </div>
                      </>
                  }

                  {!loginMethodOptions[0].selected &&
                      <YuJaButton onClick={loginViaSAML} id="loginViaSAML" style={{width: "100%", fontSize: "1.2rem", padding: "15px 0", marginTop: "32px", marginBottom: "79px"}} >LOGIN</YuJaButton>
                  }
                </Form>
              </div>

              <YuJaButton
                  type={"secondary"}
                  onClick={() => {window.location.href = JOIN_PARTICIPANT_PATH}}
                  style={{width: "70%", fontSize: "1.2rem", padding: "15px 0"}}
              >
                JOIN AS A PARTICIPANT
              </YuJaButton>

              <div className={loginMethodOptions[0].selected ?  styles.YuJaFooter : styles.NormalFooter}><p className= {styles.webLoginFooter}>Powered by YuJa</p> </div>
            </div>

          {!isNarrow && (
              <div style={{width: "60%", padding: "0%"}}>
                <div style={{backgroundImage: `url(${imgUrl})`}} className={styles.container}/>
              </div>
          )}
        </Row>
      </>
  );
}
