import React, { useEffect, useRef, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import "../../components/modals/modal.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { BUTTON, CANCEL_USER, CONFIRM_PASSWORD, CREATE_USER, CREATION_ROLES, EMAIL, FIRST_NAME, LAST_NAME, MIDDLE_NAME, MODAL, MODAL_CANCEL_TEXT, MODAL_SAVE_TEXT, PASSWORD, PHONE, REGEX_EMAIL, ROLE, ROLE_SUBTITLE, TEXTBOX, TEXT_BOX_MODAL, UPDATE_USER, USERNAME, USERNAME_SUBTITLE, USER_CREATION_FIELDS, USER_ROLE } from "../../utils/constants";
import { CHARACTER_LIMIT_EXCEEDED_GENERAL, PASSWORD_CHARACTER_LIMIT_EXCEEDED } from "../../utils/toast-message-constants";

const characterRequires = "Characters must be within letters, numbers, '_'";
const emailRequires = "The format of email is incorrect";
const phoneRequires = "The format of phone is incorrect (please exclude dashes or parentheses)";

const characterRegexp = /^[\w\s]+$/g;
const phoneRegexp = /^[\d]{10}$/g;
const testCap = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).+$");
const testSpe = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$");


export default React.memo(UserModal, (prevProps, nextProps) => {
    if (prevProps.isShow !== nextProps.isShow) {
        return false;
    }
    return prevProps.isNew === nextProps.isNew;
})

function UserModal({ checkExisted, submit, close, user = {}, isNew = true, isShow = false }) {
    const [userState, setUserState] = useState({
        userName: "",
        password: "",
        passwordConfirm: "",
        role: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: ""
    });
    const userNameRef = useRef();
    const passwordRef = useRef();
    const roleRef = useRef();
    const passwordConfirmRef = useRef();
    const firstNameRef = useRef();
    const middleNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const [loadingState, setLoadingState] = useState(false);
    const [invalidDict, setInvalidDict] = useState({});

    const handleSave = async () => {
        let validateRes = validateData();
        if (!validateRes) {
            return;
        }

        let existedRes;
        setLoadingState(true);
        await checkUserNameExisted(userState.userName).then((res) => {
            existedRes = res;
        }).catch(() => {
            existedRes = true;
        });

        if (existedRes) {
            setLoadingState(false);
            return;
        }

        submit(userState, isNew).finally(() => {
            setLoadingState(false);
        });

    };

    const handleClose = () => {
        close();
    };

    const checkUserNameExisted = (userName) => {
        // setLoadingState(true);
        return new Promise((resolve, reject) => {
            if (userName === user.userName) {
                resolve(false);
                return;
            }

            checkExisted({ userName: userName }).then(res => {
                resolve(res);
                if (res) {
                    userNameRef.current.classList.add("is-invalid");
                    userNameRef.current.nextSibling.innerHTML = "The user name already exists in database.";
                }
            }).catch(() => {
                reject();
            });
        })
    }

    const validateData = () => {
        let validateRes = true;
        const invalidJsonCopy = JSON.parse(JSON.stringify(invalidDict));
        Object.entries(userState).filter(([key]) => {
            return document.getElementsByName(key).length > 0;
        }).forEach(([key]) => {
            const ele = document.getElementsByName(key)[0];
            const res = validateInputNew(ele, invalidJsonCopy);
            if (!res) {
                validateRes = res;
            }
        });

        setInvalidDict(invalidJsonCopy);
        return validateRes;
    };

    useEffect(() => {
        const userObj = Object.assign({
            userName: "",
            password: "",
            passwordConfirm: "",
            role: "",
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            phone: "",
            instId: ""
        }, user);
        setUserState(userObj);
        setInvalidDict({});
    }, [user, isShow, isNew]);

    const changeVal = (event) => {
        const input = event.currentTarget;
        const user = Object.assign({}, userState);
        user[input.name] = input.value;
        setUserState(user);
    }

    const validateInputNew = (input, invalidJsonCopy) => {
        let ele;
        if (input.currentTarget) {
            ele = input.currentTarget;
            if (input.preventDefault) {
                input.preventDefault();
            }
            if (input.stopPropagation) {
                input.stopPropagation();
            }
        } else {
            ele = input;
        }

        if (!invalidJsonCopy) {
            invalidJsonCopy = JSON.parse(JSON.stringify(invalidDict));
        }

        if (ele.name !== "middleName" && ele.name !== "phone" && !validateBlankNew(ele, invalidJsonCopy)) {
            setInvalidDict(invalidJsonCopy);
            return false;
        }

        if (ele.name === "userName" || ele.name === "firstName" || ele.name === "lastName") {
            if (!validateCharacterNew(ele, invalidJsonCopy)) {
                setInvalidDict(invalidJsonCopy);
                return false;
            }
        }

        if (ele.name === "middleName" && ele.value && !validateCharacter(ele, invalidJsonCopy)) {
            setInvalidDict(invalidJsonCopy);
            return false;
        }

        if (ele.name === "password" || ele.name === "passwordConfirm") {
            if(validatePasswordNew(ele, invalidJsonCopy) && checkPasswordEqualNew(ele, invalidJsonCopy)) {
                delete invalidJsonCopy[ele.name];
                setInvalidDict(invalidJsonCopy);
            } else {
                setInvalidDict(invalidJsonCopy);
                return false;
            }
        }

        if (ele.name === "email" && !validateEmailNew(ele,invalidJsonCopy)) {
            setInvalidDict(invalidJsonCopy);
            return false;
        }

        if (ele.name === USER_CREATION_FIELDS.USERNAME && !validateCharacterLimit(ele, invalidJsonCopy, 100)) {
            setInvalidDict(invalidJsonCopy);
            return false;
        }
        if ((ele.name === USER_CREATION_FIELDS.FIRST_NAME || ele.name === USER_CREATION_FIELDS.MIDDLE_NAME || ele.name === USER_CREATION_FIELDS.LAST_NAME) && !validateCharacterLimit(ele, invalidJsonCopy, 50)) {
            setInvalidDict(invalidJsonCopy);
            return false;
        }

        if (ele.name === "phone" && ele.value.length > 0 && !validatePhoneNew(ele,invalidJsonCopy)) {
            setInvalidDict(invalidJsonCopy);
            return false;
        } else {
            delete invalidJsonCopy[ele.name];
        }

        setInvalidDict(invalidJsonCopy);
        return true;
    }

    const validateInput = (input) => {
        let ele;
        if (input.currentTarget) {
            ele = input.currentTarget;
            input.preventDefault();
            input.stopPropagation();
        } else {
            ele = input;
        }

        if (ele !== middleNameRef.current && ele !== phoneRef.current && !validateBlank(ele)) {
            return false;
        }

        if (ele === userNameRef.current || ele === firstNameRef.current || ele === lastNameRef.current) {
            if (!validateCharacter(ele)) {
                return false;
            }
        }

        if (ele === middleNameRef.current && ele.value && !validateCharacter(ele)) {
            return false;
        }

        if (ele === passwordRef.current || ele === passwordConfirmRef.current) {
            if(validatePassword(ele) && checkPasswordEqual(passwordRef.current)) {
                passwordRef.current.classList.remove("is-invalid");
                passwordConfirmRef.current.classList.remove("is-invalid");
                return true;
            } 
            return false;
        }
        
        if (ele === emailRef.current && !validateEmail(ele)) {
            return false;
        }

        if (ele === phoneRef.current && ele.value.length > 0 && !validatePhone(ele)) {
            return false;
        }

        ele.classList.remove("is-invalid");
        return true;

    };

    const validateCharacterLimit = (ele, copy, limit) => {
        if (ele.value.length > limit) {
            copy[ele.name] = CHARACTER_LIMIT_EXCEEDED_GENERAL.replace("length", limit);
            return false;
        }
        return true; 
    }

    const validateBlank = (ele) => {
        if (!ele.value) {
            ele.classList.add("is-invalid");
            ele.nextSibling.innerHTML = ele === roleRef.current ? ele.nextSibling.innerHTML : ("Please fill " + ele.placeholder + " field");
            return false;
        }
        return true;
    };

    const validateBlankNew = (ele, copy) => {
        if (ele.name === "role" && !ele.value) {
            copy[ele.name] = `Please select role`;
            return false;
        }


        if (!ele.value) {
            copy[ele.name] = `Please fill ${ele.placeholder} field`;
            return false;
        }

        if (copy.hasOwnProperty(ele.name)) {
            delete copy[ele.name];
        }
        return true;
    }

    const validateCharacter = (ele) => {
        if (ele.value.search(characterRegexp) < 0) {
            ele.classList.add("is-invalid");
            ele.nextSibling.innerHTML = characterRequires;
            return false;
        }
        return true;
    };

    const validateCharacterNew = (ele, copy) => {
        if (ele.value.search(characterRegexp) < 0) {
            copy[ele.name] = characterRequires;
            return false;
        }

        if (copy.hasOwnProperty(ele.name)) {
            delete copy[ele.name];
        }
        return true;
    };

    const checkPasswordEqual = (ele) => {
        if (passwordConfirmRef.current.value !== ele.value) {
            ele.classList.add("is-invalid");
            ele.nextSibling.innerHTML = "Please type the same password in both text boxes.";
            return false;
        }
        return true;
    };

    const checkPasswordEqualNew = (ele, copy) => {
        if (userState.password !== userState.passwordConfirm) {
            copy[ele.name] = "Please type the same password in both text boxes.";
            return false;
        }
        return true;
    };

    const validatePassword = (ele) => {
        if (ele.value.length < 12) {
            ele.classList.add("is-invalid");
            ele.nextSibling.innerHTML = "Password should be at least 12 characters.";
            return false;
        } else if (!testCap.test(ele.value)) {
            ele.classList.add("is-invalid");
            ele.nextSibling.innerHTML = "Password should contain at least one capital letter.";
            return false;
        } else if (!/\d/.test(ele.value)) {
            ele.classList.add("is-invalid");
            ele.nextSibling.innerHTML = "Password should contain at least one number.";
            return false;
        } else if (!testSpe.test(ele.value)) {
            ele.classList.add("is-invalid");
            ele.nextSibling.innerHTML = "Password should contain at least one special character.";
            return false;
        }
        return true;
    };

    const validatePasswordNew = (ele, copy) => {
        if (ele.value.length < 12) {
            copy[ele.name] = "Password should be at least 12 characters.";
            return false;
        } else if (ele.value.length > 30) {
            copy[ele.name] = PASSWORD_CHARACTER_LIMIT_EXCEEDED;
            return false;
        } else if (!testCap.test(ele.value)) {
            copy[ele.name] = "Password should contain at least one capital letter.";
            return false;
        } else if (!/\d/.test(ele.value)) {
            copy[ele.name] = "Password should contain at least one number.";
            return false;
        } else if (!testSpe.test(ele.value)) {
            copy[ele.name] = "Password should contain at least one special character.";
            return false;
        }
        return true;
    };

    const validateEmail = (ele) => {
        if (ele.value.search(REGEX_EMAIL) < 0) {
            ele.classList.add("is-invalid");
            ele.nextSibling.innerHTML = emailRequires;
            return false;
        }
        return true;
    };

    const validateEmailNew = (ele, copy) => {
        if (ele.value.search(REGEX_EMAIL) < 0) {
            copy[ele.name] = emailRequires;
            return false;
        }

        if (copy.hasOwnProperty(ele.name)) {
            delete copy[ele.name];
        }
        return true;
    };

    const validatePhone = (ele) => {
        if (ele.value.search(phoneRegexp) < 0) {
            ele.classList.add("is-invalid");
            ele.nextSibling.innerHTML = phoneRequires;
            return false;
        }
        return true;
    };

    const validatePhoneNew = (ele, copy) => {
        if (ele.value.search(phoneRegexp) < 0) {
            copy[ele.name] = phoneRequires;
            return false;
        }

        if (copy.hasOwnProperty(ele.name)) {
            delete copy[ele.name];
        }
        return true;
    };

    return (
        <Modal
            id="form-modal"
            show={isShow}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
        >
            <ModalClose tabIndex={0} aria-label={CANCEL_USER} role={BUTTON} onClick={handleClose} className="modal-close" />
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title
                    className="modal-title"
                    id="contained-modal-title-vcenter"
                    tabIndex={0} 
                    aria-label={(isNew ? CREATE_USER : UPDATE_USER) + MODAL}
                    role={TEXTBOX}
                >
                    {isNew ? CREATE_USER : UPDATE_USER}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="username">
                        <div className={"form-label"}>{USERNAME}</div>
                        <YuJaTextBox
                            label={TEXT_BOX_MODAL + "Username"}
                            name="userName"
                            placeholder="Username"
                            onBlur={validateInputNew}
                            onChange={changeVal}
                            value={userState.userName}
                            isInvalid={!!invalidDict["userName"]}
                            errorMsg={invalidDict["userName"]}
                            containerStyle={{width: "95%", fontSize: 13}}
                        />
                        <Form.Control.Feedback type="invalid">{USERNAME_SUBTITLE}</Form.Control.Feedback>
                    </Form.Group>

                    {!isNew ? (<></>) : (
                        <>
                            <Form.Group controlId="password">
                                <div className={"form-label"}>{PASSWORD}</div>
                                <YuJaTextBox
                                    label={TEXT_BOX_MODAL + "Password"}
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    onBlur={validateInputNew}
                                    onChange={changeVal}
                                    value={userState.password}
                                    isInvalid={!!invalidDict["password"]}
                                    errorMsg={invalidDict["password"]}
                                    containerStyle={{width: "95%", fontSize: 13}}
                                />
                            </Form.Group>

                            <Form.Group controlId="password confirm">
                                <div className={"form-label"}>{CONFIRM_PASSWORD}</div>
                                <YuJaTextBox
                                    label={TEXT_BOX_MODAL + "Confirm Password"}
                                    name="passwordConfirm"
                                    type="password"
                                    placeholder="Confirm Password"
                                    onBlur={validateInputNew}
                                    onChange={changeVal}
                                    value={isNew ? userState.passwordConfirm : userState.password}
                                    isInvalid={!!invalidDict["passwordConfirm"]}
                                    errorMsg={invalidDict["passwordConfirm"]}
                                    containerStyle={{width: "95%", fontSize: 13}}
                                />
                            </Form.Group>
                        </>
                    )}

                    <Form.Group controlId="role">
                        <div className={"form-label"}>{ROLE}</div>
                        <YuJaDropdown
                            label={USER_ROLE}
                            placeholder={"select Role"}
                            name="role"
                            containerStyle={{width: "95%", fontSize: 13}}
                            style={{padding: 6}}
                            data={Object.values(CREATION_ROLES)}
                            getOptionLabel={(item) => item.display}
                            value={userState.role}
                            isInvalid={!!invalidDict["role"]}
                            errorMsg={invalidDict["role"]}
                            onChange={item => {
                                const e = {currentTarget: {value: item.value, name: "role"}};
                                changeVal(e);
                                validateInputNew(e);
                            }}
                        />
                        {/*<Form.Select name="role"*/}
                        {/*    ref={roleRef}*/}
                        {/*    value={userState.role}*/}
                        {/*    onChange={e => {*/}
                        {/*        changeVal(e);*/}
                        {/*        validateInput(e);*/}
                        {/*    }}*/}
                        {/*     style={{width: "95%", fontSize: 13}}*/}
                        {/*>*/}
                        {/*    <option value="">select...</option>*/}
                        {/*    {Object.keys(CREATION_ROLES).map((key, index) => { return <option value={CREATION_ROLES[key].value} key={index}>{CREATION_ROLES[key].display}</option> })}*/}
                        {/*</Form.Select>*/}
                        <Form.Control.Feedback type="invalid">{ROLE_SUBTITLE}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="first name">
                        <div className={"form-label"}>{FIRST_NAME}</div>
                        <YuJaTextBox
                            label={TEXT_BOX_MODAL + "First Name"}
                            name="firstName"
                            placeholder="First Name"
                            onBlur={validateInputNew}
                            onChange={changeVal}
                            value={userState.firstName}
                            isInvalid={!!invalidDict["firstName"]}
                            errorMsg={invalidDict["firstName"]}
                            containerStyle={{width: "95%", fontSize: 13}}
                        />
                    </Form.Group>

                    <Form.Group controlId="middle name">
                        <div className={"form-label"}>{MIDDLE_NAME}</div>
                        <YuJaTextBox
                            label={TEXT_BOX_MODAL + "Middle Name"}
                            name="middleName"
                            placeholder="Middle Name"
                            onBlur={validateInputNew}
                            onChange={changeVal}
                            value={userState.middleName}
                            isInvalid={!!invalidDict["middleName"]}
                            errorMsg={invalidDict["middleName"]}
                            containerStyle={{width: "95%", fontSize: 13}}
                        />
                    </Form.Group>

                    <Form.Group controlId="last name">
                        <div className={"form-label"}>{LAST_NAME}</div>
                        <YuJaTextBox
                            label={TEXT_BOX_MODAL + "Last Name"}
                            name="lastName"
                            placeholder="Last Name"
                            onBlur={validateInputNew}
                            onChange={changeVal}
                            value={userState.lastName}
                            isInvalid={!!invalidDict["lastName"]}
                            errorMsg={invalidDict["lastName"]}
                            containerStyle={{width: "95%", fontSize: 13}}
                        />
                    </Form.Group>

                    <Form.Group controlId="email">
                        <div className={"form-label"}>{EMAIL}</div>
                        <YuJaTextBox
                            label={TEXT_BOX_MODAL + "Email"}
                            name="email"
                            placeholder="Email"
                            onBlur={validateInputNew}
                            onChange={changeVal}
                            value={userState.email}
                            isInvalid={!!invalidDict["email"]}
                            errorMsg={invalidDict["email"]}
                            containerStyle={{width: "95%", fontSize: 13}}
                        />
                    </Form.Group>

                    <Form.Group controlId="phone">
                        <div className={"form-label"}>{PHONE}</div>
                        <YuJaTextBox
                            label={TEXT_BOX_MODAL + "Phone"}
                            name="phone"
                            placeholder="Phone"
                            onBlur={validateInputNew}
                            onChange={changeVal}
                            value={userState.phone}
                            isInvalid={!!invalidDict["phone"]}
                            errorMsg={invalidDict["phone"]}
                            containerStyle={{width: "95%", fontSize: 13}}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton aria-label={CANCEL_USER} type={"secondary"} onClick={handleClose}>
                    {MODAL_CANCEL_TEXT}
                </YuJaButton>
                {loadingState ? (
                    <YuJaButton disabled={true}  onClick={handleSave} style={{width: 60}}>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </YuJaButton>
                ) : (
                    <YuJaButton onClick={handleSave} style={{width: 60}}>
                        {MODAL_SAVE_TEXT}
                    </YuJaButton>
                )}
            </Modal.Footer>
        </Modal>

    );
}
