import React from "react";
import { Col } from "react-bootstrap";
import { compareEntries, entriesToString } from "../../utils/questionUtils";
import OptionButton from "./OptionButton";
import "./styles.css";

export default function EntriesOptionRow({
    entriesRow,
    entries,
    // customEntries,
    handleEntriesUpdate
}) {
  return (
    <div className="options-row">
      {Object.values(entriesRow).map((entriesCol, idx) => {
        return (
          <Col key={idx} className={`d-flex no-padding options-row-outer ${idx % 3 === 0 ? "justify-content-start" : (idx % 3 === 1 ? "justify-content-center" : "justify-content-end")}`}>
            <OptionButton
              key={idx}
              value={parseInt(entriesCol[1])}
              textDisplay={entriesToString(entriesCol[1])}
              valueToEqual={parseInt(entries)}
              compareValue={compareEntries}
              // valueToNotEqual={customEntries}
              handleClick={handleEntriesUpdate}
            />
          </Col>
        );
      })}
    </div>);
}
