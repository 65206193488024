/*global chrome*/
import toast from "react-hot-toast";
import { browserName, fullBrowserVersion, osName, osVersion } from "react-device-detect";
import {
  ASCII_CODE_A,
  ATTEMPTS,
  ATTEMPT_NO_PREFIX,
  ATTEMPT_REGEX,
  DEFAULT_TOAST_MESSAGE_DURATION,
  FIXED_ANSWER,
  LOGOUT_CHROME_EXT_MESSAGE,
  LOG_API_FILES,
  LOG_TYPES,
  MCSSoptLower,
  MCSSoptUpper,
  MENTIMETER_EXTENSION_ID,
  POLL_CODE_LENGTH,
  POLL_EVERYWHERE_EXTENSION_ID,
  POLL_SHARE_MODE,
  POLL_TYPE,
  QUESTION_TYPES,
  REATTEMPT_OUT_OF,
  REATTEMPT_REMAINING_TEXT,
  SESSION,
  STUDENT_COLUMN_INDEX,
  SYNC_ATTEMPT,
  TOAST_TYPE,
  TOPHAT_EXTENSION_ID,
  USER_ID_COLUMN_INDEX,
  USER_SETTINGS,
  WORD_CLOUD_COLORS,
  WORD_CLOUD_ID
} from "./constants";
import { PUBLIC_ACCESS_API_URL, RESPONSE_API_URL, POLL_API_URL, USER_API_URL, INSTITUTION_API_URL, INTEGRATION_API_URL, AUTHENTICATION_API_URL, COURSE_API_URL } from "./properties.js";

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const removePrefix = (text) => {
  // ids are store with prefixes ends with # in the dynamodb
  return text.substring(text.indexOf("#") + 1);
};

export const removePrefixNew = (text) => {
  return text.substring(text.lastIndexOf("#") + 1);
};

export const transferConstantPollType = (pollType) => {
  if (!pollType) {
    return pollType;
  }

  return pollType.trim().toUpperCase().split(' ').join('_');
}


// isBefore: Str Str => Bool checks if date1 comes before date2
// date1 and date2 are strings in the format of "dd-mm-yyyy"
export const isBefore = (date1, date2) => {
  const year1 = date1.slice(-4);
  const year2 = date2.slice(-4);
  const month1 = date1.slice(3, 5);
  const month2 = date2.slice(3, 5);
  const day1 = date1.slice(0, 2);
  const day2 = date2.slice(0, 2);
  if (year1 < year2) {
    return true;
  }
  else if (year1 > year2) {
     return false;
  }
  else {
    if (month1 < month2) {
      return true;
    } else if (month1 > month2) {
      return false;
    } else {
      if (day1 <= day2) {
        return true;
      } else {
        return false;
      }
  }
}
  // return (year1 <= year2) && (month1 <= month2) && (day1 <= day2);
};

export const copyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    return navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export let logger = {
  institutionId: null,
  userId: null,
  userType: null,
  pollKey: null,
  pollCode: null,
  courseId: null,
  browserType: browserName + " " + fullBrowserVersion,
  platform: osName + " " + osVersion,
  messages: "",
  collectUserLogs: false
};

export const newPageLog = (file) => {
  updateLogger(LOG_TYPES.INFO + "User with userId: " + logger.userId + " has reached page " + file + "\n");
};

export const formatUserSubmit = () => {
  updateLogger(LOG_TYPES.INFO + "User with userId: " + logger.userId + " has submitted the question!\n");
};

export const formatErrorLog = (err) => {
  updateLogger(LOG_TYPES.ERROR + "Message: " + JSON.stringify(err));
}

export const formatAPILog = (url, body) => {
  updateLogAttributes(body);
  let file = null;
  if(url.includes(RESPONSE_API_URL) || url === "SetUserNameToLocalStorage") { file = LOG_API_FILES.RESPONSE; }
  else if(url.includes(PUBLIC_ACCESS_API_URL)) { file = LOG_API_FILES.PUBLIC; }
  else if(url.includes(POLL_API_URL)) { file = LOG_API_FILES.POLL; }
  else if(url.includes(USER_API_URL)) { file = LOG_API_FILES.USER; }
  else if(url.includes(INTEGRATION_API_URL)) { file = LOG_API_FILES.LTI; }
  else if(url.includes(INSTITUTION_API_URL)) { file = LOG_API_FILES.INST; }
  else if(url.includes(COURSE_API_URL)) { file = LOG_API_FILES.COURSE; }
  else if(url.includes(AUTHENTICATION_API_URL)) { file = LOG_API_FILES.AUTHENTICATION; }

  updateLogger(LOG_TYPES.INFO + "Calling " + url + " from " + file + " with body " + JSON.stringify(body));
};

export const formatAPIResponseLog = (apiName, respBody) => {
  updateLogAttributes(respBody);
  updateLogger(LOG_TYPES.INFO + apiName + " returned with response body " + JSON.stringify(respBody));
};

export const formatAPIErrorLog = (apiName, error) => {
  updateLogger(LOG_TYPES.ERROR + apiName + " returned with error message " + JSON.stringify(error));
};

export const updateLogAttributes = (body) => {
  logger.institutionId = body.institutionIdPK ? body.institutionIdPK.substring(0, 38) : logger.institutionId;
  logger.userId = body.userId ? (body.userId.includes("U#") ? body.userId : "U#" + body.userId) : logger.userId;
  logger.userType = body.userType ? body.userType : logger.userType;
  logger.pollKey = body.pollKey ? (body.pollKey.length === 36 ? "P#" + body.pollKey : body.pollKey) : logger.pollKey;
  logger.pollCode = body.uniqueCodePK ? (body.uniqueCodePK.length === POLL_CODE_LENGTH ? "UC#" + body.uniqueCodePK : body.uniqueCodePK) : logger.pollCode;
  logger.courseId = body.index2Pk ? (body.index2Pk.length === 36 ? "C#" + body.index2Pk : body.index2Pk) : logger.courseId;
};

export const updateLogger = (text) => {
  logger.messages += (text + "\n");
};

export const intToChar = (int) => {
  // 👇️ for Uppercase letters, replace `a` with `A`
  const code = "a".charCodeAt(0);

  return String.fromCharCode(code + int);
};

export const splitArrayIntoChunks = (arr, chunkSize) => {
  return arr
    .map((e, i) => {
      return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
    })
    .filter((e) => {
      return e;
    });
};

const buildToastOptObj = () => {
  const optionObj = {};
  const session = window.navigator.cookieEnabled ? JSON.parse(localStorage.session) : localStorageData[SESSION];
  const settings = session?.settings ? session?.settings: {};
  if (!settings.hasOwnProperty(USER_SETTINGS.TOAST_MESSAGE_DURATION)) {
    optionObj.duration = DEFAULT_TOAST_MESSAGE_DURATION * 1000;
  } else if (parseInt(settings[USER_SETTINGS.TOAST_MESSAGE_DURATION]) > 0) {
    optionObj.duration = parseInt(settings[USER_SETTINGS.TOAST_MESSAGE_DURATION]) * 1000;
  }
  return optionObj;
}


export const notifyInfo= (text) => {
  const optionObj = buildToastOptObj();
  optionObj.toastType = TOAST_TYPE.INFO;
  optionObj.title='Info!';
  toast.loading(text ? text : '', optionObj);
};

export const notifySuccess = (text, title=null) => {
  const optionObj = buildToastOptObj();
  optionObj.toastType = TOAST_TYPE.SUCCESS;
  if (title != null) {
    optionObj.title= title; 
  }
  else {
    optionObj.title='Success!';
  }
  toast.loading(text ? text : '', optionObj);
};

export const notifyWarning= (text) => {
  const optionObj = buildToastOptObj();
  optionObj.toastType = TOAST_TYPE.WARNING;
  optionObj.title='Warning';
  toast.loading(text ? text : '', optionObj);
};

export const notifyError = (text) => {
  const optionObj = buildToastOptObj();
  optionObj.toastType = TOAST_TYPE.ERROR;
  optionObj.title='Error';
  toast.loading(text ? text : '', optionObj);
};


// for old design
export const unChooseAllQuestions = (questions) => {
  let newQuestions = [];
  questions.forEach((q) => {
    q = { ...q, checked: false };
    newQuestions.push(q);
  });

  return newQuestions;
};

export const unChooseAll = (content) => {
  let newContent = [];
  content.forEach((poll) => {
    poll = {
      ...poll,
      checked: false,
      showChildren: false,
      questions: unChooseAllQuestions(poll.questions),
    };
    newContent.push(poll);
  });
  return newContent;
};
// for old design


export const validateArray = (arr) => {
  return arr && arr.length > 0;
};

export const validateMap = (map) => {
  return map && Object.entries(map).length > 0;
};

export const validateText = (text, maxLength) => {
  return text && text.trim() !== "" && text.length <= maxLength;
};

export const validateTextEmpty = (text) => {
  return text && text !== "";
};

export const validateTextMaxLength = (text, maxLength) => {
  return text.length <= maxLength;
};

export const validateOptions = (map, maxLength) => {
  return validateMap(map) && Object.values(map).every((v) => validateText(v, maxLength));
};

export const stringifyNumber = (n) => {
  const special = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
    "Eleventh",
    "Twelfth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
    "Sixteenth",
    "Seventeenth",
    "Eighteenth",
    "Nineteenth",
  ];

  const deca = [
    "Twent",
    "Thirt",
    "Fort",
    "Fift",
    "Sixt",
    "Sevent",
    "Right",
    "Ninet",
  ];

  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
};

export const customSlice = (text, length) => { // to slice FITB title without considering blanks in character count
  // console.log(text, length);
  if (text.length <= length) return text;
  let i = 0;
  let j = 0;
  let res = "";
  let resLen = 0;
  while (i < text.length && resLen <= length) {
    if (text[i] === '(') {
      j = i;
      while (j < text.length) {
        ++j;
        if (text[j] === '(') {
          --j;
          break;
        } else if (j !== i + 1 && text[j] === ')') {
          break;
        }
      }
      if (text[j] === ')' || (resLen + j - i + 1) <= length) {
        res += text.substring(i, j + 1);
        if (text[j] !== ')') resLen += (j - i + 1);
      }
      i = j + 1;
      // console.log(resLen, res);
    } else {
      if (resLen !== length) res += text[i];
      ++resLen;
      ++i;
      // console.log(resLen, res);
    }
  }
  return res;
};

export const fetchImg = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const imageBlob = await response.blob();
    const url = URL.createObjectURL(imageBlob);
    console.log(url)
    return url;
  } catch (e) {
    return "";
  }
}


export const fetchImgFile = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const imageBlob = await response.blob();
    imageBlob.lastModifiedDate = new Date();
    return imageBlob;
  } catch (e) {
    return null;
  }
}

// Returns the Institution ID from the given GSI5PK
export const getInstitutionId = (gsi5pk) => { // For example: "I#b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615#PUB#UC"
  let startIdx = gsi5pk.indexOf("#") + 1;     // 2
  let endIdx = gsi5pk.indexOf("#", startIdx); // 38
  return gsi5pk.substring(startIdx, endIdx);  // "b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615"
}

export const fixFITB = (input) => {
  const regex = /(\(.*?\))/g;
  return input.replaceAll(regex, "______")
}

export const handleUserAns = (liveQuestion, ans) => {
  if (liveQuestion.questionType === QUESTION_TYPES.MCSS.name) {
    return (liveQuestion.correctAnswers.includes(ans));
  } else if (liveQuestion.questionType === QUESTION_TYPES.TF.name) {
    return (liveQuestion.correctAnswers === ans);
  } else if (liveQuestion.questionType === QUESTION_TYPES.SA.name) {
    if (ans === FIXED_ANSWER.UNANSWERED) return false;

    let formattedAns = (ans["0"]).toString().toLowerCase().trim();

    for (let i = 0; i < liveQuestion.correctAnswers.length; i++) {
      if (formattedAns === (liveQuestion.correctAnswers[i]).toString().toLowerCase().trim())
        return true;
    }
    return false;
  } else if (liveQuestion.questionType === QUESTION_TYPES.FITB.name) {
    let correctAns = liveQuestion.correctAnswers;
    let keys = Object.keys(correctAns);
    const FITBnum = keys.length;
    let numCorrect = 0;

    for (let i = 0; i < FITBnum; i++) {
      for (let j = 0; j < correctAns[keys[i]].length; j++) {

        if (ans[keys[i]] === undefined)
          return false;

        if ((correctAns[keys[i]][j]).toString().toLowerCase().trim() === ans[keys[i]].toString().toLowerCase().trim())
          numCorrect++
      }
    }
    return numCorrect === FITBnum;
  } else if (liveQuestion.questionType === QUESTION_TYPES.CI.name) {
    const {selected} = ans;
    if (selected === FIXED_ANSWER.UNANSWERED || selected === FIXED_ANSWER.OTHER) {
      return false;
    }

    return liveQuestion.correctAnswers[0] === parseInt(selected);
  } else if (liveQuestion.questionType === QUESTION_TYPES.WC.name) { // Irrelevant for Survey; Same as SA for Graded
    return false;
  } else if (liveQuestion.questionType === QUESTION_TYPES.MH.name) {
    if (ans === FIXED_ANSWER.UNANSWERED || ans == null) return false;
    let is_correct = true; 
    for (let i = 0; i< liveQuestion.correctAnswers.length; i++) {
      if(liveQuestion.correctAnswers[i].length > 1 && (!ans.hasOwnProperty(i) || liveQuestion.correctAnswers[i][1] !== ans[i]) ) {
        is_correct= false;
      }
    }
    console.log(is_correct);
    return is_correct; 
  }
};



export const isPureNumber = (val) => {
  if (typeof val !== "string" || !(val instanceof String)) {
    return false;
  }

  return /^[0-9]+$/.test(val);
}

/**
 *
 * @returns formatted string yyyy-MM-ddTHH:mm:ss
 */
export const getFormattedCurrentTime = () => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = ("00" + now.getUTCMonth() + 1).slice(-2);
  const date = ("00" + now.getUTCDate()).slice(-2);
  const hour = ("00" + now.getUTCHours()).slice(-2);
  const minute = ("00" + now.getUTCMinutes()).slice(-2);
  const second = ("00" + now.getUTCSeconds()).slice(-2);

  return `${year}-${month}-${date}T${hour}:${minute}:${second}`;
}


/**
 *
 * @param imageName ${originalFileName}-yyyy-MM-ddTHH:mm:ss${suffix}
 * @returns {string}
 */
export const getFileNameFromImageName = (imageName) => {
  const regexp = /(.+)-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\w+/g;
  return regexp.exec(imageName)[1];
}

/**
 *
 * @param imageName ${originalFileName}-yyyy-MM-ddTHH:mm:ss${suffix}
 * @returns {string}
 */
export const getSuffixFromImageName = (imageName) => {
  const regexp = /(.+)-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\w+)/g;
  return regexp.exec(imageName)[2];
}

export const isValidScore = (s) => {
  const score = s;
  // if (score === undefined || score === null) { // invalid variable
  //   return false;
  // }
  const regexp = /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/g; // SCORE_REGEX
  const result = regexp.test(score);
  // console.log(score, typeof score, result);
  return result;
}

export const hasSA = (questions) => {
  // console.log(questions);
  for (const q of questions) {
    if (q.questionType === QUESTION_TYPES.SA.name) {
      return true;
    }
  }
  // console.log("no SA");
  return false;
};

export const hasWC = (questions) => {
  // console.log(questions);
  for (const q of questions) {
    if (q.questionType === QUESTION_TYPES.WC.name) {
      return true;
    }
  }
  // console.log("no WC");
  return false;
};

// for FITB and WC and MH
export const checkBlankAnswered = (selectedOption) => {
  if (selectedOption === FIXED_ANSWER.UNANSWERED ) {
    return true;
  }
  for (let key in selectedOption) {
    if (selectedOption[key] !== "") {
      return false;
    }
  }
  return true;
}

export const checkUnanswered = (pollType, curQuestionType, selectedOption) => {
  return !selectedOption
      || (pollType === POLL_TYPE.SURVEY && curQuestionType === QUESTION_TYPES.MCSS.name && selectedOption.length === 0)
      || (pollType === POLL_TYPE.SURVEY && curQuestionType === QUESTION_TYPES.MCSS.name && selectedOption[0] === FIXED_ANSWER.UNANSWERED)
      || selectedOption === FIXED_ANSWER.UNANSWERED;
}

export const checkIncomplete = (pollType, curQuestionType, selectedOption) => {
  return checkUnanswered(pollType, curQuestionType, selectedOption) || (curQuestionType === QUESTION_TYPES.FITB.name && Object.values(selectedOption).includes(""));
}

export const getYourAnswer = (pollType, questionType, selectedOption) => {
  if (checkUnanswered(pollType, selectedOption)) {
    return FIXED_ANSWER.UNANSWERED;
  }

  if (questionType === QUESTION_TYPES.MCSS.name && pollType === POLL_TYPE.SURVEY) {
    return `${JSON.stringify(selectedOption.map(opt => opt.toUpperCase()))}`
  }

  if (questionType === QUESTION_TYPES.MCSS.name) {
    return `${selectedOption.toUpperCase()}`
  }

  if (questionType === QUESTION_TYPES.TF.name) {
    return selectedOption;
  }

  if (questionType === QUESTION_TYPES.FITB.name) {
    let answerArr = [];
    for (const [key, value] of Object.entries(selectedOption)) {
      answerArr.push(`${key}: ${value}`);
    }
    return answerArr.join(", ");
  }

  if (questionType === QUESTION_TYPES.SA.name) {
    return selectedOption['0'];
  }

  if (questionType === QUESTION_TYPES.CI.name) {
    if (selectedOption.selected === FIXED_ANSWER.OTHER) {
      return FIXED_ANSWER.OTHER;
    }
    return String.fromCharCode(ASCII_CODE_A + parseInt(selectedOption.selected)).toUpperCase();
  }

  if (questionType === QUESTION_TYPES.WC.name) {
    return selectedOption;
  }

  return '';
}

export const checkAllEmpty = (jsonString) => {
  const jsonObject = JSON.parse(jsonString);
  return Object.values(jsonObject).every(value => typeof value !== 'string' || value.trim() === "");
}

export const getReattemptText = (attemptsCount, maxAttempts) => {
  return `${attemptsCount} ${REATTEMPT_OUT_OF} ${maxAttempts} ${REATTEMPT_REMAINING_TEXT}`
};

export const prepareAttemptSubrows = (responses) => {
  console.log("Original Responses", responses);
  let updatedResponses = [];
  responses.map((response, idx) => {
    let updatedResponse = {...response};
    // console.log(response);
    // let userId = response.userId;
    if (updatedResponse.hasOwnProperty(ATTEMPTS)) {
      updatedResponse.subRows = [];
      for (const [attemptNo, attempt] of Object.entries(updatedResponse.attempts)) {
        if (attemptNo === "0") {
          updatedResponse = {...attempt, ...updatedResponse};
        } else {
          updatedResponse.subRows.push({
            firstName: "Attempt",
            lastName: "#" + attemptNo,
            // userId: userId,
            ...attempt
          });
        }
      }
    }
    updatedResponses[idx] = updatedResponse;
    return updatedResponse;
  });
  console.log("Updated Responses", updatedResponses);
  return updatedResponses;
};

export const getAllExpanded = (rows) => {
  // console.log(rows);
  const res = rows.every(row => {
    // console.log(row, row.getCanExpand(), row.getIsExpanded());
    return !row.getCanExpand() || row.getIsExpanded();
  });
  return res;
};

export const getUserId = (row) => {
  return row.getAllCells()[USER_ID_COLUMN_INDEX].getValue();
};

export const getAttemptNo = (row) => {
  let str = row.getAllCells()[STUDENT_COLUMN_INDEX].getValue();
  // console.log(str, parseInt(str.substring(str.indexOf(ATTEMPT_NO_PREFIX) + 9)));
  return (!!str && ATTEMPT_REGEX.test(str) ? parseInt(str.substring(str.indexOf(ATTEMPT_NO_PREFIX) + 9)) : 0);
};

// export const getTotalAttempts = (row, customScoresMap) => {
//   const userId = getUserId(row);
//   if (!customScoresMap.hasOwnProperty(userId)) return -1;
//   const keys = Object.keys(customScoresMap[userId]);
//   // console.log(keys, keys.length, keys[keys.length-1]);
//   return parseInt(keys[keys.length-1]);
// };

// export const isLastRow = (row, rows) => {
//   // console.log(row, row.id);
//   // console.log(rows, rows[rows.length - 1].id);
//   // console.log(row.id === rows[rows.length - 1].id);
//   return row.id === rows[rows.length - 1].id;
// }

export const isDisabled = (row, customScoresMap, lmsAttempt) => {
  // console.log(row, customScoresMap, lmsAttempt);
  // if (row.getCanExpand() || getAttemptNo(row) === 0) {
  //   console.log("disable main attempt");
  //   return true;
  // }
  // if (lmsAttempt === SYNC_ATTEMPT.FIRST.value && getAttemptNo(row) !== 1) {
  //   console.log("disable all attempts except first attempt");
  //   return true;
  // }
  // if (lmsAttempt === SYNC_ATTEMPT.RECENT.value && getAttemptNo(row) !== Object.keys(customScoresMap[row.getAllCells()[USER_ID_COLUMN_INDEX].getValue()]).length - 1) {
  //   console.log("disable all attempts except last attempt");
  //   return true;
  // }
  // console.log("enabled");
  // return false;
  return ((row.getCanExpand() || getAttemptNo(row) === 0) || // disable main attempt
    (lmsAttempt === SYNC_ATTEMPT.FIRST.value && // disable all attempts except first attempt
      getAttemptNo(row) !== 1) ||
    (lmsAttempt === SYNC_ATTEMPT.RECENT.value && // disable all attempts except last attempt
      getAttemptNo(row) !== Object.keys(customScoresMap[getUserId(row)]).length - 1));
};

// export const getCustomScore = (r, c) => {
//   const row = r;
//   const customScoresMap = c;
//   console.log(row, customScoresMap);
//   if (!customScoresMap.size) {
//     console.log("custom scores map not initialized yet");
//     return null;
//   }
//   console.log(!!customScoresMap, customScoresMap.size);
//   const userId = getUserId(row);
//   console.log(userId);
//   const userMap = customScoresMap[userId];
//   console.log(userMap);
//   const attemptNo = getAttemptNo(row);
//   console.log(attemptNo);
//   const score = userMap[attemptNo];
//   console.log(score);
//   return score;
// };

export const calculateScore = (earnedPoints, totalPoints) => {
  const score = ((earnedPoints/totalPoints)*100.0).toFixed(2);
  // console.log(earnedPoints, totalPoints, score);
  return score;
};

export const getAnswer = (response, attemptNo, serialNo) => {
  let answer = FIXED_ANSWER.UNANSWERED;
  if (!!response && response.attempts[attemptNo].hasOwnProperty("A" + serialNo)) {
      if (response.attempts[attemptNo]["A" + serialNo] !== FIXED_ANSWER.UNANSWERED) {
          answer = response.attempts[attemptNo]["A" + serialNo][0];
      }
  }
  return answer;
}

export const parseWCId = (id) => {
  return id.substring(id.lastIndexOf("-") + 1); // "WC-IN-1" => "1"
};

export const organiseData = (data, correctAnswers) => {
  let words = [];
  if (!data) return words;
  data.forEach((name) => {
    if (name === FIXED_ANSWER.UNANSWERED) return;
    const index = words.findIndex((item) => item.name === name);
    if (index === -1) {
      if (correctAnswers.includes(name)) { // change style for correct answers in Graded Poll flow
        words.push({
          name,
          value: 1,
          textStyle: {
            color: "green",
            fontWeight: "bold"
          }
        });
      } else {
        words.push({
          name,
          value: 1,
          textStyle: {
            color: autoFontColor(),
            // size: autoFontSize()
          }
        });
      }
    } else {
      words[index].value++;
    }
  });
  return words;
};

export const getWordCloudWidth = (serialNo=1) => {
  let width = document.getElementById(WORD_CLOUD_ID + serialNo) ? document.getElementById(WORD_CLOUD_ID + serialNo).offsetWidth : 0;
  return width;
};

export const autoFontSize = (serialNo=1) => {
  let width = getWordCloudWidth(serialNo);
  let newFontSize = Math.round(width / 30);
  return newFontSize;
};

export const autoFontColor = () => {
  return WORD_CLOUD_COLORS[Math.floor(Math.random() * WORD_CLOUD_COLORS.length)];
};

export const processResult = (question, result) => {
  const {questionType, optionsMap} = question;
  let newOptionsMap = (questionType === QUESTION_TYPES.MCSS.name ? optionsMap : {});
  const MCSSnum = (questionType === QUESTION_TYPES.MCSS.name ? Object.keys(newOptionsMap).length : 0);
  const data = [];
  if (questionType === QUESTION_TYPES.MCSS.name) {
      for (let i = 0; i < MCSSnum; i++) {
          let opt;
          if (Number.isNaN(Number(result[MCSSoptLower[i]]))) {
              opt = {
                  "name": MCSSoptUpper[i] + ". 0",
                  "people": 0,
                  "ans": MCSSoptUpper[i] + ". " + newOptionsMap[MCSSoptLower[i]],
                  "total": result.total,
              }
          } else {
              opt = {
                  "name": MCSSoptUpper[i] + ". " + Number(result[MCSSoptLower[i]]),
                  "people": Number(result[MCSSoptLower[i]]),
                  "ans": MCSSoptUpper[i] + ". " + newOptionsMap[MCSSoptLower[i]],
                  "total": result.total,
              }
          }
          data.push(opt);
      }
      let unans;
      if (Number.isNaN(Number(result['unanswered']))) {
          unans = {
              "name": "N/A. 0",
              "people": 0,
              "ans": "Unanswered",
              "total": result.total,
          }
      } else {
          unans = {
              "name": "N/A. " + Number(result['unanswered']),
              "people": Number(result['unanswered']),
              "ans": "Unanswered",
              "total": result.total,
          }
      }
      data.push(unans);

  } else if (questionType === QUESTION_TYPES.TF.name) {
      let optT, optF;
      if (Number.isNaN(Number(result['True']))) {
          optT = {
              "name": "True. 0",
              "people": 0,
              "ans": "True",
              "total": result.total
          }
      } else {
          optT = {
              "name": "True. " + Number(result['True']),
              "people": Number(result['True']),
              "ans": "True",
              "total": result.total
          }
      }
      data.push(optT);

      if (Number.isNaN(Number(result['False']))) {
          optF = {
              "name": "False. 0",
              "people": 0,
              "ans": "False",
              "total": result.total
          }
      } else {
          optF = {
              "name": "False. " + Number(result['False']),
              "people": Number(result['False']),
              "ans": "False",
              "total": result.total
          }
      }
      data.push(optF);

      let unans;
      if (Number.isNaN(Number(result['unanswered']))) {
          unans = {
              "name": "N/A. 0",
              "people": 0,
              "ans": "Unanswered",
              "total": result.total
          }
      } else {
          unans = {
              "name": "N/A. " + Number(result['unanswered']),
              "people": Number(result['unanswered']),
              "ans": "Unanswered",
              "total": result.total
          }
      }
      data.push(unans);

  } else if (questionType === QUESTION_TYPES.CI.name) {
      let idx = 0
      for (; idx < optionsMap.length; idx++) {
          let opt = {
              "name": idx,
              "people": result.hasOwnProperty(idx.toString()) ? result[idx.toString()] : 0,
              "ans": `${String.fromCharCode(ASCII_CODE_A + idx)}. ${optionsMap[idx].text}`,
              "total": result.total
          }
          data.push(opt);
      }
      if (result.hasOwnProperty(FIXED_ANSWER.OTHER)) {
          idx++;
          data.push({
              "name": -1,
              "people": result[FIXED_ANSWER.OTHER],
              "ans": `${FIXED_ANSWER.OTHER}`,
              "total": result.total
          });
      }
      data.push({
          "people": result.hasOwnProperty(FIXED_ANSWER.UNANSWERED) ? result[FIXED_ANSWER.UNANSWERED] : 0,
          "ans": "Unanswered",
          "total": result.total
      });

  } else { // SA, FITB or WC or MH
      let optT, optF;
      if (Number.isNaN(Number(result['correct']))) {
          optT = {
              "name": "Correct. 0",
              "people": 0,
              "ans": "Correct",
              "total": result.total
          }
      } else {
          optT = {
              "name": "Correct. " + Number(result['correct']),
              "people": Number(result['correct']),
              "ans": "Correct",
              "total": result.total
          }
      }
      data.push(optT);

      if (Number.isNaN(Number(result['incorrect']))) {
          optF = {
              "name": "Incorrect. 0",
              "people": 0,
              "ans": "Incorrect",
              "total": result.total
          }
      } else {
          optF = {
              "name": "Incorrect. " + Number(result['incorrect']),
              "people": Number(result['incorrect']),
              "ans": "Incorrect",
              "total": result.total
          }
      }
      data.push(optF);

      let unans;
      if (Number.isNaN(Number(result['unanswered']))) {
          unans = {
              "name": "N/A. 0",
              "people": 0,
              "ans": "Unanswered",
              "total": result.total
          }
      } else {
          unans = {
              "name": "N/A. " + Number(result['unanswered']),
              "people": Number(result['unanswered']),
              "ans": "Unanswered",
              "total": result.total
          }
      }
      data.push(unans);

      if (questionType === QUESTION_TYPES.SA.name) {
          let ungraded;
          if (Number.isNaN(Number(result['ungraded']))) {
              ungraded = {
                  "name": "Ungraded. 0",
                  "people": 0,
                  "ans": "Ungraded",
                  "total": result.total
              }
          } else {
              ungraded = {
                  "name": "Ungraded. " + Number(result['ungraded']),
                  "people": Number(result['ungraded']),
                  "ans": "Ungraded",
                  "total": result.total
              }
          }
          data.push(ungraded);
      }
  }
  console.log(data);
  return data;
};

export const getPollShareModeFromPollState = (pollState) => {
  switch (pollState) {
    case 'GET_READY_ALL':
    case 'SHARED_ALL':
    case 'TIME_UP_ALL':
    case 'PAUSED_ALL':
    case 'LOCKED_ALL':
      return POLL_SHARE_MODE.SHARE_ALL;
    case 'GET_READY':
    case 'SHARED':
    case 'TIME_UP':
    case 'PAUSED':
    case 'LOCKED':
      return POLL_SHARE_MODE.SHARE_EACH;
  }
};

export const InitializeQuestionResultCount = (question) => {
  const {questionType, optionsMap} = question;
  let result = [];
  if (questionType === QUESTION_TYPES.CI.name) {
    for (const [index, item] of optionsMap.entries()) {
      result.push({"name": index, "people": 0, "ans":  String.fromCharCode('A'.charCodeAt(0) + index) + ". " + item.text, "total": 0});
    }
  } else if(questionType === QUESTION_TYPES.MCSS.name) {
    Object.entries(optionsMap).forEach(entry => {
      result.push({"name": entry[0].toUpperCase(),"people":0,"ans": entry[0].toUpperCase() + ". " + entry[1],"total": 0})
    })
  } else if (questionType === QUESTION_TYPES.TF.name) {
    result = [{"name":"True. 1","people": 0,"ans": "True","total":0},{"name":"False. 0","people": 0,"ans": "False","total": 0}];
  } else if (questionType === QUESTION_TYPES.MH.name) {
    result = [{"name": "Correct. 0","people": 0, "ans": "Correct","total": 0},{"name":"Incorrect. 2","people": 0,"ans":"Incorrect","total": 0}];
  }

  return result;
}

// engage sidebar state
export let SHOW_CREATE_OPTIONS = false;
export let CREATE_MODE = POLL_TYPE.GRADED_POLL;
export const updateShowCreateOptions = (newState) => {
  SHOW_CREATE_OPTIONS = newState;
}
export const updateCreateMode = (newState) => {
  CREATE_MODE = newState;
}

// store local storage information in global variables
export let localStorageData = {
  authToken: ""
};
export const updateLocalStorageData = (newData) => {
  localStorageData = newData;
}

// mentimeter chrome extension
export const logoutOfChromeExtensions = () => {
  try {
    chrome.runtime.sendMessage(MENTIMETER_EXTENSION_ID, {logout: LOGOUT_CHROME_EXT_MESSAGE}, function(response) {})
    chrome.runtime.sendMessage(TOPHAT_EXTENSION_ID, {logout: LOGOUT_CHROME_EXT_MESSAGE}, function(response) {})
    chrome.runtime.sendMessage(POLL_EVERYWHERE_EXTENSION_ID, {logout: LOGOUT_CHROME_EXT_MESSAGE}, function(response) {})
  }
  catch(e) {
    console.log(e);
  }
}
