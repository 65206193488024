import "./styles.css";
import React from "react";
import { ResponsiveContainer, PieChart, Pie, Label } from 'recharts';
import {ReactComponent as ShiftPrev} from "./images/SHIFT_Q_PREV.svg";
import {ReactComponent as ShiftNext} from "./images/SHIFT_Q_NEXT.svg";
import {POLL_SHARE_MODE, QUESTION_TYPES, TAB_PARTICIPANTS_CORRECT, TAB_PARTICIPANTS_INCORRECT, TAB_PARTICIPANTS_NO_RESPONSE, TAB_PARTICIPANTS_UNANSWERED} from "../../utils/constants";

export default function TabParticipantsLive({ correctAnswers, chartData=[], questionType, minimized, pollData, currQues, setCurrQues}) {

  let total = 0;
  let ratio = "";

  const correctData = [];
  const incorrectData = [];
  const unansData = [];

  const getColor = (str) => {
    switch (str) {
      case "Correct":
        return "#268726";
      case "Incorrect":
        return "#DA2C37";
      case "No Response":
      default:
        return "#776DC3";
    }
  };

  if (questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.SA.name) {
    let cor = {}, inc = {}, una = {};
    cor.name = TAB_PARTICIPANTS_CORRECT;
    cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
    cor.number = 0;
    inc.name = TAB_PARTICIPANTS_INCORRECT;
    inc.number = 0;
    inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
    una.name = TAB_PARTICIPANTS_NO_RESPONSE;
    una.number = 0;
    una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);

    for (const element of chartData) {
      if (element.ans === TAB_PARTICIPANTS_UNANSWERED) {
        una.number = element.people;
      } else if (element.ans === TAB_PARTICIPANTS_CORRECT) {
        cor.number = element.people;
      } else if(element.ans === TAB_PARTICIPANTS_INCORRECT) {
        inc.number = element.people;
      }
    }
    total = cor.number + inc.number + una.number;
    correctData.push(cor);
    incorrectData.push(inc);
    unansData.push(una);
  } else if (questionType === QUESTION_TYPES.MCSS.name) {
    let cor = {}, inc = {}, una = {};
    cor.name = TAB_PARTICIPANTS_CORRECT;
    cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
    cor.number = 0;
    inc.name = TAB_PARTICIPANTS_INCORRECT;
    inc.number = 0;
    inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
    una.name = TAB_PARTICIPANTS_NO_RESPONSE;
    una.number = 0;
    una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);

    for (const element of chartData) {
      if (element.ans === TAB_PARTICIPANTS_UNANSWERED) {
        una.number = element.people;
      } else if (correctAnswers.includes(element.name.toString().charAt(0).toLowerCase())) {
        cor.number = element.people;
      } else if (!correctAnswers.includes(element.name.toString().charAt(0).toLowerCase())) {
        inc.number = inc.number + element.people;
      }
    }
    total = cor.number + inc.number + una.number;
    correctData.push(cor);
    incorrectData.push(inc);
    unansData.push(una);
  } else if (questionType === QUESTION_TYPES.TF.name) {
    let cor = {}, inc = {}, una = {};
    cor.name = TAB_PARTICIPANTS_CORRECT;
    cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
    cor.number = 0;
    inc.name = TAB_PARTICIPANTS_INCORRECT;
    inc.number = 0;
    inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
    una.name = TAB_PARTICIPANTS_NO_RESPONSE;
    una.number = 0;
    una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);

    for (const element of chartData) {
      if (element.ans === TAB_PARTICIPANTS_UNANSWERED) {
        una.number = element.people;
      } else if (element.ans === correctAnswers) {
        cor.number = element.people;
      } else {
        inc.number = element.people;
      }
    }
    total = cor.number + inc.number + una.number;
    correctData.push(cor);
    incorrectData.push(inc);
    unansData.push(una);
  } else if (questionType === QUESTION_TYPES.CI.name) {
    let cor = {}, inc = {}, una = {};
    cor.name = TAB_PARTICIPANTS_CORRECT;
    cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
    cor.number = 0;
    inc.name = TAB_PARTICIPANTS_INCORRECT;
    inc.number = 0;
    inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
    una.name = TAB_PARTICIPANTS_NO_RESPONSE;
    una.number = 0;
    una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);

    for (const element of chartData) {
      if (element.ans === TAB_PARTICIPANTS_UNANSWERED) {
        una.number = element.people;
      } else if (correctAnswers.includes(element.name)) {
        cor.number = element.people;
      } else if (!correctAnswers.includes(element.name)) {
        inc.number = inc.number + element.people;
      }
    }
    total = cor.number + inc.number + una.number;
    correctData.push(cor);
    incorrectData.push(inc);
    unansData.push(una);
  } else if (questionType === QUESTION_TYPES.MH.name) {
    let cor = {}, inc = {}, una = {};
    cor.name = TAB_PARTICIPANTS_CORRECT;
    cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
    cor.number = 0;
    inc.name = TAB_PARTICIPANTS_INCORRECT;
    inc.number = 0;
    inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
    una.name = TAB_PARTICIPANTS_NO_RESPONSE;
    una.number = 0;
    una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);

    for (const element of chartData) {
      if (element.ans === TAB_PARTICIPANTS_UNANSWERED) {
        una.number = element.people;
      } else if (element.ans === TAB_PARTICIPANTS_CORRECT) {
        cor.number = element.people;
      } else {
        inc.number = element.people;
      }
    }
    total = cor.number + inc.number + una.number;
    correctData.push(cor);
    incorrectData.push(inc);
    unansData.push(una);
  }

  let corrNum = correctData[0].number;
  let incNum = incorrectData[0].number;
  let unansNum = unansData[0].number;
  ratio = Math.round(corrNum/total*100);
  correctData.push({name: "Total", number: total-corrNum, fill: "#F7F7F7"});
  incorrectData.push({name: "Total", number: total-incNum, fill: "#F7F7F7"});
  unansData.push({name: "Total", number: total-unansNum, fill: "#F7F7F7"});


  if(correctData[0].number === 0 && correctData[1].number === 0) {
    correctData[1].number = 1;
    ratio = 0;
  }
  if(incorrectData[0].number === 0 && incorrectData[1].number === 0) {
    incorrectData[1].number = 1;
  }
  if(unansData[0].number === 0 && unansData[1].number === 0) {
    unansData[1].number = 1;
  }

  return (<>
  {minimized && 
    <div style={{display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "space-between"}}>
      <div style={{display: "flex", flexDirection: "column"}}>
        <div className="responsePanelNumbers" style={{color: "#268726"}}>{corrNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: "#268726"}}></span>
          <div className="responsePanelText">Correct</div>
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "column"}}>
        <div className="responsePanelNumbers" style={{color: "#DA2C37"}}>{incNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: "#DA2C37"}}></span>
          <div className="responsePanelText">Incorrect</div>
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "column"}}>
        <div className="responsePanelNumbers" style={{color: "#776DC3"}}>{unansNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: "#776DC3"}}></span>
          <div className="responsePanelText">Unresponded</div>
        </div>
      </div>
    </div>
  }
  {!minimized &&
    <div style={{display: "flex", width: "100%", height: "100%", alignItems: "center"}}>
      <div style={{width: "50%", display: "flex", flexDirection: "column"}}>
        <div className="responsePanelNumbers" style={{color: "#268726"}}>{corrNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: "#268726"}}></span>
          <div className="responsePanelText">Correct</div>
        </div>
        <div className="responsePanelNumbers" style={{color: "#DA2C37", marginTop: "20px"}}>{incNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: "#DA2C37"}}></span>
          <div className="responsePanelText">Incorrect</div>
        </div>
        <div className="responsePanelNumbers" style={{color: "#776DC3", marginTop: "20px"}}>{unansNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: "#776DC3"}}></span>
          <div className="responsePanelText">Unresponded</div>
        </div>
      </div>
      <div style={{width: "70%", height: "100%"}}>
        <ResponsiveContainer className={(pollData.poll.pollShareMode === POLL_SHARE_MODE.SHARE_ALL && pollData.questions.length > 1) ? "resultsCont1" : "resultsCont2"}>
          <PieChart>
            <Pie
                data={unansData}
                innerRadius={40}
                outerRadius={50}
                paddingAngle={2}
                dataKey="number"
            >
            </Pie>
            <Pie
                data={incorrectData}
                innerRadius={52}
                outerRadius={62}
                paddingAngle={2}
                dataKey="number"
            >
            </Pie>
            <Pie
                data={correctData}
                innerRadius={64}
                outerRadius={74}
                paddingAngle={2}
                dataKey="number"
            >
              <Label className="responsePanelPercent" position="center">{ratio+"%"}</Label>
            </Pie>
          </PieChart>
        </ ResponsiveContainer>
        {pollData.poll.pollShareMode === POLL_SHARE_MODE.SHARE_ALL && pollData.questions.length > 1 &&
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px"}} className="shiftQText">
            <ShiftPrev style={{marginRight: "10px", cursor: "pointer"}} onClick={() => {setCurrQues(currQues === 1 ? 1 : currQues-1)}}/>Question {currQues}
            <ShiftNext style={{marginLeft: "10px", cursor: "pointer"}} onClick={() => {setCurrQues(currQues === pollData.questions.length ? currQues : currQues+1)}}/>
          </div>
        }
      </div>
    </div>
  }
  </>);
}
