import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import FormModal from "../../components/modals/FormModal";
import { useLoading } from "../../utils/LoadingContext";
import BlankContent from "./BlankContent";
import styles from './CourseContent.module.css';
import CourseMembersContent from "./CourseMembersContent";
import { TEXTBOX } from "../../utils/constants";

export default function CourseContentPage() {
    // const history = useHistory();
    // const isMobileScreen = useMobileAccess();
    const {courseId} = useParams();
    const [courseData, setCourseData] = useState(null);
    const [courseMembers, setCourseMembers] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [displayToggle, setDisplayToggle] = useState(true)
    const [memberOptions, setMemberOptions] = useState([]);
    const [selectedMemberOpts, setSelectedMemberOpts] = useState([]);
    const [courseMemberSelectedError, setCourseMemberSelectedError] = useState("");
    // const { getPollsFromCourse } = PollApiCalls();
    const { getCourseById, saveCourseMembers } = CourseApiCalls();
    const { loadUsers } = UserApiCalls();
    // const [filter, setFilter] = useState({});
    // const [pollData, setPollData] = useState([]);
    // const [courseMetaData, setCourseMetaData] = useState({});
    // const [dataReady, setDataReady] = useState(false);


    // const settings = useMemo(() => {
    //     return {
    //         'menuBoxType': 'lightBlue',
    //         'textTitle': 'title',
    //         'textLeftAlign': 'left',
    //         'textCenterAlign': 'center',
    //         'btnDisabled': false,
    //     };
    // }, []);

    const getCourse = async () => {
        getCourseById(courseId).then(res => {
            const {courseMembers=[]} = res.result;
            setCourseData(res.result);
            setCourseMembers(courseMembers);
        });
    };

    useEffect(() => {
        getCourse();
    }, []);

    useEffect(() => {
        loadUsers(true).then(res => {
            const ownedMemberIdSet = new Set(courseMembers.map(m => m.userId));
            const options = res.map(user => ({value: user.userId, label: `${user.userName}(${user.email})`}));
            const selectedOptions = options.filter(opt => ownedMemberIdSet.has(opt.value));
            setMemberOptions(options);
            setSelectedMemberOpts(selectedOptions);
        });
    }, [courseMembers]);

    const clearForm = () => {
        setCourseMemberSelectedError("");
        setModalShow(false);
    };

    const handleSave = async () => {
        console.log(selectedMemberOpts);
        setCourseMemberSelectedError("");

        const ownedMemberIdSet = new Set(courseMembers.map(m => m.userId));
        const selectedMemberIds = selectedMemberOpts.map(opt => opt.value);

        if (selectedMemberOpts.length === courseMembers.length) {
            const changedIdArr = selectedMemberIds.filter(id => !ownedMemberIdSet.has(id));
            if (changedIdArr.length === 0) {
                setModalShow(false);
                return;
            }
        }
        const res = await saveCourseMembers(courseId, selectedMemberIds);
        if (!res) {
            return;
        }

        getCourse();
        setModalShow(false);
    };

    const modalConfig = {
        title: "Add Course Members",
        subText: "Please choose the members you want to add in this course.",
        submitButtonText: "Save",
        clearForm: clearForm,
        submit: handleSave,

        form: [
            {
                label: "Course Members",
                value: selectedMemberOpts,
                options: memberOptions,
                isMulti: true,
                setFunction: setSelectedMemberOpts,
                error: courseMemberSelectedError,
                type: "select4",
            },
        ],
    };

    const toAddMembers = () => {
        const ownedMemberIdSet = new Set(courseMembers.map(m => m.userId));
        const selectedOptions = memberOptions.filter(opt => ownedMemberIdSet.has(opt.value));
        setSelectedMemberOpts(selectedOptions);
        setModalShow(true);
    };

    // const coursePollsPage = () => {
    //     history.push("/poll/course/" + courseId)
    // }

    // const getPollInformation = async (courseId) => {
    //     setDataReady(false);
    //     setLoading(true);
    //     const res = await getPollsFromCourse(courseId);
    //     res.sort(function (a, b) {
    //       return new Date(Date.parse(b.updatedTimeSK)) - new Date(Date.parse(a.updatedTimeSK));
    //     });
    //     setPollData(res);
    //     setLoading(false);
    //     setDataReady(true);
    // };
      
    // const getCourseInfo = async (courseId) => {
    //     setDataReady(false);
    //     setLoading(true);
    //     const result = await getCourseById(courseId);
    //     setCourseMetaData(result.result);
    //     setLoading(false);
    //     setDataReady(true);
    // };
    
    // useEffect(() => {
    //     getPollInformation(courseId);
    //     getCourseInfo(courseId);
    // }, [courseId]);
    
    // const handleSubmit = useCallback(({ ...filterObj }) => {
    //     setFilter(filterObj);
    // }, []);
        
    // const columns = [
    //     { Header: 'Date', accessor: 'updatedDatePK', width: isMobileScreen ? 100 : 120 },
    //     { Header: 'Poll Title', accessor: 'pollTitle', width: 250 },
    //     { Header: 'Poll Status', accessor: 'pollSortKey', width: isMobileScreen ? 120 : 140 },
    //     { Header: 'Questions', accessor: 'totalQuestions', width: isMobileScreen ? 100 : 120 },
    //     // { Header: 'Edit', accessor: 'pollKey', width: 100, id: 'edit' },
    //     // { Header: 'Publish', accessor: 'pollKey', width: 100 }
    //     { Header: 'Action', accessor: 'pollKey', width: 100, id: 'action' }
    // ];
    
    // const clickCreate = () => {
    //     history.push({ pathname: "/create" });
    // };

    return (
        <div className={styles.centerLayout}>
            <FormModal
                isLoading={false}
                show={modalShow}
                setModalShow={setModalShow}
                modalConfig={modalConfig}
            />
            <div className={`${styles.menuBarWrapper}`}>
                <div tabIndex={0} role={TEXTBOX} className={`${styles.menubar} ${styles.menubarText}`}>
                    {courseData && (courseData?.courseCode ? `${courseData.courseCode}: ${courseData.courseName}` : courseData.courseName)}
                </div>
                {/* <div className={`${styles.menubarBtnBar} ${styles.menubarBtnLayout}`}>
                    <NormalButton className={styles.menubarBtn} onClick={toAddMembers}>Add Course Members</NormalButton>
                    <NormalButton className={styles.menubarBtn} onClick={coursePollsPage} disabledFlag={settings.btnDisabled}>View Existing Polls</NormalButton>
                </div> */}
                <div className={`${styles.subMenubar}`}>
                    <div
                        tabIndex={0} role={TEXTBOX}
                        className={`${displayToggle ? styles.activeSubMenuOption : styles.subMenuOption}`}
                        // onClick={() => setDisplayToggle(true)}
                    >
                        Course Members
                    </div>
                    {/* <div className={`${!displayToggle ? styles.activeSubMenuOption : styles.subMenuOption}`} onClick={() => setDisplayToggle(false)}>
                        Existing Polls
                    </div> */}
                </div>
            </div>

            <div className={`${styles.bodyWrapper}`}>
                {courseMembers && courseMembers.length > 0 ?
                    (<CourseMembersContent courseMembers={courseMembers} addMembers={toAddMembers}/>) :
                    <BlankContent addMembers={toAddMembers}/>
                }
            </div>
        </div>
    );
}