import React, { useEffect, useState } from "react";
import { Form, Card } from "react-bootstrap";
import { InstitutionApiCalls } from "../../../../apis/InstitutionApiCalls";
import { CREATION_ROLES, INTEGRATION_TYPES, INTEGRATION_TYPES_LOWERCASE,ROLE_MAPPING,
  blackboardRoles, canvasRoles, moodleRoles, d2lRoles, LTI_USER_MAPPING,
  API_CONFIG, ISS, CLIENT_ID, USER_CONFIG, AUTO_PROVISION_COURSE, AUTO_SYNC_COURSE, AUTO_SYNC_USER, AUTO_PROVISION_USER,
  OIDC, JWKS, OAUTH, DEPLOYMENT, COURSE_CONFIG, API_ACCESS, TEXTBOX, INTEG_TEXT_BOX} from "../../../../utils/constants";
import styles from "./LTI3.module.css";
import {YuJaCheckbox} from "../../../../components/standardization/YuJaCheckbox";
import {YuJaTextBox} from "../../../../components/standardization/YuJaTextBox";
import {YuJaDropdown} from "../../../../components/standardization/YuJaDropdown";
import crossIntegrationLTI from "../../../../images/crossIntegrationLTI.svg";
import roleMapping from "../../../../images/roleMapping.svg";
import userProvisioning from "../../../../images/userProvisioning.svg";
import LTiIcon from "../../../../images/ltiicon.svg";
import generalIcon from "../../../../images/generalIcon.svg";

export default function GeneralLTIPage({selectedMenu, canvasForm, blackboardForm, d2lForm, moodleForm, setD2lForm, setBlackboardForm, setCanvasForm, setMoodleForm}) {
  const [loaded, setLoaded] = useState(false);
  const { getSSODetails } = InstitutionApiCalls();

  const getIntegrationType = () => {
    if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) {return INTEGRATION_TYPES.BLACKBOARD}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {return INTEGRATION_TYPES.CANVAS}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {return INTEGRATION_TYPES.MOODLE}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {return INTEGRATION_TYPES.D2L}
  };

  const getSSOMap = (ssoMap) => {
    if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) {return ssoMap.BLACKBOARD}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {return ssoMap.CANVAS}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {return ssoMap.MOODLE}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {return ssoMap.D2L}
  };

  const fetchData = () => {
    getSSODetails().then((result) => {
      let ssoMap = result.ssoConfig;
      const ssoConfigData = JSON.parse(
        ssoMap.hasOwnProperty(getIntegrationType())
          ? getSSOMap(ssoMap)
          : "{}"
      );
      if (
        ssoConfigData !== "undefined" ||
        ssoConfigData.propertyIsEnumerable()
      ) {
        if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) { setBlackboardForm(ssoConfigData); }
        else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) { setCanvasForm(ssoConfigData); }
        else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) { setMoodleForm(ssoConfigData); }
        else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) { setD2lForm(ssoConfigData); }
      }
    });
  };

  useEffect(() => {
    fetchData();
    setLoaded(true);
    if(document.getElementById("lmsLastElem") != null) {
      document.getElementById("lmsLastElem").addEventListener('keydown', function (e) {
          if (e.key === 'Tab' && !e.shiftKey && document.getElementById("integrationSaveBtn")) {
            e.preventDefault();
            document.getElementById('integrationSaveBtn').focus();
          }
      });
  }
  }, [loaded, selectedMenu]);

  const handleBlackboardFormChange = (e) => {
    const input = e.currentTarget;
    const blackboardFormConst = Object.assign({}, blackboardForm);
    if (input.type === "checkbox") {
      blackboardFormConst[input.name] = input.checked;
    } else {
      blackboardFormConst[input.name] = input.value;
    }
    setBlackboardForm(blackboardFormConst);
  };

  const handleCanvasFormChange = (e) => {
    const input = e.currentTarget;
    const canvasFormConst = Object.assign({}, canvasForm);
    if(input.type === "checkbox") {
        canvasFormConst[input.name] = input.checked;
    } else {
        canvasFormConst[input.name] = input.value;
    }
    setCanvasForm(canvasFormConst);
  };

  const handleD2lFormChange = (e) => {
    const input = e.currentTarget;
    const d2lFormConst = Object.assign({}, d2lForm);
    if (input.type === "checkbox") {
      d2lFormConst[input.name] = input.checked;
    } else {
      d2lFormConst[input.name] = input.value;
    }
    setD2lForm(d2lFormConst);
  };

  const handleMoodleFormChange = (e) => {
    const input = e.currentTarget;
    const moodleFormConst = Object.assign({}, moodleForm);
    if (input.type === "checkbox") {
      moodleFormConst[input.name] = input.checked;
    } else {
      moodleFormConst[input.name] = input.value;
    }
    setMoodleForm(moodleFormConst);
  };

  const getValueBlackboard = (element) => {
    if(element.display === LTI_USER_MAPPING.STUDENT) {return blackboardForm.studentRole}
    else if(element.display === LTI_USER_MAPPING.INSTRUCTOR) {return blackboardForm.instructorRole}
    else if(element.display === LTI_USER_MAPPING.GRADER) {return blackboardForm.graderRole}
    else if(element.display === LTI_USER_MAPPING.COURSE_BUILDER) {return blackboardForm.courseBuilderRole}
    else if(element.display === LTI_USER_MAPPING.TEACHING_ASSISTANT) {return blackboardForm.teachingAssistantRole}
    else if(element.display === LTI_USER_MAPPING.GUEST) {return blackboardForm.guestRole}
    else if(element.display === LTI_USER_MAPPING.ADMIN) {return blackboardForm.adminRole}
    else if(element.display === LTI_USER_MAPPING.OTHER) {return blackboardForm.otherRole}
  };

  const getValueCanvas = (element) => {
    if(element.display === LTI_USER_MAPPING.ADMIN) {return canvasForm.adminRole}
    else if(element.display === LTI_USER_MAPPING.STUDENT) {return canvasForm.studentRole}
    else if(element.display === LTI_USER_MAPPING.TEACHER) {return canvasForm.teacherRole}
    else if(element.display === LTI_USER_MAPPING.TEACHING_ASSISTANT) {return canvasForm.teachingAssistantRole}
    else if(element.display === LTI_USER_MAPPING.DESIGNER) {return canvasForm.designerRole}
    else if(element.display === LTI_USER_MAPPING.OBSERVER) {return canvasForm.observerRole}
  };

  const getValueMoodle = (element) => {
    if(element.display === LTI_USER_MAPPING.STUDENT_COURSE) {return moodleForm.studentRole}
    else if(element.display === LTI_USER_MAPPING.TEACHER_MANAGER) {return moodleForm.instructorRole}
    else if(element.display === LTI_USER_MAPPING.ADMIN) {return moodleForm.adminRole}
  };

  const getValueD2L = (element) => {
    if(element.display === LTI_USER_MAPPING.STUDENT_COURSE) {return d2lForm.studentRole}
    else if(element.display === LTI_USER_MAPPING.INSTRUCTOR) {return d2lForm.instructorRole}
    else if(element.display === LTI_USER_MAPPING.ADMIN) {return d2lForm.adminRole}
  };

  const callUpdateFunction = (e) => {
    if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) {handleBlackboardFormChange(e);}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {handleCanvasFormChange(e);}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {handleMoodleFormChange(e);}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {handleD2lFormChange(e);}
  };

  const getForm = () => {
    if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD) {return blackboardForm;}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS) {return canvasForm;}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE) {return moodleForm;}
    else if (selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L) {return d2lForm;}
  };

  return (<div>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
      <Card className={styles.smallBox}>
        <Card.Header>
            <img src={roleMapping} alt="RoleMapping" width={"24px"} height={"24px"} />
            <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>User {ROLE_MAPPING}</span>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div tabIndex={0} className="form-labelReg">{selectedMenu} Course Role</div>
                <div tabIndex={0} className="form-labelReg" style={{marginRight: "58px"}}>YuJa Role</div>
              </div>
              <div>
                {selectedMenu === INTEGRATION_TYPES_LOWERCASE.BLACKBOARD && blackboardRoles.map(element => <>
                  {element.display === LTI_USER_MAPPING.ADMIN && <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div tabIndex={0} className="form-label">{selectedMenu} System Role</div>
                    <div tabIndex={0} className="form-label" style={{marginRight: "58px"}}>YuJa Role</div>
                  </div>}
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "9px"}} key={element.display}>
                    <div tabIndex={0} className={styles.labelDesc}>{element.display}</div>
                    <img src={LTiIcon} alt="LTiIcon" width={"32px"} height={"32px"} />
                    <YuJaDropdown
                      containerStyle={{width: 150}}
                      data={Object.values(CREATION_ROLES)}
                      value={getValueBlackboard(element)}
                      getOptionLabel={item => item.display}
                      onChange={item => {
                        const e = {currentTarget: {value: item.value, name: element.value}};
                        handleBlackboardFormChange(e);
                      }}
                    />
                  </div>
                </>)}
                {selectedMenu === INTEGRATION_TYPES_LOWERCASE.CANVAS && canvasRoles.map(element =>
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "9px"}} key={element.display}>
                    <div tabIndex={0} className={styles.labelDesc}>{element.display}</div>
                    <img src={LTiIcon} alt="LTiIcon" width={"32px"} height={"32px"} />
                    <YuJaDropdown
                      containerStyle={{width: 150}}
                      data={Object.values(CREATION_ROLES)}
                      value={getValueCanvas(element)}
                      getOptionLabel={item => item.display}
                      onChange={item => {
                        const e = {currentTarget: {value: item.value, name: element.value}};
                        handleCanvasFormChange(e);
                      }}
                    />
                  </div>
                )}
                {selectedMenu === INTEGRATION_TYPES_LOWERCASE.MOODLE && moodleRoles.map(element =>
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "9px"}} key={element.display}>
                    <div tabIndex={0} className={styles.labelDesc}>{element.display}</div>
                    <img src={LTiIcon} alt="LTiIcon" width={"32px"} height={"32px"} />
                    <YuJaDropdown
                      containerStyle={{width: 150}}
                      data={Object.values(CREATION_ROLES)}
                      value={getValueMoodle(element)}
                      getOptionLabel={item => item.display}
                      onChange={item => {
                        const e = {currentTarget: {value: item.value, name: element.value}};
                        handleMoodleFormChange(e);
                      }}
                    />
                  </div>
                )}
                {selectedMenu === INTEGRATION_TYPES_LOWERCASE.D2L && d2lRoles.map(element =>
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "9px"}} key={element.display}>
                    <div tabIndex={0} className={styles.labelDesc}>{element.display}</div>
                    <img src={LTiIcon} alt="LTiIcon" width={"32px"} height={"32px"} />
                    <YuJaDropdown
                      containerStyle={{width: 150}}
                      data={Object.values(CREATION_ROLES)}
                      value={getValueD2L(element)}
                      getOptionLabel={item => item.display}
                      onChange={item => {
                        const e = {currentTarget: {value: item.value, name: element.value}};
                        handleD2lFormChange(e);
                      }}
                    />
                  </div>
                )}
              </div>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      <Card className={styles.smallBox}>
        <Card.Header>
            <img src={crossIntegrationLTI} alt="CrossIntegrationLTI" width={"24px"} height={"24px"} />
            <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{API_CONFIG}</span>
        </Card.Header>
        <Card.Body>
            <Form>
                <Form.Group>
                    <div className="form-label">ISS</div>
                    <YuJaTextBox
                      label={ISS + INTEG_TEXT_BOX}
                      placeholder={ISS}
                      name="iss"
                      value={getForm().iss}
                      containerStyle={{fontSize: 14, backgroundColor: "#FFF"}}
                      onChange={callUpdateFunction}
                    />
                </Form.Group>
                <Form.Group>
                    <div className="form-label">Client Id</div>
                    <YuJaTextBox
                      label={CLIENT_ID + INTEG_TEXT_BOX}
                      placeholder={CLIENT_ID}
                      name="clientId"
                      value={getForm().clientId}
                      containerStyle={{fontSize: 14, backgroundColor: "#FFF"}}
                      onChange={callUpdateFunction}
                    />
                </Form.Group>
                <Form.Group>
                    <div className="form-label">OIDC Endpoint</div>
                    <YuJaTextBox
                      label={OIDC + INTEG_TEXT_BOX}
                      placeholder={OIDC}
                      name="oidcEndpoint"
                      value={getForm().oidcEndpoint}
                      containerStyle={{fontSize: 14, backgroundColor: "#FFF"}}
                      onChange={callUpdateFunction}
                    />
                </Form.Group>
                <Form.Group>
                    <div className="form-label">JWKS Endpoint</div>
                    <YuJaTextBox
                      label={JWKS + INTEG_TEXT_BOX}
                      placeholder={JWKS}
                      name="jwksEndpoint"
                      value={getForm().jwksEndpoint}
                      containerStyle={{fontSize: 14, backgroundColor: "#FFF"}}
                      onChange={callUpdateFunction}
                    />
                </Form.Group>
                <Form.Group>
                    <div className="form-label">OAuth2 Token Endpoint</div>
                    <YuJaTextBox
                      label={OAUTH + INTEG_TEXT_BOX}
                      placeholder={OAUTH}
                      name="oAuth2Endpoint"
                      value={getForm().oAuth2Endpoint}
                      containerStyle={{fontSize: 14, backgroundColor: "#FFF"}}
                      onChange={callUpdateFunction}
                    />
                </Form.Group>
                <Form.Group>
                    <div className="form-label">Deployment Id</div>
                    <YuJaTextBox
                      placeholder={DEPLOYMENT}
                      name="deploymentId"
                      value={getForm().deploymentId}
                      containerStyle={{fontSize: 14, backgroundColor: "#FFF"}}
                      onChange={callUpdateFunction}
                    />
                </Form.Group>
            </Form>
        </Card.Body>
      </Card>
      <div style={{display: "flex", flexDirection: "column"}}>
        <Card className={styles.smallBox}>
          <Card.Header>
              <img src={userProvisioning} alt="User Provisioning" width={"24px"} height={"24px"} />
              <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{USER_CONFIG}</span>
          </Card.Header>
          <Card.Body>
              <Form>
                <Form.Group controlId="autoProvisionUser" style={{margin: "5px 0"}}>
                  <YuJaCheckbox name="autoProvisionUser" checked={getForm().autoProvisionUser} onClick={callUpdateFunction} label={AUTO_PROVISION_USER}/>
                </Form.Group>
                <Form.Group controlId="autoSyncData" style={{margin: "5px 0"}}>
                  <YuJaCheckbox name="autoSyncData" checked={getForm().autoSyncData} onClick={callUpdateFunction} label={AUTO_SYNC_USER}/>
                </Form.Group>
              </Form>
          </Card.Body>
        </Card>
        <Card className={styles.smallBox}>
          <Card.Header>
              <img src={generalIcon} alt="CourseConfig" width={"24px"} height={"24px"} />
              <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{COURSE_CONFIG}</span>
          </Card.Header>
          <Card.Body>
              <Form>
                <Form.Group controlId="autoProvisionCourse" style={{margin: "5px 0"}}>
                  <YuJaCheckbox name="autoProvisionCourse" checked={getForm().autoProvisionCourse} onClick={callUpdateFunction} label={AUTO_PROVISION_COURSE}/>
                </Form.Group>
                <Form.Group controlId="autoSyncCourseData" style={{margin: "5px 0"}}>
                  <YuJaCheckbox id="lmsLastElem" name="autoSyncCourseData" checked={getForm().autoSyncCourseData} onClick={callUpdateFunction} label={AUTO_SYNC_COURSE}/>
                </Form.Group>
              </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  </div>
  );
}
