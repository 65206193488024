import React from "react";
import { Col, Row } from "react-bootstrap";
import CancelIcon from "../../images/cancel.svg";
import FITBAddIcon from "../../images/new_fitb_add.svg";
import FITBAddIconHover from "../../images/new_fitb_add_hover.svg";
import { stringifyNumber } from "../../utils/helpers";
import {BUTTON, FITB_ADD, FITB_ANSWER_LENGTH, FITB_DELETE} from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import { FITB_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";

export default function FITBAnswer({
  answerKey,
  answerValue,
  handleAddAnswer,
  handleUpdateAnswer,
  handleDeleteAnswer,
  index,
}) {
  return (
    <Row className="fitb-row">
      {/* <Col className="margin-vertical-auto ">
        <div
          className="text-container-with-counter"
        >
          <Row>
              <Col>
                <p className="fitb-bold">{answerKey}:</p>{" "}
                <input
                  style={{
                    fontWeight: 400,
                    textAlign: "left",
                    backgroundColor: "#FFFFFF",
                    color: "#6c6c6c",
                  }}
                  className="fitb-bold focus-outline-none "
                  type="text"
                  placeholder={
                    index === 0
                      ? "Type Correct Answer"
                      : stringifyNumber(index + 1) + " Answer"
                  }
                  value={answerValue}
                  onChange={(e) => {
                    handleUpdateAnswer(answerKey, index, e.target.value);
                  }}
                  maxLength={FITB_ANSWER_LENGTH}
                />
            
              <Col className="center margin-center fitb-add-answer-col">
                <img
                  src={FITBAddIcon}
                  alt="fitb add"
                  title={"Add more Answer"}
                  className="cursor-pointer"
                  onClick={() => handleAddAnswer(answerKey)}
                />
              </Col>
            </Col>
          </Row>
        </div>
      </Col> */}
      <Col className="margin-vertical-auto" style={{paddingRight: "0px"}}>
        <div style={{display: "flex", gap: "10px"}}>
          <YuJaTextBox
              label={answerKey + " Type Correct Answer"}
              name={"question-text"}
              placeholder={
                  index === 0
                      ? "Type Correct Answer"
                      : stringifyNumber(index + 1) + " Answer"
              }
              maxLength={FITB_ANSWER_LENGTH}
              value={answerValue}
              onChange={(e) => {
                  if (e.target.value.length <= FITB_ANSWER_LENGTH) {
                      handleUpdateAnswer(answerKey, index, e.target.value);
                  } else {
                      notifyError(FITB_ANSWER_LENGTH_EXCEEDED);
                      handleUpdateAnswer(answerKey, index, e.target.value.substring(0, FITB_ANSWER_LENGTH));
                  }
              }}
              containerStyle={{fontSize: 18, padding: "5px"}}
              before={<span className="fitb-bold" style={{marginLeft: 10}}>{answerKey}:{" "}</span>}
              after={
                  <>
                      <img
                          aria-label={FITB_ADD + answerKey}
                          tabIndex={0}
                          role={BUTTON}
                          src={FITBAddIcon}
                          alt="fitb add"
                          title={"Add more Answer"}
                          className="cursor-pointer"
                          onClick={(e) => {
                              e.stopPropagation();
                              handleAddAnswer(answerKey);
                          }}
                          style={{width: "24px", fill: "blue", float: "right"}}
                          onMouseOver={(e) => {
                              e.currentTarget.src = FITBAddIconHover
                          }}
                          onMouseOut={(e) => {
                              e.currentTarget.src = FITBAddIcon
                          }}
                      />
                  </>
              }
          />
          <img
                          aria-label={FITB_DELETE + answerKey}
                          onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteAnswer(answerKey, index);
                          }}
                          src={CancelIcon}
                          style={{width: 38}}
                          alt="delete icon"
                          tabIndex={0}
                          role={BUTTON}
                          title={"Delete Answer"}
                          className="questions-page-icons cursor-pointer"
                      />
      </div>
      </Col>
        {/* <img
          onClick={() => handleDeleteAnswer(answerKey, index)}
          src={CancelIcon}
          alt="delete icon"
          title={"Delete Answer"}
          className="questions-page-icons cursor-pointer"
        /> */}
    </Row>
  );
}
