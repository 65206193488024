import React from "react";
import { Table } from "react-bootstrap";
import "./styles.css";

export default function EmptyTable() {
  return (
    <Table striped hover size="sm" className="border ">
      <thead>
        <tr>
          <th>Username</th>
          <th className=" border-right">Score</th>
        </tr>
      </thead>
      <tbody>
        <tr className="sticky-second-header">
          <td colSpan={2}>No response have been submitted yet!</td>
        </tr>
      </tbody>
    </Table>
  );
}
