import React, {useEffect, useRef, useState} from "react";
import SmallGreenCheck from "../../images/SmallGreenCheck.svg";
import {ASCII_CODE_A, BLANK_REGEX, CI_COORDINATE_SIZE, POLL_TYPE, QUESTION_TYPES} from "../../utils/constants";
import "./styles.css";
import {S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME} from "../../utils/properties";
import {fetchImg} from "../../utils/helpers";
import {useParams} from "react-router-dom";
import {Rnd} from "react-rnd";
import {decodeCIParam} from "../../utils/questionUtils";
import {ReactComponent as COORDINATE} from "../../images/coordiante.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import WordCloudResult from "../JoinQuizPage/WordCloudResult";
import ArrowIcon from "../../images/arrow_right.svg"; 


export default function TabResults({ chartData=[], institutionId, pollType, question, points}) {
  // console.log(chartData);
  const {correctAnswers, optionsMap, queTitle, serialNo, questionType, suffix, image} = question;
  const {pollKey, } = useParams();
  const [questionImageURL, setQuestionImageURL] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef(null);
  const [, setNumOptions] = useState(3);
  const [pastCorrAns, setPastCorrAns] = useState(0);
  const [showWordCloud, setShowWordCloud] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  const removeHighlight = () => {
    if(document.getElementById("Option"+pastCorrAns) !== null) {
      document.getElementById("Option"+pastCorrAns).style.backgroundColor = "";
      document.getElementById("Option"+pastCorrAns).style.color = "black";
      document.getElementById("CheckMark"+pastCorrAns).style.visibility = "hidden";
    }
  }

  const handleCorrectAnswer = () => {
    let index = 0;
    if(document.getElementById("Option"+index) !== null) {
      if(QUESTION_TYPES.MCSS.name === questionType) {
        index = correctAnswers[0].charCodeAt(0)-97;
        document.getElementById("Option"+index).style.backgroundColor = "#2b992b";
        document.getElementById("Option"+index).style.color = "white";
        document.getElementById("CheckMark"+index).style.visibility = "visible";
      }
      else if(QUESTION_TYPES.TF.name === questionType) {
        if(correctAnswers === "False") {
          index = 1;
        }
        document.getElementById("Option"+index).style.backgroundColor = "#2b992b";
        document.getElementById("Option"+index).style.color = "white";
        document.getElementById("CheckMark"+index).style.visibility = "visible";
      }
      else if(QUESTION_TYPES.CI.name === questionType) {
        index = correctAnswers[0];
        document.getElementById("Option"+index).style.backgroundColor = "#2b992b";
        document.getElementById("Option"+index).style.color = "white";
        document.getElementById("CheckMark"+index).style.visibility = "visible";
      }
      else {
        document.getElementById("Option"+index).style.backgroundColor = "#2b992b";
        document.getElementById("Option"+index).style.color = "white";
        document.getElementById("CheckMark"+index).style.visibility = "visible";
      }
      setPastCorrAns(index);
    }
  }

  useEffect(() => {
    setNumOptions(questionType === QUESTION_TYPES.MCSS.name || questionType === QUESTION_TYPES.CI.name
        ? (chartData.length) : questionType === QUESTION_TYPES.SA.name ? 4 : 3);
    if (pollType === POLL_TYPE.GRADED_POLL) {
      removeHighlight();
      handleCorrectAnswer();
    }
  }, [chartData]);

  useEffect(() => {
    setIsLoaded(false);
    setQuestionImageURL("");
    console.log(image, suffix, institutionId, pollKey, serialNo, question);
    async function fetchData() {
      if(question.directLinkEvp) {
        setQuestionImageURL(question.directLinkEvp);
        setIsVideo(true);
      }
      else if ((!!image || !!suffix) && institutionId && pollKey && serialNo) {
        let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;

        url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
        let img = await fetchImg(url);
        setQuestionImageURL(img ? img : "");
        setTimeout(() => {
          setShowWordCloud(true);
        }, 50);
        setIsVideo(false);
      } else if (!!question) {
        setShowWordCloud(true);
      }
    }
    fetchData();
  }, [suffix, serialNo, institutionId, pollKey, question]);

  const CustomizedBarShape = ({ fill, width, height }) => (
      <div
          style={{
            backgroundColor: fill,
            borderRadius: "10px",
            height: height,
            width: width - 5,
          }}
      >
      </div>
  );

  const Shape = (props) => {
    const { fill, x, y, width, height, ans, people, name } = props;
    let isCorrect;

    switch (questionType) {
      case  QUESTION_TYPES.MCSS.name:
        if(correctAnswers){
          isCorrect = correctAnswers.includes(name.toString().charAt(0).toLowerCase());
        } else {
          isCorrect = false;
        }
        break;
      case  QUESTION_TYPES.TF.name:
        if(correctAnswers){
          isCorrect = name.toString().includes(correctAnswers);
        } else {
          isCorrect = false;
        }
        break;
      case  QUESTION_TYPES.FITB.name:
      case  QUESTION_TYPES.SA.name:
        isCorrect = /^Correct. \w+$/.test(name);
        break;
      case QUESTION_TYPES.CI.name:
        isCorrect = correctAnswers.includes(name);
        break;
      default:
        isCorrect = false;
        break;
    }

    return (<>
      {/* <div style={{marginTop:"5px", marginBotton:"5px"}}> */}
      <foreignObject x={x} y={y} width={"100%"} height={height > 0 ? height : 52} fill={fill}>
        {isCorrect ?
            <p className={pollType !== POLL_TYPE.SURVEY ? "bar-title bar-title-c" : "bar-title bar-title-i"} height={30} width={250} style={{marginTop: "5px", marginBottom: "5px"}}>
              {ans}{pollType !== POLL_TYPE.SURVEY ? <img src={SmallGreenCheck} alt="small-green-check"/> : <></>}
            </p> :
            <p className="bar-title bar-title-i" height={30} width={250} style={{marginTop: "5px", marginBottom: "5px"}}>{ans}</p>}
      </foreignObject>
      {/* </div> */}
      <foreignObject x={x} y={y + 24 + 10} width={width} height={12} fill={fill} >
        <CustomizedBarShape height={12} fill={fill} width={width} people={people} />
      </foreignObject>
    </>);
  };

  const renderTitle = () => {
    if (questionType === QUESTION_TYPES.FITB.name) {
      return (<div style={{fontFamily: "Geomanist", fontWeight: "400", fontSize: "18px", lineHeight: "18px", letterSpacing: "0.035em", color: "#000000"}}>{queTitle.replace(BLANK_REGEX, "______")}</div>)
    }
    return (<div style={{fontFamily: "Geomanist", fontWeight: "400", fontSize: "18px", lineHeight: "18px", letterSpacing: "0.035em", color: "#000000"}}>{queTitle}</div>);
  };

  const onLoad = () => {
    setIsLoaded(true);
  }


  return (<>
    <div className="question-tab-body">
      {renderTitle()}
      <div> 

      {questionType === QUESTION_TYPES.MH.name && (
                <div> 
                <div style = {{ display: "flex", maxHeight: "140px"}}> 
                <div style={{display: "flex", overflowY: "auto", flex: "1"}}> 
                <div style={{flex: "1"}}> 
                {correctAnswers.map((element, index) => (
                  <>
                  {element.length > 1 ? 
                <div key={"Correct" + index} id={"CorrectOption" + index} className="questionPanelOptionsContainer" style={{marginBottom: "0px", gap: "20px"}}> 
                    <div className="questionPanelAnswersText" id={"CorrectPart1" + index} style={{marginRight: "0px", border: "3px solid #909090", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1}}>{element[0]}</div>
                    <img
                                 src={ArrowIcon}
                                 alt="Arrow icon"
                                 className="questions-page-icons cursor-pointer"
                                 style={{ width: "30px", margin: "0px 0px 0px 10px" }}
                             />
                    <div className="questionPanelAnswersText" id={"CorrectPart2" + index} style={{border: "3px solid green", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1}}>{element[1]}</div>
                  </div> : <></>}
                  </>
            ))}
            </div>
            </div>
            {questionImageURL && <img ref={imageRef} style={{ maxWidth: "250px", maxHeight: "250px"}} src={questionImageURL} onLoad={onLoad} alt="Question" />}
            </div> 
            </div> 
                
            )
            }
      </div>

      <div style={{display: "flex", alignItems: "center"}}>
      <div stlye={{flex: 8, alignItems: "center"}}>
      {questionImageURL && questionType !== QUESTION_TYPES.MH.name && (
          <div className="img-container" >
            <div style={{position: "relative"}}>
              {!isVideo && <img ref={imageRef} style={{ maxWidth: "250px", maxHeight: "250px"}} src={questionImageURL} onLoad={onLoad} alt="Question" />}
              {isVideo && <iframe allowFullScreen style={{ maxWidth: "250px", maxHeight: "250px"}} src={questionImageURL} alt="Question" />}
              {isLoaded && questionType === QUESTION_TYPES.CI.name &&
                  optionsMap.map((area, idx) =>
                      <Rnd
                          key={idx}
                          id={`option-area-${idx}`}
                          size={{
                            width: decodeCIParam(area.width, imageRef.current.width),
                            height: decodeCIParam(area.height, imageRef.current.height)
                          }}
                          position={{
                            x: decodeCIParam(area.x, imageRef.current.width),
                            y: decodeCIParam(area.y, imageRef.current.height)
                          }}
                          enableResizing={false}
                          disableDragging={true}
                          bounds={"parent"}
                      >
                        <div style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "solid red 1.5px",
                          fontWeight: "700",
                          fontSize: "16px",
                          backgroundColor: "hsla(0,0%,73.3%,.5)",
                        }}><span style={{zIndex: "1000"}}>{String.fromCharCode(ASCII_CODE_A + idx)}</span></div>
                      </Rnd>
                  )
              }

              {isLoaded && questionType === QUESTION_TYPES.CI.name &&
                  points.map((point, idx)=>
                      <COORDINATE
                          key={idx}
                          style={{
                            position: "absolute",
                            top: `${decodeCIParam(point.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                            left: `${decodeCIParam(point.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                          }}
                      />
                  )
              }
            </div>
          </div>
      )}
      </div>

      {pollType === POLL_TYPE.SURVEY && (questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.FITB.name) ?
        <></> :
        <div style={{flex: 10, marginTop: "20px"}}>
        {(pollType === POLL_TYPE.SURVEY && questionType === QUESTION_TYPES.WC.name) ?
          (showWordCloud ?
            <WordCloudResult
              data={chartData}
            /> : <></>)
          :
          chartData.map((element, index) => {
            return (
              <div key={index} id={"Option" + index} className="chart-data">
                <div style={{flex: 7, marginLeft: "22px", marginBottom: "5px", marginRight: "3px", fontFamily: "Geomanist", wordBreak: "break-word"}}>{element.ans.charAt(0).toUpperCase() + element.ans.slice(1)}
                  <FontAwesomeIcon id={"CheckMark" + index} style={{marginLeft: "15px", visibility: "hidden"}} icon={faCircleCheck} />
                </div>
                <div style={{fontWeight: "700", marginRight: "15px"}}>{element.people}</div>
              </div>
            );
          })
        }
        </div>
      }
      </div>
    </div>
  </>);
}
