import { Spin } from "../../components/standardization/YuJaLoading";
import "antd/dist/antd.min.css";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import useHostname from "../../hooks/useHostname";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { useLoading } from "../../utils/LoadingContext";
import { CREATE_POLL_PATH,  PLATFORM_SETTINGS, GRADED_POLL, POLL_TITLE_LENGTH, POLL_TYPE, QUESTION_TYPES, RECORD_ATTEMPT, SWITCH_POLL_MESSAGE, SWITCH_POLL_MODAL_TEXT, SWITCH_POLL_MODAL_TITLE, SYNC_ATTEMPT, CREATOR_MENU_LIST, SURVEY, POLL_NAVBAR, SURVEY_NAVBAR, QUESTION_DEFAULT_OPTIONS } from "../../utils/constants";
import { notifyError, removePrefix, updateCreateMode, validateText } from "../../utils/helpers";
import { FormatDate, addQuestion, chooseQuestion, questionError, trimQuestion } from "../../utils/questionUtils";
import { POLL_TITLE_ERROR, QUESTION_MAX_POINTS_ERROR, SURVEY_TITLE_ERROR } from "../../utils/toast-message-constants";
import CreateQuizSidebar from "./CreateQuizSidebar";
import EmptySidebar from "./EmptySidebar";
import QuestionsMain from "./QuestionsMain";
import "./styles.css";

export default function CreateQuizPage({
  setSidebarContent,
  setRightSidebarContent
}) {
  const pollKey = useRef("");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [currQueDisplay, setCurrQueDisplay] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  // const [description, setDescription] = useState("");
  const { recordPoll, updatePoll, fakeCall } = PollApiCalls();
  const { getSetting } = InstitutionApiCalls();
  const history = useHistory();
  // const [pollTypeModalShow, setPollTypeModalShow] = useState(false);
  const [desiredPollMode, setDesiredPollMode] = useState("");
  const { loading } = useLoading();
  const [pollType, setPollType] = useState("Graded Poll");
  const [showAnswerOnViewer, setShowAnswerOnViewer] = useState(false);
  const [recordAttempt, setRecordAttempt] = useState(RECORD_ATTEMPT.ALL.value);
  const [lmsAttempt, setLMSAttempt] = useState(SYNC_ATTEMPT.FIRST.value);
  const [maxAttempts, setMaxAttempts] = useState(-1);
  const location = useLocation();
  const [setPollTitle, getPollTitle] = useLocalStorageNew("pollTitle", "");
  const [setQues, getQues] = useLocalStorageNew("questions", []);
  const [setType, getType] = useLocalStorageNew("pollType", "");
  const [setCreateTime, getCreateTime] = useLocalStorageNew("createTime", new Date());
  const [firstSave, setFirstSave] = useState(true);

  const [focusMCElem, setFocusMCElem] = useState(-1);
  const [focusSAElem, setFocusSAElem] = useState(-1);
  const [focusTFElem, setFocusTFElem] = useState(-1);
  const [focusFITBElem, setFocusFITBElem] = useState(-1);
  const [focusCIElem, setFocusCIElem] = useState(-1);
  const [focusMHElem, setFocusMHElem] = useState(-1);
  const [disableSave, setDisableSave] = useState(false);
  // const [focusWCElem, setFocusWCElem] = useState(-1);
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);
  const [setSaveExit, getsaveExit] = useLocalStorageNew("saveExit", false);
  const [alertShow, setAlertShow] = useState(false);
  const [nextRouterPath, setNextRouterPath] = useState({ pathname: '', state: null });
  const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentQuestions", []);
  const [setShowAnswer, getShowAnswer] = useLocalStorageNew("showAnswer", false);
  const [setPollkey, getPollKey] = useLocalStorageNew("PollKey", "");
  const [alertContent,] = useState(SWITCH_POLL_MESSAGE);
  const hostResource = useHostname(window.location.hostname);
  const {institutionId = "", platformSetting} = hostResource;

  const [isSaving, setIsSaving] = useState(false); 

  const [anonymousJoin, setAnonymousJoin] = useState(platformSetting != undefined && platformSetting.hasOwnProperty(PLATFORM_SETTINGS.ANONYMOUS_JOIN) ?  JSON.parse(platformSetting[PLATFORM_SETTINGS.ANONYMOUS_JOIN]) : false)

  if (questions.length < 1) {
    const qs = addQuestion(questions, "MCSS");
    setQuestions(qs);
    setCurrQueDisplay(qs[0].serialNo);
  }

  useEffect(() => {
    if(document.getElementById("engagePageIdentifier") != null) {
      document.getElementById("engagePageIdentifier").innerText = CREATOR_MENU_LIST[1].name + (pollType === SURVEY ? SURVEY_NAVBAR : POLL_NAVBAR);
      updateCreateMode(pollType === SURVEY ? POLL_TYPE.SURVEY : POLL_TYPE.GRADED_POLL);
    }
  }, [pollType]);

  const checkChanged = () => {
    const curr_ques = JSON.stringify(getRecentQuestions());
    const curr_saved = JSON.stringify(getQues());
    if (curr_saved === "{}" && curr_ques === '[{"queTitle":"","serialNo":1,"checked":true,"questionType":"MCSS","optionsMap":{"a":"","b":"","c":"","d":""},"correctAnswers":[],"weightage":10,"timeLimit":15,"getReadyTimeLimit":10}]') {
      return false;
    }
    else if (curr_saved === "{}") {
      return true;
    }
    else {
      return !(curr_ques === curr_saved);
    }
  }

  const alertConfig = {
    title: SWITCH_POLL_MODAL_TITLE,
    okText: SWITCH_POLL_MODAL_TEXT,
    submit: async () => {
      setAlertShow(false);
      setIsConfirmationPending(true);
    },
    close:  async () => {
      setAlertShow(false);
    },
  };
  useEffect (() => {
    setRecentQuestions(questions);
  }, [questions]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (!checkChanged() || getsaveExit() === true) {
        setSaveExit(false);
        resetPoll();
        return true;
      }
      if (location.pathname && !isConfirmationPending) {
        setNextRouterPath({ pathname: location.pathname, state: location.state });
        setAlertShow(true);
        return false;
      }
      return true;
    });

    if (isConfirmationPending) {
        resetPoll();
        history.push(nextRouterPath.pathname, nextRouterPath.state);
        setIsConfirmationPending(false);
        unblock();
    }

    return () => {
      unblock();
    };
  }, [history, isConfirmationPending, nextRouterPath]);

  useEffect(() => {
    switch(questions[currQueDisplay-1].questionType) {
      case QUESTION_TYPES.MCSS.name:
        if(focusMCElem !== -1) {
          document.getElementsByName("question-text")[focusMCElem].focus();
          setFocusMCElem(-1);
        }
        break;
      case QUESTION_TYPES.SA.name:
        if(focusSAElem !== -1) {
          document.getElementsByName("question-text")[focusSAElem].focus();
          setFocusSAElem(-1);
        }
        break;
      case QUESTION_TYPES.TF.name:
        if(focusTFElem !== -1) {
          document.getElementsByName("question-text")[focusTFElem].focus();
          setFocusTFElem(-1);
        }
        break;
      case QUESTION_TYPES.FITB.name:
        if(focusFITBElem !== -1) {
          if(focusFITBElem === 0) {
            document.getElementsByClassName("notranslate public-DraftEditor-content")[focusFITBElem].focus();
          } else {
            document.getElementsByName("question-text")[focusFITBElem-1].focus();
          }
          setFocusFITBElem(-1);
        }
        break;
      case QUESTION_TYPES.CI.name:
        if(focusCIElem !== -1) {
          document.getElementsByName("question-text")[focusCIElem].focus();
          setFocusCIElem(-1);
        }
        break;

      case QUESTION_TYPES.MH.name:
        if(focusMHElem !== -1) {
          console.log(focusMHElem);
          document.getElementsByName("question-text")[focusMHElem].focus();
          setFocusMHElem(-1);
        }
        break;
      case QUESTION_TYPES.WC.name:
        // if(focusWCElem !== -1) {
        //   document.getElementsByClassName("poll-sub-text focus-outline-none")[focusWCElem].focus();
        //   setFocusWCElem(-1);
        // }
        break;
    }
  }, [currQueDisplay, focusMCElem, focusSAElem, focusTFElem, focusFITBElem, focusCIElem, focusMHElem]);

  const validatePoll = () => {
    if (!validateText(title, POLL_TITLE_LENGTH)) {
      notifyError(pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_ERROR : POLL_TITLE_ERROR);
      document.getElementsByName("pollTitle")[0].focus();
      return false;
    }

    let i = 0;
    for (let q of questions) {
      i++;
      if (q.weightage > 1000) {
        let errorWithQuestion = "Question " + i + ": " + QUESTION_MAX_POINTS_ERROR;
        notifyError(errorWithQuestion);
        setCurrQueDisplay(q.serialNo);
        return false;
      }
    }

    let index = 0;
    for (let q of questions) {
      index++;
      setCurrQueDisplay(q.serialNo);
      let {quesError, elemIndex} = questionError(q, pollType);
      switch(q.questionType) {
        case QUESTION_TYPES.MCSS.name:
          setFocusMCElem(elemIndex);
          break;
        case QUESTION_TYPES.SA.name:
          setFocusSAElem(elemIndex);
          break;
        case QUESTION_TYPES.TF.name:
          setFocusTFElem(elemIndex);
          break;
        case QUESTION_TYPES.FITB.name:
          setTimeout(() => {
            setFocusFITBElem(elemIndex);
          }, 100);
          break;
        case QUESTION_TYPES.CI.name:
          setFocusCIElem(elemIndex);
          break;
        case QUESTION_TYPES.MH.name:
          setFocusMHElem(elemIndex);
          break;
        case QUESTION_TYPES.WC.name:
          // setFocusWCElem(elemIndex);
          break;
      }

      if (quesError) {
        let errorWithQuestion = "Question " + index + ": " + quesError;
        setQuestions(chooseQuestion(q.serialNo, questions));
        notifyError(errorWithQuestion);
        return false;
      }
    }
    return true;
  };

  const savePoll = async (normalSave = true) => {
    if (!validateText(title, POLL_TITLE_LENGTH)) {
      if (normalSave) {
        notifyError(pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_ERROR : POLL_TITLE_ERROR);
        document.getElementsByName("pollTitle")[0].focus();
        return false;
      }
      return true;
    }

    const copyQuestions = JSON.parse(JSON.stringify(questions)).map(q => {
      q.timeLimit = 0;
      return q;
    });
    setSaveButtonDisabled(true);
    let pollToAdd = {
      pollType: pollType,
      questions: pollType.toUpperCase() === POLL_TYPE.SURVEY ? copyQuestions : questions,
      // ownerId: "794e0074-e325-4fb5-973c-d55750ab5324",
      pollTitle: title,
      showAnswerOnViewer: showAnswerOnViewer,
      anonymousJoin: anonymousJoin, 
      recordAttempt: recordAttempt,
      lmsAttempt: lmsAttempt,
      maxAttempts: maxAttempts,
      // pollDescription: description,
    };

    if (pollKey.current) {
      pollToAdd.pollKey = removePrefix(pollKey.current);
      await updatePoll(pollToAdd, normalSave, firstSave);
      if (firstSave) setFirstSave(false);
    } else {
      const pollRecord = await recordPoll(pollToAdd, normalSave);
      if (pollRecord) {
        pollKey.current = pollRecord.pollKey;
      }
    }
    setPollTitle(title);
    setQues(questions);
    setType(pollType);
    setSaveButtonDisabled(false);
    setPollkey(pollKey);
    window.onbeforeunload = undefined;
    return true;
  };

  const savePollClose = async (normalSave = true) => {
    setDisableSave(true);
    setSaveExit(true);
    if (!validatePoll()) {
      setDisableSave(false);
      return;
    }

    setSaveButtonDisabled(true);
    const saveQuestions = trimQuestion(questions); 
    const copyQuestions = JSON.parse(JSON.stringify(saveQuestions)).map(q => {
      q.timeLimit = 0;
      return q;
    });
    let pollToAdd = {
      pollType: pollType,
      questions: pollType.toUpperCase() === POLL_TYPE.SURVEY ? copyQuestions : saveQuestions,
      pollTitle: title,
      showAnswerOnViewer: showAnswerOnViewer,
      anonymousJoin: anonymousJoin, 
      recordAttempt: recordAttempt,
      lmsAttempt: lmsAttempt,
      maxAttempts: maxAttempts,
    };

    if (pollKey.current) {
      pollToAdd.pollKey = removePrefix(pollKey.current);
      await updatePoll(pollToAdd, normalSave, firstSave);
      if (firstSave) setFirstSave(false);
    } else {
      const pollRecord = await recordPoll(pollToAdd, normalSave);
      if (pollRecord) {
        pollKey.current = pollRecord.pollKey;
      }
    }
    setSaveButtonDisabled(false);
    window.onbeforeunload = undefined;
    history.push('/home');
  };  

  // useEffect(() => {
  //   if (questions.length !== 0) {
  //     if (!window.onbeforeunload) {
  //       window.onbeforeunload = function () {
  //         return "";
  //       };
  //     }
  //   } else {
  //     window.onbeforeunload = undefined;
  //   }
  // }, [questions]);

  useEffect(() => {
    return () => {
      window.onbeforeunload = undefined;
    };
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      setSidebarContent(
        <CreateQuizSidebar
          title={"Question Details"}
          noContentText={"Question Details"}
          setQuestions={setQuestions}
          questions={questions}
          currQueDisplay={currQueDisplay}
          setCurrQueDisplay={setCurrQueDisplay}
          pollKey={pollKey.current}
          pollType={pollType}
          setPollType={setPollType}
          handlePollTypeChange = {handlePollTypeChange}
          // pollTypeModalShow={pollTypeModalShow}
          // setPollTypeModalShow={setPollTypeModalShow}
          // desiredPollMode={desiredPollMode}
          // setDesiredPollMode={setDesiredPollMode}
          recordAttempt={recordAttempt}
          setRecordAttempt={setRecordAttempt}
          lmsAttempt={lmsAttempt}
          setLMSAttempt={setLMSAttempt}
          maxAttempts={maxAttempts}
          setMaxAttempts={setMaxAttempts}
          checkChanged = {checkChanged}
          showAnswerOnViewer={showAnswerOnViewer}
          setShowAnswerOnViewer={setShowAnswerOnViewer}
          anonymousJoin={anonymousJoin}
          setAnonymousJoin={setAnonymousJoin}
        />
      );
    } else {
      setSidebarContent(
        <EmptySidebar
          title="Poll Questions"
          noContentText="No Questions added yet"
        />
      );
    }
  }, [title, questions, setQuestions, setSidebarContent, pollType, desiredPollMode, setDesiredPollMode, currQueDisplay, setCurrQueDisplay, pollKey.current, anonymousJoin, showAnswerOnViewer, pollType, lmsAttempt, recordAttempt, maxAttempts]);

  // useEffect(() => {
  //   setRightSidebarContent(
  //     <CreateQuestionDetailSidebar
  //       title={"Question Details"}
  //       noContentText={"Question Details"}
  //       questions={questions}
  //       setQuestions={setQuestions}
  //       currQueDisplay={currQueDisplay}
  //       pollType={pollType}
  //       showAnswerOnViewer={showAnswerOnViewer}
  //       setShowAnswerOnViewer={setShowAnswerOnViewer}
  //       anonymousJoin={anonymousJoin}
  //       setAnonymousJoin={setAnonymousJoin}
  //       recordAttempt={recordAttempt}
  //       setRecordAttempt={setRecordAttempt}
  //       lmsAttempt={lmsAttempt}
  //       setLMSAttempt={setLMSAttempt}
  //       maxAttempts={maxAttempts}
  //       setMaxAttempts={setMaxAttempts}
  //     />
  //   );
  // }, [questions, setQuestions, currQueDisplay, setRightSidebarContent, pollType, showAnswerOnViewer, setShowAnswerOnViewer, anonymousJoin, setAnonymousJoin, recordAttempt, setRecordAttempt, lmsAttempt, setLMSAttempt, maxAttempts, setMaxAttempts]);

  useEffect(async () => {
    if (location && location.state) {
      if (location.state.fromEditQuizPage && location.state.pollType) {
        pollKey.current = "";
        console.log(location.state.pollType);
        setPollType(location.state.pollType);
      } else if (location.state.fromNavbar && location.state.pollType) {
        console.log(location.state.pollType);
        console.log(desiredPollMode);
        // if (window.confirm(`Changes you made may not be saved. Do you want to continue?`)) {
        setPollType(location.state.pollType);
          // resetPoll();
      }
    }
  }, [location.state]);

  // const clickOkay = () => {
  //   history.push(`/poll/published/${pollKey.current}/${pollCode.uniqueCode}`);
  // }

  const resetPoll = () => {
    // window.onbeforeunload = undefined;
    // window.location = window.location.href;
    pollKey.current = "";
    setSaveButtonDisabled(false);
    setCreateTime(new Date()); 
    // setPollCode();
    setQuestions([]);
    setTitle("");
    // setDescription("");
    // setCurrQueDisplay("1");
    setPollTitle("");
    setQues({});
    setShowAnswer(false);
    setPollkey("");
    setType("");
    // setType(pollType);
    // history.push({pathname: "/create", state : { pollType : pollType }})
  }

  const questionsEdited = () => {
    let show = false; 
    questions.forEach((question) => {
      if(question.queTitle !== "") {
        show = true; 
      }
      else if (question.hasOwnProperty("image") && question.image !== "") {
        show = true; 
      }
      else if (question.questionType == QUESTION_TYPES.MCSS.name && JSON.stringify(question.optionsMap) !== QUESTION_DEFAULT_OPTIONS.MCSS) {
        show = true; 
      }
    })
    return show; 
  }
  
  useEffect(async () => {
    if (!history.location.state || !history.location.state.previous || history.location.state.previous !== location.pathname) {
        resetPoll();
        if (history.location.state && history.location.state.pollType) {
          history.replace({
            pathname: CREATE_POLL_PATH,
            state: {pollType: history.location.state.pollType, fromNavbar: true, previous: CREATE_POLL_PATH}
        });
        }
        else {
          history.replace({
            pathname: CREATE_POLL_PATH,
            state: {pollType: GRADED_POLL, fromNavbar: true, previous: CREATE_POLL_PATH}
          })
        };
    }
    const ques = getQues();
    if (JSON.stringify(ques) !== "{}") {
      setQuestions(ques);
    }
    // const questitle = getPollTitle(); // changing poll type or creating new poll/survey should not retain title
    // if (questitle != "") {
    //   setTitle(questitle);
    // }
    const pollType = getType();
    if (pollType && pollType !== ""){
      setPollType(pollType);
    }
    const pollTitle = getPollTitle(); 
    if (pollTitle && pollTitle != "") {
      setTitle(pollTitle);
    }
    const showAnswer = getShowAnswer();
    setShowAnswerOnViewer(showAnswer);
    if (!pollKey) {
      fakeCall().then();
    }
  }, []);



  // code that saves when refresh
  // useEffect(() => {
  //   const handleBeforeUnload = async () => {
  //       if (validateText(title, POLL_TITLE_LENGTH)) {
  //         await savePoll(false);
  //         setPollTitle(title);
  //         setQues(questions);
  //         setType(pollType);
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [title, questions, pollType]);

  // Set the image url to an empty string for each question so that image load correctly
  useEffect(() => {
      const handleBeforeUnload = async () => {
          const Local_questions = getQues();
          const updatedQuestions = Local_questions.map((question) => ({
            ...question,
            imgUrl: "",
          }));
          setQues(updatedQuestions)
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [title, questions, pollType]);

  const handlePollTypeChange = async (newPollType, isSave) => {
    // Switch without saving
    // if (!isSave) {
    //   resetPoll();
    //   return true;
    // }
    await setShowAnswerOnViewer(false);
    await setAnonymousJoin(platformSetting != undefined && platformSetting.hasOwnProperty(PLATFORM_SETTINGS.ANONYMOUS_JOIN) ?  JSON.parse(platformSetting[PLATFORM_SETTINGS.ANONYMOUS_JOIN]): false);
    // if (await savePoll(false)) {
    resetPoll();
    return true;
    // }
    // return false;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (questionsEdited() && ((title !== getPollTitle()) || (JSON.stringify(questions) !== JSON.stringify(getQues())) || (pollType !== getType()))) {
        // console.log("auto saving");
        if (!title) {
          setTitle("Draft: " + FormatDate(new Date(getCreateTime())));
          setPollTitle("Draft: " + FormatDate(new Date(getCreateTime()))); 
        }
        setIsSaving(true); 
        savePoll(false);
        setPollTitle(title);
        setQues(questions);
        setType(pollType);
        setShowAnswer(showAnswerOnViewer);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [title, questions, pollType]);

  useEffect(() => {
    function getPlatformSettings() {
      getSetting().then((result) => {
        // console.log(result);
        if (!!result && !!result.LMSQuizAttempts) {
          // console.log(result.LMSQuizAttempts);
          setLMSAttempt(result.LMSQuizAttempts);
        }
      });
    }
    getPlatformSettings();
  }, []);

  useEffect(() => {
    if (isSaving) {
      const gifTimer = setTimeout(() => {
        setIsSaving(false);
      }, 5000);

      return () => clearTimeout(gifTimer);
    }
  }, [isSaving]);

  return (
    <>
    <YuJaGeneralAlert
      show={alertShow}
      setModalShow={setAlertShow}
      content={alertContent}
      config={alertConfig}
    />
      <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
        <div style={{ backgroundColor: "#FCFBFF", padding: "0 2.063rem",  height: "100%", overflowY: "hidden"}}>
          {(questions.length > 0) &&
            <QuestionsMain
              title={title}
              setTitle={setTitle}
              questions={questions}
              setQuestions={setQuestions}
              savePoll={savePoll}
              savePollClose={savePollClose}
              pollKey={getPollKey().current}
              currQueDisplay={currQueDisplay}
              changeCurrQueDisplay={setCurrQueDisplay}
              saveButtonDisabled={saveButtonDisabled}
              pollType={pollType}
              institutionId={institutionId}
              isSaving={isSaving}
              disableSave={disableSave}
            />}
        </div>
      </Spin>
    </>
  );
}
