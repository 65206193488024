import {useParams} from "react-router-dom";
import styles from "./Presentation.module.css";

import React, {useEffect, useState} from "react";
import {useWindowDimensions} from "react-native-web";
import useQuery from "../../utils/queryparam";
import {ReactComponent as YuJaLogo} from "../../images/YuJa_Logo_Login.svg";
import {Spin} from "../../components/standardization/YuJaLoading";
import {useSessionValidation} from "../../hooks/useSessionValidation";
import {PollApiCalls} from "../../apis/PollApiCalls";
import {BsXCircle} from "react-icons/bs";
import QuestionDetail from "./QuestionDetail";
import {
    PRESENTATION_QUESTION_INTERNAL_ERROR_TEXT,
    PRESENTATION_QUESTION_INTERNAL_ERROR_TITLE,
    PRESENTATION_QUESTION_PAGE_TITLE, PRESENTATION_QUESTION_POLL_UNAVAILABLE_TEXT,
    PRESENTATION_QUESTION_POLL_UNAVAILABLE_TITLE
} from "../../utils/constants";


export default function PresentationQuestionPage() {
    const {getPollRecordFromUniqueCode} = PollApiCalls();

    const { pollCode, serialNo } = useParams();
    let query = useQuery();
    const [isLoading, setIsLoading] = useState(true);
    const [pollAvailable, setPollAvailable] = useState(true);
    const [internalError, setInternalError] = useState(false);

    const [question, setQuestion] = useState({});
    const [poll, setPoll] = useState({});
    const {checkExpired, parseToken} = useSessionValidation();
    const window = useWindowDimensions();

    useEffect(() => {
        let token = query.get("token");
        if (!token || checkExpired(token)) {
            setIsLoading(false);
            setPollAvailable(false);
            return;
        }
        getPollRecordFromUniqueCode(pollCode, token).then(pollDetail => {
            if (!pollDetail || !pollDetail.poll) {
                setInternalError(true);
                setIsLoading(false);
                return;
            }
            const {poll, questions} = pollDetail;
            const {pk, index1Pk} = poll;
            const ownerId = index1Pk.substring("U#".length);

            const {userId} = parseToken(token);
            if (ownerId !== userId) {
                setPollAvailable(false);
                setIsLoading(false);
                return;
            }


            setPoll(poll);
            const question = questions.find(q => q.serialNo === parseInt(serialNo));
            setQuestion(!!question ? question : {});
            setIsLoading(false);

        }).catch((error) => {
            setInternalError(true);
            setIsLoading(false);
        });
    }, [])

    return (
        <div className={styles.container}>
            {isLoading &&
                <>
                    <div className={styles.titleContainer}>
                        {window.width < 1000 ?
                            <YuJaLogo style={{marginRight: "5px", width: 60, height: 60, alignSelf: 'center'}}/>
                            : <YuJaLogo style={{marginRight: "5px", width: 95, height: 95, alignSelf: 'center'}}/>
                        }
                        <div className={styles.title} style={{fontSize: window.width < 1000 ? 32 : 40}}>{PRESENTATION_QUESTION_PAGE_TITLE}</div>
                    </div>
                    <Spin size="large" wrapperClassName="globalSpin" spinning={true}/>
                </>
            }

            {!isLoading && (!pollAvailable || internalError) &&
                <>
                    <div className={styles.titleContainer}>
                        {window.width < 1000 ?
                            <YuJaLogo style={{marginRight: "5px", width: 60, height: 60, alignSelf: 'center'}}/>
                            : <YuJaLogo style={{marginRight: "5px", width: 95, height: 95, alignSelf: 'center'}}/>
                        }
                        <div className={styles.title} style={{fontSize: window.width < 1000 ? 32 : 40}}>{PRESENTATION_QUESTION_PAGE_TITLE}</div>
                    </div>
                    <div className={styles.resultBox} style={{width: window.width < 650 ? '80%' : 600}}>
                        {window.width < 1000 ?
                            <BsXCircle style={{width: 60, height: 60}}/>
                            : <BsXCircle style={{width: 90, height: 90}}/>
                        }
                        <div className={styles.resultTitle} style={{fontSize: window.width < 1000 ? 23 : 32}}>
                            {!pollAvailable && PRESENTATION_QUESTION_POLL_UNAVAILABLE_TITLE}
                            {internalError && PRESENTATION_QUESTION_INTERNAL_ERROR_TITLE}
                        </div>
                        <div className={styles.description} style={{fontSize: window.width < 1000 ? 16 : 24}}>
                            {!pollAvailable && PRESENTATION_QUESTION_POLL_UNAVAILABLE_TEXT}
                            {internalError && PRESENTATION_QUESTION_INTERNAL_ERROR_TEXT}
                        </div>
                    </div>
                </>
            }
            {!isLoading && pollAvailable && !internalError &&
                <QuestionDetail poll={poll} question={question}/>
            }

        </div>
    );
}