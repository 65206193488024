import { Spin } from "../../components/standardization/YuJaLoading";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Countdown from 'react-countdown';
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
  BUTTON,
  CLOSE_MODAL,
  MAX_ATTEMPTS_REACHED,
  NO_TEXT,
  REATTEMPT_MODAL_BODY,
  REATTEMPT_MODAL_TITLE,
  RETRY_TEXT,
  TEXTBOX,
  UNLIMITED_ATTEMPTS,
  UNLIMITED_ATTEMPTS_TEXT,
  USER_COMPLETE_POLL_SUBTITLE,
  USER_COMPLETE_POLL_TITLE,
  YES_TEXT
} from "../../utils/constants";
import { getReattemptText, newPageLog } from "../../utils/helpers";
import "./styles.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import NetworkStatus from "./NetworkStatus";
import waitingRoomBackground from "../../images/waitingRoomBackground.svg"; 
import waitingRoomBackgroundMobile from "../../images/waitingRoomBackgroundMobile.svg";
import {useWindowDimensions} from "react-native-web";
import {ReactComponent as CompleteIcon} from "../../images/completePoll.svg";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function UserCompletePoll({handlePollComplete, pollEndTime, handleReattempt, allowReattempt, attemptsCount, maxAttempts, websocketState, pollTitle }) {
  const { loading } = useLoading();
  const [showReattemptModal, setShowReattemptModal] = useState(false);
  const [canReattempt, setCanReattempt] = useState(false);
  const {width, height} = useWindowDimensions();
  const isNarrow = useMobileAccess(1200);
  const isMobile = useMobileAccess(480);


  useEffect(() => {
    const now = Date.now();
    if (now > pollEndTime) {
      handlePollComplete();
    }
  }, [handlePollComplete, pollEndTime]);

  document.getElementById('root').style.backgroundImage = "none";

  useEffect(() => {
    newPageLog("UserCompletePoll.js");
    if (document.getElementById("viewer-navbar") !== null) {
      document.getElementById("viewer-navbar").style.backgroundColor = "";
    }
    console.log(attemptsCount, maxAttempts);
    setCanReattempt(maxAttempts === UNLIMITED_ATTEMPTS || attemptsCount < maxAttempts);

    document.body.classList.add("waiting-room-background");
    return () => {
      document.body.classList.remove("waiting-room-background");
    };
  }, []);

  const onClickReattempt = () => {
    setShowReattemptModal(true);
  };

  const handleClose = () => {
    setShowReattemptModal(false);
  };

  return (
    <div style={{height: "100%"}}>
      <div style={{position: "fixed", height: "100vh", backgroundColor: "#FCFBFF", width: "100vw", zIndex: "-1"}}/> 
      <Modal
          id="reattempt-modal"
          show={showReattemptModal}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop={loading ? "static" : true}
          dialogClassName="sa-modal"
      >
          <Spin tip="Loading..." size="large" spinning={loading}>
              <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={handleClose} className="modal-close" />
              <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Modal.Title tabIndex={0} role={TEXTBOX}>
                  {REATTEMPT_MODAL_TITLE}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body tabIndex={0} role={TEXTBOX}>
                {REATTEMPT_MODAL_BODY}
              </Modal.Body>
              <Modal.Footer>
                  <YuJaButton type="secondary" onClick={handleClose}>
                    {NO_TEXT}
                  </YuJaButton>
                  <YuJaButton onClick={handleReattempt}>
                    {YES_TEXT}
                  </YuJaButton>
              </Modal.Footer>
          </Spin>
      </Modal>
      <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <div className="waiting-room-title-container" style={{height: "fit-content" , marginTop: window.innerWidth < 480 ? 24 : 50, width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between"}}> 
      <span className="waiting-room-title" tabIndex={0} style={{wordBreak:"break-all", paddingLeft: !isNarrow? "170px" : "48px", textAlign: "center", flex: "1"}}>{pollTitle}</span>
      <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState}/> </div>
    </div>
    <div style={{marginTop: "70px", width: "100%", display: "flex", justifyContent: "center"}}> <CompleteIcon/></div>
      <Container>
        <Row style={{marginTop: "30px"}}>
          <Col className="center">
            <span tabIndex={0} role={TEXTBOX} className="waiting-room-title" style={{fontSize: isMobile? "18px" : "30px"}}>
              {USER_COMPLETE_POLL_TITLE}
            </span>
          </Col>
        </Row>
        <Row style={{marginTop: "10px"}}>
          <Col className="center">
            <span tabIndex={0} role={TEXTBOX} className="waiting-room-bottom" style={{fontSize: isMobile? "14px" : isNarrow? "22px" : "30px"}}>
              {USER_COMPLETE_POLL_SUBTITLE}
            </span>
          </Col>
        </Row>
      </Container>
      {allowReattempt &&
        <Row className="no-padding center" style={{width: "100%", flex: 1, padding: "20px", margin: "0px"}}>
          <Col className="no-padding center" style={{display: "flex", justifyContent: "center", gap: "20px", alignItems: "center"}}>
            <span tabIndex={0} role={TEXTBOX} className="reattempt-text" style={{fontSize: isMobile? "14px" : ""}}>
              {canReattempt ?
                (maxAttempts === UNLIMITED_ATTEMPTS ?
                  UNLIMITED_ATTEMPTS_TEXT :
                  getReattemptText(attemptsCount, maxAttempts)) :
                MAX_ATTEMPTS_REACHED
              }
            </span>
            {canReattempt &&
              <div style={{ justifyContent: "center", display: "flex" }}>
                <YuJaButton onClick={onClickReattempt} style={{ width: isNarrow ? "60px" : "90px", height: isMobile? "34px" : "" , borderRadius: "4.205px", border: "2px solid #42296E", fontSize: isMobile ? "14px": isNarrow ? "18px" : "24px", fontFamily: 'Geomanist', display: "flex", justifyContent: "center"}}>{RETRY_TEXT}</YuJaButton>
              </div>
            }
          </Col>
        </Row>
      }
      </div>

      {(<Countdown className="hide"
          date={pollEndTime}
          onStart={(delta) => {}}
          onComplete={handlePollComplete}
      />)}
    </div>
  );
}
