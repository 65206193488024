import { TEXTBOX } from "../../utils/constants";
import styles from "./YuJaRadioBox.module.css";


export const YuJaRadioBox = ({checked=false, disabled=false, label="", onClick, labelStyle={}, size=17, ...props}) => {
    const onClickEvent = (e) => {
        if (disabled) {
            return
        }
        e.currentTarget.value=props.value;
        e.currentTarget.name=props.name;
        e.currentTarget.checked = true;
        e.target.value = props.value;
        e.target.name = props.name;
        e.target.checked = true;
        onClick(e);
    }

    return (
        <div className={`${styles.container}`} {...props} onClick={onClickEvent} tabIndex={0} role={TEXTBOX}>
            <div
                style={{width: size, height: size, borderRadius: size}}
                className={`${styles.box} ${disabled ? styles.disabled : ""}`}
            >
                {checked &&
                    <span className={styles.circle} style={{width: size * 0.5, height: size * 0.5, borderRadius: size * 0.5}}/>
                }
            </div>
            {label &&
                <span className={`${styles.label} ${disabled ? styles.disabledLabel : ""}`} style={{...labelStyle}}>{label}</span>
            }
        </div>
    )
}