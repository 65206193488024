import React , { useState }from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import AddButton from "../../components/buttons/AddButton";
import { intToChar, notifyError } from "../../utils/helpers";
import { addOptionToQuestion } from "../../utils/questionUtils";
import "../styles.css";
import MCQuestionOption from "./MCQuestionOption";
import YuJaButton from "../../components/standardization/YuJaButton";
import {ReactComponent as PlusIcon} from "../../images/plus.svg";
import { ADD_OPTIONS_ERROR } from "../../utils/toast-message-constants";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function MCQuestion({
  setQuestions,
  questions,
  selectedQuestion,
  pollType,
}) {
  const { optionsMap, serialNo } = selectedQuestion;
  const isNarrow = useMobileAccess(1100);

  const [showButton, setShowButton] = useState(true);

    const handleDragStart = () => {
      setShowButton(false);
    };

    const handleDragEnd = () => {
      setShowButton(true);
    };

  const handleAnswerOrderChange = (result) => {
    if (!result.destination) return;

    const sourceKey = intToChar(result.source.index);
    const destinationKey = intToChar(result.destination.index);

    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let newOptionsMap = { ...q.optionsMap };
        newOptionsMap[sourceKey] = q.optionsMap[destinationKey];
        newOptionsMap[destinationKey] = q.optionsMap[sourceKey];

        let newCorrectAns = q.correctAnswers;
        const correctAnswer = q.correctAnswers[0];
        if (sourceKey === correctAnswer) {
          newCorrectAns = [destinationKey];
        } else if (destinationKey === correctAnswer) {
          newCorrectAns = [sourceKey];
        }

        const sourceImageKey = "image" + sourceKey;
        const destinationImageKey = "image" + destinationKey;

        let updatedQuestion = {
          ...q,
          optionsMap: newOptionsMap,
          correctAnswers: newCorrectAns,
        };

        updatedQuestion[destinationImageKey] = q[sourceImageKey];
        updatedQuestion[sourceImageKey] = q[destinationImageKey];

        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push(q);
      }
    });
    setQuestions(newQuestions);
  };

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={(result) => {
      handleDragEnd(); 
      handleAnswerOrderChange(result);
      } }>
      <Droppable droppableId="droppable-multiple-choice-question">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            {...provided.dropHandleProps}
            className="droppable-multiple-choice-question-options"
            style={{marginTop: "10px", marginLeft: "-30px"}}
          >
            {Object.entries(optionsMap).map(([choice, optionValue], index) => {
              return (
                <div key={index}>
                  <MCQuestionOption
                    optionData={{ choice: choice, optionValue: optionValue }}
                    selectedQuestion={selectedQuestion}
                    questions={questions}
                    setQuestions={setQuestions}
                    index={index}
                    pollType={pollType}
                    maxSize={Object.entries(optionsMap).length}
                  />
                </div>
              );
            })}
            {/* { showButton ?
                <div style={{width: "100%", display: "flex", justifyContent: "center", marginBottom: 15}}>
                  <YuJaButton
                      title="Add Option"
                      onClick={() => {
                        const newQuestions = addOptionToQuestion(
                          selectedQuestion,
                          questions
                        );
                        if (newQuestions) {
                          setQuestions(newQuestions);
                        } else {
                          notifyError(ADD_OPTIONS_ERROR);
                        }
                      }}
                  >
                    <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/>  Add Option
                  </YuJaButton>
                </div>
            : null} */}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
