import React from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import YuJaButton from "../standardization/YuJaButton";
import {
    POLL_CHOOSER_CONFIRM_CANCEL, POLL_CHOOSER_CONFIRM_CONTENT,
    POLL_CHOOSER_CONFIRM_OK,
    POLL_CHOOSER_CONFIRM_TITLE
} from "../../utils/constants";
import styles from "./PollChooserEmbeddedConfirm.module.css"

export default function PollChooserEmbeddedConfirm({config, show, sumbitButtonShow=true}) {
    // const [disable, setDisable] = useState(false);

    return (
        <Modal
            id="warning-modal"
            show={show}
            onHide={config.close}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
        >
            <ModalClose onClick={config.close} className="modal-close" />
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title className="modal-title" id="contained-modal-title-vcenter">
                    {POLL_CHOOSER_CONFIRM_TITLE}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.contentText} style={{wordBreak: "break-all"}}>{POLL_CHOOSER_CONFIRM_CONTENT}</div>
                {/*<YuJaCheckbox label={POLL_CHOOSER_CONFIRM_DONT_SHOW_AGAIN} checked={disable} onClick={() => setDisable(!disable)}/>*/}
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton type={"secondary"} onClick={config.close}>
                    {POLL_CHOOSER_CONFIRM_CANCEL}
                </YuJaButton>
                <YuJaButton
                    onClick={(e) => {
                        e.preventDefault();
                        // config.submit(disable);
                        config.submit();
                    }}
                >
                    {POLL_CHOOSER_CONFIRM_OK}
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}