import React from "react";
import { Row } from "react-bootstrap";
import "../styles.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import { ADD_BLANK_TEXT, PREVIEW_TEXT, TEXTBOX } from "../../utils/constants";

export default function FITBRow({ noBorder, text, children, showButtons=false, handleAddBlank, handlePreview}) {
  return (
    <div
      style={noBorder ? { border: "none", padding: "0px 12px 0px 2px"} : { padding: "0px 12px 0px 2px"}}
      className="fitb-containers container"
    >
      <Row style={{marginTop: "25px"}}>
        <div style={{display: "flex", padding: "0px"}}>
        <span role={TEXTBOX} tabIndex={0} className="fitb-titles no-padding" style={{fontSize: "20px"}}>{text}</span>
        {showButtons &&
        <div style={{display: "flex", justifyContent: "end", flex: "1", alignItems: "center", gap: "10px"}}>
          <YuJaButton title= {PREVIEW_TEXT} onClick={handlePreview} style={{width: "73px", height: "30px", fontSize: "14px", alignItems: "center", display: "flex"}}> {PREVIEW_TEXT} </YuJaButton> 
          <YuJaButton title= {ADD_BLANK_TEXT} onClick={handleAddBlank} style={{width: "82px", height: "30px",  fontSize: "14px", alignItems: "center", display: "flex", whiteSpace: "nowrap", justifyContent: "center"}}>{ADD_BLANK_TEXT}</YuJaButton>
        </div>
        }
        </div>
      </Row>
      <Row style={{ minHeight: "3rem" }}>{children}</Row>
    </div>
  );
}
