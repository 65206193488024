import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getPaginationRowModel,
	useReactTable
} from "@tanstack/react-table";
import { Spin } from "../../components/standardization/YuJaLoading";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { LTIApiCalls } from "../../apis/LTIApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import "../../components/modals/modal.css";
import NormalPagination from "../../components/pagination/NormalPagination";
import useHostname from "../../hooks/useHostname";
import MH_Icon from "../../images/matching.svg";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
	ATMPT_LABEL,
	ATMPT_LABEL_2,
	ATTEMPT_REGEX,
	CHECKBOX_COLUMN_ID,
	CORRECT,
	DEFAULT_ROWS_PER_PAGE,
	FIXED_ANSWER,
	GRADE_BOOK_QUESTION_COLUMN_REGEX,
	HIDE_ATMPS,
	INCORRECT,
	INVALID_QUESTION_TYPE,
	LOSE_CHANGE_MESSAGE,
	MODAL_CANCEL_TEXT,
	MODAL_SAVE_TEXT,
	NOT_GRADED,
	NO_RESPONSE,
	POLL_TYPE,
	QUESTION_TYPES,
	QUES_LABEL_1,
	SCORE_COLUMN_ID,
	SCORE_COLUMN_NAME,
	SCORE_LABEL,
	SHOW_ATMPS,
	STD_LABEL,
	STUDENT_COLUMN_ID,
	STUDENT_COLUMN_INDEX,
	STUDENT_COLUMN_NAME,
	SUBMIT_TEXT,
	SWITCH_POLL_MODAL_TEXT,
	SWITCH_POLL_MODAL_TITLE,
	SYNC_ATTEMPT,
	TEXTBOX,
	USER_ID_COLUMN_ID,
	USER_ID_COLUMN_NAME,
	VIEW_QUES
} from "../../utils/constants";
import { getAllExpanded, getAnswer, getAttemptNo, getUserId, isDisabled, isValidScore, notifyError, notifySuccess, removePrefixNew } from "../../utils/helpers";
import { ENTER_VALID_SCORES, GRADEBOOK_SYNC_SUCCESS, GRADE_QUESTION_ERROR, GRADE_QUESTION_SUCCESS, NO_STUDENTS_FOUND, SELECT_AT_LEAST_ONE_STUDENT } from "../../utils/toast-message-constants";
import TabQuestion from "../PublishedPollPage/TabQuestion";
import styles from "./GradeBook.module.css";
import GradeQuestion from "./GradeQuestion";
import correct from "./images/Correct.svg";
import incorrect from "./images/Incorrect.svg";
import CI_ICON from "./images/clickable image.svg";
import { ReactComponent as DropDown } from "./images/caret-down.svg";
import { ReactComponent as DropUp } from "./images/caret-right.svg";
import FITB_ICON from "./images/fill-in-the-blank.svg";
import MCSS_ICON from "./images/multiple-choice-single-selection.svg";
import SA_ICON from "./images/short answer.svg";
import TF_ICON from "../../images/tf-new.svg";
import notGradedDisabled from "./images/ungraded-disabled.svg";
import notGraded from "./images/ungraded.svg";
import WC_ICON from "./images/word cloud.svg";
import YuJaButton from "../../components/standardization/YuJaButton";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";

function IndeterminateCheckbox({
	indeterminate,
	className = '',
	...rest
}) {
	const ref = useRef();

	useEffect(() => {
	  if (typeof indeterminate === 'boolean') {
		ref.current.indeterminate = !rest.checked && indeterminate;
	  }
	}, [ref, indeterminate]);

	return (
		<input
			type="checkbox"
			ref={ref}
			className={className + ' indeterminate-checkbox'}
			{...rest}
			aria-label="checkbox"
		/>
	)
}

export default function GradeBookTabs({
	responses = [],
	setResponses=undefined,
	questions = [],
	pollKey=undefined,
	pollCode=undefined,
	pollTitle=undefined,
	pollType=undefined,
	lmsAttempt="",
	syncMode=0,
	setSyncMode=undefined,
	showCheckbox=true,
	autoSyncData={},
	setAutoSyncData=undefined
}) {
	const history = useHistory();
	const hostResource = useHostname(window.location.hostname);
	const {institutionId = ""} = hostResource;
	const [question, setQuestion] = useState({});
	const [modalShow, setModalShow] = useState(false);
	const [response, setResponse] = useState({});
	const [attemptNo, setAttemptNo] = useState(0);
	const [isCorrect, setIsCorrect] = useState({});
	const [modalShowSA, setModalShowSA] = useState(false);
	const [showAnswerModal, setShowAnswerModal] = useState(false);
	const [answerModalData, setAnswerModalData] = useState(false);
	const {loading} = useLoading();
    const [updateCustomScore, setUpdateCustomScore] = useState(true);
	const [customScoresMap, setCustomScoresMap] = useState({});
	const [syncCountMap, setSyncCountMap] = useState(false);
	const [hoveredRow, setHoveredRow] = useState(-1);
	const { gradeQuestion, gradeQuestionStudents, updateScore, syncScore } = ResponseApiCalls();
	const { gradebookSync } = LTIApiCalls();
	const { setLoading } = useLoading();
	const [alertShow, setAlertShow]= useState(false); 
	const [changedCorrect, setChangedCorrect] = useState({});

	const GRADE_BOOK_COLUMNS = [
		{
			accessorKey: CHECKBOX_COLUMN_ID,
			id: CHECKBOX_COLUMN_ID,
			header: ({ table }) => {
				return (<>
					{getAllExpanded(table.getRowModel().rows) ? // getIsAllRowsExpanded() ?
						<DropUp tabIndex={0}
							aria-label={HIDE_ATMPS}
							role={TEXTBOX}
							className={styles.expandCollapse}
							onClick={() => toggleAllRowsExpanded()}
						/>
						:
						<DropDown tabIndex={0}
							aria-label={SHOW_ATMPS}
							role={TEXTBOX}
							className={styles.expandCollapse}
							onClick={() => toggleAllRowsExpanded()}
						/>
					}
					{showCheckbox ?
						<IndeterminateCheckbox
							className="form-check-input"
							checked={getIsAllRowsSelected()}
							indeterminate={getIsSomeRowsSelected()}
							onChange={getToggleAllRowsSelectedHandler()}
						/> : <></>
					}
				</>);
			},
			cell: ({ row }) => {
				// console.log(row, row.getAllCells(), row.getAllCells()[STUDENT_COLUMN_INDEX], row.getAllCells()[STUDENT_COLUMN_INDEX].getValue());
				return (<>
					{row.getCanExpand() ?
					(<>
						{row.getIsExpanded() ?
							<DropUp tabIndex={0}
							aria-label={HIDE_ATMPS}
							role={TEXTBOX}
								className={styles.expandCollapse}
								// onClick={() => row.toggleExpanded()}
							/> :
							<DropDown tabIndex={0}
							aria-label={SHOW_ATMPS}
							role={TEXTBOX}
								className={styles.expandCollapse}
								// onClick={() => row.toggleExpanded()}
							/>
						}
						{showCheckbox ?
							<IndeterminateCheckbox
								className="form-check-input"
								checked={row.getIsSelected()}
								// disabled={!row.getCanSelect()}
								indeterminate={row.getIsSomeSelected()}
								onChange={(e) => {
									row.toggleSelected();
									e.stopPropagation();
								}}
							/> : <></>
						}
					</>) :
					(!ATTEMPT_REGEX.test(row.getAllCells()[STUDENT_COLUMN_INDEX].getValue()) && showCheckbox ? 
						<IndeterminateCheckbox
							className="form-check-input"
							checked={row.getIsSelected()}
							// disabled={!row.getCanSelect()}
							indeterminate={row.getIsSomeSelected()}
							onChange={row.getToggleSelectedHandler()}
						/> : <></>
					)}
				</>);
			},
			size: 100
		},
		{
			header: STUDENT_COLUMN_NAME,
			accessorFn: row => `${row.firstName}${row.lastName ? ' ' + row.lastName : ''}`,
			id: STUDENT_COLUMN_ID,
			size: 225
		},
		{
			header: SCORE_COLUMN_NAME,
			accessorKey: SCORE_COLUMN_ID,
			id: SCORE_COLUMN_ID,
			size: 150
		},
		{
			header: USER_ID_COLUMN_NAME,
			accessorKey: USER_ID_COLUMN_ID,
			id: USER_ID_COLUMN_ID
		}, // not shown
	];
	const columns = useMemo(() => {
		return GRADE_BOOK_COLUMNS.concat(questions.map(q => ({
			header: `${q.questionType}`,
			accessorKey: `Q${q.serialNo}`,
			id: `Q${q.serialNo}`,
			size: 100
		})));
	}, [questions]);

    const pageOptions = useMemo(() => {
        let options = [];
        // console.log(responses.length, DEFAULT_ROWS_PER_PAGE);
        let nPages = Math.ceil(responses.length / DEFAULT_ROWS_PER_PAGE);
        // console.log(nPages);
        for (let i = 0; i < nPages; i++) options.push(i);
        return options;
    }, [responses]);

	const {
		getHeaderGroups,
		getRowModel,
		setPageIndex,
		getCanPreviousPage,
		previousPage,
		getCanNextPage,
		nextPage,
		getPageCount,
		getState,
		getSelectedRowModel,
		getIsAllRowsSelected,
		getIsSomeRowsSelected,
		getToggleAllRowsSelectedHandler,
		// getIsAllRowsExpanded,
		toggleAllRowsExpanded,
		setPageSize,
	} = useReactTable({
		columns: columns,
		data: responses,
		paginateExpandedRows: false,
		autoResetPage: true,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSubRows: (row) => {
			// console.log(row, row.subRows);
			return row.subRows;
		},
		// debugAll: true,
	});

	useEffect(() => {
        // console.log(updateCustomScore);
        if (!updateCustomScore) return;
		// console.log(responses);
		let tempScoresMap = {};
		let tempSyncCountMap = {};
		responses.map(r => {
			if (!r.attempts) return;
			tempSyncCountMap[r.userId] = r.syncCount;
			let tempAttemptsMap = {};
			if (!r.attempts) {
				tempScoresMap[r.userId] = tempAttemptsMap;
				return;
			}
			// tempAttemptsMap[0] = r.score.replace('%', '');
			for (const [attemptNo, attempt] of Object.entries(r.attempts)) {
				tempAttemptsMap[attemptNo] = attempt.score ? attempt.score.replace('%', '') : '';
				// console.log(attempt.score, isValidScore(attempt.score));
			}
			tempScoresMap[r.userId] = tempAttemptsMap;
		});
		console.log(tempScoresMap);
		// console.log(tempSyncCountMap);
		setCustomScoresMap(tempScoresMap);
		setSyncCountMap(tempSyncCountMap);
        setPageSize(DEFAULT_ROWS_PER_PAGE);
	}, [responses]);

	useEffect(() => {
		if (!!autoSyncData && !!autoSyncData.userId) {
			console.log("Auto Syncing...", pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
			autoSync(pollCode, autoSyncData.userId, responses[responses.findIndex(r => r.userId === autoSyncData.userId)].attempts[0].score);
			setAutoSyncData({});
		}
		else if (!!autoSyncData && !!autoSyncData.userIds) {
			for (const id of autoSyncData.userIds) {
				autoSync(pollCode, id , responses[responses.findIndex(r => r.userId === id)].attempts[0].score);
			}
			setAutoSyncData({});
		}
	}, [autoSyncData]);

	useEffect(() => {
		const syncWrapper = async () => {
			if (syncMode === 0) { // no need to sync
				return;
			}
			setLoading(true);
			if (syncMode === 1) { // student sync
				const selectedUserIds = getSelectedRowModel().flatRows.map((row) => row.original.userId);
				// console.log(selectedUserIds);
				if (selectedUserIds.length === 0) {
					notifyError(SELECT_AT_LEAST_ONE_STUDENT);
				} else {
					const res = await saveSelectedStudents(selectedUserIds);
					if (res) {
						notifySuccess(GRADEBOOK_SYNC_SUCCESS);
					} else {
						notifyError(ENTER_VALID_SCORES);
					}
				}
			} else if (syncMode === 2) { // quiz sync
				let students = customScoresMap;
				if (students.length === 0) {
					notifyError(NO_STUDENTS_FOUND);
				} else {
					const res = await saveAllStudents(students);
					if (res) {
						notifySuccess(GRADEBOOK_SYNC_SUCCESS);
					} else {
						notifyError(ENTER_VALID_SCORES);
					}
				}
			}
			setSyncMode(0);
			setLoading(false);
		};

		syncWrapper();
    }, [syncMode]);

	const autoSync = (pollCode, userId, score) => {
		console.log(pollCode, userId, score, syncCountMap[userId]);
		if (syncCountMap[userId] > 0) {
			gradebookSync(
				pollCode,
				userId,
				score,
				// () => syncScore(pollKey, pollCode, userId, true)
			);
		}
	};

	const showQuestion = async (serialNo) => {
		const no = parseInt(serialNo);
		const selectedQuestion = questions.find(q => q.serialNo === no);
		setQuestion(selectedQuestion);
		setModalShow(true);
	};

	const showGradeModal = async (row, cell) => {
		if (row.getCanExpand()) return;
		if (isDisabled(row, customScoresMap, lmsAttempt)) return;
		if (cell.column.columnDef.header !== QUESTION_TYPES.SA.name) {
			// console.log("Not a SA question");
			return;
		}
		const no = parseInt(cell.column.id.substring(1)); // Remove 'Q'
		const selectedQuestion = questions.find(q => q.serialNo === no);
		setQuestion(selectedQuestion);
		const selectedResponse = responses.find(r => r.userId === row.original.userId);
		setResponse(selectedResponse);
		setAttemptNo(getAttemptNo(row));
		setModalShowSA(true);
	};

	const handleSave = async () => {
		setLoading(true);
        try {
            var questionObj = question;
			let selectedOptions = {}; 
			for (const user of Object.keys(changedCorrect)) {
				let response = responses.find(r => r.userId === user);
				for (const attemptNo of Object.keys(changedCorrect[user])) {
					selectedOptions = {
						...selectedOptions,
						[user]: {
						  ...selectedOptions[user],
						  [attemptNo]: getAnswer(response, attemptNo, question.serialNo),
						},
					  };
				}
			}
            await gradeQuestionStudents(institutionId, pollKey, pollCode, changedCorrect, questionObj, selectedOptions);
            notifySuccess(GRADE_QUESTION_SUCCESS);
        } catch (error) {
            console.log(error);
            notifyError(GRADE_QUESTION_ERROR);
        }
        history.push({
            pathname: "/gradebook", 
            state: {
                pollKey : removePrefixNew(pollKey),
                uniqueCode: removePrefixNew(pollCode),
                autoSync: {
                    userIds: Object.keys(changedCorrect),
                    // score: newResponses[newResponses.findIndex(r => r.userId === student.value)].attempts[0] // score picked from main attempt
                }
            }
        });
		setIsCorrect({});
        setModalShowSA(false);
        setLoading(false);
	};

	const handleClose = () => {
		if(Object.keys(changedCorrect).length > 0 ) {
			setAlertShow(true)
		}
		else {
			setModalShowSA(false);
		}
	};

	const onBlurCustomScore = (event, userId, attemptNo) => {
		const score = event.target.value;
        // console.log(score, isValidScore(score));
		if (isValidScore(score)) updateScore(pollKey, pollCode, userId, score, attemptNo, lmsAttempt, autoSync);
        let attemptNoToCopy = -1;
        let scoreToCopy = "-1";
        // const attempts = responses.find(r => r.userId === userId).attempts;
        // console.log(attempts);
        if (lmsAttempt === SYNC_ATTEMPT.FIRST.value) {
            attemptNoToCopy = 1;
            scoreToCopy = customScoresMap[userId][attemptNoToCopy];
        } else if (lmsAttempt === SYNC_ATTEMPT.RECENT.value) {
            attemptNoToCopy = Object.keys(customScoresMap[userId]).length - 1;
            scoreToCopy = customScoresMap[userId][attemptNoToCopy];
        } else if (lmsAttempt === SYNC_ATTEMPT.HIGHEST.value) {
            Object.keys(customScoresMap[userId]).forEach((attemptNumber, idx) => {
				if (idx === 0) return; // skip the main attempt
				const attempt = customScoresMap[userId][attemptNumber];
				// console.log(customScoresMap[userId], attempt, idx);
				// console.log(attempt, isValidScore(attempt), parseFloat(attempt), parseFloat(scoreToCopy), parseFloat(attempt) > parseFloat(scoreToCopy));
                if (isValidScore(attempt) && parseFloat(attempt) > parseFloat(scoreToCopy)) {
					scoreToCopy = attempt;
                    attemptNoToCopy = attemptNumber;
                }
			});
        }
        console.log(attemptNoToCopy, scoreToCopy, isValidScore(scoreToCopy));
        if (isValidScore(scoreToCopy)) {
            // console.log("update customScoresMap:");
            let tempScoresMap = {...customScoresMap};
            // console.log(tempScoresMap);
            tempScoresMap[userId][0] = scoreToCopy;
            // console.log(tempScoresMap);
            setCustomScoresMap(tempScoresMap);
            
            // console.log("update main attempt:");
            let tempResponses = [...responses];
            let tempResponse = {...tempResponses.find(r => r.userId === userId)};
            let tempSubRows = [...tempResponse.subRows];
            let attempt = tempSubRows[attemptNoToCopy - 1];
            // console.log(attempt);
            let newResponse = {...tempResponse, ...attempt, firstName: tempResponse.firstName, lastName: tempResponse.lastName};
            setUpdateCustomScore(false);
            // console.log(responses, tempResponses.map(r => r.userId === userId ? newResponse : r));
            setResponses(tempResponses.map(r => r.userId === userId ? newResponse : r));
        }
    };

    const onChangeCustomScore = (event, userId, attemptNo) => {
		// console.log(customScoresMap, userId, attemptNo, event.target.value);
		let tempScoresMap = {...customScoresMap};
		tempScoresMap[userId][attemptNo] = event.target.value.trim();
		setCustomScoresMap(tempScoresMap);
    };

	const syncStudent = async (userId, score) => {
		if (!pollCode) {
			console.log("Invalid pollCode");
			return false;
		}
		if (!userId) {
			console.log("Invalid userId");
			return false;
		}
		if (!isValidScore(score)) {
			console.log("Invalid score");
			return false;
		}
		const res = await gradebookSync(
			pollCode,
			userId,
			score,
			() => {
				syncScore(pollKey, pollCode, userId, true, true);
				setSyncCountMap({...syncCountMap, [userId]: syncCountMap[userId] + 1});
			}
		);
		return res;
	};

	async function saveSelectedStudents (selectedUserIds) {
		if (selectedUserIds.length === 0) { // base case
			return true;
		}
		const selectedUserId = selectedUserIds.pop();
		if (!isValidScore(customScoresMap[selectedUserId][0])) { // invalid score
			console.log("Invalid score", customScoresMap[selectedUserId][0], "for", selectedUserId);
			// return false;
		} else {
			const res = await syncStudent(selectedUserId, customScoresMap[selectedUserId][0]);
			if (res) { 
				console.log("Synced", customScoresMap[selectedUserId][0], "for", selectedUserId);
			} else {
				console.log("Error in Gradebook Sync for", selectedUserId);
			}
		}
		return await saveSelectedStudents(selectedUserIds);
	};
	
	const saveAllStudents = async (students) => {
		// console.log(students);
		for (let student in students) {
			if (!isValidScore(students[student][0])) {
				console.log("Invalid score", students[student][0], "for", student);
				continue;
				// return false;
			}
			const res = await syncStudent(student, students[student][0]);
			if (res) {
				console.log("Synced", students[student][0], "for", student);
			} else {
				console.log("Error in Gradebook Sync for", student);
			}
		}
		return true;
	};


	const alertConfig = {
        title: SWITCH_POLL_MODAL_TITLE,
        okText: SWITCH_POLL_MODAL_TEXT,
        submit: async () => {
			setIsCorrect({});
			setChangedCorrect({});
			setAlertShow(false);
			setModalShowSA(false);
        },
        close:  async () => {
          setAlertShow(false);
        },
      };

	// const autoUpdateScore = (score) => {
	// 	const userId = response.userId;
	// 	console.log(pollKey, pollCode, userId, score);
	// 	setCustomScoresMap({...customScoresMap, [userId]: score});
	// 	updateScore(pollKey, pollCode, userId, score, autoSync);
	// };

	return (
		<>
			<YuJaGeneralAlert
				show={alertShow}
				setModalShow={setAlertShow}
				content={LOSE_CHANGE_MESSAGE}
				config={alertConfig}
			/>
			<Modal
				id="answer-modal"
				show={showAnswerModal}
				onHide={() => setShowAnswerModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				dialogClassName="sa-modal"
				>
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={() => setShowAnswerModal(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0} >
							{`Student's Answer`}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className={styles.answer}>
							{answerModalData}
						</div>
					</Modal.Body>
				</Spin>
			</Modal>
			<Modal
				id="sa-modal"
				show={modalShowSA}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				dialogClassName="sa-grade-modal"
			>
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={handleClose} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0} 
							className="modal-title"
							id="contained-modal-title-vcenter"
						>
							Short Answer Grading 
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<GradeQuestion
							question={question}
							questions={questions}
							attemptNo={attemptNo}
							response={response}
							setResponse={setResponse}
							responses={responses}
							setResponses={setResponses}
							institutionId={institutionId}
							pollKey={pollKey}
							isCorrect={isCorrect}
							setIsCorrect={setIsCorrect}
							// pollCode={pollCode}
							pollTitle={pollTitle}
							setChangedCorrect={setChangedCorrect}
							// handleClose={handleClose}
							// autoUpdateScore={autoUpdateScore}
							// autoSync={autoSync}
						/>
					</Modal.Body>
					<Modal.Footer>
						<YuJaButton type="secondary"  onClick={handleClose}>
							{MODAL_CANCEL_TEXT}
						</YuJaButton>
						<YuJaButton  onClick={handleSave}>
							{MODAL_SAVE_TEXT}
						</YuJaButton>
					</Modal.Footer>
				</Spin>
			</Modal>
			<Modal
				id="question-modal"
				show={modalShow}
				onHide={() => setModalShow(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={loading ? "static" : true}
				dialogClassName="question-modal"
			>
				<Spin tip="Loading..." size="large" spinning={loading}>
					<ModalClose tabIndex={0} onClick={() => setModalShow(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0} >
							{`Question #${question.serialNo}`}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<TabQuestion
							question={question}
							pollType={pollType}
							institutionId={institutionId}
							pollKey={pollKey}
							forGradebook={true}
						/>
					</Modal.Body>
				</Spin>
			</Modal>
			<div className={`${styles.tableContainer} ${pollType.toUpperCase() === POLL_TYPE.SURVEY ?
								styles.surveyTableContainer :
									// (showCheckbox ?
										styles.gradeBookTableContainerWCheckbox
										// : styles.gradeBookTableContainer)
			}`}>
				<Table
					responsive
					hover
					// {...getTableProps()}
				>
				{/* <table> */}
					<thead>
					{getHeaderGroups().map(headerGroup => (
						<tr
							// {...headerGroup.getHeaderGroupProps()}
							key={headerGroup.id}
						>
							{headerGroup.headers.map(column => {
								// console.log(column);
								if (GRADE_BOOK_QUESTION_COLUMN_REGEX.test(column.id)) {
									let icon = '';
									let questionType = QUESTION_TYPES[column.column.columnDef.header].description;
									switch (column.column.columnDef.header) {
										case QUESTION_TYPES.MCSS.name:
											icon = MCSS_ICON;
											break;
										case QUESTION_TYPES.TF.name:
											icon = TF_ICON;
											break;
										case QUESTION_TYPES.SA.name:
											icon = SA_ICON;
											break;
										case QUESTION_TYPES.FITB.name:
											icon = FITB_ICON;
											break;
										case QUESTION_TYPES.CI.name:
											icon = CI_ICON;
											break;
										case QUESTION_TYPES.WC.name:
											icon = WC_ICON;
											break;
										case QUESTION_TYPES.MH.name: 
											icon = MH_Icon; 
										default:
									}
									return (
										<th {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box"}
										}}>
											<button tabIndex={0} aria-label={VIEW_QUES + column.id} className={styles.questionButton} onClick={() => showQuestion(column.id.substring(1))}>
												<img src={icon} alt={questionType} style={{width: "20px", height: "20px"}} />
												<div>{column.id}</div>
											</button>
										</th>
									);
								} else if (column.id === SCORE_COLUMN_ID) {
									if (pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
									return (
										<th tabIndex={0} {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box"}
										}}>
											Score (in %)
										</th>
									);
								} else if (column.id === USER_ID_COLUMN_ID) { // Remove block to show User ID Header
									// console.log(column.getValue());
								} else if (column.id === CHECKBOX_COLUMN_ID) {
									// console.log(column.column.columnDef.header, column.getContext());
									return (
										<td {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box"}
										}}>
											{flexRender(column.column.columnDef.header, column.getContext())}
										</td>
									);
								} else {
									// console.log(column.column.columnDef.header, column.getContext());
									return (
										<th tabIndex={0} {...{
											// ...column.getHeaderProps(),
											key: column.id,
											style: {width: column.getSize(), backgroundClip: "padding-box"}
										}}>
											{flexRender(column.column.columnDef.header, column.getContext())}
										</th>
									);
								}
							})}
						</tr>
					))}
					</thead>
					<tbody
						// {...getTableBodyProps()}
					>
					{getRowModel().rows.map((row, i) => {
						// console.log(i);
						// prepareRow(row);
						// console.log(i, row);
						return (<>
							<tr
								key={row.id}
								onClick={row.getToggleExpandedHandler()}
								onMouseEnter={() => setHoveredRow(row.id)}
								onMouseLeave={() => setHoveredRow(-1)}
								// {...row.getRowProps()}
								// {...getToggleRowExpandedPropsWrapper(row)}
								// className={!row.getCanExpand() && styles.gradebookTableRow}
							>
								{row.getVisibleCells().map(cell => {
									if (GRADE_BOOK_QUESTION_COLUMN_REGEX.test(cell.column.id)) {
										// console.log(cell.column.columnDef.header, cell.getValue());
										if (cell.getValue() === undefined) {
											return (<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>Not Posted</td>)
										}
										if (pollType.toUpperCase() === POLL_TYPE.SURVEY) {
											if (cell.getValue() === NO_RESPONSE) {
												return (<td tabIndex={0} {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>{cell.getValue()}</td>);
											}
											if (cell.column.columnDef.header === QUESTION_TYPES.MCSS.name) {
												if (row.original[cell.column.id.replace('Q', 'A')][0] === FIXED_ANSWER.UNANSWERED) {
													return (<td tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>No Response</td>)
												}
												let array = row.original[cell.column.id.replace('Q', 'A')].sort((a, b) => a.localeCompare(b));
												return (
													<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
												 		<div className={styles.gradeBookSurvey} title={'MCSS'}>
												 			{array.map((key, index) => {return (
																<div tabIndex={0}
																	key={index}
																	style={{display: 'inline-flex'}}
																>
																	{key.toUpperCase()}
																</div>
															)})}
												 		</div>
													</td>
												);
											}
											if (cell.column.columnDef.header === QUESTION_TYPES.TF.name) {
												return (
													<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
														<div tabIndex={0} className={styles.gradeBookSurvey} title={'TF'}>
															{row.original[cell.column.id.replace('Q', 'A')]}
														</div>
													</td>
												);
											}
											if (cell.column.columnDef.header === QUESTION_TYPES.CI.name) {
												if (row.original[cell.column.id.replace('Q', 'A')].selected == 'other') {
													return (
														<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
															<div tabIndex={0} className={styles.gradeBookSurvey} title={'CI'}>
																other
															</div>
														</td>
													);
												}
												return (
													<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
														<div tabIndex={0} className={styles.gradeBookSurvey} title={'CI'}>
															{String.fromCharCode(65 + parseInt(row.original[cell.column.id.replace('Q', 'A')].selected, 10))}
														</div>
													</td>
												);
											}
											// SA, FITB or WC
											return (
												<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
													<div
														className={getAttemptNo(row) === 0 ? styles.gradeBookViewDisabled : styles.gradeBookView}
														title={'show answer'}
														tabIndex={0}
														onClick={() => {
															if (row.getCanExpand()) return;
															if (cell.column.columnDef.header === QUESTION_TYPES.SA.name) {
																setAnswerModalData(row.original[cell.column.id.replace('Q', 'A')]['0']);
															} else if (cell.column.columnDef.header === QUESTION_TYPES.FITB.name) {
																let array = Object.keys(row.original[cell.column.id.replace('Q', 'A')]);
																setAnswerModalData(array.map((key, index) => {return (
																	<p
																		key={index}
																		style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-all' }}
																	>
																		{key}: {row.original[cell.column.id.replace('Q', 'A')][key]}
																	</p>
																)}));
															} else if (cell.column.columnDef.header === QUESTION_TYPES.WC.name) {
																let array = Object.values(row.original[cell.column.id.replace('Q', 'A')])
																	.filter(key => key !== "");
																setAnswerModalData(array.map((key, index) => {
																	if (key === "") return <></>;
																	console.log(key);
																	return (
																	<p
																		key={index}
																		style={{ marginTop: 6, marginBottom: 6, wordBreak: 'break-all' }}
																	>
																		Entry {index+1}: {key}
																	</p>
																)}));
															} else {
																console.log(INVALID_QUESTION_TYPE);
															}
															setShowAnswerModal(true);
														}}
													>
														{/* <img src={showAnswer} alt={'showAnswerImg'}/> */}
														View
													</div>
												</td>
											);
										}
										// Graded Poll:
										if (cell.getValue() === CORRECT) {
											return (
												<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
													<div tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} className={styles.gradeBookCorrect} title={CORRECT} onClick={() => showGradeModal(row, cell)}>
														{cell.column.columnDef.header == QUESTION_TYPES.MCSS.name ?
														<div> {row.original[cell.column.id.replace(/Q/, "A")].toUpperCase()} </div> 
														: cell.column.columnDef.header == QUESTION_TYPES.CI.name &&  row.original[cell.column.id.replace(/Q/, "A")].selected != 'other' ? 
														<div> {String.fromCharCode(65 + parseInt(row.original[cell.column.id.replace(/Q/, "A")].selected, 10))} </div> 
														: <img src={correct} alt={'correctImg'}/>
														}
													</div>
												</td>
											)
										}
										if (cell.getValue() === INCORRECT) {
											return (
												<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
													<div tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} className={styles.gradeBookIncorrect} title={INCORRECT} onClick={() => showGradeModal(row, cell)}>
														{cell.column.columnDef.header == QUESTION_TYPES.MCSS.name ?
														<div> {row.original[cell.column.id.replace(/Q/, "A")].toUpperCase()} </div> 
														: cell.column.columnDef.header == QUESTION_TYPES.CI.name &&  row.original[cell.column.id.replace(/Q/, "A")].selected != 'other' ?
														<div> {String.fromCharCode(65 + parseInt(row.original[cell.column.id.replace(/Q/, "A")].selected, 10))} </div> 
														: <img src={incorrect} alt={'incorrectImg'}/> 
														}
													</div>
												</td>
											)
										}
										if (cell.getValue() === NOT_GRADED) {
											return (
												<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
													<div tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()}
														className={isDisabled(row, customScoresMap, lmsAttempt) ? styles.gradeBookNotGradedDisabled : styles.gradeBookNotGraded}
														title={NOT_GRADED}
														onClick={() => showGradeModal(row, cell)}
													>
														<img src={isDisabled(row, customScoresMap, lmsAttempt) ? notGradedDisabled : notGraded} alt={'notGradedImg'}/>
													</div>
												</td>
											)
										}
										return (<td tabIndex={0} role={TEXTBOX} aria-label={QUES_LABEL_1 + cell.column.id.substring(1) + " " + cell.column.columnDef.header + " " + cell.getValue()} {...{key: cell.id, style: {width: cell.column.getSize()}}} onClick={() => showGradeModal(row, cell)}>{cell.getValue()}</td>)
									} else if (cell.column.id === SCORE_COLUMN_ID) {
										if (pollType.toUpperCase() === POLL_TYPE.SURVEY) return <></>;
										// console.log(`customScoreInput-${getUserId(row)}-${getAttemptNo(row)}`);
										return (
											<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box", height: "fit-content"}}}>
												<div tabIndex={0} role={TEXTBOX} aria-label={SCORE_LABEL + (customScoresMap &&
																customScoresMap[getUserId(row)] &&
																customScoresMap[getUserId(row)][getAttemptNo(row)] ?
																customScoresMap[getUserId(row)][getAttemptNo(row)] : 'score') + " percent"} className={styles.customScoreBox}>
													<input
														className={styles.customScoreInput}
														id={`customScoreInput-${getUserId(row)}-${getAttemptNo(row)}`}
														type="text"
														// pattern="[0-9.]*"
														disabled={isDisabled(row, customScoresMap, lmsAttempt)}
														placeholder="00.00"
														aria-label={SCORE_LABEL + (customScoresMap &&
																customScoresMap[getUserId(row)] &&
																customScoresMap[getUserId(row)][getAttemptNo(row)] ?
																customScoresMap[getUserId(row)][getAttemptNo(row)] : 'score') + " percent"}
														value={customScoresMap &&
																customScoresMap[getUserId(row)] &&
																customScoresMap[getUserId(row)][getAttemptNo(row)] ?
																customScoresMap[getUserId(row)][getAttemptNo(row)] : ''}
														onChange={(e) => { onChangeCustomScore(e, getUserId(row), getAttemptNo(row));}}
														onBlur={(e) => { onBlurCustomScore(e, getUserId(row), getAttemptNo(row));}}
														style={{
															border: (
																isDisabled(row, customScoresMap, lmsAttempt) ||
																(customScoresMap &&
																customScoresMap[getUserId(row)] &&
																customScoresMap[getUserId(row)][getAttemptNo(row)] &&
																isValidScore(customScoresMap[getUserId(row)][getAttemptNo(row)]))
															) ?
																"transparent" : "1px solid #FF0000"
														}}
													/>
												</div>
											</td>
										);
									} else if (cell.column.id === USER_ID_COLUMN_ID) { // Remove block to show User ID
										// console.log(cell.getValue());
									} else if (cell.column.id === CHECKBOX_COLUMN_ID) {
										return (
											<td
												className={hoveredRow === row.id ? styles.hoveredRow : ''}
												{...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</td>
										);
									} else if (cell.column.id === STUDENT_COLUMN_ID) {
										// console.log(cell.column.columnDef.cell, cell.getValue());
										return (
											<td {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
												<div
													tabIndex={0} role={TEXTBOX} aria-label={cell.getValue().includes("Attempt") ? (cell.getValue() + ATMPT_LABEL_2) : (STD_LABEL + cell.getValue())} className={ATTEMPT_REGEX.test(cell.getValue()) ? styles.subRow : styles.mainRow}
												>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</div>
											</td>
										);
									} else {
										// console.log(cell);
										return (
											<td tabIndex={0} {...{key: cell.id, style: {width: cell.column.getSize(), backgroundClip: "padding-box"}}}>
												{/* {flexRender(cell.column.columnDef.cell, cell.getContext())} */}
												{cell.getValue()}
											</td>
										);
									}
								})}
							</tr>
							{/* Space between last subrow and next main row */}
							{/* {!isLastRow(row, getRowModel().rows) && getAttemptNo(row) === getTotalAttempts(row, customScoresMap) && <tr style={{height: "30px"}} />} */}
						</>)
					})}
					</tbody>
				</Table>

				<NormalPagination
					pageCount={getPageCount()}
					pageIndex={getState().pagination.pageIndex}
					pageOptions={pageOptions}
					canPreviousPage={getCanPreviousPage()}
					canNextPage={getCanNextPage()}
					previousPage={previousPage}
					nextPage={nextPage}
					gotoPage={setPageIndex}
				/>

			</div>
		</>
	)
}