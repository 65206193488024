import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import NormalPagination from "../../components/pagination/NormalPagination";
import { COURSE_CODE, COURSE_CODE_LABEL, COURSE_NAME_LABEL, DEFAULT_ROWS_PER_PAGE, VIEW_TEXT } from "../../utils/constants";
import styles from "./EnrolledCourses.module.css";

export default React.memo(EnrolledCoursesTable);
function EnrolledCoursesTable({ data = [], columns = [], globalFilterObj = {}}) {
    const history = useHistory();

    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        const filteredDate = globalFilterValue.date;
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.courseName && values.courseName.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            if (values.courseCode && values.courseCode.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        }).filter(r => {
            if (!filteredDate) {
                return true;
            }
            const values = r.values;
            return values.startDate && values.startDate === filteredDate;
        });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: DEFAULT_ROWS_PER_PAGE },
        autoResetPage: true,
        globalFilter: filterCustomize,
    }, useGlobalFilter, usePagination);

    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [globalFilterObj, setGlobalFilter])

    const onView = (courseId) => {
        history.push(`/course/content/${courseId}`);
    }

    return (
        <>
            <div className={styles.tableContainer}>
                <Table responsive hover {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th tabIndex={0} {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth
                                        }
                                    })}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.column.Header === 'View Course') {
                                            return (
                                                <td tabIndex={0} {...cell.getCellProps()} className={styles.tableBtnUnit}>
                                                    <div
                                                        className={styles.tablePublishBtn}
                                                        onClick={() => onView(cell.row.values['courseId'])}
                                                    >
                                                        {VIEW_TEXT}
                                                    </div>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td tabIndex={0} aria-label={cell.column.Header === COURSE_CODE ? COURSE_CODE_LABEL + cell.value : COURSE_NAME_LABEL + cell.value} {...cell.getCellProps()}>
                                                    {cell.value}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                
                <NormalPagination
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                />
            </div>
        </>
    );
}
