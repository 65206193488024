import React from "react";
import { updateCorrectAnswers } from "../../utils/questionUpdate";
import TFQuestionOption from "./TFQuestionOption";

export default function TFQuestion({
  setQuestions,
  questions,
  selectedQuestion,
  pollType
}) {
  const { optionsMap, serialNo, correctAnswers } = selectedQuestion;
  const indexToChange = serialNo - 1;

  const handleDeleteOption = (option) => {
    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        var newOptionsMap = [...q.optionsMap];

        var index = newOptionsMap.indexOf(option);
        if (index !== -1) {
          newOptionsMap.splice(index, 1);
        }

        newQuestions.push({
          ...q,
          optionsMap: newOptionsMap,
          correctAnswers: option === q.correctAnswers ? "" : q.correctAnswers,
        });
      } else {
        newQuestions.push({ ...q });
      }
    });

    setQuestions(newQuestions);
  };

  return (
    <>
      <div className="container question-main-container" style={{overflowY: "auto", maxHeight: "20vh", padding: "0px"}}>
        <>
          {optionsMap.map((option, index) => {
            return (
              <div key={index}>
                <TFQuestionOption
                  option={option}
                  correctAnswers={correctAnswers}
                  handleCorrectAnswerChange={(input) => {
                    setQuestions(
                      updateCorrectAnswers(input, indexToChange, questions)
                    );
                  }}
                  handleDeleteOption={handleDeleteOption}
                  optionsLength={optionsMap.length}
                  index={index}
                  pollType={pollType}
                />
              </div>
            );
          })}
        </>
      </div>
    </>
  );
}
