import React, { useEffect, useRef, useState } from "react";
// import { Tab, Tabs } from "react-bootstrap";
import * as FileSaver from "file-saver";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import XLSX from "sheetjs-style";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import GradebookWarningModal from "../../components/modals/GradebookWarningModal";
// import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import { CREATOR_MENU_LIST, FIXED_ANSWER, GRADEBOOK_DEFAULT_MESSAGE, GRADEBOOK_DROPDOWN, POLL_TEXT, POLL_TITLE_DROPDOWN_LENGTH, POLL_TYPE, QUESTION_TYPES, SURVEY, SYNC_ATTEMPT, TEXTBOX } from "../../utils/constants";
import { notifyError, prepareAttemptSubrows, removePrefix, removePrefixNew } from "../../utils/helpers";
import styles from "./GradeBook.module.css";
import GradeBookTabs from "./GradeBookTabs";
import { MISSING_OPTION_ERROR } from "../../utils/toast-message-constants";
import YuJaButton from "../../components/standardization/YuJaButton";
import { ReactComponent as EmptyGradeBook } from "../../images/gradebook_empty.svg";
// import QuestionBreakdown from "./QuestionBreakdown";
// import StudentBreakdown from "./StudentBreakdown";

export default function GradeBookPage(props) {
  const location = useLocation();  
  const { getPollsFromUser } = PollApiCalls();
  const { getResultByGradeBookPerspective, getPollRecordFromUniqueCode } = ResponseApiCalls();
  const { getSetting } = InstitutionApiCalls();

  const [options, setOptions] = useState([]);
  const [updatedResponses, setUpdatedResponses] = useState([]);
  // const [questionBreakDownData, setQuestionBreakDownData] = useState([]);
  // const [studentBreakDownData, setStudentBreakDownData] = useState([]);
  const [gradeBookData, setGradeBookData] = useState({ responses: [], questions: [] });
  const [selectedOption, setSelectedOption] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [downloadResultsBtn, setDownloadResultsBut] = useState(false);
  const [exportGradebookBtn, setExportGradebookBtn] = useState(false);
  const [syncGradebookBtn, setSyncGradebookBtn] = useState(false);
  const [showSyncOptions, setShowSyncOptions] = useState(false);
  const [autoSyncData, setAutoSyncData] = useState(null);
  const [syncMode, setSyncMode] = useState(0);
  const [fileName, setFileName] = useState("");
  const [excelData, setExcelData] = useState([]);
  // const [, getSession] = useLocalStorageNew("session", {});
  const pk = useRef("");
  const pc = useRef("");
  const pollTitle = useRef("");
  const pollType = useRef("");
  const lmsAttempt = useRef("");
  const [warningModalShow, setWarningModalShow] = useState(false);
  
  const getPlatformSetting = () => {
    return getSetting().then((result) => {
      // console.log(result);
      if (!!result && !!result.LMSQuizAttempts) {
        // console.log(result.LMSQuizAttempts);
        return result.LMSQuizAttempts;
      } else {
        return SYNC_ATTEMPT.FIRST.value; // default
      }
    });
  };

  useEffect(() => {
    if(document.getElementById("engagePageIdentifier") != null) {
      document.getElementById("engagePageIdentifier").innerText = CREATOR_MENU_LIST[3].name;
    }
  }, []);

  useEffect(async () => {
    if (location && location.state && location.state.option) {
      let poll = location.state.option; 
      const defaultLmsAttempt = await getPlatformSetting();
      let option = {label: ((poll.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (poll.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : poll.pollTitle) +
                              " (" + removePrefixNew(poll.pollSortKey) + ")",
                    value: `${removePrefixNew(poll.pollKey)}#${removePrefixNew(poll.pollSortKey)}`,
                    pollTitle: `${poll.pollTitle.trim()}`,
                    pollType: `${poll.pollType}`,
                    inCourse: !!poll.courseId,
                    lmsAttempt: poll.lmsAttempt ? poll.lmsAttempt : defaultLmsAttempt
                  }
      setSelectedOption(option);
      await getResult(option);
      if (!!location.state.autoSync) {
        console.log(location.state.autoSync);
        setAutoSyncData(location.state.autoSync);
        location.state.autoSync = null;
      }
      console.log(location.state.option);
      const res = await getPollsFromUser(true);
    // console.log(defaultLmsAttempt);
    // sort res by last updatedtime
    res.sort((poll1, poll2) => {
      let date1 = new Date(poll1.updatedTimeSK);
      let date2 = new Date(poll2.updatedTimeSK);
      return date2 - date1;
    })
    
    const newOptions = res.filter(poll => poll.pollSortKey !== 'Draft')
      .map(poll => ({ label: ((poll.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (poll.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : poll.pollTitle) +
                              " (" + removePrefixNew(poll.pollSortKey) + ")",
                    value: `${removePrefixNew(poll.pollKey)}#${removePrefixNew(poll.pollSortKey)}`,
                    pollTitle: `${poll.pollTitle.trim()}`,
                    pollType: `${poll.pollType}`,
                    inCourse: !!poll.courseId,
                    lmsAttempt: poll.lmsAttempt ? poll.lmsAttempt : defaultLmsAttempt
                  }));
    setOptions(newOptions);
    }
    else {
    const res = await getPollsFromUser(true);
    const defaultLmsAttempt = await getPlatformSetting();
    // console.log(defaultLmsAttempt);
    // sort res by last updatedtime
    res.sort((poll1, poll2) => {
      let date1 = new Date(poll1.updatedTimeSK);
      let date2 = new Date(poll2.updatedTimeSK);
      return date2 - date1;
    })
    
    const newOptions = res.filter(poll => poll.pollSortKey !== 'Draft')
      .map(poll => ({ label: ((poll.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (poll.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : poll.pollTitle) +
                              " (" + removePrefixNew(poll.pollSortKey) + ")",
                    value: `${removePrefixNew(poll.pollKey)}#${removePrefixNew(poll.pollSortKey)}`,
                    pollTitle: `${poll.pollTitle.trim()}`,
                    pollType: `${poll.pollType}`,
                    inCourse: !!poll.courseId,
                    lmsAttempt: poll.lmsAttempt ? poll.lmsAttempt : defaultLmsAttempt
                  }));
    setOptions(newOptions);
    
    
    if (location && location.state && location.state.pollKey && location.state.uniqueCode) {
      const key = `${location.state.pollKey}#${location.state.uniqueCode}`;
      const option = newOptions.find(ele => ele.value === key);
      setSelectedOption(option);
      await getResult(option);
      // setSyncGradebookBtn(!!location.state.syncGradebookBtn ? location.state.syncGradebookBtn : false);
      if (!!location.state.autoSync) {
        console.log(location.state.autoSync);
        setAutoSyncData(location.state.autoSync);
        location.state.autoSync = null;
      }
    }
  }
  }, [location]);
  
  const getResult = async (key) => {
    setShowTable(false);
    setSelectedOption(key);
    key = !!key ? key : selectedOption;
    
    if (!key) {
      notifyError(MISSING_OPTION_ERROR);
      return;
    }
    const arr = key.value.split("#");
    const [pollKey, uniqueCode] = arr;
    pk.current = removePrefix(pollKey);
    pc.current = uniqueCode;
    pollTitle.current = key.pollTitle;
    pollType.current = key.pollType.toUpperCase();
    lmsAttempt.current = key.lmsAttempt;
    console.log(lmsAttempt.current);
    
    const pollRecord = await getPollRecordFromUniqueCode(uniqueCode);
    const res = await getResultByGradeBookPerspective(pollKey, uniqueCode);
    const courseId = pollRecord.poll.index2Pk;
    const {
      gradeBookBreakdownResult,
      // studentBreakdownResult,
      // questionBreakdownResult
    } = res;
    gradeBookBreakdownResult.questions.sort((a, b) => { // sort questions by serialNo
      return a.serialNo - b.serialNo;
    });
    const responsesWithSubrows = prepareAttemptSubrows(gradeBookBreakdownResult.responses);
    setUpdatedResponses(responsesWithSubrows);
    let pollTitleColumnName = "External Tool: " + key.pollTitle;
    let excelDataFormat = [];
    let numQuestions = gradeBookBreakdownResult.questions.length;
    for (let element of responsesWithSubrows) {
      if (!element.attempts || !element.attempts[0]) console.log("Missing Attempt #0 in ", element);
      let attempt = !!element.attempts && element.attempts[0] ? element.attempts[0] : element;
      // console.log(element, attempt);
      let excelEntry = {
        "Student": element.firstName + (element.lastName ? " " + element.lastName : ""),
        "IP Address": element.ipAddress,
        "Responded By": element.browserType,
        "Platform": element.platform
      };
      for (let i = 0; i < numQuestions; i++) {
        let questionNum = "Q" + (i+1);
        let answerNum = "A" + (i+1);
        if (pollType.current === POLL_TYPE.SURVEY) {
          if (!element[questionNum]) {
            excelEntry[questionNum] = "Not Posted";
            continue;
          }
          if (element[questionNum] === "No Response") {
            excelEntry[questionNum] = "No Response";
            continue;
          }
          // console.log(gradeBookBreakdownResult.questions[i]);
          let questionType = gradeBookBreakdownResult.questions[i].questionType;
          // console.log(questionType);
          // console.log(element[answerNum]);
          if (questionType === QUESTION_TYPES.MCSS.name) {
            if (element[answerNum][0] === FIXED_ANSWER.UNANSWERED) {
              excelEntry[questionNum] = "No Response";
              continue;
            }
            let resp = '';
            element[answerNum].sort((a, b) => a.localeCompare(b)).map((key) => {resp += key.toUpperCase()});
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.TF.name) {
            excelEntry[questionNum] = element[answerNum];
          } else if (questionType === QUESTION_TYPES.SA.name) {
            excelEntry[questionNum] = element[answerNum][0];
          } else if (questionType === QUESTION_TYPES.FITB.name) {
            let resp = '';
            Object.keys(element[answerNum]).map((key, index) => {
              // console.log(`${index === 0 ? '' : ', '}${key}: ${element[answerNum][key]}`);
              resp += `${index === 0 ? '' : ', '}${key}: ${element[answerNum][key]}`;
            });
            excelEntry[questionNum] = resp;
          } else if (questionType === QUESTION_TYPES.CI.name) {
            if (element[answerNum].selected === 'other') {
              excelEntry[questionNum] = "other";
              continue;
            }
            excelEntry[questionNum] = String.fromCharCode(65 + parseInt(element[answerNum].selected, 10));
          } else {
            excelEntry[questionNum] = "Unknown Q Type" + questionType;
          }
          // console.log(excelEntry[questionNum]);
        } else {
          excelEntry[questionNum] = element[questionNum] ? element[questionNum] : "Not Posted";
        }
      }
      if (pollType.current !== POLL_TYPE.SURVEY) {
        // console.log(element.score);
        if (courseId === null || courseId === undefined) {
          excelEntry["Overall Result"] = attempt.score && attempt.score.includes("%") ? attempt.score.substring(0, attempt.score.length - 1) : attempt.score;
        } else {
          excelEntry["User ID"] = element.tpUId;
          excelEntry[pollTitleColumnName] = attempt.score && attempt.score.includes("%") ? attempt.score.substring(0, attempt.score.length - 1) : attempt.score;
        }
      }
      // console.log(excelEntry);
      excelDataFormat.push(excelEntry);
    }
    console.log(gradeBookBreakdownResult);
    setGradeBookData(gradeBookBreakdownResult);
    // setStudentBreakDownData(studentBreakdownResult);
    // setQuestionBreakDownData(questionBreakdownResult);
    setSyncGradebookBtn(pollType.current !== POLL_TYPE.SURVEY && !!key.inCourse);
    setShowTable(true);
    // console.log(excelDataFormat);
    setExcelData(excelDataFormat);
    setFileName(selectedOption.pollTitle + " Class Results");
    if (pollType.current === POLL_TYPE.SURVEY || courseId === null || courseId === undefined) {
      setDownloadResultsBut(gradeBookBreakdownResult.responses.length > 0);
      setExportGradebookBtn(false);
    } else {
      setDownloadResultsBut(false);
      setExportGradebookBtn(gradeBookBreakdownResult.responses.length > 0);
    }

  };

  const exportExcel = async () => {
    // const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const fileExtension = ".csv";
    // const wb = { Sheets: {'data':ws }, SheetNames: ['data']};
    // const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array"});
    // const data = new Blob([excelBuffer], { type: fileType});
    // FileSaver.saveAs(data, fileName + fileExtension);
    const key = selectedOption;
    const arr = key.value.split("#");
    const [pollKey, uniqueCode] = arr;
    pk.current = removePrefix(pollKey);
    pc.current = uniqueCode;
    pollTitle.current = key.pollTitle;
    pollType.current = key.pollType.toUpperCase();
    lmsAttempt.current = key.lmsAttempt;
    // console.log(lmsAttempt.current);

    const pollRecord = await getPollRecordFromUniqueCode(uniqueCode);
    const courseId = pollRecord && pollRecord.poll ? pollRecord.poll.index2Pk : null;
    if (pollType.current === POLL_TYPE.SURVEY || courseId === undefined || courseId === null) {
      handleExportFromModal();
    } else {
      setWarningModalShow(true);
    }
  };

  const handleExportFromModal = () => {
    const fileType = "application/octet-stream";
    const fileExtension = ".csv";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const csvOutput = XLSX.utils.sheet_to_csv(ws);
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
    
    const data = new Blob([s2ab(csvOutput)],{type:fileType});
    FileSaver.saveAs(data, pollTitle.current + " Class Results" + fileExtension);
    setWarningModalShow(false);
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.body}>
        <div className={styles.titleBox}>
          <div className={styles.titleBoxIcon} />
          <div tabIndex={0} className={styles.titleBoxTitle}>{CREATOR_MENU_LIST[3].name}</div>
        </div>
        <div className={styles.mainDiv}>
        <div className={styles.searchBox} style={showSyncOptions ? {paddingBottom: "110px"} : {}}>
          <div tabIndex={0} role={TEXTBOX} style={{ marginRight: "10px" }}>{"Published Poll:"}</div>
          <Select
            className={styles.select}
            options={options}
            onChange={getResult}
            value={selectedOption}
            id="gradebookSelectPoll"
            aria-label={GRADEBOOK_DROPDOWN}

          />

          {downloadResultsBtn &&
              <YuJaButton type="secondary" onClick={exportExcel} style={{margin: "0 15px"}}>Download Results [.csv]</YuJaButton>
          }

          {exportGradebookBtn &&
              <YuJaButton type="secondary" onClick={exportExcel} style={{margin: "0 15px"}}>Export Gradebook [.csv]</YuJaButton>
          }

          {syncGradebookBtn &&
            <div className={styles.syncGradebookContainer}>
              <YuJaButton type="secondary" onClick={() => setShowSyncOptions(!showSyncOptions)} style={{marginRight: 10}}>
                Sync GradeBook
              </YuJaButton>
              {showSyncOptions &&
                <div className={styles.syncGradebookOptions}>
                  <YuJaButton type="secondary" onClick={() => setSyncMode(1)}>On-Demand LMS Student Gradebook Sync</YuJaButton>
                  <YuJaButton type="secondary" onClick={() => setSyncMode(2)}>On-Demand LMS Quiz Gradebook Sync</YuJaButton>
                </div>
              }
            </div>
          }
        </div>

        {!showTable && <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <EmptyGradeBook/>
          <div className={styles.emptyTitle}>{GRADEBOOK_DEFAULT_MESSAGE}</div>
        </div>}

        {/* <Tabs defaultActiveKey="gradeBook" className="mb-3"> */}
          {/* <Tab eventKey="gradeBook" title="Grade Book"> */}
        {showTable &&
          <>
            <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
              <div tabIndex={0} role={TEXTBOX} className={styles.pollTitleTitle}>{pollType.current === POLL_TYPE.SURVEY ? SURVEY : POLL_TEXT} Title:</div>
              <div tabIndex={0} role={TEXTBOX} className={styles.pollTitleText}>{pollTitle.current}</div>
            </div>
            <GradeBookTabs
              responses={updatedResponses}
              setResponses={setUpdatedResponses}
              questions={gradeBookData.questions}
              pollKey={pk.current}
              pollCode={pc.current}
              pollTitle={pollTitle.current}
              pollType={pollType.current}
              lmsAttempt={lmsAttempt.current}
              syncMode={syncMode}
              setSyncMode={setSyncMode}
              showCheckbox={syncGradebookBtn}
              autoSyncData={autoSyncData}
              setAutoSyncData={setAutoSyncData}
            />
          </>
        }
          {/* </Tab> */}
          {/* <Tab eventKey="student" title="Student Breakdown">
            <StudentBreakdown data={studentBreakDownData}/>
          </Tab>
          <Tab eventKey="question" title="Question Breakdown">
            <QuestionBreakdown data={questionBreakDownData}/>
          </Tab> */}
        {/* </Tabs> */}

      </div>
      <GradebookWarningModal
        show={warningModalShow}
        setModalShow={setWarningModalShow}
        handleExportFromModal={handleExportFromModal}
      />
    </div>
    </div>
  );
}