import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as FcMenu } from "../images/HamburgerIcon.svg";
import useMobileAccess from "../hooks/useMobileAccess";

export default function ResponsiveSidebar({
  children,
  menuClassName,
  className,
  setCollapsed,
  collapsed = false
}) {

  const isMobile = useRef(useMobileAccess(1200));
  const sidenavToggleArrow = {
    cursor: "pointer",
    marginLeft: "5px",
  };
  const mobileSideNavToggleArrow = {
    cursor: "pointer",
    marginLeft: "0px",
    color: "#0277AD"
  };
  const [sideNavBarShow, setSideNavBarShow] = useState(!isMobile.current);
  const [menuButtonShow, setMenuButtonShow] = useState(isMobile.current);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobile.current && ref.current && !ref.current.contains(event.target)) {
        setCollapsed(true);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (collapsed) {
      setMenuButtonShow(true);
      setSideNavBarShow(false);
    } else {
      setMenuButtonShow(false);
      setSideNavBarShow(true);
    }
  }, [collapsed]);

  useEffect(() => {
    isMobile.current = window.innerWidth < 1200;
    setSideNavBarShow(!isMobile.current);
    setMenuButtonShow(isMobile.current);
  }, [window.innerWidth]);

  return (
    <>
      <div className={className} ref={ref} style={isMobile.current ? {width: sideNavBarShow ? 240 : 0} : {}}>
        {children}
      </div>

      {menuButtonShow && (
        <div className={menuClassName} >
          <FcMenu
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            style={isMobile.current ? mobileSideNavToggleArrow : sidenavToggleArrow}
            size={30}
            tabIndex={0}
          />
        </div>
      )}
    </>
  );
}
