import React, { useEffect} from "react";
import styles from "./styles.module.css";
import {ReactComponent as PollEndedIcon} from "./images/poll_ended_new.svg";
import { CLOSED_GRADED_POLL, CLOSED_SURVEY, POLL_TYPE, REATTEMPT_CLOSED_POLL, REATTEMPT_CLOSED_SURVEY, SUPPORT_PREFIX, YUJA_ENGAGE_SUPPORT,  TEXTBOX} from "../../utils/constants";
import NetworkStatus from "./NetworkStatus";
import { newPageLog } from "../../utils/helpers";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function EndedPollNew({pollTitle, pollType, websocketState}) {

    const isNarrow = useMobileAccess(1200); 

    useEffect(() => {
      newPageLog("EndedPollNew.js");
    }, []);

    return (
        <div className={styles.endedPollBackground} style={{justifyContent: "normal"}}>
            <div className="waiting-room-title-container" style={{height: "fit-content" , width: "calc(100% - 36px)", marginBottom: "50px", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between"}}> 
            <span tabIndex={0} role={TEXTBOX} className="waiting-room-title" style={{wordBreak:"break-all", paddingLeft: !isNarrow? "170px" : "48px", textAlign: "center", flex: "1"}}>{pollTitle}</span>
            <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState}/> </div>
            </div>
            <PollEndedIcon className={styles.endedPollIcon}/>
            <p className={styles.endedPolllSubtitle}>{pollType === POLL_TYPE.GRADED_POLL ? CLOSED_GRADED_POLL : CLOSED_SURVEY}</p>
            <p className={styles.endedPolllSubtext}>{pollType === POLL_TYPE.GRADED_POLL ? REATTEMPT_CLOSED_POLL : REATTEMPT_CLOSED_SURVEY}</p>
            <p className={styles.endedPollText}>{SUPPORT_PREFIX} <a href="https://support.yuja.com/hc/en-us" target="_blank">{YUJA_ENGAGE_SUPPORT}</a></p>
        </div>
    );
}
