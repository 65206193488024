import React, {useEffect} from "react";
import styles from "./Presentation.module.css";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default function QuestionOptions({total, options, setOptions, serialNo}) {

    useEffect(() => {
        setTimeout(() => {
            options.forEach((entry, index) => {
                document.getElementById(`${serialNo}option${index}`).style.width = `${!!total ? (entry.value / total) * 100 : 0}%`;
            });
        }, 300);
    }, [options, total]);

    const onMouseOver = (props) => {
        const target = props.target;
        if (target.scrollWidth > target.offsetWidth) {
            const idx = parseInt(target.id.substring('optionText'.length));
            const dataCopy = JSON.parse(JSON.stringify(options));
            dataCopy[idx].show = true;
            setOptions(dataCopy);
        }
    }

    const onMouseOut = () => {
        const dataCopy = JSON.parse(JSON.stringify(options));
        setOptions(dataCopy.map(i => {
            i.show = false;
            return i;
        }));
    }

    return (
        <div className={styles.optionsContainer} >
            {
                options.map((entry, index) => (
                    <div className={styles.optionContainer} key={index}>
                        <div className={styles.option}>
                            <div className={styles.optionIndex}>{entry.index}</div>
                            {!!entry.answer &&
                                <OverlayTrigger
                                    // show={!!entry.show}
                                    overlay={
                                        <Tooltip>{entry.answer}</Tooltip>

                                    }
                                >
                                    <div id={`optionText${index}`} className={styles.optionText} onMouseEnter={onMouseOver} onMouseLeave={onMouseOut}>
                                        {entry.answer}
                                    </div>
                                </OverlayTrigger>
                            }
                            <div
                                id={`${serialNo}option${index}`}
                                className={styles.optionCountBar}
                            />
                        </div>
                        <div className={styles.countValueContainer}>{entry.value}</div>
                    </div>
                ))
            }
        </div>
    );
}