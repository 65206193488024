import React, { useRef, useEffect } from "react";
import {ReactComponent as ClockBlack} from "./images/timer-icon.svg";
import {ReactComponent as ClockRed} from "./images/timer-icon-red.svg";
import Countdown from 'react-countdown';
import {HOUR_LABEL, MIN_LABEL, QUIZ_STATE, SEC_LABEL, TEXTBOX, TIME_REMAINING} from "../../utils/constants";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function CountdownTimer({ questionEndTime, state, setIsTimeOut }) {
  let hurry = false;
  const countDownRef = useRef();
  const isNarrow = useMobileAccess(1200);
  const handleStart = () => countDownRef.current.start();
  const handlePause = () => countDownRef.current.pause();

  useEffect(() => {
    if (state === QUIZ_STATE.PAUSED) {
      handlePause();
      console.log('Time Is Paused');
    }
    else if (state === QUIZ_STATE.RESUMED) {
      handleStart();
      console.log('Time Is Resumed');
    }
  }, [state]);

  const isEndRef = useRef();
  isEndRef.current = new Date() >= questionEndTime;

  useEffect(() => {
    if (new Date() >= questionEndTime && setIsTimeOut) {
      setIsTimeOut(true);
    }
  }, [questionEndTime, setIsTimeOut])

  const renderer = ({ hours, minutes, seconds }) => {
    if (hours === 0 && minutes === 0 && seconds < 4) {
      if (document.getElementById("tc") !== null)
        document.getElementById("tc").style.color = '#C93E3E';
      hurry = true;
    }

    // pad with 2 leading zeros
    hours = ('00' + hours).slice(-2);
    minutes = ('00' + minutes).slice(-2);
    seconds = ('00' + seconds).slice(-2);

    return (
        <>
          {hurry && <ClockRed/>}
          {!hurry && <ClockBlack/>}
          <span className="timer-count" tabIndex={0} role={TEXTBOX} aria-live="off" aria-atomic="true" aria-label={TIME_REMAINING + hours + HOUR_LABEL + minutes + MIN_LABEL + seconds + SEC_LABEL} id="tc">{hours}:{minutes}:{seconds}</span>
        </>
    );
  };

  return (
    <>
      <div className="timer-outer" style={isNarrow ? {backgroundColor: "#E6F2F7"} : {backgroundColor: "#ffffff"}}>
        <div className="img-wrap timer-holder">
          <Countdown
            ref={countDownRef}
            className="timer-count"
            date={questionEndTime}
            renderer={renderer}
            onComplete={() => {
              if (setIsTimeOut) {
                setIsTimeOut(true);
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
