import React, { useState } from "react";
import dropdownArrow from "../images/dropdown_arrows.svg";
import { ACTIVATED_STATUS, ALL_STATUS, COURSE_STATUS, DRAFT_STATUS} from "../utils/constants";

export default function StatusFilterDropDown({statusFilter, setStatusFilter}) {
  const [minimized, setMinimized] = useState(true);

  const handleEnlarge = () => {
    setMinimized(false);
  };

  const handleMinimize = () => {
    setMinimized(true);
  };

  return (
    <div style={{width: "113px", height: "37px", marginRight: "20px", position: "relative"}}>
    {minimized &&
    <div className="dropdown-poll-type-first" onClick={handleEnlarge} style={{width: "113px", height: "37px", marginRight: "20px"}}>
      <div className="dropdown-text-format" style={{color: "#565656", fontFamily: "Geomanist", fontSize: "13px", fontWeight: "400"}}>{statusFilter}</div>
      <img
        className="dropdownIcon"
        src={dropdownArrow}
        alt="Dropdown arrow"
      />
    </div>}
    {!minimized && 
      <div style={{display: "flex", flexDirection: "column", position: "absolute"}}>
        <div className="dropdown-poll-type-select" onClick={() => {setMinimized(true);}} style={{width: "113px", height: "37px", marginRight: "20px"}}>
        <div className="dropdown-text-format" style={{color: "#565656", fontFamily: "Geomanist", fontSize: "13px", fontWeight: "400"}}>{statusFilter} </div>
          <img
            className="dropdownIcon"
            src={dropdownArrow}
            alt="Dropdown arrow"
            onClick={handleMinimize}
          />
        </div>    
        <div className="dropdown-poll-type-opp" onClick={() => {
          setStatusFilter(statusFilter === ACTIVATED_STATUS ? DRAFT_STATUS : ACTIVATED_STATUS); 
          setMinimized(true);
          }} style={{width: "113px",  height: "37px"}}>
          <div className="dropdown-text-format" style={{color: "#565656", fontFamily: "Geomanist", fontSize: "13px", fontWeight: "400"}}>{statusFilter === ACTIVATED_STATUS ? DRAFT_STATUS : ACTIVATED_STATUS}</div>
          <img
            className="dropdownIcon"
            style={{visibility: "hidden"}}
            src={dropdownArrow}
            alt="Dropdown arrow"
            onClick={handleMinimize}
          />
        </div>
        {statusFilter !== DRAFT_STATUS && statusFilter !== ACTIVATED_STATUS ? <div className="dropdown-poll-type-opp" onClick={() => {
          setStatusFilter(DRAFT_STATUS); 
          setMinimized(true);
          }} style={{width: "113px",  height: "37px"}}>
          <div className="dropdown-text-format" style={{color: "#565656", fontFamily: "Geomanist", fontSize: "13px", fontWeight: "400"}}>{DRAFT_STATUS}</div>
          <img
            className="dropdownIcon"
            style={{visibility: "hidden"}}
            src={dropdownArrow}
            alt="Dropdown arrow"
            onClick={handleMinimize}
          />
        </div> : null }
        {statusFilter !== COURSE_STATUS ? <div className="dropdown-poll-type-opp" onClick={() => {
          setStatusFilter(COURSE_STATUS); 
          setMinimized(true);
          }} style={{width: "113px",  height: "37px"}}>
          <div className="dropdown-text-format" style={{color: "#565656", fontFamily: "Geomanist", fontSize: "13px", fontWeight: "400"}}>{COURSE_STATUS}</div>
          <img
            className="dropdownIcon"
            style={{visibility: "hidden"}}
            src={dropdownArrow}
            alt="Dropdown arrow"
            onClick={handleMinimize}
          />
        </div> : null }
        {statusFilter != ALL_STATUS ? <div className="dropdown-poll-type-opp" onClick={() => {
          setStatusFilter(ALL_STATUS); 
          setMinimized(true);
          }} style={{width: "113px",  height: "37px"}}>
          <div className="dropdown-text-format" style={{color: "#565656", fontFamily: "Geomanist", fontSize: "13px", fontWeight: "400"}}>{ALL_STATUS} </div>
          <img
            className="dropdownIcon"
            style={{visibility: "hidden"}}
            src={dropdownArrow}
            alt="Dropdown arrow"
            onClick={handleMinimize}
          />
        </div> : null }
      </div>
    }
  </div>);
}