import {useParams} from "react-router-dom";
import {

    BLANK_REGEX,
    FIXED_ANSWER,
    POLL_TYPE,
    QUESTION_IMAGE_ID,
    QUESTION_TYPES,
    RESPONSES
} from "../../utils/constants";
import React, {useEffect, useRef, useState} from "react";
import styles from "./Presentation.module.css";
import {questionCodeToName} from "../../utils/questionUtils";
import {ResponseApiCalls} from "../../apis/ResponseApiCalls";
import {fetchImg, getInstitutionId} from "../../utils/helpers";
import {HOST_URL, S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME} from "../../utils/properties";
import QuestionOptions from "./QuestionOptions";
import WordCloudResult from "../JoinQuizPage/WordCloudResult";
import QRCode from "qrcode.react";
import {PollApiCalls} from "../../apis/PollApiCalls";
import Countdown from "react-countdown";
import {ReactComponent as Timer} from "../PublishedPollPage/images/TIMER_ICON.svg";
import {ReactComponent as RedTimer} from "../PublishedPollPage/images/RED_TIMER_ICON.svg";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import useQuery from "../../utils/queryparam";


export default function QuestionDetail({poll, question}) {
    const {getClassResult, getShortName} = ResponseApiCalls();
    const {shareQuestionPresentation} = PollApiCalls();
    const { pollCode, serialNo } = useParams();
    let query = useQuery();
    const [, getTimeOffset] = useLocalStorageNew("timeOffset", 0);

    const {pk, index1Pk, pollType, pollTitle, totalQuestions, index5Pk} = poll;
    const {queTitle, questionType, weightage} = question;
    const pollKey = pk.substring("P#".length);

    const [newItems, setNewItems] = useState([]);
    const [questionResult, setQuestionResult] = useState([]);
    const prevQuestionResultsRef = useRef([]);
    const [questionImageURL, setQuestionImageURL] = useState("");
    const unanswered = useRef(0);
    const [optionData, setOptionData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const countDownRef = useRef();
    const intervalIdRef = useRef(0);
    const [queTimeLimit, setQueTimeLimit] = useState(0);
    const [queEnd, setQueEnd] = useState(0);

    useEffect(() => {
        setQuestionImageURL("");
        const institutionId = getInstitutionId(index5Pk);
        async function fetchData() {
            if ((!!question.image || !!question.suffix) && institutionId && pollKey && question) {
                let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;

                url = !!question.image ? `${url}/${question.image}` : `${url}/temp-${serialNo}${question.suffix}`;
                let img = await fetchImg(url);
                setQuestionImageURL(img ? img : "");
            }
        }
        fetchData();


    }, [poll, question]);

    //share question
    useEffect(() => {
        let token = query.get("token");
        shareQuestionPresentation(pollKey, pollCode, serialNo, token).then((res) => {
            setQueTimeLimit(question.timeLimit);
            setQueEnd(res.liveQuestionTime + parseInt(getTimeOffset()) + question.timeLimit * 1000);
        });
    }, []);


    //get result
    useEffect(() => {
        if (questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.WC.name) {
            getClassResult(pollKey, pollCode, question, null, true).then((result) => {
                if (result.length > 0 ) {
                    setQuestionResult(result);
                    if (questionType === QUESTION_TYPES.SA.name) {
                        updateNewItems(result)
                    } else if (questionType === QUESTION_TYPES.FITB.name) {
                        updateFITBItems(result);
                    }
                }
            });
        } else if (questionType === QUESTION_TYPES.MCSS.name || questionType === QUESTION_TYPES.TF.name || questionType === QUESTION_TYPES.CI.name) {
            getClassResult(pollKey, pollCode, question).then((result) => {
                setOptionsByData(result);
            });
        }

        intervalIdRef.current = setInterval(() => {
            if (questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.WC.name) {
                getClassResult(pollKey, pollCode, question, null, true).then((result) => {
                    if (result.length > 0) {
                        setQuestionResult(result);
                        if (questionType === QUESTION_TYPES.SA.name) {
                            updateNewItems(result);
                        } else if (questionType === QUESTION_TYPES.FITB.name) {
                            updateFITBItems(result);
                        }
                    }
                });
            } else if (questionType === QUESTION_TYPES.MCSS.name || questionType === QUESTION_TYPES.TF.name || questionType === QUESTION_TYPES.CI.name) {
                getClassResult(pollKey, pollCode, question).then((result) => {
                    setOptionsByData(result);
                });
            }
        } , 5000);

        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        }
    }, []);

    function setOptionsByData(res) {
        //remove unanswered res
        let filteredData = [];
        res.forEach((r) => {
            if (r.ans !== "Unanswered") {
                filteredData.push({...r});
            } else {
                unanswered.current = r.people;
            }
        });

        let optionArrData = [];
        let tempTotalData = 0;
        filteredData.forEach((result) => {
            if (questionType === QUESTION_TYPES.MCSS.name) {
                optionArrData.push({ index: result.ans.split(". ")[0], value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
            } else if (questionType === QUESTION_TYPES.CI.name) {
                if (result.ans === FIXED_ANSWER.OTHER) {
                    optionArrData.push({ index: FIXED_ANSWER.OTHER, value: result.people});
                } else {
                    optionArrData.push({ index: result.ans.split(". ")[0].toUpperCase(), value: result.people, answer: result.ans.substring(result.ans.indexOf(". ") + ". ".length)});
                }
            }else if (questionType === QUESTION_TYPES.TF.name) {
                optionArrData.push({ index: result.ans, value: result.people });
            } else {
                optionArrData.push({ index: result.ans, value: result.people});
            }
            tempTotalData += result.people
        })
        setTotalCount(tempTotalData);
        setOptionData(optionArrData);
    }


    function updateNewItems(arr1) {
        const list = [];

        const prevList = prevQuestionResultsRef.current;
        if (arr1.length >= prevList.length) {
            const smallerLength = Math.min(arr1.length, prevList.length);
            const length_diff = Math.abs(arr1.length - prevList.length);
            for (let i = 0; i < smallerLength; i++) {
                if (arr1[i + length_diff] !== FIXED_ANSWER.UNANSWERED && prevList[i]["0"] != FIXED_ANSWER.UNANSWERED && arr1[i + length_diff]["0"] !== prevList[i]["0"]) {
                    list.push(arr1[i + length_diff]);
                }
            }
            const remainingArray = arr1.length >= prevList.length ? arr1 : prevList;
            for (let i = 0; i < length_diff; i++) {
                if (remainingArray[i] !== FIXED_ANSWER.UNANSWERED) {
                    list.push(remainingArray[i]);
                }
            }
        }
        prevQuestionResultsRef.current = arr1;
        setNewItems(list);
    }


    function updateFITBItems(arr1) {
        if (!arr1) {
            return;
        }

        const list = [];
        const prevList = prevQuestionResultsRef.current;
        if (arr1.length > prevList.length) {
            const smallerLength = Math.min(arr1.length, prevList.length);
            const length_diff = Math.abs(arr1.length - prevList.length);

            for (let i = 0; i < smallerLength; i++) {
                if (arr1[i + length_diff] != FIXED_ANSWER.UNANSWERED && prevList[i] != FIXED_ANSWER.UNANSWERED){
                    if (Object.keys(arr1[i + length_diff]).length !== Object.keys(prevList[i]).length) {
                        list.push(arr1[i + length_diff]);
                    }
                    else {
                        const keys = Object.keys(arr1[i + length_diff]);
                        let valuesMatch = true;

                        for (let j = 0; j < keys.length; j++) {
                            const key = keys[j];
                            if (arr1[i + length_diff][key] !== prevList[i][key]) {
                                valuesMatch = false;
                                break;
                            }
                        }

                        if (!valuesMatch) {
                            list.push(arr1[i + length_diff]);
                        }
                    }
                }
            }

            const remainingArray = arr1.length >= prevList.length ? arr1 : prevList;
            for (let i = 0; i < length_diff; i++) {
                if (remainingArray[i] !== FIXED_ANSWER.UNANSWERED) {
                    list.push(remainingArray[i]);
                }
            }
        }
        prevQuestionResultsRef.current = arr1;
        setNewItems(list);
    }

    const renderer = ({ days, hours, minutes, seconds }) => {
        const hoursView = ('00' + ((days*24)+hours)).slice(-2);
        const minutesView = ('00' + minutes).slice(-2);
        const secondsView = ('00' + seconds).slice(-2);
        const urgent = days === 0 && hours === 0 && minutes === 0 && seconds < 4 && seconds !== 0;

        const time = (seconds + minutes * 60 + hours * 3600 + days * 86400);



        if (time > queTimeLimit) {
            return (
                <div className="pollPanelTimerText"><Timer style={{marginRight: "10px"}}/>Get Ready: {time - queTimeLimit - 1}</div>
            );
        }


        if (time <= queTimeLimit) {
            return (
                <div className={`pollPanelTimerText ${urgent ? 'urgent' : ''}`}>{urgent ? <RedTimer className="timerMargin"/> :<Timer className="timerMargin"/>}{hoursView}:{minutesView}:{secondsView}</div>
            );
        }

        return <></>;
    };

    function timeUp() {
        setQueEnd(0);
        if (pollType === POLL_TYPE.SURVEY) {
            return;
        }
        setTimeout(() => {
            if(intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        }, 5000);
    }


    return (
        <div className={styles.pollDetailContainer}>
            <div className={styles.pollTitlePanelContainer}>
                <div>
                    <div className={styles.pollPanelSubtitle}>Poll Title</div>
                    <div className={styles.pollPanelTitle}>{pollTitle}</div>
                </div>
            </div>
            <div style={{display: "flex", width: "100%",  height: "82%", justifyContent:"space-between"}}>
                <div className={styles.QRCodePanel}>
                    <QRCode
                        bgColor={"#f6f6f6"}
                        style={{ margin: "10px" }}
                        renderAs="canvas"
                        value={`https://${getShortName()}.${HOST_URL}/qr/${pollCode}`}
                    />
                    <div className={styles.QRCodeText}>{`#${pollCode}`}</div>
                </div>
                <div className={styles.questionPanel}>
                    <div className={styles.questionPanelTitle}>
                        <div className={styles.questionPanelCurSerialNo}>{serialNo}</div>
                        <div className={styles.questionPanelTotalCount}>of {totalQuestions}</div>
                        <div className={styles.gradientWrapQuesType}>
                            <div className={styles.questionPanelQuestionTypeContainer}>
                                {questionCodeToName(questionType)}
                            </div>
                        </div>
                        {pollType === POLL_TYPE.GRADED_POLL &&
                            <div className={styles.gradientWrapQuesType}>
                                <div className={styles.questionPanelQuestionTypeContainer}>
                                    {`${weightage} points`}
                                </div>
                            </div>
                        }
                        <div style={{flex: 1}}/>
                        {!!queEnd &&
                            <div className={styles.gradientWrapQuesType}>
                                <div className={styles.questionPanelQuestionTypeContainer}>
                                    <Countdown
                                        ref={countDownRef}
                                        className="pollPanelTimerText"
                                        autoStart={true}
                                        date={queEnd}
                                        key={queEnd}
                                        renderer={renderer}
                                        onComplete={timeUp}>
                                    </Countdown>
                                </div>
                            </div>
                        }

                    </div>
                    <div className={styles.questionPanelQueTitle}>{questionType === QUESTION_TYPES.FITB.name ? queTitle.replace(BLANK_REGEX, "______") : queTitle}</div>
                    <div style={{display: "flex", width: "100%", overflowY: "auto"}}>
                        {/*Question option*/}
                        <div style={{width: '65%', overflowY: "auto", marginRight: 10}}>
                            {(questionType === QUESTION_TYPES.MCSS.name || questionType === QUESTION_TYPES.TF.name || questionType === QUESTION_TYPES.CI.name) &&
                                <QuestionOptions options={optionData} setOptions={setOptionData} total={totalCount} serialNo={serialNo}/>
                            }

                            {questionType === QUESTION_TYPES.FITB.name &&
                                <>
                                    {questionResult.map((item, idx) => {
                                        if (item !== "unanswered") {
                                            const combinedString = Object.keys(item)
                                                .map((key) => `"${item[key]}"`)
                                                .join(", ");

                                            return (
                                                <div
                                                    className={`questionPanelAnswersText ${newItems.includes(item) ? "resultAnimation" : ""
                                                    }`}
                                                    style={{
                                                        marginTop: "20px",
                                                        backgroundColor: "#f4f4f4",
                                                        width: "fit-content",
                                                        padding: "15px",
                                                    }}
                                                    key={idx}
                                                >
                                                    {combinedString}
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </>
                            }
                            {questionType === QUESTION_TYPES.SA.name &&
                                <>
                                    {questionResult.map((item) =>
                                        item !== FIXED_ANSWER.UNANSWERED ? (
                                            <div
                                                className= {`questionPanelAnswersText ${newItems.includes(item) ? 'resultAnimation' : ''}`}
                                                style={{ marginTop: "20px", backgroundColor: "#f4f4f4", width: "fit-content", padding: "15px"}}
                                            >
                                                {`"${item["0"]}"`}
                                            </div>
                                        ) : <></>
                                    )}
                                </>
                            }

                            {questionType === QUESTION_TYPES.WC.name &&
                                <WordCloudResult
                                    data={questionResult}
                                    pollType={pollType}
                                />
                            }

                        </div>
                        {/*Question Image*/}
                        <div style={{flex: 1}}>
                            {questionImageURL && (
                                <div className="img-container-share" id={QUESTION_IMAGE_ID} style={{height:"100%"}}>
                                    <div style={{padding: 10}}>
                                        <img
                                            style={{ maxWidth: "100%", maxHeight: "100%", mixBlendMode: "normal",
                                                boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)",
                                                borderRadius: "12px"}}
                                            src={questionImageURL} alt="Question" />


                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                    <div style={{flex: 1}}/>
                    <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center"}}>
                        <div className={styles.descriptionText}>Scan the QR Code</div>
                        <div className={styles.descriptionText}>OR</div>
                        <div className={styles.descriptionText}>Join as a participant at: {`https://${getShortName()}.${HOST_URL}`}</div>
                    </div>
                </div>
            </div>

        </div>
    );
}