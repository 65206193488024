import { Spin } from "../../components/standardization/YuJaLoading";
import "antd/dist/antd.min.css";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UserApiCalls } from "../../apis/UserApiCalls";
import useHostname from "../../hooks/useHostname";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { useLoading } from "../../utils/LoadingContext";
import { POLL_TITLE_LENGTH, QUESTION_TYPES, POLL_QUESTION_TITLE, NO_QUESTION_ADDED, EDIT_QUESTION_BANK } from "../../utils/constants";
import { notifyError, validateText } from "../../utils/helpers";
import { addQuestion } from "../../utils/questionUtils";
import { QUESTION_BANK_TITLE_ERROR } from "../../utils/toast-message-constants";
import EmptySidebar from "../CreateQuizPage/EmptySidebar";
import "../CreateQuizPage/styles.css";
import CreateQuestionDetailSidebar from "./CreateQuestionDetailSidebar";
import CreateQuestionSidebar from "./CreateQuestionSidebar";
import QuestionBankMain from "./QuestionBankMain";
import CreateQuizSidebar from "../CreateQuizPage/CreateQuizSidebar";

export default function EditQuestionProfilePage({
  setSidebarContent,
  setRightSidebarContent
}) {
  const { questionProfileId} = useParams();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [currQueDisplay, setCurrQueDisplay] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  const {saveQuestionProfile, updateQuestionProfile, getQuestionProfileId} = UserApiCalls(); 
  const [desiredPollMode, setDesiredPollMode] = useState("");
  const { loading } = useLoading();
  const [showAnswerOnViewer, setShowAnswerOnViewer] = useState(false);
  const location = useLocation();
  const [setPollTitle, getPollTitle] = useLocalStorageNew("pollTitle", "");
  const [setQues, getQues] = useLocalStorageNew("questions", []);
  const [setIsSaving, getIsSaving] = useLocalStorageNew("isSaving", false);

  const [isAutoSaving, setIsAutoSaving] = useState(false); 

  const [focusMCElem, setFocusMCElem] = useState(-1);
  const [focusSAElem, setFocusSAElem] = useState(-1);
  const [focusTFElem, setFocusTFElem] = useState(-1);
  const [focusFITBElem, setFocusFITBElem] = useState(-1);
  const [focusCIElem, setFocusCIElem] = useState(-1);
  const hostResource = useHostname(window.location.hostname);
  const {institutionId = ""} = hostResource;
  
  if (questions.length < 1) {
    const qs = addQuestion(questions, "MCSS");
    setQuestions(qs);
    setCurrQueDisplay(qs[0].serialNo);
  }
  
  useEffect(() => {
    switch(questions[currQueDisplay-1].questionType) {
      case QUESTION_TYPES.MCSS.name:
        if(focusMCElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusMCElem].focus();
          setFocusMCElem(-1);
        }
        break;
      case QUESTION_TYPES.SA.name:
        if(focusSAElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusSAElem].focus();
          setFocusSAElem(-1);
        }
        break;
      case QUESTION_TYPES.TF.name:
        if(focusTFElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusTFElem].focus();
          setFocusTFElem(-1);
        }
        break;
      case QUESTION_TYPES.FITB.name:
        if(focusFITBElem !== -1) {
          if(focusFITBElem === 0 && document.getElementsByClassName("fitb-bold focus-outline-none").length === 0) {
            document.getElementsByClassName("notranslate public-DraftEditor-content")[focusFITBElem].focus();
          } else {
            document.getElementsByClassName("fitb-bold focus-outline-none")[focusFITBElem].focus();
          }
          setFocusFITBElem(-1);
        }
        break;
      case QUESTION_TYPES.CI.name:
        if(focusCIElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusCIElem].focus();
          setFocusCIElem(-1);
        }
      case QUESTION_TYPES.MH.name: 
        if (focusCIElem !== -1) {
          document.getElementsByClassName("poll-sub-text focus-outline-none")[focusCIElem].focus();
          setFocusCIElem(-1);
        }
    }
  }, [currQueDisplay, focusMCElem, focusSAElem, focusTFElem, focusFITBElem, focusCIElem]);

  const savePoll = async (showwarning=true) => {
    if (!getIsSaving()) {
      setIsSaving(true); 
      if (!validateText(title, POLL_TITLE_LENGTH)) {
        if(showwarning){
          notifyError(QUESTION_BANK_TITLE_ERROR);
        }
        return false;
      }

      setSaveButtonDisabled(true);
    let pollToAdd = {
          questions: questions,
          ProfileTitle: title,
          questionProfileId: questionProfileId, 
    };
    console.log(pollToAdd);
    if (questionProfileId != null) {
        await updateQuestionProfile(pollToAdd, showwarning); 
    }


    setPollTitle(title);
    setQues(questions);
    setSaveButtonDisabled(false);
    setIsSaving(false);
    return true;
    }
  };


  useEffect(() => {
    if (questions.length > 0) {
      setSidebarContent(
        <CreateQuizSidebar
          setQuestions={setQuestions}
          questions={questions}
          currQueDisplay={currQueDisplay}
          setCurrQueDisplay={setCurrQueDisplay}
          isBank={true}
        />
      );
    } else {
      setSidebarContent(
        <EmptySidebar
          title={POLL_QUESTION_TITLE}
          noContentText={NO_QUESTION_ADDED}
        />
      );
    }
  }, [title, questions, setQuestions, setSidebarContent, desiredPollMode, setDesiredPollMode, currQueDisplay, setCurrQueDisplay]);

  // useEffect(() => {
  //   setRightSidebarContent(
  //     <CreateQuestionDetailSidebar
  //       title={CREATE_QUESTION_DETAIL_SIDEBAR_TITLE}
  //       noContentText={CREATE_QUESTION_DETAIL_SIDEBAR_TITLE}
  //       questions={questions}
  //       setQuestions={setQuestions}
  //       currQueDisplay={currQueDisplay}
  //     />
  //   );
  // }, [questions, setQuestions, currQueDisplay, setRightSidebarContent, showAnswerOnViewer, setShowAnswerOnViewer]);

  useEffect(async () => {
    if (location && location.state) {
        resetPoll();
    }
  }, [location.state]);

  const resetPoll = () => {
    setSaveButtonDisabled(false);
    setQuestions([]);
    setPollTitle("");
    setIsSaving(false);
    setQues({});
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!title) {
        return
      }
      if ((title !== getPollTitle()) || (JSON.stringify(questions) !== JSON.stringify(getQues()))) {
        setIsAutoSaving(true);
        savePoll(false);
        setPollTitle(title);
        setQues(questions);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [title, questions]);

  useEffect(() => {
    if(document.getElementById("engagePageIdentifier") != null) {
      document.getElementById("engagePageIdentifier").innerText = EDIT_QUESTION_BANK;
    }
    setIsSaving(false);
    getQuestionProfileId(questionProfileId).then(res => {
      setQuestions(res.questions);
      setTitle(res.ProfileTitle);
    });
  }, []);

  useEffect(() => {
    if (isAutoSaving) {
      const gifTimer = setTimeout(() => {
        setIsAutoSaving(false);
      }, 5000);

      return () => clearTimeout(gifTimer);
    }
  }, [isAutoSaving]);

  return (
    <>
      <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
        <div style={{ backgroundColor: "#FCFBFF", padding: "0 2.063rem", overflowY: "hidden"}}>
          {(questions.length > 0) && 
            <QuestionBankMain
              title={title}
              setTitle={setTitle}
              questions={questions}
              setQuestions={setQuestions}
              savePoll={savePoll}
              currQueDisplay={currQueDisplay}
              changeCurrQueDisplay={setCurrQueDisplay}
              saveButtonDisabled={saveButtonDisabled}
              institutionId={institutionId}
              isEditPage={true}
              questionBankId={questionProfileId}
              isSaving={isAutoSaving}
              isBank={true}
            />}
        </div>
      </Spin>
    </>
  );
}