import "bootstrap/dist/css/bootstrap.css";
import React, {useContext, useEffect, useRef, useState} from "react";
import { Col, Row } from "react-bootstrap";
import { useWindowDimensions } from "react-native-web";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import { ReactComponent as NextEnabled } from "../../images/next_enabled.svg";
import NormalBox from "../../public/boxes/NormalBox";
import {useLoading, YuJaGlobalState} from "../../utils/LoadingContext";
import {
    BLANK_REGEX,
    CI_COORDINATE_SIZE,
    FIXED_ANSWER,
    QUESTION_TYPES,
    SURVEY_ALL_CURRENT_QUESTION_NO_KEY,
    UNANSWERED,
    WORD_CLOUD_DATA,
    PARTICIPANT_TEXT,
    RESPONSE_TEXT,
    COMPLETION_RATE_TEXT,
    CORRECT_ANSWER_TEXT,
    UNAVAILABLE_TEXT,
    WRONG_ANSWER_TEXT,
    TEXTBOX,
    QUESTION_TITLE,
    FITB_NARRATOR,
    SURVEY_RESULT_TEXT,
    BUTTON, NEXT_QUES
} from "../../utils/constants";
import { fetchImg, newPageLog, processResult } from "../../utils/helpers";
import { S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME } from "../../utils/properties";
import { decodeCIParam } from "../../utils/questionUtils";
import ClassResultChart from "./ClassResultChart";
import { ReactComponent as Boy } from "./images/graduating boy.svg";
import { notifyError } from "../../utils/helpers";
import "./styles.css";
// import {ReactComponent as PreviousEnabled} from "../../images/previous_enabled.svg";
import WordCloudResult from "./WordCloudResult";
import YuJaButton from "../../components/standardization/YuJaButton";
import NetworkStatus from "./NetworkStatus";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { ReactComponent as ParticipantsIcon } from "../../images/participants_icon.svg";
import { ReactComponent as ResponsesIcon } from "../../images/responses-icon.svg";
import { ReactComponent as CorrectIcon } from "../../images/correct-answer-icon.svg";
import { ReactComponent as WrongIcon } from "../../images/wrong-answer-icon.svg";
import { ReactComponent as CompletionRateIcon } from "../../images/completion-rate-icon.svg";
import { questionCodeToName } from "../../utils/questionUtils";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import { ReactComponent as NextIcon } from "../PublishedPollPage/images/NEXT_ARROW_ICON.svg";

export default function ClassResultSurvey({
                                              pollInfo,
                                              currentPoll,
                                              queResponses,
                                              setQueResponses,
                                              pollLiveQuestionIdx,
                                              setPollLiveQuestionIdx,
                                              pollType,
                                              handlePollComplete,
                                              question,
                                              handleSubmitResponse,
                                              changeCurPoll,
                                              websocketState,
                                              institutionId

}) {
    const isMobile = useMobileAccess(480);
    const isNarrow = useMobileAccess(1200);
    const curWindow = useWindowDimensions();
    const[, getHostResource] = useLocalStorageNew(window.location.hostname, {});
    const [setGuestSession, getGuestSession] = useLocalStorageNew("guestSession", {});
    const [setWordCloudData, ] = useLocalStorageNew(WORD_CLOUD_DATA, {});

    const { pollKey, uniqueCode, pollTitle } = pollInfo;
    const { questions } = currentPoll;

    const { queTitle, correctAnswers, questionType, serialNo, suffix="", image="" } = question;
    const { getClassResult, getAllClassResult} = ResponseApiCalls();
    const [selectedQuestion, setSelectedQuestion] = useState(question);
    const [questionresult, setQuestionResult] = useState([]);
    const [pollResults, setPollResults] = useState([]);
    const { setLoading } = useLoading();
    const [dataReady, setDataReady] = useState(false);
    const [questionImageURL, setQuestionImageURL] = useState("");
    const imageRef = useRef(null);
    const [isImgLoaded, setIsImgLoaded] = useState(false);
    const intervalIdRef = useRef(-1);
    const {isOnline} = useContext(YuJaGlobalState);

    const [participantCount, setParticipantCount] = useState("-");
    const [responsesCount, setResponsesCount] = useState("-");
    const [completionRate, setCompletionRate] = useState("-"); 
    const [questionData, setQuestionData ] = useState(""); 

    const [isSwitching, setIsSWitching] = useState(false);
    const [isVideo, setIsVideo] = useState(false);

    const unanswered = useRef(0);

    function removeUnanswered(result) {
        let newResult = [];
        if (result == null) {
            return newResult; 
        }
        result.forEach((r) => {
            if (r.ans !== UNANSWERED) {
                newResult.push({...r});
            } else {
                unanswered.current = r.people;
            }
        });
        return newResult;
    };


    async function getQuestionsData() {
        setLoading(true);
        const results = await getAllClassResult(pollKey,  questions[0].pollSortKey.split("#")[2], questions);
        let tempQuestionsData = [];
        for (const result of results) { 
          let serialNo = result.serialNo; 
          const question = questions.find(({serialNo: no}) => parseInt(no) === parseInt(serialNo));
          let CA;
          if (question.questionType === "MCSS") {
            CA = question.correctAnswers[0];
          } else if (question.questionType === "TF") {
            CA = question.correctAnswers;
          } else {
            CA = null;
          }
          let questionType = question.questionType;
          let queTitle = question.queTitle;
          let MCSSelements = [];
          if (questionType === "MCSS") {
            for (let key in question.optionsMap) {
              MCSSelements.push(question.optionsMap[key]);
            }
          }
          let MCSSnum = MCSSelements.length;
          let suffix = question.suffix;
          let image = question.image;
          let questionImageURL;
          let video = false;
          if(question.directLinkEvp) {
            questionImageURL = question.directLinkEvp;
            video = true;
          } else if ((!!image || !!suffix) && institutionId && pollKey && serialNo) {
            let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;
  
            url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
            let img = await fetchImg(url);
            questionImageURL = img ? img : "";
          }
  
          let questionData = {
            correctAnswers: CA,
            questionType: questionType,
            queTitle: queTitle,
            questionResults: result.questionResults,
            serialNo: serialNo,
            MCSSnum: MCSSnum,
            questionImageURL: questionImageURL,
            isVideo: video,
            rawResponse: result.rawResponse
          };
          tempQuestionsData.push(questionData); 
        }
  
        tempQuestionsData.forEach(item => {
          item.unansweredCount = getUnanswered(item.questionResults);
        });
  
        tempQuestionsData.forEach(item => {
          item.questionResults = removeUnanswered(item.questionResults);
        });
  
        setPollResults(tempQuestionsData);
        setDataReady(true);
        setLoading(false);
      }

    useEffect(() => {
        if (!isOnline || websocketState !== WebSocket.OPEN) {
          return;
        }
    
        // async function setResults() {
        //   let questionResultCopy = {};
        //   let promises = questions.map((question, index) => {
        //     console.log(question.questionType);
        //     if (question.questionType === QUESTION_TYPES.SA.name || question.questionType === QUESTION_TYPES.FITB.name || question.questionType === QUESTION_TYPES.WC.name) {
        //       return getClassResult(pollKey, question.pollSortKey.split("#")[2], question, null, true).then((result) => {
        //         if (result.length > 0) {
        //           questionResultCopy[index] = result;
        //         }
        //       });
        //     }
        //   })
        //   await Promise.all(promises);
        //   setQuestionResult(questionResultCopy);
        //   setDataReady(true);
        // }
        setTimeout(() => {
          getQuestionsData();
        }, 3000);
        
        const interval = setInterval(() => {
            getQuestionsData();
          }, 10000);

          return () => clearInterval(interval);
      }, [questions, isOnline, websocketState]);

    const onLoadImg = () => {
        setIsImgLoaded(true);
    };

    // const onLoadWrapper = () => {
    //     if (document.getElementsByClassName('right-wrapper')[0].scrollHeight > document.getElementsByClassName('right-wrapper')[0].offsetHeight) {
    //         document.getElementsByClassName('right-wrapper')[0].style.justifyContent = 'flex-start';
    //         document.getElementsByClassName('right-wrapper')[0].style.paddingTop = '20px';
    //     } else {
    //         document.getElementsByClassName('right-wrapper')[0].style.justifyContent = 'center';
    //         document.getElementsByClassName('right-wrapper')[0].style.paddingTop = null;
    //     }
    // };
    
    useEffect(() => {
        document.getElementById('root').style.backgroundColor = "#FCFBFF";
        newPageLog("ClassResultSurvey.js");
    }, []);

    const getUnanswered = (result) => {
        if (result == null) {
          return 0;
        }
    
        let unansweredCount = 0; 
        result.forEach((r) => {
            if (r.ans === UNANSWERED) {
                unansweredCount = r.people;
            }
        });
        return unansweredCount;
    
      }


    useEffect(() => {
        if (questionType === QUESTION_TYPES.WC.name) setWordCloudData({});
    }, [questionType]);

    // useEffect(() => {
    //     onLoadWrapper();
    // }, [curWindow.height]);

    // useEffect(() => {
    //     if (!isOnline || websocketState !== WebSocket.OPEN) {
    //         if (intervalIdRef.current > 0) {
    //             clearInterval(intervalIdRef.current);
    //         }
    //         return;
    //     }

    //     if (!question || !Object.entries(question).length) {
    //         return;
    //     }

    //     if(isNarrow && document.getElementById("viewer-navbar") !== null) {
    //         document.getElementById("viewer-navbar").style.backgroundColor = "#E6F2F7";
    //     }

    //     setLoading(true);
    //     const needRawResponse = questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.WC.name;
    //     intervalIdRef.current = setInterval(() => {
    //         if (document.visibilityState === "visible") {
    //             getClassResult(pollKey, uniqueCode, question, null, needRawResponse ? "all" : needRawResponse).then((result) => {
    //                 if (needRawResponse) {
    //                     setQuestionResult(result.responses ? result.responses : []);
    //                     const data = processResult(question, result);
    //                     setPollResults(removeUnanswered(data));
    //                 } else {
    //                     setPollResults(removeUnanswered(result));
    //                 }
    //                 setDataReady(true);
    //             });
    //         }
    //     }, 3000);
    //     // setYourAnswer(getYourAnswer(pollType, questionType, queResponses[pollLiveQuestionIdx].selectedOption));
    //     setLoading(false);

    //     //clean the interval
    //     return () => {
    //         if (intervalIdRef.current > 0) {
    //             clearInterval(intervalIdRef.current);
    //         }
    //     }
    // }, [question, isOnline, websocketState]);


    useEffect(() => {
        if (!isOnline) {
            return;
        }

        async function fetchData() {
            if(question.directLinkEvp) {
                setQuestionImageURL(question.directLinkEvp);
                setIsVideo(true);
            }
            else if ((!!image || !!suffix) && pollKey && serialNo) {
                let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${getHostResource().institutionId}/${pollKey}`;

                url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
                let img = await fetchImg(url);
                setQuestionImageURL(img ? img : "");
            } else {
                setQuestionImageURL("");
            }
        }
        fetchData();
    }, [suffix, pollKey, serialNo, isOnline]);

    const next = async () => {
        if (pollLiveQuestionIdx >= questions.length - 1) {
            return;
        }
        const answersCopy = JSON.parse(JSON.stringify(queResponses));
        const answerMap = [];
        //redirect to next question page if next question is unanswered. Otherwise, redirect to the next question class result page.
        const nexQuestionResponseCopy = answersCopy[pollLiveQuestionIdx + 1];
        if (!!nexQuestionResponseCopy && Object.entries(nexQuestionResponseCopy).length && nexQuestionResponseCopy?.skippedQuestion) {
            nexQuestionResponseCopy.skippedQuestion = false;
            answerMap.push(nexQuestionResponseCopy);
        }

        const curQuestionNo = pollLiveQuestionIdx + 2;
        //update curQuestionNo in DB
        await handleSubmitResponse(curQuestionNo, answerMap);
        setQueResponses(answersCopy);
        setPollLiveQuestionIdx(pollLiveQuestionIdx + 1);
    };

    // const previous = async () => {
    //     //Redirect to current question page if current question is unanswered
    //     const responsesCopy = JSON.parse(JSON.stringify(queResponses));
    //     const curQueResponseCopy = responsesCopy[pollLiveQuestionIdx];
    //     if (curQueResponseCopy?.skippedQuestion) {
    //         curQueResponseCopy.skippedQuestion = false;
    //         const curQuestionNo = pollLiveQuestionIdx + 1;
    //         const answerMap = [];
    //         answerMap.push(curQueResponseCopy);
    //         await handleSubmitResponse(curQuestionNo, answerMap);
    //         setQueResponses(responsesCopy);
    //         return;
    //     }

    //     //Redirect to previous question page if previous question is unanswered. Otherwise, redirect to previous class result page.
    //     const previousQueResponseCopy = responsesCopy[pollLiveQuestionIdx - 1];
    //     if (previousQueResponseCopy?.skippedQuestion) {
    //         previousQueResponseCopy.skippedQuestion = false;
    //     }
    //     const curQuestionNo = pollLiveQuestionIdx;
    //     const answerMap = [];
    //     answerMap.push(previousQueResponseCopy);
    //     await handleSubmitResponse(curQuestionNo, answerMap);
    //     setQueResponses(responsesCopy);
    //     setPollLiveQuestionIdx(pollLiveQuestionIdx - 1);
    // }

    const viewAll = () => {
        const copy = JSON.parse(JSON.stringify(currentPoll));
        copy.rawQueResponses = queResponses;
        changeCurPoll(copy);
        handlePollComplete();
        const guestSession = JSON.parse(JSON.stringify(getGuestSession()));
        delete guestSession[`$${uniqueCode}`]?.[SURVEY_ALL_CURRENT_QUESTION_NO_KEY];
        setGuestSession(guestSession);
    }


    useEffect(() => {
        if (pollResults  && pollResults.length !== 0 ) {

        let serialNo = selectedQuestion.serialNo
        const questiondata = pollResults.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
        setQuestionData(questiondata); 
        let overall_count = questiondata.questionResults[0].total;
        setParticipantCount(overall_count);
        let count = questiondata.questionResults[0].total -  questiondata.unansweredCount;
        setResponsesCount(count);
        setCompletionRate(Math.round(count/overall_count * 100));
        //   let serialNo = selectedQuestion.serialNo
        //   let overall_count = pollResults[serialNo].total;
        //   setParticipantCount(overall_count);
        //   let count = pollResults[serialNo].total -  unanswered.current;
        //   setResponsesCount(count);
        //   setCompletionRate(Math.round(count/overall_count * 100))
      }
      }, [selectedQuestion, pollResults])



      const removeSelected = (data, target, questionType) => {
        if (questionType === QUESTION_TYPES.SA.name && data) {
        const indexToRemove = data.findIndex(item => item['0'] === target);
        if (indexToRemove !== -1) {
            const filteredList = [
              ...data.slice(0, indexToRemove),
              ...data.slice(indexToRemove + 1),
            ];
      
            return filteredList
          }
        }
        else if (questionType === QUESTION_TYPES.FITB.name && data) {
            const indexToRemove = data.findIndex(item =>
                Object.entries(item).every(([key, value]) => target[key] === value)
              );
          
              if (indexToRemove !== -1) {
                const filteredList = [
                    ...data.slice(0, indexToRemove),
                    ...data.slice(indexToRemove + 1),
                  ];
            return filteredList
        }
      }
    }

    useEffect(() => {
        setIsSWitching(true);
        getQuestionsData();
    }, [window.innerWidth])
    
    return (
        <Row id="everything" className="results-container">
            {/* style={{width: isAnonymous ? "calc(100vw)" : "calc(100vw - 48px)"}} */}
            {/* {!isNarrow && (
                <div className="left-wrapper">
                    <div style={{flex: 1}}/>
                    <span className="left-header">Class Results</span>
                    <Boy width={`${Math.min(0.3 * curWindow.width, 0.6 * curWindow.height)}px`} height={`${Math.min(0.3 * curWindow.width, 0.6 * curWindow.height)}px`} margintop="25px"/>
                    <div style={{flex: 1}}/>
                    <div style={{alignSelf: "start", padding: 20}}>
                        <NetworkStatus tooltipPlacement={'right'} websocketState={websocketState}/>
                    </div>
                </div>
            )} */}

            <div> 
                {/* {isNarrow && <div className="left-header">Class Results</div>}
                <div className="right-header">{pollTitle}</div> */}

                <div className="waiting-room-title-container" style={{height: "fit-content" , marginTop: "32px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px" , justifyContent: "space-between"}}>
                    <span role={TEXTBOX} tabIndex={0} className="waiting-room-title" style={{ wordBreak: "break-word", paddingLeft: !isNarrow? "170px" : "48px", textAlign: "center", flex: "1", color: "#000", fontSize: "24px" }}>{SURVEY_RESULT_TEXT + pollTitle}</span>
                    <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState} /> </div>
                </div>

                <div style={{ display: "flex", width: "calc(100% - 36px)", gap: isMobile? "5px" : "25px", marginTop: "28px", marginLeft:  "18px", marginRight: "18px", flexDirection: isMobile? "column" : ""}}>
                    <div role={TEXTBOX} tabIndex={0} style={{ color: "#000", fontFamily: "Geomanist", fontSize:  isMobile? "14px" : "18px", alignItems: "center", display: "flex" }}> Choose Question </div>
                    <YuJaDropdown
                        containerStyle={{ width: !isMobile? "40%": "100%"}}
                        data={questions.filter(question => question.serialNo <= serialNo)}
                        value={selectedQuestion ? "Question " + selectedQuestion.serialNo + ": " + selectedQuestion.queTitle : ""}
                        getOptionLabel={item => "Question " + item.serialNo + ": " + item.queTitle}
                        getOptionValue={option => "Question " + option.serialNo + ": " + option.queTitle}
                        textStyle={{ overflow: "hidden", color: "#000", fontFamily: "Geomanist", fontSize: isMobile? "14px" : "18px", letterSpacing: "0.63px", whiteSpace: "nowrap", textOverflow: 'ellipsis', textWrap: "nowrap", height: "20px", maxWidth: "100%",  paddingTop: isMobile? "2px" : ""}}
                        onChange={(item) => {
                            setIsSWitching(true);
                            getQuestionsData();
                            setSelectedQuestion(item);
                        }}
                        style={{height: isMobile? "50px" : "40px"}}
                    />
                </div>

                {!isMobile &&
                <div className="class-result-survey-container" style={{marginLeft: "18px"}}>

                    <div style={{display: "flex", justifyContent:  "center"}}> 
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ParticipantsIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {PARTICIPANT_TEXT} </div>
                            </div>
                            <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {participantCount} </div>
                        </div>
                    </div>
                    </div> 

                    <div style={{display: "flex", justifyContent:  "center"}}> 
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ResponsesIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {RESPONSE_TEXT} </div>
                            </div>
                            <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {responsesCount} </div>
                        </div>
                    </div>
                    </div> 

                    <div style={{display: "flex", justifyContent:  "center"}}> 
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <CompletionRateIcon />
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {COMPLETION_RATE_TEXT} </div>
                            </div>
                            <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {completionRate} {completionRate !== "-" ? "%" : ""} </div>
                        </div>
                    </div>
                    </div> 

                    {/* <div style={{display: "flex", justifyContent:  "center"}}> 
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF" }}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <CorrectIcon />
                                <div style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {CORRECT_ANSWER_TEXT} </div>
                            </div>
                            <div style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {UNAVAILABLE_TEXT} </div>
                        </div>
                    </div>
                    </div> 

                    <div style={{display: "flex", justifyContent:  "center"}}> 
                    <div style={{ width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <div style={{ width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <WrongIcon />
                                </div>
                                <div style={{ fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px" }}> {WRONG_ANSWER_TEXT} </div>
                            </div>
                            <div style={{ fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700" }}> {UNAVAILABLE_TEXT} </div>
                        </div>
                    </div>
                    </div>  */}



                </div> } 

                {
                    isMobile && 
                    <div style={{width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", borderRadius: "19.565px", border: "1px solid #969696", background: "#fff", height: "fit-content", marginTop: "15px", padding: "10px"
                    }}> 
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", rowGap: '10px', columnGap: "10px"}}>

                                <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%"}}>
                                    <div style={{ marginRight: "5px", width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ParticipantsIcon />
                                    </div>
                                    <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px" }}> {PARTICIPANT_TEXT} </div>
                                    <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {participantCount} </div>
                                </div>
                            <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%"}}>
                                <div style={{ marginRight: "5px", width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ResponsesIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {RESPONSE_TEXT} </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {responsesCount} </div>
                            </div>

                            <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%", maxWidth: "50%"}}>
                            <div style={{ marginRight: "5px", width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CompletionRateIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {COMPLETION_RATE_TEXT} </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {completionRate}{completionRate !== "-" ? "%" : ""} </div>
                            </div>
                    </div>

                    </div> 
                }

                {
                    isMobile && 
                    <div style={{display: "flex", flexDirection: "column", marginTop: "16px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", gap: "15px"}}> 
                    <span className="gradientWrapQuesType" style={{ height: isNarrow ? "23px" : "45px", width: isNarrow ? "109px" : "150px", marginLeft: "0px"}}>
                                <span className="questionPanelQuestionTypeContainer" style={{ width: isNarrow ? "107px" : "", padding: "0px", height: isNarrow ? "20px" : "", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div role={TEXTBOX} tabIndex={0} className="questionPanelQuestionType" style={{ fontSize: "12px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height: isNarrow? "12px" : ""}}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                </span>
                            </span>

                            <div className="cur-question-title" tabIndex={0} role={TEXTBOX} style={{ fontSize: isMobile ? "14px" : "18px", wordBreak: "break-all"}}>Question {selectedQuestion.serialNo}  {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name ? " - " + selectedQuestion.queTitle : ""} </div> 

                            
                    {questionData.questionType === QUESTION_TYPES.FITB.name && <p className="ques-title" style={{whiteSpace: "pre-line", wordBreak:"break-word", order: "initial"}}>{questionData.queTitle.replace(BLANK_REGEX, "______")}</p> }

                    {!!questionData.questionImageURL && questionData.questionType !== QUESTION_TYPES.CI.name && (isNarrow || (questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.FITB.name)) && (
                        <div className="img-container" style={{display: 'flex', flex: "1", marginRight: 0, padding: "25px"}}>
                            {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                            {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                        </div>
                    )}

                    {questionData.questionImageURL && questionData.questionType === QUESTION_TYPES.CI.name && (
                        <div className="img-container" style={{marginRight: 0, display: 'flex', flex: "1", padding: "25px"}}>
                            <div style={{position: "relative"}}>
                                <img ref={imageRef} className="img-class-result" src={questionData.questionImageURL} alt="Question" onLoad={onLoadImg} />
                                {isImgLoaded && imageRef.current !== null
                                    && !!queResponses[selectedQuestion.serialNo - 1]?.selectedOption
                                    && queResponses[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("x")
                                    && queResponses[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("y")
                                    &&
                                    <COORDINATE
                                        style={{
                                            position: "absolute",
                                            top: `${decodeCIParam(queResponses[selectedQuestion.serialNo - 1].selectedOption.y, imageRef?.current.height) - CI_COORDINATE_SIZE.height}px`,
                                            left: `${decodeCIParam(queResponses[selectedQuestion.serialNo - 1].selectedOption.x, imageRef?.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    )}

                    {questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.FITB.name && questionData.questionType !== QUESTION_TYPES.WC.name && (
                        <>
                            <ClassResultChart
                                correctAnswers={questionData.correctAnswers}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                numOptions={questionData.questionType === QUESTION_TYPES.MCSS.name || questionData.questionType === QUESTION_TYPES.CI.name
                                    ? questionData.questionResults.length : 3}
                                pollType={pollType}
                                queResponse={queResponses[selectedQuestion.serialNo - 1]}
                            />
                        </>
                    )}

                    {/* {!dataReady && questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.FITB.name && questionData.questionType !== QUESTION_TYPES.WC.name &&
                        <div className="loading-text-back">
                            <p className="loading-text">Class Results Loading...</p>
                        </div>
                    } */}

                    {questionData.questionType === QUESTION_TYPES.SA.name &&
                        <>
                            <Col style={{height:"300px", overflowY: "auto"}}>
                                {/* {questionData.rawResponse?.map((item) =>
                                    item !== FIXED_ANSWER.UNANSWERED ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        tabIndex={0}
                                        style={{ marginTop: "20px", backgroundColor: "#42296E", width: "fit-content", padding: "15px", color: "#FFF", borderRadius: "10px"}}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )} */}

                                    {dataReady && queResponses && queResponses[selectedQuestion.serialNo - 1] && queResponses[selectedQuestion.serialNo - 1].selectedOption && queResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                    <>
                                    <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#C4BDD2",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                        {`"${queResponses[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                        </div> 
                                    
                                    {removeSelected(questionData.rawResponse,queResponses[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            tabIndex={0} 
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#CACACA",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                    </> 
                                 }

                                 { queResponses && (!queResponses[selectedQuestion.serialNo - 1] || !queResponses[selectedQuestion.serialNo - 1].selectedOption || queResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                  <>{questionData.rawResponse?.map((item) =>
                                    item !== "unanswered" ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px"
                                        }}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )}</>
                                 }
                            </Col>
                            {!isNarrow &&
                            <>
                                    {questionImageURL && (
                                        <div className="img-container" style={{marginRight: 0, display: 'flex',  justifyContent: 'end', flex: "1", padding: "25px"}}>
                                            {!isVideo && <img className="img-class-result" src={questionImageURL} alt="Question" />}
                                            {isVideo && <iframe allowFullScreen className="img-class-result" src={questionImageURL} alt="Question"/>}
                                        </div>
                                    )}
                            </>
                            }
                        </>
                    }

                    {questionData.questionType === QUESTION_TYPES.FITB.name &&
                        <>
                            <Col style={{height:"300px", overflowY: "auto"}}>
                                {/* {questionData.rawResponse?.map((item) =>
                                    item !== FIXED_ANSWER.UNANSWERED ? (
                                        <div
                                            tabIndex={0}
                                            role={TEXTBOX}
                                            className={`questionPanelAnswersText resultAnimation`}
                                            style={{ marginTop: "20px", backgroundColor: "#42296E", width: "fit-content", padding: "15px", color: "#FFF", borderRadius: "10px"}}
                                        >
                                            {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                        </div>
                                    ) : <></>
                                )} */}
                                {dataReady && queResponses && queResponses[selectedQuestion.serialNo - 1] && queResponses[selectedQuestion.serialNo - 1].selectedOption && queResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                <>
                                 <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#C4BDD2",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(queResponses[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${queResponses[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                </div>
                                <div>
                                {removeSelected(questionData.rawResponse, queResponses[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>

                                </>
                                    }

                                    {queResponses && (!queResponses[selectedQuestion.serialNo - 1] || !queResponses[selectedQuestion.serialNo - 1].selectedOption || queResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                        <>{questionData.rawResponse?.map((item) =>
                                        item !== "unanswered"? (
                                           <div
                                               className={`questionPanelAnswersText resultAnimation`}
                                               style={{
                                                   marginTop: "20px",
                                                   backgroundColor: "#CACACA",
                                                   color: "#000", borderRadius: "10px",
                                                   width: "fit-content",
                                                   padding: "15px",
                                               }}
                                           >
                                               {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                           </div>
                                       ) : <></> )} </>
                                    
                                    }
                            </Col>
                            {!isNarrow &&
                                <>
                                    {questionImageURL && (
                                        <div className="img-container" style={{marginRight: 0, display: 'flex',  justifyContent: 'end', flex: "1", padding: "25px"}}>
                                            {!isVideo && <img className="img-class-result" src={questionImageURL} alt="Question" />}
                                            {isVideo && <iframe allowFullScreen className="img-class-result" src={questionImageURL} alt="Question"/>}
                                        </div>
                                    )}
                                </>
                            }
                        </>
                    }

                    {questionData.questionType === QUESTION_TYPES.WC.name &&
                    <div style={{width: "100%"}}> 
                        <WordCloudResult
                            data={questionData.rawResponse}
                            isSwitching={isSwitching}
                            setIsSWitching={setIsSWitching}
                        />
                        </div>
                    }

                    </div> 

                }

                {!isMobile &&
                <div style={{ width: "calc(100% - 36px)", borderRadius: "8px", background: "#FFF", boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)", marginTop: "30px", minHeight: "385px", padding: "22px", marginLeft: "18px", marginRight: "18px"}}>
                    <div className="header-board" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <div tabIndex={0} role={TEXTBOX} className="cur-question-title" style={{ fontSize: "18px" }}>Question {selectedQuestion.serialNo}</div>
                        {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name && (
                            <>
                                <ArrowRight />
                                <p tabIndex={0} aria-label={QUESTION_TITLE + question.queTitle} className="ques-desc" style={{ lineHeight: "normal", color: "#000", order: "unset", marginTop: "0px", maxWidth: "70%" }}>
                                    {selectedQuestion.queTitle}
                                </p>
                            </>
                        )}
                        <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1" }}>
                            <span className="gradientWrapQuesType" style={{ height: "45px", width: "150px" }}>
                                <span className="questionPanelQuestionTypeContainer" style={{ padding: "0px" }}>
                                    <div tabIndex={0} role={TEXTBOX} className="questionPanelQuestionType" style={{ fontSize: "14px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center" }}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                </span>
                            </span>
                        </div>
                    </div>

                    {questionData.questionType === QUESTION_TYPES.FITB.name && <p tabIndex={0} aria-label={queTitle} className="ques-title" style={{whiteSpace: "pre-line", wordBreak:"break-word"}}>{questionData.queTitle.replace(BLANK_REGEX, "______")}</p> }
                    <div style={{display: "flex"}}> 

                    {questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.FITB.name && questionData.questionType !== QUESTION_TYPES.WC.name && (
                        <div style={{width:  "50%"}}>
                            <ClassResultChart
                                correctAnswers={questionData.correctAnswers}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                numOptions={questionData.questionType === QUESTION_TYPES.MCSS.name || questionData.questionType === QUESTION_TYPES.CI.name
                                    ? questionData.questionResults.length : 3}
                                pollType={pollType}
                                queResponse={queResponses[selectedQuestion.serialNo - 1]}
                            />
                        </div>
                    )}

                    {/* {!dataReady && questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.FITB.name && questionData.questionType !== QUESTION_TYPES.WC.name &&
                        <div className="loading-text-back">
                            <p className="loading-text">Class Results Loading...</p>
                        </div>
                    } */}

                    {questionData.questionType === QUESTION_TYPES.SA.name &&
                        <>
                            <div style={{width:  "50%"}}>
                            <Col style={{height:"300px", overflowY: "auto"}}>
                                {/* {questionData.rawResponse?.map((item) =>
                                    item !== FIXED_ANSWER.UNANSWERED ? (
                                    <div
                                        tabIndex={0}
                                        role={TEXTBOX}
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{ marginTop: "20px", backgroundColor: "#42296E", width: "fit-content", padding: "15px", color: "#FFF", borderRadius: "10px"}}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )} */}
                                 {dataReady && queResponses && queResponses[selectedQuestion.serialNo - 1] && queResponses[selectedQuestion.serialNo - 1].selectedOption && queResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                    <>
                                    <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#C4BDD2",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                        {`"${queResponses[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                        </div> 
                                    
                                    {removeSelected(questionData.rawResponse,queResponses[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            tabIndex={0} 
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#CACACA",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                    </> 
                                 }

                                 {queResponses && (!queResponses[selectedQuestion.serialNo - 1] || !queResponses[selectedQuestion.serialNo - 1].selectedOption || queResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") &&
                                  <>{questionData.rawResponse?.map((item) =>
                                    item !== "unanswered" ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px"
                                        }}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )}</>
                                 }
                            </Col>
                            </div>
                            {!isNarrow &&
                            <>
                                    {questionData.questionImageURL && (
                                        <div className="img-container" style={{marginRight: 0, display: 'flex',  justifyContent: 'center', flex: "1", padding: "25px"}}>
                                            {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                                            {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                                        </div>
                                    )}
                            </>
                            }
                        </>
                    }

                    {questionData.questionType === QUESTION_TYPES.FITB.name &&
                        <>
                            <div style={{width:  "50%"}}>
                            <Col style={{height:"300px", overflowY: "auto"}}>
                                {/* {questionData.rawResponse?.map((item) =>
                                    item !== FIXED_ANSWER.UNANSWERED ? (
                                        <div
                                            tabIndex={0}
                                            role={TEXTBOX}
                                            className={`questionPanelAnswersText resultAnimation`}
                                            style={{ marginTop: "20px", backgroundColor: "#42296E", width: "fit-content", padding: "15px", color: "#FFF", borderRadius: "10px"}}
                                        >
                                            {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                        </div>
                                    ) : <></>
                                )} */}

                                {dataReady && queResponses  && queResponses[selectedQuestion.serialNo - 1] && queResponses[selectedQuestion.serialNo - 1].selectedOption && queResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                <>
                                 <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#C4BDD2",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(queResponses[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${queResponses[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                </div>
                                <div>
                                {removeSelected(questionData.rawResponse, queResponses[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>

                                </>
                                    }

                                    {queResponses && (!queResponses[selectedQuestion.serialNo - 1] || !queResponses[selectedQuestion.serialNo - 1].selectedOption || queResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                        <>{questionData.rawResponse?.map((item) =>
                                        item !== "unanswered"? (
                                           <div
                                               className={`questionPanelAnswersText resultAnimation`}
                                               style={{
                                                   marginTop: "20px",
                                                   backgroundColor: "#CACACA",
                                                   color: "#000", borderRadius: "10px",
                                                   width: "fit-content",
                                                   padding: "15px",
                                               }}
                                           >
                                               {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                           </div>
                                       ) : <></> )} </>
                                    
                                    }
                            </Col>
                            </div>
                            {!isNarrow &&
                                <>
                                    {questionData.questionImageURL && (
                                        <div className="img-container" style={{marginRight: 0, display: 'flex',  justifyContent: 'center', flex: "1", padding: "25px"}}>
                                            {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                                            {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                                        </div>
                                    )}
                                </>
                            }
                        </>
                    }

                    {questionData.questionType === QUESTION_TYPES.WC.name &&
                    <div style={{width: "50%"}}> 
                        <WordCloudResult
                            data={questionData.rawResponse}
                            // pollType={POLL_TYPE.SURVEY}
                            isSwitching={isSwitching}
                            setIsSWitching={setIsSWitching}
                        />
                        </div>
                    }

                    {!!questionData.questionImageURL && questionData.questionType !== QUESTION_TYPES.CI.name && (isNarrow || (questionData.questionType !== QUESTION_TYPES.SA.name && questionData.questionType !== QUESTION_TYPES.FITB.name)) && (
                        <div className="img-container" style={{display: 'flex',  justifyContent: 'center', flex: "1", marginRight: 0, padding: "25px"}}>
                            {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt="Question" />}
                            {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                        </div>
                    )}

                    {questionData.questionImageURL && questionData.questionType === QUESTION_TYPES.CI.name && (
                        <div className="img-container" style={{marginRight: 0, display: 'flex',  justifyContent: 'center', flex: "1", padding: "25px"}}>
                            <div style={{position: "relative"}}>
                                <img ref={imageRef} className="img-class-result" src={questionData.questionImageURL} alt="Question" onLoad={onLoadImg} />
                                {isImgLoaded && imageRef.current
                                    && !!queResponses[selectedQuestion.serialNo - 1]?.selectedOption
                                    && queResponses[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("x")
                                    && queResponses[selectedQuestion.serialNo - 1].selectedOption.hasOwnProperty("y")
                                    &&
                                    <COORDINATE
                                        style={{
                                            position: "absolute",
                                            top: `${decodeCIParam(queResponses[selectedQuestion.serialNo - 1].selectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                            left: `${decodeCIParam(queResponses[selectedQuestion.serialNo - 1].selectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    )}
                </div>
                </div>
                }


                <div className={"footer-container"}/>

                {/*<div className={"footer-container"}>*/}
                {/*    <div style={{flex: "auto"}}>Your Answer: {yourAnswer}</div>*/}
                {/*</div>*/}


                <div className="d-flex" style={{ width: "100%", marginBottom: isMobile ? 100 : 20}}>
                    {isMobile &&
                        <div style={{position: "fixed", left: 0, bottom: "7.5vw", paddingLeft: '5vw', paddingRight: '5vw', display: "flex", justifyContent: 'space-between', width: '100%'}}>
                            <div>
                                {/* {
                                    (
                                        (pollLiveQuestionIdx === 0 && queResponses[0]?.skippedQuestion)
                                        || pollLiveQuestionIdx > 0
                                    )
                                    && pollLiveQuestionIdx < questions.length - 1 &&
                                    <PreviousEnabled onClick={previous}/>
                                } */}
                            </div>
                            {pollLiveQuestionIdx < questions.length - 1 &&
                                 <div>
                                 <div className={"gradientWrap"} style={{ width: isMobile ? "40px" : "75px", height: isMobile ? "40px" : "45px" }}>
                                     <span onClick={next} tabIndex={0} aria-label={NEXT_QUES} role={BUTTON} className={"gradientBtnArrows"} style={{ width: isMobile ? "35px" : "70px", height: isMobile ? "35px" : "40px", borderRadius: isMobile ? "3px" : "", padding: isMobile ? "0" : "", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                         <NextIcon />
                                     </span>
                                 </div>
                             </div>
                            }
                            {/* {pollLiveQuestionIdx >= questions.length - 1 &&
                                <YuJaButton style={{fontSize: 20, padding: "10px 25px"}} id="sub-btn-curr" onClick={viewAll}>View All</YuJaButton>
                            } */}
                        </div>
                    }

                    {!isMobile &&
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', paddingLeft: '18px', paddingRight: '18px'}}>
                            <div>
                                {/* {
                                    (
                                        (pollLiveQuestionIdx === 0 && queResponses[0]?.skippedQuestion)
                                        || pollLiveQuestionIdx > 0
                                    )
                                    && pollLiveQuestionIdx < questions.length - 1 &&
                                    <button className="submit-btnr-new" onClick={previous}>Previous</button>
                                } */}
                            </div>
                            {pollLiveQuestionIdx < questions.length - 1 &&
                                <YuJaButton
                                id="sub-btn-curr"
                                onClick={next}
                                style={{fontSize: 20, padding: "10px 25px"}}
                            >
                                Next
                            </YuJaButton>
                            }
                            {/* {pollLiveQuestionIdx >= questions.length - 1 &&
                                <YuJaButton
                                    id="sub-btn-curr"
                                    onClick={viewAll}
                                    style={{fontSize: 20, padding: "10px 25px"}}
                                >
                                    View All
                                </YuJaButton>
                            } */}
                        </div>
                    }
                </div>

            </div>
        </Row>
    );
}