import React, {useEffect, useRef, useState} from "react";
import styles from "./YuJaDropdown.module.css";
import {ReactComponent as ArrowDown} from "../../images/YuJa_arrow_down_icon.svg";
import {ReactComponent as ArrowUp} from "../../images/YuJa_arrow_up_icon.svg";
import {useWindowDimensions} from "react-native-web";
import {YuJaTooltip} from "./YuJaTooltip";
import {useYuJaEventBus} from "./YuJaEventBusProvider";
import { TEXTBOX } from "../../utils/constants";


export const YuJaDropdown = ({ data=[], onChange, ariaLabel, isInvalid=false, errorMsg="", name, id, arrowSize=null, value, placeholder, getOptionLabel, getOptionValue, containerStyle={}, style={}, textStyle={}, itemStyle={}, ...props}) => {
    const windowDimensions = useWindowDimensions();
    const dropdownRef = useRef(null);
    const menuRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [padding, setPadding] = useState(10);
    const [menuHeight, setMenuHeight] = useState(200);
    const actualValInputRef = useRef();
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();

    const open = () => {
        if (props.disabled) {
            return;
        }
        const {height: windowHeight} = windowDimensions;
        setIsOpen(!isOpen);
        const {height, top, } = dropdownRef.current?.getBoundingClientRect();
        if (top + height + menuHeight > windowHeight) {
            setMenuHeight(100);
        } else {
            setMenuHeight(200);
        }
    }

    const onClick = (item) => {
        setIsOpen(false);
        const input = actualValInputRef.current;
        if (input) {
            input.value = item.value;
        }
        if (onChange) {
            onChange(item);
        }
    }

    const getLabel =(item) => {
        if (!item) {
            return "";
        }
        if (getOptionLabel) {
            return getOptionLabel(item);
        }

        return item.label;
    }

    const getValue =(item) => {
        if (!item) {
            return "";
        }
        if (getOptionValue) {
            return getOptionValue(item);
        }

        return item.value;
    }

    useEffect(() => {
        if (style.padding) {
            setPadding(style.padding);
            return;
        }
        const inputFontSize = parseFloat(getComputedStyle(dropdownRef.current).fontSize);
        if (inputFontSize > 14) {
            setPadding(inputFontSize * 10 / 14);
        }

    }, []);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        for (const node of menuRef.current?.children) {
            const textNode = node?.children?.length ? node.children[0] : null;
            if (textNode && textNode.offsetWidth < textNode.scrollWidth) {
                textNode.title = textNode.innerText;
            }
        }
    }, [isOpen]);

    const handleKeyDown = (e, index) => {
        e.preventDefault();
    
        if (e.key === "ArrowDown" && index < data.length - 1 && isOpen) {
            document.getElementById("YuJaDropdown"+(index+1)).focus();
        }
    
        if (e.key === "ArrowUp" && index > 0 && isOpen) {
            document.getElementById("YuJaDropdown"+(index-1)).focus();
        }
    };

    const handleTabDown = (e) => {        
        if(e.key === "ArrowDown") {
            e.preventDefault();
            setIsOpen(true);
            document.getElementById("YuJaDropdown0").focus();
        }
    };

    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isOpen && menuRef.current && !dropdownRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            addClickEventHandler(handleGlobalClick);
        }

        return () => {
            removeClickEventHandler(handleGlobalClick);
        };
    }, [isOpen]);

    return (
        <div className={styles.container} style={containerStyle}>
            <div
                ref={dropdownRef}
                className={`${styles.presenter} ${isOpen ? styles.opened : ""} ${isInvalid ? styles.invalid : ""} ${props.disabled ? styles.disabled : ""}`}
                onClick={open}
                style={{...style, ...{padding: padding}}}
                tabIndex={0}
                aria-label={ariaLabel}
                onKeyDown={(e) => handleTabDown(e)}
                role={TEXTBOX}
                id={id}
            >
                <span className={styles.presenterText} style={{...textStyle}}>{value ? getLabel(data.find(item => getValue(item) === value)) : placeholder}</span>
                {isOpen ? <ArrowUp style={{width: arrowSize? arrowSize : "18px", height: arrowSize? arrowSize : "18px"}} /> : <ArrowDown style={{width: arrowSize? arrowSize : "18px", height: arrowSize? arrowSize : "18px"}}/> }
                {isInvalid && errorMsg &&
                    <div className={styles.iconContainer}>
                        <YuJaTooltip placement={"right"} text={errorMsg} tooltipStyle={{maxWidth: 300}}>
                            <div className={styles.invalidIcon}/>
                        </YuJaTooltip>
                    </div>
                }
            </div>
            <input ref={actualValInputRef} defaultValue={value} name={name} style={{display: "none"}} id={value} aria-label="dropdown"/>
            {isOpen &&
                <>
                    <div ref={menuRef} className={styles.menuList} style={{maxHeight: menuHeight, marginTop: "5px"}}>
                        {data.map((item, idx) => (
                            <div
                                key={idx}
                                id={"YuJaDropdown" + idx}
                                className={`${styles.menuItem} ${getValue(item) === value ? styles.selected : ""}`}
                                style={{...itemStyle, ...{padding: padding}}}
                                onClick={() => onClick(item)}
                                onKeyDown={(e) => handleKeyDown(e, idx)}
                                tabIndex={0}
                                role={TEXTBOX}
                            >
                                <span className={styles.menuItemText}>{getLabel(item)}</span>
                            </div>
                        ))}
                    </div>
                </>
            }
        </div>

    );
}