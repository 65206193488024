import React, { useState, useEffect } from "react";
import styles from "./platform.module.css"

import UserSettingTab from "./UserSettingTab";
import {UserApiCalls} from "../../apis/UserApiCalls";
import {useLocalStorageNew} from "../../hooks/useLocalStorageNew";
import {DEFAULT_TOAST_MESSAGE_DURATION, TEXTBOX, USER_SETTINGS, VIEWER_MENU_LIST, VIEWER_SETTINGS_SAVE_TEXT} from "../../utils/constants";
import YuJaButton from "../../components/standardization/YuJaButton";

export default function ViewerSettings() {
    const [, getSession] = useLocalStorageNew("session", {});
    const [userSettings,  setUserSettings] = useState(() => {
        const {settings={}} = getSession();
        if (!settings.hasOwnProperty(USER_SETTINGS.TOAST_MESSAGE_DURATION)) {
            settings[USER_SETTINGS.TOAST_MESSAGE_DURATION] = DEFAULT_TOAST_MESSAGE_DURATION.toString();
        }
        return settings;
    });

    useEffect(() => {
        if(document.getElementById("userLastElem") != null) {
            document.getElementById("userLastElem").addEventListener('keydown', function (e) {
                if (e.key === 'Tab' && !e.shiftKey && document.getElementById("settingsSaveBtn")) {
                  e.preventDefault();
                  document.getElementById('settingsSaveBtn').focus();
                }
            });
        }

        if(document.getElementById("engagePageIdentifier") != null) {
          document.getElementById("engagePageIdentifier").innerText = VIEWER_MENU_LIST[4].name;
        }
        
        const handleWindowResize = () => {
          if(document.getElementById("engagePageIdentifier") != null) {
            document.getElementById("engagePageIdentifier").innerText = VIEWER_MENU_LIST[4].name;
          }
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const {saveSettings} = UserApiCalls();

    const handleSaveUserSettings = async () => {
        saveSettings(userSettings);
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.btnCenterAlign}>
                <YuJaButton id="settingsSaveBtn" index={1} onClick={handleSaveUserSettings}>{VIEWER_SETTINGS_SAVE_TEXT}</YuJaButton>
            </div>
            <div className={styles.body}>
                <div className={styles.titleBox}>
                    <div className={styles.titleBoxIcon} />
                    <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>Settings</div>
                </div>
                <div className={styles.container}>
                    <UserSettingTab userSettings={userSettings} setUserSettings={setUserSettings}/>
                </div>
            </div>
        </div>
    )
};