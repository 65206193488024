import QrReader from "modern-react-qr-reader";
import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import NormalText from "../../public/texts/NormalText";
import {INVALID_POLL_CODE_ERROR, QR_SCANNER_HEIGHT, QR_SCANNER_WIDTH, VIEWER_USER_TYPE, POLL_DISABLE_ANONYMOUS_WARNING, ANONYMOUS_JOIN_SETTING_TEXT, ANONYMOUS_JOIN_MODAL_TITLE, ANONYMOUS_JOIN_MODAL_SUBTITLE, ANONYMOUS_JOIN_MODAL_BUTTON, SCAN_QR_TITLE} from "../../utils/constants";
import {formatErrorLog, notifyError} from "../../utils/helpers";
import styles from "./ScanQRPage.module.css";
import {ResponseApiCalls} from "../../apis/ResponseApiCalls";
import useHostname from "../../hooks/useHostname";
import AnonyJoinPollNameRequiredModal from "../../components/modals/AnonyJoinPollNameRequiredModal";
import { useCookie } from "../../hooks/useCookie";
import { JOIN_COURSE_POLL_ANONYMOUSLY_ERROR } from "../../utils/toast-message-constants";
import { ReactComponent as BackButton } from "../../images/backButton.svg";
import { ReactComponent as ScanProperty } from "../../images/scanProperty.svg";
import { HOME_PATH } from "../../utils/constants";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { USER_ACCESS_POLL_ERROR } from "../../utils/toast-message-constants";


export default function ScanQRPage() {
  const [, getSession] = useLocalStorageNew("session", {});
  const hostResource = useHostname(window.location.hostname);
  const [name, setName] = useState(""); 
  const [nameError, setNameError] = useState("");
  const [setCookie, , ] = useCookie("name", "Guest");
  const [pollCode, setPollCode] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const {setUserNameToLocalStorage, getPollRecordFromUniqueCode} = ResponseApiCalls();
  const [nameRequired, setNameRequired] = useState(false);
  const { checkMemberAccess } = CourseApiCalls();
  const session = getSession();
  const history = useHistory();
  const delay = 5000;
  const previewStyle = {
    height: "100vh",
    width: "100vw",
  };

  const handleError = (err) => {
    console.error(err);
  };

  const clearForm = () => {
    setName("");
    setNameError("");
  };

  const checkUserAccess = async(courseId) => {
    if (courseId == null) {
        return true;
    }

    const accessibleResult = await checkMemberAccess(courseId.substring(2));
    console.log(accessibleResult)
    if (accessibleResult == null || accessibleResult.result == null || !accessibleResult.result) {
        notifyError(USER_ACCESS_POLL_ERROR);
        formatErrorLog("User cannot access the poll");
        return false; 
    }

    return true;
  }

  const handleJoin = async () => {
    let nameTrimmed = name.trim();
    if (nameRequired && nameTrimmed === "") {
      setNameError(POLL_DISABLE_ANONYMOUS_WARNING);
      notifyError(POLL_DISABLE_ANONYMOUS_WARNING);
      return; 
    }
    else {
      setCookie(nameTrimmed);
      setUserNameToLocalStorage(pollCode, nameTrimmed, VIEWER_USER_TYPE.MANUAL_GUEST);
      history.push("/join/" + pollCode); 
    }
    
  };

  const modalConfig = {
    title: ANONYMOUS_JOIN_MODAL_TITLE,
    subText: ANONYMOUS_JOIN_MODAL_SUBTITLE,
    submitButtonText: ANONYMOUS_JOIN_MODAL_BUTTON,
    clearForm: clearForm,
    submit: handleJoin,
    form: [
      {
        label: "Name",
        value: name,
        setFunction: setName,
        error: nameError,
        type: "text",
      },
    ],
  };

  const handleScan = async (scanData) => {
    // if (scanData) {
    //   const pollCode = scanData.slice(-6);
    //   if(await checkPollExistsInCourse(pollCode)) {
    //     notifyError("This poll cannot be accessed anonymously!");
    //     return false;
    //   }else {
    //     setUserNameToLocalStorage(pollCode, session.userId, VIEWER_USER_TYPE.LOGGED_IN);
    //     history.push("/join/" + pollCode); // Pushes to the JoinQuizPage using last 6 characters as pollCode
    //   }
	// 	}

    if (!scanData) {
      return ;
    }

    const pollCode = scanData.slice(-6);
    const result = await getPollRecordFromUniqueCode(pollCode);
    if (!result || !result.poll) {
      notifyError(INVALID_POLL_CODE_ERROR);
      formatErrorLog(INVALID_POLL_CODE_ERROR);
      return;
    }
    if (session.userId) {
      setUserNameToLocalStorage(pollCode, session.userId, VIEWER_USER_TYPE.LOGGED_IN);
      history.push("/join/" + pollCode);
    }

    const {index2Pk: courseId} = result.poll;
    if (!!courseId && session.authorization && session.userId) {
      if (await checkUserAccess(courseId)) {
          setUserNameToLocalStorage(pollCode, session.userId, VIEWER_USER_TYPE.LOGGED_IN);
          history.push(`/join/${pollCode}`);
        }
    } 
    else if (!courseId && session.userId) {
      setUserNameToLocalStorage(pollCode, session.userId, VIEWER_USER_TYPE.LOGGED_IN);
      history.push("/join/" + pollCode);
    }
    if (!!courseId) {
      formatErrorLog("This poll cannot be accessed anonymously!");
      notifyError(JOIN_COURSE_POLL_ANONYMOUSLY_ERROR);
      return;
    }
    setNameRequired(result.poll.hasOwnProperty("anonymousJoin") && result.poll["anonymousJoin"] === true)
    setPollCode(pollCode);
    setModalShow(true);
  };

  return (
    <div className={styles.container}>
      <AnonyJoinPollNameRequiredModal name={name} setName={setName} modalShow={modalShow} setModalShow={setModalShow} pollCode={pollCode}/>
      <div style={{position: "fixed", backgroundColor: "rgba(20, 27, 39, 0.80)", width: "100vw", height: "240px", top : "0px", zIndex: "1", margin: 0, padding: 0}}></div>
      <div style={{position: "fixed", backgroundColor: "rgba(20, 27, 39, 0.80)", width: "100vw", height: "calc(100% - 455px)", top : "455px", zIndex: "1", margin: 0, padding: 0}}></div>
      <div style={{position: "fixed", backgroundColor: "rgba(20, 27, 39, 0.80)", width: "calc((100% - 215px) / 2)", left: "0px", height: "215px",  top : "240px", zIndex: "1" , margin: 0, padding: 0}}></div>
      <div style={{position: "fixed", backgroundColor: "rgba(20, 27, 39, 0.80)", width: "calc((100% - 215px) / 2)", right: "0px", height: "215px",  top : "240px", zIndex: "1" , margin: 0, padding: 0}}></div>
      <div  onClick={() => {if (session.authorization && session.userId) {
                    history.push(HOME_PATH);
                } else {window.location.href = "/qr"}}} style={{ position: "absolute",  zIndex: "2", width: "38px", height: "38px", borderRadius: "50%", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", top: "38px", left: "38px"}}> <BackButton /> </div>
      <div style={{color: "#FFF", fontSize: "20px", fontFamily: "Geomanist", position: "absolute", top: "44px", zIndex: "2"}}>
        {SCAN_QR_TITLE}
      </div>
      <QrReader
        delay={delay}
        facingMode={"environment"}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
        className={styles.qrImage}
        showViewFinder={false}
      />
      <div className={styles.animationBox}> </div>
      <ScanProperty  style={{ position: "absolute",  zIndex: "2", backgroundColor: "none", top: "222px"}} />
      <div style={{position: "absolute", width: "215px", height: "215px", top: "240px"}}> </div>
    </div>);
}
