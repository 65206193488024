import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import useMobileAccess from "../../hooks/useMobileAccess";
import styles from "./CourseContent.module.css";
import CourseMemberTable from "./CourseMemberTable";
import YuJaButton from "../../components/standardization/YuJaButton";
import { CREATOR_MENU_LIST, SEARCH_BOX, VIEW_COURSE_LABEL } from "../../utils/constants";

export default function CourseMembersContent({courseMembers, addMembers}) {
  const [filter, setFilter] = useState({});
  const [keyword, setKeyword] = useState("");
  const [memberData, setMemberData] = useState([]);

  useEffect(() => {
    if(document.getElementById("engagePageIdentifier") != null) {
        document.getElementById("engagePageIdentifier").innerText = CREATOR_MENU_LIST[5].name;
    }
  }, []);

  useEffect(() => {
    const copy = [...courseMembers];
    copy.sort(function (a, b) {
      return a.firstName.trim().toLowerCase() - b.firstName.trim().toLowerCase();
    });
    setMemberData(courseMembers);
  }, [courseMembers]);


  const clickSearch = () => {
    setFilter({keyword: keyword});
  }

  const enterKeyup = (e) => {
    if (e.key === 'Enter') {
      clickSearch();
    }
  }

  const isMobileScreen = useMobileAccess();

  const columns = [
    { Header: 'Username', accessor: 'userName', width: isMobileScreen ? 100 : 120 },
    { Header: 'First Name', accessor: 'firstName', width: isMobileScreen ? 100 : 120 },
    { Header: 'Last Name', accessor: 'lastName', width: isMobileScreen ? 120 : 140 },
    { Header: 'Email', accessor: 'email', width: isMobileScreen ? 160 : 200 },
    { Header: 'Role', accessor: 'role', width: isMobileScreen ? 100 : 120 },
  ];

  return (
    <>
      {memberData && memberData.length !== 0 ? (
        <>
          <div className={styles.searchBox}>
            <Form className={styles.searchBoxForm} onSubmit={e => e.preventDefault()}>
              <Form.Control
                aria-label={SEARCH_BOX}
                className={styles.searchBoxKeywordInput}
                placeholder={VIEW_COURSE_LABEL}
                onChange={e => setKeyword(e.target.value)}
                onKeyUp={enterKeyup}
                value={keyword}
              />
              <YuJaButton type="secondary" style={{margin: "0px 15px"}} onClick={clickSearch}>Search</YuJaButton>
              <YuJaButton type="secondary" onClick={addMembers}>Add Course Members</YuJaButton>
            </Form>
          </div>
          {/* <NormalBox boxType={settings.contentBoxType} className={`${styles.memberTableContainer} ${styles.blankContentLayout}`}> */}
          <div className={`${styles.memberTableContainer} ${styles.blankContentLayout}`}>
            <CourseMemberTable columns={columns} data={memberData} globalFilterObj={filter} />
          </div>
          {/* </NormalBox> */}
        </>
      ) : (
        <>
          <div className={styles.c2}>
            <div className={styles.titleBox}>
              <div className={styles.titleBoxTitle}>You have not add any course members.</div>
            </div>
            <div className={styles.text}>Press the button below to add Course Members.</div>
            <div className={styles.center}>
              <YuJaButton
                onClick={addMembers}
              >
                Add Members
              </YuJaButton>
            </div>

          </div>
        </>
      )}
    </>
  );
}