import React, { useEffect, useState, useRef} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PollApiCalls } from "../apis/PollApiCalls";
import "../components/buttons/genButton.css";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import { QUESTION_LENGTH, QUESTION_TYPES, POLL_TYPE, CI_QUESTION_TITLE, TEXTBOX, VIDEO_ERROR_CI } from "../utils/constants";
import ClickableImage from "./CI/ClickableImage";
import { ReactComponent as Discard } from "../images/discard.svg";
import {
  fetchImgFile,
  getFileNameFromImageName,
  getFormattedCurrentTime,
  getSuffixFromImageName,
  notifyError
} from "../utils/helpers";
import { S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME } from "../utils/properties";
import { updateTitleInput } from "../utils/questionUpdate";
import {
  deleteQuestion,
  duplicateQuestion,
  questionCodeToName,
  questionNameToCode
} from "../utils/questionUtils";
import { QUESTION_CHARACTER_LIMIT_EXCEEDED } from "../utils/toast-message-constants";
import BaseQuestionTitle from "./BaseQuestionTitle";
import CIQuestion from "./CI/CIQuestion";
import FITBQuestion from "./FITB/FITBQuestion";
import MCQuestion from "./MCSS/MCQuestion";
import TFQuestion from "./TF/TFQuestion";
import UploadQuestionImage from "./UploadQuestionImage";
import MHQuestion from "./MH/MHQuestion";
import YuJaButton from "../components/standardization/YuJaButton";
import {ReactComponent as Deleting} from "../images/delete_uploaded_image_new.svg";
import useMobileAccess from "../hooks/useMobileAccess";
import {ReactComponent as FITBIcon} from "../images/fitb_purple.svg";
import {ReactComponent as MCSSIcon} from "../images/mcss_purple.svg";
import {ReactComponent as MHIcon } from "../images/matching_purple.svg"
import {ReactComponent as SAIcon} from "../images/sa_purple.svg";
import {ReactComponent as TFIcon} from "../images/tf_purple.svg";
import {ReactComponent as ICIcon} from "../images/clickable_purple.svg";
import {ReactComponent as WCIcon} from "../images/wcloud_purple.svg";
import { CreatePollDropDown } from "../components/standardization/CreatePollDropDown";
import QuestionTypeOption from "../pages/CreateQuizPage/QuestionTypeOption";
import { updateQuestionType } from "../utils/questionUpdate";
import { isOpera } from "react-device-detect";
import { useLoading } from "../utils/LoadingContext";

export default function BaseQuestion({
                                       selectedQuestion,
                                       setQuestions,
                                       questions,
                                       currQueDisplay,
                                       changeCurrQueDisplay,
                                       pollType,
                                       institutionId,
                                       pollKey, 
                                       questionBankId = null,
                                       isSaving = false,
                                       isBank=false,
                                     }) {
  const { questionType, queTitle, serialNo } = selectedQuestion;
  const indexToChange = serialNo - 1;
  const [, getSession] = useLocalStorageNew("session", {});
  const { tempSaveImage , deleteImage, deleteVideo} = PollApiCalls();
  const session = getSession();
  const userId = session.userId;
  const [savingText, setSavingText] = useState("saving");
  const [setQues, ] = useLocalStorageNew("questions", []);
  const divRef = useRef();
  const { setLoading } = useLoading();

  const [questionTypeOpen, setQuestionTypeOpen] = useState(false); 

  const isNarrow = useMobileAccess(1100);
  // const handleQuestionTypeUpdate = (qType) => {
  //   setQuestions(updateQuestionType(qType, indexToChange, questions));
  // };

  // const handleTimeLimitUpdate = (timeLimit) => {
  //   setQuestions(updateTimeLimit(timeStringToSeconds(timeLimit), indexToChange, questions));
  // };

  // const handleWeightageUpdate = (weightage) => {
  //   var w = weightage.replace(/[^0-9]/g, '');
  //   console.log("weightage", w);
  //   setQuestions(updateWeightage(w, indexToChange, questions));
  // };

  const questionTypeOptions = useRef([]);
    questionTypeOptions.current = [
        QUESTION_TYPES.MCSS,
        QUESTION_TYPES.SA,
        QUESTION_TYPES.TF,
        QUESTION_TYPES.FITB,
        QUESTION_TYPES.CI,
    ];
    if (isBank || (!!pollType && pollType.toUpperCase() === POLL_TYPE.SURVEY)) {
        questionTypeOptions.current.push(QUESTION_TYPES.WC);
        if(isBank) {
            questionTypeOptions.current.push(QUESTION_TYPES.MH);
        }
    }
    else {
        questionTypeOptions.current.push(QUESTION_TYPES.MH);
    }


  const handleTitleUpdate = (e) => {
    if(e.target.value.length <= QUESTION_LENGTH) {
      setQuestions(updateTitleInput(e.target.value, indexToChange, questions));
    }
    else {
      notifyError(QUESTION_CHARACTER_LIMIT_EXCEEDED);
      setQuestions(updateTitleInput(e.target.value.substring(0, QUESTION_LENGTH), indexToChange, questions));
    }
  };


  const handleQuestionTypeUpdate = (qType) => {
    // setSelectedQuestionType(questionNameToCode(qType));
    if(qType === QUESTION_TYPES.CI.description && selectedQuestion.directLinkEvp) {
      notifyError(VIDEO_ERROR_CI);
    }
    else {
      setQuestions(updateQuestionType(qType, currQueDisplay - 1, questions, questionType));
    }
};

  const handleClose = async (e) => {
    if(!!selectedQuestion.directLinkEvp) {
      if(!pollKey) {
        selectedQuestion.directLinkEvp = null;
      }
      else {
        const res = await deleteVideo(pollKey, selectedQuestion.serialNo);
        if(res === "success") {
          selectedQuestion.directLinkEvp = null;
        }
        setLoading(false);
      }
      return;
    }

    if (!!selectedQuestion.suffix && !selectedQuestion.directLinkEvp) {
      await deleteImage(`poll/${userId}/temp-${selectedQuestion.serialNo}${selectedQuestion.suffix}`);
    }

    if (!!selectedQuestion.image && !selectedQuestion.directLinkEvp) {
      await deleteImage(`poll/${userId}/${selectedQuestion.image}`);
    }


    //clear options
    let newQuestions = [];
    for (const q of questions) {
      if (q.serialNo !== selectedQuestion.serialNo) {
        newQuestions.push(q);
        continue;
      }

      const updatedQuestion = {...q, imgUrl: "", suffix: "", image: ""};
      if (selectedQuestion.questionType === QUESTION_TYPES.CI.name) {
        updatedQuestion.optionsMap = [];
        updatedQuestion.correctAnswers = [];
      }
      newQuestions.push(updatedQuestion);
    }

    setQuestions(newQuestions);
    setQues(newQuestions);
  };


  const clickDuplicate = async () => {
    const {userId} = getSession();

    if (!institutionId || !userId) {
      return;
    }

    //check if the current question have to copy image
    let file;
    if (selectedQuestion.image || selectedQuestion.suffix) {
      let url = !!pollKey
          ? `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`
          : `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${userId}`;
      url  = !!selectedQuestion.suffix
          ? `${url}/temp-${selectedQuestion.serialNo}${selectedQuestion.suffix}`
          : `${url}/${selectedQuestion.image}`;
      url = !!selectedQuestion.imgUrl ? selectedQuestion.imgUrl : url;

      file = await fetchImgFile(url);
      file.name = !!selectedQuestion.suffix
          ? `temp-${getFormattedCurrentTime()}${selectedQuestion.suffix}`
          : getFileNameFromImageName(selectedQuestion.image) + "-" +
          getFormattedCurrentTime() + getSuffixFromImageName(selectedQuestion.image);
      tempSaveImage(file);
    }


    const imageName = !!file ? file.name: "";
    const imageUrl = !!file ? URL.createObjectURL(file) : "";
    const newQuestions = duplicateQuestion(selectedQuestion, questions, imageName, imageUrl);
    setQuestions(newQuestions);
    changeCurrQueDisplay(serialNo + 1);
  }

  const clickDelete = () => {
    setQuestions(deleteQuestion(selectedQuestion, questions));
    changeCurrQueDisplay(serialNo === 1 ? 1 : serialNo - 1);
  }

  useEffect(() => {
    if(isSaving) {
      const savingTimer = setTimeout(() => {
        setSavingText("Saved"); 
      }, 4000);

      return () => clearTimeout(savingTimer);
    }
    if (!isSaving) {
      setSavingText("Saving"); 
    }
  }, [isSaving])

  const scrollToBottom =() => {
    const savingTimer = setTimeout(() => {
  
      const element = divRef.current;
      element.scrollTop = element.scrollHeight;
    }, 1);
    return () => clearTimeout(savingTimer);
  }


  return (
    <div
      className="no-padding create-question-body"
      style={{backgroundColor : "#FFFFFF", margin: "20px 0", padding: 30, height: "75vh", borderRadius: "9.039px", border: " 1.13px solid #858687"} }
    >
            <div>
              <h1 className="question-type-title" style={{display: "flex", gap: "11.3px", marginBottom: "23.27px", justifyContent: "space-between"}}>
                <div style={{display: "flex", gap: "11.3px", alignItems: "center"}}>
                <span tabIndex={0} role={TEXTBOX}>Question {selectedQuestion.serialNo}</span>
                <span className="CreateQuestionTypeContainer" style={{ color: "#42296E", fontSize: "15px"}}>
                <CreatePollDropDown questionType={questionType} isCreateOpen={questionTypeOpen} setIsCreateOpen={setQuestionTypeOpen}>
                  <CreatePollDropDown.Menu >
                  {Object.entries(questionTypeOptions.current).map((optionData, index) => {
                            return (
                              <CreatePollDropDown.Item ariaLabel={optionData[1].description} onClick={() => {handleQuestionTypeUpdate(optionData[1].description)}} >
                                <div key={index} style={{padding: "0.5vh 0px"}}>
                                    <QuestionTypeOption
                                        optionData={optionData[1]}
                                        handleQuestionTypeUpdate={()=> {}}
                                    />
                                </div>
                                </CreatePollDropDown.Item>
                            );
                        })}
                  </CreatePollDropDown.Menu>
                </CreatePollDropDown>
                </span>

                {/* {questionType === QUESTION_TYPES.CI.name &&
                    <span
                        style={{
                          marginLeft: "10px",
                          fontWeight: "400",
                          fontSize: "18px",
                          color: "#535353"
                        }}
                    >
                      (Click anywhere on the image to mark that region as an option.)
                    </span>
                } */}
                </div>
                {isSaving && <div style={{display: "flex", alignItems: "center"}}> <img src={require("../images/saving.gif")} alt="loading..." style={{height: "32px"}}/> <div style={{fontSize: "14px", fontFamily: "Geomanist", color: "#000"}}> {savingText} </div> </div>}
                {(!!selectedQuestion.imgUrl || !!selectedQuestion.directLinkEvp) && <div style={{ display: "flex"}}><YuJaButton type="delete" style={{height: "33px", padding: "8px 10px", borderRadius: "4.205px", border: "2px solid #BF0033", display: 'flex', alignItems: "center",  whiteSpace: 'nowrap'}} onClick={handleClose}> <div style={{display: "flex", gap: "8px", alignItems: "center"}}><Deleting style={{width: "24px", height: "24px"}}/>{"Discard " + (selectedQuestion.directLinkEvp ? "Video" : "Image")}</div></YuJaButton></div>}
              </h1>
            </div>
              <div style={{display: "flex",height: "90%", width: "100%"}}>
              <div style={{flex: "1", maxWidth: !!selectedQuestion.imgUrl ?"50%" : "100%"}}>
                {/* {questionType === QUESTION_TYPES.FITB.name &&
                  <BaseQuestionTitle
                    placeholder="Type question and for the word you wish to blank, click the Blank button. Below you can add the answer to each blank."
                    editable={false}
                  />
                } */}
                {questionType !== QUESTION_TYPES.FITB.name &&
                  <BaseQuestionTitle
                    handleTitleUpdate={handleTitleUpdate}
                    questionTitle={queTitle}
                    
                    placeholder={"Enter Question Title"}
                    editable={true}
                  />
                }
            {questionType !== QUESTION_TYPES.CI.name &&
                <div className="d-flex justify-content-center">
                  <UploadQuestionImage
                      selectedQuestion={selectedQuestion}
                      questions={questions}
                      setQuestions={setQuestions}
                      // currQueDisplay={currQueDisplay}
                      // institutionId={institutionId}
                      pollKey={pollKey}
                      questionBankId={questionBankId}
                  />
                </div>
            }

              <div className="margin-vertical-auto" style={{height: questionType === QUESTION_TYPES.FITB.name ? "100%": "auto"}}>
                {questionType === QUESTION_TYPES.MCSS.name &&
                    <MCQuestion
                    setQuestions={setQuestions}
                    questions={questions}
                    selectedQuestion={selectedQuestion}
                    pollType={pollType}
                    />
                }
                {questionType === QUESTION_TYPES.TF.name &&
                    <TFQuestion
                        setQuestions={setQuestions}
                        questions={questions}
                        selectedQuestion={selectedQuestion}
                        pollType={pollType}
                    />
                }
                {questionType === QUESTION_TYPES.FITB.name &&
                    <div className="FITB-main-container">
                    <FITBQuestion
                        setQuestions={setQuestions}
                        questions={questions}
                        selectedQuestion={selectedQuestion}
                        pollType={pollType}
                    />
                    </div> 
                }
                {/* {questionType === QUESTION_TYPES.SA.name &&
                    <SAQuestion
                        setQuestions={setQuestions}
                        questions={questions}
                        selectedQuestion={selectedQuestion}
                        pollType={pollType}
                    />
                } */}
                {questionType === QUESTION_TYPES.CI.name &&
                    <CIQuestion
                        setQuestions={setQuestions}
                        questions={questions}
                        selectedQuestion={selectedQuestion}
                        currQueDisplay={currQueDisplay}
                        pollType={pollType}
                        pollKey={pollKey}
                    />
                }
                {questionType === QUESTION_TYPES.MH.name &&
                   <div className="droppable-multiple-choice-question-options" style={{marginLeft: "-30px"}}  ref={divRef}> 
                    <MHQuestion
                    setQuestions={setQuestions}
                    questions={questions}
                    selectedQuestion={selectedQuestion}
                    pollType={pollType}
                    scrollToBottom={scrollToBottom}
                    />
                    </div> 
                }
                </div>
                </div>
          {!!selectedQuestion.imgUrl && !selectedQuestion.directLinkEvp && (
            <div className="image-preview-container" style={{flex: "1", justifyContent: "end"}}>
              {selectedQuestion.questionType === QUESTION_TYPES.CI.name
                ? <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "end", gap: "10px"}}> 
                  <span
                        style={{
                          marginLeft: "10px",
                          fontWeight: "400",
                          fontSize: "18px",
                          color: "#535353",
                          width: "90%",
                          justifyContent: "center",
                          display: "flex"
                        }}
                        tabIndex={0}
                        role={TEXTBOX}
                    >
                      {CI_QUESTION_TITLE}
                    </span>
                  <ClickableImage
                  questions={questions}
                  setQuestions={setQuestions}
                  selectedQuestion={selectedQuestion}
                /> </div> 
                : <img
                  className="preview"
                  src={`${selectedQuestion.imgUrl}`}
                  alt={`Q${selectedQuestion.serialNo} preview`}
                />
              }
            </div>
          )}
          {selectedQuestion.directLinkEvp && (
            <div className="image-preview-container" style={{flex: "1", justifyContent: "end", alignItems: "center"}}>
              <iframe allowFullScreen
                style={{width: "90%", height: "90%"}}
                src={`${selectedQuestion.directLinkEvp}`}
                alt={`Q${selectedQuestion.serialNo} preview`}
              />
            </div>
          )}
              </div>
            {/* <div style={{display: "flex", gap: 20}}>
              <div style={{flex: 1}}/>
              <YuJaButton onClick={clickDuplicate} title={"Duplicate Question"}>Duplicate</YuJaButton>
              <YuJaButton type="delete"  onClick={clickDelete} title={"Delete Question"}>Delete</YuJaButton>
            </div> */}


    </div>
  );
}
