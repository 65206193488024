import { ContentState, Editor, EditorState, Modifier } from "draft-js";
import "draft-js/dist/Draft.css";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import NormalButton from "../../public/buttons/NormalButton";
import { BLANK_REGEX, FITB_INPUT, FITB_QUESTION_LENGTH, FITB_QUESTION_TITLE, QUESTION_LENGTH, TEXTBOX } from "../../utils/constants";
import { customSlice, notifyError } from "../../utils/helpers";
import {
  updateCorrectAnswers,
  updateTitleInput,
} from "../../utils/questionUpdate";
import { FITB_QUESTION_CHARACTER_LIMIT_EXCEEDED, QUESTION_CHARACTER_LIMIT_EXCEEDED } from "../../utils/toast-message-constants";
import FITBRow from "./FITBRow";
import FITBTextArea from "./FITBTextArea";
import "./draftEditorStyle.css";
import YuJaButton from "../../components/standardization/YuJaButton";

export default function FITBQuestionTitle({
  questions,
  setQuestions,
  selectedQuestion,
  editorState,
  setEditorState,
  compositeDecorator,
  previewModalShow, 
  setPreviewModalShow
}) {
  const { queTitle='', serialNo, correctAnswers: blanks } = selectedQuestion;
  const blankVariableRegex = /Blank [\d]+/;
  const indexToChange = serialNo - 1;
  const [hasFocus, setHasFocus] = useState(false);
  const [keyCounter, setKeyCounter] = useState(1);

  const setTitleAndCorrectAns = (newTitle) => {
    let newQuestions = updateTitleInput(newTitle, indexToChange, questions);
    newQuestions = updateCorrectAnswers(
      arrangeBlanks(newTitle.match(BLANK_REGEX)),
      indexToChange,
      newQuestions
    );
    setQuestions(newQuestions);
  };

  const arrangeBlanks = (blankKeys) => {
    let newBlanks = {};
    let maxKeyCounter = 0;

    if (blankKeys) {
      blankKeys.forEach((key) => {
        key = key.replaceAll(")", "").replaceAll("(", "");

        if (blanks[key]) {
          newBlanks[key] = blanks[key];
        } else {
          newBlanks[key] = [""];
        }

        if (blankVariableRegex.test(key)) {
          const keyNumbers = key.match(/\d+/);

          if (keyNumbers.length === 1) {
            const keyNumber = Number(keyNumbers[0]);
            if (maxKeyCounter < keyNumber) {
              maxKeyCounter = keyNumber;
            }
          } else {
            throw new Error(
              "Blank key cannot include more than one number substring."
            );
          }
        }
      });
    }
    setKeyCounter(maxKeyCounter + 1);
    return newBlanks;
  };

  const insertText = (text, editorValue) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      customSlice(text, QUESTION_LENGTH - currentContent.getPlainText('').replace(BLANK_REGEX, '').length)
    );
    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  const handleAddBlank = () => {
    const currentContent = editorState.getCurrentContent();
    let newBlankNumber = 1;
    const currentText = currentContent.getPlainText();

    if (currentText !== "") {
      const currentBlanks = currentText.match(BLANK_REGEX);

      if (currentBlanks) {
        const blankNumbers = currentBlanks.map(match => {
          const matches = match.match(/\d+/);
          return matches ? parseInt(matches[0], 10) : null;
        }).filter(number => number !== null);

        while (blankNumbers.includes(newBlankNumber)) {
          newBlankNumber++;
        }
      }
    }
    const textToInsert = "(Blank " + String(newBlankNumber) + ")";
    const currentContentLength = currentContent.getPlainText('').replace(BLANK_REGEX, '').length;
    if (currentContentLength <= FITB_QUESTION_LENGTH){
      const newEditorState = insertText(textToInsert, editorState);
      setEditorState(newEditorState);
      setTitleAndCorrectAns(
        newEditorState.getCurrentContent().getPlainText()
      );
    } else {
      notifyError(FITB_QUESTION_CHARACTER_LIMIT_EXCEEDED);
    }
  };

  const handlePreview = () => {
    setPreviewModalShow(true); 
  }

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      // console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }
    return length;
  }

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').replace(BLANK_REGEX, '').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > FITB_QUESTION_LENGTH - 1) {
      // notifyError(FITB_QUESTION_CHARACTER_LIMIT_EXCEEDED);
      // console.log('you can type max 150 characters');
      return 'handled';
    }
  }

  const handlePastedText = (pastedText, html) => {
    const selectedText = editorState.getCurrentContent().getPlainText('');
    const rawText = (selectedText ? selectedText : "") + pastedText;

    if (rawText.replace(BLANK_REGEX, '').length > FITB_QUESTION_LENGTH) {
      // notifyError(FITB_QUESTION_CHARACTER_LIMIT_EXCEEDED);
    }

    const newEditorState = insertText(pastedText, editorState);
    changeTitle(newEditorState);
    // setEditorState(
    //     EditorState.createWithContent(
    //         ContentState.createFromText(rawText.slice(0, QUESTION_LENGTH)),
    //         compositeDecorator
    //     )
    // );
    // console.log(editorState);

    // Important! Tell draft-js you already handled the pasted text
    return 'handled';
  }

  const changeTitle = (editorSt) => {
    if (editorSt) {
      setEditorState(editorSt);
      setTitleAndCorrectAns(
          editorSt.getCurrentContent().getPlainText()
      );
    } else {
      setEditorState(editorState);
      setTitleAndCorrectAns(
          editorState.getCurrentContent().getPlainText()
      );
    }
  }

  useEffect(() => {
    changeTitle();
  }, []);

  const addBlankStyle = {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    width: "106.213px",
    height: "51.95px",
    padding: "7.5px",
    float: "right",
    margin: "0px"
  };

  useEffect(() => {
    // console.log(editorState);
    if (hasFocus) { // Show cursor in the end of the title 
      const emptyState = EditorState.createWithContent(ContentState.createFromText(""), compositeDecorator);
      const newEditorState = insertText(queTitle, emptyState); // insert question title to editor
      setEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createWithContent(ContentState.createFromText(queTitle), compositeDecorator));
    }
  }, [serialNo]);

  return (
    <>
      <FITBRow text="Type question here." handleAddBlank={handleAddBlank} handlePreview={handlePreview} showButtons={true}>
        <Col className="fitb-input-col margin-vertical-auto no-padding" style={{fontSize: "18px", color: "#535353", fontWeight: "400", width: "100%", padding: "0px"}}>
          <div style={hasFocus ? {borderRadius: "12px", border : "3px solid #0277AD", margin : "10px 0px 0px 10px", padding: 13} : { borderRadius: "12px", border: "1.13px solid #535353", background: "var(--1-white, #FFF)", margin : "10px 0px 0px 10px", padding: 15}}>
            <Editor
              ariaLabel={FITB_INPUT}
              role={TEXTBOX}
              editorState={editorState}
              onChange={changeTitle}
              placeholder= {FITB_QUESTION_TITLE}
              spellCheck={true}
              handleBeforeInput={handleBeforeInput}
              handlePastedText={handlePastedText}
              onFocus={() => setHasFocus(true)}
              onBlur={() => setHasFocus(false)}
            />
          </div>
        </Col>
      </FITBRow>
    </>
  );
}
