import "bootstrap/dist/css/bootstrap.css";
import React, {useContext, useEffect, useRef, useState} from "react";
import reactStringReplace from "react-string-replace";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import { ReactComponent as NextEnabled } from "../../images/next_enabled.svg";
import { ReactComponent as PreviousEnabled } from "../../images/previous_enabled.svg";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import NormalBox from "../../public/boxes/NormalBox";
import {
  CI_COORDINATE_SIZE,
  DEFAULT_ENTRIES_PER_PARTICIPANT,
  DEFAULT_SHOWN_ENTRIES,
  FIRST_WC_PLACEHOLDER,
  FITB_ANSWER_LENGTH, FIXED_ANSWER,
  MCSSoptLower,
  MCSSoptUpper,
  OTHER_WC_PLACEHOLDER,
  POLL_TEXT,
  POLL_TYPE, QUESTION_TYPES,
  SUBMIT_TEXT,
  SUB_QUESTION_TYPE, SURVEY_ALL_PAGE_STATE, SURVEY_TEXT, WC_ANSWER_LENGTH, PREMISE, RESPONSE, THUMBS_UP, THUMBS_DOWN, BUTTON, POLL_TITLE_LABEL, QUESTION_TITLE, TEXTBOX, NEXT_QUES, PREV_QUES, POLL_SHARE_MODE
} from "../../utils/constants";
import {  checkBlankAnswered, checkIncomplete, checkUnanswered, fetchImg, handleUserAns, parseWCId, newPageLog,  } from "../../utils/helpers";
import {
  S3_PRIVATE_BUCKET_API_URL,
  S3_PRIVATE_BUCKET_NAME
} from "../../utils/properties";
import { decodeCIParam, encodeCIParam, getOption4CI } from "../../utils/questionUtils";
import ClassResultSurvey from "./ClassResultSurvey";
import CountdownTimerAll from "./CountdownTimerAll";
import deleteIcon from "./images/redDelete.svg";
import "./styles.css";
import styles from "./styles.module.css";
import ArrowIcon from "../../images/arrow-right-big.svg"
import YuJaButton from "../../components/standardization/YuJaButton";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";
import NetworkStatus from "./NetworkStatus";
import {YuJaGlobalState} from "../../utils/LoadingContext";
import { ReactComponent as NextIcon } from "../PublishedPollPage/images/NEXT_ARROW_ICON.svg";
import { ReactComponent as BackIcon } from "../PublishedPollPage/images/BACK_ARROW_ICON.svg";

export default React.memo(PollReadyAll, (prevProps, nextProps) => {
  return (
    prevProps.pollInfo === nextProps.pollInfo &&
    prevProps.currentQuestion === nextProps.currentQuestion
  );
});

function PollReadyAll({
                        pollInfo,
                        currentPoll = {},
                        pollLiveQuestionIdx,
                        setPollLiveQuestionIdx,
                        handleUserCompletePoll,
                        handlePollComplete,
                        stoppedAll,
                        state,
                        institutionId,
                        changeCurPoll,
                        websocketState
}) {
  const isMobile = useMobileAccess(480);
  const isNarrow = useMobileAccess(1200);
  const { getAudienceResponseByUser, updateReaction, handleSubmitMultiResponse } = ResponseApiCalls();
  const [pageState, setPageState] = useState(SURVEY_ALL_PAGE_STATE.NOT_READY);
  const [surveyClassResultQuestion, setSurveyClassResultQuestion] = useState({});
  const [questionImageURL, setQuestionImageURL] = useState("");

  const [isDropdownOpen, setIsDropdownOpen] = useState(null);


  // const [currQuestionIndex, setCurrQuestionIndex] = useState(pollLiveQuestionNo > 0 ? Number(pollLiveQuestionNo) - 1 : 0);
  const { pollKey, uniqueCode, userName, pollTitle, pollType, recordAttempt, lmsAttempt, attemptsCount } = pollInfo;
  const { questions, isTimed = true, pollEndTime, rawQueResponses=[] } = currentPoll;
  const curIdx = pollLiveQuestionIdx >= questions.length ? questions.length - 1 : pollLiveQuestionIdx;
  const [currQueTitle, setCurrQueTitle] = useState(questions[curIdx].queTitle);
  const [currCorrectAnswers, setCurrCorrectAnswers] = useState(questions[curIdx].correctAnswers);
  // const [currOptionsMap, setCurrOptionsMap] = useState(questions[0].optionsMap);
  const [currQuestionType, setCurrQuestionType] = useState(questions[curIdx].questionType);
  const [currWeightage, setCurrWeightage] = useState(questions[curIdx].weightage);
  const [currSerialNo, setCurrSerialNo] = useState(questions[curIdx].serialNo);
  const [currEntries, setCurrEntries] = useState(questions[curIdx].entries);
  const [currSuffix, setCurrSuffix] = useState(questions[curIdx].suffix);
  const [image, setImage] = useState(questions[curIdx].image);
  const [, getSession] = useLocalStorageNew("session", {});
  const {isOnline} = useContext(YuJaGlobalState);
  const session = getSession();
  const [isLoaded, setIsLoaded] = useState(false);
  const [queResponses, setQueResponses] = useState(rawQueResponses);
  const [alertShow, setAlertShow] = useState(false);
  const imageRef = useRef(null);
  const wcInputValRef = useRef(null);
  const [alertContent, setAlertContent] = useState("");
  const [firstSkipped, setFirstSkipped] = useState("");
  const [WCElements, setWCElements] = useState([]);
  const [shownEntries, setShownEntries] = useState(Math.min(currEntries, DEFAULT_SHOWN_ENTRIES)); // (isNarrow || currEntries == 1 ? 1 : 2);
  const [thumbup, setThumbUp] = useState(false);
  const [thumbdown, setThumbDown] = useState(false);
  const [, getGuestSession] = useLocalStorageNew("guestSession", {});
  const [hoveringThumbUp, setHoveringThumbUp] = useState(false);
  const [hoveringThumbDown, setHoveringThumbDown] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  // const [setRc, getRc, delRc] = useSessionStorage(`${pollKey}#${uniqueCode}#${currSerialNo}#RC`, currentIsCorrect === null ? "" : currentIsCorrect);

  const [currentSelectedOption, setCurrentSelectedOption] = useState(() => {
    if (pollLiveQuestionIdx >= questions.length) {
      return null;
    }
    const curQuestionType = questions[pollLiveQuestionIdx].questionType;
    const selectedOption = queResponses.length > pollLiveQuestionIdx ? queResponses[pollLiveQuestionIdx]?.selectedOption : null;

    //FITB
    if (curQuestionType === QUESTION_TYPES.FITB.name && checkBlankAnswered(selectedOption)) {
      const ans = {};
      for (const key of Object.keys(questions[pollLiveQuestionIdx].correctAnswers)) {
        ans[key] = "";
      }
      return ans;
    }
    //WC
    if (curQuestionType === QUESTION_TYPES.WC.name && checkBlankAnswered(selectedOption)) {
      const ans = {};
      for (let i = 0; i < questions[pollLiveQuestionIdx].entries; i++) {
          ans[i] = "";
      }
      return ans;
    }
    //MH
    if (currQuestionType === QUESTION_TYPES.MH.name && checkBlankAnswered(selectedOption)) {
      const ans = {};
      for (let i = 0; i < questions[pollLiveQuestionIdx].correctAnswers.length; i++) {
        ans[i] = "";
      }
      return ans;
    }
    //Survey MCSS
    if (pollType === POLL_TYPE.SURVEY && curQuestionType === QUESTION_TYPES.MCSS.name && checkUnanswered(pollType, curQuestionType, selectedOption)) {
      return [];
    }
    //MCSS or SA or TF or CI
    if (checkUnanswered(pollType, curQuestionType, selectedOption)) {
      return FIXED_ANSWER.UNANSWERED;
    }
    return selectedOption;
  });


  // BEGIN VARIABLE INIT FOR QUESTION TYPES
  let MCSSelements = [];
  let newOptionsMap = pollLiveQuestionIdx < questions.length && questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.MCSS.name ?
    questions[pollLiveQuestionIdx].optionsMap : {};
  const MCSSnum = Object.keys(newOptionsMap).length;

  let TFelements = ["TF0", "TF1"];
  const TFopt = ["True", "False"];

  let FITBElements = [];
  let idx = -1;

  if (pollLiveQuestionIdx < questions.length) {
    if (questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.MCSS.name) {
      for (let i = 0; i < MCSSnum; i++) {
        MCSSelements.push(QUESTION_TYPES.MCSS.name + i);
      }
    } else if (questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.FITB.name && currCorrectAnswers) {
      for (const key of Object.keys(currCorrectAnswers)) {
        FITBElements.push(key);
      }
    }
  }


  const onBlurWC = (i) => {
    document.getElementById(`WC-IN-${i}`).style.border = "1px solid #333333";
  };
  // END VARIBALE INIT

  // useEffect(() => {
  //   async function getResponses() {
  //     let questionsCompleted = await getAudienceResponseByUser(userName);
  //
  //     if (questionsCompleted.data.length - 1 < questions.length) {
  //       setPollLiveQuestionNo(questionsCompleted.data.length);
  //     }
  //   }
  //   getResponses();
  // }, []);

  useEffect(() => {
    newPageLog("PollReadyAll.js");
    if(isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }
  }, []);

  if (document.getElementById("root") !== null) {
    document.getElementById("root").style.backgroundImage = "none";
  }
  // END DOCUMENT FORMATTING


  useEffect(() => {
    if (!isOnline) {
      return;
    }

    async function fetchData() {
      if(questions[curIdx].directLinkEvp) {
        setQuestionImageURL(questions[curIdx].directLinkEvp);
        setIsVideo(true);
      }
      else if ((!!image || !!currSuffix) && institutionId && pollKey && currSerialNo) {
        let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;

        url = !!image ? `${url}/${image}` : `${url}/temp-${currSerialNo}${currSuffix}`;
        let img = await fetchImg(url);
        setQuestionImageURL(img ? img : "");
      } else {
        setQuestionImageURL("");
      }
    }
    fetchData();
  }, [institutionId, pollKey, currSerialNo, currSuffix, isOnline]);

  useEffect(() => {
    if (pollLiveQuestionIdx >= questions.length) {
      if (pollType === POLL_TYPE.SURVEY) {
        setSurveyClassResultQuestion(questions[questions.length - 1]);
        setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
        return;
      } else {
        handleUserCompletePoll();
        return;
      }
    }

    const curQueResponse = queResponses[pollLiveQuestionIdx];
    const curQue = questions[pollLiveQuestionIdx];
    //(survey and skipped the question / survey and answered the question)  go to class result page. otherwise, question page.
    if (pollType === POLL_TYPE.SURVEY && (curQueResponse?.skippedQuestion || !checkIncomplete(pollType, curQue.questionType, curQueResponse?.selectedOption))) {
      setSurveyClassResultQuestion(questions[pollLiveQuestionIdx]);
      setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
      return;
    }
    // const guestSession = JSON.parse(JSON.stringify(getGuestSession()));
    // const questionNo = guestSession[`$${uniqueCode}`]?.[SURVEY_ALL_CURRENT_QUESTION_NO_KEY];
    // if (!isNaN(questionNo)) {
    //   setSurveyClassResultQuestion(questions[questionNo]);
    //   setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
    //   return;
    // }


    setCurrQueTitle(curQue.queTitle);
    setCurrCorrectAnswers(curQue.correctAnswers);
    // setCurrOptionsMap(questions[currQuestionIndex].optionsMap);
    setCurrQuestionType(curQue.questionType);
    setCurrWeightage(curQue.weightage);
    setCurrSerialNo(curQue.serialNo);
    setCurrEntries(curQue.entries ? curQue.entries : DEFAULT_ENTRIES_PER_PARTICIPANT);
    setShownEntries(Math.min(curQue.entries, DEFAULT_SHOWN_ENTRIES));
    setCurrSuffix(curQue.suffix);
    setIsLoaded(false);
    setImage(curQue.image);

    const selectedOption = !!curQueResponse ? curQueResponse.selectedOption : null;
    //FITB
    if (curQue.questionType === QUESTION_TYPES.FITB.name) {
      if (checkBlankAnswered(selectedOption)) {
        const ans = {};
        for (const key of Object.keys(curQue.correctAnswers)) {
          ans[key] = "";
        }
        setCurrentSelectedOption(ans);
      } else {
        setCurrentSelectedOption(selectedOption);
      }
    }
    //WC
    if (curQue.questionType === QUESTION_TYPES.WC.name && checkBlankAnswered(selectedOption)) {
      const ans = {};
      for (let i = 0; i < curQue.entries; i++) {
        ans[i] = "";
      }
      setCurrentSelectedOption(ans);
    }
    //Survey and MCSS
    else if (pollType === POLL_TYPE.SURVEY && curQue.questionType === QUESTION_TYPES.MCSS.name && checkUnanswered(pollType, curQue.questionType, selectedOption)) {
      setCurrentSelectedOption([]);
    }
    //MH
    else if (curQue.questionType === QUESTION_TYPES.MH.name  && checkBlankAnswered(selectedOption)) {
      const ans = {};
      for (let i = 0; i < questions[pollLiveQuestionIdx].correctAnswers.length; i++) {
        ans[i] = "";
      }
      setCurrentSelectedOption(ans);
    }
    //MCSS or SA or TF or CI
    else if (checkUnanswered(pollType, curQue.questionType, selectedOption)) {
      setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
    } else {
      setCurrentSelectedOption(selectedOption);
    }
    setPageState(SURVEY_ALL_PAGE_STATE.QUESTION);
    console.log(currentSelectedOption)
  }, [pollLiveQuestionIdx, queResponses]);


  const handleType = (e) => {
    e.stopPropagation();

    if (currQuestionType === QUESTION_TYPES.SA.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("SA-IN").value = "";
        setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurrentSelectedOption(ans);
      }
    } else if (currQuestionType === QUESTION_TYPES.FITB.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      }
      const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : { ...currentSelectedOption };
      copy[e.target.getAttribute("id")] = e.target.value.toString();
      setCurrentSelectedOption(copy);
    } else if (currQuestionType === QUESTION_TYPES.WC.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      } else {
        if (parseWCId(e.target.getAttribute("id")) == shownEntries-1 && shownEntries < currEntries && wcInputValRef.current.length < e.target.value.toString().length) {
          // const diff = currEntries - shownEntries;
          // if (diff === 1) {
            setShownEntries(shownEntries+1);
          // } else if (diff > 1) {
            // if (isNarrow) setShownEntries(shownEntries+1);
            // else setShownEntries(shownEntries+2-(shownEntries % 2));
          // }
        }
      }
      const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : { ...currentSelectedOption };
      copy[parseWCId(e.target.getAttribute("id"))] = e.target.value.toString();
      // console.log("copy", copy);
      setCurrentSelectedOption(copy);
    }
  };

  const handleKeyDown = (e) => {
    wcInputValRef.current = e.target.value;
  };

  const handleDelete = (i) => {
    const copy = { ...currentSelectedOption };
    copy[i] = "";
    for (let j = i; j < currEntries-1; ++j) {
      copy[j] = copy[j+1];
    }
    copy[currEntries-1] = "";
    setCurrentSelectedOption(copy);
    if (shownEntries > 1) {
      setShownEntries(shownEntries-1);
    }
  };

  const handleChooseOption = (e) => {
    if (pollType === POLL_TYPE.SURVEY && currQuestionType === QUESTION_TYPES.MCSS.name) {
      const copy = [...currentSelectedOption];
      if (e.target.checked) {
        copy.push(e.target.value);
      } else {
        copy.splice(copy.indexOf(e.target.value), 1);
      }
      setCurrentSelectedOption(copy);
    } else {
      setCurrentSelectedOption(e.target.value);
    }
  };

  const handleClickImage = (e) => {
    if (!isLoaded) {
      return;
    }
    const {optionsMap} = questions[pollLiveQuestionIdx];

    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    const encodedX = encodeCIParam(x, imageRef.current.width);
    const encodedY = encodeCIParam(y, imageRef.current.height);
    const option = getOption4CI(encodedX, encodedY, optionsMap);
    setCurrentSelectedOption(option === FIXED_ANSWER.UNANSWERED
        ? FIXED_ANSWER.UNANSWERED
        : {x: encodedX, y: encodedY, selected: option});
  }

  const checkBlanks = (opts) => {
    for (const key of Object.keys(currCorrectAnswers)) {
      if (!!opts && !!opts[key] && opts[key] !== "") {
        return false;
      }
    }
    return true;
  };

  const updateFITB = async () => {
    if (!currentSelectedOption) {
      await setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      return;
    }
    const newVal = checkBlanks(currentSelectedOption) ? FIXED_ANSWER.UNANSWERED : currentSelectedOption;
    await setCurrentSelectedOption(newVal);
    return newVal;
  };

  const updateWC = async () => {
    if (!currentSelectedOption) {
      await setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      return;
    }
    const newVal = checkBlankAnswered(currentSelectedOption) ? FIXED_ANSWER.UNANSWERED : currentSelectedOption;
    await setCurrentSelectedOption(newVal);
    return newVal;
  };

  const updateMH = async() => {
    if (!currentSelectedOption) {
      await setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      return;
    }
    const newVal = checkBlankAnswered(currentSelectedOption) ? FIXED_ANSWER.UNANSWERED : currentSelectedOption;
    await setCurrentSelectedOption(newVal);
    return newVal;
  }

  useEffect(() => {
    if (currQuestionType === QUESTION_TYPES.WC.name) {
      let tempWCElements = [];
      for (let i = 0; i < shownEntries; i++) {
        tempWCElements.push(`WC-IN-${i}`);
      }
      setWCElements(tempWCElements);
    }
  }, [shownEntries]);

  // const timeUp = async () => {
  //   console.log("Time Is Up");
  //   handlePollComplete();
  //   // delRc();
  // };

  const handleSub = async (action) => {
    let copy = currentSelectedOption;
    if (currQuestionType === QUESTION_TYPES.FITB.name) {
      copy = await updateFITB();
    } else if (currQuestionType === QUESTION_TYPES.WC.name) {
      copy = await updateWC();
    } else if (pollType === POLL_TYPE.SURVEY && currQuestionType === QUESTION_TYPES.MCSS.name &&
        (!currentSelectedOption || currentSelectedOption.length === 0)) {
      copy = [FIXED_ANSWER.UNANSWERED];
    }
    if (copy !== [FIXED_ANSWER.UNANSWERED] && copy !== FIXED_ANSWER.UNANSWERED && (currQuestionType === QUESTION_TYPES.FITB.name || currQuestionType === QUESTION_TYPES.SA.name)) {
      const trimmedDictionary = {};
      for (let key in copy) {
           trimmedDictionary[key] = copy[key].trim();
      }
      copy  = trimmedDictionary;
    }

    //build cur question response
    const isCorr = await handleUserAns(questions[pollLiveQuestionIdx], copy);
    let skippedQuestion = checkIncomplete(pollType, questions[pollLiveQuestionIdx].questionType, copy);
    const answersCopy = JSON.parse(JSON.stringify(queResponses));
    answersCopy[pollLiveQuestionIdx] = {
      serialNo: questions[pollLiveQuestionIdx].serialNo,
      questionType: questions[pollLiveQuestionIdx].questionType,
      queTitle: questions[pollLiveQuestionIdx].queTitle,
      optionsMap: questions[pollLiveQuestionIdx].optionsMap,
      isCorrect: isCorr,
      attemptsCount: attemptsCount,
      selectedOption: copy,
      skippedQuestion: skippedQuestion,
      correctAnswers: questions[pollLiveQuestionIdx].correctAnswers,
    };

    //set the skippedQuestion flag of previous question false if previous question selected option is UNANSWERED
    const answerMap = [];
    if (pollType === POLL_TYPE.SURVEY && action === SUB_QUESTION_TYPE.PREVIOUS) {
      const previousAnswerCopy = answersCopy[pollLiveQuestionIdx - 1];
      previousAnswerCopy.skippedQuestion = false;
      answerMap.push(previousAnswerCopy);
    }
    if (pollType === POLL_TYPE.SURVEY && action === SUB_QUESTION_TYPE.UNANSWERED) {
      const previousAnswerCopy = answersCopy[firstSkipped - 1];
      previousAnswerCopy.skippedQuestion = false;
      if (firstSkipped !== questions[pollLiveQuestionIdx].serialNo) {
        answerMap.push(previousAnswerCopy);
      }
    }
    answerMap.push(answersCopy[pollLiveQuestionIdx]);

    //get curQuestion no
    let curQuestionNo;
    if (action === SUB_QUESTION_TYPE.NEXT && pollType === POLL_TYPE.SURVEY) {
      curQuestionNo = pollLiveQuestionIdx + 1;
    } else if(action === SUB_QUESTION_TYPE.NEXT) { //if it's survey then will not change the curQuestionNo
      curQuestionNo = pollLiveQuestionIdx + 2;
    } else if (action === SUB_QUESTION_TYPE.PREVIOUS) {
      curQuestionNo = pollLiveQuestionIdx;
    } else if (action === SUB_QUESTION_TYPE.UNANSWERED) {
      curQuestionNo = firstSkipped;
    } else {
      curQuestionNo = questions.length + 1;
    }
    await handleSubmitResponse(curQuestionNo, answerMap);
    setQueResponses(answersCopy);
    const currentPollCopy = JSON.parse(JSON.stringify(currentPoll));
    currentPollCopy.rawQueResponses = answersCopy;
    changeCurPoll(currentPollCopy);
  };

  const previous = async () => {
    if (pollLiveQuestionIdx > 0 ) {
      await handleSub(SUB_QUESTION_TYPE.PREVIOUS);
      setPollLiveQuestionIdx(pollLiveQuestionIdx - 1);
    }
  };


  const next = async () => {
    const skippedQueArr = [];
    //check all the questions if it's unanswered when cur ques is the last que.
    if (pollLiveQuestionIdx >= questions.length - 1) {
      for (let i = 0; i < questions.length - 1; i++) {
        const r = queResponses[i];
        if (!r || !Object.entries(r).length || !!r.skippedQuestion) {
          skippedQueArr.push(questions[i].serialNo);
        }
      }
      if (checkIncomplete(pollType, currQuestionType, currentSelectedOption)) {
        skippedQueArr.push(questions[questions.length - 1].serialNo);
      }
      if (skippedQueArr.length && pollType !== POLL_TYPE.SURVEY) {
        setFirstSkipped(skippedQueArr[0]);
        setAlertContent(`You have ${skippedQueArr.length} unanswered questions. Are you sure you want to submit?`);
        setAlertShow(true);
        return;
      }
    }

    await handleSub(SUB_QUESTION_TYPE.NEXT);
    //survey
    if (pollType === POLL_TYPE.SURVEY) {
      await setSurveyClassResultQuestion(questions[pollLiveQuestionIdx]);
      setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
    } else {
      setPollLiveQuestionIdx(pollLiveQuestionIdx + 1);
    }
  }


  const handleReaction = async (reaction) => {
    if (reaction == 1) {
      if(thumbdown == true) {
        setThumbDown(false);
      }
      setThumbUp(true);

    }
    else {
      if(thumbup == true) {
        setThumbUp(false);
      }
      setThumbDown(true);
    }
    // let sessionUserName = getSession().userId ? getSession().userId : getGuestSession()["$"+ uniqueCode]?.GuestId;
    // const res = await setStudentReaction(pollKey, uniqueCode, sessionUserName , reaction, currSerialNo);
    let reactions = {};
    reactions[currSerialNo] = [reaction];
    let attemptReactions = {};
    attemptReactions[attemptsCount] = reactions;
    await updateReaction(pollKey, uniqueCode, attemptReactions, attemptsCount, null);
    console.log("updated");
  }

  useEffect(() => {
    if(stoppedAll) {
      timeUp();
    }
  }, [stoppedAll]);

  useEffect(async () => {
    if (!isOnline) {
      setThumbUp(false);
      setThumbDown(false);
      return;
    }

    let res = await getAudienceResponseByUser(userName, uniqueCode);
    if(!res.hasOwnProperty("data")) {
      res.data = [];
    }
    // let sessionUserName = getSession().userId ? getSession().userId : getGuestSession()["$"+ uniqueCode]?.GuestId;
    let currentPollRes = res.data;
    let isSet = false;
    console.log(currentPollRes);
    for (const tempResult of currentPollRes) {
    if (tempResult.reactionMap && tempResult.reactionMap[attemptsCount] && tempResult.reactionMap[attemptsCount][currSerialNo]) {
      if (tempResult.reactionMap[attemptsCount][currSerialNo][0] == "1") {
        setThumbUp(true);
        setThumbDown(false);
        isSet= true;
        // await setStudentReaction(pollKey, uniqueCode, sessionUserName , "1", currSerialNo);
      }
      else if (tempResult.reactionMap[attemptsCount][currSerialNo][0] == "2") {
        setThumbDown(true);
        setThumbUp(false);
        isSet= true;
        // await setStudentReaction(pollKey, uniqueCode, sessionUserName , "2", currSerialNo);
      }
    }
    else if (tempResult.reaction && tempResult.reaction[currSerialNo]) {
      if (tempResult.reaction[currSerialNo][0] == "1") {
        setThumbUp(true);
        setThumbDown(false);
        isSet= true;
      } else if (tempResult.reaction[currSerialNo][0] == "2") { 
        setThumbDown(true);
        setThumbUp(false);
        isSet= true;
      }
    } 
    }

    if (!isSet) {
        setThumbUp(false);
        setThumbDown(false);
    }
  }, [currSerialNo, isOnline]);


  const timeUp = async () => {
    let copy = currentSelectedOption;
    if (currQuestionType === QUESTION_TYPES.FITB.name) {
      copy = await updateFITB();
    } else if (currQuestionType === QUESTION_TYPES.WC.name) {
      copy = await updateWC();
    } else if (currQuestionType === QUESTION_TYPES.MH.name) {
      copy = await updateMH();
    } else if (pollType === POLL_TYPE.SURVEY && currQuestionType === QUESTION_TYPES.MCSS.name &&
        (!currentSelectedOption || currentSelectedOption.length === 0)) {
      copy = [FIXED_ANSWER.UNANSWERED];
    }

    if (pollLiveQuestionIdx < questions.length) {
      const answersMap = [];
      //current question
      const curQue = questions[pollLiveQuestionIdx];
      const isCorr = await handleUserAns(curQue, currentSelectedOption);
      answersMap.push({
        serialNo: curQue.serialNo,
        questionType: curQue.questionType,
        queTitle: curQue.queTitle,
        optionsMap: curQue.optionsMap,
        isCorrect: isCorr,
        // attemptsCount: attemptsCount,
        selectedOption: copy,
        skippedQuestion: false,
        correctAnswers: curQue.correctAnswers,
      });

      //unanswered questions
      // for (let i = pollLiveQuestionIdx + 1; i < questions.length; i++) {
      //   answersMap.push({
      //     serialNo: questions[i].serialNo,
      //     questionType: questions[i].questionType,
      //     queTitle: questions[i].queTitle,
      //     optionsMap: questions[i].optionsMap,
      //     isCorrect: false,
      //     attemptsCount: attemptsCount,
      //     selectedOption: FIXED_ANSWER.UNANSWERED,
      //     skippedQuestion: false,
      //     correctAnswers: questions[i].correctAnswers,
      //   });
      // }
      await handleSubmitResponse(questions.length + 1, answersMap);
    }

    handlePollComplete();
  }

  const handleSubmitResponse = (curQuestionNo, answersMap) => {
    handleSubmitMultiResponse(pollInfo, curQuestionNo, questions, answersMap);

  };

  const onLoad = () => {
    setIsLoaded(true);
  }

  const alertConfig = {
    title: `${SUBMIT_TEXT} ${pollType === POLL_TYPE.GRADED_POLL ? POLL_TEXT : SURVEY_TEXT}`,
    okText: SUBMIT_TEXT,
    submit: async () => {
      setAlertShow(false);
      if (pollType === POLL_TYPE.SURVEY) {
        await handleSub(SUB_QUESTION_TYPE.NEXT);
        setSurveyClassResultQuestion(questions[pollLiveQuestionIdx]);
        setPollLiveQuestionIdx(questions.length);
        setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
      } else {
        await handleSub(SUB_QUESTION_TYPE.NEXT);
        setPollLiveQuestionIdx(questions.length);
        handleUserCompletePoll();
      }

    },
    close:  async () => {
      setAlertShow(false);
      if ( questions.length !== firstSkipped) {
        await handleSub(SUB_QUESTION_TYPE.UNANSWERED);
        setPollLiveQuestionIdx(firstSkipped - 1);
       }
    },
  };

  const handleMouseEnterThumbUp = (reaction) => {
    if (reaction === 1) {
      setHoveringThumbUp(true);
    }
    else if (reaction === 2) {
      setHoveringThumbDown(true)
    }
  };

  const handleMouseLeaveThumbUp = (reaction) => {
    if (reaction === 1) {
      setHoveringThumbUp(false);
    }
    else if (reaction === 2) {
      setHoveringThumbDown(false)
    }
  };

  return (
      <>
        <YuJaGeneralAlert
            show={alertShow}
            setModalShow={setAlertShow}
            content={alertContent}
            config={alertConfig}
        />

        {pageState === SURVEY_ALL_PAGE_STATE.RESULT &&
            <ClassResultSurvey
                pollInfo={pollInfo}
                pollType={pollType}
                currentPoll={currentPoll}
                queResponses={queResponses}
                setQueResponses={setQueResponses}
                pollLiveQuestionIdx={pollLiveQuestionIdx}
                setPollLiveQuestionIdx={setPollLiveQuestionIdx}
                question={surveyClassResultQuestion}
                handleSubmitResponse={handleSubmitResponse}
                handlePollComplete={handlePollComplete}
                changeCurPoll={changeCurPoll}
                websocketState={websocketState}
                institutionId={institutionId}
            />
        }

        {pageState === SURVEY_ALL_PAGE_STATE.QUESTION &&
            <NormalBox boxType="lightBlue" className={`${styles.shadow} ${styles.centerLayout}  ${styles.pollShareAllLayOut}`}>
              {/* {isNarrow &&
                  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: 'calc(100%-42px)'}}>
                    {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                      <CountdownTimerAll
                          pollEndTime={pollEndTime}
                          timeUp={timeUp}
                          state={state}
                      />
                    }
                    <NetworkStatus tooltipPlacement={'bottom'} websocketState={websocketState}/>
                  </div>
              } */}
              {!isMobile && window.innerWidth >= 1200 &&
                  <NormalBox boxType="light" className={`${styles.containerTop}`} style={{padding: "0px 3vw 0px 3vw"}}>
                    <div id="header" className="d-flex justify-content-between" style={{alignItems: "center", height: "90px"}}>
                      <div tabIndex={0} aria-label={POLL_TITLE_LABEL + pollTitle} role={TEXTBOX} className="poll-title">{pollTitle}</div>
                          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20}}>
                            <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState}/>
                            {isTimed && pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                                <CountdownTimerAll
                                    pollEndTime={pollEndTime}
                                    timeUp={timeUp}
                                    state={state}
                                />
                            }
                          </div>
                    </div>
                  </NormalBox>
              }

                  <div className={`${styles.containerBottom}`} style={{display: "flex" , 
            flexDirection: "column", maxHeight: "calc(100vh - 75px)", padding: "0px"}}>
                    {(isMobile || window.innerWidth < 1200) &&
                        <>
                            {isTimed && pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                            <div style={{position: "fixed", top: "20px", left: isMobile ? "calc((100% - 120px)/2)" : "calc((100% - 170px)/2)"}}>
                                <CountdownTimerAll
                                    pollEndTime={pollEndTime}
                                    timeUp={timeUp}
                                    state={state}
                                />
                              </div>
                            }
                          <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20, padding: "0px 21px 0px 21px"}}>
                          <div className="poll-title" style={{maxWidth: "100%", flex: 1}}>{pollTitle}</div>
                          <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState}/>
                          </div>
                          <hr style={{padding: "0px 21px 0px 21px"}}/>
                        </>
                    }
                    <div style={{display: "flex", flexDirection: "column", overflowY : "auto", height: "100%", flex: 1, padding: "0px 21px 5px 21px"}}>
                    {!isMobile && window.innerWidth >= 1200 ?
                    <div className="header-board" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <div tabIndex={0} role={TEXTBOX} className="cur-question-title" style={{display: "flex", alignItems: "center"}}>Question {currSerialNo} of {questions.length} </div>
                      {currQuestionType !== QUESTION_TYPES.FITB.name && (
                        <>
                         <ArrowRight style={{flexShrink: "0"}}/>
                        <p tabIndex={0} aria-label={QUESTION_TITLE + currQueTitle} className="ques-desc" style={{fontSize: "24px", letterSpacing: "0.84px", lineHeight: "normal", color: "#000", order: "unset", marginTop: "0px", maxWidth: "70%", flexShrink: "1"}}>
                          {currQueTitle}
                        </p>
                        </>
                    )}
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1"}}> 
                      {pollType !== POLL_TYPE.SURVEY &&
                      <span className="gradientWrapQuesType" style={{height: "45px", width: isNarrow? "105px" : "195px"}}>
                                    <span className="questionPanelQuestionTypeContainer" style={{width: isNarrow? "100px" : ""}}>
                                        <div tabIndex={0} role={TEXTBOX} className="questionPanelQuestionType" style={{fontSize: "18px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center"}}>  {currWeightage} Points</div>
                                    </span>
                      </span>
                      }
                      </div>
                    </div>
                     :  <> <div className="header-board" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                     <div tabIndex={0} role={TEXTBOX} className="cur-question-title">Question {currSerialNo} of {questions.length}</div>  
                     {pollType !== POLL_TYPE.SURVEY && 
                     <span className="gradientWrapQuesType" style={{ height: "23px", width: "109px", marginLeft: "0px"}}>
                                <span className="questionPanelQuestionTypeContainer" style={{ width: "107px", padding: "0px", height:  "20px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div className="questionPanelQuestionType" role={TEXTBOX} style={{ fontSize: "12px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height:  "12px"}}> {currWeightage} Points </div>
                                </span>
                            </span>}
                      </div>
                     {currQuestionType !== QUESTION_TYPES.FITB.name && (
                        <p tabIndex={0} aria-label={QUESTION_TITLE + currQueTitle} className="ques-desc" style={{order: "initial"}}>
                          {currQueTitle}
                        </p>
                    )}
                     
                     </>}

                     {image && questionImageURL && isMobile && (
                  <div className="img-container-question" style={{alignSelf: "center", margin: "0px", marginTop: "30px", width: currQuestionType === QUESTION_TYPES.CI.name ? "100%" : ""}}>
                    {currQuestionType !== QUESTION_TYPES.CI.name && <>
                      {!isVideo && <img className="img" src={questionImageURL} alt="Question"/>}
                      {isVideo && <iframe allowFullScreen className="img" src={questionImageURL} alt="Question"/>}
                    </>}
                    {currQuestionType === QUESTION_TYPES.CI.name &&
                      <div style={{ position: "relative"}}>
                        <img
                          className="img"
                          ref={imageRef}
                          src={questionImageURL}
                          onLoad={onLoad}
                          onClick={handleClickImage}
                          alt="Question Image" 
                          tabIndex={0}/>
                        {isLoaded
                          && !!currentSelectedOption
                          && currentSelectedOption.hasOwnProperty("x")
                          && currentSelectedOption.hasOwnProperty("y")
                          &&
                          <COORDINATE
                            style={{
                              position: "absolute",
                              top: `${decodeCIParam(currentSelectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                              left: `${decodeCIParam(currentSelectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                            }}
                          />
                        }
                      </div>
                    }
                  </div>
                )}

                    <div className="main-body">
                      <div style={{display: "flex", gap: "70px"}}>

                        {/* Create the options for MCSS */}
                        {currQuestionType === QUESTION_TYPES.MCSS.name && (
                            <div id="body-MCSS" style={{display: "flex", flexDirection: "column", gap: isMobile ? "17px" : "30px", flex: "1", marginTop: isMobile ? "20px" : "", width: "100%"}}>
                              {MCSSelements.map((value, index) => {
                                return (
                                    <div key={index}>
                                      {!isMobile && index === 0 && <div style={{ display: "block", height: "30px" }} />}

                                      {pollType === POLL_TYPE.SURVEY && (
                                          <input
                                              type="checkbox"
                                              id={value}
                                              value={MCSSoptLower[index]}
                                              name="MCSS"
                                              checked={
                                                  !!currentSelectedOption &&
                                                  currentSelectedOption.includes(
                                                      MCSSoptLower[index]
                                                  )
                                              }
                                              className="MCSS-btn"
                                              style={{ display: "none" }}
                                              onChange={handleChooseOption}
                                              tabIndex={0}
                                          />
                                      )}

                                      {pollType === POLL_TYPE.GRADED_POLL && (
                                          <input
                                              type="radio"
                                              id={value}
                                              value={MCSSoptLower[index]}
                                              name="MCSS"
                                              checked={
                                                  !!currentSelectedOption &&
                                                  MCSSoptLower[index] ===
                                                  currentSelectedOption
                                              }
                                              className="MCSS-btn"
                                              onChange={handleChooseOption}
                                          />
                                      )}

                                      <label tabIndex={0} htmlFor={value} className="MCSS-label" style={{width: "100%", minHeight: isMobile ? "45px" : "", borderRadius: isMobile ? "4px" : "", border: isMobile ? "1.5px solid #909090" : ""}}>
                                        <div className="MCSS-letter-container" style={{marginLeft: isMobile ? "20px" : "", border: (!!currentSelectedOption && MCSSoptLower[index] === currentSelectedOption || (Array.isArray(currentSelectedOption) && currentSelectedOption.includes(MCSSoptLower[index]))) ? "1.5px solid #FFF" : isMobile ? "1.5px solid #000" : "2.5px solid #000"}}> 
                                        <span className="MCSS-letter" style={{color: (!!currentSelectedOption && MCSSoptLower[index] === currentSelectedOption || (Array.isArray(currentSelectedOption) && currentSelectedOption.includes(MCSSoptLower[index])))  ? "#42296E" : "#000"}}>
                                          {MCSSoptUpper[index]}
                                        </span>
                                        </div> 
                                        <p className="MCSS-option" id={index + value} style={{paddingLeft: isMobile ? "15px" : "", fontSize: isMobile? "14px" : "" , color:  !!currentSelectedOption && (MCSSoptLower[index] === currentSelectedOption || (Array.isArray(currentSelectedOption) && currentSelectedOption.includes(MCSSoptLower[index]))) ? "#FFF": "#000", maxWidth: isMobile ? "70%" : window.innerWidth < 1200 ? "80%" : "90%", paddingRight: "5px"}}>
                                          {newOptionsMap[MCSSoptLower[index]]}
                                        </p>
                                      </label>
                                    </div>
                                );
                              })}
                            </div>
                        )}

                        {/* Create the TF options */}
                        {currQuestionType === QUESTION_TYPES.TF.name && (
                            <div className= "TF-option" id="body-TF" name={"1234"} style={{flex: "1"}}>
                              {TFelements.map((value, index) => {
                                return (
                                      <YuJaRadioBox
                                        size={isMobile ? 18 : 32}
                                        id={value}
                                        value={TFopt[index]}
                                        name="TF"
                                        checked={
                                            !!currentSelectedOption &&
                                            TFopt[index] === currentSelectedOption
                                        }
                                        style={{fontSize: isMobile ? 18 : 24, marginTop: 30}}
                                        labelStyle={{margin: "0px 0px 0px 16px"}}
                                        label={TFopt[index]}
                                        onClick={handleChooseOption}
                                      />
                                );
                              })}
                            </div>
                        )}

                        {/* Create the input box for SA */}
                        {currQuestionType === QUESTION_TYPES.SA.name && (
                            <div id="body-SA" className="sa-center" style={{flex: "1"}}>
                              <textarea
                                  className="sa-ans"
                                  id="SA-IN"
                                  name="sa-in"
                                  placeholder="Type short answer here."
                                  value={
                                    !currentSelectedOption ||
                                    currentSelectedOption === "unanswered"
                                        ? ""
                                        : currentSelectedOption["0"]
                                  }
                                  onChange={handleType}
                              />
                            </div>
                        )}

                        {/* Creates the question for FITB */}
                        {currQuestionType === QUESTION_TYPES.FITB.name && (
                            <div
                                id="body-FITB"
                                className="ques-desc"
                                style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "5px", marginTop: "30px", flex: "1", order: "initial"}}
                            >
                              {/* {reactStringReplace(currQueTitle,  /(\(.*?\))/g , (m, i, o) => {
                                if (m !== "()") {
                            
                                idx += 1;
                                return (
                                    <YuJaTextBox
                                        key={`FITB${i}`}
                                        id={Object.values(FITBElements)[idx]}
                                        containerStyle={{width: 150}}
                                        onChange={handleType}
                                        maxLength={FITB_ANSWER_LENGTH}
                                        value={
                                          !currentSelectedOption ||
                                          currentSelectedOption === "unanswered" ||
                                          !currentSelectedOption.hasOwnProperty(FITBElements[idx])
                                              ? ""
                                              : currentSelectedOption[FITBElements[idx]]
                                        }
                                    />
                                );
                              }
                              else {
                                return ("()");
                              }
                              })} */}

                              {
                               currQueTitle.split(/(\(.*?\))/g).map((str, i) => {
                                let re = new RegExp(/\([^\(\)]+\)/g);
                                if (!re.test(str)) {
                                    const oneLine = str.replace(/[\r\n]/g, " @newLine ");
                                    const wordArr = oneLine.split(" ");
                                    return (
                                        <>
                                            {wordArr.map((s, idx) => (<>
                                                {s === '@newLine' &&
                                                    <div key={idx} style={{width: "100%"}}></div>
                                                }
                                                {!!s && s !== '@newLine' &&
                                                    <div key={idx}>{s}</div>
                                                }
                                            </>))}
                                        </>
                                    );
                                } else {
                                  idx += 1;
                                  return (
                                      <YuJaTextBox
                                          key={`FITB${i}`}
                                          id={Object.values(FITBElements)[idx]}
                                          containerStyle={{width: 150}}
                                          onChange={handleType}
                                          maxLength={FITB_ANSWER_LENGTH}
                                          value={
                                            !currentSelectedOption ||
                                            currentSelectedOption === "unanswered" ||
                                            !currentSelectedOption.hasOwnProperty(FITBElements[idx])
                                                ? ""
                                                : currentSelectedOption[FITBElements[idx]]
                                          }
                                      />
                                  );
                                } } )}
                            </div>
                        )}

                        {/* Create the input boxes for WC */}
                        {currQuestionType === QUESTION_TYPES.WC.name &&
                            <>
                              {shownEntries > 1 &&
                                  <div id="body-WC" className={isNarrow ? "wc-center-narrow" : "wc-center"} style={{flex: "1", height: "fit-content"}}>
                                    {Array(shownEntries).fill(null).map((item, i) => {
                                      return (
                                          <div key={i} className="wc-ans-container" style={{width: isNarrow ? "100%" : ""}}>
                                            <YuJaTextBox
                                                id={`WC-IN-${i}`}
                                                name="wc-in"
                                                placeholder={i === 0 ? FIRST_WC_PLACEHOLDER : OTHER_WC_PLACEHOLDER}
                                                maxLength={WC_ANSWER_LENGTH}
                                                style={{paddingRight: "20px", boxSizing: "border-box"}}
                                                value={checkBlankAnswered(currentSelectedOption) ? "" : currentSelectedOption[i]}
                                                onChange={(e) => {
                                                  const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : {...currentSelectedOption}
                                                  copy[i] = e.target.value;
                                                  setCurrentSelectedOption(copy);
                                                  if (i === shownEntries - 1 && shownEntries < currEntries && e.target.value.length) {
                                                    setShownEntries(shownEntries + 1);
                                                  }
                                                }}
                                                containerStyle={{border: "2.5px solid #D0D0D0", borderRadius: "10px"}}
                                            />
                                            <div className="wc-char-count">
                                              {WC_ANSWER_LENGTH - (currentSelectedOption[i] ? currentSelectedOption[i].length : 0)}
                                            </div>
                                            <img
                                                src={deleteIcon}
                                                tabIndex={0}
                                                alt="Delete Answer"
                                                className="delete-entry-icon"
                                                role={BUTTON}
                                                onClick={() => handleDelete(i)}
                                                style={{width: "38px", height: "38px", marginLeft: "15px"}}
                                            />
                                          </div>
                                      );
                                    })}
                                  </div>
                              }


                              {shownEntries === 1 &&
                                  <div id="body-WC-odd" className="wc-center-odd">
                                    <div className="wc-ans-container">
                                      <YuJaTextBox
                                          id={`WC-IN-0`}
                                          name="wc-in"
                                          containerStyle={{width: "40vw", border: "2.5px solid #D0D0D0", borderRadius: "10px"}}
                                          placeholder={WCElements.length === 1 ? FIRST_WC_PLACEHOLDER : OTHER_WC_PLACEHOLDER}
                                          maxLength={WC_ANSWER_LENGTH}
                                          style={{paddingRight: "20px", boxSizing: "border-box"}}
                                          value={checkBlankAnswered(currentSelectedOption) ? "" : currentSelectedOption[0]}
                                          onChange={(e) => {
                                            const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : {...currentSelectedOption}
                                            copy[0] = e.target.value;
                                            setCurrentSelectedOption(copy);
                                            wcInputValRef.current = e.target.value;
                                          }}
                                      />
                                      <div className="wc-char-count">
                                        {WC_ANSWER_LENGTH - (currentSelectedOption[0] ? currentSelectedOption[0].length : 0)}
                                      </div>
                                      <img
                                          src={deleteIcon}
                                           className="delete-entry-icon"
                                           role={BUTTON}
                                           tabIndex={0}
                                           alt="Delete Answer"
                                           onClick={() => handleDelete(0)}
                                           style={{width: "38px", height: "38px", marginLeft: "15px"}}
                                      />
                                    </div>
                                  </div>
                              }
                            </>
                        }
                        {currQuestionType === QUESTION_TYPES.MH.name && (<>
                      <div id="body-MCSS" style={{flex: "1", maxWidth: "100%"}}>
                      <div>
                        <div style={{ display: "flex", marginTop: "30px", width: "100%"}}>
                          <div style={{width: "100%"}}>
                            {Array.isArray(questions[curIdx].correctAnswers) && questions[curIdx].correctAnswers.map((element, index) => (
                              <div key={element[0]} id={"Option" + index} className="questionPanelOptionsContainer" style={{ paddingLeft: "0px", paddingRight: "0px", marginBottom: "0px", display: "flex", alignItems: "center" }}>
                                {element.length > 1 ?
                                  <div style={{ display: "flex", flex: 1, width: "100%", borderTop: isNarrow && index ===0 ? "none" : ""}} className={isNarrow ? styles.MatchingNarrow : ""}>
                                    {isNarrow && <div style={{fontWeight: "700", fontSize: "45", marginTop:"10px", marginBottom: "10px"}}> {PREMISE} </div>}
                                    <div
                                        tabIndex={0}
                                        role={TEXTBOX}
                                        className="questionPanelAnswersText matchingPremise"
                                        id={"OptionPart1" + index}
                                        style={{
                                          marginRight: "0px",
                                          padding: 10,
                                          borderRadius: "10px",
                                          minWidth: !isNarrow ? "250px": "",
                                          height: "fit-content",
                                          flex: 1,
                                          wordBreak: "keep-all"
                                        }}
                                    >{element[0]}</div>
                                    <img
                                      src={ArrowIcon}
                                      alt="Arrow icon"
                                      className="questions-page-icons cursor-pointer"
                                      style={{ width: "48px", margin: "0px 20px", height: "fit-content",  display: isNarrow ? "none" : "block"}}
                                    /> 
                                      <div style={{minWidth: "50%", position: "relative", flex: "1"}} onBlur={() => {if(isDropdownOpen != null) {setIsDropdownOpen(null)}}}>
                                      {isNarrow && <div style={{fontWeight: "700", fontSize: "45", marginTop:"10px", marginBottom: "10px"}}> {RESPONSE} </div>}
                                        <YuJaDropdown
                                            data={questions[curIdx].optionsMap}
                                            getOptionLabel={option => option.length > 1 ? option[1]: option[0]}
                                            getOptionValue={option => option.length > 1 ? option[1]: option[0]}
                                            value={currentSelectedOption[index]}
                                            placeholder={"Select an option"}
                                            textStyle={{wordBreak: "break-all"}}
                                            itemStyle={{borderWidth: "2.5px", borderColor: "#909090" }}
                                            onChange={(option) => {
                                              let newAnswer = {[index]: option.length > 1 ? option[1]: option[0]};
                                              if(currentSelectedOption !==  FIXED_ANSWER.UNANSWERED) {
                                                newAnswer = { ...currentSelectedOption, [index]: option.length > 1 ? option[1]: option[0] };
                                              }

                                              setCurrentSelectedOption(newAnswer);
                                              document.getElementById("sub-btn-curr").style.display = "block";
                                            }}
                                            style={{fontSize: 18, padding: 10, borderRadius: "10px",
                                              border: "2.5px solid #909090",
                                             }}
                                             arrowSize={"24px"}
                                        />
                                      </div>
                                    </div> : <></>}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                </div>
              </> ) } 
                {questionImageURL && !isMobile && (
                  <div className="img-container-question" style={{alignSelf: "center", margin: "0px", marginTop: "30px", width: currQuestionType === QUESTION_TYPES.CI.name ? "100%" : ""}}>
                    {currQuestionType !== QUESTION_TYPES.CI.name && <>
                      {!isVideo && <img className="img-question" src={questionImageURL} alt="Question"/>}
                      {isVideo && <iframe className="img-question" allowFullScreen src={questionImageURL} alt="Question"/>}  
                    </>}
                    {currQuestionType === QUESTION_TYPES.CI.name &&
                      <div style={{ position: "relative"}}>
                        <img
                          className="img"
                          ref={imageRef}
                          src={questionImageURL}
                          onLoad={onLoad}
                          onClick={handleClickImage}
                          alt="Question Image" 
                          tabIndex={0}/>
                        {isLoaded
                          && !!currentSelectedOption
                          && currentSelectedOption.hasOwnProperty("x")
                          && currentSelectedOption.hasOwnProperty("y")
                          &&
                          <COORDINATE
                            style={{
                              position: "absolute",
                              top: `${decodeCIParam(currentSelectedOption.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                              left: `${decodeCIParam(currentSelectedOption.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                            }}
                          />
                        }
                      </div>
                    }
                  </div>
                )}
              </div>
              </div>
              </div> 
                      <div id="sub-spc" style={{height: "75px"}}/>
                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", paddingBottom: "85px", paddingLeft: isMobile ? "10px" : "21px", paddingRight: isMobile ? "10px" : "21px"}}>
                      {pollType !== POLL_TYPE.SURVEY &&
                          <div>
                          <div  className= {pollLiveQuestionIdx === 0 ? "grayedGradientWrap" : "gradientWrap"} style={{width: isMobile? "40px" : "75px", height: isMobile? "40px" : "45px"}}>
                                <span onClick={previous} tabIndex={pollLiveQuestionIdx === 0 ? -1 : 0} aria-label={PREV_QUES} role={BUTTON} className= {pollLiveQuestionIdx === 0 ? "grayedBtnArrows": "gradientBtnArrows"} style={{width:  isMobile? "35px" : "70px", height: isMobile? "35px" : "40px", borderRadius: isMobile? "3px" : "", padding: isMobile? "0" : "", display: "flex", alignItems: "center", justifyContent: "center"}}>
                              <BackIcon/>
                                </span>
                          </div>
                          </div>
                          }
                          {pollType === POLL_TYPE.SURVEY &&
                          <div>
                          <div style={{width: isMobile? "40px" : "75px", height: isMobile? "40px" : "45px"}}>
                          </div>
                          </div>
                          }
                  <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}> <div
                    className={thumbup || hoveringThumbUp ? "reaction-activated-background " : "reaction-background"}
                    style={{
                      width: '48px',
                      height: '48px',
                      borderRadius: '50%',
                      border: '2px solid #42296E',
                      alignItems: "center",
                      display: 'flex',
                      justifyContent: "center",
                      flexDirection: 'column'
                    }}
                    onMouseEnter={() => { handleMouseEnterThumbUp(1); }}
                    onMouseLeave={() => { handleMouseLeaveThumbUp(1); }}
                    onClick={() => { handleReaction(1) }}
                    tabIndex={0}
                    aria-label={THUMBS_UP}
                    role={BUTTON}
                  > <div className={thumbup || hoveringThumbUp ? "thumbupicon-activated" : "thumbupicon"}></div> </div>   <div
                    className={thumbdown || hoveringThumbDown ? "reaction-activated-background " : "reaction-background"}
                    style={{
                      width: '48px',
                      height: '48px',
                      borderRadius: '50%',
                      border: '2px solid #42296E',
                      alignItems: "center",
                      display: 'flex',
                      justifyContent: "center",
                      flexDirection: 'column'
                    }}
                    onMouseEnter={() => { handleMouseEnterThumbUp(2); }}
                    onMouseLeave={() => { handleMouseLeaveThumbUp(2); }}
                    onClick={() => { handleReaction(2) }}
                    tabIndex={0}
                    aria-label={THUMBS_DOWN}
                    role={BUTTON}
                  > <div className={thumbdown || hoveringThumbDown ? "thumbdownicon-activated" : "thumbdownicon"}></div> </div></div>

                      {pollLiveQuestionIdx !== questions.length - 1  ?
                          <div>
                          <div className= {"gradientWrap"} style={{width: isMobile? "40px" : "75px", height: isMobile? "40px" : "45px"}}>
                                <span onClick={next} tabIndex={0} aria-label={NEXT_QUES} role={BUTTON} className= {"gradientBtnArrows"} style={{width:  isMobile? "35px" : "70px", height: isMobile? "35px" : "40px", borderRadius: isMobile? "3px" : "", padding: isMobile? "0" : "", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <NextIcon/>
                                </span>
                            </div>
                            </div>
                        : 
                        <div> <YuJaButton
                        id="sub-btn-curr"
                        onClick={next}
                        style={{fontSize: isMobile ? "16px" : "20px", padding: isMobile ? "10px" : "10px 15px", width: isMobile? "75px" : "95px"}}
                      >
                        {'Submit'}
                      </YuJaButton>
                      </div>
                         }
                         </div>

                        
                    </div>
            </NormalBox>
        }
      </>
  );
}
