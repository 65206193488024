import React from "react";
import { MdOutlineCancel, MdOutlineCheckCircle } from "react-icons/md";
import "./styles.css";

export default function TableQuestionTitle({ serialNo }) {
    const iconStyle = { margin: "1px", color: "#1c85b4" };
  return (
    <th>
      <div className="splitScreenColumn">
        <div className="topPane padding-zero">
          <span>Q#{serialNo}</span>
        </div>
        <div className="bottomPane padding-zero">
          <MdOutlineCheckCircle size={30} style={iconStyle} />
          <MdOutlineCancel size={30} style={iconStyle} />
        </div>
      </div>
    </th>
  );
}
