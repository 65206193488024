import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FormModal from "./FormModal";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { REQUIRED_FIELD_ERROR } from "../../utils/constants";

export default function CreateCourseModal({setModalShow, show, getCourses }) {
  const [courseName, setCourseName] = useState("");
  // const [subject, setSubject] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [instructorsName, setInstructorsName] = useState("");
  const [courseNameError, setCourseNameError] = useState("");
  // const [subjectError, setSubjectError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [courseCodeError, setCourseCodeError] = useState("");
  const [instructorsNameError, setInstructorsNameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { saveCourse } = CourseApiCalls();
  const history = useHistory();

  const clearForm = () => {
    setCourseName("");
    // setSubject("");
    setCourseCode("");
    // setStartDate("");
    // setInstructorsName("");
    clearError();
    setModalShow(false)
  };

  const clearError = () => {
    setCourseNameError("");
    // setSubjectError("");
    // setStartDateError("");
    setCourseCodeError("");
    // setInstructorsNameError("");
  };

  const addCourse = async () => {
    if (validateForm()) {

      const body = {};
      body.courseName = courseName;
      // body.subject = subject;
      // body.startDate = format(startDate);
      body.courseCode = courseCode;
      // body.instructorsName = instructorsName;

      setIsLoading(true);
      const courseRecord = await saveCourse(body);
      setIsLoading(false);
      setModalShow(false);
      if (!!courseRecord) {
        getCourses();
      }
    }
  };

  const format = (input) => {
    const pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
    if (!input || !input.match(pattern)) {
      return null;
    }
    return input.replace(pattern, '$3/$2/$1');
  };

  const validateForm = () => {
    clearError();
    let returnVal = true;
    if (!courseName) {
      setCourseNameError(REQUIRED_FIELD_ERROR);
      returnVal = false;
    }

    // if (!subject) {
    //   setSubjectError(REQUIRED_FIELD_ERROR);
    //   returnVal = false;
    // }

    // if (!startDate) {
    //   setStartDateError(REQUIRED_FIELD_ERROR);
    //   returnVal = false;
    // }

    if (!courseCode) {
      setCourseCodeError(REQUIRED_FIELD_ERROR);
      returnVal = false;
    }

    // if (!instructorsName) {
    //   setInstructorsNameError(REQUIRED_FIELD_ERROR);
    //   returnVal = false;
    // }

    return returnVal;
  };

  const modalConfig = {
    title: "Create Course",
    submitButtonText: "Create Course",
    subText: "Please complete course details and click create course.",
    submit: addCourse,
    clearForm: clearForm,

    form: [
      {
        label: "Course Code",
        value: courseCode,
        setFunction: setCourseCode,
        error: courseCodeError,
        type: "text",
      },

      {
        label: "Course Name",
        value: courseName,
        setFunction: setCourseName,
        error: courseNameError,
        type: "text",
      },

      // {
      //   label: "Subject",
      //   value: subject,
      //   setFunction: setSubject,
      //   error: subjectError,
      //   type: "text",
      // },

      // {
      //   label: "Instructor's Name",
      //   value: instructorsName,
      //   setFunction: setInstructorsName,
      //   error: instructorsNameError,
      //   type: "text",
      // },

      // {
      //   label: "Start Date",
      //   value: startDate,
      //   setFunction: setStartDate,
      //   error: startDateError,
      //   type: "date",
      // },
    ],
  };

  return (
      <FormModal
        show={show}
        isLoading={isLoading}
        setModalShow={setModalShow}
        modalConfig={modalConfig}
      />
  );
}
