import React, {useMemo} from "react";
import styles from './CourseContent.module.css'
import NormalText from "../../public/texts/NormalText";
import NormalBox from "../../public/boxes/NormalBox";
import useMobileAccess from "../../hooks/useMobileAccess";
import YuJaButton from "../../components/standardization/YuJaButton";


export default React.memo(BlankContent)
function BlankContent({addMembers}) {
    const settings = useMemo(() => {
        return {
            'contentBoxType': 'light',
            'textTitle': 'title',
            'textAlign': 'center',
        };
    }, [])

    const isNarrowScreen = useMobileAccess(300);

    return (
        < >
            <NormalBox boxType={settings.contentBoxType} className={`${styles.blankContent} ${styles.blankContentLayout}`}>
                {!isNarrowScreen && (<div className={styles.blankContentIllustration}/>)}
                <NormalText
                    className={styles.blankContentBlankTitle}
                    textType={settings.textTitle}
                    alignType={settings.textAlign}
                >
                    There are currently no registered members for this course.
                </NormalText>
                <NormalText
                    className={styles.blankContentDescription}
                    alignType={settings.textAlign}
                >
                    <YuJaButton style={{fontSize: 20}} onClick={addMembers}>Add Members</YuJaButton>
                </NormalText>
            </NormalBox>
        </>
    );
}