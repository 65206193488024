import { Spin } from "../../components/standardization/YuJaLoading";
import "antd/dist/antd.min.css";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { InstitutionApiCalls } from "../../apis/InstitutionApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { useLoading } from "../../utils/LoadingContext";
import { POLL_TITLE_LENGTH, PLATFORM_SETTINGS,  POLL_TYPE, QUESTION_TYPES, RECORD_ATTEMPT, SYNC_ATTEMPT, SWITCH_POLL_MESSAGE, SWITCH_POLL_MODAL_TEXT, SWITCH_POLL_MODAL_TITLE, GRADED_POLL, CREATE_POLL_PATH, MODAL_EDIT_TEXT, POLL_NAVBAR, SURVEY_NAVBAR} from "../../utils/constants";
import { getInstitutionId, notifyError, removePrefix, updateCreateMode, validateText } from "../../utils/helpers";
import { addQuestion, chooseQuestion, questionError, trimQuestion } from "../../utils/questionUtils";
import { POLL_TITLE_ERROR, QUESTION_MAX_POINTS_ERROR, SURVEY_TITLE_ERROR } from "../../utils/toast-message-constants";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import CreateQuestionDetailSidebar from "./CreateQuestionDetailSidebar";
import CreateQuizSidebar from "./CreateQuizSidebar";
import EmptySidebar from "./EmptySidebar";
import QuestionsMain from "./QuestionsMain";
import useHostname from "../../hooks/useHostname";
import "./styles.css";

export default function EditQuizPage({ setSidebarContent, setRightSidebarContent }) {
  const { pollKey } = useParams();
  const [questions, setQuestions] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [instId, setInstId] = useState("");
  const { updatePoll, getPollDataFromKey } = PollApiCalls();
  const { getSetting } = InstitutionApiCalls();
  const history = useHistory();
  // const queChangeCount = useRef(0);
  const [currQueDisplay, setCurrQueDisplay] = useState("1");
  const { loading } = useLoading();
  const [pollType, setPollType] = useState("");
  // const [pollTypeModalShow, setPollTypeModalShow] = useState(false);
  const [desiredPollMode, setDesiredPollMode] = useState("");
  const [setPollTitle, getPollTitle] = useLocalStorageNew("pollTitle", "");
  const [showAnswerOnViewer, setShowAnswerOnViewer] = useState(false);
  const [recordAttempt, setRecordAttempt] = useState(RECORD_ATTEMPT.ALL.value);
  const [lmsAttempt, setLMSAttempt] = useState(SYNC_ATTEMPT.FIRST.value);
  const [maxAttempts, setMaxAttempts] = useState(-1);
  const [setQues, getQues] = useLocalStorageNew("questions", []);
  const [setType, getType] = useLocalStorageNew("pollType", "");

  const [focusMCElem, setFocusMCElem] = useState(-1);
  const [focusSAElem, setFocusSAElem] = useState(-1);
  const [focusTFElem, setFocusTFElem] = useState(-1);
  const [focusFITBElem, setFocusFITBElem] = useState(-1);
  const [focusCIElem, setFocusCIElem] = useState(-1);
  const [focusMHElem, setFocusMHElem] = useState(-1); 
  const [disableSave, setDisableSave] = useState(false);

  const [setSaveExit, getsaveExit] = useLocalStorageNew("saveExit", false); 
  const [nextRouterPath, setNextRouterPath] = useState({ pathname: '', state: null });
  const [alertShow, setAlertShow] = useState(false);
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);
  const [alertContent,] = useState(SWITCH_POLL_MESSAGE);
  const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentQuestions", []);
  const hostResource = useHostname(window.location.hostname);
  const {platformSetting} = hostResource;
  const [anonymousJoin, setAnonymousJoin] = useState(false);


  const [isSaving, setIsSaving] = useState(false); 

  if (questions.length < 1) {
    const qs = addQuestion(questions, "MCSS");
    setQuestions(qs);
    setCurrQueDisplay(qs[0].serialNo);
  }

  const checkChanged = () => {
    const curr_ques = JSON.stringify(getRecentQuestions()); 
    const curr_saved = JSON.stringify(getQues()); 
    return !(curr_ques === curr_saved);
  }

  useEffect(() => {
    let isCheckChangedPending = false;
  
    const unblock = history.block((location, action) => {
      if (isCheckChangedPending) {
        return true; 
      }
      isCheckChangedPending = true;
  
      const result = checkChanged();
      if (!result || getsaveExit() === true) {
        setSaveExit(false);
        resetPoll();
        return true;
      }
  
      if (location.pathname && !isConfirmationPending) {
        setNextRouterPath({ pathname: location.pathname, state: location.state });
        setAlertShow(true);
        return false;
      }
  
      return true;
    });
  
    if (isConfirmationPending) {
      resetPoll();
      history.push(nextRouterPath.pathname, nextRouterPath.state);
      setIsConfirmationPending(false);
      unblock();
    }
  
    return () => {
      unblock();
    };
  }, [history, isConfirmationPending, nextRouterPath]);

  const alertConfig = {
    title: SWITCH_POLL_MODAL_TITLE,
    okText: SWITCH_POLL_MODAL_TEXT,
    submit: async () => {
      setAlertShow(false);
      setIsConfirmationPending(true);
    },
    close:  async () => {
      setAlertShow(false);
    }, 
  };

  useEffect (() => {
    setRecentQuestions(questions); 
  }, [questions]);

  useEffect(() => {
    switch(questions[currQueDisplay-1].questionType) {
      case QUESTION_TYPES.MCSS.name:
        if(focusMCElem !== -1) {
          document.getElementsByName("question-text")[focusMCElem].focus();
          setFocusMCElem(-1);
        }
        break;
      case QUESTION_TYPES.SA.name:
        if(focusSAElem !== -1) {
          document.getElementsByName("question-text")[focusSAElem].focus();
          setFocusSAElem(-1);
        }
        break;
      case QUESTION_TYPES.TF.name:
        if(focusTFElem !== -1) {
          document.getElementsByName("question-text")[focusTFElem].focus();
          setFocusTFElem(-1);
        }
        break;
      case QUESTION_TYPES.FITB.name:
        if(focusFITBElem !== -1) {
          if(focusFITBElem === 0) {
            document.getElementsByClassName("notranslate public-DraftEditor-content")[focusFITBElem].focus();
          } else {
            document.getElementsByName("question-text")[focusFITBElem-1].focus();
          }
          setFocusFITBElem(-1);
        }
        break;
      case QUESTION_TYPES.CI.name:
        if(focusCIElem !== -1) {
          document.getElementsByName("question-text")[focusCIElem].focus();
          setFocusCIElem(-1);
        }
        break;
      case QUESTION_TYPES.MH.name:
        if (focusMHElem !== -1) {
          console.log(focusMHElem);
          document.getElementsByName("question-text")[focusMHElem].focus();
          setFocusMHElem(-1);
        }
        break;
    }
  }, [currQueDisplay, focusMCElem, focusSAElem, focusTFElem, focusFITBElem, focusCIElem, focusMHElem]);


  const changeCurrQueDisplay = (newValue) => {
    setCurrQueDisplay(newValue);
  }

  const validatePoll = () => {
    if (!validateText(title, POLL_TITLE_LENGTH)) {
      notifyError(pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_ERROR : POLL_TITLE_ERROR);
      document.getElementsByName("pollTitle")[0].focus();
      return false;
    }

    let i = 0;
    for (let q of questions) {
      i++;
      if (q.weightage > 1000) {
        let errorWithQuestion = "Question " + i + ": " + QUESTION_MAX_POINTS_ERROR;
        notifyError(errorWithQuestion);
        setCurrQueDisplay(q.serialNo);
        return false;
      }
    }

    let index = 0;
    for (let q of questions) {
      index++;
      setCurrQueDisplay(q.serialNo);
      let {quesError, elemIndex} = questionError(q, pollType);
      switch(q.questionType) {
        case QUESTION_TYPES.MCSS.name:
          setFocusMCElem(elemIndex);
          break;
        case QUESTION_TYPES.SA.name:
          setFocusSAElem(elemIndex);
          break;
        case QUESTION_TYPES.TF.name:
          setFocusTFElem(elemIndex);
          break;
        case QUESTION_TYPES.FITB.name:
          setTimeout(() => {
            setFocusFITBElem(elemIndex);
          }, 100);
          break;
        case QUESTION_TYPES.CI.name:
          setFocusCIElem(elemIndex);
        case QUESTION_TYPES.MH.name: 
          setFocusMHElem(elemIndex);
          break;
      }

      if (quesError) {
        let errorWithQuestion = "Question " + index + ": " + quesError;
        setQuestions(chooseQuestion(q.serialNo, questions));
        notifyError(errorWithQuestion);
        return false;
      }
    }
    return true;
  };

  const editPoll = async (normalEdit = true) => {
    if (!validateText(title, POLL_TITLE_LENGTH)) {
      notifyError(pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_ERROR : POLL_TITLE_ERROR);
      document.getElementsByName("pollTitle")[0].focus();
      return false;
    }

    const copyQuestions = JSON.parse(JSON.stringify(questions)).map(q => {
      q.timeLimit = 0;
      return q;
    });


    let pollToAdd = {
      pollType: pollType,
      questions: pollType.toUpperCase() === POLL_TYPE.SURVEY ? copyQuestions : questions,
      // ownerId: "794e0074-e325-4fb5-973c-d55750ab5324",
      pollTitle: title,
      pollDescription: description,
      showAnswerOnViewer: showAnswerOnViewer,
      anonymousJoin: anonymousJoin, 
      recordAttempt: recordAttempt,
      lmsAttempt: lmsAttempt,
      maxAttempts: maxAttempts,
      pollKey: removePrefix(pollKey)
    };
    await updatePoll(pollToAdd, normalEdit);
    window.onbeforeunload = undefined;
    return true;
  };

  const editPollClose = async (normalEdit = true) => {
    setDisableSave(true);
    setSaveExit(true);
    setQues(questions);
    if (!validatePoll()) {
      setDisableSave(false);
      return;
    }

    const saveQuestions = trimQuestion(questions); 
    const copyQuestions = JSON.parse(JSON.stringify(saveQuestions)).map(q => {
      q.timeLimit = 0;
      return q;
    });

    let pollToAdd = {
      pollType: pollType,
      questions: pollType.toUpperCase() === POLL_TYPE.SURVEY ? copyQuestions : saveQuestions, 
      pollTitle: title,
      pollDescription: description,
      pollKey: removePrefix(pollKey),
      showAnswerOnViewer: showAnswerOnViewer,
      anonymousJoin: anonymousJoin, 
      recordAttempt: recordAttempt,
      lmsAttempt: lmsAttempt,
      maxAttempts: maxAttempts,
    };
    await updatePoll(pollToAdd, normalEdit);
    history.push('/home');
  };

  useEffect(() => {
    const getPlatformSettings = () => {
      getSetting().then((result) => {
        if (!!result && !!result.LMSQuizAttempts) {
          setLMSAttempt(result.LMSQuizAttempts);
        }
      });
    };

    getPollDataFromKey(pollKey).then(res => {
      setAnonymousJoin(res.anonymousJoin);
      setQuestions(res.questions);
      setQues(res.questions);
      setTitle(res.pollTitle);
      setPollTitle(res.pollTitle);
      setDescription(res.pollDescription);
      setPollType(res.pollType);
      if(document.getElementById("engagePageIdentifier") != null) {
        document.getElementById("engagePageIdentifier").innerText = MODAL_EDIT_TEXT + (res.pollType === GRADED_POLL ? POLL_NAVBAR : SURVEY_NAVBAR);
        updateCreateMode(res.pollType === GRADED_POLL ? POLL_TYPE.GRADED_POLL : POLL_TYPE.SURVEY);
      }
      setType(res.pollType);
      setInstId(getInstitutionId(res.institutionId));
      setShowAnswerOnViewer(res.hasOwnProperty("showAnswerOnViewer") ? res.showAnswerOnViewer : false);
      setRecordAttempt(res.hasOwnProperty("recordAttempt") ? res.recordAttempt : RECORD_ATTEMPT.ALL.value);
      if (res.hasOwnProperty("lmsAttempt")) {
        setLMSAttempt(res.lmsAttempt);
      } else {
        getPlatformSettings();
      }
      setMaxAttempts(res.hasOwnProperty("maxAttempts") ? res.maxAttempts : -1);
    });
  }, []);


  // useEffect(() => {
  //   queChangeCount.current += 1;
  //   if (queChangeCount.current > 2 && !window.onbeforeunload) {
  //     window.onbeforeunload = function () {
  //       return '';
  //     };
  //   }
  // }, [questions]);


  useEffect(() => {
    return () => {
      window.onbeforeunload = undefined;
    }
  }, []);


  useEffect(() => {
    if (typeof(questions) != "undefined" && questions.length > 0 && pollType) {
      setSidebarContent(
        <CreateQuizSidebar
            pollKey={pollKey}
            setQuestions={setQuestions}
            questions={questions}
            currQueDisplay={currQueDisplay}
            setCurrQueDisplay={setCurrQueDisplay}
            pollType={pollType}
            setPollType={setPollType}
            handlePollTypeChange={handlePollTypeChange}
            // pollTypeModalShow={pollTypeModalShow}
            // setPollTypeModalShow={setPollTypeModalShow}
            recordAttempt={recordAttempt}
            setRecordAttempt={setRecordAttempt}
            lmsAttempt={lmsAttempt}
            setLMSAttempt={setLMSAttempt}
            maxAttempts={maxAttempts}
            setMaxAttempts={setMaxAttempts}
            desiredPollMode={desiredPollMode}
            setDesiredPollMode={setDesiredPollMode}
            checkChanged = {checkChanged}
            showAnswerOnViewer={showAnswerOnViewer}
            setShowAnswerOnViewer={setShowAnswerOnViewer}
            anonymousJoin={anonymousJoin}
            setAnonymousJoin={setAnonymousJoin}
        />
      );
    } else {
      setSidebarContent(
        <EmptySidebar
          title="Poll Questions"
          noContentText="No Questions added yet"
        />
      );
    }
  }, [questions, setQuestions, setSidebarContent, pollType, desiredPollMode, setDesiredPollMode, currQueDisplay, showAnswerOnViewer, anonymousJoin, recordAttempt, lmsAttempt, maxAttempts]);

  // useEffect(() => {
  //   setRightSidebarContent(
  //     <CreateQuestionDetailSidebar
  //       title={"Question Details"}
  //       noContentText={"Question Details"}
  //       questions={questions}
  //       setQuestions={setQuestions}
  //       currQueDisplay={currQueDisplay}
  //       pollType={pollType}
  //       showAnswerOnViewer={showAnswerOnViewer}
  //       setShowAnswerOnViewer={setShowAnswerOnViewer}
  //       anonymousJoin={anonymousJoin}
  //       setAnonymousJoin={setAnonymousJoin}
  //       recordAttempt={recordAttempt}
  //       setRecordAttempt={setRecordAttempt}
  //       lmsAttempt={lmsAttempt}
  //       setLMSAttempt={setLMSAttempt}
  //       maxAttempts={maxAttempts}
  //       setMaxAttempts={setMaxAttempts}
  //     />
  //   );
  // }, [questions, setQuestions, currQueDisplay, setRightSidebarContent, pollType, showAnswerOnViewer, setShowAnswerOnViewer, anonymousJoin, setAnonymousJoin, recordAttempt, setRecordAttempt, lmsAttempt, setLMSAttempt, maxAttempts, setMaxAttempts]);

  const resetPoll = (newPollType) => {
    history.push({ pathname: "/create", state : { pollType : newPollType, fromEditQuizPage : true }});
  }

  const handlePollTypeChange = async (newPollType, isSave) => {
    await setShowAnswerOnViewer(false);
    await setAnonymousJoin(platformSetting != undefined && platformSetting.hasOwnProperty(PLATFORM_SETTINGS.ANONYMOUS_JOIN) ?  JSON.parse(platformSetting[PLATFORM_SETTINGS.ANONYMOUS_JOIN]): false);
    // if (await editPoll(false)) {
      setSaveExit(true); 
      resetPoll(newPollType);
      return true;
    // }
    // return false;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!title) {
        return
      }
      
      if ((title !== getPollTitle()) || (JSON.stringify(questions) !== JSON.stringify(getQues())) || (pollType !== getType())) {
        setIsSaving(true);
        editPoll(false);
        setPollTitle(title);
        setQues(questions);
        setType(pollType);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [title, questions, pollType]);

  useEffect(() => {
    if (isSaving) {
      const gifTimer = setTimeout(() => {
        setIsSaving(false);
      }, 5000);

      return () => clearTimeout(gifTimer);
    }
  }, [isSaving]);

  return (
      <>
      <YuJaGeneralAlert
            show={alertShow}
            setModalShow={setAlertShow}
            content={alertContent}
            config={alertConfig}
        />
        <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
          <div style={{backgroundColor: "#FCFBFF", padding: "2.063rem", paddingTop : "0px",overflowY: "hidden"}}>
            {questions &&
              <QuestionsMain
                title={title}
                isEditPage={true}
                setTitle={setTitle}
                questions={questions}
                setQuestions={setQuestions}
                savePoll={editPoll}
                savePollClose={editPollClose}
                institutionId={instId}
                pollKey={pollKey}
                currQueDisplay={currQueDisplay}
                changeCurrQueDisplay={changeCurrQueDisplay}
                pollType={pollType}
                isSaving={isSaving}
                disableSave={disableSave}
              />
            }
          </div>
        </Spin>
      </>
  );
}

