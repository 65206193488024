import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalFilter, useTable } from "react-table";
import { UserApiCalls } from "../../apis/UserApiCalls";
import Question_Bank_Title_Icon from "../../images/Question_Bank_Title.svg";
import {
    ACTIONS_LABEL,
    CREATE_QUESTION_PROFILE_MODAL_INPUT,
    DELETE_QUES_BANK,
    EDIT_QUES_BANK,
    MODAL_CANCEL_TEXT,
    MODAL_DELETE_TEXT,
    QUESTION_PROFILE_ACTION_ONE, QUESTION_PROFILE_ACTION_TWO,
    QUESTION_PROFILE_MESSAGE,
    QUESTION_PROFILE_TITLE,
    QUES_BANK_NAME,
    TEXTBOX,
    TOTAL_QUES_BANK
} from "../../utils/constants";
import QuestionProfileKobabMenu from "./QuestionProfileKobabMenu";
import styles from "./QuestionProfiles.module.css";
import YuJaGeneralAlert from "./YuJaGeneralAlert";
import {ReactComponent as NoResult} from "../../images/no_result.svg";
import {YuJaKebabMenu} from "../standardization/YuJaKebabMenu";

export default React.memo(QuestionProfileTable);
function QuestionProfileTable({ data = [], columns = [], pageIdx=0, getQuestionProfiles, keys, selectedQuestionProfile, setSelectedQuestionProfile, setIsImporting, setModalShow, handleKeyClick, globalFilterObj = {}}) {
    const history = useHistory();
    const { deleteQuestionProfile} = UserApiCalls(); 
    const [alertShow, setAlertShow] = useState(false); 
    const [actionshow, setActionShow] = useState(false); 

    const [selectedIndex, setSelectedIndex] = useState(null); 


    const filterCustomize = (rows, columnIds, globalFilterValue) => {
        if (Object.entries(globalFilterValue).length === 0) {
            return rows;
        }

        const filteredKeyword = globalFilterValue.keyword ? globalFilterValue.keyword.toLowerCase() : "";
        return rows.filter(r => {
            if (!filteredKeyword) {
                return true;
            }
            const values = r.values;
            if (values.ProfileTitle && values.ProfileTitle.toLowerCase().indexOf(filteredKeyword) > -1) {
                return true;
            }

            return false;
        });
    };


    useEffect(() => {
        setGlobalFilter(globalFilterObj);
    }, [data, globalFilterObj]); 


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows, 
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        globalFilter: filterCustomize,
    } , useGlobalFilter);

    function formatNumberWithLeadingZeros(number, desiredLength) {
        return number.toString().padStart(desiredLength, '0');
      }

      const onDelete = async (questionProfileId) => {
        setSelectedQuestionProfile(questionProfileId); 
        setAlertShow(true); 
        setActionShow(false);
    }

    const onEdit = (questionProfileId) => {
        setModalShow(false);
        history.push({ pathname: "/questions/edit/" + questionProfileId });
    }

    const alertConfig = {
        title: QUESTION_PROFILE_TITLE,
        okText: MODAL_DELETE_TEXT,
        cancelText: MODAL_CANCEL_TEXT, 
        submit: async () => {
          setAlertShow(false);
          const res = await deleteQuestionProfile(selectedQuestionProfile);
          if (res.success) {
            getQuestionProfiles();
            }
        },
        close:  async () => {
          setAlertShow(false);
        }, 
    };

    const closeAction = () => {
        if(actionshow) {
            setActionShow(false);
        }
    }


    return (
        <>
            <YuJaGeneralAlert
                show={alertShow}
                setModalShow={setAlertShow}
                content={QUESTION_PROFILE_MESSAGE}
                config={alertConfig}
                AlertType={MODAL_DELETE_TEXT}
            />
            <div className={styles.tableContainer} style={{border: "0.25px solid #646464", borderRadius: "5px", marginTop: "20px"}} onClick={closeAction}>
                <Table hover {...getTableProps({style: {minHeight: rows.length ===0 ? "100%" : "", marginBottom: "0px"}})}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th tabIndex={0} {...column.getHeaderProps({
                                        style: {
                                            width: column.width,
                                            minWidth: column.minWidth,
                                            ...(column.render('Header') === CREATE_QUESTION_PROFILE_MODAL_INPUT
                                            ? {
                                                  textAlign: "left", 
                                                  paddingLeft: "10px"
                                              }
                                            : {
                                              }),
                                        }
                                    })}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className={styles.tablebody} {...getTableBodyProps({style: {overflowY: "auto", height: "100%", marginBottom: "0px"}})}>
                        {rows.length!== 0 ? rows.map((row) => {
                            prepareRow(row)
                            // console.log(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    { row.cells.map((cell, index) => {
                                        if (cell.column.Header === 'Questions')  {
                                            return (
                                                <td tabIndex={0} aria-label={TOTAL_QUES_BANK + row.cells.at(index-1).value + " " + formatNumberWithLeadingZeros(cell.value.length, 2)} role={TEXTBOX} {...cell.getCellProps({style: {wordBreak: 'break-word', fontSize: "16px", fontFamily: "Geomanist", color: "#000000"}})}>
                                                    {formatNumberWithLeadingZeros(cell.value.length, 2)}
                                                </td>
                                            );
                                        } else if (cell.column.Header === 'Question Bank Name') {
                                            return (
                                                <td tabIndex={0} aria-label={QUES_BANK_NAME + cell.value} role={TEXTBOX} {...cell.getCellProps({style: {wordBreak: 'break-word', fontSize: "16px", fontFamily: "Geomanist", color: "#000000", textAlign: "left", cursor: "pointer"}} )} onClick={() => {setIsImporting(true); handleKeyClick(data[cell.row.index], cell.row.index);}} title={cell.value?.length > 30 ? cell.value : ""}>
                                                    <img
                                                        src={Question_Bank_Title_Icon}
                                                        alt="edit icon"
                                                        style={{ marginRight: "15px", padding: "0px", width: "24px", height: "24px", zIndex: "100" }}
                                                    />
                                                    {cell.value?.length > 30 ? `${cell.value.slice(0, 30)}...` : cell.value}
                                                </td>
                                            );
                                        }
                                        else if (cell.column.Header === "Action") {
                                            return (
                                            <td {...cell.getCellProps()} className={styles.tableBtnUnit} style={{position:"relative" }}>
                                                <div tabIndex={0} aria-label={ACTIONS_LABEL + row.cells.at(index-2).value} role={TEXTBOX} style={{display: "flex", justifyContent: "center"}}>
                                                    <YuJaKebabMenu>
                                                        <YuJaKebabMenu.Menu>
                                                            <YuJaKebabMenu.Item label={EDIT_QUES_BANK + row.cells.at(index-2).value} onClick={() => onEdit(keys[cell.row.index])}>{QUESTION_PROFILE_ACTION_ONE}</YuJaKebabMenu.Item>
                                                            <YuJaKebabMenu.Item label={DELETE_QUES_BANK + row.cells.at(index-2).value} onClick={() => onDelete(keys[cell.row.index])}>{QUESTION_PROFILE_ACTION_TWO}</YuJaKebabMenu.Item>
                                                        </YuJaKebabMenu.Menu>
                                                    </YuJaKebabMenu>
                                                </div>
                                            </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )
                        }
                        ) : <>
                        <td align="center" colspan="3" className={styles.tableNoPadding}> <div style={{width: "100%", height: "100%"}}> <NoResult/> <div style={{marginTop: "20px"}}>No Results Found</div> </div></td>
                    </>}
                    </tbody>
                    </Table>
            </div>
        </>
    );
}