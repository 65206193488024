import { useEffect, useState } from "react";
import { PublicApiCall } from "../apis/PublicApiCall";
import { useLocalStorageNew } from "./useLocalStorageNew";

export default function useHostname(hostname) {
    const[setHostResource, getHostResource] = useLocalStorageNew(hostname, {});
    const {resolveShortName} = PublicApiCall();
    const [resource, setResource] = useState(getHostResource);

    useEffect(() => {
        let hostResource = getHostResource();
        // const {institutionId, bgImgLocation, ssoConfig, platformSetting} = hostResource;
        // if (institutionId && bgImgLocation && ssoConfig && platformSetting) {
        //     return;
        // }

        let shortName = '';
        if (hostname.includes("engage.yuja.com") && hostname.includes("www")) {
            shortName = hostname.indexOf('.') >= 0 ?
                hostname.substring(hostname.indexOf('.') + 1, hostname.indexOf("engage.yuja.com") - 1) :
                hostname;
        } else {
            shortName = hostname.indexOf('.') >= 0 ?
                hostname.substring(0, hostname.indexOf('.')) :
                hostname;
        }

        resolveShortName(shortName).then(res => {
            hostResource = getHostResource();
            hostResource.institutionId = res.institutionId;
            hostResource.bgImgLocation = res.bgImgLocation;
            hostResource.ssoConfig = res.ssoConfig;
            hostResource.platformSetting = res.platformSetting; 
            setHostResource(hostResource);
            setResource(hostResource);
        });

    }, [hostname])

    return resource;
}