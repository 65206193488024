import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import { Radio } from "../../components/radio/Radio";
import CancelIcon from "../../images/cancel.svg";
import DeleteImageIcon from "../../images/delete_uploaded_image.svg";
import DragDropIcon from "../../images/drag_drop.svg";
import ReuploadImageIcon from "../../images/reupload_image.svg";
import { addOptionToQuestion } from "../../utils/questionUtils";
import { ADD_OPTIONS_ERROR } from "../../utils/toast-message-constants";
import {
  updateAnswerImage, updateAnswers, updateCorrectAnswers
} from "../../utils/questionUpdate";
import {MC_ANSWER_LENGTH, SURVEY_TEXT, POLL_TYPE, TEXT_BOX, CREATE_OPT_RADIAL, DELETE_OPT_BTN, TEXTBOX} from "../../utils/constants";
import "../styles.css";
import { notifyError } from "../../utils/helpers";
import { MC_CI_ANSWER_LENGTH_EXCEEDED } from "../../utils/toast-message-constants";
import {YuJaTextBox} from "../../components/standardization/YuJaTextBox";

export default function MCQuestionOption({
  optionData,
  selectedQuestion,
  questions,
  setQuestions,
  index,
  pollType,
  maxSize
}) {
  const [showDragIcon, setShowDragIcon] = useState(false);
  const { choice, optionValue } = optionData;
  const { serialNo, optionsMap, correctAnswers } = selectedQuestion;
  const uploadImageId = "file-input-" + String(serialNo) + "-" + choice;
  const selectedQuestionIndex = serialNo - 1;
  const selectedImage = selectedQuestion["image" + choice];

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        397,
        243,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        397,
        243
      );
    });

  const handleAnswerChange = (input, itemKey) => {
    setQuestions(
      updateAnswers(input, itemKey, selectedQuestionIndex, questions)
    );
  };

  const handleAnswerImageChange = (file, itemKey) => {
    setQuestions(
      updateAnswerImage(file, itemKey, selectedQuestionIndex, questions)
    );
  };

  const resizeAndHandleImageChange = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file);
    handleAnswerImageChange(image, choice);
  };

  const handleCorrectAnswerChange = (input) => {
    setQuestions(updateCorrectAnswers(input, selectedQuestionIndex, questions));
  };

  const handleDeleteOption = () => {
    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let newOptionsMap = {};
        Object.entries(q.optionsMap).forEach(([key, value]) => {
          if (key !== choice) {
            if (key > choice) {
              const newKey = String.fromCharCode(key.charCodeAt(0) - 1);
              newOptionsMap[newKey] = value;
            } else {
              newOptionsMap[key] = value;
            }
          }
        });
        let updatedQuestion = {
          ...q,
          optionsMap: newOptionsMap,
          correctAnswers:
            choice === q.correctAnswers[0] ? [] : q.correctAnswers,
        };
        updatedQuestion["image" + choice] = null;
        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push({ ...q });
      }
    });
    setQuestions(newQuestions);
  };

  const handlePaste = (e) => {
    if(e.target.value.length <= MC_ANSWER_LENGTH) {
      if (index + 1 === maxSize) {
        const newQuestions = addOptionToQuestion(
          selectedQuestion,
          questions
        );
        if (newQuestions) {
          setQuestions(newQuestions);
        } else {
          notifyError(ADD_OPTIONS_ERROR);
        }
      }
    }
    else {
      notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
    }
  }

  useEffect(() => {
    console.log(correctAnswers);
  }, correctAnswers)

  return (
    <Draggable draggableId={choice} index={index} key={choice}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}>
          <div
              style={{display: "flex", flex: 1, alignItems: "center", marginBottom: 25, paddingTop: "5px"}}
              onMouseEnter={() => setShowDragIcon(true)}
              onMouseLeave={() => setShowDragIcon(false)}
          >
            <div
              {...provided.dragHandleProps}
              style={{width: 40,  display: "flex", justifyContent: "center", padding: "0"}}
              title={"Drag to Reorder"}
              tabIndex={-1}
            >
              {correctAnswers[0] === choice && pollType !== SURVEY_TEXT && <img src={DragDropIcon} alt="drag drop icon" />}
            </div>
            <Radio
              ariaLabel={CREATE_OPT_RADIAL + choice.toUpperCase()}
              text={choice.toUpperCase()}
              value={choice}
              //currently only accept radio buttons
              selected={correctAnswers[0]}
              onChange={(value) => {
                handleCorrectAnswerChange(value);
              }}
              index={index}
              pollType={pollType}
              transform={'translateX(70px)'}
            />
            {!!pollType && pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
            <YuJaTextBox
              label={TEXT_BOX + choice.toUpperCase() +  " " + (choice === correctAnswers[0] ? "selected" : "unselected")}
              containerStyle={{ width: "100%", fontSize: 20, marginLeft: 25, padding: "12.5px 12.5px 12.5px 55px", border: "1.13px solid #858687"}}
              placeholder={`Type Option ${choice.toUpperCase()}`}
              value={optionValue}
              name={"question-text"}
              maxLength={MC_ANSWER_LENGTH}
              onPaste={(e) => {handlePaste(e)}}
              onChange={(e) => {
                e.preventDefault();
                if(e.target.value.length <= MC_ANSWER_LENGTH) {
                  handleAnswerChange(e.target.value, choice);
                  if (index + 1 === maxSize) {
                    const newQuestions = addOptionToQuestion(
                      selectedQuestion,
                      questions
                    );
                    if (newQuestions) {
                      setQuestions(newQuestions);
                    } else {
                      notifyError(ADD_OPTIONS_ERROR);
                    }
                  }
                }
                else {
                  // let index = 0;
                  // switch(choice) {
                  //   case "a":
                  //     index = 1;
                  //     break;
                  //   case "b":
                  //     index = 2;
                  //     break;
                  //   case "c":
                  //     index = 3;
                  //     break;
                  //   case "d":
                  //     index = 4;
                  // }
                  notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                  // handleAnswerChange(e.target.value.substring(0, MC_ANSWER_LENGTH), choice);
                }
              }}
            /> }
            {!!pollType && pollType.toUpperCase() === POLL_TYPE.SURVEY && <YuJaTextBox
                label={TEXT_BOX + choice.toUpperCase()}
                containerStyle={{ width: "100%", fontSize: 20, marginLeft: 25, padding: "12.5px", border: "1.13px solid #858687"}}
                placeholder={`Type Option ${choice.toUpperCase()}`}
                value={optionValue}
                name={"question-text"}
                maxLength={MC_ANSWER_LENGTH}
                onPaste={(e) => {handlePaste(e)}}
                onChange={(e) => {
                  e.preventDefault();
                  if(e.target.value.length <= MC_ANSWER_LENGTH) {
                    handleAnswerChange(e.target.value, choice);
                    if (index + 1 === maxSize) {
                      const newQuestions = addOptionToQuestion(
                        selectedQuestion,
                        questions
                      );
                      if (newQuestions) {
                        setQuestions(newQuestions);
                      } else {
                        notifyError(ADD_OPTIONS_ERROR);
                      }
                    }
                  }
                  else {
                    notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);
                  }
                }}
              />
            }
            <div style={{width : 30, marginLeft: "10px", marginRight: "10px"}}>
              {Object.keys(optionsMap).length > 1 && (
                  <img
                    aria-label={DELETE_OPT_BTN + choice.toUpperCase() +  " button " + (choice === correctAnswers[0] ? "selected" : "unselected")}
                    tabIndex={0}
                    role={TEXTBOX}
                      onClick={() => {
                        handleDeleteOption();
                      }}
                      src={CancelIcon}
                      alt="delete icon"
                      title={"Delete Option"}
                      className="cursor-pointer"
                  />
              )}
            </div>

          </div>

          {selectedImage && (
            <Row>


              <Col xs={9} className="margin-vertical-auto">
                <div>
                  <div style={{ position: "relative" }} className="float-left">
                    <img alt="uploaded" src={selectedImage} />

                    <label htmlFor={uploadImageId + "2"}>
                      <img
                        src={ReuploadImageIcon}
                        alt="re-upload icon"
                        className="questions-page-icons cursor-pointer"
                        style={{
                          width: "32.98px",
                          position: "absolute",
                          right: "60px",
                          bottom: "10px",
                        }}
                      />
                    </label>
                    <input
                      id={uploadImageId + "2"}
                      type="file"
                      value=""
                      onChange={async (event) =>
                        resizeAndHandleImageChange(event)
                      }
                    />

                    <img
                      onClick={() => {handleAnswerImageChange(null, choice);}}
                      src={DeleteImageIcon}
                      alt="delete updated icon"
                      className="questions-page-icons cursor-pointer"
                      style={{
                        width: "34.98px",
                        position: "absolute",
                        right: "10px",
                        bottom: "8.5px",
                      }}
                    />
                  </div>

                  <div className="float-left">
                    <img
                      onClick={() => {
                        handleAnswerImageChange(null, choice);
                      }}
                      src={CancelIcon}
                      alt="delete icon"
                      className="questions-page-icons cursor-pointer no-margin"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
    </Draggable>
  );
}
