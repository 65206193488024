import "bootstrap/dist/css/bootstrap.css";
import React, {useContext, useEffect, useState} from "react";
import { Col, Row } from "react-bootstrap";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
// import useMobileAccess from "../../hooks/useMobileAccess";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import NormalBox from "../../public/boxes/NormalBox";
import {useLoading, YuJaGlobalState} from "../../utils/LoadingContext";
import { BLANK_REGEX, COMPLETION_RATE_TEXT, CORRECT_ANSWER_TEXT, FIXED_ANSWER, PARTICIPANT_TEXT, POLL_TYPE, QUESTION_LABEL, QUESTION_TITLE, QUESTION_TYPES, RESPONSE_TEXT, RESULT_CONTAINER_KEY_PREFIX, SURVEY_RESULT_TEXT, TEXTBOX, UNANSWERED, UNAVAILABLE_TEXT, WORD_CLOUD_DATA, WRONG_ANSWER_TEXT } from "../../utils/constants";
import { fetchImg, hasWC, newPageLog, removePrefix } from "../../utils/helpers";
import { S3_PRIVATE_BUCKET_API_URL, S3_PRIVATE_BUCKET_NAME } from "../../utils/properties";
import ClassResultChart from "./ClassResultChart";
import WordCloudResult from "./WordCloudResult";
import "./styles.css";
import styles from "./styles.module.css";
import NetworkStatus from "./NetworkStatus";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { ReactComponent as ParticipantsIcon } from "../../images/participants_icon.svg";
import { ReactComponent as ResponsesIcon } from "../../images/responses-icon.svg";
import { ReactComponent as CorrectIcon } from "../../images/correct-answer-icon.svg";
import { ReactComponent as WrongIcon } from "../../images/wrong-answer-icon.svg";
import { ReactComponent as CompletionRateIcon } from "../../images/completion-rate-icon.svg";
import { questionCodeToName } from "../../utils/questionUtils";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import useMobileAccess from "../../hooks/useMobileAccess";

export default function ClassResultAllSurvey({currentPoll, institutionId, websocketState, pollTitle=""}) {
  // const isMobile = useMobileAccess(1200);
  const {questions, rawQueResponses=[]} = currentPoll;
  const pollKey = removePrefix(questions[0].pollKey);
  const {getClassResult, getAllClassResult, getStudentResultByPoll} = ResponseApiCalls();
  const {setLoading} = useLoading();
  const [questionsData, setQuestionsData] = useState([]);
  const [questionResults, setQuestionResults] = useState({});
  const [setWordCloudData, ] = useLocalStorageNew(WORD_CLOUD_DATA, {});
  const {isOnline} = useContext(YuJaGlobalState);
  const [selectedQuestion, setSelectedQuestion] = useState(questions[0]);
  const [participantCount, setParticipantCount] = useState("-"); 
  const [responsesCount, setResponsesCount] = useState("-");
  const [completionRate, setCompletionRate] = useState("-"); 
  const [questionData, setQuestionData ] = useState(""); 

  const isNarrow = useMobileAccess(1200);
  const isMobile = useMobileAccess(480); 


  const [isSwitching, setIsSWitching] = useState(false);
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    document.getElementById('root').style.backgroundColor = "#FCFBFF";
    newPageLog("ClassResultAllSurvey.js");
    if(document.getElementById("viewer-navbar") !== null) {
      document.getElementById("viewer-navbar").style.backgroundColor = "#E6F2F7";
    }
  }, []);

  useEffect(() => {
    if (hasWC(questions)) setWordCloudData({});
  }, [questions]);

  function removeUnanswered(result) {
    let newResult = [];
    if (result == null) {
      return newResult;
    }
    result.forEach((r) => {
        if (r.ans !== UNANSWERED) {
            newResult.push({...r});
        }
    });
    return newResult;
  };

  async function getQuestionsData() {
    setLoading(true);
    const results = await getAllClassResult(pollKey,  questions[0].pollSortKey.split("#")[2], questions);
    let tempQuestionsData = [];
    for (const result of results) { 
      let serialNo = result.serialNo; 
      const question = questions.find(({serialNo: no}) => parseInt(no) === parseInt(serialNo));
      let CA;
      if (question.questionType === "MCSS") {
        CA = question.correctAnswers[0];
      } else if (question.questionType === "TF") {
        CA = question.correctAnswers;
      } else {
        CA = null;
      }
      let questionType = question.questionType;
      let queTitle = question.queTitle;
      let MCSSelements = [];
      if (questionType === "MCSS") {
        for (let key in question.optionsMap) {
          MCSSelements.push(question.optionsMap[key]);
        }
      }
      let MCSSnum = MCSSelements.length;
      let suffix = question.suffix;
      let image = question.image;
      let questionImageURL;
      let isVideo = false;
      if(question.directLinkEvp) {
        questionImageURL = question.directLinkEvp;
        isVideo = true;
      } else if ((!!image || !!suffix) && institutionId && pollKey && serialNo) {
        let url = `${S3_PRIVATE_BUCKET_API_URL}/s3?key=${S3_PRIVATE_BUCKET_NAME}/poll/${institutionId}/${pollKey}`;

        url = !!image ? `${url}/${image}` : `${url}/temp-${serialNo}${suffix}`;
        let img = await fetchImg(url);
        questionImageURL = img ? img : "";
      }

      let questionData = {
        correctAnswers: CA,
        questionType: questionType,
        queTitle: queTitle,
        questionResults: result.questionResults,
        serialNo: serialNo,
        MCSSnum: MCSSnum,
        questionImageURL: questionImageURL,
        isVideo: isVideo,
        rawResponse: result.rawResponse
      };
      tempQuestionsData.push(questionData); 
    }

    tempQuestionsData.forEach(item => {
      item.unansweredCount = getUnanswered(item.questionResults);
    });

    tempQuestionsData.forEach(item => {
      item.questionResults = removeUnanswered(item.questionResults);
    });

    setQuestionsData(tempQuestionsData);
    setDataReady(true);
    setLoading(false);
  }

  useEffect(() => {
    if (!isOnline || websocketState !== WebSocket.OPEN) {
      return;
    }

    // async function setResults() {
    //   let questionResultCopy = {};
    //   let promises = questions.map((question, index) => {
    //     console.log(question.questionType);
    //     if (question.questionType === QUESTION_TYPES.SA.name || question.questionType === QUESTION_TYPES.FITB.name || question.questionType === QUESTION_TYPES.WC.name) {
    //       return getClassResult(pollKey, question.pollSortKey.split("#")[2], question, null, true).then((result) => {
    //         if (result.length > 0) {
    //           questionResultCopy[index] = result;
    //         }
    //       });
    //     }
    //   })
    //   await Promise.all(promises);
    //   setQuestionResults(questionResultCopy);
    // }
    setTimeout(() => {
      getQuestionsData();
    }, 3000);
    
    const interval = setInterval(() => {
      getQuestionsData();
    }, 10000);

    return () => clearInterval(interval);
  }, [questions, isOnline, websocketState]);

  useEffect(() => {
    console.log(questionResults);
  }, [questionResults]);


  const getUnanswered = (result) => {
    if (result == null) {
      return 0;
    }

    let unansweredCount = 0; 
    result.forEach((r) => {
        if (r.ans === UNANSWERED) {
            unansweredCount = r.people;
        }
    });
    return unansweredCount;

  }
  useEffect(() => {
    if (questionsData && questionsData.length !== 0 ) {
      let serialNo = selectedQuestion.serialNo
      const questiondata = questionsData.find(({ serialNo: no }) => parseInt(no) === parseInt(serialNo));
      setQuestionData(questiondata); 
      let overall_count = questiondata.questionResults[0].total;
      setParticipantCount(overall_count);
      let count = questiondata.questionResults[0].total -  questiondata.unansweredCount;
      setResponsesCount(count);
      setCompletionRate(Math.round(count/overall_count * 100))
  }
  }, [selectedQuestion, questionsData])


  const removeSelected = (data, target, questionType) => {
    if (questionType === QUESTION_TYPES.SA.name && data) {
    const indexToRemove = data.findIndex(item => item['0'] === target);
    if (indexToRemove !== -1) {
        const filteredList = [
          ...data.slice(0, indexToRemove),
          ...data.slice(indexToRemove + 1),
        ];
  
        return filteredList
      }
    }
    else if (questionType === QUESTION_TYPES.FITB.name && data) {
        const indexToRemove = data.findIndex(item =>
            Object.entries(item).every(([key, value]) => target[key] === value)
          );
      
          if (indexToRemove !== -1) {
            const filteredList = [
                ...data.slice(0, indexToRemove),
                ...data.slice(indexToRemove + 1),
              ];
        return filteredList
    }
  }
}

useEffect(() => {
  setIsSWitching(true);
  getQuestionsData();
}, [window.innerWidth])

  return (
    <div id="everything" className={styles.centerLayout}>
      {/* <NormalBox
        boxType="lightBlue"
        className={`${styles.shadow} ${styles.centerLayout}`}
      > */}
        <div style={{display: "flex", width: "100%"}}>
          <div style={{flex: 1}}/>
          <div className="waiting-room-title-container" style={{height: "fit-content" , marginTop: "32px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", justifyContent: "space-between"}}> 
            <span role={TEXTBOX} tabIndex={0} className="waiting-room-title" style={{wordBreak:"break-all", paddingLeft: !isNarrow? "170px" : "48px", textAlign: "center", flex: "1", color: "#000", fontSize: "24px"}}>{SURVEY_RESULT_TEXT + pollTitle}</span>
            <div style={{display: "flex", justifyContent: "end", paddingRight: "20px"}}>  <NetworkStatus websocketState={websocketState}/> </div>
          </div>
          {/* <div className="class-res" style={{flex: 1}}>Class Results</div>
          <div style={{flex: 1, display:"flex", alignItems: "center", justifyContent: "flex-end"}}>
            <NetworkStatus tooltipPlacement={'bottom'} websocketState={websocketState}/>
          </div> */}

        </div>

        <div style={{display: "flex", width: "calc(100% - 36px)", marginLeft: "18px", gap: isMobile? "8px" : "25px", marginRight: "18px", marginTop: "28px", flexDirection: isMobile? "column" : ""}}> 
          <div role={TEXTBOX} tabIndex={0} style={{color: "#000", fontFamily: "Geomanist", fontSize:  isMobile? "14px" : "18px", alignItems: "center", display: "flex"}}> Choose Question </div>
          <YuJaDropdown
                      containerStyle={{width: !isMobile? "40%": "100%"}}
                      data={questions}
                      value={selectedQuestion ? "Question " + selectedQuestion.serialNo + ": " + selectedQuestion.queTitle : ""}
                      getOptionLabel={item => "Question " + item.serialNo + ": " + item.queTitle}
                      getOptionValue={option => "Question " + option.serialNo + ": " + option.queTitle}
                      textStyle={{overflow: "hidden", color: "#000", fontFamily: "Geomanist", fontSize: isMobile? "14px" : "18px", letterSpacing: "0.63px", whiteSpace: "nowrap", textOverflow: 'ellipsis', textWrap: "nowrap", height: "20px", maxWidth: "100%", paddingTop: isMobile? "2px" : ""}}
                      onChange={(item) => {
                        setIsSWitching(true);
                        getQuestionsData();
                        setSelectedQuestion(item);
                      }}

                      style={{height: isMobile? "50px" : "40px"}}
                    />
          </div>
        

          {!isMobile &&
        <div className="class-result-survey-container" style={{marginLeft: "18px", width: "100%"}}> 
        
        <div style={{display: "flex", justifyContent:  "center"}}> 
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
            <div style={{width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <ParticipantsIcon />
            </div> 
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {PARTICIPANT_TEXT} </div> 
            </div>
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> { participantCount} </div>
            </div>
        </div>
        </div> 

        <div style={{display: "flex", justifyContent:  "center"}}> 
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
            <div style={{width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <ResponsesIcon />
            </div> 
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {RESPONSE_TEXT} </div> 
            </div>
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> { responsesCount } </div>
            </div>
        </div>
        </div> 
        
        <div style={{display: "flex", justifyContent:  "center"}}> 
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
        <CompletionRateIcon />
        <div role={TEXTBOX} tabIndex={0} style={{fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {COMPLETION_RATE_TEXT} </div> 
            </div>
            <div role={TEXTBOX} tabIndex={0} style={{fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> {completionRate}{completionRate !== "-" ? "%" : ""} </div>
            </div>
        </div>
        </div>

        {/* <div style={{display: "flex", justifyContent:  "center"}}>
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
              <CorrectIcon />
            <div style={{fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {CORRECT_ANSWER_TEXT} </div> 
            </div>
            <div style={{fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> {UNAVAILABLE_TEXT} </div>
            </div>
        </div>
        </div>

        <div style={{display: "flex", justifyContent:  "center"}}>
        <div style={{width: "275px", height: "90px", borderRadius: "19.565px", border: "1px solid #969696", display: "flex", background: "#FFF"}}> 
            <div style={{display: "flex", flexDirection: "column", padding: "9px 31px 10px 32px", gap: "15px"}}> 
            <div style={{display: "flex", gap: "10px"}}> 
            <div style={{width: "24px", height: "24px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <WrongIcon />
            </div> 
            <div style={{fontSize: "18px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px"}}> {WRONG_ANSWER_TEXT} </div> 
            </div>
            <div style={{fontSize: "24px", fontFamily: "Geomanist", color: "#000", lineHeight: "31.304px", fontWeight: "700"}}> {UNAVAILABLE_TEXT} </div>
            </div>
        </div>
        </div> */}

        

        </div>

        }

              {
                    isMobile && 
                    <div style={{width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", borderRadius: "19.565px", border: "1px solid #969696", background: "#fff", height: "fit-content", marginTop: "15px", padding: "10px"
                    }}> 
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", rowGap: '10px', columnGap: "10px"}}>

                                <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%"}}>
                                    <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ParticipantsIcon />
                                    </div>
                                    <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px" }}> {PARTICIPANT_TEXT} </div>
                                    <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {participantCount} </div>
                                </div>
                            <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%"}}>
                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ResponsesIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {RESPONSE_TEXT} </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {responsesCount} </div>
                            </div>

                            {/* <div style={{ display: "flex", alignItems: "center", width: window.innerWidth <= 400 ? "100%" : "50%"}}>

                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CorrectIcon />
                                </div>
                                <div style={{ fontSize: "14px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px", marginLeft: "2px"}}> {CORRECT_ANSWER_TEXT} </div>
                                <div style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px"}}> {UNAVAILABLE_TEXT} </div>
                            </div>

                            <div style={{ display: "flex", alignItems: "center", width: window.innerWidth <= 400 ? "100%" : "50%"}}>

                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <WrongIcon />
                                </div>
                                <div style={{ fontSize: "14px", fontFamily: "Geomanist", color: "#000", lineHeight: "23.478px", letterSpacing: "-0.28px", marginLeft: "2px"}}> {WRONG_ANSWER_TEXT} </div>
                                <div style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px"}}> {UNAVAILABLE_TEXT} </div>
                            </div> */}

                            <div style={{ display: "flex", alignItems: "center", flex: "1", minWidth: "45%", maxWidth: "50%"}}>
                                <div style={{ width: "20px", height: "20px", borderRadius: "12px", backgroundColor: "#B3D2D9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CompletionRateIcon />
                                </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "13px", fontFamily: "Geomanist", color: "#000", letterSpacing: "-0.28px", marginLeft: "2px"}}> {COMPLETION_RATE_TEXT} </div>
                                <div role={TEXTBOX} tabIndex={0} style={{ fontSize: "15px", fontFamily: "Geomanist", color: "#000", fontWeight: "700", marginLeft: "5px", flex: "1", display: "flex", justifyContent: "end"}}> {completionRate} {completionRate !== "-" ? "%" : ""} </div>
                            </div>
                    </div>

                    </div> 
                }

          {
            isMobile && 

            <div style={{display: "flex", flexDirection: "column", marginTop: "16px", width: "calc(100% - 36px)", marginLeft: "18px", marginRight: "18px", gap: "15px"}}> 
                    <span className="gradientWrapQuesType" style={{ height: isNarrow ? "23px" : "45px", width: isNarrow ? "109px" : "150px", marginLeft: "0px"}}>
                                <span className="questionPanelQuestionTypeContainer" style={{ width: isNarrow ? "107px" : "", padding: "0px", height: isNarrow ? "20px" : "", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div className="questionPanelQuestionType" style={{ fontSize: "12px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height: isNarrow? "12px" : ""}}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                </span>
                            </span>

                            <div className="cur-question-title" style={{fontSize: isMobile ? "14px" : "18px", height: 'fit-content', wordBreak: "break-all"}}>Question {selectedQuestion.serialNo}  {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name ? " - " + selectedQuestion.queTitle : ""} </div> 


                            {(questionData.questionType === QUESTION_TYPES.SA.name) ? 
                <Row style={{marginTop: "25px"}}>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                      <div className="content">
                      {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                        <div style={{display: "flex"}}> 
                          <Col style={{height:"300px", overflowY: "auto"}}>
                          {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                    <>
                                    <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#C4BDD2",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                        {`"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                        </div> 
                                    
                                    {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            tabIndex={0} 
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#CACACA",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                    </> 
                                 }

                                 { rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                  <>{questionData.rawResponse?.map((item) =>
                                    item !== "unanswered" ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px"
                                        }}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )}</>
                                 }
                          </Col>
                          </div> 
                      </div>
                  </Col>
                </Row>
          : (questionData.questionType === QUESTION_TYPES.FITB.name)  ?
          <>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                      <div className="content">
                        <Row> 
                        <div className="ques-desc">
                            {questionData.queTitle.replace(BLANK_REGEX, "______")}
                          </div>
                        </Row>

                        {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                        <div style={{display: "flex"}}> 
                            <Col style={{height:"300px", overflowY: "auto", maxWidth: questionData.questionImageURL? "50%" : ""}}>
                            {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                <>
                                 <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#C4BDD2",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(rawQueResponses[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                </div>
                                <div>
                                {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>

                                </>
                                    }

                                    {rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                        <>{questionData.rawResponse?.map((item) =>
                                        item !== "unanswered"? (
                                           <div
                                               className={`questionPanelAnswersText resultAnimation`}
                                               style={{
                                                   marginTop: "20px",
                                                   backgroundColor: "#CACACA",
                                                   color: "#000", borderRadius: "10px",
                                                   width: "fit-content",
                                                   padding: "15px",
                                               }}
                                           >
                                               {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                           </div>
                                       ) : <></> )} </>
                                    
                                    }
                            </Col>
                        </div>
                      </div>
                  </Col>
                </Row>
                </>
          : 
             
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside">
                      <div className="content">
                        {questionData.questionImageURL && (
                            <div style={{display: 'flex',  justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                          {dataReady &&
                          <div key={RESULT_CONTAINER_KEY_PREFIX + questionData.serialNo}>
                            {questionData.questionType === QUESTION_TYPES.WC.name ?
                              <WordCloudResult
                                data={questionData.rawResponse}
                                serialNo={questionData.serialNo}
                                isSwitching={isSwitching}
                                setIsSWitching={setIsSWitching}
                              /> :
                              <ClassResultChart
                                correctAnswers={questionData.correctAnswers}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                pollType={POLL_TYPE.SURVEY}
                                queResponse={rawQueResponses[selectedQuestion.serialNo - 1]}
                              />
                            }
                          </div>
                          }
                        </div>
                      </div>
                  </Col>
                </Row>
          }

            </div> 

          }
         {!isMobile &&
        <div style={{width: "calc(100% - 36px)",  marginLeft: "18px", marginRight: "18px", borderRadius: "8px", background: "#FFF", boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)", marginTop: "30px", minHeight: "385px", padding: "22px"}}> 
        <div className="header-board" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                      <div role={TEXTBOX} tabIndex={0} className="cur-question-title" style={{fontSize: "18px"}}>Question {selectedQuestion.serialNo}</div>
                      {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name && (
                        <>
                         <ArrowRight />
                        <p role={TEXTBOX} tabIndex={0} aria-label={QUESTION_TITLE + selectedQuestion.queTitle} className="ques-desc" style={{ lineHeight: "normal", color: "#000", order: "unset", marginTop: "0px", maxWidth: "70%"}}>
                          {selectedQuestion.queTitle}
                        </p>
                        </>
                    )}
                      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1"}}> 
                      <span className="gradientWrapQuesType" style={{height: "45px", width: "150px"}}>
                                    <span className="questionPanelQuestionTypeContainer" style={{padding: "0px"}}>
                                        <div role={TEXTBOX} tabIndex={0} className="questionPanelQuestionType" style={{fontSize: "14px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center"}}> {questionCodeToName(selectedQuestion.questionType)} </div>
                                    </span>
                      </span>
                      </div>
        </div>

         {(questionData.questionType === QUESTION_TYPES.SA.name) ? 
                <Row style={{marginTop: "25px"}}>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                      <div className="content">
                        <div style={{display: "flex"}}> 
                          <Col style={{height:"300px", overflowY: "auto", maxWidth: "50%"}}>
                          {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                    <>
                                    <div
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#C4BDD2",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                        {`"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"]}"`}
                                        </div> 
                                    
                                    {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption["0"], questionData.questionType)?.map((item) =>
                                        item !== "unanswered" ? (
                                        <div
                                            tabIndex={0} 
                                            className= {`questionPanelAnswersText resultAnimation`}
                                            style={{
                                                marginTop: "20px",
                                                backgroundColor: "#CACACA",
                                                color: "#000", borderRadius: "10px",
                                                width: "fit-content",
                                                padding: "15px"
                                            }}
                                        >
                                            {`"${item["0"]}"`}
                                        </div>
                                        ) : <></>
                                    )}
                                    </> 
                                 }

                                 { rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                  <>{questionData.rawResponse?.map((item) =>
                                    item !== "unanswered" ? (
                                    <div
                                        className= {`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px"
                                        }}
                                    >
                                        {`"${item["0"]}"`}
                                    </div>
                                    ) : <></>
                                )}</>
                                 }
                          </Col>

                          {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                          </div> 
                      </div>
                  </Col>
                </Row>
          : (questionData.questionType === QUESTION_TYPES.FITB.name)  ?
          <>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                      <div className="content">
                        <Row> 
                        <div className="ques-desc" tabIndex={0} role={TEXTBOX} aria-label={questionData.queTitle}>
                            {questionData.queTitle.replace(BLANK_REGEX, "______")}
                          </div>
                        </Row>
                        <div style={{display: "flex"}}> 
                            <Col style={{height:"300px", overflowY: "auto", maxWidth: "50%"}}>
                            {dataReady && rawQueResponses && rawQueResponses[selectedQuestion.serialNo - 1] && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption && rawQueResponses[selectedQuestion.serialNo - 1].selectedOption !=="unanswered" &&
                                <>
                                 <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#C4BDD2",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(rawQueResponses[selectedQuestion.serialNo - 1].selectedOption).map((key) => `"${rawQueResponses[selectedQuestion.serialNo - 1].selectedOption[key]}"`).join(", ")}
                                </div>
                                <div>
                                {removeSelected(questionData.rawResponse, rawQueResponses[selectedQuestion.serialNo - 1].selectedOption, questionData.questionType)?.map((item) =>
                                 item !== "unanswered"? (
                                    <div
                                        className={`questionPanelAnswersText resultAnimation`}
                                        style={{
                                            marginTop: "20px",
                                            backgroundColor: "#CACACA",
                                            color: "#000", borderRadius: "10px",
                                            width: "fit-content",
                                            padding: "15px",
                                        }}
                                        tabIndex={0}
                                    >
                                        {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                    </div>
                                ) : <></> )}
                                </div>

                                </>
                                    }

                                    {rawQueResponses && (!rawQueResponses[selectedQuestion.serialNo - 1] || !rawQueResponses[selectedQuestion.serialNo - 1].selectedOption || rawQueResponses[selectedQuestion.serialNo - 1].selectedOption ==="unanswered") && 
                                        <>{questionData.rawResponse?.map((item) =>
                                        item !== "unanswered"? (
                                           <div
                                               className={`questionPanelAnswersText resultAnimation`}
                                               style={{
                                                   marginTop: "20px",
                                                   backgroundColor: "#CACACA",
                                                   color: "#000", borderRadius: "10px",
                                                   width: "fit-content",
                                                   padding: "15px",
                                               }}
                                           >
                                               {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                           </div>
                                       ) : <></> )} </>
                                    
                                    }
                            </Col>
                        {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                        </div>
                      </div>
                  </Col>
                </Row>
                </>
          : 
             
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside">
                      <div className="content">
                        <div style={{display: "flex"}}>
                        {dataReady &&
                          <div key={RESULT_CONTAINER_KEY_PREFIX + questionData.serialNo} style={{width: "50%"}}>
                            {questionData.questionType === QUESTION_TYPES.WC.name ?
                              <WordCloudResult
                                data={questionData.rawResponse}
                                serialNo={questionData.serialNo}
                                isSwitching={isSwitching}
                                setIsSWitching={setIsSWitching}
                              /> :
                              <ClassResultChart
                                correctAnswers={questionData.correctAnswers}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                pollType={POLL_TYPE.SURVEY}
                                queResponse={rawQueResponses[selectedQuestion.serialNo - 1]}
                              />
                            }
                          </div>
                          }
                          {questionData.questionImageURL && (
                            <div style={{display: 'flex',  justifyContent: 'center', flex: "1", padding: "25px"}}>
                              {!questionData.isVideo && <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`}/>}
                              {questionData.isVideo && <iframe allowFullScreen className="img-class-result" src={questionData.questionImageURL} alt="Question"/>}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
          }
        </div>
        }

         
        {/* {questionsData.map((questionData, index) => {
          if (questionData.questionType === QUESTION_TYPES.SA.name) {
            return (
              <NormalBox boxType="light" className={`${styles.resultsContainer}`} key={index}>
                <Row>
                  <div className="header-board">
                    <p className="ques-name">
                      Question {questionData.serialNo}{" "}
                    </p>
                  </div>
                </Row>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside box-bord-round">
                      <div className="content">
                        <Row> 
                        <div className="ques-desc">
                            {questionData.queTitle}
                          </div>
                        </Row>
                        <Row>
                          {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} />
                            </div>
                          )}
                        </Row>
                        <Row>
                          <Col style={{height:"300px", overflowY: "auto"}}>
                            {questionResults[index]?.map((item) =>
                              item !== FIXED_ANSWER.UNANSWERED ? (
                                <div className= {`questionPanelAnswersText resultAnimation`} style={{ marginTop: "20px", backgroundColor: "#f4f4f4", width: "fit-content", padding: "15px", marginLeft: "20px"}}>
                                    {`"${item["0"]}"`}
                                </div>
                                ) : <></>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </NormalBox>
            );
          } else if (questionData.questionType === QUESTION_TYPES.FITB.name) {
            return (
              <NormalBox boxType="light" className={`${styles.resultsContainer}`} key={index}>
                <Row>
                  <div className="header-board">
                    <p className="ques-name">
                      Question {questionData.serialNo}{" "}
                    </p>
                  </div>
                </Row>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside box-bord-round">
                      <div className="content">
                        <Row> 
                        <div className="ques-desc">
                            {questionData.queTitle.replace(BLANK_REGEX, "______")}
                          </div>
                        </Row>
                        <Row>
                          {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} />
                            </div>
                          )}
                        </Row>
                        <Row>
                            <Col style={{height:"300px", overflowY: "auto"}}>
                              {questionResults[index]?.map((item) =>
                                item !== FIXED_ANSWER.UNANSWERED ? (
                                  <div
                                    className={`questionPanelAnswersText resultAnimation`}
                                    style={{
                                      marginTop: "20px",
                                      backgroundColor: "#f4f4f4",
                                      width: "fit-content",
                                      padding: "15px",
                                      marginLeft: "20px"
                                    }}
                                  >
                                    {Object.keys(item).map((key) => `"${item[key]}"`).join(", ")}
                                  </div>
                                ) : <></>
                              )}
                            </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </NormalBox>
            );
          } else {
            return (
              <NormalBox boxType="light" className={`${styles.resultsContainer}`} key={index}>
                <Row>
                  <div className="header-board">
                    <p className="ques-name">
                      Question {questionData.serialNo}{" "}
                    </p>
                  </div>
                </Row>
                <Row>
                  <Col xs="11" sm="11" md="11" lg="11" className={styles.maxWidth}>
                    <div className="outside box-bord-round">
                      <div className="content">
                        <>
                          <div className="ques-desc">
                            {questionData.queTitle}
                          </div>
                          {questionData.questionImageURL && (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <img className="img-class-result" src={questionData.questionImageURL} alt={`Question ${questionData.serialNo}`} />
                            </div>
                          )}
                          <div key={RESULT_CONTAINER_KEY_PREFIX + questionData.serialNo}>
                            {questionData.questionType === QUESTION_TYPES.WC.name ?
                              <WordCloudResult
                                data={questionResults[index]}
                                serialNo={questionData.serialNo}
                                // pollType={POLL_TYPE.SURVEY}
                              /> :
                              <ClassResultChart
                                correctAnswers={questionData.CA}
                                chartData={questionData.questionResults}
                                questionType={questionData.questionType}
                                pollType={POLL_TYPE.SURVEY}
                                queResponse={rawQueResponses[index]}
                              />
                            }
                          </div>
                        </>
                      </div>
                    </div>
                  </Col>
                </Row>
              </NormalBox>
            );
          }
        })} */}
      {/* </NormalBox> */}
    </div>
  );
}
